/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { FormatPrice, dateFormat, formatCityText } from "../utils/common";

const PaymentDetailsItem = ({ data, productDummy, orderId, receiptId }) => {
  const initialValue = 0;
  const amounts = (productDummy?.milestones || []).map((item) =>
    Number(item.paymentmilestone[0].amount)
  );
  const totalAmount =
    Array.isArray(amounts) &&
    amounts.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0
    );
  const voucherOffset = (productDummy?.milestones || []).map((item) =>
    Number(item.paymentmilestone[0].voucherOffset)
  );
  const totalVoucherOffset =
    Array.isArray(voucherOffset) &&
    voucherOffset.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      initialValue
    );
  const netPayment = (productDummy?.allMilestones || []).map((item) =>
    Number(item.paymentmilestone[0].netPaymentDue)
  );
  const currentMilestoneIndex =
    data?.paymentMilestones.findIndex((item) => item.reciept === receiptId) ??
    0;
  const alreadyPaidAmt = (productDummy?.allMilestones || [])
    .filter(
      (x, i) => i <= (currentMilestoneIndex > 0 ? currentMilestoneIndex : 0)
    )
    .map((item) => Number(item.paymentmilestone[0]?.alreadyPaid ?? 0))
    .reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      initialValue
    );
  const totalNetPayment =
    Array.isArray(netPayment) &&
    netPayment.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      initialValue
    );
  console.log("__netPayment", netPayment);
  const paymentDetails = data?.payment_method ?? null;

  const SummaryInfo = ({ title, value, link }) => {
    return (
      <div className="flex mb-2">
        <div className="w-64">
          <p className="mr-3 w-full font-worksans-semibold text-bg-charcoal text-base">
            {title}
          </p>
        </div>
        <div className="w-full">
          <p className="text-[#4A4949] text-base">{value}</p>
        </div>
      </div>
    );
  };
  const vendorName = data?.vendorDetails?.vendorName ?? data?.vendor?.vendorName;
  const vendorAddress = () => {
    const address = data?.vendorDetails ?? data?.vendor;
    return (
      <>
        {address?.unitNo && `${address.unitNo}, `}
        {address?.blockNumber && `${address.blockNumber}, `}
        {address?.streetAddress2
          ? `${address?.streetAddress1}, `
          : `${address?.streetAddress1}`}
        <br />
        {address?.streetAddress2
          ? `${address?.streetAddress2}, `
          : address?.streetAddress2}
        {address?.streetAddress3 ? `${address?.streetAddress3},` : ""}
        {address?.streetAddress2 || address?.streetAddress3 ? <br /> : ""}
        {formatCityText(address)}
      </>
    );
  };

  const customerAddress = () => {
    const address = data?.billingDetails;
    return (
      <>
        {address?.unitNumber && `${address.unitNumber}, `}
        {address?.streetAddress2
          ? `${address?.streetAddress}, `
          : `${address?.streetAddress}`}
        <br />
        {address?.streetAddress2
          ? `${address?.streetAddress2}, `
          : address?.streetAddress2}
        {address?.streetAddress3 ? `${address?.streetAddress3},` : ""}
        {address?.streetAddress2 || address?.streetAddress3 ? <br /> : ""}
        {formatCityText(address)}
      </>
    );
  };

  const formatAddress = (addr) => {
    const address = addr;
    return (
      <>
        {address?.unitNumber && `${address.unitNumber}, `}
        {address?.streetAddress2
          ? `${address?.streetAddress}, `
          : `${address?.streetAddress}`}
        <br />
        {address?.streetAddress2
          ? `${address?.streetAddress2}, `
          : address?.streetAddress2}
        {address?.streetAddress3 ? `${address?.streetAddress3},` : ""}
        {address?.streetAddress2 || address?.streetAddress3 ? <br /> : ""}
        {formatCityText(address)}
      </>
    );
  };

  return (
    <div className="w-full items-end mt-5 justify-between">
      <div className="w-full flex border-b border-[#AAAAAA] mb-5">
        <div className="w-1/2">
          <div className="md:flex items-end mb-5">
            <p className="text-bg-primary font-cagily text-3xl">Receipt</p>
          </div>
          <div className="mb-8">
            <p className="text-black text-lg pb-2">
              Order No. {`${data?.orderId}`}
            </p>
            <p className="text-black text-lg pb-2">
              Receipt No.{" "}
              {`${
                productDummy?.milestones
                  ? productDummy?.milestones[0]?.reciept
                  : ""
              }`}
            </p>
            <p className="text-black text-lg pb-2">
              Receipt Date: {dateFormat(data?.createdAt)}
            </p>
          </div>
        </div>
      </div>
      <div className="flex border-b border-[#AAAAAA] mb-5 pb-8 pt-10">
        <div className="mr-20 w-1/2">
          <SummaryInfo
            title={`Customer ID:`}
            value={data?.userId?.userUniqueId}
          />
          <SummaryInfo
            title={`Customer Name:`}
            value={
              data?.billingDetails?.firstName +
              " " +
              data?.billingDetails?.lastName
            }
          />
          <SummaryInfo title={"Customer Address:"} value={customerAddress()} />
          <SummaryInfo title={"Phone:"} value={data?.userId?.phone} />
          <SummaryInfo title={"Email:"} value={data?.userId?.email} />
        </div>
        <div className="mr-20 w-1/2">
          <SummaryInfo
            title={"Vendor Name:"}
            value={vendorName}
          />
          <SummaryInfo title={"Vendor Address:"} value={vendorAddress()} />
          {data?.vatTaxNumber && (
            <SummaryInfo title={"GST Reg No:"} value={data?.vatTaxNumber} />
          )}
          <SummaryInfo
            title={"Total Order Amount:"}
            value={`$${FormatPrice(data?.subTotal + data?.subTotalTax)}`}
          />
          <SummaryInfo
            title={"Payment Milestone:"}
            value={
              !receiptId
                ? 1
                : (data?.paymentMilestones.findIndex(
                    (item) => item.reciept === receiptId
                  ) ?? 0) + 1
            }
          />
        </div>
      </div>
      <div>
        <div className="mb-10">
          <table className="table-auto w-full">
            <thead className="border-b text-base">
              <tr className="font-worksans-semibold">
                <th className="text-[#4A4949] text-left p-4"></th>
                <th className="text-[#4A4949] text-left p-4">Product Name</th>
                <th className="text-[#4A4949] text-center p-4">Quantity</th>
                <th className="text-[#4A4949] text-center p-4">Unit Price</th>
                <th className="text-[#4A4949] text-center p-4">
                  Item Discount
                </th>
                <th className="text-[#4A4949] text-left p-4">Total Amount</th>
              </tr>
            </thead>
            <tbody className="border-b text-base">
              {productDummy?.products?.map((item, index) => {
                return (
                  <>
                    <tr
                      className="items-center border-b font-worksans"
                      key={index}
                    >
                      <td className="p-4 text-[#4A4949] text-center">
                        {index + 1}
                      </td>
                      <td className="p-4">
                        <div>
                          <p>{item.name}</p>
                          {item.attributes.map((attribute) => (
                            <p className="text-base" key={attribute._id}>
                              {attribute.variation}
                            </p>
                          ))}
                        </div>
                      </td>
                      <td className="p-4 text-[#4A4949] text-center ">
                        {item.qty}
                      </td>
                      <td className="p-4 text-[#4A4949] text-center">{`$${FormatPrice(
                        item?.price
                      )}`}</td>
                      <td className="p-4 text-[#4A4949] text-center">{`$ ${FormatPrice(
                        item.discount
                      )}`}</td>
                      <td className="p-4 text-[#4A4949] text-left">{`$ ${FormatPrice(
                        item?.price * item?.quantity
                      )}`}</td>
                    </tr>
                  </>
                );
              })}
              <tr>
                <td colSpan={5} className="p-4 text-right">
                  <div className="mt-5 text-bg-primary space-y-2">
                    <p>Sub Total:</p>
                    {data?.appliedDiscountId !== null && (
                      <p>
                        Discount{" "}
                        {data?.discountType === "PERCENT" && (
                          <span className="underline">
                            ({data?.discountPercentage}%)
                          </span>
                        )}
                        :{" "}
                      </p>
                    )}
                    {data?.subTotalTax > 0 && <p>GST :</p>}
                    <p>Total Order Amount:</p>
                    {(data?.appliedVoucherId !== null ||
                      data?.appliedEVoucherId !== null) && (
                      <p>
                        Voucher
                        {data?.voucherType === "PERCENT" && (
                          <span className="underline">
                            ({data?.voucherPercentage}%)
                          </span>
                        )}
                        :
                      </p>
                    )}
                    <p>Total Amount to Pay:</p>
                    <p>Current Milestone to Pay:</p>
                    <p>Current Milestone Paid:</p>
                    <p>Total Amount Paid:</p>
                    <p>Balance Due:</p>
                  </div>
                </td>
                <td className="p-4 text-left">
                  <div className="mt-5 text-bg-primary space-y-2">
                    <p>{`$${FormatPrice(data?.subTotalwithoutDiscount)}`}</p>
                    {data?.appliedDiscountId !== null && (
                      <p className="text-red-600">{`($${FormatPrice(
                        data?.totalDiscount
                      )})`}</p>
                    )}
                    {data?.subTotalTax > 0 && (
                      <p>{`$${FormatPrice(data?.subTotalTax)}`}</p>
                    )}
                    <p>{`$${FormatPrice(
                      data?.subTotal + data?.subTotalTax
                    )}`}</p>
                    {(data?.appliedVoucherId !== null ||
                      data?.appliedEVoucherId !== null) && (
                      <p className="text-red-600">{`($${FormatPrice(
                        data?.totalVoucher
                      )})`}</p>
                    )}
                    <p>{`$${FormatPrice(totalNetPayment)}`}</p>
                    <p>{`$${FormatPrice(
                      productDummy?.milestones?.find(
                        (x) => x?.paymentmilestone[0]?.paymentStatus === 1
                      )?.paymentmilestone[0]?.netPaymentDue ?? 0
                    )}`}</p>
                    <p>{`$${FormatPrice(
                      productDummy?.milestones[0]?.paymentmilestone[0]
                        ?.netPaymentDue ?? 0
                    )}`}</p>
                    <p>{`$${FormatPrice(alreadyPaidAmt)}`}</p>
                    <p>{`$${FormatPrice(totalNetPayment - alreadyPaidAmt)}`}</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="w-full flex items-center mb-5">
          <table className="table-auto w-full">
            <thead className="border-b">
              <tr className="font-worksans-semibold">
                <th className="text-[#4A4949] text-center text-base p-4">
                  Payment Milestone
                </th>
                <th className="text-[#4A4949] text-center text-base p-4">
                  Payment Due Date
                </th>
                <th className="text-[#4A4949] text-center text-base p-4">
                  Total Order excl Tax
                </th>
                <th className="text-[#4A4949] text-center text-base p-4">
                  Gst
                </th>
                <th className="text-[#4A4949] text-center text-base p-4">
                  Voucher Offset
                </th>
                <th className="text-[#4A4949] text-center text-base p-4">
                  Net Payment Due
                </th>
                <th className="text-[#4A4949] text-center text-base p-4">
                  Remaining Voucher Value
                </th>
              </tr>
            </thead>
            <tbody className="border-b">
              {(productDummy?.milestones || []).map((item, index) => {
                return (
                  <tr
                    className="items-center border-b font-worksans"
                    key={index}
                  >
                    <td className="p-4 text-[#4A4949] text-center text-base">
                      {item.paymentMilestoneNumber}
                    </td>
                    <td className="p-4 text-[#4A4949] text-center text-base">
                      {dateFormat(item.paymentmilestone[0].milestoneDate)}
                    </td>
                    <td className="p-4 text-[#4A4949] text-center text-base">{`$${FormatPrice(
                      Number(item.paymentmilestone[0].amount)
                    )}`}</td>
                    <td className="p-4 text-[#4A4949] text-center text-base">{`$${FormatPrice(
                      Number(item.paymentmilestone[0].gst)
                    )}`}</td>
                    <td className="p-4 text-[#4A4949] text-center text-base">{`$${FormatPrice(
                      Number(item.paymentmilestone[0].voucherOffset)
                    )}`}</td>
                    <td className="p-4 text-[#4A4949] text-center text-base">{`$${FormatPrice(
                      Number(item.paymentmilestone[0].netPaymentDue)
                    )}`}</td>
                    <td className="p-4 text-[#4A4949] text-center text-base">{`$${FormatPrice(
                      Number(item.paymentmilestone[0].remainingVoucherValue)
                    )}`}</td>
                  </tr>
                );
              })}
              <tr className="items-center border-b font-worksans-semibold">
                <td className="p-4 text-[#4A4949] text-center text-base"></td>
                <td className="p-4 text-[#4A4949] text-center text-base"></td>
                <td className="p-4 text-[#4A4949] text-center text-base">{`$${FormatPrice(
                  Number(totalAmount)
                )}`}</td>
                <td className="p-4 text-[#4A4949] text-center text-base">{`$${FormatPrice(
                  Number(data?.subTotalTax)
                )}`}</td>
                <td className="p-4 text-[#4A4949] text-center text-base">{`$${FormatPrice(
                  Number(totalVoucherOffset)
                )}`}</td>
                <td className="p-4 text-[#4A4949] text-center text-base">{`$${FormatPrice(
                  Number(totalNetPayment)
                )}`}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex justify-between">
          <div className="block">
            <div className="mb-10 text-black">
              <p className="font-worksans-semibold text-base mb-1 mt-5">
                Comments:
              </p>
              <p className="text-base mb-7">{data?.comment}</p>
            </div>
            <div className="mb-5 text-black">
              <p className="text-black text-base">
                {vendorName} Cancellation Policy:
              </p>
              <pre className="text-black text-base font-worksans">
                {data?.vendorPaymentPolicyId?.cancelllationPolicy}
              </pre>
            </div>
            <div>
              <p className="text-base">
                Note that Order Cancellation may be subject to Cancellation
                admin charges
              </p>
              <p className="text-base">Acknowledged {dateFormat(new Date())}</p>
            </div>
          </div>
        </div>
        <div className="w-full flex gap-x-5 mt-8">
          <div className="w-[30%]">
            <div className="space-y-2 text-base">
              <p className="font-worksans-medium text-base">
                Shipping Information
              </p>
              <p className="text-base">
                {data?.shippingDetails?.firstName}{" "}
                {data?.shippingDetails?.lastName} <br />
                {data?.shippingDetails?.phone} <br />
                {formatAddress(data?.shippingDetails)}
              </p>
            </div>
          </div>
          <div className="w-[30%]">
            <div className="space-y-2">
              <p className="font-worksans-medium text-base">
                Billing Information
              </p>
              <p className="text-base">
                {data?.billingDetails?.firstName}{" "}
                {data?.billingDetails?.lastName} <br />
                {formatAddress(data?.billingDetails)}
              </p>
            </div>
          </div>
        </div>
        {paymentDetails && (
          <div className="mt-5">
            <hr className="my-3" />
            <p className="font-worksans-medium text-base mb-2">
              Payment Method
            </p>
            {paymentDetails?.card ? (
              <p className="capitalize text-base">{`${
                paymentDetails?.card?.funding
              } ${
                paymentDetails?.card?.last4
                  ? `card - *** ${paymentDetails?.card?.last4}`
                  : ""
              } ${paymentDetails?.card?.brand}`}</p>
            ) : (
              <p className="capitalize text-base">
                {paymentDetails?.type ?? ""}
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentDetailsItem;
