/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { vendorFormAction } from "../../reducers/vendor.reducer";
import { updateVendorActionChecks } from '../../actions'
import { toast } from "react-toastify";

const RenderItem = ({ title, index, onSelect, value }) => (
  <div className="flex items-center">
    <p className="w-4">{index + 1}.</p>
    <p className="w-1/2">{title}</p>
    <select
      defaultValue={"true"}
      value={value}
      onChange={(e) => onSelect(e.target.value === "true")}
      className="w-20 px-1 border-2 bg-white"
    >
      <option value={"true"}>Yes</option>
      <option value={"false"}>No</option>
    </select>
  </div>
);

const FormValidationChecks = ({ isNew }) => {
  const vendor = useSelector((state) => state.vendorSlice.vendor);
  const vendorForm = useSelector((state) => state.vendorSlice.vendorForm);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isNew) return;
  }, []);
  const handleChangeForm = (title, value) => {
    dispatch(
      vendorFormAction({
        ...vendorForm,
        validationChecks: {
          ...vendorForm.validationChecks,
          [title]: value,
        },
      })
    );
  };

  const handleStatus = (status) => {
    if (status === 2) {

      dispatch(
        updateVendorActionChecks({
          params: {
            id: vendor.id,
            status: status,
          },
          onSuccess: () => {
            toast.success("Vendor Rejected!");
            window.location.reload();
            // getVendorList(paginationObject, "", "");
          },
          onFailed: () => { },
        })
      );
    } else {
      dispatch(
        updateVendorActionChecks({
          params: {
            id: vendor.id,
            status: status,
          },
          onSuccess: () => {
            toast.success("Vendor Approved!");
            window.location.reload();
            // getVendorList(paginationObject, "", "");
          },
          onFailed: () => { },
        })
      );
    }
  }

  return (
    <div className="md:mt-10 mt-5 animate-fade-in-down">
      <div className="space-y-4">
        {[
          { title: "5 Year History", name: "fiveYearHistory" },
          {
            title: "Previously Director of a Bankcrupt Closed Company?",
            name: "isDirectorOfbankrupt",
          },
          { title: "Involved in CASE Review?", name: "isCaseReview" },
          { title: "Bad Press Coverage", name: "isBadPress" },
          { title: "Bad Reviews/Complaints", name: "isBadReviews" },
        ].map((item, index) => (
          <RenderItem
            title={item.title}
            index={index}
            onSelect={(e) => handleChangeForm(item.name, e)}
            value={
              vendorForm.validationChecks
                ? vendorForm.validationChecks[item.name]
                : "true"
            }
          />
        ))}
      </div>
      <div className="md:flex md:space-x-2 md:space-y-0 space-y-3 mt-5">
        <div className="w-full space-y-2">
          <p>Comments</p>
          <textarea
            value={vendorForm.validationChecks?.comment}
            onChange={(e) => handleChangeForm("comment", e.target.value)}
            id="w3review"
            className="w-full border-2 hover:border-blue-500 shadow-lg rounded-md p-2"
            placeholder="For Platform Admin to fill commentary about the application"
            name="Comments"
            rows="4"
            cols="50"
          ></textarea>
        </div>
      </div>
      <div className="md:flex md:space-x-2 md:space-y-0 space-y-3 mt-4">
        <div className="space-y-2">
          <div className="flex">
            {/* <button
              style={{ background: "#990000" }}
              className={` py-2 px-4 mr-2 rounded shadow-sm ${"text-white "} w-40`}
            >
              Add Review
            </button> */}
            {vendor.status === 2 ? null : <button
              style={{ background: "#990000" }}
              className={` py-2 px-4 mr-2 rounded shadow-sm ${"text-white "} w-40`}
              onClick={() => handleStatus(2)}
            >
              Reject
            </button>}
            {vendor.status === 1 ? null : <button
              style={{ background: "#990000" }}
              className={` py-2 px-4 mr-2 rounded shadow-sm ${"text-white "} w-40`}
              onClick={() => handleStatus(1)}
            >
              Approve
            </button>}
          </div>
        </div>
      </div>
    </div>
  );
};

const ValidationChecks = ({ isNew }) => {
  const [openForm, setOpenForm] = useState(false);

  return (
    <div className="w-full shadow-xl px-5 py-5 bg-white">
      <div
        onClick={() => setOpenForm(!openForm)}
        className="flex justify-between cursor-pointer"
      >
        <p className="text-xl">Validation Checks</p>
        <BsChevronDown
          size={25}
          className={
            "text-bg-primary " +
            (openForm ? "rotate-180 transition-all" : "rotate-0 transition-all")
          }
        />
      </div>
      {openForm && <FormValidationChecks isNew={isNew} />}
    </div>
  );
};

export default ValidationChecks;
