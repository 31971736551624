/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Validation from "../forms/validation-user";
import Alert from "../alerts";
import { useParams, useHistory, Link, Redirect } from "react-router-dom";
import ApiClient from "../../apis/apiClient";
import { API_GetUserById, API_UpdateUserById, url } from "../../apis/constant";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import axios from "axios";

const UserEdit = ({ message = null }) => {
  const adminToken = localStorage.getItem("wed_admin_token");
  const history = useHistory();
  const [data, onSubmit] = useState(null);
  const { id } = useParams();
  const minDate = moment().add(1, "days").format("DD-MMM-YYYY");
  const [phoneErr, setPhoneErr] = useState("");
  const [brideErr, setBrideErr] = useState("");
  const [brideLastErr, setBrideLastErr] = useState("");
  const [groomErr, setGroomErr] = useState("");
  const [groomLastErr, setGroomLastErr] = useState("");
  const [HearErr, setHearErr] = useState("");

  let itemsList = [
    {
      label: "Bride First Name",
      error: { required: "Please enter a valid name", 
      pattern: {
        value: /^([a-zA-Z0-9 ]){0,200}$/,
        message: "Name cannot be greater than 200 words.",
      }
      },
      name: "brideFirstName",
      type: "text",
      placeholder: "Enter first Name",
      value: "",
      id: "brideFirstName",
      inputType: "user-input",
    },
    {
      label: "Bride Last Name",
      error: { required: "Please enter a valid name",
      pattern: {
        value: /^([a-zA-Z0-9 ]){0,200}$/,
        message: "Name cannot be greater than 200 words.",
      }},
      name: "brideLastName",
      type: "text",
      placeholder: "Enter Last Name",
      id: "brideLastName",
      inputType: "user-input",
    },
    {
      label: "Groom First Name",
      error: { required: "Please enter a valid name",
      pattern: {
        value: /^[a-zA-Z0-9 ]{0,200}$/,
        message: "Name cannot be greater than 200 words.",
      } },
      name: "groomFirstName",
      type: "text",
      placeholder: "Enter first Name",
      value: "",
      id: "groomFirstName",
      inputType: "user-input",
    },
    {
      label: "Groom Last Name",
      error: { required: "Please enter a valid name",
      pattern: {
        value: /^[a-zA-Z0-9 ]{0,200}$/,
        message: "Name cannot be greater than 200 words.",
      } },
      name: "groomLastName",
      type: "text",
      placeholder: "Enter first Name",
      value: "",
      id: "groomLastName",
      inputType: "user-input",
    },
    {
      label: "Wedding Date",
      readOnly: true,
      error: { required: "Please enter a valid date" },
      name: "weddingDate",
      type: "date",
      placeholder: "Enter wedding date",
      // inputType: 'user-input',
      min: minDate,
      value: "",
      id: "weddingDate",
    },
    {
      label: "Hear About Us",
      error: { required: "Please enter a valid data",
       },
      name: "hearAboutUs",
      type: "text",
      placeholder: "Enter about us",
      inputType: "user-input",
      value: "",
      id: "hearAboutUs",
    },
    {
      label: "Email address",
      error: {
        required: "Please enter a valid email",
        pattern: {
          value: /^[A-Za-z0-9._]{3,}@[A-Za-z]{3,}[.]{1}[A-Za-z.]{2,6}$/,
          message: "Entered value needs to be an e-mail address.",
        },
      },
      name: "email",
      type: "email",
      placeholder: "Enter your email",
      inputType: "user-input",
      value: "",
      id: "email",
    },
    {
      label: "Phone",
      error: {
        required: "Please enter your phone number",
        pattern: {
          value:  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
          message: "Please enter valid phone number.",
        },
      },
      name: "phone",
      type: "phone",
      placeholder: "Enter your phone number",
      inputType: "user-input",
      value: "",
      id: "phone",
    },
  ];
  const [items, setItems] = useState(itemsList);
  const [originalData, setOriginalData] = useState();

  const GetUser = async () => {
    const data = "";
    const res = await ApiClient.get(
      API_GetUserById + id,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      var tempArr = [];
      setOriginalData(res.data.data);
      for (let i = 0; i < items.length; i++) {
        const element = items[i];
        if (res.data.data.weddingDate && element.id === "weddingDate") {
          element.value = moment.utc(res.data.data.weddingDate).tz("Asia/Singapore").utc().format( "YYYY-MM-DD" );
        } else {
          element.value = res.data.data[element.id];
        }
        tempArr.push(element);
      }
      setItems(tempArr);
    } else {
      toast.warning(res.data.message);
    }
  };

  useEffect(() => {
    GetUser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value, id) => {
    var result = [...items];

    result = result.map((x) => {
      if (x.id === id) {

        // if (x.id === "phone") {
        //   let regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        //   if (regex.test(value) == true) {
        //     // debugger
        //     x.value = value;
        //     setPhoneErr("");
        //   } else {
        //     // debugger
        //     setPhoneErr("Invalid Phone number.");
        //     x.value = value;
        //   }
        // } else if (x.id === "brideFirstName") {
        //   if (value.length <= 200) {
        //     x.value = value;
        //     setBrideErr("");
        //   } else {
        //     setBrideErr("Name can not be greter than 200 words.");
        //     x.value = x.value;
        //   }
        // } else if (x.id === "brideLastName") {
        //   if (value.length <= 200) {
        //     x.value = value;
        //     setBrideLastErr("");
        //   } else {
        //     setBrideLastErr("Name can not be greter than 200 words.");
        //     x.value = x.value;
        //   }
        // } else if (x.id === "groomFirstName") {
        //   if (value.length <= 200) {
        //     x.value = value;
        //     setGroomErr("");
        //   } else {
        //     setGroomErr("Name can not be greter than 200.");
        //     x.value = x.value;
        //   }
        // } else if (x.id === "groomLastName") {
        //   if (value.length <= 200) {
        //     x.value = value;
        //     setGroomLastErr("");
        //   } else {
        //     setGroomLastErr("Name can not be greter than 200.");
        //     x.value = x.value;
        //   }
        // } else if (x.id === "hearAboutUs") {
        //   if (value.length <= 30) {
        //     x.value = value;
        //     setHearErr("");
        //   } else {
        //     setHearErr("Hear About Us can not be greter than 30.");
        //     x.value = x.value;
        //   }
        // } else {
          x.value = value;
        // }
      }
      return x;
    });
    setItems(result);
  };
  //

  if (data !== null) {
    
    let logData = {
      brideFirstName: data?.brideFirstName,
      brideLastName: data?.brideLastName,
      groomFirstName: data?.groomFirstName,
      groomLastName: data?.groomLastName,
      weddingDate: originalData?.weddingDate,
      hearAboutUs: data?.hearAboutUs,
      email: data?.email,
      phone: data?.phone,
    };
    axios
      .put(url + API_UpdateUserById + id, logData, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((res) => {
        if (res.data.code === 200) {
          toast.success(res.data.message);
          history.push("/users");
        } else {
          toast.error(res.data.message);
        }
      });
  }

  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
  return (
    <>
      <div className="addBack" style={{ marginTop: "15px" }}>
        <Link
          to={"/users"}
          className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
        >
          Back
        </Link>
      </div>
      <div className="flex flex-col">
        {data && message && (
          <div className="w-full mb-4">
            <Alert
              color="bg-transparent border-green-500 text-green-500"
              borderLeft
              raised
            >
              {message}
            </Alert>
          </div>
        )}
        <Validation
          items={items}
          onSubmit={onSubmit}
          onChange={handleChange}
          phoneErr={phoneErr}
          brideErr={brideErr}
          brideLastErr={brideLastErr}
          groomErr={groomErr}
          groomLastErr={groomLastErr}
          HearErr={HearErr}

        />
      </div>
    </>
  );
        }
};

export default UserEdit;
