/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SectionTitle from "../components/section-title";
import Attributetable from "../components/attributes";
import Widget from "../components/widget";
import { toast } from "react-toastify";
import Modal2 from "../components/confirm-delete-modal/modal-2";
import { FiX } from "react-icons/fi";
import ModalActivate from "../components/confirm-activate-modal/modal-activate";
import ApiClient from "../apis/apiClient";
import {
  API_GetAllAttribute,
  API_DeleteAttributeById,
  API_UpdateAttributeById,
} from "../apis/constant";
import AddAttribute from "../components/attributes/AddAttributes";
import EditAttributeList from "../components/attributes/attributeEditModal";
import { Redirect } from "react-router-dom";
import { checkAccess } from "../utils/common";

const Simple = () => {
  const [attributeData, setAttributeData] = useState([]);
  const [Loading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [paginationObject, setPaginationObject] = useState({});
  const adminToken = localStorage.getItem("wed_admin_token");


  const getAllAttribute = async (limit, searchq = "", pageNumber) => {
    const data = "";
    setIsLoading(true);
    // setIsApiCall(true);
    const res = await ApiClient.get(
      API_GetAllAttribute +
        `?page=${pageNumber}&sortBy=asc&limit=${limit}&searchBy=${search}`,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      if (res.data.data && res.data.data.docs.length !== 0) {
        for (let i = 0; i < res.data.data.docs.length; i++) {
          res.data.data.docs[i]["displayStatus"] =
            res.data.data.docs[i].status === 0 ? (
              <label className="">Inactive</label>
            ) : (
              <label className="">Active</label>
            );
        }
      }
      setPaginationObject({
        limit: res.data.data.limit,
        page: res.data.data.page,
        pages: res.data.data.pages,
        total: res.data.data.total,
      });
      setAttributeData(res.data.data.docs);
      setIsLoading(false);
      // setIsApiCall(false);
    }
  };
  useEffect(() => {
    if (search.length > 2 || search.length === 0) {
      getAllAttribute(10, search, 1);
    }
  }, [search]);

  const deleteAttribute = async (attributeData) => {
    const id = attributeData.original.id;
    const data = "";
    const res = await ApiClient.delete(
      API_DeleteAttributeById + id,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllAttribute(10, "", 1);
    } else {
      toast.warning(res.data.message);
    }
  };

  const handleActive = async (status, data) => {
    let myObj = {
      status: status,
    };
    const res = await ApiClient.patch(
      API_UpdateAttributeById + "/" + data.id,
      myObj,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllAttribute(10, "", 1);
    } else {
      toast.warning(res.data.message);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Attribute Name",
        accessor: "attributeName",
      },
      {
        Header: "Attribute Display Name",
        accessor: "attributeDisplayName",
      },
      {
        Header: "Status",
        accessor: "displayStatus",
      },
      {
        Header: "Action",
        id: "delete",
        accessor: (str) => {},

        Cell: (Datatable) => (
          <>
            {checkAccess(9, 'edit') && <EditAttributeList
              id={Datatable.row.original.id}
              title={"Edit Attribute"}
              body={"body"}
              getApi={getAllAttribute}
            />}{" "}
            |{" "}
            {checkAccess(9, 'delete') && <Modal2
              title="Delete"
              icon={
                <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                  <FiX size={18} className="stroke-current text-red-500" />
                </span>
              }
              body={
                <div className="text-sm text-grey-500">
                  Are You Sure! You want to delete this Attribute?
                </div>
              }
              buttonTitle="Confirm"
              onClick={() => {
                deleteAttribute(Datatable.row);
              }}
              buttonClassName="underline"
            />}{" "}
            |{" "}
            {Datatable.row.original.status === 0 ? (
              checkAccess(9, 'edit') && <ModalActivate
                title="Activate"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to Deactivate this Attribute?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  handleActive(1, Datatable.cell.row.original);
                }}
                buttonClassName="underline"
              />
            ) : (
              checkAccess(9, 'edit') && <ModalActivate
                title="Deactivate"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to Active this Attribute?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  handleActive(0, Datatable.cell.row.original);
                }}
                buttonClassName="underline"
              />
            )}
          </>
        ),
      },
    ],
    []
  );

  const getPageData = (data, type) => {
    if (type === "limit") {
      getAllAttribute(data, "", 1);
    } else {
      getAllAttribute(data, "", 1);
    }
  };

  const data = React.useMemo(() => attributeData, [attributeData]);

  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
  return (
    <>
      <div className="flex justify-between">
        <div>
          <input
            className="searchCss add_fliter_input"
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        {checkAccess(9, 'add') && <AddAttribute
          title={"Add Attribute "}
          body={"body"}
          getApi={getAllAttribute}
        />}
      </div>
      <Attributetable
        columns={columns}
        data={data}
        loading={Loading}
        pagination={paginationObject}
        onSubmit={getPageData}
        showSearch={true}
        getAllAttribute={getAllAttribute}
      />
    </>
  );
}
};

const Index = () => (
  <>
    <SectionTitle title="Manage Attributes" subtitle="Attributes" />

    <Widget>
      <Simple />
    </Widget>
  </>
);
export default Index;
