import React, { useState } from 'react';
import { useHistory } from "react-router-dom"
import ResetFormValidation from '../forms/resetPassword';
import Alert from '../alerts'
import { toast } from 'react-toastify';
import axios from "axios"
import {
  url,
  API_ResetPassword


} from "../../apis/constant";



const ResetPassword = ({ message = null, token }) => {
  const [error, setError] = useState("")
  const history = useHistory()


  const handleSubmit = (data) => {
    let confirmNewPassword = data["confirm-new-password"]
    let newPassword = data["new-password"];
    if (confirmNewPassword !== newPassword) {
      setError("password did not match")
      return

    }

    let logData = {
      password: newPassword
    };
    axios
      .post(url + API_ResetPassword + token, logData, {
      })
      .then((res) => {
        if (res.data.code === 200) {
          toast.success(res.data.message);
          history.push("/login");
        } else {
          toast.error(res.data.message);
        }
      })

      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }

  const handleChange = (value, id) => {
    var result = [...items];
    result = result.map((x) => {
      if (x.id === id) x.value = value;
      return x;
    });
    setItems(result);
  };


  let itemsList = [
    {
      label: 'New password',
      error: {
        required: 'New password is required',
      },
      name: 'new-password',
      id: "newPassword",
      type: 'password',
      value: "",
      placeholder: 'new password',
      inputType: 'login',


    },
    {
      label: 'Confirm new password',
      error: {
        required: 'Password confirmation is required',
      },
      name: 'confirm-new-password',
      id: "confirmNewPass",
      type: 'password',
      value: "",
      placeholder: 'confirm password ',
      inputType: 'loginCuurent',

    },
  ]

  const [items, setItems] = useState(itemsList);

  return (
    <>
      <div className="flex flex-col">
        {error && (
          <div className="w-full mb-4">
            <Alert
              color="bg-transparent border-red-500 text-red-500"
              borderLeft
              raised>
              {error}
            </Alert>
          </div>
        )}
        <ResetFormValidation items={items} onSubmit={handleSubmit} onChange={handleChange} />
      </div>
    </>
  )
}

export default ResetPassword
