/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_OrderViewId, url } from "../apis/constant";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { formatCurrency } from "../functions/numbers";
import { Link } from "react-router-dom";
import Loader from "../components/custom-loader/loader";
import OrderDetailsItem from "../components/orderItem";
import { dateFormat } from "../utils/common";
import PaymentDetailsItem from "../components/paymentDetail";

const adminToken = localStorage.getItem("wed_admin_token");
const PaymentDetails = () => {
  const urlParams = useParams();
  const [data, setvData] = useState(null);
  const [productDummy, setProductDummy] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(url + API_OrderViewId + urlParams.id, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((res) => {
        console.log("__res", res.data);
        if (res.data.code === 200) {
          const orderData = res.data.data;
          let product = orderData?.products?.map((item) => ({
            id: item.product,
            name: item.productTitle,
            attributes: item.attributes,
            delivery_date: item.expectedDelivery,
            price: item.price,
            discountValue: item.discount,
            quantity: item.quantity,
            qty: item.quantity,
            sub_total: item.totalPrice,
            isCheck: false,
            tax: item.tax,
          }));
          let _paymentMilestones = orderData?.paymentMilestones;
          console.log("_paymentMilestones", _paymentMilestones);
          let receiptId = orderData?.receiptId;
          if (receiptId) {
            _paymentMilestones = data.paymentMilestones.filter(
              (item) => item.reciept == receiptId
            );
          }
          const dataOrderProduct = {
            products: [...product],
            milestones: _paymentMilestones,
            allMilestones: orderData?.paymentMilestones,
          };
          setProductDummy(dataOrderProduct);
          setvData(orderData);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err.message);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center h-full">
          <Loader />
        </div>
      ) : (
        <div className="p-10">
          <PaymentDetailsItem data={data} productDummy={productDummy} />
        </div>
      )}
    </>
  );
};

export default PaymentDetails;
