/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import SectionTitle from "../components/section-title";
import FilterPageData from "../components/Filters";
import Widget from "../components/widget";
import { toast } from "react-toastify";
import Modal2 from "./../components/confirm-delete-modal/modal-2";
import { FiX } from "react-icons/fi";
import ApiClient from "../apis/apiClient";
import { Link, Redirect } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { API_FilterList, API_UpdateFilterById, API_UpdateFilterStatus, API_MasterCategoryList, API_GetAllCategory, API_GetAllSubCategory, } from "../apis/constant";
import "nprogress/nprogress.css";
import "../css/custome.css";
import { checkAccess } from "../utils/common";


const Simple = () => {
  const [flterData, setflterData] = useState([]);
  const [Loading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const adminToken = localStorage.getItem("wed_admin_token");
  const [subCatList, setSubCategoryList] = useState([]);
  const [paginationObject, setPaginationObject] = useState({});
  const [categryList, setCategoryList] = useState([]);
  const [selectedMasterCategoryValue, setSelectedMasterCategoryValue] = useState(null);
  const [selectedCategoryValue, setSelectedCategoryValue] = useState(null);
  const [selectedSubCategoryValue, setSelectedSubCategoryValue] = useState(null);

  const searchRef = useRef(search);
  const selectedSubCategoryValueRef = useRef(selectedSubCategoryValue);
  const selectedCategoryValueRef = useRef(selectedCategoryValue);
  const selectedMasterCategoryValueRef = useRef(selectedMasterCategoryValue);


  const getAllFilters = async (limit, pageNumber) => {
    console.log("getAllFilters", limit, pageNumber);
    setflterData([]);
    const data = "";
    setIsLoading(true);
    // setIsApiCall(true);
    let base_uri = API_FilterList + `?page=${pageNumber}&sortBy=asc&limit=${limit}&searchBy=${searchRef.current}`;
    if (selectedSubCategoryValueRef.current !== null) {
      base_uri = base_uri + `&subCategoryId=${selectedSubCategoryValueRef.current.id}`;
    }
    if (selectedCategoryValueRef.current !== null) {
      base_uri = base_uri + `&categoryId=${selectedCategoryValueRef.current.id}`;
    }
    if (selectedMasterCategoryValueRef.current !== null) {
      base_uri = base_uri + `&masterCategoryId=${selectedMasterCategoryValueRef.current.id}`;
    }
    const res = await ApiClient.get(base_uri, data, `Bearer ${adminToken}`);
    if (res.data.code === 200) {
      if (res.data.data && res.data.data.docs.length !== 0) {
        for (let i = 0; i < res.data.data.docs.length; i++) {
          let element = res.data.data.docs[i];
          res.data.data.docs[i]["mastercategory"] = element.hasOwnProperty("masterCategoryId") ? element.masterCategoryId !== null ? element.masterCategoryId.name : "" : "";
          res.data.data.docs[i]["category"] = element.hasOwnProperty("categoryId") ? element.categoryId !== null ? element.categoryId.name : "" : "";
          res.data.data.docs[i]["subcategory"] = element.hasOwnProperty("subCategoryId") ? element.subCategoryId !== null ? element.subCategoryId.name : "" : "";
        }
      }
      setPaginationObject({
        limit: limit,
        page: pageNumber,
        pages: res.data.data.pages,
        total: res.data.data.total,
      });
      setflterData(res.data.data.docs);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    selectedSubCategoryValueRef.current = selectedSubCategoryValue;
    selectedCategoryValueRef.current = selectedCategoryValue;
    selectedMasterCategoryValueRef.current = selectedMasterCategoryValue;
    searchRef.current = search;
    if (search.length > 2 || search.length === 0) {
      getAllFilters(10, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, selectedMasterCategoryValue, selectedCategoryValue, selectedSubCategoryValue]);

  const handleAMasterCategory = async (e) => {
    setSelectedMasterCategoryValue(e);

    setCategoryList([]);
    setSubCategoryList([]);

    getCategoryListUsingMasterId("");
    getSubCategoryListUsingCategoryId("");
  };

  const MasterCategoryList = async (inputValue) => {
    const data = "";
    const res = await ApiClient.get(
      API_MasterCategoryList + `?page=1&sortBy=asc&limit=${99999}&searchBy=${inputValue}&isFor=dropdown`, data,
      `Bearer ${adminToken}`
    );

    if (res.data.code === 200) {
      return res.data.data.docs;
    }
  };

  const getCategoryListUsingMasterId = async (inputValue) => {
    const res = await ApiClient.get(
      API_GetAllCategory + `?masterCategoryId=${selectedMasterCategoryValueRef?.current?.id ?? ""}&page=1&sortBy=asc&limit=${99999}&searchBy=${inputValue}&isFor=dropdown`, {},
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      let full_arr = res.data.data.docs;
      setCategoryList(full_arr);
      return full_arr;
    }
  };

  const getSubCategoryListUsingCategoryId = async (inputValue) => {
    const res = await ApiClient.get(
      API_GetAllSubCategory + `?categoryId=${selectedCategoryValueRef?.current?.id ?? ""}&masterCategoryId=${selectedMasterCategoryValueRef?.current?.id ?? ""}&page=1&sortBy=asc&limit=${99999}&searchBy=${inputValue}&isFor=dropdown`, {},
      `Bearer ${adminToken}`
    );

    if (res.data.code === 200) {
      let full_arr = res.data.data.docs;
      setSubCategoryList(full_arr);
      return full_arr;
    }
  };

  const handleASubCategory = (e) => {
    setSelectedSubCategoryValue(e);
  };

  const handleACategory = async (e) => {
    setSelectedCategoryValue(e);
    setSubCategoryList([]);
    getSubCategoryListUsingCategoryId("");
  };

  const ClearFilters = () => {
    setSelectedMasterCategoryValue(null);
    setSelectedCategoryValue(null);
    setSelectedSubCategoryValue(null);
    getAllFilters(10, 1);

  };

  const handleActive = async (status, data) => {
    let filterStatus = "";
    if (status === 1) {
      filterStatus = "activate";
    } else {
      filterStatus = "deactivate";
    }
    let myObj = {
      ids: [data.id],
      status: filterStatus,
    };

    const res = await ApiClient.post(
      API_UpdateFilterStatus, myObj,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllFilters(10, 1);
    } else {
      toast.warning(res.data.message);
    }
  };

  const deleteFilter = async (userData) => {
    const id = userData.original.id;
    const data = "";
    const res = await ApiClient.delete(
      API_UpdateFilterById + "/" + id, data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllFilters(10, 1);
    } else {
      toast.warning(res.data.message);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: " Level 1",
        accessor: "mastercategory",
      },
      {
        Header: "Level 2",
        accessor: "category",
      },
      {
        Header: " Level 3",
        accessor: "subcategory",
      },
      {
        Header: " Filters",
        accessor: "name",
      },
      {
        Header: "Status",
        id: "status",
        accessor: (str) => {
          return str["status"] === 1 ? (
            <label>Active</label>
          ) : (
            <label>Inactive</label>
          );
        },
      },

      {
        Header: "Action",
        id: "delete",

        Cell: (Datatable) => (
          <>
            {checkAccess(10, 'edit') && <a
              target="_blank"
              rel="noreferrer"
              href={`/editfilters/${Datatable.row.original.id}`}
              className="underLineText"
            >
              Edit
            </a>}{" "}
            |{" "}
            {checkAccess(10, 'delete') && <Modal2
              title="Delete"
              icon={
                <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                  <FiX size={18} className="stroke-current text-red-500" />
                </span>
              }
              body={
                <div className="text-sm text-grey-500">
                  Are You Sure! You want to delete this Filter Data?
                </div>
              }
              buttonTitle="Confirm"
              onClick={() => {
                deleteFilter(Datatable.row);
              }}
              buttonClassName="underLineText"
            />}{" "}
            |{" "}
            {Datatable.cell.row.original.status === 1 ? (
              checkAccess(10, 'edit') && <Modal2
                title="Deactivate"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to Deactivate this Filter Data?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  handleActive(0, Datatable.cell.row.original);
                }}
                buttonClassName="underLineText"
              />
            ) : (
              checkAccess(10, 'edit') && <Modal2
                title="Activate"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to Activate this Filter Data?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  handleActive(1, Datatable.cell.row.original);
                }}
              />
            )}
          </>
        ),
      },
    ],
    []
  );

  const getPageData = (data) => {
    getAllFilters(data, 1);
  };

  const data = React.useMemo(() => flterData, [flterData]);

  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
    return (
      <>
        <div>
          {checkAccess(10, 'add') && <Link
            to={"/filters/addfilters"}
            style={{ top: "10px" }}
            className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded addFilterCss"
          >
            Add Filter
          </Link>}
        </div>
        <div>
          <input
            className="searchCss add_fliter_input"
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <div className="inline-block flex-col manage_product_box">
            <p className="filterCategoryCss">Level 1</p>
            <div className=" masterFilterCss ">
              <AsyncSelect
                className="w-100"
                cacheOptions
                defaultOptions
                isClearable
                value={selectedMasterCategoryValue}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.id}
                loadOptions={MasterCategoryList}
                onChange={handleAMasterCategory}
              />
            </div>
          </div>
          <div className="inline-block flex-col manage_product_box">
            <p className="filterCategoryCss">Level 2</p>
            <div className=" masterFilterCss ">
              <AsyncSelect
                className="w-100"
                key={categryList.length}
                cacheOptions
                defaultOptions
                isClearable
                value={selectedCategoryValue}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.id}
                loadOptions={getCategoryListUsingMasterId}
                onChange={handleACategory}
              />
            </div>
          </div>
          <div className="inline-block flex-col manage_product_box">
            <p className="filterCategoryCss">Level 3</p>
            <div className="masterFilterCss ">
              <AsyncSelect
                className="w-100"
                key={subCatList.length}
                cacheOptions
                defaultOptions
                isClearable
                value={selectedSubCategoryValue}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.id}
                loadOptions={getSubCategoryListUsingCategoryId}
                onChange={handleASubCategory}
              />
            </div>
          </div>
          <button className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white" onClick={() => ClearFilters()} >
            Clear Filters
          </button>
        </div>

        <FilterPageData
          columns={columns}
          data={data}
          loading={Loading}
          onSubmit={getPageData}
          showSearch={true}
          pagination={paginationObject}
          getAllFilters={getAllFilters}
        />
      </>
    );
  }
};

const Filters = () => (
  <>
    <SectionTitle title="Manage Filters" subtitle="Filters" />
    <Widget>
      <Simple />
    </Widget>
  </>
);
export default Filters;
