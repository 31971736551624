import React, { useState, useEffect } from "react";
import { BsChevronDown } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { vendorFormAction } from "../../reducers/vendor.reducer";

const FaqVendor = ({ isNew }) => {
  const vendorForm = useSelector((state) => state.vendorSlice.vendorForm);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isNew) return;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addFaq = (index) => {
    dispatch(
      vendorFormAction({
        ...vendorForm,
        faq: [
          ...vendorForm.faq,
          { id: index, question: "", answer: "", isEdit: false },
        ],
      })
    );
  };

  const removeFaq = (index) => {
    dispatch(
      vendorFormAction({
        ...vendorForm,
        faq: vendorForm.faq.filter((_, i) => i !== index),
      })
    );
  };

  const editAbleFaq = (index, value) => {
    dispatch(
      vendorFormAction({
        ...vendorForm,
        faq: vendorForm.faq.map((item, i) =>
          i === index ? { ...item, isEdit: value } : item
        ),
      })
    );
  };

  const editFaq = (field, value, index) => {
    dispatch(
      vendorFormAction({
        ...vendorForm,
        faq: vendorForm.faq.map((item, i) =>
          i === index ? { ...item, [field]: value } : item
        ),
      })
    );
  };

  return (
    <div className="w-full relative overflow-x-auto">
      <button
        onClick={() => {
          addFaq(vendorForm.faq.length + 1);
        }}
        className="px-4 py-2 text-sm border-2 hover:bg-grey-400 hover:text-white rounded-md"
      >
        Add FAQ
      </button>
      <table className="text-sm text-left mt-2  w-full">
        <thead className="text-white bg-grey-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              No.
            </th>
            <th scope="col" className="px-6 py-3">
              Question
            </th>
            <th scope="col" className="px-6 py-3">
              Answer
            </th>
            <th scope="col" className="px-6 py-3 text-right">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="border-b-0.5">
          {vendorForm?.faq?.map((item, index) => (
            <tr
              key={index}
              className={`border-t-0.5 ${index % 2 !== 0 ? "bg-grey-400" : ""}`}
            >
              <td className="px-6 py-4">{index + 1}</td>
              <td className="px-6 py-4">
                {item.isEdit ? (
                  <textarea
                    className="w-ful border resize-none p-2"
                    placeholder="Write here..."
                    rows={5}
                    value={item.question}
                    onChange={(e) => editFaq("question", e.target.value, index)}
                  />
                ) : item.question !== "" ? (
                  item.question
                ) : (
                  "No question"
                )}
              </td>
              <td className="px-6 py-4">
                {item.isEdit ? (
                  <textarea
                    className="w-full border resize-none p-2"
                    placeholder="Write here..."
                    value={item.answer}
                    rows={5}
                    onChange={(e) => editFaq("answer", e.target.value, index)}
                  />
                ) : item.answer !== "" ? (
                  item.answer
                ) : (
                  "No answer "
                )}
              </td>
              <td className="px-6 py-4 text-right">
                {item.isEdit ? (
                  <div className="flex space-x-2 justify-end">
                    <button
                      onClick={() => editAbleFaq(index, false)}
                      className="px-3 py-1 text-sm text-white rounded-md bg-red-500"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => editAbleFaq(index, false)}
                      className="px-4 py-1 text-sm border text-white rounded-md bg-blue-600 "
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div className="flex space-x-2 justify-end">
                    <button
                      onClick={() => editAbleFaq(index, true)}
                      className="px-4 py-1 text-sm border text-white rounded-md bg-blue-600 "
                    >
                      Edit
                    </button>
                    {vendorForm.faq.length > 1 && (
                      <button
                        onClick={() => removeFaq(index)}
                        className="px-4 py-1 text-sm border text-white rounded-md bg-red-500 "
                      >
                        Delete
                      </button>
                    )}
                  </div>
                )}
              </td>
            </tr>
          ))}

          {/* {newData != null && (
            <tr
              className={`border-t-0.5 ${
                data.length % 2 !== 0 ? "bg-bg-romance" : ""
              }`}
            >
              <td className="px-6 py-4">{data.length + 1}</td>
              <td className="px-6 py-4">
                <textarea
                  className="w-60"
                  rows={5}
                  value={newData.question}
                  onChange={(e) =>
                    setNewData({ ...newData, question: e.target.value })
                  }
                />
              </td>
              <td className="px-6 py-4">
                <textarea
                  className="w-full"
                  rows={5}
                  value={newData.answer}
                  onChange={(e) =>
                    setNewData({ ...newData, answer: e.target.value })
                  }
                />
              </td>
              <td className="px-6 py-4 text-right">
                <div className="flex space-x-2">
                  <button
                    onClick={() => setNewData(null)}
                    className="px-3 py-1 text-sm text-white bg-bg-eunry rounded-md hover:bg-bg-primary"
                  >
                    Cancel
                  </button>
                  <button className="px-4 py-1 text-sm text-white bg-bg-eunry rounded-md hover:bg-bg-primary">
                    Save
                  </button>
                </div>
              </td>
            </tr>
          )} */}
        </tbody>
      </table>
    </div>
  );
};

const FormFaqVendor = ({ isNew }) => {
  return (
    <div className="w-full md:mt-10 mt-5 animate-fade-in-down">
      <div className="w-full flex space-x-2">
        <div className="w-full overflow-x-auto">
          <FaqVendor isNew={isNew} />
        </div>
      </div>
    </div>
  );
};

const FAQ = ({ isNew }) => {
  const [openForm, setOpenForm] = useState(false);

  return (
    <div className="w-full shadow-xl px-5 py-5 bg-white">
      <div
        onClick={() => setOpenForm(!openForm)}
        className="flex justify-between cursor-pointer"
      >
        <p className="text-xl">FAQ</p>
        <BsChevronDown
          size={25}
          className={
            "text-bg-primary " +
            (openForm ? "rotate-180 transition-all" : "rotate-0 transition-all")
          }
        />
      </div>
      {openForm && <FormFaqVendor isNew={isNew} />}
    </div>
  );
};

export default FAQ;
