import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const CommentsModal = ({
  handleClose = () => {},
  open = false,
  comment = "",
  commentBy = "",
}) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{commentBy} comments</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <pre className="font-sans text-sm" style={{ fontWeight: "normal" }}>
            {comment}
          </pre>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommentsModal;
