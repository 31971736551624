/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import moment from 'moment';
import Widget1 from "../components/dashboard/widget-1";
import Section from "../components/dashboard/section";
import SectionTitle from "../components/dashboard/section-title";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { BsChevronDown } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import { Donut1 } from "../components/dashboard/donut-chart";
import Dropdown1 from "../components/widgets/dropdown-1";
import { GoCalendar } from "react-icons/go";
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  registerables,
} from "chart.js";
import { getColor } from "../functions/colors";
import { useOutsideClick } from "../hooks/OutsideClick";
import Cashflow from "../components/dashboard/Cashflow";
import Sales from "../components/dashboard/Sales";
import Vendor from "../components/dashboard/Vendor";
import Product from "../components/dashboard/Product";
import OrderAndPayment from "../components/dashboard/OrderAndPayment";
import OpenOrder from "../components/dashboard/OpenOrder";
import OrderProfitability from "../components/dashboard/OrderProfitability";
import SalesChart from "../components/dashboard/SalesChart";
import SalesInsight from "../components/dashboard/SalesInsight";

const dateFormat = "DD-MMM-YYYY";

ChartJS.register(
  ...registerables,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController
);


const MenuCheckBox = ({ title, isCheck, onChange = () => { } }) => {
  return (
    <div className="flex items-center my-1 text-lg px-5 py-2  space-x-2">
      <input onChange={onChange} type="checkbox" id={`checkbox${title}`} checked={isCheck} />
      <label htmlFor={`checkbox${title}`} className="cursor-pointer">
        {title}
      </label>
    </div>
  );
};

const Index = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openOrderStatus, setOpenOrderStatus] = useState(3);
  const [openOrderOrderBy, setOpenOrderOrderBy] = useState('');
  const [openOrderOrder, setOpenOrderOrder] = useState('');
  const [orderProfitabilityStatus, setOrderProfitabilityStatus] = useState(3);
  const [openDigitalMarketing, setOpenDigitalMarketing] = useState(true);
  const [openSalesInsights, setOpenSalesInsights] = useState(true);
  const [orderStatus, setOrderStatus] = useState(true);
  const [openSales, setOpenSales] = useState(true);
  const [openVendors, setOpenVendors] = useState(true);
  const [orderPayment, setOrderPayment] = useState(true);
  const [openProductsServices, setOpenProductsServices] = useState(true);
  const [showCalendar, setShowCalendar] = useState(false);
  const [apiFilter, setApiFilter] = useState({
    limit: 25,
    page: 1,
    sortkey: 'orderdate',
    orderstatus: 'pendingcancellation',
    startDate: moment().format(dateFormat)
  });
  const [selectDate, setSelectDate] = useState([
    {
      startDate: new Date(),
      endDate: moment(new Date()).add(7, 'd'),
      key: "selection",
    },
  ]);

  const Menu = ({ onClick, title }) => {
    return (
      <p
        onClick={onClick}
        className="cursor-pointer my-1 px-5 py-2 text-lg hover:bg-grey-300"
      >
        {title}
      </p>
    );
  };

  const [show, setShow] = useState(false);
  const [showOpenOrder, setShowOpenOrder] = useState(false);
  const [showOrderProfitability, setShowOrderProfitability] = useState(false);
  const ref = useOutsideClick(() => setShow(false));
  const refOpenOrder = useOutsideClick(() => setShowOpenOrder(false));
  const refOrderProfitability = useOutsideClick(() =>
    setShowOrderProfitability(false)
  );
  const refDateRange = useOutsideClick(() => setShowCalendar(false));

  const [showDropdown, setShowDropdown] = useState(false);
  const refDropdow = useOutsideClick(() => setShowDropdown(false));

  return (
    <>
      <SectionTitle title="Overview" subtitle="Dashboard" />

      <Cashflow />

      <p className="text-xl font-bold mt-5 mb-5">Overview</p>
      <div ref={refDropdow} className="flex space-x-2">
        <button
          onClick={() => setShowDropdown(!showDropdown)}
          className="text-2xl bg-white hover:bg-grey-200 py-2 px-4 rounded shadow-sm border mb-5"
        >
          Period
        </button>

        {showDropdown && (
          <div className="relative">
            <div
              style={{ right: "-110px" }}
              className="absolute shadow-lg ml-8 mt-16 bg-white w-56 rounded-md z-10"
            >
              <Menu
                title="This month"
                onClick={() => {
                  setShowDropdown(!showDropdown);
                  setStartDate(moment().startOf('month').format(dateFormat));
                  setEndDate(moment().format(dateFormat));
                  setApiFilter({
                    ...apiFilter,
                    startDate: moment().startOf('month').format(dateFormat),
                    endDate: moment().format(dateFormat)
                  });
                }}
              />
              <hr></hr>
              <Menu
                title="Past 3 months"
                onClick={() => {
                  setShowDropdown(!showDropdown);
                  setStartDate(moment().subtract(3, 'months').format(dateFormat));
                  setEndDate(moment().format(dateFormat));
                  setApiFilter({
                    ...apiFilter,
                    startDate: moment().subtract(3, 'months').format(dateFormat),
                    endDate: moment().format(dateFormat)
                  });
                }}
              />
              <hr></hr>
              <Menu
                title="Past 6 months"
                onClick={() => {
                  setShowDropdown(!showDropdown);
                  setStartDate(moment().subtract(6, 'months').format(dateFormat));
                  setEndDate(moment().format(dateFormat));
                  setApiFilter({
                    ...apiFilter,
                    startDate: moment().subtract(6, 'months').format(dateFormat),
                    endDate: moment().format(dateFormat)
                  });
                }}
              />
              <hr></hr>
              <Menu
                title="Since inception"
                onClick={() => {
                  setEndDate(moment().format(dateFormat));
                  const newObj = { ...apiFilter, endDate: moment().format(dateFormat) };
                  try {
                    delete newObj.startDate;
                  } catch (e) { }
                  setShowDropdown(!showDropdown);
                  setApiFilter(newObj);
                }}
              />
            </div>
          </div>
        )}

        <div ref={refDateRange} className="relative mr-24 ">
          <div className="absolute left-1 select-none w-64 text-center bg-white">
            <div
              onClick={() => setShowCalendar(!showCalendar)}
              className="border rounded-md px-3 py-2 flex items-center cursor-pointer"
            >
              <GoCalendar size={20} />
              <p className="text-2xl ml-2">Time Range</p>
            </div>
          </div>
          {showCalendar && (
            <div className="absolute left-0 mt-16 shadow-2xl border border-gray-300">
              <DateRangePicker
                onChange={(item) => {
                  const { startDate, endDate } = item.selection;
                  setSelectDate([item.selection]);
                  setStartDate(moment(startDate).format(dateFormat));
                  setEndDate(moment(endDate).format(dateFormat));
                  setApiFilter({
                    ...apiFilter,
                    startDate: moment(startDate).format(dateFormat),
                    endDate: moment(endDate).format(dateFormat)
                  });
                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={selectDate}
                direction="horizontal"
                rangeColors="#824242"
                color="#824242"
              />
            </div>
          )}
        </div>
      </div>

      <div className="bg-white border shadow-md px-2 py-4 rounded mt-4">
        <div
          onClick={() => setOpenSales(!openSales)}
          className={`flex items-center justify-between cursor-pointer ${openSales && "mb-4"}`}>
          <p className={`font-medium text-3xl`}>Sales</p>
          <BsChevronDown
            size={25} className={openSales ? "rotate-180" : "rotate-0"}
          />
        </div>
        {openSales && (
          <div className="animate-fade-in-down">
            <Sales startDate={startDate} endDate={endDate} />
            <SalesChart />
          </div>
        )}
      </div>

      <div className="bg-white border shadow-md px-2 py-4 rounded mt-12">
        <div
          onClick={() => setOpenVendors(!openVendors)}
          className={`flex items-center justify-between cursor-pointer ${openVendors && "mb-4"
            }`}
        >
          <p className={`font-medium text-3xl`}>Vendors</p>
          <BsChevronDown
            size={25}
            className={openVendors ? "rotate-180" : "rotate-0"}
          />
        </div>
        {openVendors && (
          <Vendor startDate={startDate} endDate={endDate} />
        )}
      </div>

      <div className="bg-white border shadow-md px-2 py-4 rounded mt-4">
        <div
          onClick={() => setOpenProductsServices(!openProductsServices)}
          className={`flex items-center justify-between cursor-pointer ${openProductsServices && "mb-4"
            }`}
        >
          <p className={`font-medium text-3xl`}>Products/Services</p>
          <BsChevronDown
            size={25}
            className={openProductsServices ? "rotate-180" : "rotate-0"}
          />
        </div>
        {openProductsServices && (
          <Product />
        )}
      </div>

      <div className="bg-white border shadow-md px-2 py-4 rounded mt-4">
        <div
          onClick={() => setOrderPayment(!orderPayment)}
          className={`flex items-center justify-between cursor-pointer ${orderPayment && "mb-4"
            }`}
        >
          <p className={`font-medium text-3xl`}>Orders & Payments</p>
          <BsChevronDown
            size={25}
            className={orderPayment ? "rotate-180" : "rotate-0"}
          />
        </div>
        {orderPayment && (
          <OrderAndPayment startDate={startDate} endDate={endDate} />
        )}
      </div>

      <div className="bg-white border shadow-md px-3 py-4 rounded mt-4 sales_night">
        <div
          onClick={() => setOpenSalesInsights(!openSalesInsights)}
          className={`flex items-center justify-between cursor-pointer ${openSalesInsights && "mb-4"
            }`}
        >
          <p className={`font-medium text-3xl`}>Sales Insights</p>
          <BsChevronDown
            size={25}
            className={openSalesInsights ? "rotate-180" : "rotate-0"}
          />
        </div>
        {openSalesInsights && (
          <SalesInsight />
        )}
      </div>

      <div className="bg-white border shadow-md px-3 py-4 rounded mt-4">
        <div
          onClick={() => setOpenDigitalMarketing(!openDigitalMarketing)}
          className={`flex items-center justify-between cursor-pointer ${openDigitalMarketing && "mb-4"
            }`}
        >
          <p className={`font-medium text-3xl`}>Digital Marketing</p>
          <BsChevronDown
            size={25}
            className={openDigitalMarketing ? "rotate-180" : "rotate-0"}
          />
        </div>
        {openDigitalMarketing && (
          <div className="animate-fade-in-down">
            <div className="flex flex-col lg:flex-row w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4">
              {/*widget*/}
              <div className="w-full">
                <Widget1
                  title="Online Store Sessions"
                  description={"757 visitors"}
                  right={
                    <FiUsers
                      size={24}
                      className="stroke-current text-grey-500"
                    />
                  }
                />
              </div>
              {/*widget*/}
              <div className="w-full">
                <Widget1
                  title="Returning Customer Rate"
                  description={"10%"}
                  right={
                    <FiUsers
                      size={24}
                      className="stroke-current text-grey-500"
                    />
                  }
                />
              </div>
              {/*widget*/}
              <div className="w-full">
                <Widget1
                  title="Online Store Conversion Rate"
                  description="1.5%"
                  right={
                    <FiUsers
                      size={24}
                      className="stroke-current text-grey-500"
                    />
                  }
                />
              </div>
            </div>

            <div className="flex flex-col lg:flex-row w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4">
              <div className="w-full lg:w-1/3">
                <Section
                  title="Online Store Sessions by Traffic Source"
                  // description={<span>Online Store Sessions by Traffic Source</span>}
                  right={<Dropdown1 />}
                >
                  <div className="flex flex-row w-full">
                    <Donut1
                      data={[
                        { name: "Direct (100)", value: 100 },
                        { name: "Social (200)", value: 200 },
                        { name: "Search (50)", value: 50 },
                        { name: "Uknown (30)", value: 30 },
                      ]}
                      colors={[
                        getColor("bg-red-200"),
                        getColor("bg-green-400"),
                        getColor("bg-blue-600"),
                        getColor("bg-yellow-800"),
                      ]}
                    />
                  </div>
                </Section>
              </div>

              <div className="w-full lg:w-1/3">
                <Section
                  title="Online Store Sessions by Locations"
                  // description={<span>Online Store Sessions by Traffic Source</span>}
                  right={<Dropdown1 />}
                >
                  <div className="flex flex-row w-full">
                    <Donut1
                      data={[
                        { name: "Singapore (400)", value: 400 },
                        { name: "District (200)", value: 200 },
                        { name: "Suburbs (100)", value: 100 },
                      ]}
                      colors={[
                        getColor("bg-red-200"),
                        getColor("bg-green-400"),
                        getColor("bg-blue-600"),
                      ]}
                    />
                  </div>
                </Section>
              </div>

              <div className="w-full lg:w-1/3">
                <Section
                  title="Online Store Sessions by Device Type"
                  // description={<span>Online Store Sessions by Traffic Source</span>}
                  right={<Dropdown1 />}
                >
                  <div className="flex flex-row w-full">
                    <Donut1
                      data={[
                        { name: "Desktop (400)", value: 400 },
                        { name: "Mobile (300)", value: 300 },
                        { name: "Tablet (200)", value: 200 },
                        { name: "Other (200)", value: 200 },
                      ]}
                      colors={[
                        getColor("bg-red-200"),
                        getColor("bg-green-400"),
                        getColor("bg-blue-600"),
                        getColor("bg-yellow-800"),
                      ]}
                    />
                  </div>
                </Section>
              </div>
            </div>

            <div className="flex justify-center flex-col lg:flex-row w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4">
              <div className="w-full lg:w-1/3">
                <Section
                  title="Online Store Sessions by Social Sources"
                  // description={<span>Online Store Sessions by Traffic Source</span>}
                  right={<Dropdown1 />}
                >
                  <div className="flex flex-row w-full">
                    <Donut1
                      data={[
                        { name: "Instagram (100)", value: 100 },
                        { name: "Youtube (200)", value: 200 },
                        { name: "Facebook (50)", value: 50 },
                      ]}
                      colors={[
                        getColor("bg-red-200"),
                        getColor("bg-green-400"),
                        getColor("bg-blue-600"),
                      ]}
                    />
                  </div>
                </Section>
              </div>

              <div className="w-full lg:w-1/3">
                <Section
                  title="Top landing pages by sessions"
                  description={
                    <span className="underline cursor-pointer">
                      View report
                    </span>
                  }
                  right={<Dropdown1 />}
                >
                  <div className="flex flex-row w-full">
                    <Donut1
                      data={[
                        { name: "Homepage (400)", value: 400 },
                        { name: "/collections/venues (200)", value: 200 },
                        { name: "/FAQ (100)", value: 100 },
                      ]}
                      colors={[
                        getColor("bg-red-200"),
                        getColor("bg-green-400"),
                        getColor("bg-blue-600"),
                      ]}
                    />
                  </div>
                </Section>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="bg-white border shadow-md px-2 py-4 rounded mt-4">
        <div
          onClick={() => setOrderStatus(!orderStatus)}
          className={`flex items-center justify-between cursor-pointer ${orderStatus && "mb-4"
            }`}
        >
          <p className={`font-medium text-3xl`}>Open Orders</p>
          <BsChevronDown
            size={25}
            className={orderStatus ? "rotate-180" : "rotate-0"}
          />
        </div>
        {orderStatus && (
          <div className="animate-fade-in-down">
            <div className="flex justify-between">
              <div ref={refOpenOrder} className="flex">
                <button
                  onClick={() => setShowOpenOrder(!showOpenOrder)}
                  className="bg-white hover:bg-grey-200 py-2 px-4 rounded shadow-sm border text-xl mb-5"
                >
                  Order Status
                </button>

                {showOpenOrder && (
                  <div className="relative">
                    <div
                      style={{ right: "-100px" }}
                      className="absolute shadow-lg right-0 mt-10 bg-white rounded-md z-10 w-64"
                    >
                      <MenuCheckBox
                        title="Pending Cancellation"
                        isCheck={apiFilter.orderstatus === 'pendingcancellation'}
                        onChange={() => {
                          setShowOpenOrder(!showOpenOrder);
                          setOpenOrderStatus(3);
                          setApiFilter({
                            ...apiFilter,
                            orderstatus: 'pendingcancellation'
                          });
                        }}
                      />
                      <MenuCheckBox
                        title="Paid not delivered"
                        setOperOrderStatus={4}
                        isCheck={apiFilter.orderstatus === 'paidnotdelivered'}
                        onChange={() => {
                          setShowOpenOrder(!showOpenOrder);
                          setOpenOrderStatus(4);
                          setApiFilter({
                            ...apiFilter,
                            orderstatus: 'paidnotdelivered'
                          });
                        }}
                      />
                      <MenuCheckBox
                        title="Partially Paid"
                        isCheck={apiFilter.orderstatus === 'partiallypaid'}
                        onChange={() => {
                          setShowOpenOrder(!showOpenOrder);
                          setOpenOrderStatus(2)
                          setApiFilter({
                            ...apiFilter,
                            orderstatus: 'partiallypaid'
                          });
                        }}
                      />
                      <MenuCheckBox
                        title="Delivered"
                        isCheck={apiFilter.orderstatus === 'delivered'}
                        onChange={() => {
                          setOpenOrderStatus(1)
                          setShowOpenOrder(!showOpenOrder);
                          setApiFilter({
                            ...apiFilter,
                            orderstatus: 'delivered'
                          });
                        }}
                      />
                      <MenuCheckBox
                        title="Cancelled"
                        isCheck={apiFilter.orderstatus === 'cancelled'}
                        onChange={() => {
                          setOpenOrderStatus(0)
                          setShowOpenOrder(!showOpenOrder);
                          setApiFilter({
                            ...apiFilter,
                            orderstatus: 'cancelled'
                          });
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div ref={ref} className="flex justify-items-end">
                <button
                  onClick={() => setShow(!show)}
                  className="bg-white hover:bg-grey-200 py-2 px-4 rounded shadow-sm border text-xl mb-5"
                >
                  Sort
                </button>

                {show && (
                  <div className="relative">
                    <div className=" absolute shadow-lg right-0 mt-10 bg-white rounded-md z-10 w-96">
                      <Menu
                        title="Nearest Expected Delivery Date"
                        onClick={() => {
                          setOpenOrderOrderBy('expectedDelivery');
                          setOpenOrderOrder('desc');
                          setShow(!show);
                          setApiFilter({
                            ...apiFilter,
                            sortkey: 'expected'
                          });
                        }}
                      />
                      <hr></hr>
                      <Menu
                        title="Nearest Order Date"
                        onClick={() => {
                          setShow(!show);
                          setOpenOrderOrderBy('orderDate');
                          setOpenOrderOrder('desc');
                          setApiFilter({
                            ...apiFilter,
                            sortkey: 'orderdate'
                          });
                        }}
                      />
                      <hr></hr>
                      <Menu
                        title="Vendors A-Z"
                        onClick={() => {
                          setOpenOrderOrderBy('vendorName');
                          setOpenOrderOrder('asc');
                          setShow(!show);
                          setApiFilter({
                            ...apiFilter,
                            sortkey: 'customer'
                          });
                        }}
                      />
                      <hr></hr>
                      <Menu
                        title="Order No A-Z"
                        onClick={() => {
                          setOpenOrderOrderBy('orderNo');
                          setOpenOrderOrder('asc');
                          setShow(!show);
                          setApiFilter({
                            ...apiFilter,
                            sortkey: 'orderno'
                          });
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="p-2 bg-white border rounded-md text-xl table-responsive">
              <OpenOrder
                openOrderStatus={openOrderStatus}
                startDate={startDate}
                endDate={endDate}
                openOrderOrderBy={openOrderOrderBy}
                openOrderOrder={openOrderOrder}
              />
            </div>
            <div className="flex items-center mb-4">
              <p className={`font-medium text-3xl mt-4`}>Order Profitability</p>
            </div>
            <div ref={refOrderProfitability} className="flex w-56">
              <button
                onClick={() =>
                  setShowOrderProfitability(!showOrderProfitability)
                }
                className="bg-white hover:bg-grey-200 py-2 px-4 rounded shadow-sm border text-xl mb-5"
              >
                Order Status
              </button>

              {showOrderProfitability && (
                <div className="relative">
                  <div
                    style={{ right: "-50px" }}
                    className=" absolute shadow-lg right-0 mt-10 bg-white rounded-md z-10 w-64"
                  >
                    <MenuCheckBox
                      title="Pending Cancellation"
                      isCheck={orderProfitabilityStatus === 3}
                      onChange={() => {
                        setOrderProfitabilityStatus(3);
                        setShowOrderProfitability(showOrderProfitability);
                      }}
                    />
                    <MenuCheckBox
                      title="Paid not delivered"
                      isCheck={orderProfitabilityStatus === 4}
                      onChange={() => {
                        setOrderProfitabilityStatus(4);
                        setShowOrderProfitability(showOrderProfitability);
                      }}
                    />
                    <MenuCheckBox
                      title="Partially Paid"
                      isCheck={orderProfitabilityStatus === 2}
                      onChange={() => {
                        setOrderProfitabilityStatus(2);
                        setShowOrderProfitability(showOrderProfitability);
                      }}
                    />
                    <MenuCheckBox
                      title="Delivered"
                      isCheck={orderProfitabilityStatus === 1}
                      onChange={() => {
                        setOrderProfitabilityStatus(1);
                        setShowOrderProfitability(showOrderProfitability);
                      }}
                    />
                    <MenuCheckBox
                      title="Cancelled"
                      isCheck={orderProfitabilityStatus === 0}
                      onChange={() => {
                        setOrderProfitabilityStatus(0);
                        setShowOrderProfitability(showOrderProfitability);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="p-2 bg-white border rounded-md text-xl table-responsive">
              <OrderProfitability orderProfitabilityStatus={orderProfitabilityStatus} startDate={startDate} endDate={endDate} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Index;
