import React from 'react';
// import { useSearchParams } from "react-router-dom";

import { Link } from 'react-router-dom'
import Layout from '../layouts/centered'
import CenteredForm from '../layouts/centered-form'
import ResetPassword from '../components/sample-forms/reset-password'

const Index = () => {
  // let { token } = useParams();


  let search = window.location.search;
  let params = new URLSearchParams(search);
  let token = params.get('token');

  // const [token, setToken] = useState("")


  // const [searchParams] = useSearchParams();
  // const token =searchParams.get("token")

 
 


  return (
    <Layout>
      <CenteredForm
        title="Reset password"
        subtitle="Please enter your new password to reset your account">
        <ResetPassword message="Thanks for your message. We'll get back to you as soon as possible" token={token} />

        <div className="flex flex-row w-full mt-2">
          {/*<span className="mr-1">New user?</span>
          <span>
            <Link to="/create-account" className="link">
              Sign up here
            </Link>
          </span> */}
        </div>
        <div className="flex flex-row w-full">
          <span className="mr-1">Already have an account?</span>
          <span>
            <Link to="/login" className="link">
              Login here
            </Link>
          </span>
        </div>
      </CenteredForm>
    </Layout>
  )
}

export default Index
