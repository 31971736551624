import React, { useState } from "react";
import { useForm } from 'react-hook-form'
import Alert from '../alerts'
import { FiEye , FiEyeOff } from "react-icons/fi";


const ResetFormValidation = ({ items, onSubmit, alerts, onChange }) => {
  const { handleSubmit, errors, register } = useForm();
  const [isShow, setIsShow] = useState(false);
  const [isShowPass, setIsShowPass] = useState(false);


  const onSubmitFn = data => {
    if (onSubmit) {
      onSubmit(data)
    }
  }
  items = items.map(item => {
    item['ref'] = register(item['error'])
    return item
  })

  const handleEye = () => {
    setIsShow(!isShow);
  };

  const handleEyePass=()=>{
    setIsShowPass(!isShowPass);

  }


  const handlePasswordChange = (value, id) => {
   onChange(value, id)
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmitFn)}
      className="form flex flex-wrap w-full">
      {alerts &&
        items.map((item, i) => {
          if (!errors[item.name]) return null
          let msg = errors[item.name].message
          if (msg.length === 0) msg = `${item.label} is required`
          return (
            <div className="flex flex-col w-full">
              {errors[item.name] && (
                <div className="mb-2" key={i}>
                  <Alert
                    color="bg-transparent border-red-500 text-red-500"
                    borderLeft
                    raised>
                    {msg}
                  </Alert>
                </div>
              )}
            </div>
          )
        })}
      <div className="w-full vendor-flex-box">
        {items.map((item, i) => {
        
        
        
        if ( item.inputType === 'login') {
            return (
              <>
                <div className="form-element">
                  {item.label && <div className="form-label">{item.label}</div>}
                  <input
                    ref={item.ref}
                    id={item.id}
                    value={item.value}
                    name={item.name}
                    type={isShow ? "text" : "password"}

                    onChange={(e) => handlePasswordChange(e.target.value, item.id)}
                    className={`form-input ${errors[item.name] ? 'border-red-500' : ''
                      }`}
                    placeholder={item.placeholder}
                  />
                {!isShow ?
                < FiEyeOff
                  onClick={() => handleEye()}
                  style={{ position: "relative", left: "150px", top: "-25px" }}
                  type="text"
                />:
                <FiEye
                onClick={() => handleEye()}
                style={{ position: "relative", left: "150px", top: "-25px" }}
                type="password"
                />
                }
                  {!alerts && errors[item.name] && (
                    <div className="form-error">{errors[item.name].message}</div>
                  )}
                </div>
              </>
            )
          }

          if ( item.inputType === 'loginCuurent') {
            return (
              <>
                <div className="form-element">
                  {item.label && <div className="form-label">{item.label}</div>}
                  <input
                    ref={item.ref}
                    id={item.id}
                    value={item.value}
                    name={item.name}
                    type={isShowPass ? "text" : "password"}

                    onChange={(e) => handlePasswordChange(e.target.value, item.id)}
                    className={`form-input ${errors[item.name] ? 'border-red-500' : ''
                      }`}
                    placeholder={item.placeholder}
                  />
                {!isShowPass ?
                < FiEyeOff
                  onClick={() => handleEyePass()}
                  style={{ position: "relative", left: "150px", top: "-25px" }}
                  type="text"
                />:
                <FiEye
                onClick={() => handleEyePass()}
                style={{ position: "relative", left: "150px", top: "-25px" }}
                type="password"
                />
                }
                  {!alerts && errors[item.name] && (
                    <div className="form-error">{errors[item.name].message}</div>
                  )}
                </div>
              </>
            )
          }
       
        
          return (
            <>
              <div className="form-element">
                {item.label && <div className="form-label">{item.label}</div>}
                <input
                  ref={item.ref}
                  name={item.name}
                  type={item.type}
                  value={item.value}
                  id={item.id}
                  className={`form-input ${errors[item.name] ? 'border-red-500' : ''}`}
                  placeholder={item.placeholder}
                />
                {!alerts && errors[item.name] && (
                  <div className="form-error">{errors[item.name].message}</div>
                )}
              </div>
            </>
          )
        })}
      </div>
      <button
        type="submit"
        className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
      >Submit</button>
    </form>
  )
}
export default ResetFormValidation
