import React, { useState } from "react";
import { useOutsideClick } from "../../hooks/OutsideClick";
import PhoneJson from "../../json/phoneCode.json";

const CountryPicker = ({ onSelect, value }) => {
  const [show, setShow] = useState(false);
  const ref = useOutsideClick(() => setShow(false));

  return (
    <div ref={ref} className="border relative ">
      <div className="cursor-pointer px-2 py-1" onClick={() => setShow(!show)}>
        <p className={`${!value && 'text-white'}`}>{value || '-'}</p>
      </div>
      {show && (
        <div className="bg-white absolute mt-3 -ml-1 shadow-md w-20 text-center">
          {PhoneJson.countries.map((item, index) => (
            <div
              onClick={() => {
                onSelect(item.name);
                setShow(false);
              }}
              className="py-2 cursor-pointer hover:bg-grey-300"
            >
              <p key={index.toString()}>{item.name}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CountryPicker;
