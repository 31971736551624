/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FiSave } from "react-icons/fi";
import SectionTitle from "../components/section-title";
import VendorProductPageData from "../components/manageProduct";
import { useOutsideClick } from "../hooks/OutsideClick";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  removeProductAction,
  vendorProductsAction,
  updateVendorProducts,
} from "../actions";
import { toast } from "react-toastify";
import { checkAccess } from "../utils/common";
import { formatCurrency } from "../functions/numbers";
import { getProductStatus, getProductStatusName, gstTypes } from "../utils/constants";
import moment from "moment";
import CsvDowloader from "../components/CsvDowloader";

const CellRender = (props) => {
  const product = props.data[props.row.index];
  return (
    <div className="relative">
      {checkAccess(17, "view") ? (
        <Link
          to={`/product-detail/${product.id}?vendorName=${product.vendorName}&vendorId=${product.vendorId}`}
        >
          <p className="cursor-pointer hover:text-grey-500">{props.value}</p>
        </Link>
      ) : (
        <p className="hover:text-grey-500">{props.value}</p>
      )}
    </div>
  );
};

const Simple = ({
  columns,
  product,
  Loading,
  paginationObject,
  setLimit,
  setIds,
  setPage
}) => {
  const getPageData = (data) => {
    setLimit(data);
    setPage(1);
  };
  return (
    <VendorProductPageData
      columns={columns}
      data={product}
      loading={Loading}
      onSubmit={getPageData}
      showSearch={true}
      setIds={setIds}
      pagination={paginationObject}
      setPage={setPage}
    />
  );
};

const Menu = ({ onClick, title }) => {
  return (
    <p
      onClick={onClick}
      className="cursor-pointer my-1 px-5 py-2  hover:bg-grey-300"
    >
      {title}
    </p>
  );
};

const MenuCheckBox = ({ title, isCheck, setCheck, index }) => {
  return (
    <div className="flex items-center my-1 px-5 py-2  space-x-2">
      <input
        onChange={() => setCheck(index)}
        checked={isCheck}
        type={"checkbox"}
        id={`checkbox${title}`}
      />
      <label htmlFor={`checkbox${title}`} className="cursor-pointer">
        {title}
      </label>
    </div>
  );
};

const SummaryButton = ({ summary, setSummary, title, getProductList }) => (
  <button
    onClick={() => {
      setSummary(getProductStatusName(title));
    }}
    className={` py-2 px-4 mr-2 rounded shadow-sm ${getProductStatus(summary) === title || (!getProductStatus(summary) && title === 'All')
      ? "bg-blue-500 text-white hover:bg-blue-300"
      : "bg-white hover:bg-grey-200"
      }`}
  >
    {title}
  </button>
);

const FilterItem = ({
  onRemovePress,
  index,
  setFilter,
  filters,
  setAddFilter,
}) => {
  const [filterOptions, setFilterOptions] = useState([
    { label: "Name", value: "name" },
    { label: "Category Name", value: "catname" },
  ]);
  useEffect(() => {
    if (filters.length !== 0) {
      const fil = [...filterOptions];

      const myArrayFiltered = fil.filter((filt) => {
        return !filters.some((val) => {
          return Object.keys(val).includes(filt.value);
        });
      });
      setFilterOptions(myArrayFiltered);
    } else {
      setFilterOptions(filterOptions);
    }
  }, [filters]);
  return (
    <div className="flex space-x-2 items-center">
      <select
        className="bg-white border-2 w-32 mt-2 py-1 rounded-md"
        value=""
        onChange={(e) => {
          const fil = [...filterOptions];
          const myArrayFiltered = fil.filter((filt) => {
            return !filters.some((val) => {
              return Object.keys(val).includes(filt.value);
            });
          });
          setFilter([...filters, (filters[index] = { [e.target.value]: "" })]);
          setFilterOptions(myArrayFiltered);
          setAddFilter([]);
        }}
      >
        <option value="">Select</option>
        {filterOptions.map((item, i) => (
          <option key={i} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>

      <input
        type="input"
        value={""}
        // onChange={(e) => {
        //   const updatedfilter = [...filters];
        //   updatedfilter[index] = {
        //     [Object.keys(updatedfilter[index])[0]]: e.target.value,
        //   };
        //   setFilter(updatedfilter);
        // }}
        className="bg-white border-2 w-32 mt-2 py-1 rounded-md"
      />
      {/* {index !== 0 && (
        <AiOutlineClose
          onClick={onRemovePress}
          size={15}
          className="mt-2 cursor-pointer text-grey-60000"
        />
      )} */}
    </div>
  );
};

const Index = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showColumns, setShowColumns] = useState(false);
  const [addFilter, setAddFilter] = useState([0]);
  const [page, setPage] = useState(1);
  const [filters, setFilter] = useState([]);
  const [ids, setIds] = useState([]);
  const [product, setProduct] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [summary, setSummary] = useState("");
  const [search, setSearch] = useState("");
  const [catname, setCatName] = useState("");
  const [prodName, setProdName] = useState("");
  const [limit, setLimit] = useState(10);
  const ref = useOutsideClick(() => setShow(false));
  const refColumns = useOutsideClick(() => setShowColumns(false));
  const refFilter = useOutsideClick(() => setShowFilter(false));
  const [paginationObject, setPaginationObject] = useState({});
  const [showVendorProducts, setShowVendorProducts] = useState(true);
  const query = new URLSearchParams(window.location.search)
  const vendorId = query.get('vendorId') ?? null;

  const renderCell = (cellProps) => {
    const { value } = cellProps.cell;
    return <span className="block max-w-xs truncate hover:max-w-full">{value.replace(/<[^>]+>/g, "")}</span>;
  };

  const [columns, setColumns] = useState([
    {
      Header: "No",
      accessor: "no",
      show: true,
      isCheck: true,
    },
    {
      Header: "Vendor ID",
      accessor: "vendorId",
      isCheck: true,
      show: true,
    },
    {
      Header: "Vendor Name",
      accessor: "vendorName",
      isCheck: true,
      show: true,
    },
    {
      Header: "Product Id",
      accessor: "productId",
      Cell: (props) => <CellRender {...props} />,
      isCheck: true,
      show: true,
    },
    {
      Header: "Risk Type",
      accessor: "riskType",
      isCheck: true,
      show: true,
    },
    {
      Header: "Product Title",
      accessor: "productTitle",
      isCheck: true,
      show: true,
    },
    {
      Header: "Short Description",
      accessor: "productDescription",
      Cell: renderCell,
      isCheck: true,
      show: true,
    },
    {
      Header: "Detail Product Description",
      accessor: "detailProductDescription",
      Cell: renderCell,
      isCheck: true,
      show: true,
    },
    {
      Header: "SKU Code",
      accessor: "productSkuCode",
      isCheck: true,
      show: true,
    },
    {
      Header: "Product Category",
      accessor: "productCategory",
      Cell: (props) => {
        return props.value
          ? <span className="block max-w-xs truncate hover:max-w-full">{props.value.join(', ')}</span>
          : "-";
      },
      isCheck: true,
      show: true,
    },
    {
      Header: "Product Type",
      accessor: "productTypeIdName",
      isCheck: true,
      show: true,
    },
    {
      Header: "Product Family",
      accessor: "productFamily",
      isCheck: true,
      show: true,
    },
    {
      Header: "Price",
      accessor: "price",
      isCheck: true,
      show: true,
    },
    {
      Header: "Tax",
      accessor: "tax",
      isCheck: true,
      show: true,
    },
    {
      Header: "Listing Price",
      accessor: "listingPrice",
      isCheck: true,
      show: true,
    },
    {
      Header: "GST Type",
      accessor: "gstType",
      isCheck: true,
      show: true,
    },
    {
      Header: "Unit Count",
      accessor: "unitCount",
      isCheck: true,
      show: true,
    },
    {
      Header: "Cost Per Item",
      accessor: "costPerItem",
      isCheck: true,
      show: true,
    },
    {
      Header: "Tax Status",
      accessor: "taxStatus",
      isCheck: true,
      show: true,
    },
    {
      Header: "Tax Class",
      accessor: "taxClass",
      isCheck: true,
      show: true,
    },
    {
      Header: "Min Max Rule",
      accessor: "enableMinMaxRule",
      isCheck: true,
      show: true,
    },
    {
      Header: "Minimum Qty to Order",
      accessor: "minQty",
      isCheck: true,
      show: true,
    },
    {
      Header: "Maximum Qty to Order",
      accessor: "maxQty",
      isCheck: true,
      show: true,
    },
    {
      Header: "Minimum Pricing",
      accessor: "minPrice",
      isCheck: true,
      show: true,
    },
    {
      Header: "Maximum Pricing",
      accessor: "maxPrice",
      isCheck: true,
      show: true,
    },
    {
      Header: "Tags",
      accessor: "productTags",
      isCheck: true,
      show: true,
      Cell: (props) => <span className="block max-w-xs truncate hover:max-w-full">{props.value.join(', ')}</span>
    },
    {
      Header: "Focus Keyword",
      accessor: "focusKeyword",
      isCheck: true,
      show: true,
    },
    {
      Header: "Custom Product",
      accessor: "custom",
      isCheck: true,
      show: true,
    },
    {
      Header: "Featured Product",
      accessor: "featuredProduct",
      isCheck: true,
      show: true,
    },
    {
      Header: "Publish Date",
      accessor: "publishImmediatelyDate",
      isCheck: true,
      show: true,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (props) => {
        return (
          <select
            disabled={!checkAccess(17, "edit") ? true : false}
            onChange={(e) => {
              let data = [...props.data];
              data[props.row.index] = {
                ...data[props.row.index],
                status: e.target.value,
              };
              setProduct(data);
            }}
            className="bg-white border-2 w-32 rounded-md"
          >
            <option value={1} selected={props.value === 'Active'}>
              Active
            </option>
            <option value={0} selected={props.value === 'Draft'}>
              Draft
            </option>
            <option value={2} selected={props.value === 'Rejected'}>
              Rejected
            </option>
            <option value={3} selected={props.value === 'Archived'}>
              Archived
            </option>
            <option value={4} selected={props.value === 'Probation'}>
              Probation
            </option>
          </select>
        );
      },
      isCheck: true,
      show: true,
    },
    {
      Header: "Action",
      Cell: (props) => {
        return (
          checkAccess(17, "edit") && (
            <FiSave
              size={20}
              className="cursor-pointer mt-1 ml-4"
              onClick={() => {
                dispatch(
                  updateVendorProducts({
                    params: {
                      id: props.row.original.id,
                      status: props.row.original.status,
                    },
                    onSuccess: (data) => {
                      toast.success("Product Updated!");
                      getProductList(1);
                    },
                    onFailed: () => {
                      toast.error("Something went wrong!");
                    },
                  })
                );
              }}
            />
          )
        );
      },
      isCheck: true,
      show: true,
      skip: true
    },
  ]);

  const getProductList = ( pageOveride ) => {
    setLoading(true);
    dispatch(
      vendorProductsAction({
        params: {
          limit: limit,
          search: search,
          status: summary,
          pageNumber: pageOveride ?? page,
          productcategory: catname,
          name: prodName,
          vendorId: showVendorProducts ? vendorId : ''
        },
        onSuccess: (data) => {
          const temp = data.docs.map((x, i) => ({
            ...x,
            no: (data.total - (limit * (page - 1))) - i,
            price: formatCurrency(x.productPrice.price ?? 0),
            minPrice: formatCurrency(x.minPricing.minPrice ?? 0),
            maxPrice: formatCurrency(x.maxPricing.maxPrice ?? 0),
            unitCount: x.productPrice?.priceMetric ?? '',
            listingPrice: formatCurrency(x.productPrice.priceWithGST ?? 0),
            costPerItem: formatCurrency(x.productCostPerItem.costPerItem ?? 0),
            tax: x.productPrice.gst ? formatCurrency(x.productPrice.price * ((x.productPrice.gst ?? 0) / 100)) : 0,
            vendorId: x.vendorId.vendorId,
            vendorName: x.vendorId.vendorName,
            productTypeIdName: x.productTypeId?.name ?? '',
            gstType: gstTypes[+x.productPrice.gstType],
            enableMinMaxRule: x?.enableMinMaxRule ? "Yes" : "No",
            minQty: x.minQtyOrder.minQty,
            maxQty: x.maxQtyOrder.maxQty,
            custom: x?.custom ? "Yes" : "No",
            featuredProduct: x?.featuredProduct ? "Yes" : "No",
            publishImmediatelyDate: x.publishImmediatelyDate ? moment(x.publishImmediatelyDate, 'YYYY-MM-DD').format('DD-MMM-YYYY') : '-',
            productCategory: x?.productCategory ? Object.values(x?.productCategory).map((item) => item?.name) : null,
            status: getProductStatus(x.status ?? '')
          }))
          setProduct(temp);
          setLoading(false);
          setPaginationObject({
            limit: data.limit,
            page: data.page,
            pages: data.pages,
            total: data.total,
          });
        },
        onFailed: () => { },
      })
    );
  };

  const showAll = () => {
    setShowVendorProducts(false);
    setSummary('');
    getProductList(1);
  }

  useEffect(() => {
    getProductList();
  }, [limit, search, summary, page, catname, prodName]);

  return (
    <>
      <SectionTitle title="Manage Products" subtitle="Products" />
      <div className="p-2 bg-white border rounded-md">
        <div className="flex justify-between res_button">
          <div className="flex p-3 res_flex_1">
            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="All"
            />
            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="Active"
            />

            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="Draft"
            />

            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="Rejected"
            />

            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="Probation"
            />
            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="Archived"
            />

            <div className="flex border-2 rounded-md hover:border-blue-500 w-full mr-3">
              <svg
                aria-hidden="true"
                className="flex justify-self-center self-center focus:w-5 h-5 text-gray-500 dark:text-gray-400 ml-2 text-grey-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 35 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
              <input
                type="Search"
                placeholder="Search"
                className="w-96 width_99"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              ></input>
            </div>
          </div>

          <div className="flex p-3">
            <div ref={refFilter}>
              <CsvDowloader columns={columns} data={product} filename="products" />
              {vendorId &&
                <button
                  onClick={showAll}
                  className="bg-white hover:bg-grey-200 py-2 px-4 mr-2 rounded shadow-sm"
                >
                  Show All Products
                </button>
              }
              <button
                onClick={() => setShowFilter(!showFilter)}
                className="bg-white hover:bg-grey-200 py-2 px-4 mr-2 rounded shadow-sm"
              >
                Filter
              </button>

              {showFilter && (
                <div className="relative">
                  <div
                    style={{ left: "-80px" }}
                    className="absolute shadow-lg bottom-20 bg-white rounded-md z-10 px-2 py-2"
                  >
                    <div>Filter By</div>
                    {filters.map((item, index) => (
                      <>
                        <div className="flex space-x-2 items-center">
                          <input type="input" value={Object.keys(item)[0]} className="bg-white w-32 mt-2 py-1 rounded-md" />
                          <input
                            type="input"
                            value={Object.values(item)[0]}
                            onChange={(e) => {
                              const updatedfilter = [...filters];
                              updatedfilter[index] = { [Object.keys(updatedfilter[index])[0]]: e.target.value, };
                              let object = Object.assign( {}, ...Object.values(updatedfilter) );
                              if ( Object.keys(updatedfilter[index])[0] === "name" ) {
                                setProdName(object.name);
                              } else if ( Object.keys(updatedfilter[index])[0] === "catname" ) {
                                setCatName(object.catname);
                              }
                              setFilter(updatedfilter);
                            }}
                            className="bg-white border-2 w-32 mt-2 py-1 rounded-md"
                          />
                          {
                            <AiOutlineClose
                              onClick={() => {
                                const updatedfilter = [...filters];
                                const key = Object.keys(updatedfilter[index])[0];
                                if ( key === "name" ) {
                                  setProdName("")
                                } else if ( key === "catname" ) {
                                  setCatName("");
                                }
                                const update = updatedfilter.filter( (filte) => Object.keys(filte)[0] !== Object.keys(item)[0] );
                                setFilter(update);
                                setAddFilter([]);
                              }}
                              size={15}
                              className="mt-2 cursor-pointer text-grey-60000"
                            />
                          }
                        </div>
                      </>
                    ))}
                    {addFilter.map((_, index) => (
                      <>
                        <FilterItem
                          index={index}
                          setFilter={setFilter}
                          filters={filters}
                          setAddFilter={setAddFilter}
                          onRemovePress={() => {
                            const newData = [...addFilter];
                            newData.pop();
                            setAddFilter(newData);
                          }}
                        />
                      </>
                    ))}
                    {
                      <p
                        onClick={() => setAddFilter([...addFilter, 1])}
                        className="mt-2 font-semibold cursor-pointer text-blue-500"
                      >
                        + Add more filter
                      </p>
                    }
                  </div>
                </div>
              )}
            </div>
            <div className="flex ">
              <div ref={refColumns}>
                <button
                  onClick={() => setShowColumns(!showColumns)}
                  className="bg-white hover:bg-grey-200 py-2 px-4 mr-2 rounded shadow-sm"
                >
                  Columns
                </button>

                {showColumns && (
                  <div className="relative">
                    <div className=" absolute shadow-lg right-0 bottom-20 bg-white rounded-md z-10">
                      {columns.map((item, index) => (
                        <div key={index}>
                          <MenuCheckBox
                            title={item.Header}
                            isCheck={item.isCheck}
                            setCheck={(index) => {
                              const newData = [...columns];
                              newData[index].isCheck = !newData[index].isCheck;
                              newData[index].show = !newData[index].show;

                              setColumns(newData);
                            }}
                            index={index}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {checkAccess(17, "edit") && (
              <div ref={ref}>
                <button
                  onClick={() => setShow(!show)}
                  className="bg-white hover:bg-grey-200 py-2 px-4 rounded shadow-sm"
                >
                  Bulk Action
                </button>

                {show && (
                  <div className="relative">
                    <div className=" absolute shadow-lg right-0 bottom-20 bg-white w-48 rounded-md z-10">
                      <Menu
                        title="Remove Products"
                        onClick={() => {
                          setShow(!show);
                          if (ids.length === 0) {
                            return toast.warning("Please select item first");
                          }
                          dispatch(
                            removeProductAction({
                              ids,
                              onSuccess: () => {
                                getProductList(1);
                              },
                              onFailed: () => { },
                            })
                          );
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {product.length !== 0 && (
          <Simple
            setIds={setIds}
            setPage={setPage}
            setLimit={setLimit}
            paginationObject={paginationObject}
            Loading={Loading}
            product={product}
            columns={columns}
          />
        )}
      </div>
    </>
  );
};
export default Index;
