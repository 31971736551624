import React, { useState } from "react";
import SectionTitle from "../components/section-title";
import { Link, Redirect } from "react-router-dom";
import { UnderlinedTabs } from "../components/tabs";
import AccountSettings from "../components/sample-forms/account-settings";
import SecuritySettings from "../components/sample-forms/security-settings";
import Widget from "../components/social-feed/widget";
import { API_UpdateProfile, url } from "../apis/constant";
import axios from "axios";
import { toast } from "react-toastify";
import { getPreviewUrl } from "../utils/common";

const Index = ({ listItem, items }) => {
  const adminToken = localStorage.getItem("wed_admin_token");
  const userId = localStorage.getItem("wed_admin_userId");
  const [valueImg, setValueImg] = useState("");
  const [nameAdmin, setNameAdmin] = useState("");
  const [rolValue, setRoleValue] = useState("");

  //  useEffect(()=> {
  //   if(!adminToken){
  //     history.push("/")
  //     toast.error("Your session has been expired. Please Login Again")
  //   }
  //  }, [history])

  const handleImage = async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const imgSize = e.target.files[0].size / 1024 / 1024;
    if (imgSize > 4) {
      toast.error("Image size is too big to upload");
      return false;
    }
    axios
      .patch(url + API_UpdateProfile + "/" + userId, formData, {
        headers: {
          Authorization: `Bearer ${adminToken}`,
          "Content-Type": "multipart/form-data",
        },
      })

      .then((res) => {
        if (res.data.code === 200) {
          setValueImg(res.data.data.profilePhoto);
          setNameAdmin(res.data.data.name);
          setRoleValue(res.data.data.type);
          toast.success(res.data.message);
          localStorage.setItem("wed_admin_profilePhoto", res.data.data.profilePhoto);
        } else if (res.data.code === 400) {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
      });
  };

  const tabs = [
    {
      index: 0,
      title: "Account settings",
      content: (
        <div className="py-4 w-full lg:w-1/2">
          <AccountSettings
            setValueImg={setValueImg}
            setNameAdmin={setNameAdmin}
            setRoleValue={setRoleValue}
          />
        </div>
      ),
    },

    {
      index: 2,
      title: "Security settings",
      content: (
        <div className="py-4 w-full lg:w-1/2">
          <SecuritySettings />
        </div>
      ),
    },
  ];

  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
    return (
      <>
        <SectionTitle title="" subtitle="User profile" />

        <Widget>
          <div className="flex flex-row items-center justify-start p-4">
            <div className="flex-shrink-0 w-24">
              {valueImg && (
                <img
                  src={getPreviewUrl(valueImg)}
                  alt="media"
                  className="rounded-full h-20 w-20 shadow-outline mb-2"
                />
              )}
            </div>

            <div className="py-2 px-2">
              <p className="text-base font-bold whitespace-no-wrap">
                {nameAdmin}
              </p>
              <p className="text-sm text-grey-500 whitespace-no-wrap">
                {rolValue}
              </p>
              <div className="flex flex-row items-center justify-start w-full py-1 space-x-2">
                <div className="image-upload">
                  <label htmlFor="file-input">
                    <img
                      src="https://icons.iconarchive.com/icons/hopstarter/soft-scraps/256/Button-Upload-icon.png"
                      title="Choose a photo!"
                      alt="choose"
                      style={{ width: "30px" }}
                    />
                  </label>

                  <input
                    id="file-input"
                    type="file"
                    value=""
                    style={{ display: "none" }}
                    onChange={(e) => handleImage(e)}
                  />
                </div>
              </div>
            </div>
            <div className="addBackButton">
              <Link
                to={"/dashboard"}
                className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
              >
                Back
              </Link>
            </div>
          </div>

          <div className="flex flex-wrap">
            <div className="w-full p-4">
              <UnderlinedTabs tabs={tabs} />
            </div>
          </div>
        </Widget>
      </>
    );
  }
};
export default Index;
