import React, { useMemo } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import Loader from "../custom-loader/loader";

const AnomalyOrderPageData = ({
  columns,
  data = [],
  loading,
}) => {

  const tableColumns = useMemo(() => {
    return columns.filter((column) => column.show === true);
  }, [columns]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
  } = useTable(
    {
      columns: tableColumns,
      data,
      loading,
      initialState: { pageIndex: 0, pageSize: 9999999 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="table-responsive">
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="flex flex-row items-center justify-start">
                      <span>{column.render("Header")}</span>
                      {/* Add a sort direction indicator */}
                      <span className="ml-auto">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FiChevronDown className="stroke-current text-2xs" />
                          ) : (
                            <FiChevronUp className="stroke-current text-2xs" />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {loading ? (
              <tr>
                <td colSpan={51}>
                  <Loader />
                </td>
              </tr>
            ) : page.length !== 0 ? (
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    <>
                      {row.cells.map((cell) => {
                        return (
                          <>
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          </>
                        );
                      })}
                    </>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={51} style={{ textAlign: "center" }}>
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AnomalyOrderPageData;
