/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link, useParams, Redirect } from "react-router-dom";
import {
  url,
  API_MasterCategoryList,
  API_GetVendorById,
  API_UpdateVendorById,
  API_ActDeactVendor,
} from "../../apis/constant";
import ApiClient from "../../apis/apiClient";
import Select from "react-select";
import axios from "axios";
import { toast } from "react-toastify";
import countrycodes from "./CountryCode.json";
import ModalActivate from "../../components/confirm-activate-modal/modal-activate";
import { FiX } from "react-icons/fi";

const EditVendorData = () => {
  const adminToken = localStorage.getItem("wed_admin_token");
  const history = useHistory();
  const [isSubmit, setIsSubmit] = useState(false);
  const [nameVendor, setVendorName] = useState("");
  const [getCategory, setGetCategory] = useState([]);
  const [items, setItems] = useState();
  const [getId, setGetId] = useState("");
  const [getReview, setGetReview] = useState("");
  const [emailAdd, setEmailAdd] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyReg, setCompanyReg] = useState("");
  const [website, setWebsite] = useState("");
  const [masterCat, setMasterCat] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [blockNumber, setBlockNumber] = useState("");
  const [unitNumber, setUnitNumber] = useState("");
  const [building, setBuilding] = useState("");
  const [streetAdd1, setStreetAdd1] = useState("");
  const [streetAdd2, setStreetAdd2] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postCode, setPostCode] = useState("");
  const [comment, setComment] = useState("");
  const [yearsHistory, setYearsHistory] = useState("");
  const [closedCompany, setClosedCompany] = useState("");
  const [caseReview, setCaseReview] = useState("");
  const [badPressCovorage, setBadPressCovorage] = useState("");
  const [badReviewComplaints, setBadReviewComplaints] = useState("");
  const [vendorNameError, setVendorNameError] = useState("");
  const [emailAddError, setEmailAddError] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [companyRegError, setCompanyRegError] = useState("");
  const [websiteError, setWebsiteError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [countryCodeError, setCountryCodeError] = useState("");
  const [blockNumberError, setBlockNumberError] = useState("");
  const [unitNumberError, setUnitNumberError] = useState("");
  const [buildingError, setBuildingError] = useState("");
  const [streetAdd1Error, setStreetAdd1Error] = useState("");
  const [streetAdd2Error, setStreetAdd2Error] = useState("");
  const [countryError, setCountryError] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const [postCodeError, setPostCodeError] = useState("");
  const [yearsError, setYearsError] = useState("");
  const [closedCompanyError, setClosedCompanyError] = useState("");
  const [caseReviewError, setCaseReviewError] = useState("");
  const [badPressError, setBadPressError] = useState("");
  const [badReviewError, setBadReviewError] = useState("");
  const [commentError, setCommentError] = useState("");

  const alphanumericRegex = /^[a-zA-Z0-9-. ]+$/;
  const emailRegex =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const textReg = /^[A-Za-z]*$/;
  const phoneReg = /^[0-9]{10}$/;

  const categoryD = useRef([]);
  const { id } = useParams();

  useEffect(() => {
    getAllCategory();
    getVendorById();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value, type) => {
    if (type === "name") {
      if (value && !alphanumericRegex.test(value)) {
        setVendorNameError("Please enter a valid vendor name");
      } else {
        setVendorNameError("");
      }
      setVendorName(value);
    } else if (type === "email") {
      if (emailRegex.test(value) === false) {
        setEmailAddError("Please enter a valid email address");
      } else {
        setEmailAddError("");
      }
      setEmailAdd(value);
    } else if (type === "company-name") {
      if (value && !alphanumericRegex.test(value)) {
        setCompanyNameError("Please enter a valid comany name");
      } else {
        setCompanyNameError("");
      }
      setCompanyName(value);
    } else if (type === "company-registration") {
      if (value && !alphanumericRegex.test(value)) {
        setCompanyRegError("Please enter a valid comany registration name");
      } else {
        setCompanyRegError("");
      }
      setCompanyReg(value);
    } else if (type === "website") {
      setWebsite(value);
    } else if (type === "category") {
      setGetCategory(value);
    } else if (type === "country-code") {
      setCountryCode(value);
    } else if (type === "phone") {
      if (value.length > 10 || value.length < 10) {
        setPhoneError("Phone number must be 8 digit.");
        setIsSubmit(false);
      } else {
        setPhoneError("");
      }
      setPhone(value);
    } else if (type === "block-number") {
      if (value && !alphanumericRegex.test(value)) {
        setBlockNumberError("Please enter a valid block number");
      } else {
        setBlockNumberError("");
      }
      setBlockNumber(value);
    } else if (type === "unit-number") {
      if (value && !alphanumericRegex.test(value)) {
        setUnitNumberError("Please enter a valid unit number");
      } else {
        setUnitNumberError("");
      }
      setUnitNumber(value);
    } else if (type === "building-name") {
      if (value && !alphanumericRegex.test(value)) {
        setBuildingError("Please enter a valid building name");
      } else {
        setBuildingError("");
      }
      setBuilding(value);
    } else if (type === "street-add-1") {
      setStreetAdd1(value);
    } else if (type === "street-add-2") {
      setStreetAdd2(value);
    } else if (type === "country") {
      if (!textReg.test(value)) {
        setCountryError("Please enter a valid country name");
      } else {
        setCountryError("");
      }
      setCountry(value);
    } else if (type === "city") {
      if (!textReg.test(value)) {
        setCityError("Please enter a valid city name");
      } else {
        setCityError("");
      }
      setCity(value);
    } else if (type === "state") {
      if (!textReg.test(value)) {
        setStateError("Please enter a valid state name");
      } else {
        setStateError("");
      }
      setState(value);
    } else if (type === "postcode") {
      if (value.match(/\d/g).length < 6 || value.match(/\d/g).length > 6) {
        setPostCodeError("Please enter a valid postcode");
      } else {
        setPostCodeError("");
      }
      setPostCode(value);
    } else if (type === "comments") {
      setComment(value);
    } else if (type === "years-history") {
      setYearsHistory(value);
    } else if (type === "closed-company") {
      setClosedCompany(value);
    } else if (type === "caseReview") {
      setCaseReview(value);
    } else if (type === "Bad-Press-Coverag") {
      setBadPressCovorage(value);
    } else if (type === "Bad-Reviews-Complaints") {
      setBadReviewComplaints(value);
    }
  };

  const getVendorById = () => {
    setGetId(id);
    axios
      .get(url + API_UpdateVendorById + "/" + id, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((res) => {
        if (res.data.code === 200) {
          setVendorName(res.data.data.vendorName);
          setEmailAdd(res.data.data.email);
          setCompanyName(res.data.data.companyName);
          setCompanyReg(res.data.data.companyRegistrationNumber);
          setWebsite(res.data.data.website);
          setPhone(res.data.data.phone);
          setCountryCode(res.data.data.countryCode);
          setBlockNumber(res.data.data.blockNumber);
          setUnitNumber(res.data.data.unitNo);
          setBuilding(res.data.data.buildingName);
          setStreetAdd1(res.data.data.streetAddress1);
          setStreetAdd2(res.data.data.streetAddress2);
          setCountry(res.data.data.country);
          setCity(res.data.data.city);
          setState(res.data.data.state);
          setPostCode(res.data.data.postCode);
          setGetReview(res.data.data.review);
          setComment(res.data.data.comment);
          setYearsHistory(res.data.data.fiveYearHistory);
          setClosedCompany(res.data.data.isDirectorOfbankrupt);
          setCaseReview(res.data.data.isCaseReview);
          setBadPressCovorage(res.data.data.isBadPress);
          setBadReviewComplaints(res.data.data.isBadReviews);

          let tempArr = [];

          for (let i = 0; i < res.data.data.categories.length; i++) {
            let element = res.data.data.categories[i];
            tempArr.push({
              label: element.name,
              value: element.id,
            });
          }
          setGetCategory(tempArr);
        }
      })
      .catch((err) => {});
  };

  const handleSubmit = () => {
    let isTrue = false;
    setIsSubmit(true);
    if (nameVendor === "") {
      setVendorNameError("Vendor Name should not be blank");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setVendorNameError("");
    }
    if (emailAdd === "") {
      setEmailAddError("Email should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setEmailAddError("");
    }
    if (companyName === "") {
      setCompanyNameError("Company Name should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setCompanyNameError("");
    }
    if (companyReg === "") {
      setCompanyRegError("Company Registration should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setCompanyRegError("");
    }
    if (website === "") {
      setWebsiteError("Website should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setWebsiteError("");
    }
    if (getCategory === "" || getCategory?.length === 0) {
      setCategoryError("Category should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setCategoryError("");
    }
    if (phone === "" || phone.length > 8 || phone.length < 8) {
      setPhoneError(
        "Phone should not be blank &  can not be less than 8 & greater than 8."
      );
      setIsSubmit(false);
      isTrue = true;
    } else {
      setPhoneError("");
    }
    if (countryCode === "") {
      setCountryCodeError("Country Code should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setCountryCodeError("");
    }
    if (blockNumber === "") {
      setBlockNumberError("Block Number should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setBlockNumberError("");
    }
    if (unitNumber === "") {
      setUnitNumberError("Unit Number should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setUnitNumberError("");
    }
    if (building === "") {
      setBuildingError("Building should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setBuildingError("");
    }
    if (streetAdd1 === "") {
      setStreetAdd1Error("Street Address 1 should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setStreetAdd1Error("");
    }
    if (streetAdd2 === "") {
      setStreetAdd2Error("Street Address 2 should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setStreetAdd2Error("");
    }
    if (country === "") {
      setCountryError("Country should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setCountryError("");
    }
    if (city === "") {
      setCityError("City should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setCityError("");
    }
    if (state === "") {
      setStateError("State should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
    }
    setStateError("");
    if (postCode === "") {
      setPostCodeError("Postcode should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setPostCodeError("");
    }
    if (yearsHistory === "") {
      setYearsError("Please Choose Years History from Radio Button");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setYearsError("");
    }
    if (closedCompany === "") {
      setClosedCompanyError("Please Choose Closed Company from Radio Button");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setClosedCompanyError("");
    }
    if (caseReview === "") {
      setCaseReviewError("Please Choose Case Review from Radio Button");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setCaseReviewError("");
    }
    if (badPressCovorage === "") {
      setBadPressError("Please Choose Bad Press Complaints from Radio Button");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setBadPressError("");
    }
    if (badReviewComplaints === "") {
      setBadReviewError(
        "Please Choose Bad Review Complaints from Radio Button"
      );
      setIsSubmit(false);
      isTrue = true;
    } else {
      setBadReviewError("");
    }
    if (comment === "") {
      setCommentError("Comment should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setCommentError("");
    }

    if (isTrue) {
      return;
    }

    let cat = [];

    for (let i = 0; i < getCategory?.length; i++) {
      cat.push(getCategory[i].value);
    }
    setMasterCat(cat.length);

    let logData = {
      vendorName: nameVendor,
      email: emailAdd,
      companyName: companyName,
      companyRegistrationNumber: companyReg,
      categories: cat,
      phone: phone,
      countryCode: countryCode,
      blockNumber: blockNumber,
      unitNo: unitNumber,
      buildingName: building,
      streetAddress1: streetAdd1,
      streetAddress2: streetAdd2,
      country: country,
      city: city,
      state: state,
      postCode: postCode,
      website: website,
      comment: comment,
      fiveYearHistory: yearsHistory,
      isDirectorOfbankrupt: closedCompany,
      isCaseReview: caseReview,
      isBadPress: badPressCovorage,
      isBadReviews: badReviewComplaints,
    };

    axios
      .put(url + API_UpdateVendorById + id, logData, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((res) => {
        if (res.data.code === 200) {
          toast.success(res.data.message);
          history.push("/vendors");
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsSubmit(false);
      });
  };

  const GetVendor = async () => {
    setGetId(id);
    const data = "";
    const res = await ApiClient.get(
      API_GetVendorById + id,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      var tempArr = [];
      for (let i = 0; i < items.length; i++) {
        const element = items[i];
        if (element.type === "multi-select") {
          element.options = categoryD.current;
          element.value = res.data.data[element.id];
        } else {
          element.value = res.data.data[element.id];
        }
        tempArr.push(element);
      }
      setItems(tempArr);
      setGetReview(res.data.data.review);
    } else {
      toast.warning(res.data.message);
    }
  };

  const getAllCategory = async () => {
    const data = "";
    const res = await ApiClient.get(
      API_MasterCategoryList + `?page=1&sortBy=asc&limit=10&searchBy=`,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      let tempArr = [];
      if (res.data.data && res.data.data.docs.length !== 0) {
        for (let i = 0; i < res.data.data.docs.length; i++) {
          let element = res.data.data.docs[i];
          res.data.data.docs[i]["displayCategorie"] =
            element.name !== null ? element.name : "NA";
          tempArr.push({
            label: element.name,
            value: element.id,
          });
        }
      }
      categoryD.current = tempArr;
    }
  };

  const actDeactivateVendor = async (getStatus) => {
    const id = getId;
    var arrayId = new Array(id);
    const data = {
      ids: arrayId,
      status: getStatus,
    };
    const res = await ApiClient.post(
      API_ActDeactVendor,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      // getAllvendors();
    } else {
      toast.warning(res.data.message);
    }
  };

  //update review
  const handleActive = async (review) => {
    const id = getId;
    let myObj = {
      review: review,
    };

    axios
      .put(url + API_UpdateVendorById + id, myObj, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((res) => {
        if (res.data.code === 200) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      });
  };

  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
  return (
    <>
      <div className="addBack" style={{ marginTop: "15px" }}>
        <Link
          to={"/vendors"}
          className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
        >
          Back
        </Link>
      </div>
      <div className="flex_box edit_vendor_box">
        <div className="form-element">
          <label>Name</label>
          <input
            type="text"
            name="name"
            placeholder="type Name..."
            value={nameVendor}
            className="inputFields"
            id=""
            onChange={(e) => handleChange(e.target.value, "name")}
            style={{ marginTop: "29px" }}
          />
          {vendorNameError && <p style={{ color: "red" }}>{vendorNameError}</p>}
        </div>

        <div className="form-element">
          <label>Email</label>
          <input
            type="email"
            name="email"
            placeholder="type email..."
            value={emailAdd}
            className="inputFields"
            id=""
            onChange={(e) => handleChange(e.target.value, "email")}
            style={{ marginTop: "29px" }}
          />
          {emailAddError && <p style={{ color: "red" }}>{emailAddError}</p>}
        </div>
      </div>

      <div className="flex_box edit_vendor_box">
        <div className="form-element">
          <label>Company Name</label>
          <input
            type="text"
            name="company-name"
            placeholder="type Company Name..."
            value={companyName}
            className="inputFields"
            id=""
            onChange={(e) => handleChange(e.target.value, "company-name")}
            style={{ marginTop: "29px" }}
          />
          {companyNameError && (
            <p style={{ color: "red" }}>{companyNameError}</p>
          )}
        </div>

        <div className="form-element">
          <label>Company Registration</label>
          <input
            type="text"
            name="company-registration"
            placeholder="type Company Registration..."
            value={companyReg}
            className="inputFields"
            id=""
            onChange={(e) =>
              handleChange(e.target.value, "company-registration")
            }
            style={{ marginTop: "29px" }}
          />
          {companyRegError && <p style={{ color: "red" }}>{companyRegError}</p>}
        </div>
      </div>

      <div className="flex_box edit_vendor_box">
        <div className="form-element">
          <label>Website</label>
          <input
            type="text"
            name="website"
            placeholder="type website..."
            value={website}
            className="inputFields"
            id=""
            onChange={(e) => handleChange(e.target.value, "website")}
            style={{ marginTop: "29px" }}
          />
          {websiteError && <p style={{ color: "red" }}>{websiteError}</p>}
        </div>

        <div className="form-element">
          <div className="form-element" style={{ width: "100%" }}>
            <div className="form-label">Level 1</div>

            <div className="flex flex-wrap w-full">
              <div
                className="p-0 web_level_box"
                style={{ width: "100%", margin: "29px 0px 8px 0px" }}
              >
                <Select
                  options={categoryD.current}
                  name="category"
                  id=""
                  value={getCategory}
                  onChange={(value) => handleChange(value, "category")}
                  isMulti={true}
                  placeholder="Select multiple..."
                />
              </div>
            </div>
            {categoryError && <div className="form-error">{categoryError}</div>}
          </div>
        </div>
      </div>

      <div className="flex_box edit_vendor_box">
        <div className="form-element ">
          <label>Country Code</label>
          <select
            onChange={(e) => handleChange(e.target.value, "country-code")}
            name="country-code"
            value={countryCode}
            id=""
            style={{ margin: "28px 0px 8px 0px" }}
            className="inputFields"
          >
            <option value="">Select...</option>
            {countrycodes?.map((mcat, idx) => (
              <option key={idx} value={mcat?.dial_code}>
                {mcat?.dial_code}
                {mcat?.code}
              </option>
            ))}
          </select>
          {countryCodeError && (
            <p style={{ color: "red" }}>{countryCodeError}</p>
          )}
        </div>

        <div className="form-element">
          <label>Phone</label>
          <input
            type="number"
            name="phone"
            placeholder="type phone..."
            value={phone}
            className="inputFields"
            id=""
            onChange={(e) => handleChange(e.target.value, "phone")}
            style={{ marginTop: "29px" }}
          />
          {phoneError && <p style={{ color: "red" }}>{phoneError}</p>}
        </div>
      </div>

      <div className="flex_box edit_vendor_box">
        <div className="form-element">
          <label>Block Number</label>
          <input
            type="text"
            name="block-number"
            placeholder="type block number..."
            value={blockNumber}
            className="inputFields"
            id=""
            onChange={(e) => handleChange(e.target.value, "block-number")}
            style={{ marginTop: "29px" }}
          />
          {blockNumberError && (
            <p style={{ color: "red" }}>{blockNumberError}</p>
          )}
        </div>

        <div className="form-element">
          <label>Unit Number</label>
          <input
            type="text"
            name="unit-number"
            placeholder="type unit number..."
            value={unitNumber}
            className="inputFields"
            id=""
            onChange={(e) => handleChange(e.target.value, "unit-number")}
            style={{ marginTop: "29px" }}
          />
          {unitNumberError && <p style={{ color: "red" }}>{unitNumberError}</p>}
        </div>
      </div>

      <div className="flex_box edit_vendor_box">
        <div className="form-element">
          <label>Building Name</label>
          <input
            type="text"
            name="building-name"
            placeholder="type building Name..."
            value={building}
            className="inputFields"
            id=""
            onChange={(e) => handleChange(e.target.value, "building-name")}
            style={{ marginTop: "29px" }}
          />
          {buildingError && <p style={{ color: "red" }}>{buildingError}</p>}
        </div>

        <div className="form-element">
          <label>Street Address 1</label>
          <input
            required
            type="text"
            name="street-add-1"
            placeholder="type street address 1..."
            value={streetAdd1}
            className="inputFields"
            id=""
            onChange={(e) => handleChange(e.target.value, "street-add-1")}
            style={{ marginTop: "29px" }}
          />
          {streetAdd1Error && <p style={{ color: "red" }}>{streetAdd1Error}</p>}
        </div>
      </div>

      <div className="flex_box edit_vendor_box">
        <div className="form-element">
          <label>Street Address 2</label>
          <input
            type="text"
            name="street-add-2"
            placeholder="type street address 2 ..."
            value={streetAdd2}
            className="inputFields"
            id=""
            onChange={(e) => handleChange(e.target.value, "street-add-2")}
            style={{ marginTop: "29px" }}
          />
          {streetAdd2Error && <p style={{ color: "red" }}>{streetAdd2Error}</p>}
        </div>

        <div className="form-element">
          <label>Country</label>
          <input
            type="text"
            name="country"
            placeholder="type country..."
            value={country}
            className="inputFields"
            id=""
            onChange={(e) => handleChange(e.target.value, "country")}
            style={{ marginTop: "29px" }}
          />
          {countryError && <p style={{ color: "red" }}>{countryError}</p>}
        </div>
      </div>

      <div className="flex_box edit_vendor_box">
        <div className="form-element">
          <label>City</label>
          <input
            type="text"
            name="city"
            placeholder="type city..."
            value={city}
            className="inputFields"
            id=""
            onChange={(e) => handleChange(e.target.value, "city")}
            style={{ marginTop: "29px" }}
          />
          {cityError && <p style={{ color: "red" }}>{cityError}</p>}
        </div>

        <div className="form-element">
          <label>State</label>
          <input
            type="text"
            name="state"
            placeholder="type state..."
            value={state}
            className="inputFields"
            id=""
            onChange={(e) => handleChange(e.target.value, "state")}
            style={{ marginTop: "29px" }}
          />
          {stateError && <p style={{ color: "red" }}>{stateError}</p>}
        </div>
      </div>

      <div className="flex_box years_history">
        <div className="form-element">
          <label>Post Code</label>
          <input
            type="text"
            name="postcode"
            placeholder="type postcode..."
            value={postCode}
            className="inputFields"
            id=""
            onChange={(e) => handleChange(e.target.value, "postcode")}
            style={{ marginTop: "29px" }}
          />
          {postCodeError && <p style={{ color: "red" }}>{postCodeError}</p>}
        </div>

        <div>
          <p>5 years history:</p>
          <input
            required
            type="radio"
            id="yes"
            name="history"
            checked={yearsHistory === true}
            value="YES"
            // onChange={(e) => handleChange(e.target.value, "years-history")}
            onChange={(e) => {
              handleChange(e.target.value);
              setYearsHistory(true);
            }}
          />
          <label>Yes</label>
          {"   "}
          <input
            type="radio"
            required
            id="no"
            name="history"
            checked={yearsHistory === false}
            value="No"
            // onChange={(e) => handleChange(e.target.value, "years-history")}
            onChange={(e) => {
              handleChange(e.target.value);
              setYearsHistory(false);
            }}
          />
          <label>No</label>
          {yearsError && <p style={{ color: "red" }}>{yearsError}</p>}
        </div>
      </div>

      <div className="flex_box seprate-radio">
        <div className="spearte_inner">
          <p>Previously director of a bankrupt or closed company?</p>
          <input
            type="radio"
            id="yes"
            name="closed-company"
            checked={closedCompany === true}
            value="YES"
            // onChange={(e) => handleChange(e.target.value, "closed-company")}
            onChange={(e) => {
              handleChange(e.target.value);
              setClosedCompany(true);
            }}
          />
          <label>Yes</label>
          {"   "}
          <input
            type="radio"
            id="no"
            name="closed-company"
            checked={closedCompany === false}
            value="No"
            // onChange={(e) => handleChange(e.target.value, "closed-company")}
            onChange={(e) => {
              handleChange(e.target.value);
              setClosedCompany(false);
            }}
          />
          <label>No</label>
          {closedCompanyError && (
            <p style={{ color: "red" }}>{closedCompanyError}</p>
          )}
        </div>

        <div className="spearte_inner">
          <p>Involved in CASE review?</p>
          <input
            type="radio"
            id="yes"
            name="case-review"
            checked={caseReview === true}
            value="YES"
            // onChange={(e) => handleChange(e.target.value, "caseReview")}
            onChange={(e) => {
              handleChange(e.target.value);
              setCaseReview(true);
            }}
          />
          <label>Yes</label>
          {"   "}
          <input
            type="radio"
            id="no"
            name="case-review"
            checked={caseReview === false}
            value="No"
            // onChange={(e) => handleChange(e.target.value, "caseReview")}
            onChange={(e) => {
              handleChange(e.target.value);
              setCaseReview(false);
            }}
          />
          <label>No</label>
          {caseReviewError && <p style={{ color: "red" }}>{caseReviewError}</p>}
        </div>
      </div>

      <div className="flex_box seprate-radio">
        <div className="spearte_inner">
          <p>Bad Press Coverage</p>
          <input
            type="radio"
            id="yes"
            name="bad-press"
            checked={badPressCovorage === true}
            value="YES"
            // onChange={(e) => handleChange(e.target.value, "Bad-Press-Coverag")}
            onChange={(e) => {
              handleChange(e.target.value);
              setBadPressCovorage(true);
            }}
          />
          <label>Yes</label>
          {"   "}
          <input
            type="radio"
            id="no"
            name="bad-press"
            checked={badPressCovorage === false}
            value="No"
            // onChange={(e) => handleChange(e.target.value, "Bad-Press-Coverag")}
            onChange={(e) => {
              handleChange(e.target.value);
              setBadPressCovorage(false);
            }}
          />
          <label>No</label>
          {badPressError && <p style={{ color: "red" }}>{badPressError}</p>}
        </div>

        <div className="spearte_inner">
          <p>Bad Reviews/Complaints</p>
          <input
            type="radio"
            id="yes"
            name="bad-review"
            checked={badReviewComplaints === true}
            value="YES"
            // onChange={(e) =>handleChange(e.target.value, "Bad-Reviews-Complaints")}
            onChange={(e) => {
              handleChange(e.target.value);
              setBadReviewComplaints(true);
            }}
          />
          <label>Yes</label>
          {"   "}
          <input
            type="radio"
            id="no"
            name="bad-review"
            checked={badReviewComplaints === false}
            value="No"
            // onChange={(e) =>handleChange(e.target.value, "Bad-Reviews-Complaints")}
            onChange={(e) => {
              handleChange(e.target.value);
              setBadReviewComplaints(false);
            }}
          />
          <label>No</label>
          {badReviewError && <p style={{ color: "red" }}>{badReviewError}</p>}
        </div>
      </div>

      <div className="form-element">
        <p>
          <label>Comment</label>
        </p>
        <textarea
          value={comment}
          id="comment"
          name="comment"
          rows="4"
          cols="50"
          onChange={(e) => handleChange(e.target.value, "comments")}
          style={{ padding: "15px" }}
        >
          {commentError && <p style={{ color: "red" }}>{commentError}</p>}{" "}
        </textarea>
      </div>

      <div className="btn-box edit_vendor_btnBox">
        <div className="flex flex-col ">
          <div className="">
            {isSubmit ? (
              <button className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded">
                Loading...
              </button>
            ) : (
              <button
                className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
                onClick={() => handleSubmit()}
              >
                Submit
              </button>
            )}
          </div>
        </div>
        <ModalActivate
          title="Reject"
          icon={
            <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
              <FiX size={18} className="stroke-current text-red-500" />
            </span>
          }
          body={
            <div className="text-sm text-grey-500">
              Are You Sure! You want to reject this vendor?
            </div>
          }
          buttonTitle="Confirm"
          onClick={(e) => {
            actDeactivateVendor("deactivate");
          }}
          buttonClassName="btn btn-default btn-rounded bg-red-500 hover:bg-red-600 text-white ml-2"
        />
        <ModalActivate
          title="Approve"
          icon={
            <span className="h-10 w-10 bg-blue-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
              <FiX size={18} className="stroke-current text-red-500" />
            </span>
          }
          body={
            <div className="text-sm text-grey-500">
              Are You Sure! You want to approve this vendor?
            </div>
          }
          buttonTitle="Confirm"
          onClick={(e) => {
            actDeactivateVendor("activate");
          }}
          buttonClassName="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white ml-2"
        />

        {getReview === 0 ? (
          <ModalActivate
            title="Review"
            icon={
              <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                <FiX size={18} className="stroke-current text-red-500" />
              </span>
            }
            body={
              <div className="text-sm text-grey-500">
                Are You Sure! You want to Review?
              </div>
            }
            buttonTitle="Confirm"
            onClick={() => {
              handleActive(1);
            }}
            buttonClassName="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white ml-2"
          />
        ) : (
          <ModalActivate
            title="Reviewed"
            icon={
              <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                <FiX size={18} className="stroke-current text-red-500" />
              </span>
            }
            body={<div className="text-sm text-grey-500">Are You Sure?</div>}
            buttonTitle="Confirm"
            onClick={() => {
              handleActive(0);
            }}
            buttonClassName="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white ml-2"
          />
        )}
      </div>
    </>
  );
          }
};

export default EditVendorData;
