import React, { useEffect, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { vendorFormAction } from "../../reducers/vendor.reducer";

const RenderItem = ({ title, index, onSelect, value }) => (
  <div className="flex items-center">
    <p className="w-4">{index + 1}.</p>
    <p className="w-1/2">{title}</p>
    <select
      defaultValue={"true"}
      value={value}
      onChange={(e) => onSelect(e.target.value === "true")}
      className="w-20 px-1 border-2 bg-white"
    >
      <option value="true">Yes</option>
      <option value="false">No</option>
    </select>
  </div>
);

const FormSettings = ({ isNew }) => {
  const vendorForm = useSelector((state) => state.vendorSlice.vendorForm);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isNew) return;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeForm = (title, value) => {
    dispatch(
      vendorFormAction({
        ...vendorForm,
        settings: {
          ...vendorForm.settings,
          [title]: value,
        },
      })
    );
  };

  return (
    <div className="md:mt-10 mt-5 animate-fade-in-down">
      <div className="space-y-4">
        {[
          { title: "Enable Selling", name: "enableSelling" },
          { title: "Publish Product Directly", name: "publishProductDirectly" },
          { title: "Make Vendor Featured", name: "makeVendorFeatured" },
        ].map((item, index) => (
          <RenderItem
            title={item.title}
            index={index}
            onSelect={(e) => handleChangeForm(item.name, e)}
            value={
              vendorForm.settings ? vendorForm.settings[item.name] : "true"
            }
          />
        ))}
      </div>
    </div>
  );
};

const Settings = ({ isNew }) => {
  const [openForm, setOpenForm] = useState(false);

  return (
    <div className="w-full shadow-xl px-5 py-5 bg-white">
      <div
        onClick={() => setOpenForm(!openForm)}
        className="flex justify-between cursor-pointer"
      >
        <p className="text-xl">Settings</p>
        <BsChevronDown
          size={25}
          className={
            "text-bg-primary " +
            (openForm ? "rotate-180 transition-all" : "rotate-0 transition-all")
          }
        />
      </div>
      {openForm && <FormSettings isNew={isNew} />}
    </div>
  );
};

export default Settings;
