import React, { useEffect, useState } from "react";
import Validation from "../forms/validation-login";
import Alert from "../alerts";
import ApiClient from "./../../apis/apiClient";
import { API_LOGIN } from "../../apis/constant";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { tokenAction } from "../../reducers/auth.reducer";

const Login = ({ message = null }) => {
  // const [data, onSubmit] = useState(null)
  const dispatch = useDispatch();
  const [isSubmit, setIsSubmit] = useState(false);
  const history = useHistory();

  const onSubmit = (data) => {
    setIsSubmit(true);
    let logData = {
      email: data?.email,
      password: data?.password,
    };
    ApiClient.post(
      API_LOGIN,
      logData,
      "Basic Z29ndWFyZDpnb2d1YXJkQDEyMzQ1"
    ).then((res) => {
      if (res.data.code === 200) {
        setIsSubmit(false);

        toast.success(res.data.message);
        localStorage.setItem("wed_admin_token", res.data.data.tokens.refresh.token);
        localStorage.setItem("wed_admin_userId", res.data.data.admin.id);
        localStorage.setItem('wed_admin_adata', JSON.stringify(res.data.data.admin))
        localStorage.setItem("wed_admin_profilePhoto", res.data.data.admin.profilePhoto);
        dispatch(tokenAction(res.data.data.tokens));

        history.push("/dashboard");
      } else {
        setIsSubmit(false);

        toast.error(res.data.message);
      }
    });
  };

  let items = [
    {
      label: "Email",
      error: {
        required: "Email is required",
      },
      name: "email",
      type: "email",
      inputType: "login",
      placeholder: "Enter you email",
    },
    {
      label: "Password",
      error: {
        required: "Password is required",
        // minLength: {
        //   value: 4,
        //   message: 'Your password should have at least 4 characters'
        // },
        // maxLength: {
        //   value: 10,
        //   message: 'Your password should have no more than 8 characters'
        // }
      },
      // maxLength: {
      //     value: 10,
      //     message: 'Your password should have no more than 8 characters'
      //   },
      name: "password",
      type: "password",
      inputType: "login",
      placeholder: "Enter your password",
    },
  ];

  useEffect(() => {
    if (localStorage.getItem("wed_admin_token")) {
      history.push('/dashboard');
      return;
    }
  })
  return (
    <>
      <div className="flex flex-col">
        {message && (
          <div className="w-full mb-4">
            <Alert
              color="bg-transparent border-green-500 text-green-500"
              borderLeft
              raised
            >
              {message}
            </Alert>
          </div>
        )}
        <Validation items={items} onSubmit={onSubmit} isSubmit={isSubmit} />
      </div>
    </>
  );
};

export default Login;
