/* eslint-disable no-unreachable */
import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../utils/createApi";
import { logoutAction } from "./auth.action";

export const uploadMediaAction = createAsyncThunk("chat/uploadMediaInChat", async ({ onSuccess, onProgress, onFailed, data }, { getState }) => {
  var fd = new FormData();
  fd.append("roomId", data.roomId);
  fd.append("type", data.type);
  fd.append("senderId", data.senderId);
  for (let I = 0; I < data.files.length; I++) {
    const file = data.files[I];
    fd.append(`file`, file);
  }

  try {
    const token = localStorage.getItem("wed_admin_token");
    const result = await API.post(`/room/image-upload`, fd,
      {
        onUploadProgress: (progressEvent) => {
          onProgress((progressEvent.loaded / progressEvent.total) * 100);
        },
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (result.data.code === 200) {
      onSuccess(result.data)
    } else {
      onFailed(result.data)
    }
  } catch (err) {
    onFailed();
    throw new Error(err);
  }
});

export const getRooms = createAsyncThunk(
  "chat/getRooms",
  async ({ onSuccess, onFailed, data }, { getState, dispatch }) => {
    const token = localStorage.getItem("wed_admin_token");
    try {
      const result = await API.post(
        `/room/roomsList`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result.data.code === 401) {
        dispatch(logoutAction());
        onFailed(result.data)
        return;
      }
      if (result.data.code === 200) {
        onSuccess(result.data.rooms)
      } else {
        onFailed(result.data)
      }
      return result;
    } catch (err) {
      onFailed();
      throw new Error(err);
    }
  }
);

export const getChats = createAsyncThunk(
  "chat/getChats",
  async ({ onSuccess, onFailed, data }, { getState, dispatch }) => {
    const token = localStorage.getItem("wed_admin_token");
    try {
      const result = await API.post(
        `/room/chatsList`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result.data.code === 401) {
        dispatch(logoutAction());
        onFailed(result.data)
        return;
      }
      if (result.data.code === 200) {
        onSuccess(result.data.chats)
      } else {
        onFailed(result.data)
      }
      return result;
    } catch (err) {
      onFailed();
      throw new Error(err);
    }
  }
);