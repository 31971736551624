import React, { useEffect, useState } from "react";
import SectionTitle from "../components/section-title";
import EVoucherTable from "../components/evoucher";
import Widget from "../components/widget";
import { toast } from "react-toastify";
import Modal2 from "../components/confirm-delete-modal/modal-2";
import { FiX } from "react-icons/fi";
import ModalActivate from "../components/confirm-activate-modal/modal-activate";
import ApiClient from "../apis/apiClient";
import { getAllEVoucherList, statusUpdate } from "../apis/constant";
import CsvDowloader from "../components/CsvDowloader";
import { Redirect } from "react-router-dom";
import moment from "moment";
import { checkAccess } from "../utils/common";
import { IoMdClose } from "react-icons/io";

const usdFormatter = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

const Simple = () => {
  const [eVoucherList, setEVoucherList] = useState([]);
  const [Loading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [paginationObject, setPaginationObject] = useState({});
  const adminToken = localStorage.getItem("wed_admin_token");

  const getAllEvoucher = async (limit, searchq = "", pageNumber) => {
    const data = "";
    setIsLoading(true);
    try {
      const res = await ApiClient.get(
        getAllEVoucherList +
        `?page=${pageNumber}&sortBy=asc&limit=${limit}&searchBy=${searchq}`,
        data,
        `Bearer ${adminToken}`
      );
      if (res.data.code === 200) {
        if (res.data.data && res.data.data.docs.length !== 0) {
          const tempData = res.data.data.docs.map((element) => {
            let stripeFees = 0;
            let stripePaymentId = '-';
            let stripeTransactionId = '-';
            if (element?.orderId?.paymentMilestones) {
              stripeFees = element?.orderId?.paymentMilestones[0]?.paymentmilestone[0]?.stripeFees;
              stripeTransactionId = element?.orderId?.paymentMilestones[0]?.paymentmilestone[0]?.stripeTransactionId;
              stripePaymentId = element?.orderId?.paymentMilestones[0]?.paymentmilestone[0]?.stripePaymentId;
            }
            element.stripeFees = stripeFees;
            element.stripeTransactionId = stripeTransactionId;
            element.stripePaymentId = stripePaymentId;
            const tracker = element.track_discount_vouchers[0];
            element.status = element.status === 0 ? "Inactive" : "Active";
            element.orderId = tracker ? tracker.orderId.orderId : "";
            element.subTotalWithTax = tracker
              ? usdFormatter(tracker.orderId.subTotalWithTax ?? 0)
              : 0;
            element.amount = usdFormatter(element.amount ?? 0);
            element.vendorId = tracker ? tracker.vendorId.vendorId : "";
            element.userName = element.userId.userName;
            element.couponAmount = tracker
              ? tracker.couponAmount > tracker.orderId.subTotalWithTax
                ? usdFormatter(tracker.orderId.subTotalWithTax)
                : usdFormatter(tracker?.couponAmount)
              : 0;
            element.usedAt = tracker
              ? moment(tracker.createdAt).format("DD-MMM-YYYY")
              : "";
            element.createdAt = moment(element.createdAt).format("DD-MMM-YYYY");
            element.expiryDate = moment(element.expiryDate).format("DD-MMM-YYYY");
            return element;
          });
          setEVoucherList(tempData);
        }
        setPaginationObject({
          limit: res.data.data.limit,
          page: res.data.data.page,
          pages: res.data.data.pages,
          total: res.data.data.total,
        });
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (search.length > 2 || search.length === 0) {
      getAllEvoucher(10, search, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const deleteEVoucher = async (eVoucherList) => {
    const id = eVoucherList.original.id;
    const data = "";
    const res = await ApiClient.delete(
      getAllEVoucherList + id,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllEvoucher(10, "", 1);
    } else {
      toast.warning(res.data.message);
    }
  };

  const handleActive = async (status, data) => {
    let myObj = {
      status: status,
      ids: [data.id],
    };
    const res = await ApiClient.post(
      statusUpdate,
      myObj,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllEvoucher(10, "", 1);
    } else {
      toast.warning(res.data.message);
    }
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "E-Voucher Order No.",
        accessor: "voucherOrderId",
      },
      {
        Header: "From",
        accessor: "from",
      },
      {
        Header: "User Name",
        accessor: "userName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Code",
        accessor: "code",
      },

      {
        Header: "Message",
        accessor: "message",
      },
      {
        Header: "Voucher Purchase Date",
        accessor: "createdAt",
      },
      {
        Header: "Voucher Value",
        accessor: "amount",
      },
      {
        Header: "E-Voucher Stripe Fees",
        accessor: "stripeFees",
      },
      {
        Header: "Stripe Transaction ID",
        accessor: "stripeTransactionId",
      },
      {
        Header: "Stripe Payment ID",
        accessor: "stripePaymentId",
      },
      {
        Header: "Expiry date",
        accessor: "expiryDate",
      },
      {
        Header: "Order ID",
        accessor: "orderId",
      },
      {
        Header: "Total Order Amt",
        accessor: "subTotalWithTax",
      },
      {
        Header: "Voucher Offset",
        accessor: "couponAmount",
      },
      {
        Header: "Vendor ID",
        accessor: "vendorId",
      },
      {
        Header: "Voucher Used Date",
        accessor: "usedAt",
      },
      {
        Header: "VENDOR NAME / PLATFORM ADMIN",
        accessor: "track_discount_vouchers[0].vendorId.vendorName",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Action",
        id: "delete",
        accessor: (str) => { },
        show: false,
        Cell: (Datatable) => (
          <>
            {checkAccess(25, "delete") && (
              <Modal2
                title="Delete"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to delete this E-Voucher?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  deleteEVoucher(Datatable.row);
                }}
                buttonClassName={`underline ${Datatable.row.original.track_discount_vouchers.length > 0
                  ? "opacity-50"
                  : ""
                  }`}
                isDisable={
                  Datatable.row.original.track_discount_vouchers.length > 0
                    ? true
                    : false
                }
              />
            )}{" "}
            |{" "}
            {Datatable.row.original.status === "Inactive"
              ? checkAccess(25, "edit") && (
                <ModalActivate
                  title="Activate"
                  icon={
                    <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                      <FiX
                        size={18}
                        className="stroke-current text-red-500"
                      />
                    </span>
                  }
                  body={
                    <div className="text-sm text-grey-500">
                      Are You Sure! You want to Deactivate this E-Voucher?
                    </div>
                  }
                  buttonTitle="Confirm"
                  onClick={() => {
                    handleActive(1, Datatable.cell.row.original);
                  }}
                  buttonClassName={`underline ${Datatable.cell.row.original.track_discount_vouchers
                    .length > 0
                    ? "opacity-50"
                    : ""
                    }`}
                  isDisable={
                    Datatable.cell.row.original.track_discount_vouchers
                      .length > 0
                      ? true
                      : false
                  }
                />
              )
              : checkAccess(25, "edit") && (
                <ModalActivate
                  title="Deactivate"
                  icon={
                    <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                      <FiX
                        size={18}
                        className="stroke-current text-red-500"
                      />
                    </span>
                  }
                  body={
                    <div className="text-sm text-grey-500">
                      Are You Sure! You want to Active this E-Voucher?
                    </div>
                  }
                  buttonTitle="Confirm"
                  onClick={() => {
                    handleActive(0, Datatable.cell.row.original);
                  }}
                  isDisable={
                    Datatable.cell.row.original.track_discount_vouchers
                      .length > 0
                      ? true
                      : false
                  }
                  buttonClassName={`underline ${Datatable.cell.row.original.track_discount_vouchers
                    .length > 0
                    ? "opacity-50"
                    : ""
                    }`}
                />
              )}
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getPageData = (data, type) => {
    if (type === "limit") {
      getAllEvoucher(data, "", 1);
    } else {
      getAllEvoucher(data, "", 1);
    }
  };

  const data = React.useMemo(() => eVoucherList, [eVoucherList]);
  console.log("__data", data);

  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
    return (
      <>
        <div className="flex justify-between">
          <div className="flex justify-center items-center searchCss add_fliter_input w-96">
            <input
              className=""
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <IoMdClose
              size={20}
              onClick={() => setSearch("")}
              className="cursor-pointer"
            />
          </div>
          <CsvDowloader columns={columns} data={data} filename="evouchers" />
        </div>
        <EVoucherTable
          columns={columns}
          data={data}
          loading={Loading}
          pagination={paginationObject}
          onSubmit={getPageData}
          showSearch={true}
          getAllEvoucher={getAllEvoucher}
        />
      </>
    );
  }
};

const EVoucher = () => (
  <>
    <SectionTitle title="Manage E-Voucher" subtitle="E-Voucher" />

    <Widget>
      <Simple />
    </Widget>
  </>
);
export default EVoucher;
