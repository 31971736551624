import React, {useState} from "react";
import { useForm } from "react-hook-form";
import Alert from "../alerts";
import { FiEye, FiEyeOff } from "react-icons/fi";

const FormValidation = ({ items, onSubmit, alerts, onChange }) => {

  const { handleSubmit, errors, register } = useForm();
  const [isShow, setIsShow] = useState([]);

  const onSubmitFn = (data) => {
    if (onSubmit) {
      onSubmit(data);
    }
  };
  items = items.map((item) => {
    item["ref"] = register(item["error"]);
    return item;
  });

  const handlePasswordChange = (value, id) => {
    onChange(value, id);
  };

  const handleEye = (index) => {
    const value = !isShow[index];  
    const newArr = [...isShow];
    newArr[index] = value;
    setIsShow(newArr);
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmitFn)}
      className="form flex flex-wrap w-full"
    >
      {alerts &&
        items.map((item, i) => {
          if (!errors[item.name]) return null;
          let msg = errors[item.name].message;
          if (msg.length === 0) msg = `${item.label} is required`;
          return (
            <div className="flex flex-col w-full">
              {errors[item.name] && (
                <div className="mb-2" key={i}>
                  <Alert
                    color="bg-transparent border-red-500 text-red-500"
                    borderLeft
                    raised
                  >
                    {msg}
                  </Alert>
                </div>
              )}
            </div>
          );
        })}
      <div className="w-full">
        {items.map((item, i) => {
          if (item.inputType === "login") {
            return (
              <>
                <div className="form-element">
                  {item.label && <div className="form-label">{item.label}</div>}
                  <input
                    ref={item.ref}
                    id={item.id}
                    value={item.value}
                    name={item.name}
                    type={isShow[i] ? "text" : "password"}
                    onChange={(e) =>
                      handlePasswordChange(e.target.value, item.id)
                    }
                    className={`form-input ${
                      errors[item.name] ? "border-red-500" : ""
                    }`}
                    placeholder={item.placeholder}
                  />                  
                  {!isShow[i] ? (
                    <FiEyeOff
                      onClick={() => handleEye(i)}
                      style={{
                        position: "absolute",
                        right: "25px",
                        top: "39px",
                      }}
                    />
                  ) : (
                    <FiEye
                      onClick={() => handleEye(i)}
                      style={{
                        position: "absolute",
                        right: "25px",
                        top: "39px",
                      }}
                    />
                  )}
                  {!alerts && errors[item.name] && (
                    <div className="form-error">
                      {errors[item.name].message}
                    </div>
                  )}
                </div>
              </>
            );
          }
          return (
            <>
              <div className="form-element">
                {item.label && <div className="form-label">{item.label}</div>}
                <input
                  ref={item.ref}
                  name={item.name}
                  type={item.type}
                  value={item.value}
                  id={item.id}
                  className={`form-input ${
                    errors[item.name] ? "border-red-500" : ""
                  }`}
                  placeholder={item.placeholder}
                />
                {!alerts && errors[item.name] && (
                  <div className="form-error">{errors[item.name].message}</div>
                )}
              </div>
            </>
          );
        })}
      </div>
      <button
        type="submit"
        className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
      >
        Submit
      </button>
    </form>
  );
};
export default FormValidation;
