import React, { useEffect, useState } from "react";
import SectionTitle from "../components/section-title";
import Usertable from "../components/usertable";
import Widget from "../components/widget";
import { toast } from "react-toastify";
import Modal2 from "./../components/confirm-delete-modal/modal-2";
import ModalActivate from "../components/confirm-activate-modal/modal-activate";

import { FiX } from "react-icons/fi";
import ApiClient from "../apis/apiClient";
import {
  API_GetAllUsers,
  API_DeleteUserById,
  API_ActDeactSubuser,
} from "../apis/constant";
import { Link, Redirect } from "react-router-dom";
import "nprogress/nprogress.css";
import moment from "moment";
import { checkAccess } from "../utils/common";
import CsvDowloader from "../components/CsvDowloader";

const Simple = () => {
  const [usersData, setUsersData] = useState([]);
  const [Loading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [paginationObject, setPaginationObject] = useState({});
  const adminToken = localStorage.getItem("wed_admin_token");

  const getAllUsers = async (limit, status, pageNumber) => {
    const data = "";
    setIsLoading(true);
    // setIsApiCall(true);
    const res = await ApiClient.get(
      API_GetAllUsers +
        `?page=${pageNumber}&sortBy=asc&limit=${limit}&searchBy=${search}&status=${status}`, //page number
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      const data = res.data.data.docs ?? [];
      if (res.data.data && data.length !== 0) {
        for (let i = 0; i < data.length; i++) {
          data[i].weddingDate = moment(data[i].weddingDate).format(
            "DD-MMM-YYYY"
          );
          data[i].status = data[i].status === 0 ? "Inactive" : "Active";
          data[i].createdAt = moment(data[i].createdAt).format("DD-MMM-YYYY");
          data[i].hearAboutUs = data[i].hearAboutUs.join(", ");
        }
      }
      setPaginationObject({
        limit: res.data.data.limit,
        page: res.data.data.page,
        pages: res.data.data.pages,
        total: res.data.data.total,
      });

      const _dataTemp = data.map((item, index) => {
        return {
          ...item,
          no: res.data.data.total - (pageNumber - 1) * limit - index,
        };
      });
      setUsersData(_dataTemp);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (search.length > 2 || search.length === 0) {
      getAllUsers(10, search, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const deleteUser = async (userData) => {
    const id = userData.original.id;
    const data = "";
    const res = await ApiClient.delete(
      API_DeleteUserById + id,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllUsers();
    } else {
      toast.warning(res.data.message);
    }
  };

  const actDeactivateUser = async (userData, getStatus) => {
    const id = userData.original.id;
    var arrayId = new Array(id);
    const data = {
      ids: arrayId,
      status: getStatus,
    };
    const res = await ApiClient.post(
      API_ActDeactSubuser,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllUsers(10, "", 1);
    } else {
      toast.warning(res.data.message);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "No",
        accessor: "no",
      },
      {
        Header: "User Id",
        accessor: "userUniqueId",
      },
      {
        Header: "User Name",
        accessor: "userName",
      },
      {
        Header: "Bride First Name",
        accessor: "brideFirstName",
      },
      {
        Header: "Bride Last Name",
        accessor: "brideLastName",
      },
      {
        Header: "Groom First Name",
        accessor: "groomFirstName",
      },
      {
        Header: "Groom Last Name",
        accessor: "groomLastName",
      },
      {
        Header: "Wedding Date",
        accessor: "weddingDate",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Object Id",
        accessor: "id",
      },
      {
        Header: "Hear About Us",
        accessor: "hearAboutUs",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Created At",
        accessor: "createdAt",
      },
      {
        Header: "Action",
        id: "delete",
        accessor: (str) => {},
        show: false,
        Cell: (Datatable) => (
          <>
            {checkAccess(3, "edit") && (
              <Link
                // className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white ml-2"
                style={{ cursor: "pointer", textDecoration: "underline" }}
                to={`/edituser/${Datatable.row.original.id}`}
              >
                Edit
              </Link>
            )}{" "}
            |{" "}
            {checkAccess(3, "delete") && (
              <Modal2
                title="Delete"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to delete this User?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  deleteUser(Datatable.row);
                }}
                buttonClassName="underline"
              />
            )}{" "}
            |{" "}
            {Datatable.row.original.displayStatus === "Inactive"
              ? checkAccess(3, "edit") && (
                  <ModalActivate
                    title="Active"
                    icon={
                      <span className="h-10 w-10 bg-blue-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                        <FiX
                          size={18}
                          className="stroke-current text-red-500"
                        />
                      </span>
                    }
                    body={
                      <div className="text-sm text-grey-500">
                        Are You Sure! You want to activate this User?
                      </div>
                    }
                    buttonTitle="Confirm"
                    onClick={(e) => {
                      actDeactivateUser(Datatable.row, "activate");
                    }}
                    buttonClassName="underline"
                  />
                )
              : checkAccess(3, "edit") && (
                  <ModalActivate
                    title="Deactive"
                    icon={
                      <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                        <FiX
                          size={18}
                          className="stroke-current text-red-500"
                        />
                      </span>
                    }
                    body={
                      <div className="text-sm text-grey-500">
                        Are You Sure! You want to deactivate this User?
                      </div>
                    }
                    buttonTitle="Confirm"
                    onClick={(e) => {
                      actDeactivateUser(Datatable.row, "deactivate");
                    }}
                    buttonClassName="underline"
                  />
                )}
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getPageData = (data, type) => {
    if (type === "limit") {
      getAllUsers(data, "", 1);
    } else {
      getAllUsers(data, "", 1);
    }
  };

  const data = React.useMemo(() => usersData, [usersData]);
  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
    return (
      // <div style={{ overflowX: "auto" }}>
      <div>
        <div className="w-full flex items-center justify-between">
          <input
            className="searchCss"
            type="text"
            placeholder="Search By Email"
            value={search}
            // onChange={(e) => handleSearchData(e.target.value)}
            onChange={(e) => setSearch(e.target.value)}
          />
          <CsvDowloader columns={columns} data={data} filename="customers" />
        </div>
        <Usertable
          columns={columns}
          data={data}
          loading={Loading}
          pagination={paginationObject}
          onSubmit={getPageData}
          showSearch={true}
          getAllUsers={getAllUsers}
        />
      </div>
    );
  }
};

const Index = () => (
  <>
    <SectionTitle title="" subtitle="Users" />
    <Widget>
      <Simple />
    </Widget>
  </>
);
export default Index;
