import React from "react";
import PaymentPolicyContent from "../components/PaymentPolicyContent";
// import Head from "next/head";
// import PaymentPolicyContent from "../../../../components/layouts/pages/vendor/settings/paymentPolicyContent/PaymentPolicyContent";

const PaymentPolicyDetail = () => {
  return (
    <>
      {/* <Head>
        <title>Payment Policy Vendor</title>
      </Head> */}

      <div className="px-36 py-10" style={{ minHeight: "80vh" }}>
        <div className="justify-between flex items-center mb-6">
          <p className="font-cagily text-3xl text-bg-primary ">
            Payment Policy Vendor
          </p>
        </div>

        <PaymentPolicyContent />
      </div>
    </>
  );
};

export default PaymentPolicyDetail;
