/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SectionTitle from "../components/section-title";
import DiscountsDataTable from "../components/discounts";
import Widget from "../components/widget";
import { toast } from "react-toastify";
import Modal2 from "../components/confirm-delete-modal/modal-2";
import { FiX } from "react-icons/fi";
import ModalActivate from "../components/confirm-activate-modal/modal-activate";
import ApiClient from "../apis/apiClient";
import { getAllListOfDiscounts } from "../apis/constant";
import { Link, Redirect } from "react-router-dom";
import moment from "moment";
import { checkAccess } from "../utils/common";
import CsvDowloader from "../components/CsvDowloader";
import { IoMdClose } from "react-icons/io";

const usdFormatter = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

const Simple = () => {
  const [discountList, setDiscountList] = useState([]);
  const [Loading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [paginationObject, setPaginationObject] = useState({});
  const adminToken = localStorage.getItem("wed_admin_token");

  const getAllDiscountData = async (limit, searchq = "", pageNumber) => {
    const data = "";
    setIsLoading(true);
    // setIsApiCall(true);
    const res = await ApiClient.get(
      getAllListOfDiscounts +
        `?page=${pageNumber}&sortBy=asc&limit=${limit}&searchBy=${search}`,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      if (res.data.data && res.data.data.docs.length !== 0) {
        for (let i = 0; i < res.data.data.docs.length; i++) {
          res.data.data.docs[i].item_number =
            (res.data.data.page > 1
              ? res.data.data.total -
                res.data.data.limit * Number(res.data.data.page - 1)
              : res.data.data.total) - i;
          if (
            res.data.data.docs[i].adminId &&
            !res.data.data.docs[i].vendorId
          ) {
            res.data.data.docs[i]["vendorNameAndPlatFormAdmin"] =
              "Platform Admin";
            res.data.data.docs[i].vendorId = "-";
          } else {
            res.data.data.docs[i]["vendorNameAndPlatFormAdmin"] =
              res.data.data.docs[i].vendorId.vendorName;
            res.data.data.docs[i].vendorId =
              res.data.data.docs[i].vendorId.vendorId;
          }
          if (
            res.data.data.docs[i].usageLimitsPerCustomer === null ||
            res.data.data.docs[i].usageLimitsPerCustomer === -1
          ) {
            res.data.data.docs[i].usageLimitsPerCustomer = "Unlimited";
          }
          res.data.data.docs[i]["customerEligibility"] =
            res.data.data.docs[i].customerEligibility.to;
          res.data.data.docs[i]["appliesTo"] =
            res.data.data.docs[i].discountApplies.to === "productType"
              ? "Product Family"
              : res.data.data.docs[i].discountApplies.to
                  .split(/(?=[A-Z])/)
                  .join(" ");
          res.data.data.docs[i]["displayStatus"] =
            res.data.data.docs[i].status === 0 ? "Inactive" : "Active";
          res.data.data.docs[i]["startDate"] = moment(
            res.data.data.docs[i].startDateTime
          ).format("DD-MMM-YYYY");
          res.data.data.docs[i]["startTime"] = moment(
            res.data.data.docs[i].startDateTime
          ).format("HH:mm");
          if (res.data.data.docs[i].endDateTime) {
            res.data.data.docs[i]["endDate"] = moment(
              res.data.data.docs[i].endDateTime
            ).format("DD-MMM-YYYY");
            res.data.data.docs[i]["endTime"] = moment(
              res.data.data.docs[i].endDateTime
            ).format("HH:mm");
          } else {
            res.data.data.docs[i]["endDate"] = "-";
            res.data.data.docs[i]["endTime"] = "-";
          }
          res.data.data.docs[i]["createdAt"] = moment(
            res.data.data.docs[i].createdAt
          ).format("DD-MMM-YYYY HH:mm");
          res.data.data.docs[i]["updatedAt"] = moment(
            res.data.data.docs[i].updatedAt
          ).format("DD-MMM-YYYY HH:mm");
          res.data.data.docs[i]["totalUsage"] =
            res.data.data.docs[i].track_discount_vouchers.length;
          res.data.data.docs[i].discountValue =
            res.data.data.docs[i].type === "PERCENT"
              ? `${res.data.data.docs[i].discountValue}%`
              : usdFormatter(res.data.data.docs[i].discountValue);
          res.data.data.docs[i].minimumRequirementPurchaseAmount = usdFormatter(
            res.data.data.docs[i].minimumRequirementPurchaseAmount ?? 0
          );
        }
      }
      setPaginationObject({
        limit: res.data.data.limit,
        page: res.data.data.page,
        pages: res.data.data.pages,
        total: res.data.data.total,
      });
      setDiscountList(res.data.data.docs);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (search.length > 2 || search.length === 0) {
      getAllDiscountData(10, search, 1);
    }
  }, [search]);

  const deleteDiscounts = async (discountList) => {
    const id = discountList.original.id;
    const data = "";
    const res = await ApiClient.delete(
      getAllListOfDiscounts + id,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(" Discounts is Deleted Successfully");
      getAllDiscountData(10, "", 1);
    } else {
      toast.warning("Error");
    }
  };

  const handleActive = async (status, data) => {
    let myObj = {
      status: status,
    };
    const res = await ApiClient.patch(
      getAllListOfDiscounts + "/" + data.id,
      myObj,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success("Status is updated successfully");
      getAllDiscountData(10, "", 1);
    } else {
      toast.warning("Error");
    }
  };
  const data = React.useMemo(() => discountList, [discountList]);

  const columns = React.useMemo(
    () => [
      {
        Header: "No",
        accessor: "item_number",
      },
      {
        Header: "Vendor Name",
        accessor: "vendorNameAndPlatFormAdmin",
      },
      {
        Header: "Vendor	ID",
        accessor: "vendorId",
      },
      {
        Header: "Code	",
        accessor: "code",
      },
      {
        Header: "Discount Name",
        accessor: "discountName",
      },
      {
        Header: "Discount Condition",
        accessor: "discountCode",
      },
      {
        Header: "Discount Type",
        accessor: "type",
      },
      {
        Header: "Discount Value",
        accessor: "discountValue",
      },
      {
        Header: "Eligibility",
        accessor: "customerEligibility",
      },
      {
        Header: "Applies To",
        accessor: "appliesTo",
        Cell: ({ row }) => (
          <span className="capitalize">{row.original.appliesTo}</span>
        ),
      },
      {
        Header: "Limit Per User",
        accessor: "usageLimitsPerCustomer",
      },
      {
        Header: "Min Purchase Amount",
        accessor: "minimumRequirementPurchaseAmount",
      },
      {
        Header: "Max Cap",
        accessor: "maxCap",
      },
      {
        Header: "Start Date	",
        accessor: "startDate",
      },
      {
        Header: "Start Time	",
        accessor: "startTime",
      },
      {
        Header: "End Date	",
        accessor: "endDate",
      },
      {
        Header: "End Time	",
        accessor: "endTime",
      },
      {
        Header: "Created At	",
        accessor: "createdAt",
      },
      {
        Header: "Updated At	",
        accessor: "updatedAt",
      },
      {
        Header: "Total Usage",
        accessor: "totalUsage",
        Cell: (dataTable) => (
          <Link
            to={`/audit-report/discount/${dataTable.row.original.id}`}
            className="underline"
          >
            {dataTable?.value}{" "}
          </Link>
        ),
      },
      {
        Header: "Status",
        accessor: "displayStatus",
      },
      {
        Header: "Action",
        id: "delete",
        accessor: (str) => {},
        show: false,
        Cell: (Datatable) => (
          <>
            {checkAccess(27, "edit") && (
              <Link
                to={`/sales/editDiscount/${Datatable.row.original.id}`}
                buttonClassName="underline"
              >
                Edit{" "}
              </Link>
            )}
            {checkAccess(27, "delete") && (
              <Modal2
                title="Delete"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to delete this Discounts?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  deleteDiscounts(Datatable.row);
                }}
                buttonClassName="underline"
              />
            )}{" "}
            |{" "}
            {Datatable.row.original.status === 0
              ? checkAccess(27, "edit") && (
                  <ModalActivate
                    title="Activate"
                    icon={
                      <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                        <FiX
                          size={18}
                          className="stroke-current text-red-500"
                        />
                      </span>
                    }
                    body={
                      <div className="text-sm text-grey-500">
                        Are You Sure! You want to Deactivate this Discount?
                      </div>
                    }
                    buttonTitle="Confirm"
                    onClick={() => {
                      handleActive(1, Datatable.cell.row.original);
                    }}
                    buttonClassName="underline"
                  />
                )
              : checkAccess(27, "edit") && (
                  <ModalActivate
                    title="Deactivate"
                    icon={
                      <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                        <FiX
                          size={18}
                          className="stroke-current text-red-500"
                        />
                      </span>
                    }
                    body={
                      <div className="text-sm text-grey-500">
                        Are You Sure! You want to Active this Discount?
                      </div>
                    }
                    buttonTitle="Confirm"
                    onClick={() => {
                      handleActive(0, Datatable.cell.row.original);
                    }}
                    buttonClassName="underline"
                  />
                )}
          </>
        ),
      },
    ],
    []
  );

  const getPageData = (data, type) => {
    // if (type === "limit") {
    getAllDiscountData(data, "", 1);
    // }
  };

  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
    return (
      <>
        <div className="flex justify-between">
          <div className="flex justify-center items-center searchCss add_fliter_input w-96">
            <input
              className=""
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <IoMdClose
              size={20}
              onClick={() => setSearch("")}
              className="cursor-pointer"
            />
          </div>
          <div className="flex items-center">
            <CsvDowloader columns={columns} data={data} filename="discounts" />
            {checkAccess(27, "add") && (
              <Link
                to={"/sales/addDiscount"}
                style={{ top: "2px" }}
                className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded addFilterCss"
              >
                Add Discount
              </Link>
            )}
          </div>
        </div>

        <DiscountsDataTable
          columns={columns}
          data={data}
          loading={Loading}
          pagination={paginationObject}
          onSubmit={getPageData}
          showSearch={true}
          getAllDiscountData={getAllDiscountData}
        />
      </>
    );
  }
};

const DiscountPage = () => (
  <>
    <SectionTitle title="Manage Discount" subtitle="Discount" />

    <Widget>
      <Simple />
    </Widget>
  </>
);
export default DiscountPage;
