import { createSlice } from "@reduxjs/toolkit";
import {
  vendorProductsAction,
  vendorProductGetByIdAction,
  removeProductAction,
  updateVendorProducts
} from "../actions";

const initialState = {
  products: [],
  isFetching: false,
  product: null,
};



export const vendorProductsSlice = createSlice({
  name: "vendorProducts",
  initialState,
  reducers: {
    // vendorProductFormAction: vendorFormProduct,
  },
  extraReducers: {
    //Get all Vendor' Products
    [vendorProductsAction.pending]: (state, action) => {
      state.isFetching = true;
      state.error = null;
    },
    [vendorProductsAction.fulfilled]: (state, action) => {
      state.products = action.payload.products;
      state.isFetching = false;
    },
    [vendorProductsAction.rejected]: (state, action) => {
      state.isFetching = false;
    },

    //Get Vendor's Product by id
    [vendorProductGetByIdAction.pending]: (state, action) => {
      state.isFetching = true;
      state.error = null;
    },
    [vendorProductGetByIdAction.fulfilled]: (state, action) => {
      state.product = action.payload.product;
      state.isFetching = false;
    },
    [vendorProductGetByIdAction.rejected]: (state, action) => {
      state.isFetching = false;
    },

    //Remove Vendor's Product by id
    [removeProductAction.pending]: (state, action) => {
      state.isFetching = true;
      state.error = null;
    },
    [removeProductAction.fulfilled]: (state, action) => {
      state.isFetching = false;
    },
    [removeProductAction.rejected]: (state, action) => {
      state.isFetching = false;
    },
    //Remove Vendor's Product by id
    [updateVendorProducts.pending]: (state, action) => {
      state.isFetching = true;
      state.error = null;
    },
    [updateVendorProducts.fulfilled]: (state, action) => {
      state.isFetching = false;
    },
    [updateVendorProducts.rejected]: (state, action) => {
      state.isFetching = false;
    },
  },
});

// export const { vendorProductFormAction } = vendorProductsSlice.actions;

export default vendorProductsSlice.reducer;
