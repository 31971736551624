/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import SectionTitle from "../components/section-title";
import Permissiontable from "../components/rolePermission";
import Widget from "../components/widget";
import { toast } from "react-toastify";
import Modal2 from "./../components/confirm-delete-modal/modal-2";
import { FiX } from "react-icons/fi";
import ApiClient from "../apis/apiClient";
import AsyncSelect from "react-select/async";
import { API_CategoryList, API_DeleteCategoryById, API_MasterCategoryList, API_UpdateCategoryById, } from "../apis/constant";
import "nprogress/nprogress.css";
import "../css/custome.css";
import AddCategory from "../components/category/addCategory";
import EditCategory from "../components/category/editCategory";
import { Redirect } from "react-router-dom";
import { checkAccess } from "../utils/common";

const Simple = () => {
  const [permissionsData, setPermissionsData] = useState([]);
  const [Loading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const adminToken = localStorage.getItem("wed_admin_token");
  const [paginationObject, setPaginationObject] = useState({});
  const searchRef = useRef(search);
  const [selectedMasterCategoryValue, setSelectedMasterCategoryValue] = useState(null);
  const selectedMasterCategoryValueRef = useRef(selectedMasterCategoryValue);

  const getAllMasterCategory = async (limit, pageNumber) => {
    const data = "";
    setIsLoading(true);
    // setIsApiCall(true);
    const res = await ApiClient.get(
      API_CategoryList +
      `?masterCategoryId=${selectedMasterCategoryValueRef?.current?.id ?? ""}&page=${pageNumber}&sortBy=asc&limit=${limit}&searchBy=${searchRef.current}`,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      if (res.data.data && res.data.data.docs.length !== 0) {
        for (let i = 0; i < res.data.data.docs.length; i++) {
          let element = res.data.data.docs[i];
          res.data.data.docs[i]["mastercategory"] = element.hasOwnProperty(
            "masterCategoryId"
          )
            ? element.masterCategoryId !== null
              ? element.masterCategoryId.name
              : ""
            : "";
        }
      }
      setPaginationObject({
        limit: limit,
        page: pageNumber,
        pages: res.data.data.pages,
        total: res.data.data.total,
      });
      // let activeData = res?.data?.data?.docs?.filter(item => item.status === 1 )
      setPermissionsData(res?.data?.data?.docs);
      setIsLoading(false);
      // setIsApiCall(false);
    }
  };

  const handleAMasterCategory = async (e) => {
    setSelectedMasterCategoryValue(e);
  };

  const MasterCategoryList = async (inputValue) => {
    const data = "";
    const res = await ApiClient.get(
      API_MasterCategoryList + `?page=1&sortBy=asc&limit=${99999}&searchBy=${inputValue}&isFor=dropdown`, data,
      `Bearer ${adminToken}`
    );

    if (res.data.code === 200) {
      return res.data.data.docs;
    }
  };

  useEffect(() => {
    searchRef.current = search;
    selectedMasterCategoryValueRef.current = selectedMasterCategoryValue
    if (search.length > 2 || search.length === 0) {
      getAllMasterCategory(10, 1);
    }
  }, [search, selectedMasterCategoryValue]);

  const handleActive = async (status, data) => {
    let myObj = {
      status: status,
      name: data.name,
    };

    const res = await ApiClient.patch(
      API_UpdateCategoryById + data.id,
      myObj,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllMasterCategory(10, 1);
    } else {
      toast.warning(res.data.message);
    }
  };

  const deleteUser = async (userData) => {
    const id = userData.original.id;
    const data = "";
    const res = await ApiClient.delete(
      API_DeleteCategoryById + "/" + id,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllMasterCategory(10, 1);
    } else {
      toast.warning(res.data.message);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: " Level 1",
        accessor: "mastercategory",
      },
      {
        Header: " Level 2",
        accessor: "name",
      },
      {
        Header: "Status",
        id: "status",
        accessor: (str) => {
          return str["status"] === 1 ? (
            <label>Active</label>
          ) : (
            <label>Inactive</label>
          );
        },
      },

      {
        Header: "Action",
        id: "delete",
        accessor: (str) => { },

        Cell: (Datatable) => (
          <>
            {checkAccess(6, 'edit') && <EditCategory
              id={Datatable.row.original.id}
              title={"Edit Level 2"}
              body={"body"}
              getApi={getAllMasterCategory}
            />}{" "}
            |{" "}
            {checkAccess(6, 'delete') && <Modal2
              title="Delete"
              icon={
                <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                  <FiX size={18} className="stroke-current text-red-500" />
                </span>
              }
              body={
                <div className="text-sm text-grey-500">
                  Are You Sure! You want to delete this Category?
                </div>
              }
              buttonTitle="Confirm"
              onClick={() => {
                deleteUser(Datatable.row);
              }}
              buttonClassName="underLineText"
            />}{" "}
            |{" "}
            {Datatable.cell.row.original.status === 1 ? (
              checkAccess(6, 'edit') && <Modal2
                title="Deactivate"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to Deactivate this Category?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  handleActive(0, Datatable.cell.row.original);
                }}
                buttonClassName="underLineText"
              />
            ) : (
              checkAccess(6, 'edit') && <Modal2
                title="Activate"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to Activate this Category?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  handleActive(1, Datatable.cell.row.original);
                }}
                buttonClassName="underLineText"
              />
            )}
          </>
        ),
      },
    ],
    []
  );

  const getPageData = (data) => {
    getAllMasterCategory(data, 1);
  };

  const data = React.useMemo(() => permissionsData, [permissionsData]);

  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
    return (
      <>
        <div className="flex justify-between sub_cate_flex">
          <div className="flex flex_box_11">
            <div className="inline-block flex-col manage_product_box">
              <p style={{ margin: '0px 0px 8px 10px', fontWeight: "bold" }}>Search</p>
              <input
                className="searchCss sub_cate_input"
                type="text"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="inline-block flex-col manage_product_box">
              <p className="filterCategoryCss">Level 1</p>
              <div className=" masterFilterCss ">
                <AsyncSelect
                  className="w-100"
                  cacheOptions
                  defaultOptions
                  isClearable
                  value={selectedMasterCategoryValue}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.id}
                  loadOptions={MasterCategoryList}
                  onChange={handleAMasterCategory}
                />
              </div>
            </div>
          </div>
          <div>

            {checkAccess(6, 'add') && <AddCategory
              title={"Add Level 2"}
              body={"body"}
              getApi={getAllMasterCategory}
            />}
          </div>
        </div>

        <Permissiontable
          columns={columns}
          data={data}
          loading={Loading}
          onSubmit={getPageData}
          showSearch={true}
          pagination={paginationObject}
          getAllUsers={getAllMasterCategory}
        />
      </>
    );
  }
};

const Category = () => (
  <>
    <SectionTitle title="Manage Categories" subtitle=" Categories" />
    <Widget>
      <Simple />
    </Widget>
  </>
);
export default Category;
