import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Alert from "../alerts";
import Select from "react-select";
import { getColor, toRGB } from "../../functions/colors";
import { FiEye, FiEyeOff } from "react-icons/fi";

const FormValidation = ({
  items,
  onSubmit,
  alerts,
  onChange,
  isSubmit,
  nameErr,
  nameVErr,
}) => {
  const { handleSubmit, errors, register } = useForm();
  const [emailValidError, setEmailValidError] = useState(false);
  const [isShow, setIsShow] = useState(false);

  const onSubmitFn = (data) => {
    if (onSubmit) {
      onSubmit(data);
    }
  };
  items = items.map((item) => {
    item["ref"] = register(item["error"]);
    return item;
  });

  useEffect(() => {
    onChange();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEmailChange = (value, id) => {
    const regex =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (value === "") {
      setEmailValidError(false);
    } else if (regex.test(value) === false) {
      setEmailValidError(true);
    } else {
      setEmailValidError(false);
    }
    onChange(value, id);
  };

  const handleEye = () => {
    setIsShow(!isShow);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmitFn)}
      className="form flex flex-wrap w-full"
    >
      {alerts &&
        items.map((item, i) => {
          if (!errors[item.name]) return null;
          let msg = errors[item.name].message;
          if (msg.length === 0) msg = `${item.label} is required`;
          return (
            <div className="flex flex-col w-full">
              {errors[item.name] && (
                <div className="mb-2" key={i}>
                  <Alert
                    color="bg-transparent border-red-500 text-red-500"
                    borderLeft
                    raised
                  >
                    {msg}
                  </Alert>
                </div>
              )}
            </div>
          );
        })}
      <div className="w-full vendor-flex-box add_sub_adminBox">
        {items.map((item, i) => {
          if (item.type === "checkbox") {
            return (
              <div className="form-element">
                {item.label && <div>{item.label}</div>}
                <div className="flex items-center justify-start space-x-2">
                  {item.options.map((option, j) => (
                    <label className="flex items-center justify-start space-x-2">
                      <input
                        key={j.id}
                        ref={item.ref}
                        type="checkbox"
                        value={item.value}
                        id={item.id}
                        name={item.name}
                        className={`form-checkbox h-4 w-4 ${
                          errors[item.name + "11111"] ? "text-red-500" : ""
                        }`}
                      />
                      <span
                        className={`${errors[item.name] ? "text-red-500" : ""}`}
                      >
                        {option.label}
                      </span>
                    </label>
                  ))}
                </div>
              </div>
            );
          }
          if (item.type === "radio") {
            return (
              <div className="form-element">
                {item.label && <div>{item.label}</div>}
                <div className="flex items-center justify-start space-x-2">
                  {item.options.map((option, j) => (
                    <label className="flex items-center justify-start space-x-2">
                      <input
                        key={j.id}
                        type="radio"
                        value={item.value}
                        id={item.id}
                        name={item.name}
                        ref={register({ required: true })}
                        className={`form-radio h-4 w-4 ${
                          errors[item.name] ? "text-red-500" : ""
                        }`}
                      />
                      <span
                        className={`${errors[item.name] ? "text-red-500" : ""}`}
                      >
                        {option.label}
                      </span>
                    </label>
                  ))}
                </div>
              </div>
            );
          }
          if (item.type === "select") {
            return (
              <div className="form-element lg:w-1/2">
                {item.label && <div>{item.label}</div>}
                <select
                  ref={item.ref}
                  name={item.name}
                  value={item.value}
                  id={item.id}
                  onChange={(e) => onChange(e.target.value, item.id)}
                  className={`form-select ${
                    errors[item.name] ? "border border-red-500" : ""
                  }`}
                >
                  {item.options.map((option, j) => (
                    <option key={j} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {!alerts && errors[item.name] && (
                  <div className="form-error">{errors[item.name].message}</div>
                )}
              </div>
            );
          }
          if (item.type === "multi-select") {
            return (
              <div
                className="form-element"
                style={{ width: "500px", height: "30px" }}
              >
                {item.label && <div>{item.label}</div>}

                <div className="flex flex-wrap w-full">
                  <div className="w-1/3 p-2">
                    <Select
                      options={item.value}
                      isMulti={true}
                      placeholder="Select multiple..."
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: getColor("bg-blue-500"),
                          primary25: toRGB(getColor("bg-blue-500"), 0.25),
                          primary50: toRGB(getColor("bg-blue-500"), 0.5),
                          primary75: toRGB(getColor("bg-blue-500"), 0.75),
                          danger: getColor("bg-red-500"),
                          dangerLight: toRGB(getColor("bg-red-500"), 0.25),
                        },
                      })}
                    />
                  </div>
                </div>
                {!alerts && errors[item.name] && (
                  <div className="form-error">{errors[item.name].message}</div>
                )}
              </div>
            );
          }
          if (item.type === "textarea") {
            return (
              <>
                <div className="form-element">
                  {item.label && <div>{item.label}</div>}
                  <textarea
                    ref={item.ref}
                    name={item.name}
                    value={item.value}
                    id={item.id}
                    className={`form-textarea ${
                      errors[item.name] ? "border border-red-500" : ""
                    }`}
                    rows="3"
                    placeholder={item.placeholder}
                  ></textarea>
                  {!alerts && errors[item.name] && (
                    <div className="form-error">
                      {errors[item.name].message}
                    </div>
                  )}
                </div>
              </>
            );
          }
          if (item.type === "email") {
            return (
              <>
                <div className="form-element lg:w-1/2">
                  {item.label && <div>{item.label}</div>}
                  <input
                    ref={item.ref}
                    id={item.id}
                    value={item.value}
                    name={item.name}
                    type={item.type}
                    onChange={(e) => handleEmailChange(e.target.value, item.id)}
                    className={`form-input ${
                      errors[item.name] ? "border-red-500" : ""
                    }`}
                    placeholder={item.placeholder}
                  />
                  {!alerts && errors[item.name] && (
                    <div className="form-error">
                      {errors[item.name].message}
                    </div>
                  )}
                  {emailValidError && (
                    <div className="form-error">Please enter valid email</div>
                  )}
                </div>
              </>
            );
          }
          if (item.type === "date") {
            return (
              <>
                <div className="form-element lg:w-1/2">
                  {item.label && <div>{item.label}</div>}
                  <input
                    ref={item.ref}
                    id={item.id}
                    value={item.value}
                    name={item.name}
                    type={item.type}
                    min={item.min}
                    onChange={(e) => onChange(e.target.value, item.id)}
                    className={`form-input ${
                      errors[item.name] ? "border-red-500" : ""
                    }`}
                    placeholder={item.placeholder}
                  />
                  {!alerts && errors[item.name] && (
                    <div className="form-error">
                      {errors[item.name].message}
                    </div>
                  )}
                </div>
              </>
            );
          }
          if (item.inputType === "vendor-input") {
            return (
              <>
                <div className="form-element lg:w-1/2">
                  {item.label && <div>{item.label}</div>}
                  <input
                    ref={item.ref}
                    id={item.id}
                    value={item.value}
                    // maxLength= {item.maxLength}
                    name={item.name}
                    // type={isShow ? "text" : "password"}
                    type={item.type}
                    onChange={(e) => onChange(e.target.value, item.id)}
                    className={`form-input ${
                      errors[item.name] ? "border-red-500" : ""
                    }`}
                    placeholder={item.placeholder}
                  />
                  {/* {isShow ?
                < FiEyeOff
                  onClick={() => handleEye()}
                  style={{ position: "relative", left: "480px", top: "-25px" }}
                  type="text"
                />:
                <FiEye
                onClick={() => handleEye()}
                style={{ position: "relative", left: "480px", top: "-25px" }}
                type="password"
                />
                } */}
                  {!alerts && errors[item.name] && (
                    <div className="form-error">
                      {errors[item.name].message}
                    </div>
                  )}
                  {item.id === "vendorName" && (
                    <p style={{ color: "red" }}>{nameErr}</p>
                  )}
                  {item.id === "name" && (
                    <p style={{ color: "red" }}>{nameVErr}</p>
                  )}
                </div>
              </>
            );
          }
          if (item.inputType === "password") {
            return (
              <>
                <div className="form-element lg:w-1/2">
                  {item.label && <div>{item.label}</div>}
                  <input
                    ref={item.ref}
                    id={item.id}
                    value={item.value}
                    name={item.name}
                    // type={item.type}
                    type={isShow ? "text" : "password"}
                    // type={item.type}
                    onChange={(e) => onChange(e.target.value, item.id)}
                    className={`form-input ${
                      errors[item.name] ? "border-red-500" : ""
                    }`}
                    placeholder={item.placeholder}
                    style={{ paddingRight: "35px" }}
                  />
                  {isShow ? (
                    <FiEyeOff
                      onClick={() => handleEye()}
                      style={{
                        position: "absolute",
                        right: "25px",
                        top: "32px",
                      }}
                      type="text"
                    />
                  ) : (
                    <FiEye
                      onClick={() => handleEye()}
                      style={{
                        position: "absolute",
                        right: "25px",
                        top: "32px",
                      }}
                      type="password"
                    />
                  )}

                  {!alerts && errors[item.name] && (
                    <div className="form-error">
                      {errors[item.name].message}
                    </div>
                  )}
                </div>
              </>
            );
          }
          return (
            <>
              <div className="form-element">
                {item.label && <div>{item.label}</div>}
                <input
                  ref={item.ref}
                  name={item.name}
                  type={item.type}
                  //  type={isShow ? "text" : "password"}
                  value={item.value}
                  id={item.id}
                  className={`form-input ${
                    errors[item.name] ? "border-red-500" : ""
                  }`}
                  placeholder={item.placeholder}
                />
                {/* <FiEyeOff/> */}
                {/* {isShow ?
                < FiEyeOff
                  onClick={() => handleEye()}
                  // style={{ position: "relative", left: "480px", top: "-25px" }}
                  type="text"
                />:
                <FiEye
                onClick={() => handleEye()}
                // style={{ position: "relative", left: "480px", top: "-25px" }}
                type="password"
                />
                } */}
                {!alerts && errors[item.name] && (
                  <div className="form-error">{errors[item.name].message}</div>
                )}
              </div>
            </>
          );
        })}
      </div>
      {isSubmit ? (
        <button
          type="submit"
          className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
        >
          Loading...
        </button>
      ) : (
        <button
          type="submit"
          className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
        >
          Submit
        </button>
      )}
    </form>
  );
};
export default FormValidation;
