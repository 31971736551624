import React, { useState, useEffect, useRef } from "react";
import { useSelector, shallowEqual } from "react-redux";
import Portal from "../portal";
import { FiX } from "react-icons/fi";
import { API_UpdateCommissionDetailsById, url } from "../../apis/constant";
import { toast } from "react-toastify";
import axios from "axios";

const EditCommission = ({
  id,
  name,
  monthlyFee,
  quarterlyFee,
  yearlyFee,
  title,
  getApi,
  status,
}) => {
  const alphanumericRegex = /^[a-zA-Z0-9-. ]+$/;
  const adminToken = localStorage.getItem("wed_admin_token");
  const [error, setError] = useState("");
  const [comNameerror, setComNameError] = useState("");
  const [varError, setVarError] = useState("");
  const [quarterError, setQuarterError] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [monthValue, setMonthValue] = useState("");
  const [quarterValue, setQuarterValue] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [yearValue, setYearValue] = useState([]);

  const { palettes } = useSelector(
    (state) => ({
      palettes: state.palettes,
    }),
    shallowEqual
  );
  let { background } = {
    ...palettes,
  };

  const modalRef = useRef(null);
  const [open, setOpen] = useState(false);
  const show = () => {
    getCommissionById();
    setOpen(true);
  };
  const hide = () => {
    setOpen(false);
    setMonthValue("");
    setNameValue("");
    setYearValue("");
    setError("");
    setComNameError("");
    setQuarterError("");
    setVarError("");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false;
      if (!open || modalRef.current.contains(event.target)) {
        return false;
      }
      setOpen(!open);
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, modalRef]);

  const getCommissionById = () => {
    setNameValue(name);
    setMonthValue(monthlyFee);
    setQuarterValue(quarterlyFee);
    setYearValue(yearlyFee);
  };

  const handleChange = (value, type) => {
    if (type === "name") {
      const limit = 200;
      if (value.length <= limit) {
        setComNameError("");
        setNameValue(value);
      } else if (value && !alphanumericRegex.test(value)) {
        setComNameError("Name should not be blank");
        setNameValue(value);
      } else {
        setComNameError("Please enter less than 200 words");
      }
    } else if (type === "attname") {
      const limit = 200;
      if (value.length <= limit) {
        setQuarterError("");
        setMonthValue(value);
      } else if (value && !alphanumericRegex.test(value)) {
        setQuarterError("Monthly Fee should not be blank.");
        setMonthValue(value);
      } else {
        setQuarterError("Please enter less than 200 words");
      }
    } else if (type === "quartername") {
      const limit = 200;
      if (value.length <= limit) {
        setError("");
        setQuarterValue(value);
      } else if (value && !alphanumericRegex.test(value)) {
        setError("Quarterly Fee should not be blank.");
        setQuarterValue(value);
      } else {
        setError("Please enter less than 200 words");
      }
    } else if (type === "varname") {
      const limit = 200;
      if (value.length <= limit) {
        setVarError("");
        setYearValue(value);
      } else if (value && !alphanumericRegex.test(value)) {
        setVarError("Yearly Fee should not be blank.");
        setYearValue(value);
      } else {
        setVarError("Please enter less than 200 words");
      }
    }
  };

  const handleCommission = () => {
    let isTrue = false;
    setIsSubmit(true);
    if (nameValue === "") {
      setComNameError("Commission Name should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setComNameError("");
    }
    if (monthValue === "") {
      setError("Monthly Fee should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setError("");
    }
    if (quarterValue === "") {
      setQuarterError("Quarterly Fee should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setQuarterError("");
    }
    if (yearValue === "") {
      setVarError("Yearly Fee should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setVarError("");
    }

    if (isTrue) {
      return;
    }

    let logData = {
      name: nameValue,
      monthlyFee: String(monthValue),
      yearlyFee: String(yearValue),
      quarterlyFee: String(quarterValue),
      status: status,
    };
    axios
      .patch(url + API_UpdateCommissionDetailsById + id, logData, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((res) => {
        if (res.data.code === 200) {
          toast.success("Subscription edited successfully");
          hide();
          getApi(10, "", 1);
        } else if (res.data.code === 400) {
          toast.error(res.data.message);
          setIsSubmit(false);
        }
      })
      .catch((err) => {
        setIsSubmit(false);
        toast.error(err.response.data.message);
      });
  };

  return (
    <>
      <button
        style={{ cursor: "pointer", textDecoration: "underline" }}
        type="button"
        onClick={show}
      >
        Edit
      </button>
      {open && (
        <Portal selector="#portal">
          <div className="modal-backdrop fade-in"></div>
          <div
            className={`modal show commision_edit_modal ${
              background === "dark" ? "dark-mode" : ""
            }`}
            data-background={background}
          >
            <div
              className="relative min-w-sm w-auto mx-auto lg:max-w-5xl commision_edit_modalBox"
              ref={modalRef}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="text-xl font-semibold">{title}</h3>
                  <button
                    className="modal-close btn btn-transparent"
                    onClick={hide}
                  >
                    <FiX size={18} className="stroke-current" />
                  </button>
                </div>

                <div className="relative p-4 flex-auto">
                  <label>Name</label>
                  <div className="inputBoxCategoryCss">
                    <input
                      type="text"
                      placeholder="Please Enter Title"
                      name="name"
                      value={nameValue}
                      onChange={(e) => handleChange(e.target.value, "name")}
                      className="inputFields"
                    />
                  </div>

                  {comNameerror && (
                    <p style={{ color: "red", paddingTop: "10px" }}>
                      {comNameerror}
                    </p>
                  )}
                </div>

                <div className="relative p-4 flex-auto">
                  <label>Monthly Fee</label>
                  <div className="inputBoxCategoryCss">
                    <input
                      type="text"
                      placeholder="Please Enter Title"
                      name="attname"
                      value={monthValue}
                      onChange={(e) => handleChange(e.target.value, "attname")}
                      className="inputFields"
                    />
                  </div>

                  {error && (
                    <p style={{ color: "red", paddingTop: "10px" }}>{error}</p>
                  )}
                </div>

                <div className="relative p-4 flex-auto">
                  <label>Quarterly Fee</label>
                  <div className="inputBoxCategoryCss">
                    <input
                      type="text"
                      placeholder="Please Enter Title"
                      name="attname"
                      value={quarterValue}
                      onChange={(e) =>
                        handleChange(e.target.value, "quartername")
                      }
                      className="inputFields"
                    />
                  </div>

                  {quarterError && (
                    <p style={{ color: "red", paddingTop: "10px" }}>
                      {quarterError}
                    </p>
                  )}
                </div>

                <div className="relative p-4 flex-auto">
                  <label>Yearly Fee</label>
                  <div className="inputBoxCategoryCss">
                    <input
                      type="text"
                      placeholder="Please Enter Title"
                      name="varname"
                      value={yearValue}
                      onChange={(e) => handleChange(e.target.value, "varname")}
                      className="inputFields"
                    />
                  </div>

                  {varError && (
                    <p style={{ color: "red", paddingTop: "10px" }}>
                      {varError}
                    </p>
                  )}
                </div>

                <div className="modal-footer space-x-2">
                  <button
                    className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                    type="button"
                    onClick={hide}
                  >
                    Close
                  </button>
                  {isSubmit ? (
                    <button
                      className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                      type="button"
                    >
                      Loading...
                    </button>
                  ) : (
                    <button
                      className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                      type="button"
                      onClick={() => handleCommission()}
                    >
                      Save Changes
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default EditCommission;
