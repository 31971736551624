import React, { useEffect, useState } from "react";
import SectionTitle from "../components/section-title";
import SubUserstable from "../components/sub-users";
import Widget from "../components/widget";
import ApiClient from "../apis/apiClient";
import {
  API_GetAllAddessAdmin,
} from "../apis/constant";
import { Redirect } from "react-router-dom";

const Simple = () => {
  const [nasubusersData, setNaSubusersData] = useState([]);
  const [Loading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [paginationObject, setPaginationObject] = useState({});
  const adminToken = localStorage.getItem("wed_admin_token");

  const getAllsubUsers = async (limit, searchq = "", status, pageNumber) => {
    const data = "";
    setIsLoading(true);
    // setIsApiCall(true);
    const res = await ApiClient.get(
      API_GetAllAddessAdmin +
        `?page=${pageNumber}&sortBy=asc&limit=${limit}&searchBy=${search}&status=${status}`,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      if (res.data.data && res.data.data.docs.length !== 0) {
        const data = res.data.data.docs ?? [];
        const _dataTemp = data.map((item, index) => {
          return {
            ...item,
            no: res.data.data.total - (pageNumber - 1) * limit - index,
          }
        })
        setNaSubusersData(_dataTemp);
      }
      setPaginationObject({
        limit: res.data.data.limit,
        page: res.data.data.page,
        pages: res.data.data.pages,
        total: res.data.data.total,
      });
      // setSubusersData(res.data.data.docs);
      setIsLoading(false);
      // setIsApiCall(false);
    }
  };

  useEffect(() => {
    if (search.length > 2 || search.length === 0) {
      getAllsubUsers(10, search, "", 1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const columns = React.useMemo(
    () => [
      {
        Header: "No.",
        accessor: "no",
      },
      {
        Header: "User Id",
        accessor: "userId.userUniqueId",
      },
      {
        Header: "User Name",
        accessor: "userId.userName",
      },
      {
        Header: "Email",
        accessor: "userId.email",
      },
      {
        Header: "Billing First Name",
        accessor: "billingDetails.firstName",
      },
      {
        Header: "Billing Last Name",
        accessor: "billingDetails.lastName",
      },
      {
        Header: "Billing Street Address",
        accessor: "billingDetails.streetAddress",
      },
      {
        Header: "Billing Street Address 2",
        accessor: "billingDetails.streetAddress2",
      },
      {
        Header: "Billing Street Address 3",
        accessor: "billingDetails.streetAddress3",
      },
      {
        Header: "Billing Unit Number",
        accessor: "billingDetails.unitNumber",
      },
      {
        Header: "Billing Postal Code",
        accessor: "billingDetails.postalCode",
      },
      {
        Header: "Billing City",
        accessor: "billingDetails.city",
      },
      {
        Header: "Billing State",
        accessor: "billingDetails.state",
      },
      {
        Header: "Billing Country",
        accessor: "billingDetails.country",
      },
      {
        Header: "Shipping First Name",
        accessor: "shippingDetails.firstName",
      },
      {
        Header: "Shipping Last Name",
        accessor: "shippingDetails.lastName",
      },
      {
        Header: "Shipping Street Address",
        accessor: "shippingDetails.streetAddress",
      },
      {
        Header: "Shipping Street Address 2",
        accessor: "shippingDetails.streetAddress2",
      },
      {
        Header: "Shipping Street Address 3",
        accessor: "shippingDetails.streetAddress3",
      },
      {
        Header: "Shipping Unit Number",
        accessor: "shippingDetails.unitNumber",
      },
      {
        Header: "Shipping Postal Code",
        accessor: "shippingDetails.postalCode",
      },
      {
        Header: "Shipping City",
        accessor: "shippingDetails.city",
      },
      {
        Header: "Shipping State",
        accessor: "shippingDetails.state",
      },
      {
        Header: "Shipping Country",
        accessor: "shippingDetails.country",
      },
      {
        Header: "Shipping Phone",
        accessor: "shippingDetails.phone",
      }
    ],
    []
  );

  const getPageData = (data, type) => {
    if (type === "limit") {
      // setPageLimit(data);
      getAllsubUsers(data, "", "", 1);
    } else {
      getAllsubUsers(10, "", "", data);
    }
  };

  const data = React.useMemo(() => nasubusersData, [nasubusersData]);
  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
  return (
    <>
      <div>
        <input
          className="searchCss"
          type="text"
          placeholder="Search By first name"
          value={search}
          // onChange={(e) => handleSearchData(e.target.value)}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <SubUserstable
        columns={columns}
        data={data}
        loading={Loading}
        pagination={paginationObject}
        onSubmit={getPageData}
        showSearch={true}
        getAllsubUsers={getAllsubUsers}
      />
    </>
  );
  }
};

const Index = () => (
  <>
    <SectionTitle title="" subtitle="Users Address" />
    <Widget>
      <Simple />
    </Widget>
  </>
);
export default Index;
