import React from "react";
import { Link } from "react-router-dom";
import { FiUser, FiLogIn, FiX } from "react-icons/fi";
import Modal2 from "../../components/confirm-delete-modal/modal-2";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutAction } from "../../reducers/auth.reducer";

const AccountLinks = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const handleLogout = () => {
    dispatch(logoutAction())
  };

  const items = [
    {
      url: "/user-profile",
      icon: <FiUser size={18} className="stroke-current" />,
      name: "Profile",
      badge: null,
      id: "profile",
    },

    {
      url: "#",
      id: "logout",
      icon: <FiLogIn size={18} className="stroke-current" />,
      name: (
        <Modal2
          title="Logout"
          icon={
            <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
              <FiX size={18} className="stroke-current text-red-500" />
            </span>
          }
          body={
            <div className="text-sm text-grey-500">
              Are You Sure! You want to Logout ?
            </div>
          }
          buttonTitle="Confirm"
          onClick={() => {
            handleLogout();
          }}
          buttonClassName="underLineText"
        />
      ),
      badge: null,
    },
  ];

  return (
    <div className="flex flex-col w-full">
      <ul className="list-none">
        {items.map((item, i) => (
          <li
            key={i}
            className="dropdown-item"
            style={{
              display:
                item.id === "profile" && location.pathname === "/user-profile"
                  ? "none"
                  : "block",
            }}
          >
            <Link
              to={item.url}
              className="flex flex-row items-center justify-start h-10 w-full px-2"
            >
              {item.icon}
              <span className="mx-2">{item.name}</span>
              {item.badge && (
                <span
                  className={`uppercase font-bold inline-flex text-center p-0 leading-none text-2xs h-4 w-4 inline-flex items-center justify-center rounded-full ${item.badge.color} ml-auto`}
                >
                  {item.badge.number}
                </span>
              )}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AccountLinks;
