/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import SectionTitle from "../section-title";
import Widget from "../widget";
import ApiClient from "../../apis/apiClient";
import { Link, useHistory, useParams } from "react-router-dom";
import "nprogress/nprogress.css";
import "../../css/custome.css";
import { toast } from "react-toastify";
import {
  url,
  API_CreateVendorCommissions,
  API_GetAllCommissionList,
  API_GetVendorSubscriptionById,
} from "../../apis/constant";
import axios from "axios";
import EditCategory from "../e-commerce/editVendorSubsCategory";

const EditVendorSubscription = () => {
  const [commissionList, setCommissionList] = useState([]);
  const [commissionData, setCommissonData] = useState("");
  const { id } = useParams();
  const adminToken = localStorage.getItem("wed_admin_token");
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [subsrciptionFee, setSubsrciptionFee] = useState("");
  const [duration, setDuration] = useState("");
  const [discountPlans, setDiscountPlans] = useState([]);

  let history = useHistory();
  const alphanumericRegex = /^[a-zA-Z0-9-. ]+$/;

  const [modules, setModules] = useState([]);

  const handleClick = (e, item, type) => {
    const values = [...modules];
    for (let i = 0; i < values.length; i++) {
      if (item.moduleId === values[i].moduleId) {
        if (type === "noAccess") {
          values[i].noAccess = true;
          values[i].viewOnly = false;
          values[i].viewEdit = false;
        } else if (type === "viewOnly") {
          values[i].noAccess = false;
          values[i].viewOnly = true;
          values[i].viewEdit = false;
        } else if (type === "viewEdit") {
          values[i].noAccess = false;
          values[i].viewOnly = false;
          values[i].viewEdit = true;
        }
      }
    }
    setModules(values);
  };

  const handleChange = (value) => {
    if (!alphanumericRegex.test(value)) {
      setError("Please enter valid role name.");
      setName(value);
      return;
    } else {
      setName(value);
      setError("");
    }
  };

  useEffect(() => {
    getAllCommisionList();
    getVendorSubscriptionById();
  }, []);

  const getVendorSubscriptionById = () => {
    axios
      .get(url + API_GetVendorSubscriptionById + "/" + id, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((res) => {
        if (res.data.code === 200) {
          let data = res.data.data;
          setName(data.name);
          setDuration(data.type);
          setSubsrciptionFee(data.fee);

          let vendorsub = [];
          for (let d in data.accessToPages) {
            let obj = {
              moduleName: data.accessToPages[d].pageName,
              moduleId: data.accessToPages[d].pageId,
              name: data.accessToPages[d].pageName.toLowerCase().replace(" ", ""),
            };

            if (data.accessToPages[d].permissions.includes("noaccess")) {
              obj["noAccess"] = true;
              obj["viewOnly"] = false;
              obj["viewEdit"] = false;
            } else if (data.accessToPages[d].permissions.includes("view")) {
              obj["noAccess"] = false;
              obj["viewOnly"] = true;
              obj["viewEdit"] = false;
            } else if (data.accessToPages[d].permissions.includes("view-edit")) {
              obj["noAccess"] = false;
              obj["viewOnly"] = false;
              obj["viewEdit"] = true;
            } else {
              obj["noAccess"] = false;
              obj["viewOnly"] = false;
              obj["viewEdit"] = false;
            }
            vendorsub.push(obj);
          }
          setModules(vendorsub);
          //let comData = data.commissionId.monthlyFee + "-" + data.commissionId.yearlyFee + "-" + data.commissionId.id;
          setCommissonData(data.commissionId);
          setDiscountPlans(data.discountPlan);
        }
      })
      .catch((err) => { });
  };

  const feeUpdate = (type) => {
    if (type === "Monthly") {
      setSubsrciptionFee(commissionData?.monthlyFee ?? 0);
    } else if (type === "Quarterly") {
      setSubsrciptionFee(commissionData?.quarterlyFee ?? 0);
    } else if (type === "Yearly") {
      setSubsrciptionFee(commissionData?.yearlyFee ?? 0);
    } else {
      setSubsrciptionFee(0);
    }
  }

  useEffect(() => {
    feeUpdate(duration);
  }, [duration, commissionData]);

  const handlePricePlan = (value) => {
    setDuration(value);
    feeUpdate(value);
  };

  const getAllCommisionList = async () => {
    const res = await ApiClient.get(
      API_GetAllCommissionList + "?limit=99999",
      "",
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      var values = [];
      for (let i = 0; i < res.data.data.docs.length; i++) {
        const element = res.data.data.docs[i];
        if (element.status === 1) {
          values.push(element)
        }
      }
      setCommissionList(values);
    }
  };

  const handleCommission = (e) => {
    let value = e.target.value;
    const comm = commissionList.find(x => x.id === value);
    if (comm) {
      setCommissonData(comm);
    }
  };

  const handleSubmitAdd = async () => {
    setIsSubmit(true);

    if (name === "") {
      setError("Comp Name can not be blank.");
      setIsSubmit(false);
      return;
    }
    let myObj = {
      name: name,
      type: duration,
      fee: parseInt(subsrciptionFee),
      commissionId: commissionData.id,
      accessToPages: [],
      discountPlan: [],
    };

    for (let i = 0; i < modules.length; i++) {
      let emptyObj = {};
      emptyObj["pageName"] = modules[i].moduleName;
      emptyObj["pageId"] = modules[i].moduleId;

      if (modules[i].noAccess) {
        emptyObj["permissions"] = ["noaccess"];
      } else if (modules[i].viewEdit) {
        emptyObj["permissions"] = ["view-edit"];
      } else if (modules[i].viewOnly) {
        emptyObj["permissions"] = ["view"];
      }
      myObj.accessToPages.push(emptyObj);
    }
    let discountData = [];
    for (let i = 0; i < discountPlans.length; i++) {
      let emptyObj = {
        masterCategory: discountPlans[i].masterCategory.id,
        productTypeWiseDiscountPlan: [],
        commission: discountPlans[i].commission,
        discount: discountPlans[i].discount
      };

      for (let j = 0; j < discountPlans[i].productTypeWiseDiscountPlan.length; j++) {
        let catObj = {
          commission: discountPlans[i].productTypeWiseDiscountPlan[j].commission,
          discount: discountPlans[i].productTypeWiseDiscountPlan[j].discount,
          productTypeId: discountPlans[i].productTypeWiseDiscountPlan[j].productTypeId.id,
        };
        emptyObj.productTypeWiseDiscountPlan.push(catObj);
      }

      discountData.push(emptyObj);
    }
    myObj.discountPlan = discountData;
    const res = await ApiClient.patch(
      API_CreateVendorCommissions + id,
      myObj,
      `Bearer ${adminToken}`
    );

    if (res.data.code === 200) {
      toast.success(res.data.message);
      history.push("/vendor_Suscription");
    } else if (res.data.code === 400) {
      toast.error(res.data.message);
      setIsSubmit(false);
    }
  };

  return (
    <>
      <SectionTitle title="" subtitle="Vendor Compensation" />
      <div className="addBack">
        <Link to="/vendor_Suscription">
          <button className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded cancel_btn">
            Back
          </button>
        </Link>
        {isSubmit ? (
          <button className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded">
            Loading...
          </button>
        ) : (
          <button
            className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
            onClick={() => handleSubmitAdd()}
          >
            Submit
          </button>
        )}
      </div>

      <div className="flex flex-col lg:flex-row lg:space-x-2 space-y-2 lg:space-y-0 mb-2">
        <div className="w-full lg:w-1/2">
          <div className="w-full p-4 rounded-lg bg-white border border-grey-100 dark:bg-grey-895 dark:border-grey-890">
            <Widget>
              <div className="">
                <div className="col-md-6">
                  <div className="card-body">
                    <div className="form-group row flex flex-col lg:flex-row lg:space-x-2 space-y-2 lg:space-y-0 mb-2">
                      <span className="w-full lg:w-1/2">
                        <label> Comp Name: </label>
                        <div className="form-element inline-flex" style={{ width: "100%" }}>
                          <input
                            type="text"
                            className="form-input inline-flex"
                            id="exampleInputName1"
                            placeholder="Comp Name"
                            name="name"
                            value={name}
                            maxLength={50}
                            minLength={2}
                            onChange={(e) => handleChange(e.target.value)}
                          />
                        </div>
                        {error && <p style={{ color: "red" }}>{error}</p>}
                      </span>
                      <span className="w-full lg:w-1/2">
                        <label>Subsription Fee: </label>
                        <div className="form-element inline-flex" style={{ width: "100%" }}>
                          <input
                            type="text"
                            className="form-input inline-flex"
                            id="exampleInputName1"
                            placeholder="Subscription Fee"
                            name="name"
                            value={subsrciptionFee}
                            disabled
                          />
                        </div>
                      </span>

                      <div className="w-full lg:w-1/3" >
                        <label>Duration: </label>
                        <div className="form-element inline-flex w-100" style={{ width: "100%" }}>
                          <select
                            className="form-input inline-flex"
                            name="commissionList"
                            id="commissionList"
                            value={duration}
                            onChange={(e) => handlePricePlan(e.target.value)}
                          >
                            <option value="Monthly">Monthly Fee</option>
                            <option value="Quarterly">Quarterly Fee</option>
                            <option value="Yearly">Yearly Fee</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-12">
                        <div className="table-responsive border rounded mt-1">
                          <h6 className="py-1 mx-1 mb-0 font-medium-2">
                            <i className="fa fa-lock mr-25"></i>
                            <span className="align-middle">
                              Page View Setting
                            </span>
                          </h6>
                          <table className="table table-striped table-borderless">
                            <thead className="thead-light">
                              <tr>
                                <th>Module Name</th>
                                <th>No Access</th>
                                <th>View Only</th>
                                <th>View & Edit</th>
                              </tr>
                            </thead>
                            <tbody>
                              {modules.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{item.moduleName}</td>
                                    <td>
                                      {item.noAccess ? (
                                        <input
                                          name={item.name}
                                          type="radio"
                                          className="form-radio h-4 w-4 border-black"
                                          checked
                                          onChange={(e) =>
                                            handleClick(e, item, "noAccess")
                                          }
                                        />
                                      ) : (
                                        <input
                                          name={item.name}
                                          type="radio"
                                          className="form-radio h-4 w-4 border-black"
                                          onChange={(e) =>
                                            handleClick(e, item, "noAccess")
                                          }
                                        />
                                      )}
                                    </td>
                                    <td>
                                      {item.viewOnly ? (
                                        <input
                                          name={item.name}
                                          type="radio"
                                          className="form-radio h-4 w-4 border-black"
                                          checked
                                          onChange={(e) =>
                                            handleClick(e, item, "viewOnly")
                                          }
                                        />
                                      ) : (
                                        <input
                                          name={item.name}
                                          type="radio"
                                          className="form-radio h-4 w-4 border-black"
                                          onChange={(e) =>
                                            handleClick(e, item, "viewOnly")
                                          }
                                        />
                                      )}
                                    </td>
                                    <td>
                                      {item.viewEdit ? (
                                        <input
                                          name={item.name}
                                          type="radio"
                                          className="form-radio h-4 w-4 border-black"
                                          checked
                                          onChange={(e) =>
                                            handleClick(e, item, "viewEdit")
                                          }
                                        />
                                      ) : (
                                        <input
                                          name={item.name}
                                          type="radio"
                                          className="form-radio h-4 w-4 border-black"
                                          onChange={(e) =>
                                            handleClick(e, item, "viewEdit")
                                          }
                                        />
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Widget>
          </div>
        </div>
        <div className="w-full lg:w-1/2">
          <div className="w-full p-4 rounded-lg bg-white border border-grey-100 dark:bg-grey-895 dark:border-grey-890">
            <Widget>
              <div className="">
                <div className="commission_box">
                  <p>Commission settings</p>
                  <div className="form-element form-element-inline mb-0">
                    <select
                      name="commissionList"
                      id="commissionList"
                      className="form-input inline-flex"
                      value={commissionData.id}
                      onChange={(e) => handleCommission(e)}
                    >
                      <option value="">Select Comm</option>
                      {commissionList.map((item) => (
                        <option value={item.id} >
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="master_cat_box">
                  {discountPlans &&
                    discountPlans?.map((item, i) => {
                      return (
                        <EditCategory
                          key={`disPlan_${i}`}
                          {...item}
                          discountPlans={discountPlans}
                          setDiscountPlans={setDiscountPlans}
                        />
                      )
                    })}
                </div>
              </div>
            </Widget>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditVendorSubscription;
