/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import SectionTitle from "../components/section-title";
import axios from "axios";
import { API_AllAnomalyOrdersAdmin, url } from "../apis/constant";
import { toast } from "react-toastify";
import moment from "moment";
import CsvDowloader from "../components/CsvDowloader";
import AnomalyOrderPageData from "../components/anomaly_order";

const getToken = () => localStorage.getItem("wed_admin_token");

const Index = () => {
  const columns = [
    {
      Header: "No",
      accessor: "no",
      show: true,
      isCheck: true,
    },
    {
      Header: "Order No",
      accessor: "orderNo",
      isCheck: true,
      show: true,
    },
    {
      Header: "Order Date",
      accessor: "orderdateee",
      isCheck: true,
      show: true,
    },
    {
      Header: "USER ID",
      accessor: "userId",
      isCheck: true,
      show: true,
    },
    {
      Header: "Customer",
      accessor: "customer",
      isCheck: true,
      show: true,
    },
    {
      Header: "Total Order Amt",
      accessor: "actualamount",
      isCheck: true,
      show: true,
    },
    {
      Header: "Payment Milestone",
      accessor: "paymilestone",
      isCheck: true,
      show: true,
    },
  ];

  const [orderData, setOrderData] = useState([]);
  const [Loading, setIsLoading] = useState(true);

  const getAnomalyOrder = () => {
    setIsLoading(true);
    axios
      .get(url + API_AllAnomalyOrdersAdmin, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then((res) => {
        if (res.data.code === 200) {
          const response = res.data.data;
          try {
            const resultData = response.map((v, i) => {
              return {
                no: response.length - i,
                orderNo: v?.orderId,
                orderdateee: moment(v?.createdAt).format("DD-MMM-YYYY"),
                userId: v?.userId?.userUniqueId,
                customer: `${v.userId?.brideFirstName} ${v.userId?.groomFirstName}`,
                actualamount: v?.totalOrderAmount ?? "",
                paymilestone: v?.paymentMilestones.length ?? 0,
              };
            });
            setIsLoading(false);
            setOrderData(resultData);
            
          } catch (ex) {
            console.log(ex);
          }
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getAnomalyOrder()
  }, [])

  return (
    <>
      <SectionTitle title="Anomaly Orders" subtitle="Anomaly Orders View" />
      <div className="flex p-3 w-full">
        <div className="ml-auto">
          <CsvDowloader columns={columns} data={orderData} filename="anomaly_orders" />
        </div>
      </div>
      <AnomalyOrderPageData
        columns={columns}
        data={orderData}
        loading={Loading}
      />
    </>
  );
};
export default Index;
