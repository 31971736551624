import React, { useState, useEffect, useRef } from "react";
import { API_AdminPaymentMilestone, url } from "../apis/constant";
import axios from "axios";
import { toast } from "react-toastify";
import SectionTitle from "../components/section-title";
import PaymentPolicyPageData from "../components/paymentSummary";
import CsvDowloader from "../components/CsvDowloader";
import moment from "moment";
import { useQuery } from "../hooks/useQuery";
import { Link } from "react-router-dom";

const Simple = ({
  columns,
  data,
  handlePagination,
  Loading,
  paginationObject,
}) => {
  const datatable = React.useMemo(() => data, [data]);

  return (
    <PaymentPolicyPageData
      columns={columns}
      data={datatable}
      loading={Loading}
      onSubmit={(val) => handlePagination(paginationObject.page, val)}
      showSearch={true}
      pagination={paginationObject}
      getAllVendorProduct={(limit, page) => handlePagination(page, limit)}
      setIds={() => {}}
    />
  );
};

const usdFormatter = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

const Item = ({ onClick, title, goto }) => {
  return (
    <Link to={goto}>
      <p
        onClick={onClick}
        className="cursor-pointer px-5 py-2  hover:bg-grey-300"
      >
        {title}
      </p>
    </Link>
  );
};

const CellRender = (props) => {
  const orderId = props.data[props.row.index].id;
  const orderById = props.data[props.row.index].orderno;
  const [show, setShow] = useState(false);

  return (
    <div
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      className="relative"
    >
      <p className="cursor-pointer hover:text-grey-500">{props.value}</p>
      {show && (
        <div
          className={`flex ${
            props.flatRows[props.flatRows.length - 1]?.index === props.row.index
              ? ""
              : "absolute"
          } shadow-lg bottom-20 bg-white rounded-md z-10`}
        >
          <Item
            title="View"
            goto={`/payment-details/${orderId}/${orderById}`}
          />
        </div>
      )}
    </div>
  );
};

const Index = () => {
  const query = useQuery();
  const updateFieldsRef = useRef();
  const orderId = query.get("orderId");
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [updatedData, setUpdatedData] = useState({});
  const [paginationObject, setPaginationObject] = useState({
    limit: 10,
    page: 1,
  });

  const getList = (search = "") => {
    setIsLoading(true);
    const adminToken = localStorage.getItem("wed_admin_token");
    axios
      .get(
        url +
          API_AdminPaymentMilestone +
          `paymentMilestonesByMultipleOrderId?page=${
            paginationObject.page
          }&sortBy=asc&limit=${paginationObject.limit}&searchBy=${
            search ?? ""
          }`,
        {
          headers: { Authorization: `Bearer ${adminToken}` },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          const apiData = res.data.docs;
          setPaginationObject({
            ...paginationObject,
            pages: res.data.pages,
            total: res.data.total,
          });
          let editableData = {};
          const finalData = apiData.map((v, i) => {
            const milestone = v?.paymentmilestone[0];
            const vendor = v?.vendorId;
            const order = v?.orderId;
            const user = v?.userId;
            const product = order?.products[0];

            const tempItem = {
              voucherSettlementAmt: milestone?.voucherSettlementAmt,
            };
            editableData = { ...editableData, [v.id]: tempItem };
            return {
              receiptId: v?._id,
              id: order?._id,
              orderNo: res.data.total - (paginationObject.page - 1) * paginationObject.limit - i,
              vendorID: vendor?.vendorId ?? "",
              vendorname: order?.vendorDetails?.vendorName ?? vendor?.vendorName ?? "",
              customerid: user?.userUniqueId,
              customeradd: user?.add,
              customerphone: user?.phone,
              customeremail: user?.email,
              productunitPrice: !v?.isVoucher ? usdFormatter(product?.price) : 0,
              productitemdiscount: !v?.isVoucher ? product?.productTitle : "",
              discount: !v?.isVoucher ? usdFormatter(order?.totalDiscount) : 0,
              subTotalwithoutDiscount: usdFormatter( order?.subTotalwithoutDiscount ?? 0 ),
              productname: !v?.isVoucher ? order?.products?.map((item) => item?.productTitle).join(", ") : "",
              quantity: !v?.isVoucher ? product?.quantity : null,
              expectedDeliveryData: !v?.isVoucher ? moment(v?.weddingDate).format("DD-MMM-YYYY") : null,
              totalorderamount: v?.totalorderamount,
              paymentMilestoneNumber: v?.paymentMilestoneNumber,
              customername: user?.brideFirstName + " " + user?.groomFirstName,
              reciept: v?.reciept,
              amttopay: v?.amtToPay,
              pcurmil: v?.paymentForCurrMilestone,
              totalamountpaid: v?.totalamountpaid,
              balancedue: v?.balanceDue,
              duedate: moment(milestone?.milestoneDate)?.format("DD-MMM-YYYY"),
              amt: isNaN(parseInt(milestone?.amount)?.toFixed(2)) ? "" : usdFormatter(milestone?.amount),
              amtgst: isNaN(parseInt(order?.subTotalTax)?.toFixed(2)) ? "" : usdFormatter(order?.subTotalTax),
              pmGst: usdFormatter(milestone?.gst ?? 0),
              voucheroffsett: isNaN( parseInt(milestone?.voucherOffset)?.toFixed(2) ) ? "" : usdFormatter(milestone?.voucherOffset),
              netdue: isNaN(parseInt(milestone?.netPaymentDue)) ? "" : usdFormatter(milestone?.netPaymentDue),
              vouval: isNaN(parseInt(milestone?.remainingVoucherValue)) ? "" : usdFormatter(milestone?.remainingVoucherValue),
              orderno: order?.orderId,
              receiptDate: milestone?.paymentDate ? moment(milestone?.paymentDate).format("DD-MMM-YYYY") : "-",
              stripeFees: usdFormatter(milestone?.stripeFees ?? 0),
              platformFees: usdFormatter(milestone?.adminChargeToPay ?? 0),
              action: "",
              vendoraddress: order?.vendorDetails?.streetAddress1 ?? vendor?.streetAddress1,
              vatNo: order?.vatTaxNumber,
              stripeTransactionId: milestone?.stripeTransactionId,
              stripePaymentId: milestone?.stripePaymentId,
              feeId: milestone?.feeId,
              totalPmMilestone: v?.totalPmMilestone,
              taxAdjustAmt: v?.taxAdjustAmt ?? null,
            };
          });
          setUpdatedData(editableData);
          setData(finalData);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error(`Something went wrong!, ${err}`);
      });
  };

  useEffect(() => {
    updateFieldsRef.current = updatedData;
  }, [updatedData]);

  useEffect(() => {
    setSearch(orderId);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getList(orderId || search || "");
    // eslint-disable-next-line
  }, [orderId, paginationObject.limit, paginationObject.page]);

  const [columns] = useState([
    {
      Header: "No",
      accessor: "orderNo",
      show: true,
      isCheck: true,
    },
    {
      Header: "Order No",
      accessor: "orderno",
      Cell: (props) => <CellRender {...props} />,
      isCheck: true,
      show: true,
    },
    {
      Header: "Receipt No",
      accessor: "reciept",
      show: true,
      isCheck: true,
    },
    {
      Header: "Receipt Date",
      accessor: "receiptDate",
      show: true,
      isCheck: true,
    },
    {
      Header: "Stripe Transaction ID",
      accessor: "stripeTransactionId",
      isCheck: true,
      show: true,
    },
    {
      Header: "Stripe Payment ID",
      accessor: "stripePaymentId",
      isCheck: true,
      show: true,
    },
    {
      Header: "Stripe Fee ID",
      accessor: "feeId",
      isCheck: true,
      show: true,
    },
    {
      Header: "Customer ID",
      accessor: "customerid",
      show: true,
      isCheck: true,
    },
    {
      Header: "Customer Name",
      accessor: "customername",
      show: true,
      isCheck: true,
    },
    {
      Header: "Customer Address",
      accessor: "customeradd",
      show: true,
      isCheck: true,
    },
    {
      Header: "Phone",
      accessor: "customerphone",
      show: true,
      isCheck: true,
    },
    {
      Header: "Email",
      accessor: "customeremail",
      show: true,
      isCheck: true,
    },
    {
      Header: "Vendor Name",
      accessor: "vendorname",
      show: true,
      isCheck: true,
    },
    {
      Header: "Vendor Address",
      accessor: "vendoraddress",
      show: true,
      isCheck: true,
    },
    {
      Header: "GST Reg No",
      accessor: "vatNo",
      show: true,
      isCheck: true,
    },
    {
      Header: "Expected Delivery Date",
      accessor: "expectedDeliveryData",
      show: true,
      isCheck: true,
    },
    {
      Header: "Total PM Milestones",
      accessor: "totalPmMilestone",
      show: true,
      isCheck: true,
    },

    {
      Header: "Payment Milestone",
      accessor: "paymentMilestoneNumber",
      show: true,
      isCheck: true,
    },
    {
      Header: "Product Name",
      accessor: "productname",
      show: true,
      isCheck: true,
    },
    {
      Header: "Quantity",
      accessor: "quantity",
      show: true,
      isCheck: true,
    },
    {
      Header: "Unit Price",
      accessor: "productunitPrice",
      show: true,
      isCheck: true,
    },
    {
      Header: "Item Discount",
      accessor: "productitemdiscount",
      show: true,
      isCheck: true,
    },
    {
      Header: "Sub Total",
      accessor: "subTotalwithoutDiscount",
      show: true,
      isCheck: true,
    },
    {
      Header: "Discount",
      accessor: "discount",
      show: true,
      isCheck: true,
    },
    {
      Header: "GST",
      accessor: "amtgst",
      show: true,
      isCheck: true,
    },
    {
      Header: "Total Order Amount incl Tax",
      accessor: "totalorderamount",
      show: true,
      isCheck: true,
    },
    {
      Header: "Voucher Offset",
      accessor: "voucheroffsett",
      show: true,
      isCheck: true,
    },
    {
      Header: "Remaining Voucher Value",
      accessor: "vouval",
      show: true,
      isCheck: true,
    },
    {
      Header: "Total Amount to Pay",
      accessor: "amttopay",
      show: true,
      isCheck: true,
    },
    {
      Header: "Payment Due Date",
      accessor: "duedate",
      show: true,
      isCheck: true,
    },
    {
      Header: "PM Amt Excl Tax",
      accessor: "amt",
      show: true,
      isCheck: true,
    },
    {
      Header: "PM GST",
      accessor: "pmGst",
      show: true,
      isCheck: true,
    },
    {
      Header: "TAX Adjust Amount",
      accessor: "taxAdjustAmt",
      show: true,
      isCheck: true,
    },
    {
      Header: "Payment for Current Milestone",
      accessor: "pcurmil",
      show: true,
      isCheck: true,
    },
    {
      Header: "Net Payment Due",
      accessor: "netdue",
      show: true,
      isCheck: true,
    },
    {
      Header: "Total Amount Paid",
      accessor: "totalamountpaid",
      show: true,
      isCheck: true,
    },
    {
      Header: "balance Due",
      accessor: "balancedue",
      show: true,
      isCheck: true,
    },
    {
      Header: "Stripe Fees incl Tax",
      accessor: "stripeFees",
      show: true,
      isCheck: true,
    },
    {
      Header: "Platform Fees",
      accessor: "platformFees",
      show: true,
      isCheck: true,
    },
    // {
    //   Header: "Action",
    //   accessor: "action",
    //   show: true,
    //   isCheck: true,
    //   Cell: (props) => (
    //     <div>
    //       <p
    //         onClick={() => updateMilestone(props?.row?.original?.id)}
    //         className="underline cursor-pointer"
    //       >
    //         Save
    //       </p>
    //     </div>
    //   ),
    // },
  ]);
  const [Loading, setIsLoading] = useState(false);
  return (
    <>
      <SectionTitle title="Payment" subtitle="Payment Summary" />
      <div className="p-2 bg-white border rounded-md">
        <div className="flex">
          <div className="flex border-2 rounded-md hover:border-blue-500 py-2">
            <svg
              aria-hidden="true"
              className="flex justify-self-center self-center focus:w-5 h-5 text-gray-500 dark:text-gray-400 ml-2 text-grey-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 35 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
            <input
              type="Search"
              placeholder="Search Order ID"
              className="w-96"
              value={search}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  getList(search);
                }
              }}
              onChange={(event) => {
                setSearch(event.target.value);
              }}
            ></input>
          </div>
          <button
            onClick={() => getList(search)}
            className="ml-2 border-2 rounded-md py-2 px-4 bg-bg-primary text-white"
          >
            Search
          </button>
          <button
            onClick={() => {
              setSearch("");
              getList("");
            }}
            className="ml-2 py-2 px-4  text-red-500"
          >
            Clear
          </button>
          {/* <div className="flex p-3">
            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="All"
            />
            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="Approve"
            />
            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="Pending"
            />
            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="Rejected"
            />
            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="Archive"
            />
            <div className="flex border-2 rounded-md hover:border-blue-500 w-full mr-3">
              <svg
                aria-hidden="true"
                className="flex justify-self-center self-center focus:w-5 h-5 text-gray-500 dark:text-gray-400 ml-2 text-grey-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 35 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
              <input
                type="Search"
                placeholder="Search"
                className="w-96"
              ></input>
            </div>
          </div> */}

          <div className="flex space-x-2 justify-end w-full">
            <CsvDowloader columns={columns} data={data} filename="payments" />
            {/* <ModalImport
              title="Export / Import Data"
              body={
                <div className="grid grid-cols-1 mx-12 my-5">
                  <span className="text-lg my-4">
                    Easily organize your own data with import or export data
                    feature
                  </span>
                  <button className="bg-white hover:bg-grey-200 py-2 px-4 my-2 rounded shadow-sm border">
                    Download CSV Template
                  </button>
                  <button className="bg-white hover:bg-grey-200 py-2 px-4 my-2 rounded shadow-sm border">
                    Export
                  </button>
                  <button className="bg-white hover:bg-grey-200 py-2 px-4 my-2 rounded shadow-sm border">
                    Import Individual Customer
                  </button>
                  <button className="bg-white hover:bg-grey-200 py-2 px-4 my-2 rounded shadow-sm border">
                    Import Aggregate Customer
                  </button>
                  <hr className="my-2" />
                  <p className="underline text-center cursor-pointer">
                    Ask Customer Service
                  </p>
                </div>
              }
            /> */}
            {/* <button className="bg-white hover:bg-grey-200 py-2 px-4 rounded shadow-sm border">
              Export
            </button>
            <button className="bg-white hover:bg-grey-200 py-2 px-4 rounded shadow-sm border">
              Import
            </button> */}
            {/* <div ref={ref}>
              <button
                onClick={() => setShow(!show)}
                className="bg-white hover:bg-grey-200 py-2 px-4 rounded shadow-sm border"
              >
                Bulk Action
              </button>

              {show && (
                <div className="relative">
                  <div className=" absolute shadow-lg right-0 bottom-20 bg-white w-48 rounded-md z-10">
                    <Menu
                      title="Approve Reviews"
                      onClick={() => console.log("Active")}
                    />
                    <hr></hr>
                    <Menu
                      title="Reject Reviews"
                      onClick={() => console.log("Diactive")}
                    />
                    <hr></hr>
                    <Menu title="Archive" onClick={() => console.log("Copy")} />
                  </div>
                </div>
              )}
            </div> */}
          </div>
        </div>
        <Simple
          paginationObject={paginationObject}
          columns={columns}
          data={data}
          Loading={Loading}
          handlePagination={(page, limit) => {
            setPaginationObject({ ...paginationObject, limit, page });
          }}
        />
      </div>
    </>
  );
};
export default Index;
