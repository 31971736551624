/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, shallowEqual } from "react-redux";
import Portal from "../components/portal";
import moment from "moment";
import Modal1 from "../components/modals/modal-1";
import Modal11 from "../components/modals/modal-1-1-2";
import { FiX } from "react-icons/fi";
import CustomerReviewData from "../components/customerReview/tableWithoutPagination";
import ApiClient from "../apis/apiClient";
import ReactStars from "react-rating-stars-component";
import { toast } from "react-toastify";
import { updateReview } from "../apis/constant";

const Modal = ({ title, body, shortDesc, open, setOpen, data, Loading, setIds, getAllReviewListData, allData }) => {
  const adminToken = localStorage.getItem("wed_admin_token");
  const { palettes } = useSelector(
    (state) => ({
      palettes: state.palettes,
    }),
    shallowEqual
  );
  let { background } = {
    ...palettes,
  };

  const modalRef = useRef(null);
  // const [open, setOpen] = useState(false);
  const show = () => {
    setOpen(true);
  };
  const hide = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false;
      if (!open || modalRef.current.contains(event.target)) {
        return false;
      }
      setOpen(!open);
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, modalRef]);
  const RenderDescription = (props) => {
    return (
      <div className="relative">
        <Modal1
          title="Review Description"
          shortDesc={props.cell.row.original?.reviewDescription?.substring(0, 30)}
          body={<div>{props.cell.row.original?.reviewDescription}</div>}
        />
      </div>
    );
  };

  const handleReviewSource = async (source, review) => {
    let myObj = {
      // rating: review.rating,
      // reviewDescription: review.reviewDescription,
      // orderId: review.orderId,
      // title: review.title,
      // vendorId: review?.vendorId?.id,
      // userId: review?.userId?.id,
      status: review.status,
      reviewSource: source
    };

    const res = await ApiClient.patch(
      updateReview + review?.Ogid,
      myObj,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      hide();
      toast.success(res.data.message);
      getAllReviewListData(10, "", 1, "");
    }
  }
  const [columns] = useState([
    {
      Header: "Vendor Id",
      accessor: "alpha2Code",
      Cell: (props) => {
        return <p>{props.cell.row.original?.vendorId?.vendorId}</p>;
      },
      isCheck: true,
    },
    {
      Header: "Vendor Name",
      accessor: "name",
      Cell: (props) => {
        return <p>{props.cell.row.original?.vendorId?.vendorName}</p>;
      },
      isCheck: true,
    },
    {
      Header: "Rating",
      accessor: "rating",
      Cell: (props) => {
        return (
          <ReactStars
            value={props.cell.row.original?.rating}
            count={5}
            size={16}
            activeColor="#ffd700"
            isHalf={true}
            edit={false}
          />
        );
      },
      isCheck: true,
    },

    {
      Header: "Review Description",
      accessor: "reviewDescription",
      Cell: (props) => {
        return <RenderDescription {...props} />;
      },
      isCheck: true,
    },
    {
      Header: "Reviwer Name",
      accessor: "alpha3Code",
      Cell: (props) => {
        return (
          <p>
            {props.cell.row.original?.userId?.brideFirstName +
              " " +
              props.cell.row.original?.userId?.groomFirstName}
          </p>
        );
      },
      isCheck: true,
    },
    {
      Header: "Review Date",

      accessor: "date",
      Cell: (props) => {
        return (
          <p>
            {moment
              .utc(props.cell.row.original?.updatedAt)
              .format("DD-MMM-YYYY")}
          </p>
        );
      },
      isCheck: true,
    },
    {
      Header: "Review Source",
      accessor: "review-source",
      Cell: (props) => {
        return (
          <select
            className="bg-white border-2 w-32 mt-2 py-1 rounded-md"
            value={props.cell.row.original?.reviewSource}
            onChange={(e) => handleReviewSource(e.target.value, props.cell.row.original)}
            disabled={props.cell.row.original.status === 1 || props.cell.row.original.status === 2 ? true : false}
          >
            <option value="">Select..</option>

            <option value={"Google"}>Google</option>
            <option value={"Instagram"}>Instagram</option>
            <option value={"Twitter"}>Twitter</option>
            <option value={"Youtube"}>Youtube</option>
            <option value={"Facebook"}>Facebook</option>
            <option value={"Pinterest"}>Pinterest</option>
            <option value={"Tik Tok"}>Tik Tok</option>
            <option value={"Own Website"}>Own Website</option>
            <option value={"Others"}>Others</option>
          </select>
        );
      },
      isCheck: true,
    },
    {
      Header: "Customer Review Status",
      accessor: "status",
      Cell: (props) => {
        return (
          <Modal11
            title={"Update Status=="}
            body={"Do you really want to update the status?"}
            shortDesc={"hi"}
            props={props}
            handleAction={handleAction}
          />
        );
      },
      isCheck: true,
    },
    // {
    //   Header: "Action",
    //   // accessor: "enddate",
    //   Cell: (props) => {
    //     return (
    //       <button onClick={() => setOpen(true)}>
    //         View

    //       </button>
    //     );
    //   },
    //   isCheck: true,
    // },
    {
      Header: "Import Date",
      accessor: "enddate",
      Cell: (props) => {
        return (
          <p>
            {moment
              .utc(props.cell.row.original?.createdAt)
              .format("DD-MMM-YYYY")}
          </p>
        );
      },
      isCheck: true,
    },
    // {
    //   Header: "Action",
    //   Cell: (props) => {
    //     return <FiSave size={20} className="cursor-pointer mt-1 ml-4" />;
    //   },
    //   isCheck: true,
    // },
  ]);

  const handleAction = async (value, review) => {
    let myObj = {
      // rating: review.rating,
      reviewSource: review.reviewSource,
      // orderId: review.orderId,
      // title: review.title,
      // vendorId: review.vendorId.id,
      status: value,
    };
    const res = await ApiClient.patch(
      updateReview + review?.Ogid,
      myObj,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      hide();
      toast.success(res.data.message);
      getAllReviewListData(10, "", 1, "");
      // getAllReviewListData();
    }
  };
  return (
    <>
      <p className="cursor-pointer" onClick={show}>
        {shortDesc}
      </p>
      {open && (
        <Portal selector="#portal">
          <div className="modal-backdrop fade-in"></div>
          <div
            className={`modal show ${background === "dark" ? "dark-mode" : ""}`}
            data-background={background}
          >
            <div
              className="relative min-w-sm w-auto mx-auto lg:max-w-5xl"
              ref={modalRef}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="text-xl font-semibold">{title}</h3>
                  <button
                    className="modal-close btn btn-transparent"
                    onClick={hide}
                  >
                    <FiX size={18} className="stroke-current" />
                  </button>
                </div>
                <div className="relative p-4 flex-auto">
                  <CustomerReviewData
                    columns={columns}
                    data={data}
                    loading={Loading}
                    // onSubmit={getPageData}
                    showSearch={true}
                    // pagination={paginationObject}
                    // getAllVendorProduct={getAllReviewListData}
                    setIds={setIds}
                  />
                </div>
                <div className="modal-footer space-x-2">
                  <button
                    className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                    type="button"
                    onClick={hide}
                  >
                    Close
                  </button>
                  {/* <button
                    className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                    type="button"
                    onClick={hide}
                  >
                    Save Changes
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default Modal;