/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { BsChevronDown } from "react-icons/bs";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { vendorFormAction } from "../../reducers/vendor.reducer";
import {
  getCategoriesLevelOneAction,
  updateVendorSellerOffering,
} from "../../actions";
import { toast } from "react-toastify";
import moment from "moment";

const FormSellerOffering = ({ isNew, vendor }) => {
  const vendorForm = useSelector((state) => state.vendorSlice.vendorForm);
  // const services = useSelector((state) => state.categories.categories);
  const [serviceOffering, setServiceOffering] = useState([]);
  const [openService, setOpenService] = useState(null);
  const optionRef = useRef(null);
  const selectRef = useRef(null);
  const categories = useSelector(
    (state) => state.vendorSlice.categoriesLevelOne
  );
  const dispatch = useDispatch();
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        optionRef.current &&
        !optionRef.current.contains(event.target) &&
        selectRef.current &&
        !selectRef.current.contains(event.target)
      ) {
        setOpenService(null);
      }
    }
    // Bind the event listener
    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [optionRef]);
  useEffect(() => {
    handleServicesForm();
  }, [categories]);
  const handleChangeForm = (title, value) => {
    if (title === "availability") {
      value = value === "" ? "" : moment(value).format("DD-MMM-YYYY");
    }
    dispatch(
      vendorFormAction({
        ...vendorForm,
        sellerOffering: {
          ...vendorForm.sellerOffering,
          [title]: value,
        },
      })
    );
  };
  const addServiceOffering = () => {
    if (serviceOffering.length < 5) {
      setServiceOffering([
        ...serviceOffering,
        { id: 0, serviceName: "", sub_categories: [] },
      ]);
      dispatch(
        vendorFormAction({
          ...vendorForm,
          sellerOffering: {
            ...vendorForm.sellerOffering,
            services: [
              ...serviceOffering,
              { id: 0, serviceName: "", sub_categories: [] },
            ],
          },
        })
      );
    }
  };

  const removeServiceOffering = (index) => {
    const newServiceOffering = [...serviceOffering];
    newServiceOffering.splice(index, 1);
    setServiceOffering(newServiceOffering);
    dispatch(
      vendorFormAction({
        ...vendorForm,
        sellerOffering: {
          ...vendorForm.sellerOffering,
          services: [...newServiceOffering],
        },
      })
    );
  };
  const handleServicesForm = (value, index) => {
    const newServiceOffering = [...serviceOffering];
    if (vendorForm.sellerOffering?.services?.length !== 0) {
      vendorForm.sellerOffering.services.forEach((item, i) => {
        if (newServiceOffering[index]) {
          newServiceOffering[index].id = categories.find(
            (i) => i.id === item
          )?.id;
          newServiceOffering[index].serviceName = categories.find(
            (i) => i.id === item
          )?.name;
          setServiceOffering(newServiceOffering);
        }

        newServiceOffering.push({
          id: categories.find((cat) => cat.id === item?.id)?.id,
          serviceName: categories.find((i) => i.id === item?.id)?.name,
          sub_categories: [],
        });
        setServiceOffering(newServiceOffering);
      });
    } else {
      newServiceOffering.push({ id: 0, serviceName: "", sub_categories: [] });
      setServiceOffering(newServiceOffering);
    }

    // const finalofferings = newServiceOffering.slice(1);

    dispatch(
      vendorFormAction({
        ...vendorForm,
        sellerOffering: {
          ...vendorForm.sellerOffering,
          services: newServiceOffering,
        },
      })
    );
  };

  const serviceOfferingChange = (item, index) => {
    const newServiceOffering = [...serviceOffering];
    newServiceOffering[index] = {
      ...newServiceOffering[index],
      id: item.id,
      serviceName: item.name,
    };
    setServiceOffering(newServiceOffering);
    dispatch(
      vendorFormAction({
        ...vendorForm,
        sellerOffering: {
          ...vendorForm.sellerOffering,
          services: newServiceOffering,
        },
      })
    );
  };

  return (
    <div className="md:mt-10 mt-5 animate-fade-in-down">
      <div className="flex space-x-2">
        <div className="w-full flex items-center">
          <input
            onChange={() =>
              handleChangeForm(
                "areYouASocialChampion",
                !vendorForm?.sellerOffering?.areYouASocialChampion
              )
            }
            checked={vendorForm?.sellerOffering?.areYouASocialChampion}
            className="h-5 w-5 border rounded-sm"
            type="checkbox"
            id="socialChampion"
          />
          <label htmlFor="socialChampion" className="ml-3 flex cursor-pointer">
            <p>Are you a Social Champion?</p>
          </label>
        </div>
      </div>
      <div className="md:flex mt-5 md:space-x-2 md:space-y-0 space-y-3">
        <div className="w-full space-y-2">
          <p>Price</p>
          <input
            onChange={(e) => handleChangeForm("price", e.target.value)}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
            value={vendorForm.sellerOffering?.price}
          />
        </div>
        <div className="md:w-1/2 space-y-2">
          <p>Pricing Metrics</p>
          <input
            onChange={(e) => handleChangeForm("priceMetrics", e.target.value)}
            value={vendorForm.sellerOffering?.priceMetrics}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          />
        </div>
        <div className="w-full space-y-2">
          <p>Availability</p>
          <input
            onChange={(e) => handleChangeForm("availability", e.target.value)}
            value={vendorForm.sellerOffering?.availability}
            type="date"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2 cursor-pointer"
          />
        </div>
      </div>
      <div className="flex space-x-2 mt-5">
        <div className="w-full space-y-2">
          <p>Select your Primary Service Offering for Weddings</p>
          <div className="space-y-2 select-none">
            {serviceOffering.length > 0 &&
              serviceOffering.map((item, index) => (
                <div key={index}>
                  <div className="flex md:space-x-2 w-full flex-col md:flex-row">
                    <div className="w-full font-medium relative">
                      <div
                        ref={selectRef}
                        onClick={() =>
                          setOpenService(openService === index ? null : index)
                        }
                        className="mt-2 w-full h-10 bg-white cursor-pointer flex px-2 items-center relative border border-bg-primary rounded-sm"
                      >
                        <p className="font-medium">
                          {item.serviceName
                            ? item.serviceName
                            : "Select Service"}
                        </p>
                        <div className="absolute right-0 top-0 h-full flex items-center">
                          <BsChevronDown size={13} />
                        </div>
                      </div>
                      {openService === index && (
                        <div
                          ref={optionRef}
                          className="absolute top-14 px-1 py-2 space-y-2 bg-white rounded-md shadow-lg z-20 h-36 animate-fade-in-down max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-bg-primary scrollbar-track-gray-200 w-full"
                        >
                          {categories.map((xitem, xindex) => {
                            return (
                              <div
                                key={xindex}
                                className="px-4 py-1 cursor-pointer hover:bg-gray-200 rounded-md"
                              >
                                <p
                                  onClick={() => {
                                    serviceOfferingChange(xitem, index);
                                    setOpenService(null);
                                  }}
                                  key={xindex}
                                >
                                  {xitem.name}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    <div className="w-full font-medium">
                      {index === 0 ? (
                        <div
                          onClick={() => addServiceOffering()}
                          className="text-white bg-bg-primary hover:bg-bg-primary-darker mt-2 h-10 w-full cursor-pointer flex rounded-md justify-center items-center px-2"
                        >
                          <AiFillPlusCircle size={25} />
                          <p className="text-center text-sm ml-1">
                            SECONDARY SERVICE OFFERING
                          </p>
                        </div>
                      ) : (
                        <div
                          onClick={() => removeServiceOffering(index)}
                          className="bg-bg-primary hover:bg-bg-primary-darker mt-2 h-10 w-12 flex justify-center items-center cursor-pointer rounded-md text-white"
                        >
                          <AiFillMinusCircle size={25} />
                        </div>
                      )}
                    </div>
                    <div className="w-full flex items-center">
                      {index === 0 && (
                        <p className="italic text-xs">
                          You can add additional service offerings if you are
                          able to offer more than 1 service types
                        </p>
                      )}
                    </div>
                  </div>

                  <hr className="mt-5 mb-3" />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const SellerOffering = ({ isNew }) => {
  const [openForm, setOpenForm] = useState(false);

  const vendor = useSelector((state) => state.vendorSlice.vendor);
  const vendorForm = useSelector((state) => state.vendorSlice.vendorForm);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getCategoriesLevelOneAction({
        onSuccess: (categories) => {},
        onFailed: () => {},
      })
    );

    if (isNew) {
      return;
    }
  }, [vendor]);
  const handleStatus = () => {
    let iserror = false;
    const lookup = vendorForm.sellerOffering.services.reduce((a, e) => {
      a[e.id] = ++a[e.id] || 0;
      return a;
    }, {});
    const isDuplicate = vendorForm.sellerOffering.services.filter(
      (e) => lookup[e.id]
    );
    if (isDuplicate.length > 0) {
      toast.warning("Duplicate offerings are not allowed!");
      return;
    }
    vendorForm.sellerOffering.services.forEach((item) => {
      if (item.id === 0) {
        iserror = true;
      }
    });

    if (vendorForm.sellerOffering.price === "") {
      toast.warning("Please enter price in Service Offerings details");
      return;
    } else if (vendorForm.sellerOffering.priceMetrics === "") {
      toast.warning("Please enter price metrics Service Offerings details");
      return;
    } else if (vendorForm.sellerOffering.availability === "") {
      toast.warning("Please enter availability Service Offerings details");
      return;
    }

    if (iserror) {
      toast.warning("Please select Service Offerings");
      return;
    }
    const newServiceOffering = vendorForm.sellerOffering.services.map((item) =>
      item.id ? item.id : item
    );
    dispatch(
      vendorFormAction({
        ...vendorForm,
        sellerOffering: {
          ...vendorForm.sellerOffering,
          services: newServiceOffering,
        },
      })
    );
    dispatch(
      updateVendorSellerOffering({
        params: {
          id: vendor.id,
        },
        onSuccess: () => {
          iserror = false;
          toast.success("Vendor Updated");
          window.locations.reload();
          // getVendorList(paginationObject, "", "");
        },
        onFailed: () => {},
      })
    );
  };

  return (
    <div className="w-full shadow-xl px-5 py-5 bg-white">
      <div
        onClick={() => setOpenForm(!openForm)}
        className="flex justify-between cursor-pointer"
      >
        <p className="text-xl">Seller Offerings</p>
        <BsChevronDown
          size={25}
          className={
            "text-bg-primary " +
            (openForm ? "rotate-180 transition-all" : "rotate-0 transition-all")
          }
        />
      </div>
      {openForm && <FormSellerOffering isNew={isNew} vendor={vendor} />}
      {!isNew && openForm && (
        <button
          style={{ background: "#990000" }}
          className={` py-2 px-4 mr-2 rounded shadow-sm ${"text-white "} w-40`}
          onClick={() => handleStatus()}
        >
          Update
        </button>
      )}
    </div>
  );
};

export default SellerOffering;
