import React, {useState, useEffect, useRef} from 'react'
import AccountLinks from './account-links'
import { getPreviewUrl } from '../../utils/common'

const Dropdown = () => {
  const [hidden, setHidden] = useState(true)
  const [profilePhoto, setProfilePhoto] = useState("")

  const buttonRef = useRef(null)
  const dropdownRef = useRef(null)

  useEffect(() => {
    setProfilePhoto(getPreviewUrl(localStorage.getItem("wed_admin_profilePhoto")))
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("wed_admin_profilePhoto")])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        hidden ||
        buttonRef.current.contains(event.target) ||
        dropdownRef.current.contains(event.target)
      ) {
        return false
      }
      setHidden(!hidden)
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [hidden, dropdownRef, buttonRef])

  const handleDropdownClick = () => {
    setHidden(!hidden)
  }

  return (
    <div className="relative">
      <button
        ref={buttonRef}
        onClick={handleDropdownClick}
        className="flex h-16 w-8 rounded-full ml-2 relative">
        <span className="absolute top-0 left-0 pt-4">
          <img
            className="h-8 w-8 rounded-full shadow"
            src={profilePhoto}
            alt="avatar"
          />
         
        </span>
      </button>
      <div ref={dropdownRef} 
          className={`dropdown absolute top-0 right-0 mt-16 ${hidden ? '' : 'open'}`}>
          <div className="dropdown-content w-48 bottom-end">
            <AccountLinks />
          </div>
        </div>
    </div>
  )
}

export default Dropdown
