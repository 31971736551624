import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import BankInfo from "../components/vendor-detail/bank-info";
import BusinessOwner from "../components/vendor-detail/business-owner";
import CompensationPlan from "../components/vendor-detail/compensation-plan";
import FAQ from "../components/vendor-detail/faq";
import SellerInfo from "../components/vendor-detail/seller-info";
import SellerOffering from "../components/vendor-detail/seller-offering";
import SocialMedia from "../components/vendor-detail/social-media";
import ValidationChecks from "../components/vendor-detail/validation-checks";
import Settings from "../components/vendor-detail/settings";
import { useDispatch, useSelector } from "react-redux";
import { addVendorAction, vendorForm, vendorGetByIdAction } from "../actions";
import { initialVendor, vendorFormAction } from "../reducers/vendor.reducer";
import moment from "moment";

const ButtonAction = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const vendorForm = useSelector((state) => state.vendorSlice.vendorForm);
  // const vendorForm = useSelector()
  const handleSubmit = () => {
    if(vendorForm.sellerInfo.username 
      === "" || vendorForm.sellerInfo.password 
      === "" || vendorForm.sellerInfo.vendorName
      === "" || vendorForm.sellerInfo.phone
      === "" || vendorForm.sellerInfo.email
      === "" || vendorForm.sellerInfo.streetAddress1
      === "" || vendorForm.sellerInfo.country
      === "" || vendorForm.sellerInfo.postCode
      === "" || vendorForm.sellerInfo.city
      === "" || vendorForm.businessOwner.phone
      === "" || vendorForm.bankInfo.accountName
      === "" || vendorForm.bankInfo.accountNumber
      === "" || vendorForm.bankInfo.bankName
      === "" || vendorForm.bankInfo.bankAddress
      === "" || vendorForm.bankInfo.routingNumber
      === "" || vendorForm.bankInfo.swiftCode
      === "" || vendorForm.bankInfo.iban === ""){

        toast.error("Please fill all details!");
        return
      }
    dispatch(
      addVendorAction({
        onSuccess: () => {
          toast.success("Vendor successfully created!")
          history.push("/vendors");
        },
        onFailed: () => {},
      })
    );
  };

  return (
    <button
      onClick={handleSubmit}
      style={{ background: "#990000" }}
      className={` py-2 px-4 mr-2 rounded shadow-sm ${"text-white "} w-40`}
    >
      Add New
    </button>
  );
};

const VendorDetail = () => {
  const isNew = window.location.pathname === "/vendor-add";
  
  const dispatch = useDispatch();
  useEffect(() => {
    //TODO: add id here

    const idvendor = window.location.pathname;
    const id = idvendor.substring(8);
    if (isNew) {
      dispatch(vendorFormAction(initialVendor));
      return;
    }

    dispatch(
      vendorGetByIdAction({
        onSuccess: (vendor) => {
          dispatch(
            vendorFormAction({
              ...vendorForm,
              sellerInfo: {
                profileSummary: vendor.profileSummary,
                profileImage: vendor.profileImage,
                review: vendor.review,
                website: vendor.website,
                username: vendor.username,
                password: vendor.password,
                vendorName: vendor.vendorName,
                vatTaxNumber: vendor.vatTaxNumber,
                companyName: vendor.companyName,
                companyRegistrationNumber: vendor.companyRegistrationNumber,
                countryCode: vendor.countryCode,
                phone: vendor.phone,
                countryCodeForMobile: vendor.countryCodeForMobile === "" || "+65",
                mobileNumber: vendor.mobileNumber,
                email: vendor.email,
                unitNo: vendor.unitNo,
                buildingName: vendor.buildingName,
                streetAddress1: vendor.streetAddress1,
                streetAddress2: vendor.streetAddress2,
                streetAddress3: vendor.streetAddress3,
                postCode: vendor.postCode,
                country: vendor.country,
                city: vendor.city,
                state: vendor.state,
                blockNumber: vendor.blockNumber,
              },
              businessOwner: {
                firstName: vendor.businessOwnerInformation.firstName,
                lastName: vendor.businessOwnerInformation.lastName,
                email: vendor.businessOwnerInformation.email,
                countryCode: vendor.businessOwnerInformation.countryCode,
                phone: vendor.businessOwnerInformation.phone,
                isPrivacyAndTerms: vendor.isPrivacyAndTerms,
                termsConditions: vendor.termsConditions
              },
             
              socialMedia: {
                facebook: vendor.socialOptions?.facebook,
                flickr: vendor.socialOptions?.flickr,
                instagram: vendor.socialOptions?.instagram,
                linkedIn: vendor.socialOptions?.linkedIn,
                pinterest: vendor.socialOptions?.pinterest,
                twitter: vendor.socialOptions?.twitter,
                youtube: vendor.socialOptions?.youtube,
              },
              bankInfo: {
                accountName: vendor.bankInfo?.accountName,
                accountNumber: vendor.bankInfo?.accountNumber,
                bankAddress: vendor.bankInfo?.bankAddress,
                bankName: vendor.bankInfo?.bankName,
                iban: vendor.bankInfo?.iban,
                routingNumber: vendor.bankInfo?.routingNumber,
                swiftCode: vendor.bankInfo?.swiftCode,
              },
              faq: [
                {
                  answer: vendor.faq.answer,
                  question: vendor.faq.question,
                },
              ],
              validationChecks: {
                fiveYearHistory: vendor?.fiveYearHistory,
                isDirectorOfbankrupt: vendor?.isDirectorOfbankrupt,
                isCaseReview: vendor?.isCaseReview,
                isBadPress: vendor?.isBadPress,
                isBadReviews: vendor?.isBadReviews,
                comment: vendor?.comment,
              },
              settings: {
                enableSelling: vendor.setting?.enableSelling,
                publishProductDirectly: vendor.setting?.publishProductDirectly,
                makeVendorFeatured: vendor.setting?.makeVendorFeatured,
              },
              sellerOffering: {
                ...vendorForm.sellerOffering,
                price: vendor.sellerOffering.price,
                priceMetrics: vendor.sellerOffering.priceMetrics,
                services: vendor.categories,
                areYouASocialChampion: vendor.areYouASocialChampion,
                availability: moment(vendor.sellerOffering?.availability).format("DD-MMM-YYYY")
              },
              compensationPlan: {
                commissionPlan: vendor?.compensationPlan?.commissionPlan
              }
            })
          );
        },
        onFailed: () => {},
        id: id,
      })
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="space-y-3">
      <SellerInfo isNew={isNew} />
      <BusinessOwner isNew={isNew} />
      <SellerOffering isNew={isNew} />
      <CompensationPlan  isNew={isNew}/>
      <SocialMedia isNew={isNew} />

      {/* <CompensationPlan isNew={isNew} /> */}
      {/* <Referral isNew={isNew} /> */}

      <BankInfo isNew={isNew} />
      <FAQ isNew={isNew} />
      <ValidationChecks isNew={isNew} />
      <Settings isNew={isNew} />
      {isNew && (
        <div className="mt-5">
          <ButtonAction />
        </div>
      )}
    </div>
  );
};

export default VendorDetail;
