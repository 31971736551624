/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react";
import SectionTitle from "../components/section-title";
import Widget from "../components/widget";
import AuditTable from "../components/audit/auditTables";
import ApiClient from "../apis/apiClient";
import { getAllListOfDiscounts } from "../apis/constant";
import { Redirect, useParams } from "react-router-dom";
import moment from "moment";
import ReactHTMLTableToExcel from 'react-html-table-to-excel-3';

const Simple = () => {
  const { id } = useParams()

  const [discountList, setDiscountList] = useState([]);
  const [Loading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [paginationObject, setPaginationObject] = useState({});
  const adminToken = localStorage.getItem("wed_admin_token");

  // const getAllVoucherData = async (limit, searchq = "", pageNumber) => {
  //   const data = "";
  //   setIsLoading(true);
  //   // setIsApiCall(true);
  //   const res = await ApiClient.get(
  //     salesVoucher +
  //     `${id}`,
  //     data,
  //     `Bearer ${adminToken}`
  //   );
  //   if (res.data.code === 200) {
  //     setPaginationObject({
  //       limit: res.data.data.limit,
  //       page: res.data.data.page,
  //       pages: res.data.data.pages,
  //       total: res.data.data.total,
  //     });
  //     setDiscountList(res.data.data.track_discount_vouchers);
  //     setIsLoading(false);
  //     // setIsApiCall(false);
  //   }
  // };

  const getAllDiscountData = async (limit, searchq = "", pageNumber) => {
    const data = "";
    setIsLoading(true);
    // setIsApiCall(true);
    const res = await ApiClient.get(
      getAllListOfDiscounts +
      `trackdiscountvoucher/${id}?page=${pageNumber}&sortBy=asc&limit=${limit}`,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      setPaginationObject({
        limit: res.data.data.limit,
        page: res.data.data.page,
        pages: res.data.data.pages,
        total: res.data.data.total,
      });
      setDiscountList(res.data.data.docs);
      setIsLoading(false);
      // setIsApiCall(false);
    }
  };
  useEffect(() => {
    // if(type === 'voucher'){
      // if (search.length > 2 || search.length === 0) {
      //   getAllVoucherData();
      // }
    // }else if(type === 'discount') {
      if (search.length > 2 || search.length === 0) {
        getAllDiscountData(10, "", 1);
      }
    // }
  }, [search]);

  const data = useMemo(() => discountList, [discountList]);

  const Auditcolumns = useMemo(
    () => [
      {
        Header: "Order Id",
        accessor: "orderId.orderId",
      },
      {
        Header: "Order Amount",
        accessor: "orderAmount",
        Cell: (props) => {
          return (
            <p>{parseInt(props.value).toFixed(2)}</p>
          )
        }
      },
      {
        Header: "Vendor ID",
        accessor: "vendorId.vendorId",
      },
      {
        Header: "Vendor Name	",
        accessor: "vendorId.vendorName",
      },
      {
        Header: "Order Date	",
        accessor: "createdAt",
        Cell: (props) => (
          <p>{moment(props.value).format("DD-MMM-YYYY")}</p>
        )
      },
      {
        Header: "Username	",
        accessor: "userId.email",
      },
    ])

  

  const getPageData = (data, type) => {
    if (type === "limit") {
      getAllDiscountData(data, "", 1);
    }
  };


  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
    return (
      <>
        <div className="flex justify-between">
          <div>
            <input
              className="searchCss add_fliter_input"
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div
            className="flex">
            <div
              className="addFilterCss"
              style={{ top: "10px" }}
              >
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white  "
                table="auditTable-to-xls"
                filename="tablexls"
                filetype="xls"
                sheet="tablexls"
                buttonText="Download as XLS" />
            </div>
          </div>
          
        </div>
        
        <AuditTable
          columns={Auditcolumns}
          data={data}
          loading={Loading}
          pagination={paginationObject}
          onSubmit={getPageData}
          showSearch={true}
          getAllDiscountData={getAllDiscountData}
          setIds={() => {}}
        />
      </>
    );
  }
};

const AuditPage = () => (
  <>
    <SectionTitle title="" subtitle="Audit Report" />

    <Widget>
      <Simple />
    </Widget>
  </>
);
export default AuditPage;
