import React, { useEffect, useState } from "react";
import SectionTitle from "../components/section-title";
import VoucherTableData from "../components/salesVoucher";
// import DiscountsDataTable from "../components/discounts";
import Widget from "../components/widget";
import { toast } from "react-toastify";
import Modal2 from "../components/confirm-delete-modal/modal-2";
import { FiX } from "react-icons/fi";
import ModalActivate from "../components/confirm-activate-modal/modal-activate";
import ApiClient from "../apis/apiClient";
import { salesVoucher } from "../apis/constant";
import { Link, Redirect } from "react-router-dom";
import moment from "moment";
import { checkAccess } from "../utils/common";
import CsvDowloader from "../components/CsvDowloader";
import { IoMdClose } from "react-icons/io";

const usdFormatter = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

const Simple = () => {
  const [voucherList, setvoucherList] = useState([]);
  const [Loading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [paginationObject, setPaginationObject] = useState({});
  const adminToken = localStorage.getItem("wed_admin_token");

  const getAllVoucherData = async (limit, searchq = "", pageNumber) => {
    const data = "";
    setIsLoading(true);
    try {
      const res = await ApiClient.get(
        salesVoucher +
          `?page=${pageNumber}&sortBy=asc&limit=${limit}&searchBy=${search}`,
        data,
        `Bearer ${adminToken}`
      );
      if (res.data.code === 200) {
        let no =
          res.data.data.total -
          (res.data.data.page - 1) * res.data.data.limit +
          1;
        if (res.data.data && res.data.data.docs.length !== 0) {
          const tempData = res.data.data.docs;
          tempData.forEach((element) => {
            no--;
            element.voucherNo = no;
            if (element.vendorId) {
              element["vendorNameAndPlatFormAdmin"] =
                element.vendorId.vendorName;
              element.vendorId = element.vendorId.vendorId;
            } else {
              element["vendorNameAndPlatFormAdmin"] = "Platform Admin";
              element.vendorId = "NA";
            }
            element["displayStatus"] =
              element.status === 0 ? "Inactive" : "Active";
            element["startDate"] = element.expiry.dateTime
              ? moment(element.expiry.dateTime).format("DD-MMM-YYYY")
              : null;
            element["startTime"] = element.expiry.dateTime
              ? moment(element.expiry.dateTime).format("hh:mm A")
              : null;
            element["endDate"] = element.expiry.endDateTime
              ? moment(element.expiry.endDateTime).format("DD-MMM-YYYY")
              : null;
            element["endTime"] = element.expiry.endDateTime
              ? moment(element.expiry.endDateTime).format("hh:mm A")
              : null;
            element["updatedAt"] = moment(element.updatedAt).format(
              "DD-MMM-YYYY HH:mm"
            );
            element["createdAt"] = moment(element.createdAt).format(
              "DD-MMM-YYYY HH:mm"
            );
            element["totalUsage"] = element.track_discount_vouchers.length;
            element.discountValue =
              element.type === "PERCENT"
                ? `${element.discountValue}%`
                : usdFormatter(element.discountValue);
            element["minimumPurchase"] = usdFormatter(
              element.minimumPurchase ?? 0
            );
            element["maxUsage"] =
              element.maximumUsage === -1 ? "Unlimited" : element.maximumUsage;
            element.eligibility =
              element.customerEligibility.to === "everyOne"
                ? "Everyone"
                : "Specific Customers";
            element.expiryOpt =
              element.expiry.options === "noEndData"
                ? "No End Date"
                : "Select a Date and Time Range";
          });
          setvoucherList(tempData);
        }
        setPaginationObject({
          limit: res.data.data.limit,
          page: res.data.data.page,
          pages: res.data.data.pages,
          total: res.data.data.total,
        });
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      setvoucherList([]);
    }
  };
  useEffect(() => {
    if (search.length > 2 || search.length === 0) {
      getAllVoucherData(10, search, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const deleteVoucher = async (voucherList) => {
    const id = voucherList.original.id;
    const data = "";
    try {
      const res = await ApiClient.delete(
        salesVoucher + id,
        data,
        `Bearer ${adminToken}`
      );
      if (res.data.code === 200) {
        toast.success(" Voucher is Deleted Successfully");
        getAllVoucherData(10, "", 1);
      } else {
        toast.error(res.data?.message ?? "Error occured please try again");
      }
    } catch (e) {
      toast.error(e?.message ?? "Error occured please try again");
    }
  };

  const handleActive = async (status, data) => {
    let myObj = {
      status: status,
    };
    const res = await ApiClient.patch(
      salesVoucher + "/" + data.id,
      myObj,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success("Status is updated successfully");
      getAllVoucherData(10, "", 1);
    } else {
      toast.warning("Error");
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "No",
        accessor: "voucherNo",
      },
      {
        Header: "Vendor Name / Platform Admin",
        accessor: "vendorNameAndPlatFormAdmin",
      },
      {
        Header: "Vendor Id",
        accessor: "vendorId",
      },
      {
        Header: "Voucher Code",
        accessor: "code",
      },
      {
        Header: "Voucher Name",
        accessor: "voucherName",
      },
      {
        Header: "Voucher Type",
        accessor: "voucherType",
      },
      {
        Header: "Discount Type",
        accessor: "discountType",
      },
      {
        Header: "Discount Value	",
        accessor: "discountValue",
      },
      {
        Header: "Min Purchase Amount",
        accessor: "minimumPurchase",
      },
      {
        Header: "Maximum Usage",
        accessor: "maxUsage",
      },
      {
        Header: "Eligibility",
        accessor: "eligibility",
      },
      {
        Header: "Expiry Option",
        accessor: "expiryOpt",
        Cell: (row) => {
          return <span className="capitalize">{row.value}</span>;
        },
      },
      {
        Header: "Start Date",
        accessor: "startDate",
      },

      {
        Header: "Start Time",
        accessor: "startTime",
      },
      {
        Header: "End Date",
        accessor: "endDate",
      },

      {
        Header: "End Time",
        accessor: "endTime",
      },
      {
        Header: "Created At	",
        accessor: "createdAt",
      },
      {
        Header: "UpdatedAt",
        accessor: "updatedAt",
      },
      {
        Header: "Total Usage",
        accessor: "totalUsage",
        Cell: (data) => (
          <Link
            to={`/audit-report/voucher/${data?.row.original.id}`}
            className="underline"
          >
            {data?.value}{" "}
          </Link>
        ),
      },
      {
        Header: "Status",
        accessor: "displayStatus",
      },
      {
        Header: "Action",
        id: "delete",
        accessor: (str) => {},
        show: false,
        Cell: (Datatable) => (
          <>
            {checkAccess(28, "edit") && (
              <Link
                to={`/sales/editVoucher/${Datatable.row.original.id}`}
                buttonClassName="underline"
              >
                Edit{" "}
              </Link>
            )}
            {checkAccess(28, "delete") && (
              <Modal2
                title="Delete"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to delete this Discounts?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  deleteVoucher(Datatable.row);
                }}
                buttonClassName="underline"
              />
            )}{" "}
            |{" "}
            {Datatable.row.original.status === 0
              ? checkAccess(28, "edit") && (
                  <ModalActivate
                    title="Activate"
                    icon={
                      <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                        <FiX
                          size={18}
                          className="stroke-current text-red-500"
                        />
                      </span>
                    }
                    body={
                      <div className="text-sm text-grey-500">
                        Are You Sure! You want to Deactivate this Voucher?
                      </div>
                    }
                    buttonTitle="Confirm"
                    onClick={() => {
                      handleActive(1, Datatable.cell.row.original);
                    }}
                    buttonClassName="underline"
                  />
                )
              : checkAccess(28, "edit") && (
                  <ModalActivate
                    title="Deactivate"
                    icon={
                      <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                        <FiX
                          size={18}
                          className="stroke-current text-red-500"
                        />
                      </span>
                    }
                    body={
                      <div className="text-sm text-grey-500">
                        Are You Sure! You want to Active this Voucher?
                      </div>
                    }
                    buttonTitle="Confirm"
                    onClick={() => {
                      handleActive(0, Datatable.cell.row.original);
                    }}
                    buttonClassName="underline"
                  />
                )}
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getPageData = (data, type) => {
    // if (type === "limit") {
    getAllVoucherData(data, "", 1);
    // }
  };

  const data = React.useMemo(() => voucherList, [voucherList]);

  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
    return (
      <>
        <div className="flex justify-between">
          <div className="flex justify-center items-center searchCss add_fliter_input w-96">
            <input
              className="w-full bg-transparent"
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <IoMdClose
              size={20}
              onClick={() => setSearch("")}
              className="cursor-pointer"
            />
          </div>

          <div className="flex items-center">
            <CsvDowloader columns={columns} data={data} filename="vouchers" />
            {checkAccess(28, "add") && (
              <Link
                to={"/sales/addVoucher/"}
                style={{ top: "2px" }}
                className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded addFilterCss"
              >
                Add Voucher
              </Link>
            )}
          </div>
        </div>
        <VoucherTableData
          columns={columns}
          data={data}
          loading={Loading}
          pagination={paginationObject}
          onSubmit={getPageData}
          showSearch={true}
          getAllVoucherData={getAllVoucherData}
        />
      </>
    );
  }
};

const SalesVoucher = () => (
  <>
    <SectionTitle title="Vouchers" subtitle="Voucher" />

    <Widget>
      <Simple />
    </Widget>
  </>
);
export default SalesVoucher;
