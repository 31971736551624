import { createSlice} from "@reduxjs/toolkit";
import { getCategoriesLevelOne, getCategoriesAll } from "../actions";

const initialState = {
  selectedCategory: null,
  categories: [],
  categoryList: [],
  isFetching: false,
  error: null,
};

export const categories = createSlice({
  name: "categories",
  initialState,
  reducers: {
    insertSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
  },
  extraReducers: {
    //category level one
    [getCategoriesLevelOne.pending]: (state, action) => {
      state.isFetching = true;
      state.error = null;
    },
    [getCategoriesLevelOne.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.categories = action.payload;
    },
    [getCategoriesLevelOne.rejected]: (state, action) => {
      state.isFetching = false;
      state.error = action.error.message;
    },
    //category all
    [getCategoriesAll.pending]: (state, action) => {
      state.isFetching = true;
      state.error = null;
    },
    [getCategoriesAll.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.categoryList = action.payload;
    },
    [getCategoriesAll.rejected]: (state, action) => {
      state.isFetching = false;
      state.error = action.error.message;
    },
  },
});

export const { insertSelectedCategory } = categories.actions;

export default categories.reducer;