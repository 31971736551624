/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SectionTitle from "../components/section-title";
import Faqtable from "../components/faq-details";
import Widget from "../components/widget";
import { toast } from "react-toastify";
import Modal2 from "../components/confirm-delete-modal/modal-2";
import { FiX } from "react-icons/fi";
import ModalActivate from "../components/confirm-activate-modal/modal-activate";
import ApiClient from "../apis/apiClient";
import {
  API_GetAllFaqTypes,
} from "../apis/constant";
import AddFaqCategory from "../components/faq-details/addFaqType";
import EditFaqList from "../components/faq-details/editFaqType";
import { Redirect, Link } from "react-router-dom";

const Simple = () => {
  const [Loading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [paginationObject, setPaginationObject] = useState({});
  const [allFaqTypes, setAllFaqTypes] = useState([]);
  const adminToken = localStorage.getItem("wed_admin_token");

 
  const getAllFaqTypes = async (limit, searchq = "", pageNumber) => {
    const data = "";
    setIsLoading(true);
    // setIsApiCall(true);
    const res = await ApiClient.get(
        API_GetAllFaqTypes +
        `?page=${pageNumber}&sortBy=asc&limit=${limit}&searchBy=${searchq}`,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      // if (res.data.data && res.data.data.docs.length !== 0) {
      //   for (let i = 0; i < res.data.data.docs.length; i++) {
      //     const element = res.data.data.docs[i];
      //   }
      // }
      setPaginationObject({
        limit: res.data.data.limit,
        page: res.data.data.page,
        pages: res.data.data.pages,
        total: res.data.data.total,
      });
      setAllFaqTypes(res.data.data.docs);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (search.length > 2 || search.length === 0) {
        getAllFaqTypes(10, search, 1);
    }
  }, [search]);

  const deleteFaq = async (faqData) => {
    const id = faqData.original.id;
    const data = "";
    const res = await ApiClient.delete(
        API_GetAllFaqTypes + id,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllFaqTypes(10, "", 1);
    } else {
      toast.warning(res.data.message);
    }
  };

  const handleActive = async (status, data) => {
    let myObj = {
      status: status,
    };
    const res = await ApiClient.patch(
        API_GetAllFaqTypes + "/" + data.id,
      myObj,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllFaqTypes(10, "", 1);
    } else {
      toast.warning(res.data.message);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Faq Type",
        accessor: "type",
      },
      
      {
        Header: "Status",
        accessor: "status",
        Cell: (Datatable) => (
            <>
            {Datatable.value === 0 ? "Deactive": "Active"}
            </>
        )
      },
      {
        Header: "Action",
        id: "delete",
        accessor: (str) => {},

        Cell: (Datatable) => (
          <>
            <EditFaqList
              id={Datatable.row.original.id}
              title={"Edit Faq"}
              body={"body"}
              getApi={getAllFaqTypes}
              data={Datatable.row.original}
            />{" "}
            |{" "}
            <Modal2
              title="Delete"
              icon={
                <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                  <FiX size={18} className="stroke-current text-red-500" />
                </span>
              }
              body={
                <div className="text-sm text-grey-500">
                  Are You Sure! You want to delete this Faq?
                </div>
              }
              buttonTitle="Confirm"
              onClick={() => {
                deleteFaq(Datatable.row);
              }}
              buttonClassName="underline"
            />{" "}
            |{" "}
            {Datatable.row.original.status === 0 ? (
              <ModalActivate
                title="Activate"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to Activate this Faq?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  handleActive(1, Datatable.cell.row.original);
                }}
                buttonClassName="underline"
              />
            ) : (
              <ModalActivate
                title="Deactivate"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to Deactivate this Faq?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  handleActive(0, Datatable.cell.row.original);
                }}
                buttonClassName="underline"
              />
            )}
          </>
        ),
      },
    ],
    []
  );

  const getPageData = (data, type) => {
    // if (type === "limit") {
        getAllFaqTypes(data, "", 1);
    // }
  };

  const data = React.useMemo(() => allFaqTypes, [allFaqTypes]);
  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
    return (
      <>
        <div className="flex justify-between faq_input_btn_sec">
          <div className="input_btn_box">
            <input
              className="searchCss"
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="btn_box">
            <AddFaqCategory title={"Add Faq Category"} body={"body"} getApi={getAllFaqTypes} />
            <Link
            to={"/faq-details"}
            className="btn btn-default bg-red-500 hover:bg-red-600 text-white btn-rounded ml-3"
          >
            Back
          </Link>
          </div>
        </div>
        <Faqtable
          columns={columns}
          data={data}
          loading={Loading}
          pagination={paginationObject}
          onSubmit={getPageData}
          showSearch={true}
          getAllFaq={getAllFaqTypes}
        />
      </>
    );
  }
};

const Index = () => (
  <>
    <SectionTitle title="" subtitle=" FAQs Type" />

    <Widget>
      <Simple />
    </Widget>
  </>
);
export default Index;
