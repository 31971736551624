import React from 'react'

import axios from "axios";

import { formatCurrency } from "../../functions/numbers";
import { url, API_Dashboard } from "../../apis/constant";
import Datatable from "../datatable";

const Vendor = ({startDate,endDate}) => {

  const [apiData, setApiData] = React.useState([]);
  const columnsVendors = [
    {
      Header: "Top 10 Vendors",
      accessor: "vendorName",
    },
    {
      Header: "Total Sales",
      accessor: "totalSales",
    },
    {
      Header: "Total Orders",
      accessor: "totalOrders",
    },
    {
      Header: "Ave Purchase",
      accessor: "avgPurchase",
    },
  ];

  const columnsVendorsBottom = [
    {
      Header: "Bottom 10 Vendors",
      accessor: "vendorName",
    },
    {
      Header: "Total Sales",
      accessor: "totalSales",
    },
    {
      Header: "Total Orders",
      accessor: "totalOrders",
    },
    {
      Header: "Ave Purchase",
      accessor: "avgPurchase",
    },
  ];

  const columnsVendorsZeroSales = [
    {
      Header: "Vendor with $0 Sales",
      accessor: "vendorName",
    },
    {
      Header: "Total Sales",
      accessor: "totalSales",
    },
    {
      Header: "Total Orders",
      accessor: "totalOrders",
    },
    {
      Header: "Ave Purchase",
      accessor: "avgPurchase",
    },
  ];

  React.useEffect(() => {
    const adminToken = localStorage.getItem("wed_admin_token");
    try {
      axios.get(url + API_Dashboard.vendor, {
        params: {startDate, endDate},
        headers: {
          Authorization: `Bearer ${adminToken}`
        }
      }).then(res => {
        const data = res.data.data;
        setApiData(data);
      }).catch(err => {
        console.log(err);
      })
    } catch (error) {
      console.log(error);
    }
  }, [startDate,endDate]);

  return (
    <div className="animate-fade-in-down">

      <div id="vendor-summary" className="p-2 bg-white border rounded-md text-xl my-4">
        <div className="flex justify-between my-2">
          <p className="self-center">No of Vendors added</p>
          <input readOnly className="border shadow-sm rounded-md p-2 w-3/5" value={apiData.summary ? apiData.summary.vendorActiveCount : ''} style={{ cursor: 'default' }} />
        </div>
        <div className="flex justify-between my-2">
          <p className="self-center">No of Vendors pending Approval</p>
          <input readOnly className="border shadow-sm rounded-md p-2 w-3/5" value={apiData.summary ? apiData.summary.vendorPendingCount : ''} style={{ cursor: 'default' }} />
        </div>
        <div className="flex justify-between my-2">
          <p className="self-center">
            No of Approved Vendors with no Stripe ID
                </p>
          <input readOnly className="border shadow-sm rounded-md p-2 w-3/5" value={apiData.summary ? apiData.summary.vendorActiveWithoutStripeCount : ''} style={{ cursor: 'default' }} />
        </div>
        <div className="flex justify-between my-2">
          <p className="self-center">Total Vendors to date</p>
          <input readOnly className="border shadow-sm rounded-md p-2 w-3/5" value={apiData.summary ? apiData.summary.totalActiveAndPendingVendorCount : ''} style={{ cursor: 'default' }} />
        </div>
      </div>

      <div id="top-10" className="p-2 bg-white border rounded-md text-xl">
        <Datatable columns={columnsVendors}
          data={apiData.topVendor && apiData.topVendor.top10Vendor ? apiData.topVendor.top10Vendor.map(v => ({
            vendorName: v.vendorId,
            totalSales: formatCurrency(v.totalSales),
            totalOrders: v.totalOrdersCount,
            avgPurchase: formatCurrency(v.averageSales)
          })) : []}
          onCheck={(data) => { }}
          showResponseData={false}
          showPagination={false}
          showSelection={false}
        />
      </div>

      <div id='bottom-10' className="p-2 bg-white border rounded-md text-xl mt-5">
        <Datatable columns={columnsVendorsBottom}
          data={apiData.topVendor?.bottom10Vendor ? apiData.topVendor.bottom10Vendor.map(v => ({
            vendorName: v.vendorId,
            totalSales: formatCurrency(v.totalSales),
            totalOrders: v.totalOrdersCount,
            avgPurchase: formatCurrency(v.averageSales)
          })) : []}
          onCheck={(data) => { }}
          showResponseData={false}
          showPagination={false}
          showSelection={false}
        />
      </div>

      <div id='no-sales' className="p-2 bg-white border rounded-md text-xl mt-5">
        <Datatable columns={columnsVendorsZeroSales}
          data={apiData.topVendor?.vendorWithoutSales ? apiData.topVendor.vendorWithoutSales.map(v => ({
            vendorName: v.vendorId,
            totalSales: formatCurrency(v.totalSales),
            totalOrders: v.totalOrdersCount,
            avgPurchase: formatCurrency(v.averageSales)
          })) : []}
          onCheck={(data) => { }}
          showResponseData={false}
          showPagination={false}
          showSelection={false}
        />
      </div>
    </div>
  )
}

export default Vendor
