/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SectionTitle from "../components/section-title";
import VendorProductPageData from "../components/manageProduct";
import Widget from "../components/widget";
import { toast } from "react-toastify";
import Modal2 from "./../components/confirm-delete-modal/modal-2";
import { FiX } from "react-icons/fi";
import ApiClient from "../apis/apiClient";
import { Link, Redirect } from "react-router-dom";
import AsyncSelect from "react-select/async";

import {
  API_ManageProductList,
  API_UpdateManageProductStatus,
  API_DeleteProductById,
  API_MasterCategoryList,
  API_GetAllCategory,
  API_GetAllSubCategory,
} from "../apis/constant";
import "nprogress/nprogress.css";
import "../css/custome.css";

const Simple = () => {
  const [vendorPrdoducts, setvendorPrdoducts] = useState([]);
  const [Loading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const adminToken = localStorage.getItem("wed_admin_token");
  const [subCatList, setSubCategoryList] = useState([]);
  const [paginationObject, setPaginationObject] = useState({});
  const [categryList, setCategoryList] = useState([]);

  const [selectedMasterCategoryValue, setSelectedMasterCategoryValue] =
    useState(null);
  const [selectedCategoryValue, setSelectedCategoryValue] = useState(null);
  const [selectedSubCategoryValue, setSelectedSubCategoryValue] =
    useState(null);

  const getAllVendorProduct = async (limit, searchq = "", pageNumber) => {
    setvendorPrdoducts([]);
    const data = "";
    setIsLoading(true);
    let base_uri =
      API_ManageProductList +
      `?page=${pageNumber}&sortBy=asc&limit=${limit}&searchBy=${searchq}`;
    if (selectedSubCategoryValue !== null) {
      base_uri = base_uri + `&level3=${selectedSubCategoryValue.id}`;
    }
    if (selectedCategoryValue !== null) {
      base_uri = base_uri + `&level2=${selectedCategoryValue.id}`;
    }
    if (selectedMasterCategoryValue !== null) {
      base_uri =
        base_uri + `&level1=${selectedMasterCategoryValue.id}`;
    }
    const res = await ApiClient.get(base_uri, data, `Bearer ${adminToken}`);

    if (res.data.code === 200) {
      if (res.data.data && res.data.data.docs.length !== 0) {
        for (let i = 0; i < res.data.data.docs.length; i++) {
          let element = res.data.data.docs[i];
          res.data.data.docs[i]["level1"] = element.hasOwnProperty(
            "productCategory"
          )
            ? element.productCategory !== null
              ? element.productCategory.level1?.name
              : ""
            : "";
          res.data.data.docs[i]["level2"] = element.hasOwnProperty(
            "productCategory"
          )
            ? element.productCategory !== null
              ? element.productCategory.level2?.name
              : ""
            : "";
          res.data.data.docs[i]["level3"] = element.hasOwnProperty(
            "productCategory"
          )
            ? element.productCategory !== null
              ? element.productCategory.level3?.name
              : ""
            : "";
        }
      }
      setPaginationObject({
        limit: res.data.data.limit,
        page: res.data.data.page,
        pages: res.data.data.pages,
        total: res.data.data.total,
      });
      setvendorPrdoducts(res.data.data.docs);
      setIsLoading(false);
    }
  };

  

  useEffect(() => {
    if (search.length > 2 || search.length === 0) {
      getAllVendorProduct(10, search, 1);
    }
  }, [
    search,
    selectedMasterCategoryValue,
    selectedCategoryValue,
    selectedSubCategoryValue,
  ]);

  const handleAMasterCategory = async (e) => {
    const id = e.id;
    setSelectedMasterCategoryValue(e);
    if (id === "") {
      setCategoryList([]);

      return;
    }
    localStorage.setItem("masterId", id);
    getCategoryListUsingMasterId(id, "");
  };

  const MasterCategoryList = async ( search) => {
    const data = "";
    const res = await ApiClient.get(
      API_MasterCategoryList +
        `?page=${1}&sortBy=asc&limit=${10}&searchBy=${search}&isFor=dropdown`,
      data,
      `Bearer ${adminToken}`
    );

    if (res.data.code === 200) {
      // debugger
      return res.data.data.docs;
    }
  };
  useEffect(() => {
    MasterCategoryList( "");
  }, []);
  const getCategoryListUsingMasterId = async (id, inputValue) => {
    var masterId = id ? id : localStorage.getItem("masterId");
    const res = await ApiClient.get(
      API_GetAllCategory +
        `?masterCategoryId=${masterId}&page=1&sortBy=asc&limit=${10}&searchBy=${inputValue}`,
      {},
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      let full_arr = res.data.data.docs;
      setCategoryList(full_arr);
      return full_arr;
    }
  };

  const getSubCategoryListUsingCategoryId = async (id, inputValue) => {
    var categoryId = id ? id : localStorage.getItem("categoryId");
    const res = await ApiClient.get(
      API_GetAllSubCategory +
        `?categoryId=${categoryId}&page=1&sortBy=asc&limit=${10}&searchBy=${inputValue}`,
      {},
      `Bearer ${adminToken}`
    );

    if (res.data.code === 200) {
      let full_arr = res.data.data.docs;
      setSubCategoryList(full_arr);
      return full_arr;
    }
  };
  const handleASubCategory = (e) => {
    setSelectedSubCategoryValue(e);
  };

  const handleACategory = async (e) => {
    const id = e.id;
    setSelectedCategoryValue(e);

    if (id === "") {
      setSubCategoryList([]);
      return;
    }

    localStorage.setItem("categoryId", id);
    getSubCategoryListUsingCategoryId(id, "");
  };

  const ClearFilters = () => {
    setSelectedMasterCategoryValue(null);
    setSelectedCategoryValue(null);
    setSelectedSubCategoryValue(null);
    getAllVendorProduct(10, "", 1);
  };

  const handleActive = async (status, data) => {
    let myObj = {
      status: status,
      ids: [data.id],
    };

    const res = await ApiClient.post(
      API_UpdateManageProductStatus,
      myObj,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllVendorProduct(10, "", 1);
    } else {
      toast.warning(res.data.message);
    }
  };

  const deleteVendorProduct = async (userData) => {
    const id = userData.original.id;
    const data = "";
    const res = await ApiClient.delete(
      API_DeleteProductById + id,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllVendorProduct(10, "", 1);
    } else {
      toast.warning(res.data.message);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: " Name",
        accessor: "productTitle",
      },
      {
        Header: " Level 1",
        accessor: "level1",
      },
      {
        Header: "Level 2",
        accessor: "level2",
      },
      {
        Header: "Level 3",
        accessor: "level3",
      },
      {
        Header: "Status",
        id: "status",
        accessor: (str) => {
          return str["status"] === 1 ? (
            <label>Active</label>
          ) : (
            <label>Inactive</label>
          );
        },
      },

      {
        Header: "Action",
        id: "delete",

        Cell: (Datatable) => (
          <>
            <Link
              to={`/editvendor/${Datatable.row.original.id}`}
              className="underLineText"
            >
              Edit
            </Link>{" "}
            |{" "}
            <Modal2
              title="Delete"
              icon={
                <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                  <FiX size={18} className="stroke-current text-red-500" />
                </span>
              }
              body={
                <div className="text-sm text-grey-500">
                  Are You Sure! You want to delete this Vendor Product?
                </div>
              }
              buttonTitle="Confirm"
              onClick={() => {
                deleteVendorProduct(Datatable.row);
              }}
              buttonClassName="underLineText"
            />{" "}
            |{" "}
            {Datatable.cell.row.original.status === 1 ? (
              <Modal2
                title="Deactivate"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to Deactivate this Vendor Product?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  handleActive(0, Datatable.cell.row.original);
                }}
                buttonClassName="underLineText"
              />
            ) : (
              <Modal2
                title="Activate"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to Activate this Vendor Product?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  handleActive(1, Datatable.cell.row.original);
                }}
                buttonClassName="underLineText"
              />
            )}
          </>
        ),
      },
    ],
    []
  );

  const getPageData = (data) => {
    getAllVendorProduct(data, "", 1);
  };

  const data = React.useMemo(() => vendorPrdoducts, [vendorPrdoducts]);
  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
    return (
      <>
        <div>
          <input
            className="searchCss manage_input"
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <div className="inline-block flex-col manage_product_box">
            <p className="filterCategoryCss">Level 1</p>
            <div className="masterFilterCss ">
            <AsyncSelect
                className="w-100"
                cacheOptions
                defaultOptions
                value={selectedMasterCategoryValue}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.id}
                loadOptions={MasterCategoryList}
                onChange={handleAMasterCategory}
              />
            </div>
          </div>
          <div className="inline-block flex-col  manage_product_box">
            <p className="filterCategoryCss">Level 2</p>
            <div className="masterFilterCss ">
              <AsyncSelect
                className="w-100"
                key={categryList.length}
                cacheOptions
                defaultOptions
                value={selectedCategoryValue}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.id}
                loadOptions={(e) => getCategoryListUsingMasterId(e.id, "")}
                onChange={handleACategory}
              />
            </div>
          </div>
          <div className="inline-block flex-col  manage_product_box">
            <p className="filterCategoryCss">Level 3</p>
            <div className="masterFilterCss ">
              <AsyncSelect
                className="w-100"
                key={subCatList.length}
                cacheOptions
                defaultOptions
                value={selectedSubCategoryValue}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.id}
                loadOptions={(e) => getSubCategoryListUsingCategoryId(e.id, "")}
                onChange={handleASubCategory}
              />
            </div>
          </div>
          <button
            className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white"
            onClick={() => ClearFilters()}
          >
            Clear Filters
          </button>
        </div>

        <VendorProductPageData
          columns={columns}
          data={data}
          loading={Loading}
          onSubmit={getPageData}
          showSearch={true}
          pagination={paginationObject}
          getAllVendorProduct={getAllVendorProduct}
        />
      </>
    );
  }
};

const ManageProduct = () => (
  <>
    <SectionTitle title="Manage Vendor Product" subtitle="Vendor Product " />
    <Widget>
      <Simple />
    </Widget>
  </>
);
export default ManageProduct;
