import React, { useState } from "react";
import PhysicalMeeting from "../physicalMeeting";
import { MdContentCopy } from "react-icons/md";

const TypeOfMeeting = ({ eventForm, setEventForm }) => {
  const [meetingType, setMeetingType] = useState("offline");

  return (
    <div>
      <div className="w-full flex items-center">
        <p>Type of Meeting:</p>
        <div className="ml-5 mt-0.5 flex items-center space-x-5">
          <label className="flex items-center">
            <input
              onChange={() => setMeetingType("offline")}
              checked={meetingType === "offline"}
              type="radio"
              className="mr-2 h-5 w-5"
            />
            <span>Offline Meeting</span>
          </label>
          <label className="flex items-center">
            <input
              onChange={() => setMeetingType("online")}
              checked={meetingType === "online"}
              type="radio"
              className="mr-2 h-5 w-5"
            />
            <span>Online Meeting</span>
          </label>
        </div>
      </div>
      <div className="mt-3">
        {meetingType === "offline" ? (
          <PhysicalMeeting />
        ) : (
          <div className="w-full bg-bg-serenade p-2 flex items-center">
            <p>Meeting Link: https://meet.google.com/</p>
            <MdContentCopy
              size={22}
              className="ml-2 cursor-pointer"
              title="Copy Meeting Link"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TypeOfMeeting;
