/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";

const ModalAddSectionCourse = ({
  setOpen,
  handleAddSection,
  handleUpdateSection,
  level,
  selectedItem,
  isAdd = false,
}) => {
  const hide = () => {
    setOpen(false);
  };

  const initialCourseData = {
    title: "",
    slug: "",
    level: "",
    video_link: "",
    description: "",
    sub_section_header: [],
  };
  const [courseData, setCourseData] = useState(initialCourseData);

  useEffect(() => {
    if (selectedItem && !isAdd) {
      setCourseData({ ...selectedItem });
    }
  }, [selectedItem]);

  const handleSave = () => {
    if (selectedItem && !isAdd) {
      return handleUpdateSection(courseData);
    }
    return handleAddSection(courseData);
  };

  const handleChangeCourse = (e) => {
    const params = { ...courseData };
    params.title = e.target.value;
    params.slug = e.target.value.replace(/\s+/g, "-").toLowerCase();
    params.level = level;
    setCourseData(params);
  };

  return (
    <>
      <div className="modal-content">
        <div className="modal-header">
          <h3 className="text-xl font-semibold">
            {selectedItem && !isAdd ? "Update" : "Add"} Section
          </h3>
          <button className="modal-close btn btn-transparent" onClick={hide}>
            <FiX size={18} className="stroke-current" />
          </button>
        </div>
        <div className="relative py-4 px-6 flex-auto">
          <div className="flex flex-col w-96">
            <label className="mb-1">Title</label>
            <input
              value={courseData?.title}
              onChange={handleChangeCourse}
              type="text"
              className="border border-gray-300 rounded-md p-2"
              placeholder="Title"
            />
          </div>
          <div className="flex flex-col w-96 mt-4">
            <label className="mb-1">Video Link</label>
            <input
              value={courseData?.video_link}
              onChange={(e) => {
                setCourseData({
                  ...courseData,
                  video_link: e.target.value,
                });
              }}
              type="text"
              className="border border-gray-300 rounded-md p-2"
              placeholder="Video Link"
            />
          </div>
        </div>
        <div className="modal-footer space-x-2">
          <button
            className="btn btn-default btn-rounded bg-white hover:bg-grey-200 border border-grey-200"
            type="button"
            onClick={hide}
          >
            Close
          </button>
          <button
            className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
            type="button"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default ModalAddSectionCourse;
