/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { useOutsideClick } from "../../hooks/OutsideClick";

const SelectArrayItem = ({ customStyle, value, onChange, option, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [optionList, setOptionList] = useState(option || []);
  const [valueTemp, setValueTemp] = useState(value || "");
  const ref = useOutsideClick(() => setIsOpen(false));

  return (
    <div ref={ref} className="relative w-full">
      <div className={`flex justify-between items-center bg-white ${customStyle}`}>
        <input
          onChange={(e) => setValueTemp(e.target.value)}
          // onKeyUp={filterSearch}
          value={value}
          type="text"
          className="w-full outline-none bg-transparent pr-2"
          placeholder={placeholder}
          onFocus={() => setIsOpen(true)}
        />
        <BsChevronDown onClick={() => setIsOpen(!isOpen)} className={`${isOpen ? "rotate-180" : ""} transition-all cursor-pointer`} size={20} />
      </div>
      {isOpen && (
        <div className="bg-white absolute z-20 max-h-80 overflow-y-auto shadow-xl rounded-md border border-gray-300 w-full">
          {optionList.length > 0 ? (
            optionList.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  setIsOpen(false);
                  onChange(item);
                  setValueTemp(item);
                }}
                className={"px-4 py-2 hover:bg-bg-romance cursor-pointer w-full " + (value === item ? "bg-green-100" : "")}
              >
                <p>{item}</p>
              </div>
            ))
          ) : (
            <div className="px-4 py-2 cursor-pointer w-full flex justify-center">
              <p>No item found</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectArrayItem;
