import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_VendorPaymentPolicy, url } from "../apis/constant";
import SectionTitle from "../components/section-title";
import PaymentPolicyPageData from "../components/paymentPolicy";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { checkAccess } from "../utils/common";

const Item = ({ onClick, title, goto }) => {
  return (
    <Link to={goto}>
      <p
        onClick={onClick}
        className="cursor-pointer px-5 py-2  hover:bg-grey-300"
      >
        {title}
      </p>
    </Link>
  );
};

const CellRender = (props) => {
  const id = props.data[props.row.index].id;
  const vendorId = props.data[props.row.index].vendorid;
  const [show, setShow] = useState(false);
  return (
    <div
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      className="relative"
    >
      <p className="cursor-pointer hover:text-grey-500">{props.value}</p>
      {show && (
        <div
          className={`flex ${props.flatRows[props.flatRows.length - 1]?.index === props.row.index
              ? ""
              : "absolute"
            } shadow-lg bottom-20 bg-white rounded-md z-10`}
        >
          {checkAccess(16, "edit") && (
            <Item
              title="View"
              onClick={() => console.log("View")}
              goto={`/payment-policy-detail/${id}`}
            />
          )}
          <Item
            title="Payment List"
            onClick={() => console.log("Payments")}
            goto={`/payment-summary/${vendorId}`}
          />
          {/* <Item
            title="Vendor Profile"
            onClick={() => console.log("Payment Policy")}
            goto="/vendor/63a1238758054b139ab14cea"
          /> */}
        </div>
      )}
    </div>
  );
};

const Simple = (props) => {
  const {
    columns,
    data,
    getPolicyList,
    Loading,
    getPageData,
    paginationObject,
  } = props;
  return (
    <PaymentPolicyPageData
      columns={columns}
      data={data}
      loading={Loading}
      onSubmit={getPageData}
      showSearch={true}
      pagination={paginationObject}
      getAllVendorProduct={getPolicyList}
      setIds={() => { }}
    />
  );
};

const Index = () => {
  const [Loading, setIsLoading] = useState(false);
  const query = new URLSearchParams(window.location.search)
  const vendor = query.get('vendorName') ?? null;
  const [paginationObject, setPaginationObject] = useState({
    limit: 10,
    page: 1,
  });
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");

  const columns = [
    {
      Header: "Vendor ID",
      accessor: "vendorID",
      Cell: (props) => <CellRender {...props} />,
      isCheck: true,
    },
    {
      Header: "Vendor Name",
      accessor: "vendorname",
      Cell: (props) => <CellRender {...props} />,
      isCheck: true,
    },
    {
      Header: "Payment ID",
      accessor: "paymentId",
      isCheck: true,
    },
    {
      Header: "Ticket Number",
      accessor: "ticketNumber",
      isCheck: true,
    },
    {
      Header: "PAYMENT MILESTONE (PM)",
      accessor: "paymentMilestone",
      isCheck: true,
    },
    {
      Header: "PAYMENT $",
      accessor: "payment",
      isCheck: true,
    },
    {
      Header: "PAYMENT %",
      accessor: "paymentPercentage",
      isCheck: true,
    },
    {
      Header: "PM DUE NO OF DAYS PRIOR TO DELIVERY DATE",
      accessor: "pmDueNoofDaysPriorToDeliveryDate",
      isCheck: true,
    },
    {
      Header: "CANCELLATION NO OF DAYS PRIOR TO DELIVERY DATE",
      accessor: "cancellationNoofDaysPriorToDeliveryDate",
      isCheck: true,
    },
    {
      Header: "REFUND $",
      accessor: "refund",
      isCheck: true,
    },
    {
      Header: "REFUND %",
      accessor: "refundPercentage",
      isCheck: true,
    },
    {
      Header: "START DATE",
      accessor: "startDate",
      isCheck: true,
    },
    {
      Header: "END DATE",
      accessor: "endDate",
      isCheck: true,
    },
    {
      Header: "CANCELLATION POLICY",
      accessor: "canCellationPolicy",
      isCheck: true,
    },
    // {
    //   Header: "ACTION",
    //   accessor: "action",
    //   isCheck: true,
    // },
    {
      Header: "Vendor Address",
      accessor: "vendoraddress",
      isCheck: true,
    },
    {
      Header: "Contact No",
      accessor: "phone",
      isCheck: true,
    },
    {
      Header: "Email Address",
      accessor: "email",
      isCheck: true,
    },
    {
      Header: "Vat No",
      accessor: "vatNo",
      isCheck: true,
    },
  ];

  const getPolicyList = (limit, page, search) => {
    const adminToken = localStorage.getItem("wed_admin_token");
    setIsLoading(true);
    axios
      .get(url + API_VendorPaymentPolicy, {
        params: {
          page: page,
          sortBy: "asc",
          limit: limit,
          searchBy: search,
        },
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((res) => {
        if (res.data.code === 200) {
          const apiData = res.data.data.docs;
          setPaginationObject({
            limit: res.data.data.limit,
            page: res.data.data.page,
            pages: res.data.data.pages,
            total: res.data.data.total,
          });
          const finalData = apiData.map((v) => {
            return {
              id: v.id,
              vendorID: v?.vendorId?.vendorId,
              vendorid: v?.vendorId?.id,
              ticketNumber: v?.ticketNumber,
              vendorname: v?.vendorId?.vendorName,
              paymentId: v?.paymentMilestone[0]?._id,
              paymentMilestone: v?.status,
              payment: v?.paymentMilestone[0]?.paymentAmount,
              paymentPercentage: v?.paymentMilestone[0]?.paymentInPercentage,
              pmDueNoofDaysPriorToDeliveryDate:
                v?.paymentMilestone[0]?.pmDueNoOfDaysPriorTodeliveryDate,
              cancellationNoofDaysPriorToDeliveryDate:
                v?.paymentMilestone[0]?.cancellableNoOfDaysPriorToDeliveryDate,
              refund: v?.paymentMilestone[0]?.refundAmount,
              refundPercentage: v?.paymentMilestone[0]?.refundInPercentage,
              startDate: moment(v?.startDate).format("DD-MMM-YYYY"),
              endDate:
                v?.endDate !== null
                  ? moment(v?.endDate).format("DD-MMM-YYYY")
                  : "Unlimited",
              canCellationPolicy: v?.cancelllationPolicy,
              action: "",
              vendoraddress: v?.vendorId?.streetAddress1,
              phone: v?.vendorId?.mobileNumber,
              email: v?.vendorId?.email,
              vatNo: v?.vendorId?.vatTaxNumber,
            };
          });
          setData(finalData);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const getPageData = (data) => {
    getPolicyList(data, 1, "");
  };

  const OnEnter = (e) => {
    var pay = e.target.value;
    setSearch(pay);
    if (pay.length >= 3 || pay.length === 0) {
      getPolicyList(10, 1, pay);
    }
  };

  useEffect(() => {
    if (vendor) {
      setSearch(vendor);
    }
    getPolicyList(10, 1, vendor ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <SectionTitle title="Payment" subtitle="Vendor Payment Policy " />
      <div className="p-2 bg-white border rounded-md">
        <div className="flex justify-start">
          <div className="flex p-3"></div>

          <div className="flex p-3">
            {/* <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="All"
            />
            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="Approve"
            />
            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="Pending"
            />
            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="Rejected"
            />
            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="Archive"
            /> */}
            <div className="flex ">
              {/* <svg
                aria-hidden="true"
                className="flex justify-self-center self-center focus:w-5 h-5 text-gray-500 dark:text-gray-400 ml-2 text-grey-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 35 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg> */}
              <input
                type="Search"
                placeholder="Search by vendor name"
                className="searchCss add_fliter_input"
                value={search}
                onChange={OnEnter}
              ></input>
            </div>
          </div>

          <div className="flex p-3">
            {/* <ModalImport
              title=""
              body={
                <div className="grid grid-cols-1 mx-12 my-5">
                  <span className="text-lg my-4">
                    Easily organize your own data with import or export data
                    feature
                  </span>
                  <button className="bg-white hover:bg-grey-200 py-2 px-4 my-2 rounded shadow-sm border">
                    Download CSV Template
                  </button>
                  <button className="bg-white hover:bg-grey-200 py-2 px-4 my-2 rounded shadow-sm border">
                    Export
                  </button>
                  <button className="bg-white hover:bg-grey-200 py-2 px-4 my-2 rounded shadow-sm border">
                    Import Individual Customer
                  </button>
                  <button className="bg-white hover:bg-grey-200 py-2 px-4 my-2 rounded shadow-sm border">
                    Import Aggregate Customer
                  </button>
                  <hr className="my-2" />
                  <p className="underline text-center cursor-pointer">
                    Ask Customer Service
                  </p>
                </div>
              }
            /> */}
            {/* <button className="bg-white hover:bg-grey-200 py-2 px-4 rounded shadow-sm border">
              Export
            </button>
            <button className="bg-white hover:bg-grey-200 py-2 px-4 rounded shadow-sm border">
              Import
            </button> */}
            {/* <div ref={ref}>
              <button
                onClick={() => setShow(!show)}
                className="bg-white hover:bg-grey-200 py-2 px-4 rounded shadow-sm border"
              >
                Bulk Action
              </button>

              {show && (
      const adminToken = localStorage.getItem("wed_admin_token");
          <div className="relative">
                  <div className=" absolute shadow-lg right-0 bottom-20 bg-white w-48 rounded-md z-10">
                    <Menu
                      title="Approve Reviews"
                      onClick={() => console.log("Active")}
                    />
                    <hr></hr>
                    <Menu
                      title="Reject Reviews"
                      onClick={() => console.log("Diactive")}
                    />
                    <hr></hr>
                    <Menu title="Archive" onClick={() => console.log("Copy")} />
                  </div>
                </div>
              )}
            </div> */}
          </div>
        </div>
        <Simple
          paginationObject={paginationObject}
          Loading={Loading}
          getPageData={getPageData}
          columns={columns}
          data={data}
          setData={setData}
          getPolicyList={getPolicyList}
        />
      </div>
    </div>
  );
};
export default Index;
