import React from 'react'
import axios from "axios";
import { url, API_Dashboard } from "../../apis/constant";

const Product = () => {
  const [apiData, setApiData] = React.useState([]);

  React.useEffect(() => {
    const adminToken = localStorage.getItem("wed_admin_token");
    try {
      axios.get(url + API_Dashboard.product, {
        headers: {
          Authorization: `Bearer ${adminToken}`
        }
      }).then(res => {
        const data = res.data.data;
        setApiData(data);
      }).catch(err => {
        console.log(err);
      })
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div className="animate-fade-in-down">
      <div className="p-2 bg-white border rounded-md text-xl my-4">
        <div className="flex justify-between my-2">
          <p className="self-center">No of Products/Services created</p>
          <input className="border shadow-sm rounded-md p-2 w-3/5 cursor-default" value={apiData?.totalProducts || 0} readOnly />
        </div>
      </div>
    </div>
  )
}

export default Product
