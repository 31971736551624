import React, { useState, useEffect } from "react";
import Validation from "../forms/add-profile";
import Alert from "../alerts";
import ApiClient from "../../apis/apiClient";
import { toast } from "react-toastify";
import { API_UpdateStatusAdmin, url } from "../../apis/constant";

import axios from "axios";

const AccountSettings = ({
  setValueImg,
  message = null,
  setNameAdmin,
  setRoleValue,
}) => {
  const [data] = useState(null);
  const adminToken = localStorage.getItem("wed_admin_token");
  const userId = localStorage.getItem("wed_admin_userId");
  const [phoneErr, setPhoneErr] = useState("");

  const handleChange = (value, id) => {
    var result = [...listItem];

    result = result.map((x) => {
      if (x.id === id) {
        if (x.id === "phone") {
          //validation
          if (value.length <= 8) {
            // value.stopPropagation();
            x.value = value;
            setPhoneErr("");
          } else {
            setPhoneErr("Phone number can not be greater than 8.");
          }
        } else {
          x.value = value;
        }
      }
      return x;
    });
    setListItem(result);
  };

  const handleSubmit = (data) => {
    let logData = {
      name: data?.firstname.trim(),
      email: data?.email,
      phone: data?.mobile_number,
    };
    axios
      .patch(url + API_UpdateStatusAdmin + "/" + userId, logData, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })

      .then((res) => {
        if (res.data.code === 200) {
          getProfileDeatail();
          toast.success(res.data.message);
        } else if (res.data.code === 400) {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    getProfileDeatail();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProfileDeatail = async () => {
    const res = await ApiClient.get(
      API_UpdateStatusAdmin + "/" + userId,
      "",
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      setValueImg(res.data.data.profilePhoto);
      setNameAdmin(res.data.data.name);
      setRoleValue(res.data.data.type);
      let tempArry = [];
      for (let i = 0; i < items.length; i++) {
        let element = items[i];
        element.value = res.data.data[element.id];
        tempArry.push(element);
      }
      setListItem(tempArry);
    } else {
      toast.warning(res.data.message);
    }
  };

  let items = [
    {
      label: "Full Name",
      error: {
        required: "Please enter a valid full name",
        minLength: {
          value: 2,
          message: "Username is too short [2-30]",
        },
        maxLength: {
          value: 30,
          message: "Usename is too long [2-30]",
        },
      },
      name: "firstname",
      type: "text",
      value: "",
      placeholder: "Enter your Full Name",
      id: "name",
      inputType: "vendor-input",
    },
    {
      label: "Email Address",
      // error: { required: "Please enter a valid  email address" },
      name: "email",
      type: "email",
      placeholder: "Enter your Email Address",
      value: "",
      id: "email",
      readOnly: true,
    },
    {
      label: "Mobile Number",
      error: {
        required: "Please enter a valid mobile number",
      },
      name: "mobile_number",
      type: "Number",
      value: "",
      placeholder: "Enter your Mobile Number",
      id: "phone",
      inputType: "vendor-input",
    },
  ];
  const [listItem, setListItem] = useState(items);

  return (
    <>
      <div className="flex flex-col">
        {data && message && (
          <div className="w-full mb-4">
            <Alert
              color="bg-transparent border-green-500 text-green-500"
              borderLeft
              raised
            >
              {message}
            </Alert>
          </div>
        )}
        <Validation
          items={listItem}
          onSubmit={handleSubmit}
          onChange={handleChange}
          phoneErr={phoneErr}
        />
      </div>
    </>
  );
};

export default AccountSettings;
