import React from 'react';
import axios from "axios";
import { formatCurrency } from "../../functions/numbers";
import { url, API_Dashboard } from "../../apis/constant";
import Datatable from "../datatable";

const OrderAndPayment = ({startDate,endDate}) => {
  const [apiData, setApiData] = React.useState([]);

  // Define your columns for the datatable here
  const columns = [
    {
      Header: "Total",
      accessor: "totalSales",
    },
    {
      Header: "Payments Received",
      accessor: "totalPaymentReceived",
    },
    {
      Header: "Payments Outstanding",
      accessor: "totalPaymentOutstanding",
    },
    {
      Header: "No of Orders",
      accessor: "ordersCount",
    },
  ];

  React.useEffect(() => {
    const adminToken = localStorage.getItem("wed_admin_token");
    try {
      axios.get(url + API_Dashboard.orderPayment, {
        params: {startDate, endDate},
        headers: {
          Authorization: `Bearer ${adminToken}`
        }
      }).then(res => {
        setApiData([res.data.data]);
      }).catch(err => {
        console.log(err);
      })
    } catch (error) {
      console.log(error);
    }
  }, [startDate, endDate]);

  return (
    <div className="animate-fade-in-down">
      <div className="p-2 bg-white border rounded-md text-xl">
        <Datatable columns={columns}
          data={apiData.map((item, index) => ({
            totalSales: formatCurrency(item.totalSales),
            totalPaymentReceived: formatCurrency(item.totalPaymentReceived),
            totalPaymentOutstanding: formatCurrency(item.totalPaymentOutstanding),
            ordersCount: item.ordersCount,
          }))}
          showResponseData={false}
          showPagination={false}
          showSelection={false}
        />
      </div>
    </div>
  );
}

export default OrderAndPayment;
