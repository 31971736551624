import moment from "moment";
import React, { useState } from "react";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";

export const DateStart = ({
  idx,
  setUpdatedPolicyStartDate,
  val,
  setPolicies,
  dateStart,
  FORMAT,
  showDateStart,
  setShowDateStart,
  isdisabled,
}) => {
  // const [showDateStart, setShowDateStart] = useState(false);
  const [selectedDays, setSelectedDayEnd] = useState(val?.end_date);
  return (
    <div>
      <div>
        <p className="font-worksans-semibold w-32">Start Date</p>
        <div className="relative">
          <div
            onClick={() => {
              setShowDateStart(!showDateStart);
            }}
            className="md:w-64 w-full  h-10 outline-none py-2 px-2 rounded-lg border-2 flex justify-between items-center z-10 cursor-pointer"
          >
            <p className={!dateStart && "text-slate-400"}>
              {
                val.start_date ? moment(new Date(val.start_date)).format(FORMAT) : ""
                
              }
            </p>
            {/* <AiOutlineDown className="text-bg-primary font-bold" /> */}
          </div>
          {showDateStart && !isdisabled && (
            <div className="bg-white absolute top-12 right-0 border-2 z-10">
              <DayPicker
                onDayClick={(e) => {
                  setShowDateStart(false);
                  setUpdatedPolicyStartDate(moment(e).format(FORMAT));
                  setSelectedDayEnd(e);

                  //   const copyPolicy = [...policies];
                  //   copyPolicy.splice(idx, 1, { ...val, start_date: e.toISOString() });
                  //   setPolicies(copyPolicy);
                }}
                // onChangeRaw={e => isdisabled && e.preventDefault()}
                // onFocus={e => isdisabled && e.preventDefault()}
                // onKeyDown={e => isdisabled && e.preventDefault()}
                selectedDays={selectedDays}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
