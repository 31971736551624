import React, { useState, useEffect } from "react";
import { useHistory, Link, useParams, Redirect } from "react-router-dom";
import {
  url,
  API_FilterList,
  API_UpdateProductTypeById,
  API_MasterCategoryList,
  API_GetAllSubCategory,
  API_GetAllCategory
} from "../../apis/constant";
import ApiClient from "../../apis/apiClient";
import InputTag from "../tags/InputTag";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { getPreviewUrl } from "../../utils/common";

const EditProductType = () => {
  const adminToken = localStorage.getItem("wed_admin_token");
  const history = useHistory();
  const [isSubmit, setIsSubmit] = useState(false);
  const [subCatList, setSubCategoryList] = useState([]);
  const [categryList, setCategoryList] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [tags, setTags] = React.useState([]);
  const [error, setError] = useState("");
  const [masterCatError, setMasterCatError] = useState("");
  const [catError] = useState("");
  const [subCatError] = useState("");
  const [tagError, setTagError] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [handleFilterData, sethandleFilterData] = useState([]);
  const [handleFilterError, setHandleFilterError] = useState("");
  const [media, setMedia] = useState(null);
  const [showEnable, setShowEnable] = useState(false);
  const [selectedMasterCategoryValue, setSelectedMasterCategoryValue] = useState(null);
  const [selectedSubCategoryValue, setSelectedSubCategoryValue] = useState(null);
  const [selectedCategoryValue, setSelectedCategoryValue] = useState(null);

  const alphanumericRegex = /^[^'"`]+$/;
  const { id } = useParams();

  useEffect(() => {
    getproductById();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleImage = (e) => {
    setMedia(e);
  };
  const handleClickRemove = () => {
    setMedia("");
  };

  const handleEnable = (e) => {
    if (e === "Yes") {
      setShowEnable(true);
    } else {
      setShowEnable(false);
    }
  };

  const handleInput = (value) => {
    const limit = 200;
    if (value.length > limit) {
      setError("Please enter less than 200 words.");
    } else if (value && !alphanumericRegex.test(value)) {
      setError("Please enter valid name.");
      setNameFilter(value);
    } else {
      setError("");
      setNameFilter(value);
    }
  };

  const handleChangeFilter = (value) => {
    sethandleFilterData(value);
  };

  const FiltersList = async (searchq = "") => {
    const data = "";
    const res = await ApiClient.get(
      API_FilterList + `?page=1&sortBy=asc&limit=${999999}&searchBy=${searchq}&masterCategoryId=${selectedMasterCategoryValue?.id ?? ""}&categoryId=${selectedCategoryValue?.id ?? ""}&subCategoryId=${selectedSubCategoryValue?.id ?? ""}`, data,
      `Bearer ${adminToken}`
    );

    if (res.data.code === 200) {
      let full_arr = res.data.data.docs;
      setFilterData(full_arr);
    }
  };

  const getproductById = () => {
    axios
      .get(url + API_UpdateProductTypeById + "/" + id, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((res) => {
        if (res.data.code === 200) {
          setNameFilter(res.data.data.name);
          setSelectedMasterCategoryValue(res.data.data.masterCategoryId);
          if (res.data.data.hasOwnProperty("categoryId")) {
            setSelectedCategoryValue(res.data.data.categoryId);
          } else {
            setSelectedCategoryValue(null);
          }
          if (res.data.data.hasOwnProperty("subCategoryId")) {
            setSelectedSubCategoryValue(res.data.data.subCategoryId);
          } else {
            setSelectedSubCategoryValue(null);
          }
          setTags(res.data.data.searchTerms);
          setMedia(res.data.data.image);
          setShowEnable(res.data.data.isImage);
          sethandleFilterData(res.data.data.filters);
        }
      })
      .catch((err) => { });
  };

  const handleSubmit = () => {
    let isTrue = false;
    setIsSubmit(true);

    if (selectedMasterCategoryValue?.id === "") {
      setMasterCatError("Level 1 should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setMasterCatError("");
    }

    if (tags.length === 0) {
      setTagError("Search Items should not be blank.");
      setIsSubmit(false);
      // return;
      isTrue = true;
    } else {
      setTagError("");
    }
    if (nameFilter === "") {
      setError("Name should not be blank.");
      setIsSubmit(false);
      // return;
      isTrue = true;
    } else {
      setError("");
    }
    if (handleFilterData === null) {
      setHandleFilterError("Filter Type can not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setHandleFilterError("");
    }
    if (isTrue) {
      return;
    }

    let filters = [];

    for (let i = 0; i < handleFilterData.length; i++) {
      filters.push(handleFilterData[i].id);
    }

    let logData = new FormData();
    logData.append("name", nameFilter);
    logData.append("filters", JSON.stringify(filters));
    logData.append("searchTerms", JSON.stringify(tags));
    logData.append("masterCategoryId", selectedMasterCategoryValue?.id);
    if (selectedCategoryValue !== null) {
      logData.append("categoryId", selectedCategoryValue?.id);
    } else {
      logData.append("categoryId", "");
    }
    if (selectedSubCategoryValue !== null) {
      logData.append("subCategoryId", selectedSubCategoryValue?.id);
    } else {
      logData.append("subCategoryId", "");
    }
    if (showEnable === true) {
      logData.append("image", media);
    }
    logData.append("isImage", showEnable);

    axios
      .patch(url + API_UpdateProductTypeById + id, logData, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((res) => {
        if (res.data.code === 200) {
          toast.success(res.data.message);
          history.push("/product_type");
        } else if (res.data.code === 400) {
          toast.error(res.data.message);
          setIsSubmit(false);
        }
      })
      .catch((err) => {
        setIsSubmit(false);
      });
  };

  const onAddTag = (tag) => {
    setTags([...tags, ...tag]);
  };

  const onDeleteTag = (tag) => {
    let remainingTags = tags.filter((t) => {
      return t !== tag;
    });
    setTags([...remainingTags]);
  };

  const handleAMasterCategory = async (e) => {
    setCategoryList([]);
    setSubCategoryList([]);
    setSelectedMasterCategoryValue(e);
    getCategoryListUsingMasterId("");
    getSubCategoryListUsingCategoryId("");
  };

  const MasterCategoryList = async (inputValue) => {
    const data = "";
    const res = await ApiClient.get(
      API_MasterCategoryList +
      `?page=1&sortBy=asc&limit=${999999}&searchBy=${inputValue}&isFor=dropdown`,
      data,
      `Bearer ${adminToken}`
    );

    if (res.data.code === 200) {
      return res.data.data.docs;
    }
  };

  const handleACategory = async (e) => {
    setSubCategoryList([]);

    setSelectedCategoryValue(e);
    getSubCategoryListUsingCategoryId("");
  };

  const getCategoryListUsingMasterId = async (inputValue) => {
    const res = await ApiClient.get(
      API_GetAllCategory +
      `?masterCategoryId=${selectedMasterCategoryValue?.id ?? ""}&page=1&sortBy=asc&limit=${999999}&searchBy=${inputValue}&isFor=dropdown`,
      {},
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      let full_arr = res.data.data.docs;
      setCategoryList(full_arr);
      return full_arr;
    }
  };

  const handleASubCategory = (e) => {
    setSelectedSubCategoryValue(e);
  };

  const getSubCategoryListUsingCategoryId = async (inputValue) => {
    const res = await ApiClient.get(
      API_GetAllSubCategory +
      `?masterCategoryId=${selectedMasterCategoryValue?.id ?? ""}&categoryId=${selectedCategoryValue?.id ?? ""}&page=1&sortBy=asc&limit=${999999}&searchBy=${inputValue}&isFor=dropdown`,
      {},
      `Bearer ${adminToken}`
    );

    if (res.data.code === 200) {
      let full_arr = res.data.data.docs;
      setSubCategoryList(full_arr);
      return full_arr;
    }
    return [];
  };

  useEffect(() => {
    FiltersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMasterCategoryValue, selectedCategoryValue, selectedSubCategoryValue]);

  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
    return (
      <>
        <div className="addBack" style={{ marginTop: "15px" }}>
          <Link
            to={"/product_type"}
            className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
          >
            Back
          </Link>
        </div>

        <div className="flex_box">
          <div className="form-element">
            <div>Level 1:</div>
            <AsyncSelect
              className="w-100"
              cacheOptions
              defaultOptions
              isClearable
              value={selectedMasterCategoryValue}
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.id}
              loadOptions={MasterCategoryList}
              onChange={handleAMasterCategory}
            />
            {masterCatError && (
              <p
                style={{
                  color: "red",
                  padding: "10px 4px",
                  paddingLeft: "5px",
                }}
              >
                {masterCatError}
              </p>
            )}
          </div>

          <div className="form-element">
            <div>Level 2:</div>
            <AsyncSelect
              className="w-100"
              key={categryList.length}
              cacheOptions
              defaultOptions
              isClearable
              value={selectedCategoryValue}
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.id}
              loadOptions={getCategoryListUsingMasterId}
              onChange={handleACategory}
            />
            {catError && (
              <p
                style={{
                  color: "red",
                  padding: "10px 4px",
                  paddingLeft: "5px",
                }}
              >
                {catError}
              </p>
            )}
          </div>
        </div>
        <div className="flex_box">
          <div className="form-element">
            <div>Level 3:</div>
            <AsyncSelect
              className="w-100"
              key={subCatList.length}
              cacheOptions
              defaultOptions
              isClearable
              value={selectedSubCategoryValue}
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.id}
              loadOptions={getSubCategoryListUsingCategoryId}
              onChange={handleASubCategory}
            />
            {subCatError && (
              <p
                style={{
                  color: "red",
                }}
              >
                {subCatError}
              </p>
            )}
          </div>

          <div className="form-element">
            <div>Product Type</div>

            <input
              type="text"
              name="name"
              placeholder="type Name..."
              value={nameFilter}
              className="inputFields"
              onChange={(e) => handleInput(e.target.value)}
              style={{ height: "38px", marginTop: "0" }}
            />
            {error && <p style={{ color: "red" }}>{error}</p>}
          </div>
        </div>

        <div className="flex_box">
          <div className="form-element">
            <div>Search Terms</div>
            <div className="inputTag">
              <InputTag
                className="inputFields"
                onAddTag={onAddTag}
                onDeleteTag={onDeleteTag}
                defaultTags={tags}
                placeholder="enter tags separated by comma"
              />
            </div>
            {tagError && <p style={{ color: "red" }}>{tagError}</p>}
          </div>
          <div className="form-element">
            <div>Select Filters</div>
            <div className="inputTag">
              <Select
                options={filterData}
                name="category"
                value={handleFilterData}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.id}
                onChange={(value) => handleChangeFilter(value)}
                isMulti={true}
                placeholder="Select multiple..."
              />
            </div>
            {handleFilterError && (
              <p style={{ color: "red", margin: "6px 2px" }}>
                {handleFilterError}
              </p>
            )}
          </div>
        </div>

        <div>
          <label>Enable Image Upload !!</label>
          <div>
            <label htmlFor="Yes">
              {" "}
              Yes
              <input
                type="radio"
                id="Yes"
                name="Yes"
                value="Yes"
                key="1"
                checked={showEnable === true}
                style={{ width: "50px ", marginTop: "20px" }}
                onClick={(e) => handleEnable(e.target.value)}
              />
            </label>

            <label htmlFor="No">
              {" "}
              No
              <input
                type="radio"
                id="No"
                name="Yes"
                value="No"
                key="2"
                checked={showEnable === false}
                style={{ width: "50px", marginTop: "20px" }}
                onClick={(e) => handleEnable(e.target.value)}
              />
            </label>
          </div>
        </div>
        {showEnable ? (
          <div className="mt-4">
            <label>Upload Media!</label>
            <div className="flex flex-row justify-start w-full py-1 space-x-2">
              <div className="image-upload">
                <label htmlFor="file-input">
                  <img
                    src="https://icons.iconarchive.com/icons/hopstarter/soft-scraps/256/Button-Upload-icon.png"
                    title="Choose a photo!"
                    alt="choose"
                    style={{ width: "30px" }}
                  />
                </label>

                <input
                  id="file-input"
                  type="file"
                  placeholder="testt"
                  value=""
                  style={{ display: "none" }}
                  accept=".png, .jpeg, .jpg, .JPG, .PNG, .JPEG"
                  onChange={(e) => handleImage(e.target.files[0])}
                />
              </div>

              <div>
                {
                  media && (
                    // (imgArry.includes(media.type) ? (
                    <div className="img-box">
                      {" "}
                      <img
                        className="img"
                        alt="custom"
                        //  src={URL.createObjectURL(media)}
                        src={
                          typeof media === "string"
                            ? getPreviewUrl(media)
                            : URL.createObjectURL(media)
                        }
                      />
                      <img
                        src="https://icons.iconarchive.com/icons/hopstarter/sleek-xp-basic/256/Close-2-icon.png"
                        onClick={() => handleClickRemove()}
                        className="removeCss"
                        alt="close"
                      />
                    </div>
                  )
                  // ) : (
                  //   <div className="img-box">
                  //     {" "}
                  //     <video
                  //       className="img"
                  //       src={URL.createObjectURL(media)}
                  //       controls
                  //     />
                  //     <img
                  //       src="https://icons.iconarchive.com/icons/hopstarter/sleek-xp-basic/256/Close-2-icon.png"
                  //       onClick={() => handleClickRemove()}
                  //       className="removeCss"
                  //     />
                  //   </div>
                  // ))}
                }
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="flex flex-col mt-4">
          <div className="inputBoxCategoryCss">
            {isSubmit ? (
              <button className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded">
                Loading...
              </button>
            ) : (
              <button
                className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
                onClick={() => handleSubmit()}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </>
    );
  }
};

export default EditProductType;
