import React, { useState } from "react";
import SectionTitle from "../section-title";
import Widget from "../widget";
import ApiClient from "../../apis/apiClient";
import { API_CreateRole } from "../../apis/constant";
import { Link, useHistory } from "react-router-dom";
import "nprogress/nprogress.css";
import "../../css/custome.css";
import { toast } from "react-toastify";

const Simple = () => {
  const adminToken = localStorage.getItem("wed_admin_token");
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  let history = useHistory();
  const alphanumericRegex = /^[a-zA-Z0-9-. ]+$/;

  let moduleData = [
    {
      moduleName: "Role And Permission",
      moduleId: "1",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    {
      moduleName: "Manage Admin",
      moduleId: "2",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    {
      moduleName: "Manage Users",
      moduleId: "3",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    {
      moduleName: "Users Address",
      moduleId: "14",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    {
      moduleName: "Manage Custormer Review",
      moduleId: "15",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    {
      moduleName: "Manage Vendors",
      moduleId: "4",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    {
      moduleName: "Manage Vendor Policy",
      moduleId: "16",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    {
      moduleName: "Manage Products",
      moduleId: "17",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    {
      moduleName: "Manage Subscription",
      moduleId: "18",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    {
      moduleName: "Manage Vendor Compensation",
      moduleId: "19",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    {
      moduleName: "Manage Sceduling",
      moduleId: "29",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    {
      moduleName: "Category Level 1",
      moduleId: "5",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    {
      moduleName: "Category Level 2",
      moduleId: "6",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    {
      moduleName: "Category Level 3",
      moduleId: "7",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    {
      moduleName: "Category Product Type",
      moduleId: "11",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    {
      moduleName: "Order",
      moduleId: "20",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    {
      moduleName: "Cancel Order",
      moduleId: "21",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    {
      moduleName: "Payment List",
      moduleId: "32",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    {
      moduleName: "Manage Attributes",
      moduleId: "9",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    {
      moduleName: "Filters",
      moduleId: "10",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    
    {
      moduleName: "Manage Faq",
      moduleId: "12",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    // {
    //   moduleName: "Manage Products",
    //   moduleId: "13",
    //   selectedAll: false,
    //   list: false,
    //   add: false,
    //   edit: false,
    //   view: false,
    //   delete: false,
    // },
    {
      moduleName: "User Terms & Conditions",
      moduleId: "22",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    {
      moduleName: "User Privacy & Policy",
      moduleId: "29",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    {
      moduleName: "Vendor Privacy & Policy",
      moduleId: "30",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    {
      moduleName: "Vendor Terms & Conditions",
      moduleId: "31",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    {
      moduleName: "Chat",
      moduleId: "23",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    {
      moduleName: "Tickets",
      moduleId: "24",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    {
      moduleName: "E-Vouchers",
      moduleId: "25",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    {
      moduleName: "Sales Campaign",
      moduleId: "26",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    {
      moduleName: "Discount",
      moduleId: "27",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
    {
      moduleName: "Voucher",
      moduleId: "28",
      selectedAll: false,
      list: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
    },
  ];

  const [modules, setModules] = useState(moduleData);

  const handleSubmitAdd = async () => {
    setIsSubmit(true);
    if (name === "") {
      // let errors = "Name is required";
      setError("Role Name is required");
      setIsSubmit(false);

      return;
    }

    if (name && !alphanumericRegex.test(name)) {
      setError("Please enter valid name.");
      setIsSubmit(false);

      return;
    }

    let myObj = {
      name: name,
      resource: [],
    };

    for (let data in modules) {
      let boxes = modules[data];
      let permission_arr = [];

      if (boxes["selectedAll"] === true) {
        permission_arr.push("selectedAll");
      }
      if (boxes["list"] === true) {
        permission_arr.push("list");
      }
      if (boxes["add"] === true) {
        permission_arr.push("add");
      }
      if (boxes["edit"] === true) {
        permission_arr.push("edit");
      }
      if (boxes["view"] === true) {
        permission_arr.push("view");
      }
      if (boxes["delete"] === true) {
        permission_arr.push("delete");
      }

      myObj["resource"].push({
        permissions: permission_arr,
        moduleName: modules[data]["moduleName"],
        moduleId: modules[data]["moduleId"],
      });
    }

    let error = false;
    for (let arr in myObj.resource) {
      if (myObj.resource[arr].permissions.length === 0) {
        error = true;
      } else {
        error = false;
        break;
      }
    }

    if (error) {
      toast.error("Please select atleast one permission.");
      setIsSubmit(false);

      return;
    }

    const res = await ApiClient.post(
      API_CreateRole,
      myObj,
      `Bearer ${adminToken}`
    );

    if (res.data.code === 200) {
      history.push("/role/permission");
    } else if (res.data.code === 400) {
      toast.error(res.data.message);
      setIsSubmit(false);
    }
  };

  const handleClick = (e, index, type) => {
    const values = [...modules];
    if (type === "all") {
      values[index].selectedAll = e.target.checked;
      values[index].list = e.target.checked;
      values[index].add = e.target.checked;
      values[index].edit = e.target.checked;
      values[index].view = e.target.checked;
      values[index].delete = e.target.checked;
    } else if (type === "list") {
      values[index].list = e.target.checked;
    } else if (type === "add") {
      values[index].add = e.target.checked;
    } else if (type === "edit") {
      values[index].edit = e.target.checked;
    } else if (type === "view") {
      values[index].view = e.target.checked;
    } else {
      values[index].delete = e.target.checked;
    }
    if (
      values[index].add &&
      values[index].edit &&
      values[index].view &&
      values[index].delete &&
      values[index].list
    ) {
      values[index].selectedAll = true;
    } else {
      values[index].selectedAll = false;
    }
    setModules(values);
  };

  const handleChange = (value) => {
    if (!alphanumericRegex.test(value)) {
      setError("Please enter valid role name.");
      setName(value);
      return;
    } else {
      setName(value);
      setError("");
    }
  };

  return (
    <div className="card-body">
      <div className="form-group row">
        <span>
          <label>Name: </label>
          <div className="form-element lg:w-1/2 inline-flex">
            <input
              type="text"
              className="form-input inline-flex"
              id="exampleInputName1"
              placeholder="Enter Role Name"
              name="name"
              maxLength={50}
              minLength={2}
              onChange={(e) => handleChange(e.target.value)}
            />
            <p style={{ color: "red" ,padding:"10px 4px"}}> {error}</p>
          </div>
        </span>
      </div>
      <div className="form-group row">
        <div className="col-12">
          <div className="table-responsive border rounded mt-1">
            <h6 className="py-1 mx-1 mb-0 font-medium-2">
              <i className="fa fa-lock mr-25"></i>
              <span className="align-middle">Permission</span>
            </h6>
            <table className="table table-striped table-borderless">
              <thead className="thead-light">
                <tr>
                  <th>Module Name</th>
                  <th>Select All</th>
                  <th>List</th>
                  <th>Add</th>
                  <th>Edit</th>
                  <th>View</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {modules.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.moduleName}</td>
                      <td>
                        <input
                          name="checkall"
                          type="checkbox"
                          className="form-checkbox h-4 w-4 border-black"
                          checked={item.selectedAll}
                          onChange={(e) => handleClick(e, index, "all")}
                        />
                      </td>
                      <td>
                        <input
                          name="checkList"
                          type="checkbox"
                          className="form-checkbox h-4 w-4 border-black"
                          checked={item.list}
                          onChange={(e) => handleClick(e, index, "list")}
                        />
                      </td>
                      <td>
                        <input
                          name="checkAdd"
                          type="checkbox"
                          className="form-checkbox h-4 w-4 border-black"
                          checked={item.add}
                          onChange={(e) => handleClick(e, index, "add")}
                        />
                      </td>
                      <td>
                        <input
                          name="checkEdit"
                          type="checkbox"
                          className="form-checkbox h-4 w-4 border-black"
                          checked={item.edit}
                          onChange={(e) => handleClick(e, index, "edit")}
                        />
                      </td>
                      <td>
                        <input
                          name="checkView"
                          type="checkbox"
                          className="form-checkbox h-4 w-4 border-black"
                          checked={item.view}
                          onChange={(e) => handleClick(e, index, "view")}
                        />
                      </td>
                      <td>
                        <input
                          name="checkView"
                          type="checkbox"
                          className="form-checkbox h-4 w-4 border-black"
                          checked={item.delete}
                          onChange={(e) => handleClick(e, index, "delete")}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isSubmit ? (
        <button className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded addSubmit">
          Loading...
        </button>
      ) : (
        <button
          className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded addSubmit"
          onClick={() => handleSubmitAdd()}
        >
          Submit
        </button>
      )}
    </div>
  );
};

const Index = () => (
  <>
    <SectionTitle title="Tables" subtitle="Add Role & Permission" />
    <div className="addBack">
      <Link
        to={"/role/permission/"}
        className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
      >
        Back
      </Link>
    </div>
    <Widget>
      <Simple />
    </Widget>
  </>
);
export default Index;
