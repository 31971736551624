/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SectionTitle from "../components/section-title";
import SubscriptionListTables from "../components/vendorSubscription";
import Widget from "../components/widget";
import { toast } from "react-toastify";
import Modal2 from "../components/confirm-delete-modal/modal-2";
import { FiX } from "react-icons/fi";
import ModalActivate from "../components/confirm-activate-modal/modal-activate";
import ApiClient from "../apis/apiClient";
import {
  API_GetAllSubscriptionList,
  API_DeleteSubscriptionList,
  API_UpdateStatusSbscrption,
} from "../apis/constant";
import { Link, Redirect } from "react-router-dom";
import { checkAccess } from "../utils/common";

const Simple = () => {
  const [Subscrption, setSubscrption] = useState([]);
  const [Loading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [paginationObject, setPaginationObject] = useState({});
  const adminToken = localStorage.getItem("wed_admin_token");

  const getAllSubscriptionList = async (limit, searchq = "", pageNumber) => {
    const data = "";
    setIsLoading(true);
    const res = await ApiClient.get(
      API_GetAllSubscriptionList +
        `?page=${pageNumber}&sortBy=asc&limit=${limit}&searchBy=${search}`,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      if (res.data.data && res.data.data.docs.length !== 0) {
        for (let i = 0; i < res.data.data.docs.length; i++) {
          res.data.data.docs[i]["displayStatus"] =
            res.data.data.docs[i].status === 0 ? (
              <label className="">Inactive</label>
            ) : (
              <label className="">Active</label>
            );
        }
      }
      setPaginationObject({
        limit: res.data.data.limit,
        page: res.data.data.page,
        pages: res.data.data.pages,
        total: res.data.data.total,
      });
      setSubscrption(res.data.data.docs);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (search.length > 2 || search.length === 0) {
      getAllSubscriptionList(10, search, 1);
    }
  }, [search]);

  const deleteAttribute = async (Subscrption) => {
    const id = Subscrption.original.id;
    const data = "";
    const res = await ApiClient.delete(
      API_DeleteSubscriptionList + id,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllSubscriptionList(10, "", 1);
    } else {
      toast.warning(res.data.message);
    }
  };

  const handleActive = async (status, data) => {
    let myObj = {
      status: status,
      name: data.name,
    };
    const res = await ApiClient.patch(
      API_UpdateStatusSbscrption + "/" + data.id,
      myObj,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllSubscriptionList(10, "", 1);
    } else {
      toast.warning(res.data.message);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Plan Name ",
        accessor: "name",
      },
      {
        Header: "Type",
        accessor: "type",
      },

      {
        Header: "Subscription Fee",
        accessor: "fee",
      },
      {
        Header: "Status",
        accessor: "displayStatus",
      },
      {
        Header: "Action",
        id: "delete",
        accessor: (str) => {},

        Cell: (Datatable) => (
          <>
            {/* <EditAttributeList
              id={Datatable.row.original.id}
              title={"Edit Attribute"}
              body={"body"}
              getApi={getAllSubscriptionList}
            />{" "}
            |{" "} */}
             {checkAccess(19, 'edit') &&<Link
              to={`/editvendorsubscription/${Datatable.row.original.id}`}
              className="underLineText"
            >
              Edit
            </Link>}{" "}
            |{" "}
           {checkAccess(19, 'delete') && <Modal2
              title="Delete"
              icon={
                <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                  <FiX size={18} className="stroke-current text-red-500" />
                </span>
              }
              body={
                <div className="text-sm text-grey-500">
                  Are You Sure! You want to delete this vendor Compensation?
                </div>
              }
              buttonTitle="Confirm"
              onClick={() => {
                deleteAttribute(Datatable.row);
              }}
              buttonClassName="underline"
            />}{" "}
            |{" "}
            {Datatable.row.original.status === 0 ? (
              checkAccess(19, 'edit') && <ModalActivate
                title="Activate"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to Deactivate this vendor Compensation?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  handleActive(1, Datatable.cell.row.original);
                }}
                buttonClassName="underline"
              />
            ) : (
              checkAccess(19, 'edit') && <ModalActivate
                title="Deactivate"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to Active this vendor Subscription?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  handleActive(0, Datatable.cell.row.original);
                }}
                buttonClassName="underline"
              />
            )}
          </>
        ),
      },
    ],
    []
  );

  const getPageData = (data, type) => {
    if (type === "limit") {
      getAllSubscriptionList(data, "", 1);
    } else {
      getAllSubscriptionList(data, "", 1);
    }
  };

  const data = React.useMemo(() => Subscrption, [Subscrption]);

  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
    return (
      <>
        <div className="flex justify-between vendor_sub_btn_box">
          <div>
            <input
              className="searchCss"
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          {checkAccess(19, 'add') && <Link to="/add_vendor_subscription" className="vendor_btn">
            <button className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded addFilterCss">Add Compensation</button>
          </Link>}
        </div>
        <SubscriptionListTables
          columns={columns}
          data={data}
          loading={Loading}
          pagination={paginationObject}
          onSubmit={getPageData}
          showSearch={true}
          getAllSubscriptionList={getAllSubscriptionList}
        />
      </>
    );
  }
};

const Suscription = () => (
  <>
    <SectionTitle title="" subtitle="Vendor Compensation" />

    <Widget>
      <Simple />
    </Widget>
  </>
);
export default Suscription;
