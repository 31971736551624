/* eslint-disable no-sparse-arrays */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "../discounts/discount.css";
import "../salesVoucher/addVoucher.css";
import ApiClient from "../../apis/apiClient";
import moment from "moment";

import { Link, useHistory, useParams } from "react-router-dom";

import {
  API_GetAllUsers,
  API_GetAllVendors,
  salesVoucher,
  url,
} from "../../apis/constant";
import { toast } from "react-toastify";
import axios from "axios";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { vendorVoucherTypes, voucherTypes } from "../../utils/constants";
import DateInput from "./DateInput";

function EditVoucher() {
  const [discType, setDiscType] = useState("FIXED");
  const [masterCatdata, setMasterCatData] = useState([]);
  const [productTypeValue, setProductTypeValue] = useState("");
  const [fixedOrPerDisc, setFixedOrPerDisc] = useState("");
  const [purchaseAmnt, setPurchaseAmnt] = useState("");
  const [elegibility, setElegibility] = useState("");
  const [customerEligibility, setCustomerEligibility] = useState("");
  const [selectedSpecificCustomer, setSelectedSpecificCustomer] = useState([]);
  const [
    selectedSpecificCustomerSegments,
    setSelectedSpecificCustomerSegments,
  ] = useState([]);
  const [expiry, setExpiry] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endTime, setEndTime] = useState("");
  const [checked, setChecked] = useState(false);
  const [vendor, setVendor] = useState("");
  const [vendorProducts, setvendorPrdoduct] = useState("");
  const [proSku, setProductSku] = useState("");
  const [voucherName, setVoucherName] = useState("");
  const [discription, setDiscription] = useState("");
  const [voucherCode, setVoucherCode] = useState("");
  const [noOfUses, setNoOfUses] = useState("");
  const [specificCustomer, setSpecificCustomer] = useState([]);

  const [isSubmit, setIsSubmit] = useState(false);

  let history = useHistory();
  const { id } = useParams();

  const adminToken = localStorage.getItem("wed_admin_token");

  const specificCustomerSegments = [
    {
      label: "Customer not yet purchased",
      value: "Customer not yet purchased",
    },
    {
      label: "Customers with only 1 purchase",
      value: "Customers with only 1 purchase",
    },
  ];

  const handleVendorList = (e) => {
    if (e !== null) {
      setVendor(e);
    }
  };

  useEffect(() => {
    getSalesDiscountById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSalesDiscountById = () => {
    axios
      .get(url + salesVoucher + "/" + id, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((res) => {
        if (res.data.code === 200) {
          setVoucherName(res.data.data.voucherName);
          setDiscription(res.data.data.description);
          setVoucherCode(res.data.data.code);
          setProductSku(res.data.data.applicableProduct);
          setMasterCatData(res.data.data.productCategory);
          handleVendorList({
            id: res.data.data.vendorId?.id,
            name: res.data.data.vendorId?.vendorName,
          });
          setVendor(res?.data?.data?.vendorId ?? "");
          setProductTypeValue(res.data.data.voucherType);
          setvendorPrdoduct(res.data.data.productFamily);
          setDiscType(res.data.data.discountType);
          setFixedOrPerDisc(res.data.data.discountValue);
          setCustomerEligibility(res.data.data.customerEligibility.to);
          if (res.data.data.customerEligibility.to === "specificCustomer") {
            setSelectedSpecificCustomer(
              res.data.data.customerEligibility.specificCustomer
            );
          } else if (
            res.data.data.customerEligibility.to === "specificCustomerSegments"
          ) {
            setSelectedSpecificCustomerSegments(
              res.data.data.customerEligibility.specificCustomerSegments
            );
          }
          if (res.data.data.maximumUsage === -1) {
            setElegibility("unlimited");
          } else {
            setElegibility("specifyNoOfUsers");
          }
          setNoOfUses(res.data.data.maximumUsage);
          setExpiry(res.data.data.expiry.options);
          const expiry = res.data.data.expiry;
          let startDate = "";
          let startTime = "";
          if (expiry.dateTime) {
            startTime = parseDateTimeIntoHoursAndMinutes(expiry.dateTime);
            startDate = moment(expiry.dateTime).format("YYYY-MM-DD");
          }
          setStartDate(startDate);
          setStartTime(startTime);
          if (res.data.data.expiry.options === "selectDateandTimeRange") {
            let endDate = "";
            let endTime = "";
            if (expiry.endDateTime) {
              endTime = parseDateTimeIntoHoursAndMinutes(expiry.endDateTime);
              endDate = moment(expiry.endDateTime).format("YYYY-MM-DD");
            }

            setEndDate(endDate);
            setEndTime(endTime);
          }
          if (res.data.data.minimumPurchase > 0) {
            setChecked(true);
            setPurchaseAmnt(res.data.data.minimumPurchase);
          }
        }
      })
      .catch((err) => {
        console.error("Error on getSalesDiscountById", err);
        // toast.error(err.response.data.message);
      });
  };
  const parseDateTimeIntoHoursAndMinutes = (dateTime) => {
    if (!dateTime) return "";
    const expiryDateTime = new Date(dateTime);
    let hours = expiryDateTime.getHours();
    if (hours.toString().length === 1) {
      hours = "0" + hours;
    }
    let minutes = expiryDateTime.getMinutes();
    if (minutes.toString().length === 1) {
      minutes = "0" + minutes;
    }
    return hours + ":" + minutes;
  };

  const getAllvendors = async (search = "") => {
    const data = "";

    const res = await ApiClient.get(
      API_GetAllVendors +
      `?page=${1}&sortBy=asc&limit=${20}&searchBy=${search}`, // page number and status issue
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      const data = res.data.data.docs.map((item) => ({
        id: item.id,
        name: item.vendorName,
        ...item,
      }));
      return data;
    }
  };

  const getAllUsers = async () => {
    try {
      const res = await ApiClient.get(
        API_GetAllUsers +
        `?page=${1}&sortBy=asc&limit=${9999}&searchBy=&vendorId=${vendor?.id ?? ""
        }`,
        null,
        `Bearer ${adminToken}`
      );
      if (res.data.code === 200) {
        setSpecificCustomer(
          res.data.data.docs.map((data) => ({
            value: data.id ?? data._id,
            label: data.email,
          }))
        );
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  };

  useEffect(() => {
    if (customerEligibility === "specificCustomer") {
      getAllUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerEligibility, vendor]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmit(false);

    if (productTypeValue === "") {
      toast.error("Voucher type can not empty");
      return;
    }
    if (fixedOrPerDisc === "") {
      toast.error("Discount value can not empty");
      return;
    }
    if (voucherName === "") {
      toast.error("Voucher Name can not empty");
      return;
    }
    if (!startDate) {
      toast.error("Start Date may not be blank");
      return;
    }
    if (!startTime) {
      toast.error("Start Time may not be blank");
      return;
    }
    if (expiry === "selectDateandTimeRange") {
      if (!endDate) {
        toast.error("End Date may not be blank");
        return;
      }
      if (!endTime) {
        toast.error("End Time may not be blank");
        return;
      }
      if (endDate && moment(startDate).isAfter(endDate)) {
        toast.error("End Date may not be earlier than Start Date!");
        return;
      }
    } else {
      setEndDate("");
      setEndTime("");
    }

    let dateTime = "";
    let endDateTime = "";
    if (startDate && startTime) {
      dateTime = new Date(
        moment(startDate).format("DD-MMM-YYYY") + " " + startTime
      );
    }
    if (endDate && endTime && expiry === "selectDateandTimeRange") {
      endDateTime = new Date(
        moment(endDate).format("DD-MMM-YYYY") + " " + endTime
      );
    }

    let logData = {
      vendorId: vendor?._id ?? null,
      voucherType: productTypeValue,
      productCategory: masterCatdata,
      productType: null,
      productFamily: vendorProducts,
      voucherName: voucherName,
      description: discription,
      minimumPurchase: purchaseAmnt,
      discountType: discType,
      discountValue: fixedOrPerDisc,
      maximumUsage: noOfUses,
      code: voucherCode,
      applicableProduct: proSku,
      customerEligibility: {
        to: "everyOne",
        specificCustomer: [],
        specificCustomerSegments: [],
      },
      expiry: {
        options: "noEndData",
        dateTime: dateTime,
        endDateTime: endDateTime,
      },
    };

    if (expiry === "once") {
      logData["expiry"] = {
        options: "once",
        dateTime: dateTime,
        endDateTime: endDateTime,
      };
    }

    if (expiry === "selectDateandTimeRange") {
      logData["expiry"] = {
        options: "selectDateandTimeRange",
        dateTime: dateTime,
        endDateTime: endDateTime,
      };
    }

    if (customerEligibility === "specificCustomer") {
      logData["customerEligibility"] = {
        to: "specificCustomer",
        specificCustomer: selectedSpecificCustomer,
        specificCustomerSegments: [],
      };
    } else if (customerEligibility === "specificCustomerSegments") {
      logData["customerEligibility"] = {
        to: "specificCustomerSegments",
        specificCustomer: [],
        specificCustomerSegments: selectedSpecificCustomerSegments,
      };
    }
    try {
      const res = await ApiClient.patch(
        salesVoucher + "/" + id,
        logData,
        `Bearer ${adminToken}`
      );

      if (res.data.code === 200) {
        toast.success("Voucher has been successfully updated");

        history.push("/sales/voucher");
      } else if (res.data.code === 400) {
        toast.error("Voucher can not be updated");
        setIsSubmit(false);
      }
    } catch (e) {
      toast.error(e?.message ?? "Error occured please try again!");
    }
  };

  return (
    <div>
      <div className="w-full shadow-xl px-5 py-5 mt-10">
        <div className="flex items-center justify-between cursor-pointer">
          <p className="text-xl">Update Voucher</p>
          <div className="" style={{ float: "right" }}>
            <Link
              to={"/sales/voucher"}
              className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
            >
              Back
            </Link>
          </div>
        </div>
        <form>
          <div className="mt-10 animate-fade-in-down w-full">
            <div className="w-full flex items-center space-x-2 custom-flex">
              <div className="w-full">
                <p className="mb-0">Vendor ID</p>
                <AsyncSelect
                  className="w-100"
                  cacheOptions
                  defaultOptions
                  isClearable
                  value={vendor}
                  getOptionLabel={(e) => e.vendorId}
                  getOptionValue={(e) => e.id}
                  loadOptions={getAllvendors}
                  onChange={(e) => {
                    setVendor(e);
                    setSpecificCustomer([]);
                    setSelectedSpecificCustomer([]);
                  }}
                />
              </div>
              <div className="w-full">
                <p className="mb-0">Vendor Name</p>
                <AsyncSelect
                  className="w-100"
                  cacheOptions
                  defaultOptions
                  isClearable
                  value={vendor}
                  getOptionLabel={(e) => e.vendorName}
                  getOptionValue={(e) => e.id}
                  loadOptions={getAllvendors}
                  onChange={(e) => {
                    setVendor(e);
                    setSpecificCustomer([]);
                    setSelectedSpecificCustomer([]);
                  }}
                />
              </div>
            </div>
            <div className="flex space-x-2 relative custom-flex mt-10">
              <div className="w-full">
                <p className="mb-0">Voucher Type</p>
                <Select
                  options={[...voucherTypes, ...vendorVoucherTypes]}
                  value={{
                    value: productTypeValue,
                    label: productTypeValue,
                  }}
                  name="productType"
                  onChange={(value) => setProductTypeValue(value.value)}
                  isMulti={false}
                  placeholder="Select Voucher Type"
                />
              </div>
              <div className="w-full">
                <p className="mb-0">Voucher Name</p>
                <input
                  type="text"
                  value={voucherName}
                  onChange={(e) => setVoucherName(e.target.value)}
                  className="border border-grey-400 rounded-sm w-full px-3 py-2"
                />
              </div>
              <div className="w-full">
                <p className="mb-0">Code</p>
                <input
                  type="text"
                  value={voucherCode}
                  onChange={(e) => setVoucherCode(e.target.value)}
                  className="border border-grey-400 rounded-sm w-full px-3 py-2"
                />
              </div>
              <div className="w-full">
                <p>Type</p>
                <select
                  className="border border-grey-400 rounded-sm w-full px-3 py-2"
                  onChange={(e) => setDiscType(e.target.value)}
                  value={discType}
                >
                  <option value="PERCENT">Percentage %</option>
                  <option value="FIXED">Fixed Amount</option>
                </select>
              </div>
              {discType === "PERCENT" ? (
                <div className="w-full">
                  <p>Voucher Value</p>
                  <div className="w-full flex">
                    <select
                      className="border border-grey-400 rounded-sm w-full px-3 py-2"
                      value={fixedOrPerDisc}
                      onChange={(e) => setFixedOrPerDisc(e.target.value)}
                    >
                      <option value="0">0</option>
                      {new Array(100).fill().map((_, i) => (
                        <option value={i + 1}>{i + 1} %</option>
                      ))}
                    </select>
                  </div>
                </div>
              ) : (
                <div className="w-full">
                  <p>Voucher Value</p>
                  <div className="w-full flex">
                    <input
                      type="number"
                      className="border border-grey-400 rounded-sm w-full px-3 py-2"
                      value={fixedOrPerDisc}
                      onChange={(e) => setFixedOrPerDisc(e.target.value)}
                    />
                  </div>
                </div>
              )}
            </div>
            {/* <div className="w-full mt-10">
              <div className="flex items-center">
                <p>Apply Discount at Order Level</p>
                <input
                  type="checkbox"
                  className="ml-2 w-4 h-4"
                  checked={applyDiscountAtOrderLevel}
                  onChange={(e) =>
                    setApplyDiscountAtOrderLevel(e.target.checked)
                  }
                />
              </div>
            </div> */}
            <div className="mt-10">
              <div className="flex items-end">
                <div className="mr-3">
                  <p>Minimum Purchase Amount</p>
                  <div className="flex items-center">
                    <input
                      className="w-4 h-4"
                      type="checkbox"
                      id="minimum"
                      checked={checked}
                      onChange={(e) => {
                        setChecked(e.target.checked);
                        if (!e.target.checked) {
                          setPurchaseAmnt(0);
                        }
                      }}
                    />
                    <label htmlFor="minimum" className="ml-2" value="yes">
                      Yes
                    </label>
                  </div>
                </div>
                {checked === true && (
                  <div className="flex">
                    <input
                      className="border border-grey-400 rounded-sm w-full px-3 py-2"
                      type="number"
                      value={purchaseAmnt}
                      inputmode="numeric"
                      onChange={(e) => setPurchaseAmnt(e.target.value)}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex space-x-2 mt-5">
              <div className="w-full">
                <p className="mb-2">Customer Eligibility</p>
                <div className="flex items-start space-x-5 custom-flex">
                  <div className="flex items-center radio-box mb-2">
                    <input
                      type="radio"
                      name="elegibility"
                      id="elegibility1"
                      className="w-5 h-5 mr-2"
                      value="everyOne"
                      checked={customerEligibility === "everyOne"}
                      onChange={(e) => setCustomerEligibility(e.target.value)}
                    />
                    <label className="text-sm" htmlFor="elegibility1">
                      Everyone
                    </label>
                  </div>

                  <div className="flex flex-col items-start justify-end relative">
                    <div className="flex items-center mb-2 radio-box">
                      <input
                        type="radio"
                        name="elegibility"
                        id="elegibility2"
                        className="w-5 h-5 mr-2"
                        value="specificCustomer"
                        checked={customerEligibility === "specificCustomer"}
                        onChange={(e) => setCustomerEligibility(e.target.value)}
                      />
                      <label className="text-sm" htmlFor="elegibility2">
                        Specific Customer
                      </label>
                    </div>
                    {customerEligibility === "specificCustomer" && (
                      <Select
                        options={specificCustomer}
                        className="w-full"
                        name="specificCustomer"
                        value={selectedSpecificCustomer}
                        onChange={(value) => setSelectedSpecificCustomer(value)}
                        isMulti={true}
                        placeholder="Select multiple..."
                      />
                    )}
                  </div>

                  <div className="flex flex-col items-start justify-end relative">
                    <div className="flex items-center mb-2 radio-box">
                      <input
                        type="radio"
                        name="elegibility"
                        id="elegibility3"
                        className="w-5 h-5 mr-2"
                        value="specificCustomerSegments"
                        checked={
                          customerEligibility === "specificCustomerSegments"
                        }
                        onChange={(e) => setCustomerEligibility(e.target.value)}
                      />
                      <label className="text-sm" htmlFor="elegibility3">
                        Specific Customer Segments
                      </label>
                    </div>

                    {customerEligibility === "specificCustomerSegments" && (
                      <Select
                        options={specificCustomerSegments}
                        className="w-full"
                        name="specificCustomerSegments"
                        value={selectedSpecificCustomerSegments}
                        onChange={(value) =>
                          setSelectedSpecificCustomerSegments(value)
                        }
                        isMulti={true}
                        placeholder="Select multiple..."
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-10">
              <div className="w-full relative">
                <p className="mb-0">Usage Limits Per Customer:</p>
                <div className="flex items-center space-x-3">
                  <div className="relative w-1/2">
                    <select
                      className="border border-grey-400 rounded-sm w-full px-3 py-2"
                      value={elegibility}
                      onChange={(e) => {
                        setElegibility(e.target.value);
                        if (e.target.value === "unlimited") {
                          setNoOfUses(-1);
                        } else {
                          setNoOfUses(1);
                        }
                      }}
                    >
                      <option value="unlimited">Unlimited</option>
                      <option value="specifyNoOfUsers">
                        Specify no of usage
                      </option>
                    </select>
                  </div>
                  {elegibility === "specifyNoOfUsers" && (
                    <input
                      type="number"
                      className="border border-grey-400 rounded-sm w-1/2 px-3 py-2"
                      placeholder="Specify no of usage"
                      value={noOfUses}
                      onChange={(e) => setNoOfUses(e.target.value)}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="flex space-x-2 mt-5">
              <div className="w-full space-y-2">
                <p className="mb-2">Active Dates</p>
                <div className="flex items-start space-x-5">
                  <div className="w-full flex space-x-1 custom-flex">
                    <DateInput
                      label={"Start Date"}
                      className="mr-2"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                    <DateInput
                      type="time"
                      label={"Start Time"}
                      value={startTime}
                      onChange={(e) => setStartTime(e.target.value)}
                    />
                    <DateInput
                      label={"End Date"}
                      className="mr-2"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      disabled={expiry === "noEndData"}
                    />
                    <DateInput
                      label={"End Date"}
                      type="time"
                      value={endTime}
                      onChange={(e) => setEndTime(e.target.value)}
                      disabled={expiry === "noEndData"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-start items-center mt-5">
              <label htmlFor="checkboxNoEndDate">No End Date</label>
              <input
                type="checkbox"
                name="appliesTo"
                id="checkboxNoEndDate"
                className="w-4 h-4 ml-2"
                checked={expiry === "noEndData"}
                onChange={() => {
                  setEndDate("");
                  setEndTime("");
                  setExpiry(
                    expiry === "noEndData"
                      ? "selectDateandTimeRange"
                      : "noEndData"
                  );
                }}
              />
            </div>
          </div>
          <div className="w-full flex justify-end mt-10 custom-flex">
            {isSubmit ? (
              <button className="flex items-center px-5 py-2 bg-blue-500 hover:bg-blue-600 rounded-sm text-white mt-3">
                Loading...
              </button>
            ) : (
              <button
                className="flex items-center px-5 py-2 bg-blue-500 hover:bg-blue-600 rounded-sm text-white mt-3"
                onClick={(e) => handleSubmit(e)}
              >
                Save Voucher
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditVoucher;
