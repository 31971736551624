/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import SectionTitle from "../components/section-title";
import Commissiontable from "../components/commission";
import Widget from "../components/widget";
import { toast } from "react-toastify";
import { FiX } from "react-icons/fi";
import ModalActivate from "../components/confirm-activate-modal/modal-activate";
import ApiClient from "../apis/apiClient";
import {
  API_GetAllCommissionDetails,
  API_UpdateCommissionDetailsById,
} from "../apis/constant";
import EditCommissionList from "../components/commission/EditCommission";
import AddCommission from "../components/commission/AddCommission";
import { Redirect } from "react-router-dom";
import { checkAccess } from "../utils/common";

const Simple = () => {
  const [commissionData, setCommissionData] = useState([]);
  const [Loading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [monthlyData, setMonthlyData] = useState("");
  const [limit, setLimit] = useState(10);
  const [paginationObject, setPaginationObject] = useState({});
  const adminToken = localStorage.getItem("wed_admin_token");

  const getAllCommission = async (limit, search, pageNumber) => {
    const data = "";
    setIsLoading(true);
    const res = await ApiClient.get(
      API_GetAllCommissionDetails +
        `?page=${pageNumber}&sortBy=asc&limit=${limit}&searchBy=${search}`,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      if (res.data.data && res.data.data.docs.length !== 0) {
        for (let i = 0; i < res.data.data.docs.length; i++) {
          let element = res.data.data.docs[i];
          res.data.data.docs[i]["displayStatus"] =
            res.data.data.docs[i].status === 0 ? (
              <label className="">Inactive</label>
            ) : (
              <label className="">Active</label>
            );
        }
      }
      setLimit(res.data.data.limit);
      // setPage()
      setPaginationObject({
        limit: res.data.data.limit,
        page: res.data.data.page,
        pages: res.data.data.pages,
        total: res.data.data.total,
      });
      setCommissionData(res.data.data.docs);
      setIsLoading(false);
      setMonthlyData(res.data.data.docs.monthlyFee);
    }
  };
  useEffect(() => {
    if (search.length > 2 || search.length === 0) {
      // if(paginationObject.limit !== undefined) {
      getAllCommission(limit, search, 1);
      // }else {

      // }
    }
  }, [search, limit]);

  const handleActive = async (status, data) => {
    let myObj = {
      status: status,
      name: data.name,
      quarterlyFee: String(data.quarterlyFee),
      yearlyFee: String(data.yearlyFee),
      monthlyFee: String(data.monthlyFee),
    };
    const res = await ApiClient.patch(
      API_UpdateCommissionDetailsById + data.id,
      myObj,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      const text = status === 0 ? "activated" : "deactivated";
      toast.success(`Subscription ${text} Successfully`);
      getAllCommission(limit, search, 1);
    } else {
      toast.warning(res.data.message);
    }
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Plan Name",
        accessor: "name",
      },
      {
        Header: "Monthly Fee",
        accessor: "monthlyFee",
      },
      {
        Header: "Quarterly Fee",
        accessor: "quarterlyFee",
      },
      {
        Header: "Yearly Fee",
        accessor: "yearlyFee",
      },
      {
        Header: "Status",
        accessor: "displayStatus",
      },
      {
        Header: "Action",
        id: "delete",
        accessor: (str) => {},

        Cell: (Datatable) => (
          <>
            {checkAccess(18, 'edit') && <EditCommissionList
              id={Datatable.row.original.id}
              name={Datatable.row.original.name}
              monthlyFee={Datatable.row.original.monthlyFee}
              quarterlyFee={Datatable.row.original.quarterlyFee}
              yearlyFee={Datatable.row.original.yearlyFee}
              status={Datatable.row.original.status}
              title={"Edit Subscription"}
              body={"body"}
              getApi={getAllCommission}
            />}{" "}
            |{" "}
            {Datatable.row.original.status === 0 ? (
              checkAccess(18, 'edit') && <ModalActivate
                title="Activate"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to Deactivate this Subscription?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  handleActive(1, Datatable.cell.row.original);
                }}
                buttonClassName="underline"
              />
            ) : (
              checkAccess(18, 'edit') && <ModalActivate
                title="Deactivate"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to Active this Subscription?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  handleActive(0, Datatable.cell.row.original);
                }}
                buttonClassName="underline"
              />
            )}
          </>
        ),
      },
    ],
    []
  );

  const getPageData = (data, type) => {
    // if (type === "limit") {
    getAllCommission(data, search, 1);
    // }
  };

  const data = React.useMemo(() => commissionData, [commissionData]);
  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
    return (
      <>
        <div className="flex justify-between">
          <div>
            <input
              className="searchCss"
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          {checkAccess(18, 'add') &&<AddCommission
            className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white "
            type="button"
            title={"Add Subscription"}
            body={"body"}
            getApi={getAllCommission}
          />}
          {/* <button
              className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white "
              type="button"
              // onClick={show}
            >
              Add Commision
            </button> */}
        </div>
        <Commissiontable
          columns={columns}
          data={data}
          loading={Loading}
          pagination={paginationObject}
          onSubmit={getPageData}
          showSearch={true}
          getAllCommission={getAllCommission}
        />
      </>
    );
  }
};

const Index = () => (
  <>
    <SectionTitle title="Manage Subscription" subtitle="Subscription" />

    <Widget>
      <Simple />
    </Widget>
  </>
);
export default Index;
