import React, { useState, useEffect } from "react";
import { useHistory, Link, useParams, Redirect } from "react-router-dom";
import {
  url,
  API_UpdateFilterById,
  API_MasterCategoryList,
  API_GetAllSubCategory,
  API_GetAllCategory,
} from "../../apis/constant";
import ApiClient from "../../apis/apiClient";
import axios from "axios";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";
import { getPreviewUrl } from "../../utils/common";

const EditFilterData = () => {
  const adminToken = localStorage.getItem("wed_admin_token");
  const history = useHistory();
  const [isSubmit, setIsSubmit] = useState(false);
  const [subCatList, setSubCategoryList] = useState([]);
  const [categryList, setCategoryList] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [error, setError] = useState("");
  const [masterCatError, setMasterCatError] = useState("");
  const [catError] = useState("");
  const [subCatError] = useState("");
  const [filterError, setFilterError] = useState("");
  const [filterTypes, setFilterTypes] = useState([{ id: 1, inputValue: "", isImg: false, image: "" },]);
  const [selectedMasterCategoryValue, setSelectedMasterCategoryValue] = useState(null);
  const [selectedCategoryValue, setSelectedCategoryValue] = useState(null);
  const [selectedSubCategoryValue, setSelectedSubCategoryValue] = useState(null);

  const alphanumericRegex = /^[a-zA-Z0-9-. ()]+$/;
  const { id } = useParams();

  useEffect(() => {
    getcategoryById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getcategoryById = () => {
    axios
      .get(url + API_UpdateFilterById + "/" + id, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((res) => {
        if (res.data.code === 200) {
          setNameFilter(res.data.data.name);
          handleAMasterCategory(res.data.data.masterCategoryId);
          if (res.data.data.categoryId) {
            handleACategory(res.data.data.categoryId);
          } else {
            setSelectedCategoryValue(null);
          }
          if (res.data.data.subCategoryId) {
            handleASubCategory(res.data.data.subCategoryId);
          } else {
            setSelectedSubCategoryValue(null);
          }
          let newArr = [];
          let count = 1;
          let elm = res.data.data.filterTypes;
          for (let i = 0; i < elm.length; i++) {
            newArr.push({
              id: count,
              inputValue: elm[i].name,
              isImg: elm[i].hasOwnProperty("image") ? elm[i].image.length > 0 ? true : false : false,
              image: elm[i].hasOwnProperty("image") ? getPreviewUrl(elm[i].image) : "",
            });
            count += 1;
          }
          setFilterTypes(newArr);
        }
      })
      .catch((err) => { });
  };

  const handleChange = (value, type) => {
    const limit = 200;
    if (value.length > limit) {
      setError("Please enter less than 200 words.");
    } else if (type === "name") {
      if (value && !alphanumericRegex.test(value)) {
        setError("Filters should not be blank.");
      } else {
        setError("");
      }
      setNameFilter(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let isTrue = false;
    setIsSubmit(true);

    if (selectedMasterCategoryValue?.id === "") {
      setMasterCatError("Level 1 should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setMasterCatError("");
    }

    if (nameFilter === "") {
      setError("Name should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setError("");
    }

    for (let i = 0; i < filterTypes.length; i++) {
      if (filterTypes[i].inputValue.length === 0) {
        setFilterError("Filter Values should not be blank.");
        isTrue = true;
        setIsSubmit(false);
        break;
      } else {
        setFilterError("");
      }
    }

    if (isTrue) {
      return;
    }

    let logData = new FormData();
    let emptyArr = [];
    for (let i = 0; i < filterTypes.length; i++) {
      if (typeof filterTypes[i].image === "object") {
        emptyArr.push({
          name: filterTypes[i].inputValue,
          image: filterTypes[i].image !== "" ? `images${i}` : "",
        });
        logData.append(`images${i}`, filterTypes[i].image);
      } else {
        var img = filterTypes[i].image.split("=");
        emptyArr.push({
          name: filterTypes[i].inputValue,
          image: img[1],
        });
      }
    }
    logData.append("filterTypes", JSON.stringify(emptyArr));
    logData.append("name", nameFilter);
    logData.append("title", nameFilter);
    logData.append("masterCategoryId", selectedMasterCategoryValue?.id);
    if (selectedCategoryValue !== null) {
      logData.append("categoryId", selectedCategoryValue?.id);
    } else {
      logData.append("categoryId", "");
    }
    if (selectedSubCategoryValue !== null) {
      logData.append("subCategoryId", selectedSubCategoryValue?.id);
    } else {
      logData.append("subCategoryId", "");
    }
    axios
      .patch(url + API_UpdateFilterById + id, logData, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((res) => {
        if (res.data.code === 200) {
          toast.success(res.data.message);
          history.push("/filters");
        } else if (res.data.code === 400) {
          toast.error(res.data.message);
          setIsSubmit(false);
        }
      })
      .catch((err) => {
        setIsSubmit(false);
      });
  };

  const handleAMasterCategory = async (e) => {
    setCategoryList([]);
    setSubCategoryList([]);
    setSelectedMasterCategoryValue(e);
    getCategoryListUsingMasterId("");
    getSubCategoryListUsingCategoryId("");
  };

  const getAllMasterCategory = async (inputValue) => {
    const data = "";
    // setIsApiCall(true);
    const res = await ApiClient.get(
      API_MasterCategoryList +
      `?page=1&sortBy=asc&limit=${999999}&searchBy=${inputValue}&isFor=dropdown`,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      return res.data.data.docs;
    }
  };

  const handleACategory = async (e) => {
    setSubCategoryList([]);

    setSelectedCategoryValue(e);
    getSubCategoryListUsingCategoryId("");
  };

  const getCategoryListUsingMasterId = async (inputValue) => {
    const res = await ApiClient.get(
      API_GetAllCategory + `?masterCategoryId=${selectedMasterCategoryValue?.id ?? ""}&page=1&sortBy=asc&limit=${999999}&searchBy=${inputValue}&isFor=dropdown`, {},
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      let full_arr = res.data.data.docs;
      setCategoryList(full_arr);
      return full_arr;
    }
  };

  const handleASubCategory = (e) => {
    setSelectedSubCategoryValue(e);
  };

  const getSubCategoryListUsingCategoryId = async (inputValue) => {
    const res = await ApiClient.get(
      API_GetAllSubCategory +
      `?masterCategoryId=${selectedMasterCategoryValue?.id ?? ""}&categoryId=${selectedCategoryValue?.id ?? ""}&page=1&sortBy=asc&limit=${99999}&searchBy=${inputValue}&isFor=dropdown`,
      {},
      `Bearer ${adminToken}`
    );

    if (res.data.code === 200) {
      let full_arr = res.data.data.docs;
      setSubCategoryList(full_arr);
      return full_arr;
    }
  };

  const removeFilterType = (item) => {
    let WhArr = [...filterTypes];
    let filteredItem = WhArr.filter((data) => data.id !== item.id);
    setFilterTypes(filteredItem);
  };

  const handleImageUpload = (image, item) => {
    let WhArr = [...filterTypes];
    let newArr = [];
    for (let i = 0; i < WhArr.length; i++) {
      if (item.id === WhArr[i].id) {
        newArr.push({
          id: item.id,
          inputValue: item.inputValue,
          isImg: true,
          image: image,
        });
      } else {
        newArr.push(WhArr[i]);
      }
    }
    setFilterTypes(newArr);
  };

  const handleInputFilter = (e, item) => {
    const value = e.target.value;
    const limit = 200;

    let WhArr = [...filterTypes];
    let newArr = [];
    for (let i = 0; i < WhArr.length; i++) {
      if (item.id === WhArr[i].id) {
        if (value.length <= limit) {
          setFilterError("");
          newArr.push({
            id: item.id,
            inputValue: e.target.value,
            isImg: item.isImg,
            image: item.image,
          });
        } else {
          newArr.push({
            id: item.id,
            inputValue: item.inputValue,
            isImg: item.isImg,
            image: item.image,
          });
          setFilterError("Please enter less than 200 words.");
        }
      } else {
        newArr.push(WhArr[i]);
      }
    }
    setFilterTypes(newArr);
  };

  const increaseFilter = (e) => {
    e.preventDefault();
    if (filterTypes) {
      let wholeArr = [...filterTypes];
      wholeArr.unshift({
        id: filterTypes.length + 1,
        inputValue: "",
        isImg: false,
        image: "",
      });
      setFilterTypes(wholeArr);
    }
  };

  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
    return (
      <>
        <div className="addBack" style={{ marginTop: "15px" }}>
          <Link
            to={"/filters"}
            className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
          >
            Back
          </Link>
        </div>

        <div className="flex_box">
          <div className="form-element">
            <div className="form-label">Level 1:</div>
            <AsyncSelect
              className="w-100"
              cacheOptions
              defaultOptions
              isClearable
              value={selectedMasterCategoryValue}
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.id}
              loadOptions={getAllMasterCategory}
              onChange={handleAMasterCategory}
            />
            {masterCatError && (
              <p
                style={{
                  color: "red",
                  padding: "10px 4px",
                  paddingLeft: "5px",
                }}
              >
                {masterCatError}
              </p>
            )}
          </div>

          <div className="form-element">
            <div className="form-label">Level 2:</div>
            <AsyncSelect
              className="w-100"
              key={categryList.length}
              cacheOptions
              defaultOptions
              isClearable
              value={selectedCategoryValue}
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.id}
              loadOptions={getCategoryListUsingMasterId}
              onChange={handleACategory}
            />
            {catError && (
              <p
                style={{
                  color: "red",
                  padding: "10px 4px",
                  paddingLeft: "5px",
                }}
              >
                {catError}
              </p>
            )}
          </div>
        </div>

        <div className="flex_box">
          <div className="form-element">
            <div className="form-label">Level 3:</div>
            <AsyncSelect
              className="w-100"
              key={subCatList.length}
              cacheOptions
              defaultOptions
              isClearable
              value={selectedSubCategoryValue}
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.id}
              loadOptions={getSubCategoryListUsingCategoryId}
              onChange={handleASubCategory}
            />
            {subCatError && (
              <p
                style={{
                  color: "red",
                  padding: "10px 4px",
                  paddingLeft: "5px",
                }}
              >
                {subCatError}
              </p>
            )}
          </div>

          <div className="form-element">
            <div>Filter Name</div>
            <input
              type="text"
              name="name"
              placeholder="type Name..."
              value={nameFilter}
              className="inputFilterFields"
              id=""
              onChange={(e) => handleChange(e.target.value, "name")}
              style={{ height: "38px", marginTop: "8px" }}
            />
            {error && (
              <p
                style={{
                  color: "red",
                  padding: "10px 4px",
                  paddingLeft: "5px",
                }}
              >
                {error}
              </p>
            )}
          </div>
        </div>

        <div className="flex_box tagBox">
          <div className="form-element w-100">
            <label>Filter Values</label>
            <div>
              <img
                src="https://icons.iconarchive.com/icons/awicons/vista-artistic/256/add-icon.png"
                onClick={(e) => increaseFilter(e)}
                alt="add"
                style={{
                  width: "20px",
                  margin: "10px 5px",
                  cursor: "pointer",
                }}
              // className="removeCss"
              />
            </div>
            {filterTypes.map((item, idx) => (
              <div className="inputTag">
                <div className="flex_box" style={{ background: "transparent" }}>
                  <div className="form-element">
                    <input
                      type="text"
                      className="inputFilterFields"
                      onChange={(e) => handleInputFilter(e, item)}
                      value={item.inputValue}
                    />
                  </div>
                  <div className="form-element">
                    <div className="inline_b">
                      {filterTypes.length > 1 && (
                        <img
                          src="https://icons.iconarchive.com/icons/hopstarter/sleek-xp-basic/256/Close-2-icon.png"
                          onClick={(e) => removeFilterType(item)}
                          alt="close"
                          style={{
                            width: "20px",
                            margin: "10px 5px",
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="flex flex-row justify-start w-full py-1 space-x-2 mb-15 product-gallery"
                  style={{ background: "transparent" }}
                >
                  <div className="image-upload1">
                    <label htmlFor={`profile-img-${idx}`}>
                      <img
                        src="https://icons.iconarchive.com/icons/awicons/vista-artistic/256/add-icon.png"
                        title="Choose a photo!"
                        alt="choose"
                        style={{ width: "30px" }}
                      />
                    </label>
                    <input
                      style={{ display: "none" }}
                      type="file"
                      id={`profile-img-${idx}`}
                      onChange={(e) =>
                        handleImageUpload(e.target.files[0], item)
                      }
                      accept=".png, .jpeg, .jpg, .JPG, .PNG, .JPEG"
                    />
                  </div>

                  {item.isImg && (
                    <div className="img-box1">
                      <img
                        className="img"
                        alt="custom"
                        src={
                          typeof item.image === "string"
                            ? item.image
                            : URL.createObjectURL(item.image)
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
            {filterError && (
              <p
                style={{
                  color: "red",
                  padding: "10px 4px",
                  paddingLeft: "5px",
                }}
              >
                {filterError}
              </p>
            )}
          </div>
        </div>

        <div className="flex flex-col ">
          <div className="inputBoxCategoryCss">
            {isSubmit ? (
              <button className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded">
                Loading...
              </button>
            ) : (
              <button
                className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
                onClick={(e) => handleSubmit(e)}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </>
    );
  }
};

export default EditFilterData;
