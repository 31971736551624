import React, { useState, useEffect, useRef } from "react";
import { useSelector, shallowEqual } from "react-redux";
import Portal from "../portal";
import { FiX } from "react-icons/fi";
import {
  API_CreateFaqTypes,
  url,
} from "../../apis/constant";
import { toast } from "react-toastify";
import axios from "axios";

const AddFaqCategory = ({ title, body, getApi }) => {
  const alphanumericRegex = /^[a-zA-Z0-9-. ]+$/;
  const adminToken = localStorage.getItem("wed_admin_token");
  const [error, setError] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [nameValue, setNameValue] = useState("");

  const { palettes } = useSelector(
    (state) => ({
      palettes: state.palettes,
    }),
    shallowEqual
  );
  let { background } = {
    ...palettes,
  };

  const modalRef = useRef(null);
  const [open, setOpen] = useState(false);
  const show = () => {
    setOpen(true);
  };
  const hide = () => {
    setOpen(false);
  };

  useEffect(() => {
    // getAllFaqTypes("");
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false;
      if (!open || modalRef.current.contains(event.target)) {
        return false;
      }
      setOpen(!open);
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, modalRef]);

  const handleNameValue = (value) => {
    if (value && !alphanumericRegex.test(value)) {
      setError("Please enter valid name.");
      setNameValue(value);
      return;
    } else {
      setError("");
      setNameValue(value);
    }
  };

  const handleFaqCategory = () => {
    setIsSubmit(true);
    if (nameValue === "") {
      setError("FaqType Name should not be blank.");
      setIsSubmit(false);
      return;
    }
    if (nameValue && !alphanumericRegex.test(nameValue)) {
      setError("Please enter valid Type.");
      setIsSubmit(false);
      return;
    }
    if ((nameValue && nameValue.length < 3) || nameValue.length > 50) {
      setError("Type length should be in between 2 to 50 characters");
      setIsSubmit(false);
      return;
    }
    setError("");
    let logData = {
      type: nameValue,
    };
    axios
      .post(url + API_CreateFaqTypes, logData, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((res) => {
        if (res.data.code === 200) {
          toast.success(res.data.message);
          hide();
          setNameValue("");
          setIsSubmit(false);

          getApi(10, "", 1);
        } else if (res.data.code === 400) {
          toast.error(res.data.message);
          setIsSubmit(false);
        }
      })
      .catch((err) => {
        setIsSubmit(false);
        toast.error(err.response.data.message);
      });
  };

  //

  // const getAllFaqTypes = async (limit, searchq = "") => {
  //   const data = "";
  //   // setIsApiCall(true);
  //   const res = await ApiClient.get(
  //     API_GetAllFaqTypes,
  //     data,
  //     `Bearer ${adminToken}`
  //   );
  //   if (res.data.code === 200) {
  //     if (res.data.data && res.data.data.docs.length !== 0) {
  //       for (let i = 0; i < res.data.data.docs.length; i++) {
  //         const element = res.data.data.docs[i];
  //       }
  //     }
  //     setAllFaqTypes(res.data.data.docs);
  //   }
  // };

  return (
    <>
      <button
        className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white ml-5"
        type="button"
        onClick={show}
      >
        {title}
      </button>
      {open && (
        <Portal selector="#portal">
          <div className="modal-backdrop fade-in"></div>
          <div
            className={`modal show add_faq_cat_modal ${background === "dark" ? "dark-mode" : ""}`}
            data-background={background}
          >
            <div
              className="relative min-w-sm w-auto mx-auto lg:max-w-5xl add_faq_cat_modal_box"
              ref={modalRef}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="text-xl font-semibold">{title}</h3>
                  <button
                    className="modal-close btn btn-transparent"
                    onClick={hide}
                  >
                    <FiX size={18} className="stroke-current" />
                  </button>
                </div>
                <div className="relative p-4 flex-auto faq_box_inner">
                  
                  <label>Faq Type</label>
                  <div className="inputBoxCategoryCss">
                    <input
                      type="text"
                      placeholder="Please Enter Title"
                      name="name"
                      value={nameValue}
                      onChange={(e) => handleNameValue(e.target.value)}
                      className="inputFields"
                    />
                  </div>
                  {error && (
                    <p
                      style={{
                        color: "red",
                        padding: "10px 4px",
                        paddingTop: "10px",
                      }}
                    >
                      {error}
                    </p>
                  )}
                </div>
                <div className="modal-footer space-x-2">
                  <button
                    className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                    type="button"
                    onClick={hide}
                  >
                    Close
                  </button>
                  {isSubmit ? (
                    <button
                      className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                      type="button"
                      onClick={() => handleFaqCategory()}
                    >
                      Loading...
                    </button>
                  ) : (
                    <button
                      className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                      type="button"
                      onClick={() => handleFaqCategory()}
                    >
                      Save Changes
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default AddFaqCategory;