import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Alert from "../alerts";
import { Link } from "react-router-dom";
import { FiEye, FiEyeOff } from "react-icons/fi";

const FormValidation = ({ items, onSubmit, alerts, onChange, isSubmit }) => {
  const { handleSubmit, errors, register } = useForm();
  const [emailValidError, setEmailValidError] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const onSubmitFn = (data) => {


    if (onSubmit) {
      // setIsSubmit(true);

      onSubmit(data);
    }
  };
  items = items.map((item) => {
    item["ref"] = register(item["error"]);
    return item;
  });

  //

  const handleEmailChange = (value) => {
    const regex =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (value === "") {
      setEmailValidError(false);
    } else if (regex.test(value) === false) {
      setEmailValidError(true);
    } else {
      setEmailValidError(false);
    }
  };

  const handleEye = () => {
    setIsShow(!isShow);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmitFn)}
      className="form flex flex-wrap w-full"
    >
      {alerts &&
        items.map((item, i) => {
          if (!errors[item.name]) return null;
          let msg = errors[item.name].message;
          if (msg.length === 0) msg = `${item.label} is required`;
          return (
            <div className="flex flex-col w-full">
              {errors[item.name] && (
                <div className="mb-2" key={i}>
                  <Alert
                    color="bg-transparent border-red-500 text-red-500"
                    borderLeft
                    raised
                  >
                    {msg}
                  </Alert>
                </div>
              )}
            </div>
          );
        })}
      <div className="w-full">
        {items.map((item, i) => {
          if (item.type === "email" && item.inputType === "login") {
            return (
              <>
                <div className="form-element w-full">
                  {item.label && <div>{item.label}</div>}
                  <input
                    ref={item.ref}
                    id={item.id}
                    value={item.value}
                    name={item.name}
                    type={item.type}
                    onChange={(e) => handleEmailChange(e.target.value)}
                    className={`form-input ${errors[item.name] ? "border-red-500" : ""
                      }`}
                    placeholder={item.placeholder}
                  />
                  {!alerts && errors[item.name] && (
                    <div className="form-error">
                      {errors[item.name].message}
                    </div>
                  )}
                  {emailValidError && (
                    <div className="form-error">Please enter valid email</div>
                  )}
                </div>
              </>
            );
          }

          return (
            <>
              <div className="form-element w-full">
                {item.label && <div>{item.label}</div>}
                <input
                  ref={item.ref}
                  name={item.name}
                  type={isShow ? "text" : "password"}
                  value={item.value}
                  id={item.id}
                  className={`form-input ${errors[item.name] ? "border-red-500" : ""
                    }`}
                  placeholder={item.placeholder}
                  style={{ paddingRight: "35px" }}
                />
                {!isShow ?
                  < FiEyeOff
                    onClick={() => handleEye()}
                    style={{ position: "absolute", right: "15px", top: "32px" }}
                    type="text"
                  /> :
                  <FiEye
                    onClick={() => handleEye()}
                    style={{ position: "absolute", right: "15px", top: "32px" }}
                    type="password"
                  />
                }
                {!alerts && errors[item.name] && (
                  <div className="form-error">{errors[item.name].message}</div>
                )}
              </div>
            </>
          );
        })}
      </div>
      {/* <button
        type="submit"
        className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
      >
        Login
      </button> */}
      <div className="w-full">
        {isSubmit ? (
          <button
            type="button"
            className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
          >
            Loading...
          </button>
        ) : (
          <button
            type="submit"
            className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
          >
            Submit
          </button>
        )
        }
        <Link className="link forgetpassword" to="/forgot-password">
          Forgot Password?
        </Link>
      </div>
    </form>
  );
};
export default FormValidation;
