import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useOutsideClick } from "../../hooks/OutsideClick";

const DropDown = (props) => {
  const { value, options } = props;
  const [isOpen, setIsOpen] = useState(false);
  const ref = useOutsideClick(() => setIsOpen(false));
  const [valueTemp, setValueTemp] = useState(value);

  useEffect(() => {
    setValueTemp(value);
  }, [value]);

  return (
    <div ref={ref} className="relative">
      <div
        className="bg-bg-eunry text-white rounded-sm border border-bg-eunry px-3 py-1 cursor-pointer"
      >
        <p>{valueTemp}</p>
      </div>
      {isOpen && (
        <div className="absolute top-10 left-0 w-32 bg-white text-black rounded-sm border border-bg-eunry px-3 py-1">
          {options.map((item, index) => (
            <p
              onClick={() => {
                setValueTemp(item);
                setIsOpen(false);
              }}
              key={index}
              className="cursor-pointer border-b my-1"
            >
              {item}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

const Pricing = (props) => {
  const productForm = useSelector((state) => state.productsSlice);

  return (
    <div className="flex flex-col mt-5 space-y-5">
      <div className="flex space-x-2">
        <div className="flex space-x-2 w-[70%]">
          <div className="w-[15%] h-16 flex flex-col justify-between">
            <p>Price *</p>
            <div className="flex items-center w-full border border-bg-primary rounded-sm py-1 px-2">
              <p>${productForm.product?.productPrice?.price}</p>
              <input disabled type="text" className="outline-none w-full" />
            </div>
          </div>
          <div className="w-[15%] h-16 flex flex-col justify-between">
            <p>GST</p>
            <input
              value={productForm.product?.productPrice?.gst}
              disabled
              type="text"
              className="outline-none w-full border border-bg-primary rounded-sm py-1 px-2"
            />
          </div>
          <div className="w-[15%] h-16 flex flex-col justify-between">
            <p>Price w/GST</p>
            <input
              value={productForm.product?.productPrice?.priceWithGST}
              type="text"
              disabled
              className="outline-none w-full border border-bg-primary rounded-sm py-1 px-2"
            />
          </div>
          <div className="w-[5%] h-16 flex flex-col justify-end">
            <div className="flex w-full cursor-pointer justify-center py-1 px-2">
              <div className=" mr-3 cursor-pointer hover:font-bold">
                <p>${productForm.product?.productPrice?.priceCurrency}</p>
              </div>
            </div>
          </div>
          <div className="w-[20%] h-16 flex flex-col justify-end">
            <div className="flex w-full cursor-pointer justify-center border border-bg-eunry py-1 rounded-sm bg-bg-eunry">
              <p
                className="px-2 text-white cursor-pointer truncate"
                style={{ maxWidth: "300px", overflow: "hidden" }}
              >
                {productForm.product?.productPrice?.priceMetric}
              </p>
            </div>
          </div>
          <div className="w-[20%] h-16 flex flex-col justify-end">
            <DropDown
              value="w/o GST"
              options={["GST Exempt", "w/o GST", "w/GST"]}
            />
          </div>
        </div>
        <div className="w-[30%] space-y-2">
          <div className="w-full h-16 flex flex-col justify-between">
            <p>Cost per Item</p>
            <div className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2 flex justify-between">
              <label className="flex items-center">
                {productForm.product?.productCostPerItem.costPerItem}
                <input disabled type="text" className="outline-none w-full" />
              </label>
              <div className="flex">
                <div className=" mr-3 cursor-pointer hover:font-bold">
                  <p>
                    {
                      productForm.product?.productCostPerItem
                        .costPerItemCurrency
                    }
                  </p>
                </div>
                <div className="relative hover:bg-bg-primary rounded-sm bg-bg-eunry">
                  <p
                    className="px-2 text-white cursor-pointer break-words truncate"
                    style={{ maxWidth: "300px", overflow: "hidden" }}
                  >
                    {productForm.product?.productCostPerItem
                      .costPerItemMetric || "Per Package"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex space-x-2">
        <div className="w-full space-y-2">
          <p>Tax Status</p>
          <div
            className="cursor-pointer w-full outline-none border border-bg-primary rounded-sm px-1 flex items-center"
            style={{ height: "34px" }}
          >
            <p>{productForm.product?.taxStatus}</p>
          </div>
        </div>
        <div className="w-full space-y-2">
          <p>Tax Class</p>
          <div
            className="cursor-pointer w-full outline-none border border-bg-primary rounded-sm px-1 flex items-center"
            style={{ height: "34px" }}
          >
            <p>{productForm.product?.taxClass}</p>
          </div>
        </div>
      </div>
      <div className=" space-x-2">
        <div className="w-44 space-y-2">
          <p>Enable Min Max rule</p>
        </div>
        <div className="flex w-30 mt-2">
          <div className="flex space-x-3 mr-10">
            <p className="mb-2">Yes</p>
            <input
              checked={productForm.product?.enableMinMaxRule}
              className="mr-2 mt-1 h-5 w-5 bg-white cursor-pointer"
              type="checkbox"
            />
          </div>

          <div className="flex space-x-3 w-full">
            <p className="mb-2">No</p>
            <input
              checked={!productForm.product?.enableMinMaxRule}
              className="mr-2 mt-1 h-5 w-5 bg-white cursor-pointer"
              type="checkbox"
            />
          </div>
        </div>
      </div>
      <div className="flex space-x-2 items-center">
        <div className="w-full space-y-2">
          <div>
            <p className="mb-2">Minimum Qty to Order</p>
            <div className="flex w-full justify-between items-center border border-bg-primary rounded-sm py-1 px-2">
              <input
                value={productForm.product?.minQtyOrder?.minQty}
                type="text"
                disabled
                className="outline-none w-full"
              />
              <div>
                <p
                  className="px-2 text-white flex justify-center bg-bg-eunry cursor-pointer truncate"
                  style={{ width: "100px", overflow: "hidden" }}
                >
                  {productForm.product?.minQtyOrder?.minQtyMetric}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full space-y-2">
          <div>
            <p className="mb-2">Minimum Pricing</p>
            <div className="w-full relative flex outline-none border border-bg-primary rounded-sm py-1 px-2">
              <p>${productForm.product?.minPricing?.minPrice}</p>
              <input type="text" disabled className="outline-none w-full" />
              <p>{productForm.product?.minPricing?.minPriceCurrency}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex space-x-2 items-center">
        <div className="w-full space-y-2">
          <div>
            <p className="mb-2">Maximum Qty to Order</p>
            <div className="w-full flex items-center border border-bg-primary rounded-sm py-1 px-2">
              <input
                value={productForm.product?.maxQtyOrder?.maxQty}
                type="text"
                disabled
                className="outline-none w-full m-auto"
              />
              <div>
                <p
                  className="px-2 text-white flex justify-center bg-bg-eunry cursor-pointer truncate"
                  style={{ width: "100px", overflow: "hidden" }}
                >
                  {productForm.product?.maxQtyOrder?.maxQtyMetric}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full space-y-2">
          <div>
            <p className="mb-2">Maximum Pricing</p>
            <div className="w-full relative flex outline-none border border-bg-primary rounded-sm py-1 px-2">
              <p>${productForm.product?.maxPricing?.maxPrice}</p>
              <input type="text" disabled className="outline-none w-full" />
              <p>{productForm.product?.maxPricing?.maxPriceCurrency}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex space-x-2">
        <div className="w-full space-y-2">
          <p>Product Tags</p>
          <div
            className="cursor-pointer w-full outline-none border border-bg-primary rounded-sm px-1 flex items-center"
            style={{ height: "34px" }}
          >
            {productForm.product?.productTags.map((item, index) => (
              <div className="bg-grey-200 mr-2 py-1 px-2">{item}</div>
            ))}
          </div>
        </div>
        <div className="w-full space-y-2">
          <p>Focus Keyword</p>
          <div
            className="cursor-pointer w-full outline-none border border-bg-primary rounded-sm px-1 flex items-center"
            style={{ height: "34px" }}
          >
            <p>{productForm.product?.focusKeyword}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
