/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { API_AdminPaymentMilestone, url } from "../apis/constant";
import axios from "axios";
import { toast } from "react-toastify";
import SectionTitle from "../components/section-title";
import PaymentPolicyPageData from "../components/paymentSummary";
import moment from 'moment';
const adminToken = localStorage.getItem("wed_admin_token");

const Simple = ({ columns, data, getList, Loading, getPageData, paginationObject }) => {
  const datatable = React.useMemo(() => data, [data]);

  return (
    <PaymentPolicyPageData
      columns={columns}
      data={datatable}
      loading={Loading}
      onSubmit={getPageData}
      showSearch={true}
      pagination={paginationObject}
      getAllVendorProduct={getList}
      setIds={() => { }}
    />
  );
};

const Index = () => {
  const [data, setData] = useState([])
  const [orderId, setOrderId] = useState([])
  const [paginationObject, setPaginationObject] = useState({});
  useEffect(() => {
    let orderIds = localStorage.getItem("orderIds");
    if (orderIds) {
      orderIds = JSON.parse(orderIds)
      // setOrderId(orderIds)
    } else {
      orderIds = []
    }
    setOrderId(orderIds)
  }, [])
  useEffect(() => {
    getList(10, 1, "")

  }, [orderId])
  const getList = (limit, page, search) => {
    setIsLoading(true)
    axios
      .post(url + API_AdminPaymentMilestone + `paymentMilestonesByMultipleOrderId?page=${page}&sortBy=asc&limit=${limit}&searchBy=${search}`, { ids: [...orderId] }, {

        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((res) => {
        if (res.status === 200) {
          const apiData = res.data.docs;
          setPaginationObject({
            limit: res.data.limit,
            page: res.data.page,
            pages: res.data.pages,
            total: res.data.total,
          });
          const finalData = apiData.map(v => {
            return {
              id: v.id,
              vendorID: v.vendorId.id,
              vendorname: v.orderId?.vendorDetails?.vendorName ?? v.vendorId.vendorName,
              customerid: v.userId.userUniqueId,
              customeradd: v.userId.add,
              customerphone: v.userId.phone,
              customeremail: v.userId.email,
              productunitPrice: v.orderId.products[0].product.productPrice.price,
              productitemdiscount: v.orderId.products[0].product.productTitle,
              discount: v.orderId.totalDiscount,
              totalgst: v.orderId.subTotalTax,
              productname: v.orderId.products.map(item => item.product.productTitle).join(", "),
              quantity: v.orderId.products[0].quantity,
              expectedDeliveryData: moment(v.orderId.products[0].expectedDelivery).format("DD-MMM-YYYY"),
              totalorderamount: v.orderId.subTotal,
              totalamount: isNaN(parseInt(v.orderId.subTotal - v.orderId.totalDiscount)?.toFixed(2)) ? "" : parseInt(v.orderId.subTotal - v.orderId.totalDiscount)?.toFixed(2),
              paymentMilestoneNumber: v.paymentMilestoneNumber,
              customername: v.userId.brideFirstName + " " + v.userId.groomFirstName,
              reciept: v.reciept,
              amttopay: isNaN(parseInt(v.paymentmilestone[0].totalAmount)?.toFixed(2)) ? "" : parseInt(v.paymentmilestone[0].totalAmount)?.toFixed(2),
              voucher: v?.track_discount_voucher?.length !== 0 ? v?.track_discount_vouchers[0]?.code : "",
              pcurmil: (v.paymentmilestone[0].amount).toFixed(2),
              totalamountpaid: isNaN(parseInt(v.paymentmilestone[0].alreadyPaid)?.toFixed(2)) ? "" : parseInt(v.paymentmilestone[0].alreadyPaid)?.toFixed(2),
              balancedue: isNaN(parseInt(v.paymentmilestone[0].alreadyPaid)?.toFixed(2)) ? "" : parseInt(v.paymentmilestone[0].alreadyPaid)?.toFixed(2),
              duedate: moment(v.paymentmilestone[0].milestoneDate)?.format("DD-MMM-YYYY"),
              amt: isNaN(parseInt(v.paymentmilestone[0].amount)?.toFixed(2)) ? "" : parseInt(v.paymentmilestone[0].amount)?.toFixed(2),
              amtgst: isNaN(parseInt(v.orderId.subTotalTax)?.toFixed(2)) ? "" : parseInt(v.orderId.subTotalTax)?.toFixed(2),
              voucheroffsett: isNaN(parseInt(v.paymentmilestone[0].voucherOffset)?.toFixed(2)) ? "" : parseInt(v.paymentmilestone[0].voucherOffset)?.toFixed(2),
              netdue: v.paymentmilestone[0].netPaymentDue,
              vouval: v.paymentmilestone[0].remainingVoucherValue,
              orderno: v.orderId.orderId,
              receiptDate: moment(v.paymentmilestone[0].paymentDate).format("DD-MMM-YYYY"),
              action: "",
              vendoraddress: v.orderId?.vendorDetails?.streetAddress1 ?? v.vendorId.streetAddress1,
              vatNo: v?.orderId?.vatTaxNumber ?? v.vendorId.vatTaxNumber
            };
          });
          setData(finalData);
          setIsLoading(false)
        }
      })
      .catch((err) => {
        toast.error("Something went wrong!");
      });
  }
  const getPageData = (data) => {
    getList(data, 1, "");
  };
  const [columns] = useState([
    // {
    //   Header: "ID",
    //   accessor: "id",
    //   // Cell: (props) => <p>1</p>,
    //   isCheck: true,
    // },
    {
      Header: "Order No",
      accessor: "orderno",
      // Cell: (props) => <p>A23456</p>,
      isCheck: true,
    },
    {
      Header: "Receipt No",
      accessor: "reciept",
      // Cell: (props) => <p>INV/231/33322/11</p>,

      isCheck: true,
    },
    {
      Header: "Receipt Date",
      accessor: "receiptDate",
      // Cell: (props) => <p>22-Aug-2022</p>,
      isCheck: true,
    },
    {
      Header: "Customer ID",
      accessor: "customerid",
      // Cell: (props) => <p>123456</p>,
      isCheck: true,
    },
    {
      Header: "Customer Name",
      accessor: "customername",
      // Cell: (props) => <p>Tumas</p>,
      isCheck: true,
    },
    {
      Header: "Customer Address",
      accessor: "customeradd",
      // Cell: (props) => <p>Singapore</p>,
      isCheck: true,
    },
    {
      Header: "Phone",
      accessor: "customerphone",
      // Cell: (props) => <p>+6591111111</p>,
      isCheck: true,
    },
    {
      Header: "Email",
      accessor: "customeremail",
      // Cell: (props) => <p>xxx@gmail.com</p>,
      isCheck: true,
    },
    {
      Header: "Vendor Name",
      accessor: "vendorname",
      // Cell: (props) => <p>Tumas Men's Wear</p>,
      isCheck: true,
    },
    {
      Header: "Vendor Address",
      accessor: "vendoraddress",
      // Cell: (props) => <p>Singapore</p>,
      isCheck: true,
    },
    {
      Header: "GST Reg No",
      accessor: "vatNo",
      // Cell: (props) => <p>M2-1234567-K</p>,
      isCheck: true,
    },
    {
      Header: "Expected Delivery Date",
      accessor: "expectedDeliveryData",
      // Cell: (props) => <p>25-Mar-2023</p>,

      isCheck: true,
    },

    {
      Header: "Total Order Amount",
      accessor: "totalorderamount",
      // Cell: (props) => <p>$60,000.00</p>,

      isCheck: true,
    },

    {
      Header: "Payment Milestone",
      accessor: "paymentMilestoneNumber",
      // Cell: (props) => <p>4</p>,

      isCheck: true,
    },
    {
      Header: "Product Name",
      accessor: "productname",
      // Cell: (props) => <p>Tuxedo</p>,
      isCheck: true,
    },
    {
      Header: "Quantity",
      accessor: "quantity",
      // Cell: (props) => <p>2</p>,

      isCheck: true,
    },
    {
      Header: "Unit Price",
      accessor: "productunitPrice",
      // Cell: (props) => <p>$20,000.00</p>,
      isCheck: true,
    },
    {
      Header: "Item Discount",
      accessor: "productitemdiscount",
      // Cell: (props) => <p>$5,000.00</p>,
      isCheck: true,
    },
    {
      Header: "Total Amount",
      accessor: "totalamount",
      // Cell: (props) => <p>$30,000.00</p>,
      isCheck: true,
    },
    {
      Header: "Sub Total",
      accessor: "subtotal",
      // Cell: (props) => <p>$60,000.00</p>,
      isCheck: true,
    },
    {
      Header: "Discount",
      accessor: "discount",
      // Cell: (props) => <p>$6,000.00</p>,
      isCheck: true,
    },
    {
      Header: "Total GST",
      accessor: "totalgst",
      // Cell: (props) => <p>$3,780.00</p>,

      isCheck: true,
    },
    {
      Header: "Total Amount to Pay",
      accessor: "amttopay",
      // Cell: (props) => <p>$57,780.00</p>,
      isCheck: true,
    },
    {
      Header: "Voucher",
      accessor: "voucher",
      // Cell: (props) => <p>$20,000.00</p>,
      isCheck: true,
    },
    {
      Header: "Payment for Current Milestone",
      accessor: "pcurmil",
      // Cell: (props) => <p>$0</p>,
      isCheck: true,
    },
    {
      Header: "Total Amount Paid",
      accessor: "totalamountpaid",
      // Cell: (props) => <p>$0</p>,
      isCheck: true,
    },
    {
      Header: "balance Due",
      accessor: "balancedue",
      // Cell: (props) => <p>$52,202.00</p>,
      isCheck: true,
    },
    {
      Header: "Payment Due Date",
      accessor: "duedate",
      // Cell: (props) => <p>30-Apr-2023</p>,

      isCheck: true,
    },
    {
      Header: "Amount",
      accessor: "amt",
      // Cell: (props) => <p>$20,223.00</p>,
      isCheck: true,
    },
    {
      Header: "GST",
      accessor: "amtgst",
      // Cell: (props) => <p>$189.00</p>,
      isCheck: true,
    },
    {
      Header: "TAX Adjust Amount",
      accessor: "amtax",
      // Cell: (props) => <p>$20,412.00</p>,
      isCheck: true,
    },
    {
      Header: "Voucher Offset",
      accessor: "voucheroffsett",
      // Cell: (props) => <p>$0</p>,

      isCheck: true,
    },
    {
      Header: "Net Payment Due",
      accessor: "netdue",
      // Cell: (props) => <p>$20,223.00</p>,
      isCheck: true,
    },
    {
      Header: "Remaining Voucher Value",
      accessor: "vouval",
      // Cell: (props) => <p>$0</p>,
      isCheck: true,
    },

    // {
    //   Header: "Comment",
    //   accessor: "comments",
    //   // Cell: (props) => <p>testing hello world</p>,

    //   isCheck: true,
    // },

    // {
    //   Header: "Action",
    //   Cell: (props) => {
    //     return (
    //       <div className="flex justify-between">
    //         <FiSave size={20} className="cursor-pointer mt-1 ml-4" />
    //         <FiCopy size={20} className="cursor-pointer mt-1 ml-4" />
    //         <FiEdit size={20} className="cursor-pointer mt-1 ml-4" />
    //       </div>
    //     );
    //   },
    //   isCheck: true,
    // },
  ]);
  
  const [Loading, setIsLoading] = useState(false);
  return (
    <>
      <SectionTitle title="Payment" subtitle="Payment Summary" />
      <div className="p-2 bg-white border rounded-md">
        <div className="flex justify-between">
          {/* <div className="flex p-3">
            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="All"
            />
            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="Approve"
            />
            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="Pending"
            />
            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="Rejected"
            />
            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="Archive"
            />
            <div className="flex border-2 rounded-md hover:border-blue-500 w-full mr-3">
              <svg
                aria-hidden="true"
                className="flex justify-self-center self-center focus:w-5 h-5 text-gray-500 dark:text-gray-400 ml-2 text-grey-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 35 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
              <input
                type="Search"
                placeholder="Search"
                className="w-96"
              ></input>
            </div>
          </div> */}

          <div className="flex p-3 space-x-2">
            {/* <ModalImport
              title="Export / Import Data"
              body={
                <div className="grid grid-cols-1 mx-12 my-5">
                  <span className="text-lg my-4">
                    Easily organize your own data with import or export data
                    feature
                  </span>
                  <button className="bg-white hover:bg-grey-200 py-2 px-4 my-2 rounded shadow-sm border">
                    Download CSV Template
                  </button>
                  <button className="bg-white hover:bg-grey-200 py-2 px-4 my-2 rounded shadow-sm border">
                    Export
                  </button>
                  <button className="bg-white hover:bg-grey-200 py-2 px-4 my-2 rounded shadow-sm border">
                    Import Individual Customer
                  </button>
                  <button className="bg-white hover:bg-grey-200 py-2 px-4 my-2 rounded shadow-sm border">
                    Import Aggregate Customer
                  </button>
                  <hr className="my-2" />
                  <p className="underline text-center cursor-pointer">
                    Ask Customer Service
                  </p>
                </div>
              }
            /> */}
            {/* <button className="bg-white hover:bg-grey-200 py-2 px-4 rounded shadow-sm border">
              Export
            </button>
            <button className="bg-white hover:bg-grey-200 py-2 px-4 rounded shadow-sm border">
              Import
            </button> */}
            {/* <div ref={ref}>
              <button
                onClick={() => setShow(!show)}
                className="bg-white hover:bg-grey-200 py-2 px-4 rounded shadow-sm border"
              >
                Bulk Action
              </button>

              {show && (
                <div className="relative">
                  <div className=" absolute shadow-lg right-0 bottom-20 bg-white w-48 rounded-md z-10">
                    <Menu
                      title="Approve Reviews"
                      onClick={() => console.log("Active")}
                    />
                    <hr></hr>
                    <Menu
                      title="Reject Reviews"
                      onClick={() => console.log("Diactive")}
                    />
                    <hr></hr>
                    <Menu title="Archive" onClick={() => console.log("Copy")} />
                  </div>
                </div>
              )}
            </div> */}
          </div>
        </div>
        <Simple paginationObject={paginationObject} columns={columns} data={data} getList={getList} Loading={Loading} getPageData={getPageData} />
      </div>
    </>
  );
};
export default Index;
