import { createSlice } from "@reduxjs/toolkit";
import {
  loginAction,
  logoutAction as logout,
  tokenAction as token,
} from "../actions";

const initialState = {
  token: null,
  userData: null,
  isFetching: false,
};

export const adminAuthSlice = createSlice({
  name: "adminAuth",
  initialState,
  reducers: {
    logoutAction: logout,
    tokenAction: token,
  },
  extraReducers: {
    //Login user
    [loginAction.pending]: (state, action) => {
      state.isFetching = true;
      state.error = null;
    },
    [loginAction.fulfilled]: (state, action) => {
      state.token = action.payload.tokens;
      state.userData = action.payload.dataUser;
      state.isFetching = false;
    },
    [loginAction.rejected]: (state, action) => {
      state.isFetching = false;
    },
  },
});

export const { logoutAction, tokenAction } = adminAuthSlice.actions;

export default adminAuthSlice.reducer;
