import React, { useEffect, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import AddNewProduct from "../components/productContent/addNewProduct";
import Pricing from "../components/productContent/pricing";
import Publish from "../components/productContent/publish";
import Attributes from "../components/productContent/attributes";
import { useDispatch } from "react-redux";
import { vendorProductGetByIdAction } from "../actions";
import AttributesNew from "../components/productContent/attributesNew";

const ProductAdd = () => {
  const [openForm, setOpenForm] = useState(true);
  const [openFormPrice, setOpenFormPrice] = useState(false);
  const [openFormPublish, setOpenFormPublish] = useState(false);
  const [openFormAttributes, setOpenFormAttributes] = useState(false);
  const [openFormAttributesNew, setOpenFormAttributesNew] = useState(false);
  const query = new URLSearchParams(window.location.search);

  const dispatch = useDispatch();
  useEffect(() => {
    const idvendor = window.location.pathname.split("/");
    const id = idvendor[2];
    dispatch(
      vendorProductGetByIdAction({ onSuccess: () => { }, onFailed: () => { }, id })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="my-4">
      <div className=" w-full flex flex-col items-center justify-center text-bg-primary">
        <div className="w-full ">
          <div className="w-full shadow-xl px-5 py-5">
            <div
              onClick={() => setOpenForm(!openForm)}
              className="flex justify-between cursor-pointer"
            >
              <p className="text-xl">Product {query.get('vendorName') ? '(' + query.get('vendorName') + ')' : ''}</p>
              <BsChevronDown
                size={25}
                className={
                  "text-bg-primary " +
                  (openForm
                    ? "rotate-180 transition-all"
                    : "rotate-0 transition-all")
                }
              />
            </div>
            <div className={openForm ? "inline" : "hidden"}>
              <AddNewProduct />
            </div>
          </div>
        </div>
        <div className="w-full mt-10">
          <div className="w-full shadow-xl px-5 py-5">
            <div
              onClick={() => setOpenFormPrice(!openFormPrice)}
              className="flex justify-between cursor-pointer"
            >
              <p className="text-xl">Price</p>
              <BsChevronDown
                size={25}
                className={
                  "text-bg-primary " +
                  (openFormPrice
                    ? "rotate-180 transition-all"
                    : "rotate-0 transition-all")
                }
              />
            </div>
            <div className={openFormPrice ? "inline" : "hidden"}>
              <Pricing />
            </div>
          </div>
        </div>
        <div className="w-full mt-10">
          <div className="w-full shadow-xl px-5 py-5">
            <div
              onClick={() => setOpenFormAttributes(!openFormAttributes)}
              className="flex justify-between cursor-pointer"
            >
              <p className="text-xl">Search Filters</p>
              <BsChevronDown
                size={25}
                className={
                  "text-bg-primary " +
                  (openFormAttributes
                    ? "rotate-180 transition-all"
                    : "rotate-0 transition-all")
                }
              />
            </div>
            <div className={openFormAttributes ? "inline" : "hidden"}>
              <Attributes />
            </div>
          </div>
        </div>
        <div className="w-full mt-10">
          <div className="w-full shadow-xl px-5 py-5">
            <div
              onClick={() => setOpenFormAttributesNew(!openFormAttributesNew)}
              className="flex justify-between cursor-pointer"
            >
              <p className="text-xl">Attributes</p>
              <BsChevronDown
                size={25}
                className={
                  "text-bg-primary " +
                  (openFormAttributesNew
                    ? "rotate-180 transition-all"
                    : "rotate-0 transition-all")
                }
              />
            </div>
            <div className={openFormAttributesNew ? "inline" : "hidden"}>
              <AttributesNew />
            </div>
          </div>
        </div>
        <div className="w-full mt-10">
          <div className="w-full shadow-xl px-5 py-5">
            <div
              onClick={() => setOpenFormPublish(!openFormPublish)}
              className="flex justify-between cursor-pointer"
            >
              <p className="text-xl">Publish</p>
              <BsChevronDown
                size={25}
                className={
                  "text-bg-primary " +
                  (openFormPublish
                    ? "rotate-180 transition-all"
                    : "rotate-0 transition-all")
                }
              />
            </div>
            <div className={openFormPublish ? "inline" : "hidden"}>
              <Publish />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductAdd;
