/* eslint-disable no-unreachable */
import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../utils/createApi";

// storing data only
export const logoutAction = (state) => {
  state.token = null;
  state.userData = null;
  localStorage.clear();
  window.location.pathname = '/';
};

export const tokenAction = (state, action) => {
  state.token = action.payload;
};

export const updatedSideBar = (state, action) => {
  state.navigation = action.payload;
};
// Fetching API
// Auth
export const loginAction = createAsyncThunk(
  "auth/loginAction",
  async ({ email, password, onSuccess, onFailed }) => {
    try {
      const result = await API.post("/admin/auth/login", {
        email,
        password,
      });

      if (result.data.code === 401) {
        onFailed();
        return {
          tokens: null,
          dataUser: null,
        };
      }

      onSuccess();
      return {
        tokens: result.data.data.tokens,
        dataUser: result.data.data.admin,
      };
    } catch (err) {
      onFailed();
      throw new Error(err);
    }
  }
);