import React from 'react';
import axios from "axios";
import { toast } from 'react-toastify';
import { url, API_Dashboard } from "../../apis/constant";
import { formatCurrency } from "../../functions/numbers";
import { Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  registerables,
} from "chart.js";

ChartJS.register(
  ...registerables,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController
);

const SalesChart = () => {
  const [targetSalesInput, setTargetSalesInput] = React.useState([]);
  const [dataBar, setDataBar] = React.useState({
    labels: [],
    datasets: [
      {
        type: "line",
        label: "Cumulative Sales",
        lineTension: 0.3,
        backgroundColor: "#824242",
        borderColor: "#824242",
        borderWidth: 2,
        fill: false,
        data: [],
      },
      {
        type: "bar",
        label: "Actual Sales",
        backgroundColor: "#00ABCF",
        borderColor: "white",
        borderWidth: 2,
        data: [],
      },
      {
        type: "bar",
        label: "Target Sales",
        backgroundColor: "#80BF3D",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: "white",
        borderWidth: 2,
      },
    ],
  });

  React.useEffect(() => {
    setTargetSalesInput(dataBar.datasets.find(dataset => dataset.label === "Target Sales").data.map((value, index) => ({
      period: dataBar.labels[index],
      value: value,
    })));
    // [
    //     {
    //         "period": "2022-12",
    //         "actualSales": 0,
    //         "cumulativeSales": 0
    //     }
    // ]
    const adminToken = localStorage.getItem("wed_admin_token");
    try {
      // Fetch sales target data
      axios.get(url + API_Dashboard.salesTarget, {
        headers: {
          Authorization: `Bearer ${adminToken}`
        }
      }).then(res => {
        const targetSalesData = res.data.data;
        const formattedTargetSales = targetSalesData.targets.map(item => ({
          period: item.period,
          value: item.target,
        }));
        setTargetSalesInput(formattedTargetSales);

        // Update the Target Sales dataset with values from formattedTargetSales
        setDataBar(prevDataBar => ({
          ...prevDataBar,
          datasets: prevDataBar.datasets.map(dataset => {
            if (dataset.label === "Target Sales") {
              return { ...dataset, data: formattedTargetSales.map(item => item.value) };
            }
            return dataset;
          }),
        }));
      }).catch(err => {
        console.log('Error fetching target sales:', err);
      });

      // Fetch chart data
      axios.get(url + API_Dashboard.chart, {
        headers: {
          Authorization: `Bearer ${adminToken}`
        }
      }).then(res => {
        const newData = res.data.data;
        setDataBar(prevDataBar => ({
          ...prevDataBar,
          labels: newData.map(item => item.period),
          datasets: prevDataBar.datasets.map(dataset => {
            if (dataset.label === "Cumulative Sales") {
              return { ...dataset, data: newData.map(item => item.cumulativeSales) };
            } else if (dataset.label === "Actual Sales") {
              return { ...dataset, data: newData.map(item => item.actualSales) };
            } else {
              return dataset;
            }
          }),
        }));
      }).catch(err => {
        console.log(err);
      });
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  // Function to update the dataset for Target Sales
  const updateTargetSalesDataset = (datasets, index, updatedValue) => {
    return datasets.map(dataset => {
      if (dataset.label === "Target Sales") {
        const newData = [...dataset.data];
        newData[index] = +updatedValue;
        return { ...dataset, data: newData };
      }
      return dataset;
    });
  };

  const handleTargetSalesInputChange = (event, index) => {
    const updatedValue = event.target.value;
    // Update the input state for Target Sales
    setTargetSalesInput(currentInputs =>
      currentInputs.map((input, idx) => {
        if (idx === index) {
          // Update the chart data for Target Sales
          setDataBar(prevDataBar => ({
            ...prevDataBar,
            datasets: updateTargetSalesDataset(prevDataBar.datasets, index, updatedValue),
          }));
          return { ...input, value: updatedValue };
        }
        return input;
      })
    );
  };

  // Function to save Target Sales
  const saveTargetSales = async () => {
    const adminToken = localStorage.getItem("wed_admin_token");
    try {
      const payload = {
        targets: targetSalesInput.map(item => ({
          period: item.period,
          target: Number(item.value)
        }))
      };

      // Send PUT request to update target sales
      const response = await axios.put(url + API_Dashboard.salesTarget, payload, {
        headers: {
          Authorization: `Bearer ${adminToken}`
        }
      });
      if (response.status === 200) {
        // Handle successful update with a toast
        toast.success('Target sales updated successfully');
      } else {
        // Handle other statuses with a toast
        toast.error(`Failed to update target sales: ${response.status}`);
      }
    } catch (error) {
      // Handle errors
      console.error('Error updating target sales:', error);
    }
  };
  return (
    <>
      <Chart type="bar" data={dataBar} />
      <div className="m-4 mt-3 text-xl overflow-x-auto shadow-md sm:rounded-lg">
        <table id='target-sales' className="min-w-full divide-y divide-gray-200 text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="bg-gray-50">
            <tr className="border-b">
              <th className="px-6 py-4 text-left align-middle font-semibold">Period</th>
              {dataBar.labels.map((label, index) => (
                <th key={index} className="text-left align-middle">{label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className="border-b">
              <td className='px-6 py-4 text-left align-middle'>Cumulative Sales</td>
              {dataBar.datasets.find(dataset => dataset.label === "Cumulative Sales").data.map((value, index) => (
                <td key={index} className="px-6 py-4 text-left align-middle">{formatCurrency(value)}</td>
              ))}
            </tr>
            <tr className="border-b">
              <td className='px-6 py-4 text-left align-middle'>Actual Sales</td>
              {dataBar.datasets.find(dataset => dataset.label === "Actual Sales").data.map((value, index) => (
                <td key={index} className="px-6 py-4 text-left align-middle">{formatCurrency(value)}</td>
              ))}
            </tr>
            <tr className="border-b">
              <td className='px-4 py-2 text-left align-middle'>Target Sales</td>
              {targetSalesInput.map((item, index) => (
                <td key={index} className="px-4 py-2 text-left align-middle">
                  <div className="flex items-center">
                    <span className="">$</span>
                    <input
                      type="text"
                      value={item.value || 0}
                      className="w-20 px-1 py-1 border-2 border-gray-300 rounded-md text-gray-700 focus:ring-blue-500 focus:border-blue-500"
                      onChange={(e) => handleTargetSalesInputChange(e, index)}
                    />
                  </div>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
      <div className="mx-3">
        <button
          style={{ background: "#824242" }}
          className={` mt-2 py-2 px-4 mr-2 rounded shadow-sm ${"text-white "} w-40`}
          onClick={saveTargetSales}
        >
          Save
        </button>
      </div>
    </>
  );
}

export default SalesChart;
