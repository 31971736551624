// Base_URL
export const url = process.env.REACT_APP_API_URL;
export const socketUrl = process.env.REACT_APP_SOCKET_URL;
// All Api name
export const API_LOGIN = "admin/auth/login"; // admin/auth/login
export const API_LOGOUT = "admin/auth/logout";
export const API_Forgot = "admin/auth/forgot-password";
export const API_ChangePassword = "admin/change-password";
export const API_ResetPassword = "admin/auth/reset-password?token=";
export const API_GetAllVendors = "vendor/vendorList";
export const API_GetAllUsers = "user/userList";
export const API_DeleteUserById = "user/";
export const API_GetUserById = "user/";
export const API_GetVendorById = "vendor/";
export const API_DeleteVendorById = "vendor/";
export const API_UpdateUserById = "user/";
export const API_UpdateVendorById = "vendor/";
export const API_GetAllCategory = "category/";
export const API_ActDeactVendor = "vendor/admin/activateDeactivateVendors/";
export const API_ListOfRoles = "admin/roles";
export const API_CreateRole = "admin/roles";
export const API_DeleteRole = "admin/roles";
export const API_UpdateStatus = "admin/roles";
export const Api_EditRole = "admin/roles";
export const API_GetAllSubUsers = "user/admin/subUserList/";
export const API_DeleteSubUserById = "user/admin/subUser/";
export const API_UpdateSubUserById = "user/admin/subUser/";
export const API_GetSubUserById = "user/admin/subUser/";
export const API_ChangeVendorRisk = "vendor/admin/risktype/";
export const API_ChangeProductRisk = "vendor/admin/customRisktype/";
export const API_VendorPaymentPolicy = "admin-paymentpolicy";
export const API_AllOrdersAdmin = "order-admin";
export const API_AllAnomalyOrdersAdmin = "order-admin/anomaly";
export const API_AdminPaymentPolicy = "admin-paymentpolicy/";
export const API_AdminPaymentMilestone = "payment-milestone-admin/";
export const API_OrderViewId = "order-admin/";
export const API_CancelOrder = "admin/cancelorder/list";
export const API_cancel_order = "admin/cancelorder";
export const API_AdminDashboard = "admin/dashboard";
export const API_Dashboard = {
  cashflow: "/admin-dashboard/cashflow",
  sales: "/admin-dashboard/sales-report",
  chart: "/admin-dashboard/sales-chart",
  vendor: "/admin-dashboard/vendor-report",
  product: "/admin-dashboard/product-report",
  orderPayment: "/admin-dashboard/order-payment",
  openOrder: "/admin-dashboard/open-order",
  orderProfitability: "/admin-dashboard/order-profitability",
  salesTarget: "/admin/sales-target",
  salesInsight: "/admin-dashboard/sales-insight",
}
//SubAdmin
export const API_ListofAllSubAdmin = "admin/subadmin";
export const API_CreateSubAdmin = "admin/subadmin";
export const API_DeleteSubAdmin = "admin/subadmin";
export const API_UpdateStatusAdmin = "admin/subadmin";
export const API_EditUserSubAdmin = "admin/subadmin";
export const API_GetAllAddessAdmin = "order-admin/address";


//subAdmin Profile
export const API_UpdateProfile = "admin/subadmin/profile";

// Change Password
export const API_ChangeProfile = "admin/change-password";

export const API_ActDeactSubuser = "user/admin/activateDeactivateUsers/";
export const API_DeleteMultiSubUser = "user/admin/deleteMultipleUsers/";
export const API_DeleteMultiVendor = "vendor/admin/deleteMultipleVendors/";
export const API_GetAllSubCategory = "sub-category/";
export const API_UpdateSubCategoryById = "sub-category/";
export const API_DeleteSubCategoryById = "sub-category/";
export const API_CreateSubCategory = "sub-category/";
export const API_GetSubCategoryById = "sub-category/";

//sub sub categories
export const API_GetAllSubSubCategory = "sub-sub-category/";
export const API_UpdateSubSubCategoryById = "sub-sub-category/";
export const API_DeleteSubSubCategoryById = "sub-sub-category/";
export const API_CreateSubSubCategory = "sub-sub-category/";
export const API_GetSubSubCategoryById = "sub-sub-category/";

//master Category

export const API_MasterCategoryList = "masterCategory/";
export const API_CreateMasterCategory = "masterCategory/";
export const API_GetMasterCategoryById = "masterCategory/";
export const API_UpdateMasterCategoryById = "masterCategory/";
export const API_DeleteMasterCategoryById = "masterCategory/";

// Category

export const API_CategoryList = "category/";
export const API_CreateCategory = "category/";
export const API_GetCategoryById = "category/";
export const API_UpdateCategoryById = "category/";
export const API_DeleteCategoryById = "category/";

//attribute
export const API_GetAllAttribute = "attribute/";
export const API_UpdateAttributeById = "attribute/";
export const API_DeleteAttributeById = "attribute/";
export const API_CreateAttribute = "attribute/";
export const API_GetAttributeById = "attribute/";

// Filters

export const API_FilterList = "filter/";
export const API_CreateFilter = "filter/";
export const API_GetFilterById = "filter/";
export const API_UpdateFilterById = "filter/";
export const API_DeleteFilterById = "filter/";
export const API_UpdateFilterStatus = "filter/admin/activateDeactivateFilters";

// faq
export const API_GetAllFaqDetails = "faq/";
export const API_UpdateFaqDetailsById = "faq/";
export const API_DeleteFaqDetailsById = "faq/";
export const API_CreateFaqDetails = "faq/";
export const API_GetFaqDetailsById = "faq/";

//all faq type
export const API_GetAllFaqTypes = "faqType/";
export const API_CreateFaqTypes = "faqType/";

// productType
export const API_ProductList = "productType/";
export const API_CreateProductType = "productType/";
export const API_GetProductTypeById = "productType/";
export const API_UpdateProductTypeById = "productType/";
export const API_DeleteProductTypeById = "productType/";
export const API_UpdateProductTypeStatus =
  "productType/admin/activateDeactivateProductType";

// productFamily 
export const API_ProductFamilyList = "product-family/";

//manageProduct
export const API_ManageProductList = "vendorProduct/";
export const API_CreateManageProduct = "vendorProduct/";
export const API_GetManageProductById = "vendorProduct/";
export const API_UpdateManageProductById = "vendorProduct/";
export const API_DeleteProductById = "vendorProduct/";
export const API_UpdateManageProductStatus =
  "vendorProduct/admin/activateDeactivateVendorProducts";

//static content
export const API_TermConditions = "admin/static/page/vendorterms/";
export const API_UpdateTermConditions = "admin/static/page/";
export const API_UsersTermConditions = "admin/static/page/customerterms/";
export const API_PrivacyPolicy = "admin/static/page/privacy/";
export const API_CommunityGuidelines = "admin/static/page/communityguidelines/";
export const API_UpdatePrivacyPolicy = "admin/static/page/";
export const API_RefundPolicy = "admin/static/page/refundpolicy/";
export const API_UpdateRefundPolicy = "admin/static/page/";

//commision model
export const API_GetAllCommissionDetails = "commission/";
export const API_UpdateCommissionDetailsById = "commission/";
export const API_AddCommissionDetails = "commission/";

// vendor subsrciption

export const API_GetAllCommissionList = "commission/";
export const API_CreateVendorCommissions = "admin/vendorSubscription/";
export const API_GetAllSubscriptionList = "admin/vendorSubscription/";
export const API_DeleteSubscriptionList = "admin/vendorSubscription/";
export const API_UpdateStatusSbscrption = "admin/vendorSubscription/";
export const API_GetVendorSubscriptionById = "admin/vendorSubscription/";
export const API_MasterCatWithAllCategory = "masterCategory/list/dropdown/";

//customer review
export const getAllReviewList = "admin-review/";
export const getDeletedReviewList = "admin-review/deletedCustomerReviewList";
export const updateReview = "admin-review/acceptRejectCustomerReviewEditRequest/";

//ticket Customer

export const getAllCustomerTicketList = "ticket-admin/admin-ticket/";
export const getCustomerTicketById = "ticket-admin/admin-ticket";
export const APIUpdateStatus = "ticket-admin/admin-ticket/";

//eVoucher
export const getAllEVoucherList = "evoucher-admin/";
export const statusUpdate = "evoucher-admin/activateDeactivateEvoucher/";

// sales compaign
// discounts
export const getAllListOfDiscounts = "discount-admin/";
//voucher

export const salesVoucher = "voucher-admin/"
