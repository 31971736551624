import React, { useState, useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import ApiClient from "../apis/apiClient";
import { API_TermConditions, API_UpdateTermConditions } from "../apis/constant";
import "../css/style.scss";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { checkAccess } from "../utils/common";

const TermCondition = () => {
  const [loading, setLoading] = useState(false);
  const adminToken = localStorage.getItem("wed_admin_token");
  const editorRef = useRef(null);
  const [id, setId] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    getStaticPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const htmlEscape = (str) => {
    return str
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">");
  };

  const getStaticPageData = async () => {
    const data = "";
    const res = await ApiClient.get(
      API_TermConditions,
      data,
      `Bearer ${adminToken}`
    );

    if (res.data.code === 200) {
      setId(res.data.data.id);
      setContent(res.data.data.description);
    }
  };

  const handleUpdate = async () => {
    if (editorRef.current.getContent() === "") {
      toast.warning("Please enter some content");
      return;
    }
    const data = {
      description: content,
    };
    setLoading(true);

    const res = await ApiClient.patch(
      API_UpdateTermConditions + id,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      setLoading(false);

      toast.success("Updated successfully");
    }
  };

  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className="static-container" style={{ padding: "25px" }}>
        <div className="static-label">
          <h4 className="terms_condition_title">Vendors Terms & Conditions </h4>
        </div>
        <div className="editor_state">
          <Editor
            apiKey="w9q4gfv8mt702dhmz6fkaurldvqty67bwaxvihd5eu1xu1g6"
            onInit={(evt, editor) => (editorRef.current = editor)}
            onEditorChange={(content) => setContent(content)}
            value={htmlEscape(content)}
            init={{
              plugins: "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount code",
              toolbar: "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat | code",
              content_style: "p {margin: 0px;}",
            }}
          />
        </div>
        {checkAccess(22, "edit") && (
          <div className="w-full flex justify-end">
            <button
              className="saveButton"
              disabled={loading}
              type="button"
              onClick={() => {
                handleUpdate();
              }}
            >
              {loading ? "Please wait..." : "Save"}
            </button>
          </div>
        )}
      </div>
    );
  }
};

export default TermCondition;
