import React, { useState, useEffect, useRef } from "react";

const Modal = ({ title, body, shortDesc, props, handleAction }) => {

  const modalRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(props.cell.row.original.status);
  const show = (e) => {
    setValue(e.target.value);
    setOpen(true);
    handleAction(e.target.value, props.cell.row.original)
  };
  // const handleClose = () => {
  //   setValue(oldValue);
  //   hide();
  // };

  useEffect(() => {
    setValue(props.cell.row.original.status)
  }, [props.cell]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false;
      if (!open || modalRef.current.contains(event.target)) {
        return false;
      }
      setOpen(!open);
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, modalRef]);
  return (
    <>
      {/* <p className="cursor-pointer" onClick={show}>{shortDesc}</p> */}
      <select
        className="bg-white border-2 w-32 mt-2 py-1 rounded-md"
        value={value}
        disabled={value === 1 || value === 2? true: false}
        onChange={(e) => show(e)}
      >
        <option value={0}>Pending</option>
        <option value={1}>Approved</option>
        <option value={2}>Rejected</option>
        <option value={3}>Archive</option>
      </select>
      {/* {open && (
        <Portal selector="#portal">
          <div className="modal-backdrop fade-in"></div>
          <div
            className={`modal show ${background === "dark" ? "dark-mode" : ""}`}
            data-background={background}
          >
            <div
              className="relative min-w-sm w-auto mx-auto lg:max-w-5xl"
              ref={modalRef}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="text-xl font-semibold">{title}</h3>
                  <button
                    className="modal-close btn btn-transparent"
                    onClick={hide}
                  >
                    <FiX size={18} className="stroke-current" />
                  </button>
                </div>
                <div className="relative p-4 flex-auto">{body}</div>
                <div className="modal-footer space-x-2">
                  <button
                    className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                    type="button"
                    onClick={hide}
                  >
                    No
                  </button>
                  <button
                    className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                    type="button"
                    onClick={() =>{
                    }
                    }
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )} */}
    </>
  );
};

export default Modal;
