/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import SectionTitle from "../components/section-title";
import Permissiontable from "../components/rolePermission";
import Widget from "../components/widget";
import { toast } from "react-toastify";
import Modal2 from "./../components/confirm-delete-modal/modal-2";
import { FiX } from "react-icons/fi";
import ApiClient from "../apis/apiClient";
import {
  API_MasterCategoryList,
  API_DeleteMasterCategoryById,
  API_UpdateMasterCategoryById,
} from "../apis/constant";
import "nprogress/nprogress.css";
import "../css/custome.css";
import AddCategory from "../components/masterCategory/addMasterCategory";
import EditCategory from "../components/masterCategory/editMasterCategory";
import { Redirect } from "react-router-dom";
import { checkAccess } from "../utils/common";


const Simple = () => {
  const [permissionsData, setPermissionsData] = useState([]);
  const [Loading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const adminToken = localStorage.getItem("wed_admin_token");
  const [paginationObject, setPaginationObject] = useState({});
  const searchRef = useRef(search);

  const getAllUsers = async (limit, pageNumber) => {
    const data = "";
    setIsLoading(true);
    // setIsApiCall(true);
    const res = await ApiClient.get(
      API_MasterCategoryList +
      `?page=${pageNumber}&sortBy=asc&limit=${limit}&searchBy=${searchRef.current}`,
      data,
      `Bearer ${adminToken}`
    );

    if (res.data.code === 200) {
      setPaginationObject({
        limit: res.data.data.limit,
        page: res.data.data.page,
        pages: res.data.data.pages,
        total: res.data.data.total,
      });
      setPermissionsData(res.data.data.docs);
      setIsLoading(false);
      // setIsApiCall(false);
    }
  };

  useEffect(() => {
    searchRef.current = search;
    if (search.length > 2 || search.length === 0) {
      getAllUsers(10, 1);
    }
  }, [search]);

  const handleActive = async (status, data) => {
    let myObj = {
      status: status,
      name: data.name,
    };

    const res = await ApiClient.patch(
      API_UpdateMasterCategoryById + "/" + data.id,
      myObj,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllUsers(10, 1);
    } else {
      toast.warning(res.data.message);
    }
  };

  const deleteUser = async (userData) => {
    const id = userData.original.id;
    const data = "";
    const res = await ApiClient.delete(
      API_DeleteMasterCategoryById + "/" + id,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllUsers(10, 1);
    } else {
      toast.warning(res.data.message);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: " Level 1",
        accessor: "name",
      },

      {
        Header: "Status",
        id: "status",
        accessor: (str) => {
          return str["status"] === 1 ? (
            <label>Active</label>
          ) : (
            <label>Inactive</label>
          );
        },
      },

      {
        Header: "Action",
        id: "delete",
        accessor: (str) => { },

        Cell: (Datatable) => (
          <>
            {checkAccess(5, 'edit') && <EditCategory
              id={Datatable.row.original.id}
              title={"Edit Level 1"}
              body={"body"}
              getApi={getAllUsers}
            />}{" "}
            |{" "}
           {checkAccess(5, 'delete') && <Modal2
              title="Delete"
              icon={
                <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                  <FiX size={18} className="stroke-current text-red-500" />
                </span>
              }
              body={
                <div className="text-sm text-grey-500">
                  Are You Sure! You want to delete this Category?
                </div>
              }
              buttonTitle="Confirm"
              onClick={() => {
                deleteUser(Datatable.row);
              }}
              buttonClassName="underLineText"
            />}{" "}
            |{" "}
            {Datatable.cell.row.original.status === 1 ? (
              checkAccess(5, 'edit') &&<Modal2
                title="Deactivate"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to Deactivate this Category?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  handleActive(0, Datatable.cell.row.original);
                }}
                buttonClassName="underLineText"
              />
            ) : (
              checkAccess(5, 'edit') &&<Modal2
                title="Activate"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to Activate this Category?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  handleActive(1, Datatable.cell.row.original);
                }}
                buttonClassName="underLineText"
              />
            )}
          </>
        ),
      },
    ],
    []
  );

  const getPageData = (data) => {
    getAllUsers(data, 1);
  };

  const data = React.useMemo(() => permissionsData, [permissionsData]);
  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
    return (
      <>
       {checkAccess(5, 'add') && <AddCategory
          title={"Add Level 1"}
          body={"body"}
          getApi={getAllUsers}
        />}
        <div>
          <input
            className="searchCss"
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <Permissiontable
          columns={columns}
          data={data}
          loading={Loading}
          onSubmit={getPageData}
          showSearch={true}
          pagination={paginationObject}
          getAllUsers={getAllUsers}
        />
      </>
    );
  }
};

const MasterCategory = () => (
  <>
    <SectionTitle title="Manage Master Categories" subtitle="Master Categories" />
    <Widget>
      <Simple />
    </Widget>
  </>
);
export default MasterCategory;
