import React, { useState, useEffect } from "react";

import axios from "axios";
import { BsChevronDown } from "react-icons/bs";

import { formatCurrency } from "../../functions/numbers";
import { url, API_Dashboard } from "../../apis/constant";
import Datatable from "../datatable";
import Modal from "../modals/Modal";


const Cashflow = () => {
  const [apiData, setApiData] = useState({});
  const [openCashFlow, setOpenCashFlow] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const columnsOrderDetails = [
    {
      Header: "Order ID",
      accessor: "id",
    },
    {
      Header: "Net Sales",
      accessor: "netSales",
    },
    {
      Header: "Amount Paid",
      accessor: "amountPaid",
    },
    {
      Header: "Amount Unpaid",
      accessor: "amountUnpaid",
    },
    {
      Header: "Amount Cancelled",
      accessor: "amountCancelled",
    },
    {
      Header: "Platform Commission Received",
      accessor: "platformCommissionReceived",
    },
  ];

  const columnsCashFlow = [
    {
      Header: "",
      accessor: "totalOrdersSinceInception",
    },
    {
      Header: "Total Net Sales",
      accessor: "totalNetSales",
      Cell: (props) => {
        return <span onClick={() => setShowModal(true)}
          className="text-blue-500 hover:text-blue-700 cursor-pointer">{props.value}</span>;
      },
    },
    {
      Header: "Total Amount Paid",
      accessor: "totalAmountPaid",
      Cell: (props) => {
        return <span onClick={() => setShowModal(true)}
          className="text-blue-500 hover:text-blue-700 cursor-pointer">{props.value}</span>;
      },
    },
    {
      Header: "Total Amount Unpaid",
      accessor: "totalAmountUnpaid",
      Cell: (props) => {
        return <span onClick={() => setShowModal(true)}
          className="text-blue-500 hover:text-blue-700 cursor-pointer">{props.value}</span>;
      },
    },
    {
      Header: "Cancelled Orders ($)",
      accessor: "cancelledOrders",
      Cell: (props) => {
        return <span onClick={() => setShowModal(true)}
          className="text-blue-500 hover:text-blue-700 cursor-pointer">{props.value}</span>;
      },
    },
    {
      Header: "Platform Commissions Received",
      accessor: "platformCommissionsReceived",
      Cell: (props) => {
        return <span onClick={() => setShowModal(true)}
          className="text-blue-500 hover:text-blue-700 cursor-pointer">{props.value}</span>;
      },
    },
  ];

  useEffect(() => {
    const adminToken = localStorage.getItem("wed_admin_token");
    axios.get(url + API_Dashboard.cashflow, {
      params: {},
      headers: {
        Authorization: `Bearer ${adminToken}`
      }
    }).then(res => {
      if (res.data.code === 200) {
        setApiData(res.data.data);
      }
    }).catch(e => {
      console.error(`Error: ${e}`);
    })
  }, []);


  return (
    <div className="bg-white border shadow-md px-2 py-4 rounded mt-4">
      <div
        onClick={() => setOpenCashFlow(!openCashFlow)}
        className={`flex items-center justify-between cursor-pointer ${
          openCashFlow && "mb-4"
          }`}
      >
        <p className={`font-medium text-3xl`}>Cash Flow</p>
        <BsChevronDown
          size={25}
          className={openCashFlow ? "rotate-180" : "rotate-0"}
        />
      </div>
      {openCashFlow && (
        <div className="animate-fade-in-down">
          <div className="p-2 bg-white border rounded-md text-xl table-responsive">
            <SimpleCashFlow apiData={apiData} columns={columnsCashFlow} detailColumns={columnsOrderDetails} showModal={showModal} setShowModal={setShowModal} />
          </div>
        </div>
      )}
    </div>
  );
}

export default Cashflow;

const SimpleCashFlow = ({ detailColumns, apiData, columns, tableSize = 10, setTableSize = () => { }, showModal, setShowModal }) => {
  const data = React.useMemo(() => [
    {
      "totalOrdersSinceInception": "Total Orders since inception",
      "totalNetSales": formatCurrency(apiData?.totalNetSales),
      "totalAmountPaid": formatCurrency(apiData?.totalAmountPaid),
      "totalAmountUnpaid": formatCurrency(apiData?.totalAmountUnpaid),
      "cancelledOrders": formatCurrency(apiData?.totalAmountCancelled),
      "platformCommissionsReceived": formatCurrency(apiData?.totalPlatformCommissionReceived),
    }
  ], [apiData]);

  const orderDetails = React.useMemo(() => {
    return apiData?.orderDetails?.map((item) => ({
      'id': item.id,
      'netSales': formatCurrency(item.netSales),
      'amountPaid': formatCurrency(item.amountPaid),
      'amountUnpaid': formatCurrency(item.amountUnpaid),
      'amountCancelled': formatCurrency(item.amountCancelled),
      'platformCommissionReceived': formatCurrency(item.platformCommissionReceived),
    }))
  }, [apiData]);

  return (
    <>
      <Datatable
        columns={columns}
        data={data}
        onCheck={(data) => { }}
        tableSize={tableSize}
        setTableSize={setTableSize}
        showResponseData={false}
        showPagination={false}
        showSelection={false}
      />
      {showModal && (
        <Modal title="Order Details"
          onClose={() => setShowModal(false)}
          data={orderDetails}
        >
          {(currentItems) => (
            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50 sticky top-0 z-10 bg-white">
                  {detailColumns.map((column, index) => (
                    <th key={index} scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky top-0 z-10 bg-white">
                      {column.Header}
                    </th>
                  ))}
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {currentItems.map((item, index) => (
                    <tr key={index}>
                      {detailColumns.map((column, colIndex) => (
                        <td key={colIndex} className="px-6 py-4 whitespace-nowrap">
                          {item[column.accessor]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

