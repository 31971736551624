import { Modal } from "@mui/material";
import React, { useState } from "react";
import { BsCalendarEvent } from "react-icons/bs";
import moment from "moment";
import { GrClose } from "react-icons/gr";
import CreateCalendarEvent from "../event";

const ReminderVendor = ({ data, isOpen, setIsOpen }) => {
  const [selectedMeeting, setSelectedMeeting] = useState("");
  const [eventForm, setEventForm] = useState({ members: [""] });
  const [isOpenEvent, setIsOpenEvent] = useState(false);

  const getDiffereceTime = (date) => {
    // const currentDate = new Date();
    // const reminderDate = new Date(date);
    // const diff = reminderDate - currentDate;
    // const diffInMinutes = diff / 1000 / 60;
    // const diffInHours = diffInMinutes / 60;

    // if (diffInMinutes < 60) {
    //   if (diffInMinutes < 0) {
    //     return "Expired";
    //   } else {
    //     return `${Math.floor(diffInMinutes)} minutes`;
    //   }
    // } else if (diffInHours < 24) {
    //   return `${Math.floor(diffInHours)} hours`;
    // } else {
    //   return `${Math.floor(diffInHours / 24)} days`;
    // }

    const currentDate = moment();
    const reminderDate = moment(date);
    const diff = reminderDate.diff(currentDate);
    const diffInMinutes = moment.duration(diff).asMinutes();
    const diffInHours = moment.duration(diff).asHours();

    if (diffInMinutes < 60) {
      if (diffInMinutes < 0) {
        return "Expired";
      } else {
        return `${Math.floor(diffInMinutes)} minutes`;
      }
    }
    if (diffInHours < 24) {
      return `${Math.floor(diffInHours)} hours`;
    }
    return `${Math.floor(diffInHours / 24)} days`;
  };

  return (
    <>
      <Modal open={isOpen} onClose={() => setIsOpen(false)} className="flex justify-center items-center w-full">
        <div className="bg-white flex flex-col justify-between py-5 rounded-md md:w-1/2 w-full">
          <div className="w-full text-xl px-5">
            <div className="w-full flex items-center justify-between">
              <p className="font-worksans-semibold text-xl">Reminders</p>
              <GrClose size={25} className="cursor-pointer" onClick={() => setIsOpen(false)} />
            </div>
            {selectedMeeting && (
              <div className="w-full flex items-center mt-5">
                <div className="mr-5">
                  <BsCalendarEvent size={30} className="text-bg-primary" />
                </div>
                <div>
                  <p className="text-xl">{selectedMeeting.title}</p>
                  <p className="text-base">{moment(selectedMeeting.start).format("DD-MMM-YYYY, HH:mm A")}</p>
                </div>
              </div>
            )}
            <div className="mt-5 h-[45vh] overflow-y-auto border border-bg-primary rounded-md p-2">
              {data.map((item, index) => (
                <div
                  key={index}
                  onClick={() => (selectedMeeting.id === item.id ? setSelectedMeeting("") : setSelectedMeeting(item))}
                  onDoubleClick={() => {
                    setEventForm({
                      ...eventForm,
                      title: item.title,
                      date: item.start,
                      start_time: moment(item.start).format("HH:mm:ss"),
                      end_time: moment(item.end).format("HH:mm:ss"),
                    });
                    setIsOpenEvent(true);
                  }}
                  className={`flex items-center justify-between cursor-pointer select-none hover:bg-gray-100 ${
                    selectedMeeting.id === item.id ? "bg-gray-100" : ""
                  }`}
                >
                  <div className="flex items-center space-x-2">
                    <BsCalendarEvent size={20} className="text-bg-primary" />
                    <p>{item.title}</p>
                  </div>
                  <p>{getDiffereceTime(item.start)}</p>
                </div>
              ))}
            </div>
            {selectedMeeting && (
              <div className="mt-4 w-full flex justify-end">
                <div className="flex items-center space-x-3">
                  <button className="bg-bg-primary text-white rounded-md w-24 py-1">Cancel</button>
                  <button className="bg-bg-primary text-white rounded-md w-24 py-1">Edit</button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
      <CreateCalendarEvent eventForm={eventForm} setEventForm={setEventForm} isOpen={isOpenEvent} setIsOpen={setIsOpenEvent} />
    </>
  );
};

export default ReminderVendor;
