/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { BsChevronDown } from "react-icons/bs";
import SectionTitle from "../components/section-title";
import Datatable from "../components/datatable/index-table";
import axios from "axios";
import { API_CancelOrder, url, API_cancel_order } from "../apis/constant";
import { toast } from "react-toastify";
import RefundModal from "../components/modals/RefundModal";
import { FormatPrice } from "../utils/common";
import CommentsModal from "../components/modals/CommentsModal";
import { refundMethods } from "../utils/constants";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ModalReviewDescription from "../components/modals/modalReviewDescription";

const FILE_URL = process.env.REACT_APP_FILE_URL;
const adminToken = localStorage.getItem("wed_admin_token");

const TableOrder = () => {
  const initialData = { docs: [], total: 0, pages: 0, loading: true };
  const initialDataLimit = { limit: 5, page: 1 };
  const [updatedData, setUpdatedData] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);

  const [cashflowData, setCashFlowData] = useState(initialData);
  const [cashflowLimit, setCashflowLimit] = useState(initialDataLimit);
  //
  const [cancelData, setCancelData] = useState(initialData);
  const [cancelLimit, setCancelLimit] = useState(initialDataLimit);
  //
  const [userVenAppData, setUserVenAppData] = useState(initialData);
  const [userVenAppLimit, setUserVenAppLimit] = useState(initialDataLimit);
  //
  const [refundAmount, setRefundAmount] = useState(initialData);
  const [refundedOrders, setRefundedOrders] = useState(initialData);
  //
  const [refundAmountLimit, setRefundAmountLimit] = useState(initialDataLimit);
  const [refundedOrdersLimit, setRefundedOrdersLimit] = useState(initialDataLimit);

  const [refundItem, setRefundItem] = useState(null);
  const [commentModal, setCommentModal] = useState(null);

  const handleChange = (value, orderNo, key, column) => {
    if (value) {
      updatedData[column][orderNo][key] = value;
    }
  };

  const handleSaveData = (item, column, data) => {
    axios.patch(url + API_cancel_order + `/approve/${item.id}`, data ? { ...data } : { ...updatedData[column][item.orderno] }, { headers: { Authorization: `Bearer ${adminToken}` }, })
      .then((res) => {
        if (res.data.code === 200) {
          getCancellationRequests();
          getCanceldataList();
          getApprovedOrders();
          getRefundedOrders();
          if (data) {
            getItemsReceived();
          }
          toast.success("Order Updated successfully!");
        }
      })
      .catch((err) => {
        toast.error(err?.message ?? "Error occured please try again!");
      });
  };

  const handleReject = (id) => {
    axios.patch(url + API_cancel_order + `/reject/${id}`, {}, { headers: { Authorization: `Bearer ${adminToken}` }, })
      .then((res) => {
        if (res.data.code === 200) {
          getCancellationRequests();
          getCanceldataList();
          toast.success("Request rejected successfully!");
        }
      })
      .catch((err) => {
        toast.error(err?.message ?? "Error occured please try again!");
      });
  };

  const initiatePayment = (id) => {
    axios.post(url + API_cancel_order + `/refundOrder/${id}`, {}, { headers: { Authorization: `Bearer ${adminToken}` }, })
      .then((res) => {
        if (res.data.code === 200) {
          getCancellationRequests();
          getCanceldataList();
          getApprovedOrders();
          getRefundedOrders();
          getItemsReceived();
          toast.success("Order Refunded successfully!");
        } else {
          toast.error(res?.data?.message ?? "Error occured please try again!");
        }
      }).catch(err => {
        toast.error(err?.response?.data?.message ?? "Error occured please try again!");
      });
  }

  const forceApproval = (id) => {
    axios.patch(url + API_cancel_order + `/approve/force/${id}`, {}, { headers: { Authorization: `Bearer ${adminToken}` }, })
      .then((res) => {
        if (res.data.code === 200) {
          getCancellationRequests();
          getCanceldataList();
          getApprovedOrders();
          getRefundedOrders();
          getItemsReceived();
          toast.success("Order is Forcefully approved!");
        } else {
          toast.error(res?.data?.message ?? "Error occured please try again!");
        }
      }).catch(err => {
        toast.error(err?.response?.data?.message ?? "Error occured please try again!");
      });
  }

  const sendApprovalEmail = async (id, status) => {
    try {
      const res = await axios.post(`${url}${API_cancel_order}/sendApprovalEmail/${id}`, { submitStatus: status }, { headers: { Authorization: `Bearer ${adminToken}` } });
      if (res.data.code === 200) {
        getCanceldataList();
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message ?? "Error occured please try again!");
      }
    } catch (e) {
      toast.error(e?.message ?? "Error occured please try again!");
    }
  };

  const payoutToVendor = async (id) => {
    try {
      const amount = prompt("Please enter payout amount");
      if(amount > 0){
        const res = await axios.post(`${url}${API_cancel_order}/payout/${id}`, { stripePayout: amount }, { headers: { Authorization: `Bearer ${adminToken}` } });
        if (res.data.code === 200) {
          getRefundedOrders();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message ?? "Error occured please try again!");
        }
      }
    } catch (e) {
      toast.error(e?.message ?? "Error occured please try again!");
    }
  };

  const transferToPlatform = async (id) => {
    try {
      const amount = prompt("Please enter transfer amount");
      if(amount > 0){
        const res = await axios.post(`${url}${API_cancel_order}/transfer/${id}`, { stripeTransfer: amount }, { headers: { Authorization: `Bearer ${adminToken}` } });
        if (res.data.code === 200) {
          getRefundedOrders();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message ?? "Error occured please try again!");
        }
      }
    } catch (e) {
      toast.error(e?.message ?? "Error occured please try again!");
    }
  };

  const onSelect = (tableName, obj) => {
    if (obj.limit === 999999) {
      obj.page = 1;
    }
    // eslint-disable-next-line default-case
    switch (tableName) {
      case "CANCEL_REQUESTS":
        setCashflowLimit(obj);
        break;
      case "WORKING_SECTION":
        setCancelLimit(obj);
        break;
      case "BOTH_AGREED":
        setUserVenAppLimit(obj);
        break;
      case "ITEMS_RECEIVED":
        setRefundAmountLimit(obj);
        break;
      case "REFUNDED_ORDERS":
        setRefundedOrdersLimit(obj);
        break;
    }
  };

  const getCancellationRequests = () => {
    setCashFlowData(initialData);
    axios.get(url + API_CancelOrder, { params: { type: "CANCEL_REQUESTS", ...cashflowLimit }, headers: { Authorization: `Bearer ${adminToken}` }, })
      .then((res) => {
        if (res.data.code === 200) {
          const orderData = res.data.data.docs;
          const resultData = orderData.map((v) => {
            const tempItem = {
              reason: v.reason,
              refundMethod: v.refundMethod,
              reasonDetails: v.reasonDetails,
            };
            setUpdatedData((prevData) => ({
              ...prevData,
              cancelDataRequest: {
                ...prevData.cancelDataRequest,
                [v.order.orderId]: tempItem,
              },
            }));
            return {
              vendorID: v.vendor ? v.vendor.vendorId : "",
              vendorName: v?.order?.vendorDetails?.vendorName ?? v?.vendor?.vendorName ?? "",
              orderno: v.order ? v.order.orderId : "",
              vendorCharges: v.vendorCharges,
              cancelreason: v.reason,
              canceldetails: v.reasonDetails,
              vendorAttachfiles: v.vendorAttachments,
              customerAttachfiles: v.customerAttachments,
              refundMethod: v.refundMethod,
              orderstatus: v.order ? v.order.orderStatus : "",
              id: v.id,
              submittedBy:
                v.requestedBy === "User"
                  ? v.user.email
                  : v.requestedBy === "Vendor"
                    ? v.vendor.email
                    : "superadmin@yopmail.com",
              cancelSubmittedDate: v?.createdAt
                ? moment(v?.createdAt).format("DD-MMM-YYYY")
                : "-",
              orderdate: v.order?.createdAt
                ? moment(v.order?.createdAt).format("DD-MMM-YYYY")
                : "-",
            };
          });
          setCashFlowData({ ...res.data.data, docs: resultData, loading: false });
        }
      })
      .catch((err) => {
        toast.error(err?.res?.data?.message);
      });
  };

  const getCanceldataList = () => {
    setCancelData(initialData);
    axios.get(url + API_CancelOrder, { params: { type: "WORKING_SECTION", ...cancelLimit }, headers: { Authorization: `Bearer ${adminToken}` }, })
      .then((res) => {
        if (res.data.code === 200) {
          const cancelData = res.data.data.docs;
          const resultData = cancelData.map((v) => {
            const tempItem = {
              estimatedRefundAmount: v.estimatedRefundAmount,
              cancellationCharges: v.cancellationCharges,
              vendorCharges: v.vendorCharges,
              platformCommToRefundVendor: v.platformCommToRefundVendor,
              refundedAmount: v.refundedAmount,
              adminComment: v.adminComment ?? "",
              vendorPaymentToPlatform: v.vendorPaymentToPlatform,
            };
            setUpdatedData((prevData) => ({
              ...prevData,
              workingSection: {
                ...prevData.workingSection,
                [v.order.orderId]: tempItem,
              },
            }));

            return {
              vendorid: v.vendor ? v.vendor.id : "",
              totalorder: v.order ? v.order.totalOrderAmount : "",
              paymentmade: v.order ? v.order.totalPayments : "",
              milestone: v.order ? v.order.paymentMilestones?.length : "",
              vendorpayout: v.vendorCharges,
              platformcomm: v.adminCharges,
              commisionrefund: v.platformCommToRefundVendor,
              orderno: v.order.orderId,
              refundedAmount: v.refundedAmount,
              admchargetocust: v.cancellationCharges,
              estimation: v.estimatedRefundAmount,
              actions: v.attachments,
              submitStatus: v.submitStatus,
              voucherCode: v.order?.voucherCode,
              voucherOffset: v.order?.voucherOffset,
              submittedDates: {
                vendorSubmittedDate: v.vendorSubmittedDate,
                customerSubmittedDate: v.customerSubmittedDate,
              },
              adminVerified: v.adminVerified,
              userAgreed: v.userAgreed,
              vendorAgreed: v.vendorAgreed,
              orderId: v.order._id,
              platformcomments: v.adminComment,
              vendorComments: v.vendorComment,
              customerComments: v.customerComments,
              vendorAgreedRefund: v.vendorAgreedRefund,
              totalTransactionFees: v.order?.totalTransactionFees,
              id: v.id,
              itemsReceived: v.itemsReceived,
              refundDate: v.order.refundDate,
              userAgreedOn: v.userAgreedOn,
              refundMethod: v.refundMethod,
              vendorAgreedOn: v.vendorAgreedOn,
              rejectedBy: v.rejectedBy,
            };
          });
          setCancelData({ ...res.data.data, docs: resultData, loading: false });
        }
      })
      .catch((err) => {
        toast.error(err?.res?.data?.message);
      });
  };

  const getItemsReceived = () => {
    setRefundAmount(initialData);
    axios.get(url + API_CancelOrder, { params: { type: "ITEMS_RECEIVED", ...refundAmountLimit }, headers: { Authorization: `Bearer ${adminToken}` }, })
      .then((res) => {
        if (res.data.code === 200) {
          const refundData = res.data.data.docs;
          const resultData = refundData.map((v) => {
            return {
              id: v.id,
              orderno: v.order.orderId,
              itemsrcvd: v.itemsReceived ? "Yes" : "No",
              rcvddandt: v.itemsReceivedOn,
              rrnumber: v.itemsReceivedReferenceNo,
              vendorcomments: v.vendorComment,
              tri: v.userShouldReturnItem,
              returnrefno: v.refundReferenceNo,
              itemdesc: v.returnItemDescription,
              returnto: v.returningAddress,
            };
          });
          setRefundAmount({ ...res.data.data, docs: resultData, loading: false });
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const getApprovedOrders = () => {
    setUserVenAppData(initialData);
    axios.get(url + API_CancelOrder, { params: { type: "BOTH_AGREED", ...userVenAppLimit }, headers: { Authorization: `Bearer ${adminToken}` }, })
      .then((res) => {
        if (res.data.code === 200) {
          const cancelData = res.data.data.docs;
          const resultData = cancelData.map((v) => {
            return {
              id: v.id,
              orderno: v.order.orderId,
              tri: v.userShouldReturnItem,
              itemsReceived: v.itemsReceived,
              refundMethod: refundMethods.find(x => x.key === v.refundMethod)?.value,
              receiptstoplatform: v.vendorPaymentToPlatform ?? 0,
              attachcreditnote: v.vendorCreditNoteToPlatform ?? "",
              refundreference: v.refundReferenceNo,
              voucherCoupon: v.evoucherCoupon,
              stripeId: v.stripeId,
              prtimestamp: v?.voucherRefunedOn ? moment(v.voucherRefunedOn).format("DD-MMM-YYYY, HH:mm") : "-",
              srtimestamp: v?.paymentRefundedOn ? moment(v.paymentRefundedOn).format("DD-MMM-YYYY, HH:mm") : "-"
            };
          });
          setUserVenAppData({ ...res.data.data, docs: resultData, loading: false });
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const getRefundedOrders = () => {
    setRefundedOrders(initialData);
    axios.get(url + API_CancelOrder, { params: { type: "REFUNDED_ORDERS", ...refundedOrdersLimit }, headers: { Authorization: `Bearer ${adminToken}` }, })
      .then((res) => {
        if (res.data.code === 200) {
          const refundData = res.data.data.docs;
          const resultData = refundData.map((v) => {
            const order = v?.order;
            const vendor = v?.vendor;
            return {
              id: v.id,
              vendorId: vendor.vendorId,
              vendorName: order?.vendorDetails?.vendorName ?? vendor?.vendorName ?? "",
              cancelreason: v.reason,
              tri: v.userShouldReturnItem,
              canceldetails: v.reasonDetails,
              vendorpayout: v.vendorCharges,
              vendorAttachfiles: v.vendorAttachments,
              returnrefno: v.refundReferenceNo,
              customerAttachfiles: v.customerAttachments,
              orderno: order.orderId,
              orderdate: order.createdAt ? moment(order.createdAt).format("DD-MMM-YYYY") : "-",
              cancelSubmittedDate: v?.createdAt ? moment(v?.createdAt).format("DD-MMM-YYYY") : "-",
              estimateRefundAmt: v.estimatedRefundAmount,
              itemsrcvd: v.itemsReceived ? "Yes" : "No",
              rcvddandt: v.itemsReceivedOn,
              vendorAgreedRefund: v.vendorAgreedRefund,
              rrnumber: v.itemsReceivedReferenceNo,
              refundedAmount: v.refundedAmount,
              receiptstoplatform: v.vendorPaymentToPlatform ?? 0,
              refundDate: v.order.refundDate,
              attachcreditnote: v.vendorCreditNoteToPlatform ?? "",
              admchargetocust: v.cancellationCharges,
              refundreference: v.itemsReceivedReferenceNo,
              customerComments: v.customerComments,
              platformcomments: v.adminComment,
              vendorComments: v.vendorComment,
              vendorAgreedOn: v.vendorAgreedOn,
              customerAgreedOn: v.userAgreedOn,
              voucherCoupon: v.evoucherCoupon,
              stripeId: v.stripeId,
              refundMethod: refundMethods.find(x => x.key === v.refundMethod)?.value,
              prtimestamp: v?.voucherRefunedOn ? moment(v.voucherRefunedOn).format("DD-MMM-YYYY, HH:mm") : "-",
              srtimestamp: v?.paymentRefundedOn ? moment(v.paymentRefundedOn).format("DD-MMM-YYYY, HH:mm") : "-",
              adminAgreedOn: v?.adminAgreedOn,
              submittedBy: v.requestedBy === "User" ? v.user.email : v.requestedBy === "Vendor" ? v.vendor.email : "superadmin@yopmail.com",
              itemdesc: v.returnItemDescription,
              returnto: v.returningAddress,
              stripePayoutDate: moment(v.stripePayoutDate).format("DD-MMM-YYYY"),
              stripePayoutId: v.stripePayoutId,
              stripePayoutAmt: v.stripePayoutAmt,
              stripeTransferDate: moment(v.stripeTransferDate).format("DD-MMM-YYYY"),
              stripeTransferId: v.stripeTransferId,
              stripeTransferAmt: v.stripeTransferAmt,
            };
          });
          setRefundedOrders({ ...res.data.data, docs: resultData, loading: false });
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  useEffect(() => {
    getCancellationRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashflowLimit]);

  useEffect(() => {
    getCanceldataList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelLimit]);

  useEffect(() => {
    getItemsReceived();
    // eslint-disable-next-line
  }, [refundAmountLimit]);

  useEffect(() => {
    getApprovedOrders();
    // eslint-disable-next-line
  }, [userVenAppLimit]);

  useEffect(() => {
    getRefundedOrders();
    // eslint-disable-next-line
  }, [refundedOrdersLimit]);

  const [selectedReasonDetails, setSelectedReasonDetails] = useState("");
  const [openModalReasonDetails, setOpenModalReasonDetails] = useState(false);

  const columnsCashFlow = useMemo(
    () => [
      {
        Header: "Order No",
        accessor: "orderno",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Vendor ID",
        accessor: "vendorID",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Vendor Name",
        accessor: "vendorName",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Order Date",
        accessor: "orderdate",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Cancel Submitted Date",
        accessor: "cancelSubmittedDate",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Cancel Reason",
        accessor: "cancelreason",
        Cell: (props) => {
          const orderNo = props?.row?.original?.orderno;
          return (
            <select
              defaultValue={
                updatedData["cancelDataRequest"][orderNo]?.reason
              }
              onChange={(e) =>
                handleChange(
                  e.target.value,
                  orderNo,
                  "reason",
                  "cancelDataRequest"
                )
              }
              className="w-56 text-sm outline-none border border-bg-primary rounded-sm py-1 px-2 h-9 "
            >
              <option
                value="Others"
                selected={props.value === "Others"}
              >
                Others
              </option>
              <option
                value="Vendor did not/unable to deliver"
                selected={props.value === "Vendor did not/unable to deliver"}
              >
                Vendor did not/unable to deliver
              </option>
              <option
                value="Vendor did not deliver per agreed specs"
                selected={props.value === "Vendor did not deliver per agreed specs"}
              >
                Vendor did not deliver per agreed specs
              </option>
              <option
                value="Cancelled due to Breakup"
                selected={props.value === "Cancelled due to Breakup"}
              >
                Cancelled due to Breakup
              </option>
              <option
                value="Cancelled due to Extenuating Circumstances eg Death, Disaster"
                selected={
                  props.value ===
                  "Cancelled due to Extenuating Circumstances eg Death, Disaster"
                }
              >
                Cancelled due to Extenuating Circumstances eg Death, Disaster
              </option>
              <option
                value="Vendor did not/unable to deliver"
                selected={props.value === "Vendor did not/unable to deliver"}
              >
                Vendor did not/unable to deliver
              </option>
              <option
                value="Customer did not pay full"
                selected={props.value === "Customer did not pay full"}
              >
                Customer did not pay full
              </option>
            </select>
          )
        },
        isCheck: true,
      },
      {
        Header: "Cancel Reason Details",
        accessor: "canceldetails",
        Cell: (props) => {
          const orderNo = props?.row?.original?.orderno;
          const reasonDetails = updatedData["cancelDataRequest"][orderNo]?.reasonDetails;
          const truncatedDescription = reasonDetails.length > 30 ? reasonDetails.substring(0, 30) + "....." : reasonDetails;
          const handleViewReasonDetails = () => {
            setSelectedReasonDetails(reasonDetails);
            setOpenModalReasonDetails(true);
          };
          // return (
          //   <textarea
          //     defaultValue={
          //       updatedData["cancelDataRequest"][orderNo]?.reasonDetails
          //     }
          //     onChange={(e) =>
          //       handleChange(
          //         e.target.value,
          //         orderNo,
          //         "reasonDetails",
          //         "cancelDataRequest"
          //       )
          //     }
          //     placeholder="Cancel Reason Details"
          //     className="border w-64 text-sm"
          //   />
          // )
          return (
            <div className="flex items-center space-x-2">
              {reasonDetails.length > 30 ? (
                <p
                  onClick={handleViewReasonDetails}
                  className="cursor-pointer hover:text-grey-500"
                >
                  {truncatedDescription}
                </p>
              ) : (
                <p>{truncatedDescription}</p>
              )}
            </div>
          );
        },
        isCheck: true,
      },
      {
        Header: "Vendor Attach Files",
        accessor: "vendorAttachfiles",
        Cell: (props) => (
          <div className="flex flex-col justify-center items-center">
            {props.value?.map((f) => (
              // eslint-disable-next-line
              <a
                key={f}
                href={FILE_URL + f}
                target="_blank"
                className="underline text-base text-bg-primary"
              >
                {f}
              </a>
            ))}
          </div>
        ),
        isCheck: true,
      },
      {
        Header: "Customer Attach Files",
        accessor: "customerAttachfiles",
        Cell: (props) => (
          <div className="flex flex-col justify-center items-center">
            {props.value?.map((f) => (
              // eslint-disable-next-line
              <a
                key={f}
                href={FILE_URL + f}
                target="_blank"
                className="underline text-base text-bg-primary"
              >
                {f}
              </a>
            ))}
          </div>
        ),
        isCheck: true,
      },
      {
        Header: "Refund Method",
        accessor: "refundMethod",
        Cell: (props) => {
          const orderNo = props?.row?.original?.orderno;
          return (
            <select
              defaultValue={
                updatedData["cancelDataRequest"][orderNo]?.refundMethod
              }
              onChange={(e) =>
                handleChange(
                  e.target.value,
                  orderNo,
                  "refundMethod",
                  "cancelDataRequest"
                )
              }
              className="w-32 outline-none text-sm border border-bg-primary rounded-sm py-1 px-2 h-9"
            >
              <option value="original" selected={props.value === "original"}>
                Original Payment Method
              </option>
              <option value="voucher" selected={props.value === "voucher"}>
                Voucher
              </option>
            </select>
          );
        },
        isCheck: true,
      },
      {
        Header: "Order Status",
        accessor: "orderstatus",
        Cell: (props) => (
          <select
            disabled
            className="w-32 text-sm outline-none border border-bg-primary rounded-sm py-1 px-2 h-9"
          >
            <option value={1} selected={props.value === 1}>
              Delivered
            </option>
            <option value={2} selected={props.value === 2}>
              Partially Paid
            </option>
            <option value={4} selected={props.value === 4}>
              Paid not delivered
            </option>
            <option value={0} selected={props.value === 0}>
              Cancelled
            </option>
            <option value={3} selected={props.value === 3}>
              Pending Cancellation
            </option>
          </select>
        ),
        isCheck: true,
      },
      {
        Header: "Submitted By",
        accessor: "submittedBy",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Action",
        accessor: "id",
        isCheck: true,
        show: true,
        Cell: (props) => {
          const order = props?.row?.original;
          return (
            <div>
              <p
                onClick={() => handleReject(order?.id)}
                className="underline cursor-pointer text-sm text-red-500"
              >
                Reject
              </p>
              <p
                onClick={() => handleSaveData(order, "cancelDataRequest")}
                className="underline cursor-pointer text-sm"
              >
                Save
              </p>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updatedData]
  );

  const columnsCancellation = useMemo(
    () => [
      {
        Header: "Order No",
        accessor: "orderno",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Refund Date Allowable",
        accessor: "refundDate",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Total Order",
        accessor: "totalorder",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Voucher Code",
        accessor: "voucherCode",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Voucher Offset",
        accessor: "voucherOffset",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Total Payment Made",
        accessor: "paymentmade",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Payment Milestone",
        accessor: "milestone",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Platform Commissions",
        accessor: "platformcomm",
        Cell: (props) => <p className="text-sm">${FormatPrice(props.value)}</p>,
        isCheck: true,
      },
      {
        Header: "Total Transaction Fees",
        accessor: "totalTransactionFees",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
        show: true,
      },
      {
        Header: "Platform Comm to refund Vendor",
        accessor: "commisionrefund",
        Cell: (props) => {
          const orderNo = props?.row?.original?.orderno;
          return (
            <input
              type={"number"}
              onChange={(e) =>
                handleChange(
                  e.target.value,
                  orderNo,
                  "platformCommToRefundVendor",
                  "workingSection"
                )
              }
              defaultValue={
                updatedData["workingSection"][orderNo]
                  ?.platformCommToRefundVendor ?? props.value
              }
              className="border w-40 text-sm"
            />
          );
        },
        isCheck: true,
      },
      {
        Header: "Est. Refund Amt",
        accessor: "estimation",
        Cell: (props) => {
          const orderNo = props?.row?.original?.orderno;
          return (
            <input
              type={"number"}
              onChange={(e) =>
                handleChange(
                  e.target.value,
                  orderNo,
                  "estimatedRefundAmount",
                  "workingSection"
                )
              }
              className="border w-40 text-sm"
              defaultValue={
                updatedData["workingSection"][orderNo]?.estimatedRefundAmount ??
                props.value
              }
            />
          );
        },
        isCheck: true,
      },
      {
        Header: "Vendor Agreed Refund",
        accessor: "vendorAgreedRefund",
        Cell: (props) => <p className="text-sm">${FormatPrice(props.value)}</p>,
        isCheck: true,
      },
      {
        Header: "Vendor Payout",
        accessor: "vendorpayout",
        Cell: (props) => {
          const orderNo = props?.row?.original?.orderno;
          return (
            <input
              type={"number"}
              className="border w-40 text-sm"
              onChange={(e) =>
                handleChange(
                  e.target.value,
                  orderNo,
                  "vendorCharges",
                  "workingSection"
                )
              }
              defaultValue={
                updatedData["workingSection"][orderNo]?.vendorCharges ??
                props.value
              }
            />
          );
        },
        isCheck: true,
      },
      {
        Header: "Admin Charge to customer",
        accessor: "admchargetocust",
        Cell: (props) => {
          const orderNo = props?.row?.original?.orderno;
          return (
            <input
              type={"number"}
              onChange={(e) =>
                handleChange(
                  e.target.value,
                  orderNo,
                  "cancellationCharges",
                  "workingSection"
                )
              }
              defaultValue={
                updatedData["workingSection"][orderNo]?.cancellationCharges ??
                props.value
              }
              className="border w-40 text-sm"
            />
          );
        },
        isCheck: true,
      },
      {
        Header: "Refunded Amt To Customer",
        accessor: "refundedAmount",
        Cell: (props) => {
          const orderNo = props?.row?.original?.orderno;
          return (
            <input
              type={"number"}
              className="border w-40 text-sm"
              onChange={(e) =>
                handleChange(
                  e.target.value,
                  orderNo,
                  "refundedAmount",
                  "workingSection"
                )
              }
              defaultValue={
                updatedData["workingSection"][orderNo]?.refundedAmount ??
                props.value
              }
            />
          );
        },
        isCheck: true,
      },
      {
        Header: "Vendor Refund To Platform",
        accessor: "vendorPaymentToPlatform",
        Cell: (props) => {
          const orderNo = props?.row?.original?.orderno;
          return (
            <input
              type={"number"}
              onChange={(e) =>
                handleChange(
                  e.target.value,
                  orderNo,
                  "vendorPaymentToPlatform",
                  "workingSection"
                )
              }
              defaultValue={
                updatedData["workingSection"][orderNo]?.vendorPaymentToPlatform
              }
              className="border w-40 text-sm"
            />
          );
        },
        isCheck: true,
      },
      {
        Header: "Platform Comments",
        accessor: "platformcomments",
        isCheck: true,
        Cell: (props) => {
          const orderNo = props?.row?.original?.orderno;
          return (
            <textarea
              onChange={(e) =>
                handleChange(
                  e.target.value,
                  orderNo,
                  "adminComment",
                  "workingSection"
                )
              }
              defaultValue={
                updatedData["workingSection"][orderNo]?.adminComment ??
                props.value
              }
              placeholder="Platform Comments"
              className="border w-64 text-sm"
            />
          );
        },
      },
      {
        Header: "Vendor Comments",
        accessor: "vendorComments",
        isCheck: true,
        Cell: (props) => <p className="text-sm ellipsis" onClick={() => props.value ? setCommentModal({ commentBy: 'Vendor', comment: props.value }) : {}}>{props.value}</p>,
      },
      {
        Header: "Customer Comments",
        accessor: "customerComments",
        isCheck: true,
        Cell: (props) => <p className="text-sm ellipsis" onClick={() => props.value ? setCommentModal({ commentBy: 'Customer', comment: props.value }) : {}}>{props.value}</p>,
      },
      {
        Header: "Action",
        accessor: "id",
        isCheck: true,
        show: true,
        Cell: (props) => {
          const order = props?.row?.original;
          return (
            <div>
              <p
                onClick={() => handleSaveData(order, "workingSection")}
                className="underline cursor-pointer text-sm"
              >
                Save
              </p>
            </div>
          );
        },
      },
      {
        Header: "Approval Action",
        accessor: "actions",
        Cell: (props) => {
          const item = props?.row?.original;
          return (
            <div className="flex flex-col">
              <p
                onClick={() =>
                  item.submitStatus !== 1 ? sendApprovalEmail(item.id, 1) : {}
                }
                className={`${item.submitStatus !== 1 ? "underline cursor-pointer" : ""
                  } text-sm text-bg-primary`}
              >
                {item.submitStatus !== 1
                  ? "Vendor To Approve"
                  : "Vendor Approve Submitted"}
              </p>
              <p
                onClick={() =>
                  item.submitStatus !== 2 ? sendApprovalEmail(item.id, 2) : {}
                }
                className={`${item.submitStatus !== 2 ? "underline cursor-pointer" : ""
                  } text-sm text-bg-primary`}
              >
                {item.submitStatus !== 2
                  ? "Customer To Approve"
                  : "Customer Approve Submitted"}
              </p>
              <p
                onClick={() => {
                  initiatePayment(item.id);
                }}
                className="underline cursor-pointer text-sm text-bg-primary"
              >
                Platform Approve
              </p>
              {(!item.userAgreed || !item.vendorAgreed) &&
                <p
                  onClick={() => {
                    forceApproval(item.id);
                  }}
                  className="underline cursor-pointer text-sm text-bg-primary"
                >
                  Force User And Vendor Approve
                </p>
              }
            </div>
          );
        },
        isCheck: true,
      },
      {
        Header: "Submit Status",
        accessor: "submitStatus",
        Cell: (props) => {
          const item = props?.row?.original;
          return (
            <p className="text-sm">
              <p>
                {item.rejectedBy === "Vendor" || item.vendorAgreedOn
                  ? item.vendorAgreed
                    ? "Accepted"
                    : "Rejected"
                  : "-"}
              </p>
              <p>
                {item.rejectedBy === "User" || item.userAgreedOn
                  ? item.userAgreed
                    ? "Accepted"
                    : "Rejected"
                  : "-"}
              </p>
            </p>
          );
        },
        isCheck: true,
      },
      {
        Header: "Submitted Dates",
        accessor: "submittedDates",
        Cell: (props) => {
          const item = props?.value;
          return (
            <p className="text-sm">
              <p>
                {item.vendorSubmittedDate
                  ? moment(item.vendorSubmittedDate).format("DD-MMM-YYYY HH:mm")
                  : "-"}
              </p>
              <p>
                {item.customerSubmittedDate
                  ? moment(item.customerSubmittedDate).format("DD-MMM-YYYY HH:mm")
                  : "-"}
              </p>
            </p>
          );
        },
        isCheck: true,
      },
      {
        Header: "Approved Dates",
        accessor: "approvedDates",
        Cell: (props) => {
          const item = props?.row?.original;
          return (
            <p className="text-sm">
              <p>
                {item.vendorAgreed
                  ? moment(item.vendorAgreedOn).format("DD-MMM-YYYY HH:mm")
                  : "-"}
              </p>
              <p>
                {item.userAgreed
                  ? moment(item.userAgreedOn).format("DD-MMM-YYYY HH:mm")
                  : "-"}
              </p>
            </p>
          );
        },
        isCheck: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updatedData]
  );

  const columnsRefund = useMemo(
    () => [
      {
        Header: "Order No",
        accessor: "orderno",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "To Return Item",
        accessor: "tri",
        Cell: (props) => <p className="text-sm">{props.value ? "Yes" : "No"}</p>,
        isCheck: true,
      },
      {
        Header: "Return Ref",
        accessor: "returnrefno",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Item Description",
        accessor: "itemdesc",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Return To",
        accessor: "returnto",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isDisable: false,
        isCheck: true,
      },
      {
        Header: "Items Received",
        accessor: "itemsrcvd",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Items Received Date & Time",
        accessor: "rcvddandt",
        Cell: (props) => (
          <p className="text-sm">
            {props?.value
              ? moment(props.value).format("DD-MMM-YYYY, HH:mm")
              : null}
          </p>
        ),
        isCheck: true,
      },
      {
        Header: "Receiver",
        accessor: "rrnumber",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Vendor Comments",
        accessor: "vendorcomments",
        Cell: (props) => <p className="text-sm ellipsis" onClick={() => props.value ? setCommentModal({ commentBy: 'Vendor', comment: props.value }) : {}}>{props.value}</p>,
        isCheck: true,
      },
      // {
      //   Header: "Action",
      //   accessor: "id",
      //   isCheck: true,
      //   show: true,
      //   Cell: (props) => {
      //     const item = props?.row?.original;
      //     return (
      //       <p
      //         onClick={() => markItemsAsReceived(item.id)}
      //         className={`underline cursor-pointer text-sm`}
      //       >
      //         Mark Items as Received
      //       </p>
      //     );
      //   },
      // },
    ],
    []
  );

  const columnsReturn = useMemo(
    () => [
      {
        Header: "Order No",
        accessor: "orderno",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Vendor Payment to Platform",
        accessor: "receiptstoplatform",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Vendor Refund Doc",
        accessor: "attachcreditnote",
        Cell: (props) => (
          <div className="flex flex-col justify-center">
            {props.value?.map((f) => (
              // eslint-disable-next-line
              <a
                key={f}
                href={FILE_URL + f}
                target="_blank"
                className="underline text-sm text-bg-primary"
              >
                {f}
              </a>
            ))}
          </div>
        ),
        isCheck: true,
      },
      {
        Header: "Refund Ref",
        accessor: "refundreference",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Refund Method",
        accessor: "refundMethod",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Platform Refund Timestamp",
        accessor: "prtimestamp",
        Cell: (props) => <p className="text-sm">{props?.value}</p>,
        isCheck: true,
      },
      {
        Header: "Stripe Refund Timestamp",
        accessor: "srtimestamp",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Action",
        accessor: "id",
        isCheck: true,
        show: true,
        Cell: (props) => {
          const item = props?.row?.original;
          const isReadyForRefund = item.tri && !item.itemsReceived;
          return (
            <div className="flex items-center gap-4">
              <p
                onClick={() => (isReadyForRefund ? {} : setRefundItem(item))}
                className={`${isReadyForRefund ? "" : "underline cursor-pointer"} text-sm`}
              >
                {isReadyForRefund
                  ? "Waiting for items to be received"
                  : "Initiate Refund"}
              </p>
              {item.itemsReceived &&
                <p
                  onClick={() => handleSaveData(item, '', { itemsReceived: false, itemsReceivedReferenceNo: '', itemsReceivedOn: '' })}
                  className={`${isReadyForRefund ? "" : "underline cursor-pointer"} text-sm`}
                >
                  Mark items as unreceived
                </p>
              }
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const refundedColumns = useMemo(
    () => [
      {
        Header: "Order No",
        accessor: "orderno",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Vendor ID",
        accessor: "vendorId",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Vendor Name",
        accessor: "vendorName",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Order Date",
        accessor: "orderdate",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Cancel Submitted Date",
        accessor: "cancelSubmittedDate",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Cancel Reason",
        accessor: "cancelreason",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Cancel Reason Details",
        accessor: "canceldetails",
        Cell: (props) => {
          const reasonDetails = props.value;
          const truncatedDescription = reasonDetails.length > 30 ? reasonDetails.substring(0, 30) + "....." : reasonDetails;
          const handleViewReasonDetails = () => {
            setSelectedReasonDetails(reasonDetails);
            setOpenModalReasonDetails(true);
          };
          return (
            <div className="flex items-center space-x-2">
              {reasonDetails.length > 30 ? (
                <p
                  onClick={handleViewReasonDetails}
                  className="cursor-pointer hover:text-grey-500"
                >
                  {truncatedDescription}
                </p>
              ) : (
                <p>{truncatedDescription}</p>
              )}
            </div>
          );
        },
        // Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Vendor Attach Files",
        accessor: "vendorAttachfiles",
        Cell: (props) => (
          <div className="flex flex-col justify-center items-center">
            {props.value?.map((f) => (
              // eslint-disable-next-line
              <a
                key={f}
                href={FILE_URL + f}
                target="_blank"
                className="underline text-base text-bg-primary"
              >
                {f}
              </a>
            ))}
          </div>
        ),
        isCheck: true,
      },
      {
        Header: "Customer Attach Files",
        accessor: "customerAttachfiles",
        Cell: (props) => (
          <div className="flex flex-col justify-center items-center">
            {props.value?.map((f) => (
              // eslint-disable-next-line
              <a
                key={f}
                href={FILE_URL + f}
                target="_blank"
                className="underline text-base text-bg-primary"
              >
                {f}
              </a>
            ))}
          </div>
        ),
        isCheck: true,
      },
      {
        Header: "Submitted By",
        accessor: "submittedBy",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Refund Date Allowable",
        accessor: "refundDate",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Approvers",
        accessor: "vendorAgreedOn",
        Cell: (props) => (
          <>
            <p className="text-sm">Vendor</p>
            <p className="text-sm">Customer</p>
            <p className="text-sm">Platform Admin</p>
          </>
        ),
        isCheck: true,
      },
      {
        Header: "Approved Dates",
        accessor: "customerAgreedOn",
        Cell: (props) => {
          const item = props?.row?.original;
          return (
            <p className="text-sm">
              <p>
                {item.vendorAgreedOn
                  ? moment(item.vendorAgreedOn).format("DD-MMM-YYYY HH:mm")
                  : "-"}
              </p>
              <p>
                {item.customerAgreedOn
                  ? moment(item.customerAgreedOn).format("DD-MMM-YYYY HH:mm")
                  : "-"}
              </p>
              <p>
                {item.adminAgreedOn
                  ? moment(item.adminAgreedOn).format("DD-MMM-YYYY HH:mm")
                  : "-"}
              </p>
            </p>
          )
        },
        isCheck: true,
      },
      {
        Header: "Platform Comments",
        accessor: "platformcomments",
        isCheck: true,
        Cell: (props) => <p className="text-sm ellipsis" onClick={() => props.value ? setCommentModal({ commentBy: 'Platform', comment: props.value }) : {}}>{props.value}</p>,
      },
      {
        Header: "Vendor Comments",
        accessor: "vendorComments",
        isCheck: true,
        Cell: (props) => <p className="text-sm ellipsis" onClick={() => props.value ? setCommentModal({ commentBy: 'Vendor', comment: props.value }) : {}}>{props.value}</p>,
      },
      {
        Header: "Customer Comments",
        accessor: "customerComments",
        isCheck: true,
        Cell: (props) => <p className="text-sm ellipsis" onClick={() => props.value ? setCommentModal({ commentBy: 'Customer', comment: props.value }) : {}}>{props.value}</p>,
      },
      {
        Header: "To Return Item",
        accessor: "tri",
        Cell: (props) => <p className="text-sm">{props.value ? "Yes" : "No"}</p>,
        isCheck: true,
      },
      {
        Header: "Return Ref",
        accessor: "returnrefno",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Item Description",
        accessor: "itemdesc",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Return To",
        accessor: "returnto",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isDisable: false,
        isCheck: true,
      },
      {
        Header: "Items Received",
        accessor: "itemsrcvd",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Items Received Date & Time",
        accessor: "rcvddandt",
        Cell: (props) => (
          <p className="text-sm">
            {props?.value
              ? moment(props.value).format("DD-MMM-YYYY, HH:mm")
              : null}
          </p>
        ),
        isCheck: true,
      },
      {
        Header: "Receiver",
        accessor: "rrnumber",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Vendor Agreed Refund",
        accessor: "vendorAgreedRefund",
        Cell: (props) => <p className="text-sm">${FormatPrice(props.value)}</p>,
        isCheck: true,
      },
      {
        Header: "Est. Refund Amt",
        accessor: "estimateRefundAmt",
        Cell: (props) => <p className="text-sm">${FormatPrice(props.value)}</p>,
        isCheck: true,
      },
  
      {
        Header: "Vendor Payout",
        accessor: "vendorpayout",
        Cell: (props) => <p className="text-sm">${FormatPrice(props.value)}</p>,
        isCheck: true,
      },
      {
        Header: "Admin Charge to customer",
        accessor: "admchargetocust",
        Cell: (props) => <p className="text-sm">${FormatPrice(props.value)}</p>,
        isCheck: true,
      },
      {
        Header: "Refunded Amt To Customer",
        accessor: "refundedAmount",
        Cell: (props) => <p className="text-sm">${FormatPrice(props.value)}</p>,
        isCheck: true,
      },
      {
        Header: "Vendor Refund to Platform",
        accessor: "receiptstoplatform",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Vendor Refund Doc",
        accessor: "attachcreditnote",
        Cell: (props) => (
          <div className="flex flex-col justify-center">
            {props.value?.map((f) => (
              // eslint-disable-next-line
              <a
                key={f}
                href={FILE_URL + f}
                target="_blank"
                className="underline text-sm text-bg-primary"
              >
                {f}
              </a>
            ))}
          </div>
        ),
        isCheck: true,
      },
      // {
      //   Header: "Refund Ref",
      //   accessor: "refundreference",
      //   Cell: (props) => <p className="text-sm">{props.value}</p>,
      //   isCheck: true,
      // },
      {
        Header: "Ref Voucher Code",
        accessor: "voucherCoupon",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
  
      {
        Header: "Ref Stripe ID",
        accessor: "stripeId",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Refund Method",
        accessor: "refundMethod",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Platform Refund Timestamp",
        accessor: "prtimestamp",
        Cell: (props) => <p className="text-sm">{props?.value}</p>,
        isCheck: true,
      },
      {
        Header: "Stripe Refund Timestamp",
        accessor: "srtimestamp",
        Cell: (props) => <p className="text-sm">{props.value}</p>,
        isCheck: true,
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: (props) => {
          const item = props?.row?.original;
          return (
            <div className="flex flex-col">
              <p
                onClick={() => !item.stripePayoutId ? payoutToVendor(item?.id) : {}}
                className={`${!item.stripePayoutId ? "underline cursor-pointer" : ""
                  } text-sm text-bg-primary`}
              >
                {!item.stripePayoutId
                  ? "Payout to vendor"
                  : `Payout - SGD $${item.stripePayoutAmt} - ${item.stripePayoutDate} - ${item.stripePayoutId}`}
              </p>
              <p
                onClick={() => !item.stripeTransferId ? transferToPlatform(item?.id) : {}}
                className={`${!item.stripeTransferId ? "underline cursor-pointer" : ""
                  } text-sm text-bg-primary`}
              >
                {!item.stripeTransferId
                  ? "Transfer to platform"
                  : `Transferred - SGD $${item.stripeTransferAmt} - ${item.stripeTransferDate} - ${item.stripeTransferId}`}
              </p>
              
            </div>
          );
        },
        isCheck: true,
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div>
      <SectionTitle title="Overview" subtitle="Cancel Order" />
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<BsChevronDown />} aria-controls="cashflow-content" id="cashflow-header" className={`font-medium text-xl`} >
          Cancel Requests
        </AccordionSummary>
        <AccordionDetails>
          <Datatable
            setIds={setSelectedRows}
            columns={columnsCashFlow}
            data={cashflowData}
            setTableRecord={(limit) => onSelect("CANCEL_REQUESTS", limit)}
            tableSize={cashflowLimit}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<BsChevronDown />} aria-controls="cancellation-content" id="cancellation-header" className={`font-medium text-xl`} >
          Cancellation Working Section
        </AccordionSummary>
        <AccordionDetails>
          <Datatable
            selectedRows={selectedRows}
            columns={columnsCancellation}
            data={cancelData}
            setTableRecord={(limit) => onSelect("WORKING_SECTION", limit)}
            tableSize={cancelLimit}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<BsChevronDown />} aria-controls="vendor-return-content" id="vendor-return-header" className={`font-medium text-xl`} >
          Vendor to update Returned items
        </AccordionSummary>
        <AccordionDetails>
          <Datatable
            selectedRows={selectedRows}
            columns={columnsRefund}
            data={refundAmount}
            setTableRecord={(limit) => onSelect("ITEMS_RECEIVED", limit)}
            tableSize={refundAmountLimit}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<BsChevronDown />} aria-controls="refunds-content" id="refunds-header" className={`font-medium text-xl`} >
          Refunds
        </AccordionSummary>
        <AccordionDetails>
          <Datatable
            selectedRows={selectedRows}
            columns={columnsReturn}
            data={userVenAppData}
            setTableRecord={(limit) => onSelect("BOTH_AGREED", limit)}
            tableSize={userVenAppLimit}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<BsChevronDown />} aria-controls="refunded-content" id="refunded-header" className={`font-medium text-xl`} >
          Refunded Orders
        </AccordionSummary>
        <AccordionDetails>
          <Datatable
            columns={refundedColumns}
            data={refundedOrders}
            setTableRecord={(limit) => onSelect("REFUNDED_ORDERS", limit)}
            tableSize={refundedOrdersLimit}
          />
        </AccordionDetails>
      </Accordion>

      {refundItem && (
        <RefundModal
          refundItem={refundItem}
          handleRefund={() => {
            getCanceldataList(cancelLimit);
            getApprovedOrders();
            setRefundItem(null);
          }}
          handleClose={() => setRefundItem(null)}
        />
      )}

      {commentModal?.comment &&
        <CommentsModal
          open={true}
          handleClose={() => setCommentModal(null)}
          commentBy={commentModal.commentBy}
          comment={commentModal.comment}
        />
      }

      <ModalReviewDescription
        title={"Cancel Reason Details"}
        body={selectedReasonDetails}
        open={openModalReasonDetails}
        setOpen={setOpenModalReasonDetails}
      />
    </div>
  );
};

export default TableOrder;
