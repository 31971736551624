import { Modal } from "@mui/material";
import React, { useState } from "react";
import { GrClose } from "react-icons/gr";
import { useOutsideClick } from "../../../hooks/OutsideClick";
import SelectArrayItem from "../SelectArrayItem";
import SelectDate from "../SelectDate";
import InvitePerson from "./invitePerson";
import TypeOfMeeting from "./typeOfMeeting";

const CreateCalendarEvent = ({
  updateAppointment,
  eventForm,
  setEventForm,
  isOpen,
  setIsOpen,
}) => {
  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="flex justify-center items-center w-full"
      >
        <div
          style={{ height: "80vh" }}
          className="bg-white flex flex-col justify-between py-3 rounded-md md:w-1/2 w-full"
        >
          <div className="w-full px-5 pb-2 flex items-center justify-between border-b border-b-gray-200">
            <p className="font-worksans-semibold text-2xl">Appointment</p>
            <GrClose
              size={25}
              className="cursor-pointer"
              onClick={() => setIsOpen(false)}
            />
          </div>
          <div className="w-ful overflow-y-auto text-xl px-5">
            <div className="mt-6 w-full flex flex-col">
              <div className="mt-5">
                <p>Title</p>
                <input
                  onChange={(e) =>
                    setEventForm({ ...eventForm, topic: e.target.value })
                  }
                  value={eventForm.topic}
                  type="text"
                  className="w-full py-2 px-2 rounded-lg border-2 outline-none"
                />
              </div>
              <div className="mt-5">
                <p>Description</p>
                <textarea
                  onChange={(e) =>
                    setEventForm({ ...eventForm, description: e.target.value })
                  }
                  value={eventForm.description}
                  type="text"
                  className="w-full py-2 px-2 rounded-lg border-2 outline-none"
                  rows={3}
                />
              </div>
              <div className="flex w-full items-center space-x-2 mt-5">
                <div className="w-full">
                  <p>Date</p>
                  <SelectDate
                    onChange={(e) => setEventForm({ ...eventForm, date: e })}
                    value={eventForm.date}
                  />
                </div>
                <div className="w-full">
                  <p>Start Time</p>
                  <input
                    onChange={(e) =>
                      setEventForm({ ...eventForm, startTime: e.target.value })
                    }
                    value={eventForm.startTime}
                    type="time"
                    className="w-full py-2 px-2 rounded-lg border-2 outline-none"
                  />
                </div>
                <div className="w-full">
                  <p>End Time</p>
                  <input
                    onChange={(e) =>
                      setEventForm({ ...eventForm, endTime: e.target.value })
                    }
                    value={eventForm.endTime}
                    type="time"
                    className="w-full py-2 px-2 rounded-lg border-2 outline-none"
                  />
                </div>
              </div>
              <div className="mt-5">
                <p>Reminders</p>
                <div className="flex items-center space-x-2">
                  <div className="flex space-x-2 w-full">
                    <input
                      type="number"
                      onChange={(e) =>
                        setEventForm({ ...eventForm, reminder: e.target.value })
                      }
                      value={eventForm.value}
                      className="w-full py-2 px-2 rounded-lg border-2 outline-none"
                    />
                    <SelectArrayItem
                      placeholder="Minutes"
                      option={["Minutes", "Hours", "Days"]}
                      customStyle="w-full py-2 px-2 rounded-lg border-2 outline-none"
                      value={eventForm.type}
                      onChange={(e) => setEventForm({ ...eventForm, type: e })}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-10">
                <TypeOfMeeting
                  eventForm={eventForm}
                  setEventForm={setEventForm}
                />
              </div>
              <div className="mt-10">
                <p>Invite</p>
                <div className="flex flex-col gap-y-2">
                  <InvitePerson
                    eventForm={eventForm}
                    setEventForm={setEventForm}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="px-5 pt-2 w-full flex items-center justify-between border-t border-t-gray-200">
            <p className="text-xl">Actions:</p>
            <div className="flex gap-x-2">
              <ButtonAction btnText="Accept" />
              <ButtonAction btnText="Tentative" />
              <ButtonAction btnText="Decline" />
              <button className="bg-bg-primary text-white text-xl rounded-md py-1 px-5 hover:opacity-70 transition-all">
                Propose New Time
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const ButtonAction = ({ btnText }) => {
  const [openAction, setOpenAction] = useState(false);
  const ref = useOutsideClick(() => setOpenAction(false));

  return (
    <div ref={ref} className="relative">
      {openAction && (
        <div className="absolute -top-10 right-28 w-72 bg-gray-50 shadow-xl rounded-md py-1">
          <button className="w-full px-2 py-1 text-left hover:bg-gray-100">
            Edit response before sending
          </button>
          <button className="w-full px-2 py-1 text-left hover:bg-gray-100">
            Send response
          </button>
          <button className="w-full px-2 py-1 text-left hover:bg-gray-100">
            Do not send a response
          </button>
        </div>
      )}
      <button
        onClick={() => setOpenAction(!openAction)}
        className="bg-bg-primary text-white text-xl rounded-md py-1 px-5 hover:opacity-70 transition-all"
      >
        {btnText}
      </button>
    </div>
  );
};

export default CreateCalendarEvent;
