import React, { useState } from "react";
import { HiOutlineTrash } from "react-icons/hi";
import { BsInfoCircle } from "react-icons/bs";

const NationalHolidays = () => {
  const [venue, setVenue] = useState([
    {
      venue: "",
      address: "",
    },
  ]);

  const addVenue = () => {
    setVenue([
      ...venue,
      {
        venue: "",
        address: "",
      },
    ]);
  };

  const removeHoliday = (index) => {
    let _venue = [...venue];
    _venue.splice(index, 1);
    setVenue(_venue);
  };

  const handleVenue = (key, value, index) => {
    let _venue = [...venue];
    _venue[index][key] = value;
    setVenue(_venue);
  };

  return (
    <div>
      <div className="w-full flex justify-between items-center">
        <div className="flex items-center space-x-3">
          <span
            className="flex items-center space-x-2 cursor-pointer"
            title="Please check for time availability if you have to travel to another location for the meeting."
          >
            <p className="font-worksans-semibold">
              Specify Physical Meeting Locations
            </p>
            <BsInfoCircle />
          </span>
        </div>
        <button
          onClick={() => addVenue()}
          className="py-1 px-4 rounded-md bg-bg-primary text-lg text-white hover:opacity-70"
        >
          Add Venue
        </button>
      </div>
      {venue.map((item, index) => {
        return (
          <div key={index} className="mt-3 w-full">
            <div className="w-full flex items-center space-x-2">
              <div className="flex items-center gap-x-2 w-full">
                <div className="w-[40%]">
                  <p>Venue</p>
                  <input
                    onChange={(e) =>
                      handleVenue("venue", e.target.value, index)
                    }
                    type="text"
                    className="w-full py-2 text-base px-2 rounded-lg border-2 outline-none"
                  />
                </div>
                <div className="w-[60%]">
                  <p>Address</p>
                  <input
                    onChange={(e) =>
                      handleVenue("address", e.target.value, index)
                    }
                    type="text"
                    className="w-full py-2 text-base px-2 rounded-lg border-2 outline-none"
                  />
                </div>
              </div>
              <div
                onClick={() => removeHoliday(index)}
                className="bg-bg-primary p-2 rounded-md text-white mt-8 cursor-pointer hover:opacity-70"
              >
                <HiOutlineTrash size={20} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default NationalHolidays;
