import React from 'react';
import axios from "axios";
import { formatCurrency } from "../../functions/numbers";
import { url, API_Dashboard } from "../../apis/constant";
import Datatable from "../datatable";
import moment from 'moment';
import { getOrderStatus } from '../../utils/constants';

const OpenOrder = ({ openOrderStatus, startDate, endDate, openOrderOrder, openOrderOrderBy }) => {
  const [apiData, setApiData] = React.useState([]);
  const [limit, setLimit] = React.useState(10);

  // Define your columns for the datatable here
  const columns = [
    {
      Header: "Order No",
      accessor: "orderId",
    },
    {
      Header: "Vendor Name",
      accessor: "vendorName",
    },
    {
      Header: "Vendor ID",
      accessor: "vendorId",
    },
    {
      Header: "Total",
      accessor: "totalAmount",
      Cell: (props) => formatCurrency(props?.value ?? 0)
    },
    {
      Header: "Order Date",
      accessor: "orderDate",
      Cell: (props) => moment(props?.value).format('DD-MMM-YYYY')
    },
    {
      Header: "Expected Delivery Date",
      accessor: "expectedDeliveryDate",
      Cell: (props) => moment(props?.value).format('DD-MMM-YYYY')
    },
    {
      Header: "Order Status",
      accessor: "orderStatus",
      Cell: (props) => getOrderStatus(props?.value ?? 0)
    },
  ];

  React.useEffect(() => {
    const adminToken = localStorage.getItem("wed_admin_token");
    try {
      axios.get(url + API_Dashboard.openOrder, {
        params: { status: openOrderStatus, startDate, endDate, order: openOrderOrder, orderBy: openOrderOrderBy },
        headers: {
          Authorization: `Bearer ${adminToken}`
        }
      }).then(res => {
        setApiData(res.data.data);
      }).catch(err => {
        console.log(err);
      })
    } catch (error) {
      console.log(error);
    }
  }, [openOrderStatus, startDate, endDate, openOrderOrder, openOrderOrderBy]);

  return (
    <div className="p-2 bg-white border rounded-md text-xl table-responsive">
      <Datatable
        columns={columns}
        data={apiData}
        showResponseData={false}
        showSelection={false}
        tableSize={limit}
        setTableSize={setLimit}
      />
    </div>
  );
}

export default OpenOrder;
