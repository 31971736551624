import { useEffect, useState } from "react";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { insertSelectedCategory } from "../../reducers/categories";
import Banner1 from '../../images/No-Image-Placeholder.png'
import { getPreviewUrl } from "../../utils/common";

const MegaMenu = ({ onClose, height = "max-h-50vh", addPy = "mt-4", onSelect, showLvl4 = true }) => {
  const dispatch = useDispatch();
  const categoryListApi = useSelector((state) => state.categories.categoryList);
  const [levelOneIndex, setLevelOneIndex] = useState(-1);
  const [levelTwoIndex, setLevelTwoIndex] = useState(-1);
  const [levelThreeIndex, setLevelThreeIndex] = useState(-1);
  const [hover, setHover] = useState(null);

  useEffect(() => {
    setLevelTwoIndex(-1);
    setLevelThreeIndex(-1);
  }, [levelOneIndex]);

  useEffect(() => {
    setLevelThreeIndex(-1);
  }, [levelTwoIndex]);

  const selectCategory = (category, productType = null) => {
    if (category) {
      dispatch(insertSelectedCategory(category));
      onClose();
      if (onSelect && productType) {
        onSelect({ categorySelected: category, selectedProductType: productType });
      }
    }
  };

  return (
    <div className={`w-full ${height} mega-menu-wrap overflow-y-auto ${addPy} bg-white`}>
      {categoryListApi?.map((item, index) => (
        <div key={index} onMouseEnter={() => setLevelOneIndex(index)} className={"hover:bg-grey-200 px-2 py-2 border-b-1 cursor-pointer flex justify-between items-center " + (levelOneIndex === index ? "bg-grey-200" : "")} >
          <p
            className="w-full text-bg-blue-500"
            onMouseEnter={() => {
              let _category = { id: item._id, name: item.name, categories: null };
              setHover(_category);
            }}
            onClick={() => {
              let _category = { id: item._id, name: item.name, categories: null };
              selectCategory(_category, null);
            }}
          >
            {item.name}
          </p>
          {(item.categories.length > 0 || item.productTypes?.length > 0) && <AiOutlineDoubleRight size={12} className="text-bg-blue-500" />}
          {levelOneIndex !== -1 && levelOneIndex === index && categoryListApi[levelOneIndex].categories.length > 0 && (
            <div className={`w-full absolute h-full left-full top-0`}>
              {categoryListApi[levelOneIndex].categories.map((lv1, lv1Index) => (
                <div key={lv1Index} onMouseEnter={() => setLevelTwoIndex(lv1Index)} className={"hover:bg-grey-200 bg-white px-2 py-2 border-b-1 cursor-pointer flex justify-between items-center " + (levelTwoIndex === lv1Index ? "bg-grey-200" : "")} >
                  <p
                    className="text-bg-blue-500 w-full"
                    onMouseEnter={() => {
                      let _categorylv1 = { id: item._id, name: item.name, categories: { id: lv1._id, name: lv1.name, "sub-categories": {}, }, };
                      setHover(_categorylv1);
                    }}
                    onClick={() => {
                      let _categorylv1 = { id: item._id, name: item.name, categories: { id: lv1._id, name: lv1.name, "sub-categories": null, }, };
                      selectCategory(_categorylv1, null);
                    }}
                  >
                    {lv1.name}
                  </p>
                  {lv1["sub-categories"].length > 0 && <AiOutlineDoubleRight size={12} className="text-bg-blue-500" />}

                  {levelTwoIndex !== -1 && levelTwoIndex === lv1Index && categoryListApi[levelOneIndex].categories[levelTwoIndex]?.["sub-categories"].length > 0 && (
                    <div className={`w-full absolute h-full left-full top-0`} >
                      {categoryListApi[levelOneIndex].categories[levelTwoIndex]?.["sub-categories"].map((lv2, lv2Index) => (
                        <div key={lv2Index} onMouseEnter={() => setLevelThreeIndex(lv2Index)} className={"hover:bg-grey-200 bg-white px-2 py-2 border-b-1 cursor-pointer flex justify-between items-center " + (levelThreeIndex === lv2Index ? "bg-grey-200" : "")} >
                          <p
                            className=" text-bg-blue-500 w-full"
                            onMouseEnter={() => {
                              let _categorylv2 = { id: item._id, name: item.name, categories: { id: lv1._id, name: lv1.name, "sub-categories": { id: lv2._id, name: lv2.name, "sub-sub-categories": null }, }, };
                              setHover(_categorylv2);
                            }}
                            onClick={() => {
                              let _categorylv2 = { id: item._id, name: item.name, categories: { id: lv1._id, name: lv1.name, "sub-categories": { id: lv2._id, name: lv2.name, "sub-sub-categories": null }, }, };
                              selectCategory(_categorylv2, null);
                            }}
                          >
                            {lv2.name}
                          </p>
                          {lv2["productTypes"]?.length > 0 && showLvl4 && <AiOutlineDoubleRight size={12} className="text-bg-blue-500" />}
                        </div>
                      ))}
                      {levelThreeIndex !== -1 && showLvl4 && categoryListApi[levelOneIndex].categories[levelTwoIndex]?.["sub-categories"][levelThreeIndex]?.["productTypes"]?.length > 0 && (
                        <div className="w-500px absolute h-full left-full top-0">
                          <div className="p-5 bg-white border-1">
                            <div className="grid grid-cols-3 gap-2">
                              {categoryListApi[levelOneIndex].categories[levelTwoIndex]?.["sub-categories"][levelThreeIndex]?.["productTypes"].map((lvl4, index) => (
                                <div
                                  key={index}
                                  onClick={() => {
                                    const _category = { ...hover, categories: { ...hover.categories, "sub-categories": { ...hover.categories["sub-categories"], "sub-sub-categories": { id: lvl4._id, name: lvl4.name }, }, }, };
                                    selectCategory(_category, lvl4._id);
                                    setHover(null);
                                  }}
                                  className="flex items-center flex-col cursor-pointer"
                                >
                                  {lvl4.isImage ? 
                                    <img alt="lvl4" src={getPreviewUrl(lvl4.image)} className="rounded-md aspect-square object-cover" style={{width: 100, height: 100}} />
                                    :
                                    <img alt="lvl4" src={Banner1} className="rounded-md aspect-square object-cover" style={{width: 100, height: 100}} />
                                  }
                                  <p className="text-bg-blue-500 text-center px-2 mt-3">{lvl4.name}</p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {levelTwoIndex !== -1 && levelTwoIndex === lv1Index && categoryListApi[levelOneIndex].categories[levelTwoIndex]?.productTypes?.length > 0 && (
                    <div className={`w-500px absolute left-full top-0`} >
                      <div className="p-5 bg-white border-1">
                        <div className="grid grid-cols-3 gap-2">
                          {categoryListApi[levelOneIndex].categories[levelTwoIndex]?.productTypes?.map((lv2, lv2Index) => (
                            <div key={lv2Index} onMouseEnter={() => setLevelThreeIndex(lv2Index)} className="w-full flex flex-col justify-start items-center cursor-pointer rounded-md" >
                              <div
                                className="flex items-center flex-col cursor-pointer"
                                onClick={() => {
                                  const _category = { ...hover, categories: { ...hover?.categories, "sub-categories": { ...hover?.categories["sub-categories"], "sub-sub-categories": { id: lv2._id, name: lv2.name }, }, }, };
                                  selectCategory(_category, lv2._id);
                                  setHover(null);
                                }}
                              >
                                {lv2.isImage ?
                                  <img alt="lvl2" src={getPreviewUrl(lv2.image)} className="rounded-md aspect-square object-cover" style={{width: 100, height: 100}} />
                                  :
                                  <img alt="lvl2" src={Banner1} className="rounded-md aspect-square object-cover" style={{width: 100, height: 100}} />
                                }
                                <p className="text-bg-blue-500 text-center px-2 mt-3">{lv2.name}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}

          {(levelOneIndex !== -1 && levelOneIndex === index && categoryListApi[levelOneIndex]?.productTypes?.length > 0) && (
            <div className={`w-500px h-full absolute left-full top-0`} onMouseEnter={() => setLevelOneIndex(levelOneIndex)} >
              <div className="p-5 bg-white border-1">
                <div className="grid grid-cols-3 gap-2">
                  {categoryListApi[levelOneIndex].productTypes?.map((lv2, lv2Index) => (
                    <div key={lv2Index} className="w-full flex flex-col justify-start items-center cursor-pointer rounded-md" >
                      <div
                        className="flex items-center flex-col cursor-pointer"
                        onClick={() => {
                          const _category = { ...hover, categories: { ...hover?.categories, "sub-categories": { "sub-sub-categories": { id: lv2._id, name: lv2.name }, }, }, };
                          selectCategory(_category, lv2._id);
                          setHover(null);
                        }}
                      >
                        {lv2.isImage ?
                          <img alt="lvl2" src={getPreviewUrl(lv2.image)} className="rounded-md aspect-square object-cover" style={{width: 100, height: 100}} />
                          :
                          <img alt="lvl2" src={Banner1} className="rounded-md aspect-square object-cover" style={{width: 100, height: 100}} />
                        }
                        <p className="text-bg-blue-500 text-center px-2 mt-3">{lv2.name}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default MegaMenu;
