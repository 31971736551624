import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';

const MuiDateTimePicker = ({ onChange = () => { }, value = null, label = '' }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
            <DateTimePicker
                renderInput={(props) => <TextField variant='standard' {...props} fullWidth />}
                label={label}
                value={value}
                inputFormat='DD-MM-YYYY hh:mm A'
                onChange={onChange}
            />
        </LocalizationProvider>
    )
}

export default MuiDateTimePicker