/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SectionTitle from "../components/section-title";
import ManageAdmin from "../components/rolePermission";
import Widget from "../components/widget";
import { toast } from "react-toastify";
import Modal2 from "./../components/confirm-delete-modal/modal-2";
import { FiX } from "react-icons/fi";
import ApiClient from "../apis/apiClient";
import {
   API_ListofAllSubAdmin,
API_DeleteSubAdmin,
API_UpdateStatusAdmin,
} from "../apis/constant";
import { Link,Redirect } from "react-router-dom";
import "nprogress/nprogress.css";
import "../css/custome.css";
import { checkAccess } from "../utils/common";
const Simple = () => {
  const [getAdminPermission, setgetAdminPermission] = useState([]);
  const [Loading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const adminToken = localStorage.getItem("wed_admin_token");
  const [paginationObject, setPaginationObject] = useState({});


  const getAllAdmin = async (limit, searchq = "", pageNumber) => {
    const data = "";
    setIsLoading(true);
    // setIsApiCall(true);
    const res = await ApiClient.get(
        API_ListofAllSubAdmin + `?page=${pageNumber}&sortBy=asc&limit=${limit}&searchBy=${searchq}`,
      data,
      `Bearer ${adminToken}`
    );

    if (res.data.code === 200) {
      if (res.data.data && res.data.data.docs.length !== 0) {
        for (let i = 0; i < res.data.data.docs.length; i++) {
          const element = res.data.data.docs[i];
          res.data.data.docs[i]['role'] = element.hasOwnProperty("role")? (element.role !== null ? element.role.name :"") : ""

        }
      }
      setPaginationObject({
        limit: res.data.data.limit,
        page: res.data.data.page,
        pages: res.data.data.pages,
        total: res.data.data.total
      })
      setgetAdminPermission(res.data.data.docs);
      setIsLoading(false);
      // setIsApiCall(false);
    }
  };


  useEffect(() => {
    if (search.length > 2 || search.length === 0) {
      getAllAdmin(10, search, 1);
    }
  }, [search]);

  const handleActive = async (status, data) => {
    let myObj = {
      status: status,
      name: data.name,
    };

    const res = await ApiClient.patch(
        API_UpdateStatusAdmin + "/" + data.id,
      myObj,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllAdmin(10, '', 1);
    } else {
      toast.warning(res.data.message);
    }

  };

  const deleteAdminUsers = async (userData) => {
    const id = userData.original.id;
    const data = "";
    const res = await ApiClient.delete(
        API_DeleteSubAdmin + "/" + id,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllAdmin(10, '', 1);
    } else {
      toast.warning(res.data.message);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: " Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Role",
        accessor: "role",
      },
    
      {
        Header: "Status",
        id: "status",
        accessor: (str) => {
          return str["status"] === 1 ? (
            <label >Active</label>
          ) : (
            <label >Inactive</label>
          );
        },
      },

      {
        Header: "Action",
        id: "delete",
        accessor: (str) => {
        },

        Cell: (Datatable) => (
          <>
            {checkAccess(2, "edit") && <Link
              className="underLineText"
              style={{ cursor: "pointer" }}
              to={`/editAdminUser/${Datatable.row.original.id}`}
            >
              Edit
            </Link>} |{" "}
            {checkAccess(2, "delete") && <Modal2
              title="Delete"
              icon={
                <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                  <FiX size={18} className="stroke-current text-red-500" />
                </span>
              }
              body={
                <div className="text-sm text-grey-500">
                  Are You Sure! You want to delete this Sub-Admin?
                </div>
              }
              buttonTitle="Confirm"
              onClick={() => {
                deleteAdminUsers(Datatable.row);
              }}
               buttonClassName="underLineText"
            /> }{" "}|
            {" "}            

            {Datatable.cell.row.original.status === 1 ? (
              checkAccess(2, "edit") && <Modal2
                title="Deactivate"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to Active this permission?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  handleActive(0, Datatable.cell.row.original);
                }}
                 buttonClassName="underLineText"
              />
            ) : (
              checkAccess(2, "edit") && <Modal2
                title="Activate"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
              
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to Deactivate this permission?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  handleActive(1, Datatable.cell.row.original);
                }}
                 buttonClassName="underLineText"
              />
            )}
          </>
        ),
      },
    ],
    []
  );

  const getPageData = (data) => {
    getAllAdmin(data, '', 1);
  };

  const data = React.useMemo(() => getAdminPermission, [getAdminPermission]);

  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
    return (
      <>
        <div >
          <input
          className="searchCss"
            type="text"
            placeholder="Search by name, email"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <ManageAdmin
          columns={columns}
          data={data}
          loading={Loading}
          onSubmit={getPageData}
          showSearch={true}
          pagination={paginationObject}
          getAllAdmin={getAllAdmin}
        />
      </>
    );
}
};

const Permission = () => (
  <>
    <div>
      {checkAccess(2, "add") &&<Link
        to={"/admin/addSubAdmin"}
        className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded addRoleCss"
      >
        Add New Admin
      </Link>}
    </div>
    <SectionTitle title="Manage Admin" subtitle="Manage Admin" />
    <Widget>
      <Simple />
    </Widget>
  </>
);
export default Permission;
