import React from 'react';

import axios from "axios";

import { formatCurrency } from "../../functions/numbers";
import { url, API_Dashboard } from "../../apis/constant";
import Datatable from "../datatable";
import Modal from "../modals/Modal";

const Sales = ({startDate, endDate}) => {
  const [apiData, setApiData] = React.useState([]);
  const [orderDetails, setOrderDetails] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);

  const [detailColumns] = React.useState([
    {
      Header: "Order ID",
      accessor: "id",
    },
    {
      Header: "Net Sales",
      accessor: "netSales",
    },
  ]);

  const columnsSales = [
    {
      Header: "",
      accessor: "salesType",
    },
    {
      Header: "Total Net Sales",
      accessor: "totalNetSales",
      Cell: (props) => {
        return <span onClick={() => {
          setShowModal(true);
          setOrderDetails(
            props.row.original.orderDetails.map((item) => {
              return {
                id: item.id,
                netSales: formatCurrency(item.netSales),
              }
            }
            ))
        }}
          className="text-blue-500 hover:text-blue-700 cursor-pointer">{props.value}</span>;
      },
    },
    {
      Header: "Total Orders",
      accessor: "totalOrders",
      Cell: (props) => {
        return <span onClick={() => {
          setShowModal(true);
          setOrderDetails(
            props.row.original.orderDetails.map((item) => {
              return {
                id: item.id,
                netSales: formatCurrency(item.netSales),
              }
            }
            ))
        }}
          className="text-blue-500 hover:text-blue-700 cursor-pointer">{props.value}</span>;
      },
    },
    {
      Header: "Ave Purchase",
      accessor: "avePurchase",
      Cell: (props) => {
        return <span onClick={() => {
          setShowModal(true);
          setOrderDetails(
            props.row.original.orderDetails.map((item) => {
              return {
                id: item.id,
                netSales: formatCurrency(item.netSales),
              }
            }
            ))
        }}
          className="text-blue-500 hover:text-blue-700 cursor-pointer">{props.value}</span>;
      },
    },
  ];

  React.useEffect(() => {
    const adminToken = localStorage.getItem("wed_admin_token");
    try {
      axios.get(url + API_Dashboard.sales, {
        params: {startDate, endDate},
        headers: {
          Authorization: `Bearer ${adminToken}`
        }
      }).then(res => {
        const data = res.data.data;
        setApiData(
          [
            {
              'salesType': 'All',
              'totalNetSales': formatCurrency(data.all.totalNetSales),
              'totalOrders': data.all.totalCountOrders,
              'avePurchase': formatCurrency(data.all.averageNetSales),
              'orderDetails': data.all.orderDetails,
            },
            {
              'salesType': 'Completed',
              'totalNetSales': formatCurrency(data.completed.totalNetSales),
              'totalOrders': data.completed.totalCountOrders,
              'avePurchase': formatCurrency(data.completed.averageNetSales),
              'orderDetails': data.completed.orderDetails,
            },
            {
              'salesType': 'Cancelled',
              'totalNetSales': formatCurrency(data.cancelled.totalNetSales),
              'totalOrders': data.cancelled.totalCountOrders,
              'avePurchase': formatCurrency(data.cancelled.averageNetSales),
              'orderDetails': data.cancelled.orderDetails,
            }
          ]
        )
      }).catch(err => {
        console.log(err);
      })
    } catch (error) {
      console.log(error);
    }
  }, [startDate, endDate]);

  return (
    <div className="p-2 bg-white border rounded-md text-xl">
      <Datatable columns={columnsSales}
        data={apiData}
        onCheck={(data) => { }}
        showResponseData={false}
        showPagination={false}
        showSelection={false}
      />
      {showModal && (
        <Modal title="Order Details"
          onClose={() => setShowModal(false)}
          data={orderDetails}
        >
          {(currentItems) => (
            <div style={{ maxHeight: '400px', overflowY: 'auto', width: '100%' }}>
              <table className="min-w-full divide-y divide-gray-200 w-full">
                <thead className="bg-gray-50 sticky top-0 z-10 bg-white">
                  {detailColumns.map((column, index) => (
                    <th key={index} scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky top-0 z-10 bg-white">
                      {column.Header}
                    </th>
                  ))}
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {currentItems.map((item, index) => (
                    <tr key={index}>
                      {detailColumns.map((column, colIndex) => (
                        <td key={colIndex} className="px-6 py-4 whitespace-nowrap">
                          {item[column.accessor]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </Modal>
      )}
    </div>
  );
}

export default Sales;

