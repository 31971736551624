import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useOutsideClick } from "../../hooks/OutsideClick";
import axios from "axios";
import { API_ChangeProductRisk, url } from "../../apis/constant";
import { toast } from "react-toastify";
import { getPreviewUrl } from "../../utils/common";

const PRODUCT_STATUS = {
  0: "Draft",
  1: "Active",
  2: "Pending",
  3: "Delete",
  4: "Public",
};

const DropDown = (props) => {
  const { value, options, enabled, productId } = props;
  const [isOpen, setIsOpen] = useState(false);
  const ref = useOutsideClick(() => setIsOpen(false));
  const [valueTemp, setValueTemp] = useState(value);
  const adminToken = localStorage.getItem("wed_admin_token");

  useEffect(() => {
    setValueTemp(options[value]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onSelect = (val) => {
    const data = { riskType: val } 
    axios
      .patch(url + API_ChangeProductRisk + productId, data, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((res) => {
        if (res.data.code === 200) {
          toast.success(res.data.message);
          
        } else if (res.data.code === 400) {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }

  return (
    <div ref={ref} className="relative">
      <div
        onClick={() => enabled && setIsOpen(!isOpen)}
        className="border border-bg-primary px-3 rounded-sm py-1 cursor-pointer"
      >
        <p>{valueTemp}</p>
      </div>
      {isOpen && (
        <div className="absolute top-10 left-0 w-full bg-white text-black rounded-sm border border-bg-eunry px-3 py-1">
          {Object.keys(options).map((item, index) => (
            <p
              onClick={() => {
                setValueTemp(options[item]);
                setIsOpen(false);
                onSelect(item);
              }}
              key={index}
              className="cursor-pointer border-b my-1"
            >
              {options[item]}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};
function htmlToText(html) {
  var temp = document.createElement('div');
  temp.innerHTML = html;
  return temp.textContent; // Or return temp.innerText if you need to return only visible text. It's slower.
}
const AddNewProduct = () => {
  const productForm = useSelector((state) => state.productsSlice);

  return (
    <div className="bg-white">
      <div className="flex flex-col mt-5">
        <div className="w-full flex items-center space-x-2">
          <div className="w-1/2">
            <p>Product Title</p>
            <input
              disabled
              value={productForm.product?.productTitle}
              type="text"
              className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
            />
          </div>
          <div className="w-1/2">
            <p>Risk Type</p>
            <DropDown enabled={productForm.product?.custom} productId={productForm.product?._id} value={productForm?.product?.riskType ?? "high"} options={{"high": "High Risk","low": "Low Risk"}} />
          </div>
        </div>
        <div className="mt-5">
          <p className="mb-2">Short Description</p>
          <div className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2 h-56">
            <p>{htmlToText(productForm.product?.productDescription)}</p>
          </div>
        </div>
        <div className="mt-10">
          <div className="flex items-center">
            <p className="mb-2 ">Product Gallery</p>
          </div>

          {productForm.product?.productGallery.length > 0 ? (
            <div className="grid grid-cols-6 gap-1">
              {productForm.product?.productGallery.map((item, index) => (
                <div className="w-56 h-56">
                  <img
                    key={index.toString()}
                    className="w-56 h-56 "
                    src={getPreviewUrl(item)}
                    alt=""
                  />
                </div>
              ))}
            </div>
          ) : (
            <p>There's no image</p>
          )}
        </div>
        <div className="mt-5">
          <p className="mb-2">Detail Product Description</p>
          <div className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2 h-56">
            <p>{htmlToText(productForm.product?.detailProductDescription)}</p>
          </div>
        </div>
        <div className="flex mt-5 space-x-2">
          <div className="w-full space-y-2">
            <p>SKU Code *</p>
            <input
              value={productForm.product?.productSkuCode}
              disabled
              type="text"
              className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
            />
          </div>
          <div className="w-full space-y-2">
            <p>Vendor Name</p>
            <input
              disabled
              value={productForm.product?.vendorName}
              type="text"
              className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
              required
            />
          </div>
          <div className="w-full space-y-2">
            <p>Vendor ID</p>
            <input
              disabled
              value={productForm.product?.vendorId?.vendorId}
              type="text"
              className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
            />
          </div>
        </div>
        <div className="flex mt-5 space-x-2 relative">
          <div className="w-full space-y-2">
            <p>Product Category</p>
            <input
              disabled
              value={`${productForm?.product?.productCategory?.level1?.name} > ${productForm?.product?.productCategory?.level2?.name} > ${productForm?.product?.productCategory?.level3?.name}`}
              type="text"
              className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
            />
          </div>
          <div className="w-full space-y-2">
            <p>Product Type</p>
            <input
              disabled
              value={productForm.product?.productTypeId}
              type="text"
              className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
            />
          </div>
        </div>
        <div className="flex mt-5 space-x-2 relative">
          <div className="w-full space-y-2">
            <p>Product Family</p>
            <input
              disabled
              value={productForm.product?.productFamily}
              type="text"
              className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
            />
          </div>
          <div className="w-full space-y-2">
            <p>Product Status</p>
            <input
              disabled
              value={PRODUCT_STATUS[productForm.product?.status]}
              type="text"
              className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewProduct;
