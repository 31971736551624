/* eslint-disable eqeqeq */
import React from "react";
import { FormatPrice, dateFormat, formatCityText } from "../utils/common";
import { Link } from "react-router-dom";

const OrderDetailsItem = ({ data, urlParams }) => {
  const initialValue = 0;
  const amounts = (data?.paymentMilestones || []).map((item) =>
    Number(item.paymentmilestone[0].amount)
  );
  const totalAmount =
    Array.isArray(amounts) &&
    amounts.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      initialValue
    );
  const nextMilestones = data?.paymentMilestones?.filter(
    (x) => x?.paymentmilestone[0]?.paymentStatus === 0
  );
  const netPayment = (data?.paymentMilestones || []).map((item) =>
    Number(item.paymentmilestone[0].netPaymentDue)
  );
  const totalNetPayment =
    Array.isArray(netPayment) &&
    netPayment.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      initialValue
    );
  const voucherOffset = (data?.paymentMilestones || []).map((item) =>
    Number(item?.paymentmilestone[0].voucherOffset)
  );
  const totalVoucherOffset =
    Array.isArray(voucherOffset) &&
    voucherOffset.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      initialValue
    );
  const changeRequest = data?.changeRequest;
  const approvedLenth = data?.approvedChanges?.length;
  const approvedChanges = approvedLenth
    ? data?.approvedChanges?.[approvedLenth - 1]
    : null;
  const paymentDetails = data?.payment_method ?? null;

  const vendorName =
    data?.vendorDetails?.vendorName ?? data?.vendor?.vendorName;
  const addressText = () => {
    if (data) {
      const dataAddress = data?.vendorDetails ?? data?.vendor;
      const streetAddr = dataAddress?.streetAddress1
        ? `${dataAddress?.streetAddress1}, `
        : "";
      const streetAddr2 = dataAddress?.streetAddress2
        ? `${dataAddress?.streetAddress2}, `
        : "";
      const streetAddr3 = dataAddress?.streetAddress3
        ? `${dataAddress?.streetAddress3}, `
        : "";
      const city = dataAddress?.city ? `${dataAddress?.city}, ` : "";
      const state = dataAddress?.state ? `${dataAddress?.state}, ` : "";
      const zip = dataAddress?.postCode
        ? `${dataAddress?.postCode}${
            dataAddress?.country === "Singapore" ||
            dataAddress?.country === "SG"
              ? ""
              : ", "
          } `
        : "";
      const country = dataAddress?.country ? `${dataAddress?.country}` : "";
      const buildingName = dataAddress?.buildingName
        ? `${dataAddress?.buildingName}, `
        : "";
      const unitNo = dataAddress?.unitNo ? `${dataAddress?.unitNo}, ` : "";
      const blockNumber = dataAddress?.blockNumber
        ? `Blk ${dataAddress?.blockNumber}, `
        : "";

      let address = (
        <>
          {unitNo}
          {blockNumber}
          {buildingName}
          {streetAddr}
          <br />
          {streetAddr2}
          {streetAddr3}
          {streetAddr2 || streetAddr3 ? <br /> : ""}
        </>
      );
      if (country !== "Singapore" && country !== "SG") {
        address = (
          <>
            {address}
            {city}
            {state}
          </>
        );
      }
      return (
        <>
          {address}
          {zip}
          {country}
        </>
      );
    }
    return "No Address";
  };

  const formatPrice = (amount) => {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  const formatAddress = (addr) => {
    const address = addr;
    return (
      <>
        {address?.unitNumber && `${address.unitNumber}, `}
        {address?.streetAddress2
          ? `${address?.streetAddress}, `
          : `${address?.streetAddress}`}
        <br />
        {address?.streetAddress2
          ? `${address?.streetAddress2}, `
          : address?.streetAddress2}
        {address?.streetAddress3 ? `${address?.streetAddress3},` : ""}
        {address?.streetAddress2 || address?.streetAddress3 ? <br /> : ""}
        {formatCityText(address)}
      </>
    );
  };

  const OrderEditRequest = () => {
    return (
      <>
        {changeRequest && (
          <div className="text-base mt-10">
            <p className="text-[#7c7b7b]">Request Changes:</p>
            <div className="mt-3">
              <p className="text-base">{changeRequest.comment}</p>
              <p className="text-base">
                Requested at: {dateFormat(new Date(changeRequest.commentedOn))}
              </p>
            </div>
          </div>
        )}
        {approvedChanges && !changeRequest && (
          <div className="text-base mt-10">
            <p className="text-[#7c7b7b]">{`Request received from ${
              approvedChanges.requestedBy == "User" ? "Customer" : "Vendor"
            }:`}</p>
            <div className="mt-3">
              <p className="text-base">{approvedChanges.changeComment}</p>
              <p className="text-base">
                {approvedChanges.approveOrderEditRequest
                  ? `Approved by ${
                      approvedChanges.acceptRejectby == "User"
                        ? "Customer"
                        : "Vendor"
                    } at`
                  : `Declined by ${
                      approvedChanges.acceptRejectby == "User"
                        ? "Customer"
                        : "Vendor"
                    } at`}
                : {dateFormat(new Date(approvedChanges.approveChangeOn))}
              </p>
            </div>
          </div>
        )}
        {approvedChanges && !changeRequest && approvedChanges?.rejectReason && (
          <div className="text-base mt-5">
            <p className="text-[#7c7b7b]">Decline Reason:</p>
            <div className="mt-3">
              <p className="text-base">{approvedChanges.rejectReason}</p>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="w-full mt-8">
      <div className="mb-4 text-lg">
        <p className="text-lg font-worksans-semibold">Vendor: {vendorName}</p>
        <p>{addressText()}</p>
        {data?.vatTaxNumber && <p>GST Reg No: {data?.vatTaxNumber}</p>}
      </div>
      <div className="mb-10 w-full">
        <table className="table w-full">
          <thead className="border-b">
            <tr className="font-worksans-semibold text-base">
              <th className="w-10"></th>
              <th className="text-left p-4 w-1/3">PRODUCT</th>
              <th className="text-center p-4">EXPECTED DELIVERY DATE</th>
              <th className="text-left p-4">PRICE($)</th>
              <th className="text-center p-4">ITEM DISCOUNT</th>
              <th className="text-center px-8 p-4">QUANTITY</th>
              <th className="text-left p-4">TOTAL($)</th>
            </tr>
          </thead>
          <tbody>
            {data?.products?.length && (
              <>
                {data?.products.map((item, index) => (
                  <tr
                    className="items-center border-b border-[#AAAAAA] mb-10 font-worksans"
                    key={index}
                  >
                    <td>
                      <p className="text-base">{index + 1}</p>
                    </td>
                    <td className="p-4">
                      <div>
                        <p
                          className="text-base underline cursor-pointer"
                          style={{ letterSpacing: "0px" }}
                        >
                          {item.productTitle}
                        </p>
                        {item.attributes.map((attribute) => (
                          <p key={attribute._id}>{attribute.variation}</p>
                        ))}
                        <p className="text-base">{item.short_description}</p>
                      </div>
                    </td>
                    <td className="p-4 text-base text-center">
                      {dateFormat(item.expectedDelivery)}
                    </td>
                    <td className="p-4 text-base">{`$${FormatPrice(
                      Number(item.itemPrice)
                    )}`}</td>
                    <td className="p-4 text-base text-center">{`$${FormatPrice(
                      Number(item.discount)
                    )}`}</td>
                    <td className="p-4 text-base text-center">
                      {item.quantity}
                    </td>
                    <td className="p-4 text-base text-left">{`$${FormatPrice(
                      Number(item.totalPrice)
                    )}`}</td>
                  </tr>
                ))}
              </>
            )}
            <tr>
              <td colSpan={6} className="pr-4 pt-7">
                <p className="text-[#814242] text-base text-right">
                  Sub Total:
                </p>
              </td>
              <td className="pl-4 pt-7 text-[#4A4949] font-bold text-left">
                <p className="text-base">{`$${formatPrice(
                  parseFloat(data?.subTotalwithoutDiscount)
                )}`}</p>
              </td>
            </tr>
            {data.appliedDiscountId !== null && (
              <tr>
                <td colSpan={6} className="pr-4 py-1">
                  <p className="text-[#814242] text-base text-right">
                    Discount{" "}
                    {data?.discountType === "PERCENT" && (
                      <span className="underline">
                        ({data?.discountPercentage}%)
                      </span>
                    )}
                    :
                  </p>
                </td>
                <td className="pl-4 text-[#4A4949] font-bold text-left">
                  <p className="text-base text-red-700">{`($${formatPrice(
                    data.totalDiscount
                  )})`}</p>
                </td>
              </tr>
            )}
            {data?.subTotalTax > 0 && (
              <tr>
                <td colSpan={6} className="pr-4 py-1">
                  <p className="text-[#814242] text-base text-right">GST:</p>
                </td>
                <td className="pl-4 text-[#4A4949] font-bold text-left">
                  <p className="text-base">{`$${formatPrice(
                    data.subTotalTax
                  )}`}</p>
                </td>
              </tr>
            )}
            <tr>
              <td colSpan={6} className="pr-4 py-1">
                <p className="text-[#814242] text-base text-right">
                  Total Order Amount:
                </p>
              </td>
              <td className="pl-4 text-[#4A4949] font-bold text-left">
                <p className="text-base">{`$${formatPrice(
                  data?.subTotal + data?.subTotalTax
                )}`}</p>
              </td>
            </tr>
            {(data.appliedVoucherId !== null || data.appliedEVoucherId) && (
              <tr>
                <td colSpan={6} className="pr-4 py-1">
                  <p className="text-[#814242] text-base text-right">
                    Voucher
                    {data?.voucherType === "PERCENT" && (
                      <span className="underline">
                        ({data?.voucherPercentage}%)
                      </span>
                    )}
                    :
                  </p>
                </td>
                <td className="pl-4 text-[#4A4949] font-bold text-left">
                  <p className="text-base text-red-700">{`($${formatPrice(
                    data?.totalVoucher
                  )})`}</p>
                </td>
              </tr>
            )}
            {nextMilestones?.length > 0 && (
              <tr>
                <td colSpan={6} className="pr-4 py-1">
                  <p className="text-[#814242] text-base text-right">
                    Total Amount to Pay:
                  </p>
                </td>
                <td className="pl-4 text-[#4A4949] font-bold text-left">
                  <p className="text-base">{`$${formatPrice(
                    Number(totalNetPayment)
                  )}`}</p>
                </td>
              </tr>
            )}
            <tr>
              <td colSpan={6} className="pr-4 py-1">
                <p className="text-[#814242] text-base text-right">
                  Current Milestone to Pay:
                </p>
              </td>
              <td className="pl-4 text-[#4A4949] font-bold text-left">
                <p className="text-base">{`$${formatPrice(
                  Number(
                    nextMilestones[0]?.paymentmilestone[0]?.netPaymentDue ?? 0
                  )
                )}`}</p>
              </td>
            </tr>
            <tr>
              <td colSpan={6} className="pr-4 py-1">
                <p className="text-[#814242] text-base text-right">
                  Next Milestone to Pay:
                </p>
              </td>
              <td className="pl-4 text-[#4A4949] font-bold text-left">
                <p className="text-base">{`$${formatPrice(
                  Number(
                    nextMilestones[1]?.paymentmilestone[0]?.netPaymentDue ?? 0
                  )
                )}`}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="ml-5 mb-10 mt-5">
        <table className="table-auto w-full">
          <thead className="border-b">
            <tr className="font-worksans-semibold">
              <th className="text-[#4A4949] text-center text-base p-4">
                Payment Milestone
              </th>
              <th className="text-[#4A4949] text-center text-base p-4">
                Payment Due Date
              </th>
              <th className="text-[#4A4949] text-center text-base p-4">
                Total Order excl Tax
              </th>
              <th className="text-[#4A4949] text-center text-base p-4">Gst</th>
              {/* <th className="text-[#4A4949] text-center text-base p-4">Tax Adjusted Amount</th> */}
              <th className="text-[#4A4949] text-center text-base p-4">
                Voucher Offset
              </th>
              <th className="text-[#4A4949] text-center text-base p-4">
                Net Payment Due
              </th>
              <th className="text-[#4A4949] text-center text-base p-4">
                Remaining Voucher Value
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody className="border-b">
            {(data?.paymentMilestones || []).map((item, index) => {
              return (
                <tr className="items-center border-b font-worksans" key={index}>
                  <td className="p-4 text-[#4A4949] text-center text-base">
                    {item.paymentMilestoneNumber}
                  </td>
                  <td className="p-4 text-[#4A4949] text-center text-base">
                    {dateFormat(item.paymentmilestone[0].milestoneDate)}
                  </td>
                  <td className="p-4 text-[#4A4949] text-center text-base">{`$${formatPrice(
                    Number(item.paymentmilestone[0].amount)
                  )}`}</td>
                  <td className="p-4 text-[#4A4949] text-center text-base">{`$${formatPrice(
                    Number(item.paymentmilestone[0].gst)
                  )}`}</td>
                  <td className="p-4 text-[#4A4949] text-center text-base">{`$${formatPrice(
                    Number(item.paymentmilestone[0].voucherOffset)
                  )}`}</td>
                  <td className="p-4 text-[#4A4949] text-center text-base">{`$${formatPrice(
                    Number(item.paymentmilestone[0].netPaymentDue)
                  )}`}</td>
                  <td className="p-4 text-[#4A4949] text-center text-base">{`$${formatPrice(
                    Number(item.paymentmilestone[0].remainingVoucherValue)
                  )}`}</td>
                  <td className="p-4">
                    <>
                      {item.paymentmilestone[0].paymentStatus == 1 ? (
                        <Link
                          to={`/payment-details/${urlParams.id}/${urlParams.orderId}`}
                        >
                          <button className="bg-bg-primary text-white px-4 py-1 rounded-md">
                            Paid
                          </button>
                        </Link>
                      ) : data?.orderStatus === 0 || data?.orderStatus === 3 ? (
                        <p>X</p>
                      ) : (
                        <button
                          disabled
                          className="bg-bg-primary text-white px-4 py-1 rounded-md"
                        >
                          Unpaid
                        </button>
                      )}
                    </>
                  </td>
                </tr>
              );
            })}
            <tr className="items-center border-b font-worksans-semibold">
              <td className="p-4 text-[#4A4949] text-left text-base"></td>
              <td className="p-4 text-[#4A4949] text-left text-base"></td>
              <td className="p-4 text-[#4A4949] text-center text-base">{`$${formatPrice(
                Number(totalAmount)
              )}`}</td>
              <td className="p-4 text-[#4A4949] text-center text-base">{`$${formatPrice(
                data.subTotalTax
              )}`}</td>
              <td className="p-4 text-[#4A4949] text-center text-base">{`$${formatPrice(
                Number(totalVoucherOffset)
              )}`}</td>
              <td className="p-4 text-[#4A4949] text-center text-base">{`$${formatPrice(
                Number(totalNetPayment)
              )}`}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="text-base font-worksans">
        <p className="text-base mb-1">Comments:</p>
        <p className="text-base mb-7">{data?.comment}</p>
        <p className="text-base">{vendorName} Cancellation Policy:</p>
        <pre className="text-base font-worksans">
          {data.vendorPaymentPolicyId?.cancelllationPolicy}
        </pre>
        <div className="mt-5">
          <p className="text-base">
            Note that Order Cancellation may be subject to Cancellation admin
            charges
          </p>
          <p className="text-base">Acknowledged {dateFormat(new Date())}</p>
        </div>
      </div>
      <OrderEditRequest />
      <div className="w-full flex gap-x-5 mt-16">
        <div className="w-[30%]">
          <div className="space-y-2 text-base">
            <p className="font-worksans-medium text-base">
              Shipping Information
            </p>
            <p className="text-base">
              {data?.shippingDetails?.firstName}{" "}
              {data?.shippingDetails?.lastName} <br />
              {data?.shippingDetails?.phone} <br />
              {formatAddress(data?.shippingDetails)}
            </p>
          </div>
        </div>
        <div className="w-[30%]">
          <div className="space-y-2 text-base">
            <p className="font-worksans-medium text-base">
              Billing Information
            </p>
            <p>
              {data?.billingDetails?.firstName} {data?.billingDetails?.lastName}{" "}
              <br />
              {formatAddress(data?.billingDetails)}
            </p>
          </div>
        </div>
      </div>
      {paymentDetails && (
        <div className="mt-5">
          <hr className="my-3" />
          <p className="font-worksans-medium text-lg mb-2">Payment Method</p>
          {paymentDetails?.card ? (
            <p className="capitalize">{`${paymentDetails?.card?.funding} ${
              paymentDetails?.card?.last4
                ? `card - *** ${paymentDetails?.card?.last4}`
                : ""
            } ${paymentDetails?.card?.brand}`}</p>
          ) : (
            <p className="capitalize">{paymentDetails?.type ?? ""}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default OrderDetailsItem;
