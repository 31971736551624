import { combineReducers } from "redux";
import config from "./config";
import leftSidebar from "./left-sidebar";
import palettes from "./palettes";
import navigation from "./navigation";
import vendorSlice from "./vendor.reducer";
import adminAuthSlice from "./auth.reducer";
import productsSlice from "./products.reducer";
import categories from "./categories";
const rootReducer = combineReducers({
  navigation,
  config,
  leftSidebar,
  palettes,
  vendorSlice,
  adminAuthSlice,
  productsSlice,
  categories
});

export default rootReducer;
