import React, { useEffect, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { vendorFormAction } from "../../reducers/vendor.reducer";
import CountryPicker from "../countryPicker/CountryPicker";
import PhonePicker from "../phone-picker/PhonePicker";

const FormSellerInfo = ({ isNew }) => {
  const vendorForm = useSelector((state) => state.vendorSlice.vendorForm);
  const dispatch = useDispatch();

  const handleChangeForm = (title, value) => {
    dispatch(
      vendorFormAction({
        ...vendorForm,
        sellerInfo: {
          ...vendorForm.sellerInfo,
          [title]: value,
        },
      })
    );
  };

  return (
    <div className="mt-10 animate-fade-in-down">
      <div className="flex md:flex-row flex-col md:space-x-2">
        <div className="md:w-1/4 px-5 py-5 border border-gray-200 border-dashed rounded-sm flex flex-col justify-center items-center">
          <p>Vendor Picture</p>
          <label className="h-32 w-32 cursor-pointer border border-opacity-40 border-gray-200 flex justify-center items-center">
            <img
              className="w-24 h-24 bg-grey-200 border-none object-fill"
              src={vendorForm.sellerInfo?.profilePreview}
              alt=""
            />
            <input
              type="file"
              className="hidden"
              accept="image/*"
              onChange={(e) => {
                handleChangeForm("profileImage", e.target.files[0].name);
                handleChangeForm(
                  "profilePreview",
                  URL.createObjectURL(e.target.files[0])
                );
              }}
            />
          </label>
          <p className="text-center text-sm">
            You can change your profile picture here
          </p>
        </div>
        <div className="md:w-3/4 px-5 py-5 border border-gray-200 border-dashed flex flex-col justify-center items-center rounded-sm">
          {!vendorForm.sellerInfo?.bannerPreview ? (
            <>
              <label className="px-6 py-2 cursor-pointer rounded-md bg-blue-500 hover:bg-blue-500-darker text-white">
                <p>Upload Image</p>
                <input
                  onChange={(e) => {
                    handleChangeForm("bannerImage", e.target.files[0]);
                    handleChangeForm(
                      "bannerPreview",
                      URL.createObjectURL(e.target.files[0])
                    );
                  }}
                  type="file"
                  className="hidden"
                  accept="image/*"
                />
              </label>
              <p className="mt-5">
                Upload banner for your store. Banner size in (625x300) pixels
              </p>
            </>
          ) : (
            <label className="cursor-pointer w-full flex justify-center">
              <img
                src={vendorForm.sellerInfo?.bannerPreview}
                style={{ width: "100%", height: "250px" }}
                className="object-cover"
                alt=""
              />
              <input
                onChange={(e) => {
                  handleChangeForm("bannerImage", e.target.files[0]);
                  handleChangeForm(
                    "bannerPreview",
                    URL.createObjectURL(e.target.files[0])
                  );
                }}
                type="file"
                className="hidden"
                accept="image/*"
              />
            </label>
          )}
        </div>
      </div>
      <div className="flex mt-5">
        <div className="w-full space-y-2">
          <p>Profile Summary</p>
          <textarea
            onChange={(e) => handleChangeForm("review", e.target.value)}
            value={vendorForm.sellerInfo?.profileSummary}
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          />
        </div>
      </div>
      <div className="flex mt-5">
        <div className="w-full space-y-2">
          <p>Website</p>
          <input
            onChange={(e) => handleChangeForm("website", e.target.value)}
            value={vendorForm.sellerInfo?.website}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          />
        </div>
      </div>
      <div className="md:flex mt-5 md:space-x-2 md:space-y-0 space-y-4">
        <div className="w-full space-y-2">
          <p>Username *</p>
          <input
            onChange={(e) => handleChangeForm("username", e.target.value)}
            value={vendorForm.sellerInfo?.username}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
            required
          />
        </div>
        {/* <div className="w-full space-y-2">
          <div className="w-full flex justify-between items-center">
            <p>Password *</p>
            <p className="text-right text-sm hover:underline cursor-pointer">
              Change Password
            </p>
          </div>
          <input
            onChange={(e) => handleChangeForm("password", e.target.value)}
            value={vendorForm.sellerInfo?.password}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
            required
          />
        </div> */}
      </div>
      <div className="md:flex mt-5 md:space-x-2 md:space-y-0 space-y-4">
        <div className="w-full space-y-2">
          <p>Vendor Name *</p>
          <input
            onChange={(e) => handleChangeForm("vendorName", e.target.value)}
            value={vendorForm.sellerInfo?.vendorName}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
            required
          />
        </div>
        <div className="w-full space-y-2">
          <p>Company Registration Number</p>
          <input
            onChange={(e) =>
              handleChangeForm("companyRegistrationNumber", e.target.value)
            }
            value={vendorForm.sellerInfo?.companyRegistrationNumber}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
            placeholder="Registration No. (UEN or Other ID) "
          />
        </div>
      </div>
      <div className="md:flex mt-5 md:space-x-2 md:space-y-0 space-y-4">
        <div className="w-full space-y-2">
          <p>Company Name</p>
          <input
            onChange={(e) => handleChangeForm("companyName", e.target.value)}
            value={vendorForm.sellerInfo?.companyName}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
            placeholder="Company Name if different to Trading name"
          />
        </div>
        <div className="w-full space-y-2">
          <p>VAT/Tax Number</p>
          <input
            onChange={(e) => handleChangeForm("vatTaxNumber", e.target.value)}
            value={vendorForm.sellerInfo?.vatTaxNumber}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          />
        </div>
      </div>
      <div className="md:flex mt-5 md:space-x-2 md:space-y-0 space-y-5">
        <div className="w-full space-y-2">
          <p>Phone Number *</p>
          <PhonePicker
            phoneCode={vendorForm.sellerInfo?.countryCode}
            phone={vendorForm.sellerInfo?.phone}
            onSelect={(code) => handleChangeForm("countryCode", code)}
            onChangePhone={(value) => handleChangeForm("phone", value)}
          />
        </div>
        <div className="w-full space-y-2">
          <p>Mobile Number *</p>
          <PhonePicker
            phoneCode={vendorForm.sellerInfo?.countryCodeForMobile}
            phone={vendorForm.sellerInfo?.mobileNumber}
            onSelect={(code) => handleChangeForm("countryCodeForMobile", code)}
            onChangePhone={(value) => handleChangeForm("mobileNumber", value)}
          />
        </div>
        <div className="w-full space-y-2">
          <p>Email Address *</p>
          <input
            onChange={(e) => handleChangeForm("email", e.target.value)}
            value={vendorForm.sellerInfo?.email}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          />
        </div>
      </div>
      <div className="md:flex mt-5 md:space-x-2 md:space-y-0 space-y-4">
        <div className="w-full space-y-2">
          <p>Blk No.</p>
          <input
            onChange={(e) => handleChangeForm("blockNumber", e.target.value)}
            value={vendorForm.sellerInfo?.blockNumber}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          />
        </div>
        <div className="w-full space-y-2">
          <p>Unit No./House No.</p>
          <input
            onChange={(e) => handleChangeForm("unitNo", e.target.value)}
            value={vendorForm.sellerInfo?.unitNo}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          />
        </div>
      </div>
      <div className="md:flex mt-5 md:space-x-2 md:space-y-0 space-y-5">
        <div className="w-full space-y-2">
          <p>Building Name</p>
          <input
            onChange={(e) => handleChangeForm("buildingName", e.target.value)}
            value={vendorForm.sellerInfo?.buildingName}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          />
        </div>
        <div className="w-full space-y-2">
          <p>Street Address 1 *</p>
          <input
            onChange={(e) => handleChangeForm("streetAddress1", e.target.value)}
            value={vendorForm.sellerInfo?.streetAddress1}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
            required
          />
        </div>
      </div>
      <div className="md:flex mt-5 md:space-x-2 md:space-y-0 space-y-4">
        <div className="w-full space-y-2">
          <p>Street Address 2</p>
          <input
            onChange={(e) => handleChangeForm("streetAddress2", e.target.value)}
            value={vendorForm.sellerInfo?.streetAddress2}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          />
        </div>
        <div className="w-full space-y-2">
          <p>Street Address 3</p>
          <input
            onChange={(e) => handleChangeForm("streetAddress3", e.target.value)}
            value={vendorForm.sellerInfo?.streetAddress3}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          />
        </div>
      </div>
      <div className="md:flex mt-5 md:space-x-2 md:space-y-0 space-y-4">
        <div className="w-full space-y-2">
          <p>Postcode *</p>
          <input
            onChange={(e) => handleChangeForm("postCode", e.target.value)}
            value={vendorForm.sellerInfo?.postCode}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
            required
          />
        </div>
        <div className="w-full space-y-2">
          <p>Country *</p>
          <CountryPicker
            onSelect={(value) => handleChangeForm("country", value)}
            value={vendorForm.sellerInfo?.country}
          />
          {/* <p>{vendorForm.sellerInfo?.country}</p> */}
        </div>
      </div>
      <div className="md:flex mt-5 md:space-x-2 md:space-y-0 space-y-4">
        <div className="w-full space-y-2">
          <p>City *</p>
          <input
            onChange={(e) => handleChangeForm("city", e.target.value)}
            value={vendorForm.sellerInfo?.city}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
            required
          />
        </div>
        <div className="w-full space-y-2">
          <p>State / Region</p>
          <input
            onChange={(e) => handleChangeForm("state", e.target.value)}
            value={vendorForm.sellerInfo?.state}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          />
        </div>
      </div>
      <div className="md:flex mt-5 md:space-x-2 md:space-y-0 space-y-3">
        <div className="w-full space-y-2">
          <p>Store 1</p>
          <input
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          />
        </div>
        <div className="w-full space-y-2">
          <p>Store 2</p>
          <input
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          />
        </div>
      </div>
    </div>
  );
};

const SellerInfo = ({ isNew, value }) => {
  const [openForm, setOpenForm] = useState(false);
  const query = new URLSearchParams(window.location.search);
  const vendor = query.get('vendorId');

  useEffect(() => {
    if (isNew) return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full shadow-xl px-5 py-5 bg-white">
      <div
        onClick={() => setOpenForm(!openForm)}
        className="flex justify-between cursor-pointer"
      >
        <p className="text-xl">Seller Info {vendor}</p>
        <BsChevronDown
          size={25}
          className={
            "text-bg-primary " +
            (openForm ? "rotate-180 transition-all" : "rotate-0 transition-all")
          }
        />
      </div>
      {openForm && <FormSellerInfo isNew={isNew} />}
    </div>
  );
};

export default SellerInfo;
