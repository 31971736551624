import React, { useState } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useGlobalFilter,
} from "react-table";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import Loader from "../../components/custom-loader/loader";
import Pagination from "react-js-pagination";
import { AiOutlineInfoCircle } from "react-icons/ai";
// import TooltipTicket from "../tooltips/ticket";
import ModalMessage from "./modalMessage";
import PageSizeDropdown from "../PageSizeDropdown";

const TicketListTable = ({
  columns,
  data,
  loading,
  pagination,
  onSubmit,
  getAllTicketListData,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setPageSize,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      loading,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns]);
    }
  );
  const [openModalMessage, setOpenModalMessage] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const handlePageData = (e) => {
    onSubmit(e);
  };

  const handlePageChange = (pageNumber) => {
    getAllTicketListData(10, "", pageNumber);
  };

  return (
    <>
      <div className="flex p-5"></div>
      <div className="table-responsive">
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {/* <th>No.</th> */}
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="flex flex-row items-center justify-start">
                      <span>{column.render("Header")}</span>
                      <span className="ml-auto">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FiChevronDown className="stroke-current text-2xs" />
                          ) : (
                            <FiChevronUp className="stroke-current text-2xs" />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {loading ? (
              <tr>
                <td colSpan={10}>
                  <Loader />
                </td>
              </tr>
            ) : page.length !== 0 ? (
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {/* <td>
                      {pagination.limit * pagination.page +
                        (i + 1) -
                        pagination.limit}
                    </td> */}
                    {row.cells.map((cell) => {
                      if (cell.column.Header === "Order ID") {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.value && (
                              <div
                                onClick={() => {
                                  setModalContent({
                                    text: cell.value,
                                    title: "Order ID",
                                  });
                                  setOpenModalMessage(true);
                                }}
                                className="flex items-center justify-between space-x-2 relative"
                              >
                                <p>{cell.value.length > 8 ? cell.value.substring(0, 8) + "..." : cell.value}</p>
                                <AiOutlineInfoCircle size={18} />
                              </div>
                            )}
                          </td>
                        );
                      }

                      if (cell.column.Header === "Message") {
                        return (
                          <td {...cell.getCellProps()}>
                            <div
                              onClick={() => {
                                setModalContent({
                                  text: cell.value,
                                  title: "Message",
                                });
                                setOpenModalMessage(true);
                              }}
                              className="flex items-center justify-between space-x-2 relative"
                            >
                              {/* <p>{cell.render("Cell")}</p> */}
                              <p>{cell.value.length > 8 ? cell.value.substring(0, 8) + "..." : cell.value}</p>
                              <AiOutlineInfoCircle size={18} />
                            </div>
                          </td>
                        );
                      }

                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10} style={{ textAlign: "center" }}>
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div>
        <ModalMessage
          open={openModalMessage}
          setOpen={setOpenModalMessage}
          content={modalContent.text}
          title={modalContent.title}
        />
      </div>
      {!loading && page.length > 0 && (
        <div className="flex flex-row items-center justify-between my-4 flex-wrap">
          <Pagination
            color="primary"
            activePage={pagination.page}
            itemsCountPerPage={pagination.limit}
            totalItemsCount={pagination.total}
            pageRangeDisplayed={5}
            onChange={(e) => handlePageChange(e)}
          />
          <select
            className="form-select text-sm bg-white dark:bg-grey-800 dark:border-grey-800 outline-none shadow-none focus:shadow-none"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              handlePageData(e.target.value);
            }}
          >
            <PageSizeDropdown />
          </select>
        </div>
      )}
    </>
  );
};

export default TicketListTable;
