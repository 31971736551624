/* eslint-disable no-unreachable */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { logoutAction } from "../reducers/auth.reducer";
import API from "../utils/createApi";

//// Fetching API
// Get All Products
export const vendorProductsAction = createAsyncThunk(
  "vendorProducts/vendorProductsAction",
  async ({ params, onSuccess, onFailed }, { getState, dispatch }) => {
    // const token = getState().adminAuthSlice.token.access.token;
    const token = localStorage.getItem("wed_admin_token");
    try {
      const result = await API.get(
        `/vendorProduct?&page=${params?.pageNumber}&sortBy=7&limit=${params.limit}&searchBy=${params.search}&status=${params.status}&productcategory=${params.productcategory}&name=${params.name}&vendorId=${params.vendorId ?? ''}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.data.code === 401) {
        onFailed();
        dispatch(logoutAction());
        return { products: [] };
      }
      onSuccess(result.data.data)
      return { products: result.data.data.docs };
    } catch (err) {
      onFailed();
      throw new Error(err);
    }
  }
);

export const updateVendorProducts = createAsyncThunk(
  "vendorProducts/updateVendorProducts",
  async ({ params, onSuccess, onFailed }, { getState, dispatch }) => {
    // const token = getState().adminAuthSlice.token.access.token;
    const token = localStorage.getItem("wed_admin_token");
    const fd = new FormData();
    fd.append("status", params.status)
    try {
      const result = await API.patch(
        `/vendorProduct/${params.id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.data.code === 401) {
        onFailed();
        dispatch(logoutAction());
        return { products: [] };
      }
      onSuccess(result.data.data)
      return { products: [] };
    } catch (err) {
      onFailed();
      throw new Error(err);
    }
  }
);

export const vendorProductGetByIdAction = createAsyncThunk(
  "vendorProduct/vendorProductGetByIdAction",
  async ({ onSuccess, onFailed, id }, { getState, dispatch }) => {
    const token = getState().adminAuthSlice.token.access.token;
    try {
      const result = await API.get(`/vendorProduct/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (result.data.code === 401) {
        onFailed();
        dispatch(logoutAction());
        return { product: null };
      }

      onSuccess(result.data.data);
      return { product: result.data.data };
    } catch (err) {
      onFailed();
      throw new Error(err);
    }
  }
);

export const removeProductAction = createAsyncThunk(
  "vendorProduct/removeProductAction",
  async ({ onSuccess, onFailed, ids }, { getState, dispatch }) => {
    // const token = getState().adminAuthSlice.token.access.token;
    const token = localStorage.getItem("wed_admin_token");
    try {
      const result = await API.post(
        `/vendorProduct/admin/deleteVendorProducts`,
        {
          ids,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.data.code === 401) {
        onFailed();
        dispatch(logoutAction());
        return { product: null };
      }

      onSuccess(result.data.data);
      return { product: result.data.data };
    } catch (err) {
      onFailed();
      throw new Error(err);
    }
  }
);
