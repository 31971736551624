/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import SectionTitle from "../components/section-title";
import Faqtable from "../components/faq-details";
import Widget from "../components/widget";
import { toast } from "react-toastify";
import Modal2 from "../components/confirm-delete-modal/modal-2";
import { FiX } from "react-icons/fi";
import ModalActivate from "../components/confirm-activate-modal/modal-activate";
import ApiClient from "../apis/apiClient";
import {
  API_GetAllFaqDetails,
  API_DeleteFaqDetailsById,
  API_UpdateFaqDetailsById,
} from "../apis/constant";
import AddFaq from "../components/faq-details/AddFaq";
import EditFaqList from "../components/faq-details/faqEditModal";
import { Link, Redirect } from "react-router-dom";
import { checkAccess } from "../utils/common";

const Simple = () => {
  const [faqData, setFaqData] = useState([]);
  const [Loading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [paginationObject, setPaginationObject] = useState({});
  const adminToken = localStorage.getItem("wed_admin_token");

  const getAllFaq = async (limit, search = "", pageNumber) => {
    const data = "";
    setIsLoading(true);
    const res = await ApiClient.get(
      API_GetAllFaqDetails +
        `?page=${pageNumber}&sortBy=asc&limit=${limit}&searchBy=${search}`,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      if (res.data.data && res.data.data.docs.length !== 0) {
        for (let i = 0; i < res.data.data.docs.length; i++) {
          res.data.data.docs[i]["displayStatus"] =
            res.data.data.docs[i].status === 0 ? (
              <label className="">Inactive</label>
            ) : (
              <label className="">Active</label>
            );
          res.data.data.docs[i]["displayTypes"] =
            res.data.data.docs[i].faqTypeId.type;
        }
      }
      setPaginationObject({
        limit: res.data.data.limit,
        page: res.data.data.page,
        pages: res.data.data.pages,
        total: res.data.data.total,
      });
      setFaqData(res.data.data.docs);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (search.length > 2 || search.length === 0) {
      getAllFaq(10, search, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const deleteFaq = async (faqData) => {
    const id = faqData.original.id;
    const data = "";
    const res = await ApiClient.delete(
      API_DeleteFaqDetailsById + id,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllFaq(10, "", 1);
    } else {
      toast.warning(res.data.message);
    }
  };

  const handleActive = async (status, data) => {
    let myObj = {
      status: status,
    };
    const res = await ApiClient.patch(
      API_UpdateFaqDetailsById + "/" + data.id,
      myObj,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllFaq(10, "", 1);
    } else {
      toast.warning(res.data.message);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Faq Type",
        accessor: "displayTypes",
      },
      {
        Header: "Question",
        accessor: "question",
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        whiteSpace: "",
      },
      {
        Header: "Answer",
        accessor: "answer",
      },
      {
        Header: "Status",
        accessor: "displayStatus",
      },
      {
        Header: "Action",
        id: "delete",
        accessor: (str) => {},

        Cell: (Datatable) => (
          <>
            {checkAccess(12, "edit") && (
              <EditFaqList
                id={Datatable.row.original.id}
                title={"Edit FAQ"}
                body={"body"}
                getApi={getAllFaq}
              />
            )}{" "}
            |{" "}
            {checkAccess(12, "delete") && (
              <Modal2
                title="Delete"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to delete this Faq?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  deleteFaq(Datatable.row);
                }}
                buttonClassName="underline"
              />
            )}{" "}
            {/*|{" "}
             {Datatable.row.original.status === 0 ? (
             checkAccess(12, 'edit') &&  <ModalActivate
                title="Activate"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to Deactivate this Faq?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  handleActive(1, Datatable.cell.row.original);
                }}
                buttonClassName="underline"
              />
            ) : (
              checkAccess(12, 'edit') && <ModalActivate
                title="Deactivate"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to Active this Faq?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  handleActive(0, Datatable.cell.row.original);
                }}
                buttonClassName="underline"
              />
            )} */}
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getPageData = (data, type) => {
    if (type === "limit") {
      getAllFaq(data, "", 1);
    }
  };

  const data = React.useMemo(() => faqData, [faqData]);
  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
    return (
      <>
        <div className="flex justify-between faq_input_btn_sec">
          <div className="input_btn_box">
            <input
              className="searchCss"
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="btn_box">
            {checkAccess(12, "add") && (
              <AddFaq title={"Add FAQ "} body={"body"} getApi={getAllFaq} />
            )}
            <Link
              to={"/add-faq-category"}
              className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded ml-3"
            >
              Create Faq Type
            </Link>
          </div>
        </div>
        <Faqtable
          columns={columns}
          data={data}
          loading={Loading}
          pagination={paginationObject}
          onSubmit={getPageData}
          showSearch={true}
          getAllFaq={getAllFaq}
        />
      </>
    );
  }
};

const Index = () => (
  <>
    <SectionTitle title="FAQs" subtitle="FAQ" />

    <Widget>
      <Simple />
    </Widget>
  </>
);
export default Index;
