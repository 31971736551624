import React, { useEffect, useState } from "react";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { DatePolicyContent } from "./datePolicyContent";
import { useOutsideClick } from "../hooks/OutsideClick";
import { useParams } from "react-router-dom";
import { API_ChangeVendorRisk, url, API_AdminPaymentPolicy } from "../apis/constant";
import moment from 'moment';
import { useHistory } from "react-router-dom";

const FORMAT = "DD-MMM-YYYY";

const DropDown = (props) => {
  const { value, options, onSelect } = props;
  const [isOpen, setIsOpen] = useState(false);
  const ref = useOutsideClick(() => setIsOpen(false));
  const [valueTemp, setValueTemp] = useState(value);

  useEffect(() => {
    setValueTemp(options[value]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div ref={ref} className="relative">
      <div
        onClick={() => { setIsOpen(!isOpen) }}
        className="border border-bg-primary px-3 rounded-sm py-1 cursor-pointer"
      >
        <p>{valueTemp}</p>
      </div>
      {isOpen && (
        <div className="absolute top-10 left-0 w-full bg-white text-black rounded-sm border border-bg-eunry px-3 py-1">
          {Object.keys(options).map((item, index) => (
            <p
              onClick={() => {
                setValueTemp(options[item]);
                setIsOpen(false);
                onSelect(item);
              }}
              key={index}
              className="cursor-pointer border-b my-1"
            >
              {options[item]}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

const PaymentPolicyContent = () => {
  const [loading] = useState(false);
  const [policies, setPolicies] = useState([]);
  const [isNoEndDate, setIsNoEndDate] = useState(false);
  const [showDateStart, setShowDateStart] = useState(false);
  const adminToken = localStorage.getItem("wed_admin_token");
  const [showDateEnd, setShowDateEnd] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [vendor, setVendorData] = useState("");
  const [order, setOrderData] = useState("");
  const [page, setPage] = useState("");
  const [updatedPaymentData, setUpdatedPaymentData] = useState([])
  const [updatedCancellationPolicy, setUpdatedCancellationPolicy] = useState("");
  const [updatedAdditionalPolicy, setUpdatedAdditionalPolicy] = useState("");
  const [updatedPolicyStartDate, setUpdatedPolicyStartDate] = useState("")
  const [updatedPolicyEndDate, setUpdatedPolicyEndDate] = useState("")
  const [error, setError] = useState("")

  const getpaymentPolicyDetails = () => {
    axios
      .get(url + API_AdminPaymentPolicy + urlParams.id, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((res) => {
        if (res.data.code === 200) {
          const orderData = res.data.data;
          setOrderData({ ...res.data.data });
          setVendorData(res.data.data?.vendorId);
          setUpdatedPaymentData(orderData.paymentMilestone)
          setUpdatedCancellationPolicy(orderData.cancelllationPolicy);
          setUpdatedAdditionalPolicy(orderData.additionalPolicy);
          setUpdatedPolicyStartDate(orderData.startDate)
          setUpdatedPolicyEndDate(orderData.endDate)
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }

  useEffect(() => {
    if (vendor.id) {
      getPaymentHistory(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendor.id]);

  const getPaymentHistory = (pageNumber) => {
    axios
      .get(
        url +
        `paymentpolicy-history/${vendor.id}?page=${pageNumber}`,
        {
          headers: { Authorization: `Bearer ${adminToken}` },
        }
      )
      .then((res) => {
        if (res.data.code === 200) {
          let updateHistoryPolicy = [];
          res.data.data.forEach(history => {
            updateHistoryPolicy.push({
              email: history?.updatedByAdmin?.email ?? history?.vendorId?.email,
              cancelllationPolicy: history?.cancelllationPolicy,
              startDate: history?.startDate,
              endDate: history?.endDate,
              createdAt: history.createdAt,
              requestStatus: history.requestStatus,
            })
          });
          setHistoryData(updateHistoryPolicy ?? []);
          setPage(res.data.total)
        } else {
          setHistoryData([]);
          setPage(0);
        }
      }).catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getpaymentPolicyDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let history = useHistory();
  const urlParams = useParams();

  const checkValidation = () => {
    if (!updatedPolicyStartDate) {
      toast.error("Please select start date");
      return false;
    }

    if (!updatedCancellationPolicy) {
      toast.error("Please enter cancellation policy");
      return false;
    }

    const _checkValidation = updatedPaymentData.map((val, index) => {
      if (val.paymentAmount === null || val.paymentAmount === "") {
        toast.error(`Please fill Payment SGP field at  ${index + 1}`);
        return false;
      }
      if (val.paymentInPercentage === null || val.paymentInPercentage === "") {
        toast.error(`Please fill Payment percentage field at  ${index + 1}`);
        return false;
      }
      if (index !== 0 && (!val.pmDueNoOfDaysPriorTodeliveryDate || val.pmDueNoOfDaysPriorTodeliveryDate === 0)) {
        toast.error(`Please fill PM Due No of Days field at  ${index + 1}`);
        return false;
      }
      if ((!val.refundInPercentage || Number(val.refundInPercentage) === 0) && (val.cancellableNoOfDaysPriorToDeliveryDate && Number(val.cancellableNoOfDaysPriorToDeliveryDate) > 0)) {
        toast.error(`Please fill Refund percentage field at  ${index + 1}`);
        return false;
      }
      if ((!val.cancellableNoOfDaysPriorToDeliveryDate || Number(val.cancellableNoOfDaysPriorToDeliveryDate) === 0) && (val.refundInPercentage && Number(val.refundInPercentage) > 0)) {
        toast.error(`Please fill Cancellable No of Days field at  ${index + 1}`);
        return false;
      }
      return true;
    });

    if (_checkValidation.includes(false)) return false;
    return true;
  };

  const handleSavePolicy = () => {
    try {
      const _checkValidation = checkValidation();
      if (!_checkValidation) {
        throw new Error("Validation Failed")
      }
      const updatedDetails = {
        id: order.id,
        paymentMilestone: [...updatedPaymentData],
        cancelllationPolicy: `${updatedCancellationPolicy}\n${updatedAdditionalPolicy ?? ''}`,
        additionalPolicy: updatedAdditionalPolicy,
        endDate: updatedPolicyEndDate,
        startDate: updatedPolicyStartDate,
      };
      axios
        .patch(
          url + API_AdminPaymentPolicy + order?.ticketId,
          { ...updatedDetails },
          {
            headers: { Authorization: `Bearer ${adminToken}` },
          }
        )
        .then((res) => {
          if (res.data.code === 200) {
            toast.success(res.data.message);
            history.push("/payment-policy");
          } else if (res.data.code === 400) {
            toast.warning(res.data.message);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    } catch (err) {
      toast.error(err?.response?.data?.message);
    };
  };

  const onSelect = (val) => {
    const data = { riskType: val }
    axios
      .patch(url + API_ChangeVendorRisk + vendor?.id, data, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((res) => {
        if (res.data.code === 200) {
          toast.success(res.data.message);

        } else if (res.data.code === 400) {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handlePageChange = (event, value) => {
    getPaymentHistory(value)
  }
  const setAutoCancellationPolicyText = () => {
    let message = '';
    updatedPaymentData.forEach((milestone, idx) => {
      message += `PM ${idx + 1}: ${(milestone.paymentAmount && milestone.paymentAmount !== 0) ? milestone.paymentAmount + " + " : ""}${milestone.paymentInPercentage}% Payment${milestone.pmDueNoOfDaysPriorTodeliveryDate ? ", due in " + milestone.pmDueNoOfDaysPriorTodeliveryDate + " days" : ""}${(milestone.cancellableNoOfDaysPriorToDeliveryDate && milestone.cancellableNoOfDaysPriorToDeliveryDate > 0) ? `, Cancellable within ${milestone.cancellableNoOfDaysPriorToDeliveryDate} days to Expected Delivery date ` : ''}${milestone.refundInPercentage && milestone.refundInPercentage > 0 ? `with ${milestone.refundInPercentage}% refund` : ', non refundable'}\n`;
    })
    setUpdatedCancellationPolicy(message.slice(0, message.length - 1));
  }
  useEffect(() => {
    setAutoCancellationPolicyText();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedPaymentData])
  return (
    <>
      <div className="w-full">
        {/* <div className="md:justify-end flex items-center mb-6">
          <button
            onClick={handleAddPaymentPolicy}
            className="transition bg-bg-primary hover:bg-bg-primary-darker px-10 py-2 text-white rounded-md mt-5 md:w-auto w-full"
          >
            Add Payment Policy
          </button>
        </div> */}

        <div
          style={{
            boxShadow: "0px 0px 5px -2px #333",
            padding: 20,
            marginBottom: 10,
          }}
        >
          <div className="md:relative overflow-x-auto">
            <div className="w-1/5 flex">
              <div className="w-1/2">
                <p className="text-sm">Vendor Name:</p>
              </div>
              <div className="w-1/2">
                <p className="text-sm font-bold">{vendor?.vendorName}</p>
              </div>
            </div>
            <div className="w-1/5 flex">
              <div className="w-1/2">
                <p className="text-sm">Vendor ID:</p>
              </div>
              <div className="w-1/2">
                <p className="text-sm font-bold">
                  {vendor?.vendorId || vendor?.id}
                </p>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="w-1/5 flex items-center mt-3">
                <div className="w-1/2">
                  <p className="text-sm">Risk Type:</p>
                </div>
                <div className="w-1/2">
                  <DropDown
                    value={vendor?.riskType ?? "high"}
                    options={{ "high": "High Risk", "low": "Low Risk" }}
                    onSelect={onSelect}
                  />
                </div>
              </div>
              {(order?.isEditable && order?.ticketId !== undefined) && (
                <div className=" flex items-center">
                  <button
                    className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white addCategoryCss "
                    type="button"
                    onClick={() =>
                      setUpdatedPaymentData([...updatedPaymentData, {
                        "paymentAmount": 0,
                        "paymentInPercentage": 0,
                        "pmDueNoOfDaysPriorTodeliveryDate": "",
                        "cancellableNoOfDaysPriorToDeliveryDate": "",
                        "refundAmount": "",
                        "refundInPercentage": ""
                      }])
                    }
                  // onClick={show}
                  >
                    Add new milestone
                  </button>
                </div>
              )}
            </div>

            <table className="w-full text-sm text-left">
              <thead className="">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center"
                  >
                    PM
                  </th>
                  <th scope="col" className="px-6 py-3 md:pl-0 pl-20">
                    Payment $
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Payment %
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3"
                  >
                    PM due No of Days Prior to Delivery Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3"
                  >
                    Cancellable No of Days Prior to Delivery Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Refund %
                  </th>
                  <th scope="col" className="px-6 py-3"></th>
                </tr>
              </thead>
              <tbody className="border-b-0.5">
                {updatedPaymentData.map((number, index) => (
                  <tr className="border-t-0.5">
                    <td className="px-6 py-3 text-center">
                      {index + 1}
                    </td>
                    <td className="px-6 py-4 md:pl-0 pl-20">
                      <div className="border-2 py-1 px-2 rounded-md">
                        {order?.isEditable && (order?.ticketId && order?.ticketId !== null) ? (
                          <input
                            type="number"
                            className="text-gray-400"
                            onInput={(e) =>
                              (e.target.value = e.target.value.slice(0, 10))
                            }
                            onChange={(e) => {
                              let updateInput = [...updatedPaymentData];
                              if (e.target.value.length === 0) {
                                updateInput[index] = {
                                  ...updateInput[index],
                                  paymentAmount: "",
                                };
                              } else {
                                // let updateInput = [...updatedPaymentData];
                                updateInput[index] = {
                                  ...updateInput[index],
                                  paymentAmount: parseInt(e.target.value),
                                };
                              }

                              setUpdatedPaymentData(updateInput);
                            }}
                            value={
                              isNaN(updatedPaymentData[index]?.paymentAmount)
                                ? ""
                                : updatedPaymentData[index]?.paymentAmount
                            }
                          // value={updatedPaymentData[index]?.paymentAmount}
                          />
                        ) : (
                          <p
                            style={{ pointerEvents: "none" }}
                            className="text-gray-400 h-5"
                          >
                            {number.paymentAmount}
                          </p>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="border-2 py-1 px-2 rounded-md">
                        {order?.isEditable && (order?.ticketId && order?.ticketId !== null) ? (
                          <input
                            type="number"
                            className="text-gray-400"
                            // value={updatedPaymentData[index]?.paymentInPercentage}
                            // max={100}
                            onInput={(e) =>
                              (e.target.value = e.target.value.slice(0, 10))
                            }
                            value={
                              isNaN(
                                updatedPaymentData[index]?.paymentInPercentage
                              )
                                ? ""
                                : updatedPaymentData[index]?.paymentInPercentage
                            }
                            onChange={(e) => {

                              if (parseInt(e.target.value) > 100) {
                                setError(
                                  "Payment Percentage cannot be greater than 100%"
                                );
                                return;
                              } else {
                                setError("");
                              }
                              let updateInput = [...updatedPaymentData];
                              if (e.target.value.length === 0) {
                                updateInput[index] = {
                                  ...updateInput[index],
                                  paymentInPercentage: "",
                                };
                              } else {

                                updateInput[index] = {
                                  ...updateInput[index],
                                  paymentInPercentage: parseInt(e.target.value),
                                };
                              }
                              setUpdatedPaymentData(updateInput);
                            }}
                          />
                        ) : (
                          <p
                            style={{ pointerEvents: "none" }}
                            className="text-gray-400 h-5"
                          >
                            {number.paymentInPercentage}
                          </p>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="border-2 py-1 px-2 rounded-md">
                        {order?.isEditable && (order?.ticketId && order?.ticketId !== null) ? (
                          <input
                            // style={{ pointerEvents: "none" }}
                            type="number"
                            onInput={(e) =>
                              (e.target.value = e.target.value.slice(0, 10))
                            }
                            className="text-gray-400"
                            // value={updatedPaymentData[index]?.pmDueNoOfDaysPriorTodeliveryDate}
                            value={
                              isNaN(
                                updatedPaymentData[index]
                                  ?.pmDueNoOfDaysPriorTodeliveryDate
                              )
                                ? ""
                                : updatedPaymentData[index]
                                  ?.pmDueNoOfDaysPriorTodeliveryDate
                            }
                            onChange={(e) => {
                              // if(updatedPaymentData[index-1]?.pmDueNoOfDaysPriorTodeliveryDate <)
                              let updateInput = [...updatedPaymentData];
                              if (
                                updateInput[index - 1]
                                  ?.pmDueNoOfDaysPriorTodeliveryDate &&
                                updateInput[index - 1]
                                  ?.pmDueNoOfDaysPriorTodeliveryDate <
                                parseInt(e.target.value)
                              ) {
                                setError(
                                  "PM due no of days prior to delivery Date cannot be greater than previous value"
                                );
                                return;
                              } else {
                                setError("");
                              }
                              if (e.target.value.length === 0) {
                                updateInput[index] = {
                                  ...updateInput[index],
                                  pmDueNoOfDaysPriorTodeliveryDate: "",
                                };
                              } else {
                                updateInput[index] = {
                                  ...updateInput[index],
                                  pmDueNoOfDaysPriorTodeliveryDate: parseInt(
                                    e.target.value
                                  ),
                                };
                              }

                              setUpdatedPaymentData(updateInput);
                            }}
                          />
                        ) : (
                          <p
                            style={{ pointerEvents: "none" }}
                            className="text-gray-400 h-5"
                          >
                            {/* {JSON.stringify(order)} */}
                            {number.pmDueNoOfDaysPriorTodeliveryDate}
                          </p>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="border-2 py-1 px-2 rounded-md">
                        {order?.isEditable && (order?.ticketId && order?.ticketId !== null) ? (
                          <input
                            type="number"
                            // style={{ pointerEvents: "none" }}
                            className="text-gray-400 "
                            // value={
                            onInput={(e) =>
                              (e.target.value = e.target.value.slice(0, 10))
                            }
                            // updatedPaymentData[index]?.cancellableNoOfDaysPriorToDeliveryDate
                            value={
                              isNaN(
                                updatedPaymentData[index]
                                  ?.cancellableNoOfDaysPriorToDeliveryDate
                              )
                                ? ""
                                : updatedPaymentData[index]
                                  ?.cancellableNoOfDaysPriorToDeliveryDate
                            }
                            // }
                            onChange={(e) => {
                              let updateInput = [...updatedPaymentData];
                              if (
                                updateInput[index - 1]
                                  ?.cancellableNoOfDaysPriorToDeliveryDate &&
                                updateInput[index - 1]
                                  ?.cancellableNoOfDaysPriorToDeliveryDate <
                                parseInt(e.target.value)
                              ) {
                                setError(
                                  "Cancellable due no of days prior to delivery Date cannot be greater than previous value"
                                );
                                return;
                              } else {
                                setError("");
                              }
                              e.target.value.length === 0
                                ? (updateInput[index] = {
                                  ...updateInput[index],
                                  cancellableNoOfDaysPriorToDeliveryDate:
                                    null,
                                })
                                : (updateInput[index] = {
                                  ...updateInput[index],
                                  cancellableNoOfDaysPriorToDeliveryDate:
                                    parseInt(e.target.value),
                                });
                              setUpdatedPaymentData(updateInput);
                            }}
                          />
                        ) : (
                          <p
                            style={{ pointerEvents: "none" }}
                            className="text-gray-400 h-5"
                          >
                            {
                              updatedPaymentData[index]
                                ?.cancellableNoOfDaysPriorToDeliveryDate
                            }
                          </p>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="border-2 py-1 px-2 rounded-md">
                        {order?.isEditable && (order?.ticketId && order?.ticketId !== null) ? (
                          <input
                            type="number"
                            onInput={(e) =>
                              (e.target.value = e.target.value.slice(0, 10))
                            }
                            // style={{ pointerEvents: "none" }}
                            className="text-gray-400"
                            // value={updatedPaymentData[index]?.refundInPercentage}
                            value={
                              isNaN(
                                updatedPaymentData[index]?.refundInPercentage
                              )
                                ? ""
                                : updatedPaymentData[index]?.refundInPercentage
                            }
                            onChange={(e) => {
                              let updateInput = [...updatedPaymentData];
                              if (parseInt(e.target.value) > 100) {
                                setError(
                                  "Refund Percentage cannot be greater than 100"
                                );
                                return;
                              } else {
                                setError("");
                              }
                              if (e.target.value.length === 0) {
                                updateInput[index] = {
                                  ...updateInput[index],
                                  refundInPercentage: "",
                                };
                              } else {

                                updateInput[index] = {
                                  ...updateInput[index],
                                  refundInPercentage: parseInt(e.target.value),
                                };
                              }
                              setUpdatedPaymentData(updateInput);
                            }}
                          />
                        ) : (
                          <p
                            style={{ pointerEvents: "none" }}
                            className="text-gray-400 h-5"
                          >
                            {number.refundInPercentage}
                          </p>
                        )}
                      </div>
                    </td>
                    {/* {JSON.stringify(order?.isEditable)} */}
                    {/* {JSON.stringify(order?.ticketId? !== undefined)} */}
                    {/* {JSON.stringify(order?.ticketId === undefined)} */}
                    {(order?.isEditable && order?.ticketId !== undefined) && (
                      <td className="px-6 py-4">
                        <div className="border-2 py-1 px-2 rounded-md">
                          <button
                            onClick={() => {
                              let updateInput = [...updatedPaymentData];
                              updateInput.splice(index, 1);
                              setUpdatedPaymentData(updateInput);
                              // const arr
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <span>
            <p className="text-red-500">{error}</p>
          </span>
          {/* <div className="flex md:items-center justify-between md:flex-row flex-col">
              <button
                onClick={() => {
                  setStep(step + 1);
                  const copyPolicy = [...policies];
                  const copyMp = [...val.milestone_policies];
                  copyMp.push(defaultMilestone);
                  copyPolicy.splice(idx, 1, { ...val, milestone_policies: copyMp });
                  setPolicies(copyPolicy);
                }}
                className="transition border-2 border-bg-primary px-6 md:py-2 py-1 text-bg-primary hover:bg-bg-primary hover:text-white rounded-md mt-5"
              >
                Add Payment Milestone
              </button> */}

          {/* <div className="flex md:flex-row flex-col md:items-center">
                <div className="md:w-60 md:mt-0 mt-5">
                  <p className="font-worksans-semibold">Stripe Charges to absorb:</p>
                </div>
                <div className="md:flex hidden">
                  <input
                    className="mr-2 appearance-none h-5 w-5 border rounded-md bg-white checked:bg-bg-eunry checked:border-bg-eunry focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
                    type="radio"
                    checked={!!val.stripe_absorb}
                    onClick={() => {
                      const copyPolicy = [...policies];
                      copyPolicy.splice(idx, 1, { ...val, stripe_absorb: 1 });
                      setPolicies(copyPolicy);
                    }}
                  />
                  <p className="md:text-base text-sm">YES</p>

                  <input
                    className="mr-2 ml-10 appearance-none h-5 w-5 border rounded-md bg-white checked:bg-bg-eunry checked:border-bg-eunry focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
                    type="radio"
                    checked={!val.stripe_absorb}
                    onClick={() => {
                      const copyPolicy = [...policies];
                      copyPolicy.splice(idx, 1, { ...val, stripe_absorb: 0 });
                      setPolicies(copyPolicy);
                    }}
                  />
                  <p className="md:text-base text-sm">NO</p>
                </div>
                <div className="md:hidden flex mb-4 mt-2">
                  <input
                    className="mr-2 appearance-none h-5 w-5 border rounded-md bg-white checked:bg-bg-eunry checked:border-bg-eunry focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
                    type="radio"
                    checked={!!val.stripe_absorb}
                    onClick={() => {
                      const copyPolicy = [...policies];
                      copyPolicy.splice(idx, 1, { ...val, stripe_absorb: 1 });
                      setPolicies(copyPolicy);
                    }}
                  />
                  <p className="md:text-base text-sm">YES</p>

                  <input
                    className="mr-2 ml-10 appearance-none h-5 w-5 border rounded-md bg-white checked:bg-bg-eunry checked:border-bg-eunry focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
                    type="radio"
                    checked={!val.stripe_absorb}
                    onClick={() => {
                      const copyPolicy = [...policies];
                      copyPolicy.splice(idx, 1, { ...val, stripe_absorb: 0 });
                      setPolicies(copyPolicy);
                    }}
                  />
                  <p className="md:text-base text-sm">NO</p>
                </div>
              </div> */}
          {/* </div> */}
          <div className="items-center gap-5 lg:flex md:block">
            <div
              className="md:mt-6 w-full"
            >
              <div className={`flex gap-2`}>
                <p className="font-worksans-semibold">Cancellation Policy</p>
              </div>
              <textarea
                disabled={true}
                placeholder="Enter Cancellation Policy here"
                className={`h-24 mt-2 bg-bg-dark-grey rounded-md outline-none p-2 resize-none w-full cursor-not-allowed`}
                style={{ background: "#F2F2F2", textAlign: "start" }}
                value={updatedCancellationPolicy ?? ""}
              />
            </div>
            <div
              className="md:mt-6 w-full"
            >
              <div className={`flex gap-2`}>
                <p className="font-worksans-semibold">Additional Policy</p>
              </div>
              <textarea
                disabled={order?.isEditable && (order?.ticketId && order?.ticketId !== null) ? false : true}
                placeholder="Enter Additional Policy here"
                className={`h-24 mt-2 bg-bg-dark-grey rounded-md outline-none p-2 resize-none w-full cursor-auto`}
                style={{ background: "#F2F2F2", textAlign: "start" }}
                onChange={(e) => setUpdatedAdditionalPolicy(e.target.value)}
                value={updatedAdditionalPolicy ?? ""}
              />
            </div>
          </div>
          <div className="flex justify-start items-center">
            <label htmlFor="checkboxNoEndDate">No End Date</label>
            <input
              disabled={order?.isEditable && (order?.ticketId && order?.ticketId !== null) ? false : true}

              type="checkbox"
              name="appliesTo"
              id="checkboxNoEndDate"
              className="w-4 h-4 ml-2"
              value=""
              onChange={(e) => {
                setIsNoEndDate(!isNoEndDate);
                if (!isNoEndDate) {
                  setUpdatedPolicyEndDate("");
                }
              }}
            />
          </div>
          {/* {order?.ticketId} */}
          <DatePolicyContent
            FORMAT={FORMAT}
            val={"02-02-2022"}
            policies={policies}
            isdisabled={order?.isEditable && (order?.ticketId && order?.ticketId !== null) ? false : true}
            isNoEndDate={isNoEndDate}
            // disabled={order?.}
            setPolicies={setPolicies}
            showDateStart={showDateStart}
            setShowDateStart={setShowDateStart}
            dateStart={updatedPolicyStartDate}
            setUpdatedPolicyStartDate={setUpdatedPolicyStartDate}
            setUpdatedPolicyEndDate={setUpdatedPolicyEndDate}
            showDateEnd={showDateEnd}
            setShowDateEnd={setShowDateEnd}
            dateEnd={updatedPolicyEndDate}
          />

          {/* <div className="justify-end flex md:flex-row flex-col  items-center mb-6">
              <button
                onClick={() => {
                  const poli = policies.filter((po, index) => index !== idx);
                  setPolicies(poli);
                  onRemovePaymentPolicy(val?.id);
                }}
                className="transition bg-bg-primary md:w-auto w-full px-10 py-2 text-white rounded-md mt-2"
              >
                Delete Payment Policy
              </button>
            </div> */}
        </div>

        {/* {addPayment ? (
          policies.length > 0 && (
            <button
              disabled={!addPayment}
              onClick={handleSavePolicy}
              className={
                !addPayment
                  ? "transition bg-bg-primary  px-10 py-2 text-white rounded-md mt-5 opacity-50"
                  : "transition bg-bg-primary hover:bg-bg-primary-darker  px-10 py-2 text-white rounded-md mt-5"
              }
            >
              {!loading ? "Save" : <CircularProgress size={20} color="inherit" />}
            </button>
          )
        ) : (
          <div></div>
        )} */}
        {/* {policies.length > 0 && (
          <button
            disabled={step !== 0 ? false : true}
            onClick={handleSavePolicy}
            className={
              step === 0
                ? "transition bg-bg-primary  px-10 py-2 text-white rounded-md mt-5 opacity-50 md:w-auto w-full"
                : "transition bg-bg-primary hover:bg-bg-primary-darker  px-10 py-2 text-white rounded-md mt-5 md:w-auto w-full"
            }
            // className={ "transition bg-bg-primary hover:bg-bg-primary-darker  px-10 py-2 text-white rounded-md mt-5"}
          >
            {!loading ? "Save" : <CircularProgress size={20} color="inherit" />}
          </button>
        )} */}
        {(order?.isEditable && order?.ticketId !== undefined) && (
          <button
            // disabled={sata.ticketNumber.length !== 0 ? false : true}
            onClick={handleSavePolicy}
            // className={
            //   // step === 0
            //     // ? "transition bg-bg-primary  px-10 py-2 text-white rounded-md mt-5 opacity-50 md:w-auto w-full"
            //     "transition bg-bg-primary hover:bg-bg-primary-darker  px-10 py-2 text-white rounded-md mt-5 md:w-auto w-full"
            // }
            className={
              "transition bg-bg-primary hover:bg-bg-primary-darker  px-10 py-2 text-white rounded-md mt-5"
            }
          >
            {!loading ? "Save" : <CircularProgress size={20} color="inherit" />}
          </button>
        )}
        <div className="mt-10 border-t-2 pt-5">
          <p className="font-cagily text-bg-primary text-2xl">History</p>
          <div className="pt-4">
            <div className={`relative overflow-x-auto  ${(historyData && historyData?.length > 5) ? 'h-[600px] overflow-y-auto' : ''}`}>
              <table className="md:w-full text-sm text-left w-100">
                <thead className="bg-bg-eunry text-white sticky top-0 left-0 right-0">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Email
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Description
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Start Date
                    </th>
                    <th scope="col" className="px-6 py-3">
                      End Date
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Created Date
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="border-b-0.5">
                  {historyData.map((item, index) => {
                    return (
                      <tr key={index} className={`border-t-0.5 ${index % 2 !== 0 ? "bg-bg-romance" : ""}`}>
                        <td className="px-6 py-4" style={{ width: "10%" }}>
                          {item.email}
                        </td>
                        <td className="px-6 py-4" style={{ width: "40%" }}>
                          <pre style={{ background: 'transparent' }}>{item.cancelllationPolicy}</pre>
                        </td>
                        <td className="px-6 py-4" style={{ width: "10%" }}>
                          {moment(item.startDate).format("DD-MMM-YYYY")}
                        </td>
                        <td className="px-6 py-4" style={{ width: "10%" }}>
                          {item.endDate ? moment(item.endDate).format("DD-MMM-YYYY") : "-"}
                        </td>
                        <td className="px-6 py-4" style={{ width: "10%" }}>
                          {moment(item.createdAt).format("DD-MMM-YYYY HH:mm")}
                        </td>
                        <td className="px-6 py-4" style={{ width: "10%" }}>
                          {item.requestStatus}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="justify-center w-full flex my-10">
            <Pagination
              count={page}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentPolicyContent;
