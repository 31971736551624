import React, { useEffect, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { vendorFormAction } from "../../reducers/vendor.reducer";

const FormSocialOption = ({ isNew }) => {
  const vendorForm = useSelector((state) => state.vendorSlice.vendorForm);
  const dispatch = useDispatch();

  const handleChangeForm = (title, value) => {
    dispatch(
      vendorFormAction({
        ...vendorForm,
        socialMedia: {
          ...vendorForm.socialMedia,
          [title]: value,
        },
      })
    );
  };

  return (
    <div className="md:mt-10 mt-5 animate-fade-in-down">
      <div className="md:flex md:space-x-2 md:space-y-0 space-y-3">
        <div className="w-full space-y-2">
          <p>Facebook</p>
          <input
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
            onChange={(e) => handleChangeForm("facebook", e.target.value)}
            value={vendorForm.socialMedia?.facebook}
          />
        </div>
        <div className="w-full space-y-2">
          <p>Flickr</p>
          <input
            onChange={(e) => handleChangeForm("flickr", e.target.value)}
            value={vendorForm.socialMedia?.flickr}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          />
        </div>
      </div>
      <div className="md:flex md:space-x-2 md:space-y-0 space-y-3 mt-5">
        <div className="w-full space-y-2">
          <p>Twitter</p>
          <input
            onChange={(e) => handleChangeForm("twitter", e.target.value)}
            value={vendorForm.socialMedia?.twitter}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          />
        </div>
        <div className="w-full space-y-2">
          <p>Youtube</p>
          <input
            onChange={(e) => handleChangeForm("youtube", e.target.value)}
            value={vendorForm.socialMedia?.youtube}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          />
        </div>
      </div>
      <div className="md:flex md:space-x-2 md:space-y-0 space-y-3 mt-5">
        <div className="w-full space-y-2">
          <p>LinkedIn</p>
          <input
            onChange={(e) => handleChangeForm("linkedin", e.target.value)}
            value={vendorForm.socialMedia?.linkedin}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          />
        </div>
        <div className="w-full space-y-2">
          <p>Pinterest</p>
          <input
            onChange={(e) => handleChangeForm("pinterest", e.target.value)}
            value={vendorForm.socialMedia?.pinterest}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          />
        </div>
      </div>
      <div className="md:flex md:space-x-2 md:space-y-0 space-y-3 mt-5">
        <div className="w-full space-y-2">
          <p>Instagram</p>
          <input
            onChange={(e) => handleChangeForm("instagram", e.target.value)}
            value={vendorForm.socialMedia?.instagram}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          />
        </div>
      </div>
    </div>
  );
};

const SocialMedia = ({ isNew }) => {
  const [openForm, setOpenForm] = useState(false);

  useEffect(() => {
    if (isNew) return;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full shadow-xl px-5 py-5 bg-white">
      <div
        onClick={() => setOpenForm(!openForm)}
        className="flex justify-between cursor-pointer"
      >
        <p className="text-xl">Social Media</p>
        <BsChevronDown
          size={25}
          className={
            "text-bg-primary " +
            (openForm ? "rotate-180 transition-all" : "rotate-0 transition-all")
          }
        />
      </div>
      {openForm && <FormSocialOption isNew={isNew} />}
    </div>
  );
};

export default SocialMedia;
