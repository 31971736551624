import React, { useState, useEffect, useRef } from "react";
import { useSelector, shallowEqual } from "react-redux";
import Portal from "../portal";
import { FiX } from "react-icons/fi";
import { API_CreateMasterCategory, url } from "../../apis/constant";
import { toast } from "react-toastify";
import axios from "axios";

const EditCategory = ({ id, title, getApi }) => {
  const alphanumericRegex = /^[^'"`]+$/;
  const adminToken = localStorage.getItem("wed_admin_token");
  const [error, setError] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [nameValue, setNameValue] = useState("");

  const { palettes } = useSelector(
    (state) => ({
      palettes: state.palettes,
    }),
    shallowEqual
  );
  let { background } = {
    ...palettes,
  };

  const modalRef = useRef(null);
  const [open, setOpen] = useState(false);
  const show = () => {
    setOpen(true);
    getcategoryById();
  };
  const hide = () => {
    setOpen(false);
    setNameValue("");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false;
      if (!open || modalRef.current.contains(event.target)) {
        return false;
      }
      setOpen(!open);
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, modalRef]);

  const getcategoryById = () => {
    axios
      .get(url + API_CreateMasterCategory + "/" + id, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((res) => {
        if (res.data.code === 200) {
          setNameValue(res.data.data.name);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handleNameValue = (value) => {
    const limit = 200;
    if (value.length > limit) {
      setError("Please enter less than 200 words.");
    } else if (value && !alphanumericRegex.test(value)) {
      setError("Please enter valid name.");
      setNameValue(value);
      return;
    } else {
      setError("");
      setNameValue(value);
    }
  };

  const handleCategory = () => {
    setIsSubmit(true);
    if (nameValue === "") {
      setError("Name should not be blank.");
      setIsSubmit(false);
      return;
    }
    if (nameValue && !alphanumericRegex.test(nameValue)) {
      setError("Please enter valid name.");
      setIsSubmit(false);
      return;
    }
    // if ((nameValue && nameValue.length < 3) || nameValue.length > 50) {
    //   setError("Name length should be in between 2 to 50 characters");
    //   setIsSubmit(false);
    //   return;
    // }
    setError("");
    let logData = {
      name: nameValue,
    };
    axios
      .patch(url + API_CreateMasterCategory + "/" + id, logData, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((res) => {
        if (res.data.code === 200) {
          toast.success(res.data.message);
          hide();
          getApi(10, 1);
        } else if (res.data.code === 400) {
          toast.error(res.data.message);
          setIsSubmit(false);
        }
      })
      .catch((err) => {
        setIsSubmit(false);
        toast.error(err.response.data.message);
      });
  };

  return (
    <>
      <button className=" underLineText" type="button" onClick={show}>
        Edit
      </button>{" "}
      {open && (
        <Portal selector="#portal">
          <div className="modal-backdrop fade-in"></div>
          <div
            className={`modal show ${background === "dark" ? "dark-mode" : ""}`}
            data-background={background}
          >
            <div
              className="relative min-w-sm w-auto mx-auto lg:max-w-5xl"
              ref={modalRef}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="text-xl font-semibold">{title}</h3>
                  <button
                    className="modal-close btn btn-transparent"
                    onClick={hide}
                  >
                    <FiX size={18} className="stroke-current" />
                  </button>
                </div>
                <div className="relative p-4 flex-auto">
                  <label>Title:</label>
                  <div className="inputBoxCategoryCss">
                    <input
                      type="text"
                      placeholder="Please Enter Title"
                      name="name"
                      value={nameValue}
                      onChange={(e) => handleNameValue(e.target.value)}
                      className="inputFields"
                    />
                  </div>

                  {error && (
                    <p style={{ color: "red", padding: "10px 4px" }}>{error}</p>
                  )}
                </div>
                <div className="modal-footer space-x-2">
                  <button
                    className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                    type="button"
                    onClick={hide}
                  >
                    Close
                  </button>
                  {isSubmit ? (
                    <button
                      className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                      type="button"
                    >
                      Loading...
                    </button>
                  ) : (
                    <button
                      className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                      type="button"
                      onClick={() => handleCategory()}
                    >
                      Save Changes
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default EditCategory;
