/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef, useMemo } from "react";
import io from "socket.io-client";
import moment from "moment";
// import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react';
import NoDataImg from "../images/Nochatsfound.png";
import { uploadMediaAction } from "../actions";
import { useDispatch } from "react-redux";
import { socketUrl } from "../apis/constant";
import SectionTitle from "../components/section-title";
import { useOutsideClick } from "../hooks/OutsideClick";
import NoProfile from '../images/No-Image-Placeholder.png'
import { getPreviewUrl } from "../utils/common";
import { toast } from "react-toastify";
import { getChats, getRooms } from "../actions/chat.action";
import { Backdrop, Tooltip } from "@mui/material";
import Loader from "../components/custom-loader/loader";
// import InfiniteScroll from 'react-infinite-scroll-component';
const socket = io(socketUrl);
const allowedFileTypes = ['.png', '.jpg', '.pdf', '.doc', ' .ppt', '.jpeg', '.pptx', '.docx', '.xls', '.xlsx', '.txt'];
const allowedMimeTypes = ['image/png', 'image/jpeg', 'application/pdf', 'application/msword', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/plain',];

const RenderLeft = ({ onlineUsers, selectedRoomHandle, selectedRoom, roomList = [], chatsStatus, chatView = "All", onChange = () => { }, roomListUpdate = () => { }, setChatViewChange = () => { } }) => {
  const [search, setSearch] = useState("");
  const getFileUrl = (room) => {
    const imageLink = selectedRoomHandle(room)?.profileImage;
    if (room && imageLink) {
      return getPreviewUrl(imageLink);
    } else if (room && room.ticketDetails && room.ticketDetails.length !== 0 && room.ticketDetails.file.length !== 0 && room.ticketDetails.file[0].length !== 0 && Object.keys(room.ticketDetails.file[0]).length !== 0) {
      return getPreviewUrl(room.ticketDetails.file[0]);
    } else {
      return NoProfile
    }
  }
  const getChatRoomName = (item) => {
    return item?.ticketId ? "Ticket # - " + item?.ticketDetails?.ticketNumber : item.groupName ?? "";
  }

  const isOnline = (item) => {
    return onlineUsers?.hasOwnProperty(selectedRoomHandle(item)?._id || "")
  };
  // const getParticipantNames = (participants) => {
  //   var Names = [];
  //   participants.forEach(par => {
  //     Names.push(par.vendorName ?? par.userName);
  //   });
  //   return Names.join(" x ")
  // }
  return (
    <div className="border-r border-gray-300 lg:col-span-1">
      <div className="mx-3 my-3">
        <div className="relative text-gray-600">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2 svg-icon-storke">
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" className="w-6 h-6 text-gray-300" > <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path> </svg>
          </span>
          <input type="search" className="block w-full py-2 pl-10 bg-gray-100 rounded outline-none bg-color" name="search" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
        </div>
      </div>
      <div className="flex flex-wrap justify-between mb-2">
        <p className="my-2 px-1 mb-2 ml-2 text-lg font-worksans-medium text-gray-600"> Chats </p>
        <select className="border-gray-300 text-gray-600 outline-none rounded-l-md px-2" value={chatView} onChange={(e) => setChatViewChange(e.target.value)}>
          <option value={"All"}>All</option>
          <option value={"Tickets"}>Tickets</option>
          <option value={"Users"}>User & Vendor</option>
        </select>
        <select className="border-gray-300 text-gray-600 outline-none rounded-l-md px-2" value={chatsStatus} onChange={(e) => roomListUpdate(e.target.value)}>
          <option value={"Inbox"}>Inbox</option>
          <option value={"Archived"}>Archived</option>
        </select>
      </div>
      <ul className="overflow-y-auto scrollbar-thin scrollbar-thumb-bg-eunry scrollbar-track-bg-romance h-[79vh]">
        <li className="list-font">
          {[...roomList].filter((x) => { if (search !== "") { return getChatRoomName(x).toLowerCase().indexOf(search.toLowerCase()) !== -1 } return true; }).map((item, i) => (
            <a key={i} className={`flex items-start px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-gray-300 cursor-pointer hover:bg-gray-100 focus:outline-none ${selectedRoom._id === item._id && 'bg-grey-300'}`} >
              <div className="rounded-full bg-blue-300 h-12 w-12 relative" style={{ maxHeight: "3rem", minWidth: "3rem" }}>
                <img className="object-cover w-full h-full rounded-full" src={getFileUrl(item)} alt="" />
                {item?.ticketId &&
                  <div className={`rounded-full h-4 w-4 ml-2 absolute right-0 bottom-0 ${isOnline(item) ? "bg-green-500" : "bg-grey-300"}`} />
                }
              </div>
              <div className="w-full pb-2" onClick={() => onChange(item)}>
                <div className="flex flex-items-center justify-between">
                  <span className="block ml-2 text-gray-600 text-lg font-worksans-medium text-ellipsis"
                    title={getChatRoomName(item)}>
                    {getChatRoomName(item)}
                  </span>
                  <span className="block ml-2 text-sm text-gray-600">
                    {item.lastMessage && item.lastMessage.timeStamp ? moment(item.lastMessage.timeStamp).format("hh:mm A") : ""}
                  </span>
                </div>
                <div className="flex justify-between">
                  <div>
                    <span className="block ml-2 text-sm text-gray-600 text-ellipsis">
                      {(item.lastMessage && item.lastMessage) && (
                        <>
                          {
                            item.lastMessage.messageType === "text" && (item?.lastMessage?.message ?? "No Last Message")
                          }
                          {
                            item.lastMessage.messageType === "media" &&
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-image" viewBox="0 0 16 16" > {" "} <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />{" "} <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z" />{" "} </svg>
                          }
                        </>
                      )}
                    </span>
                  </div>
                  <div className="unread-box rounded-full bg-primary px-2 text-white">
                    {item.unreadMessage !== 0 && <span>{item.unreadMessage}</span>}
                  </div>
                </div>
              </div>
            </a>
          ))}
        </li>
      </ul>
    </div>
  );
};

const RenderCenter = ({ message, isOnline = false, messagesEndRef, messageList = [], selectedRoom, updateChatStatus = () => { }, updateMessageEmoji = () => { }, handleChange = () => { }, onKeyPress = () => { }, updateMessageInput = () => { }, readMessage = () => { }, selectedRoomHandle = () => { } }) => {
  const [show, setShow] = useState(false);
  const ref = useOutsideClick(() => setShow(false));
  const wrapperRef = useRef(null);
  const [showEmojis, setShowEmojis] = useState(false);
  const [hidden, setHidden] = useState(true);
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [showEmojis]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        buttonRef?.current &&
        (hidden ||
          buttonRef?.current.contains(event.target) ||
          dropdownRef?.current.contains(event.target))
      ) {
        return false;
      }
      setHidden(true);
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hidden, ref, buttonRef]);

  const getFileUrlForMsg = (msg) => {
    const url = getPreviewUrl(msg?.mediaUrl);
    switch (msg.mediaType) {
      case 'image/png':
      case 'image/jpeg':
        return <a rel="noreferrer" style={{ display: "block" }} href={url} target="_blank"><img src={url} alt="user" style={{ width: "250px", height: "150px" }} /></a>;
      default:
        return <a rel="noreferrer" style={{ display: "block", textDecoration: "underline" }} href={url} target="_blank">({msg?.filename ?? "File"}) - Download</a>
    }
  };

  const getFileUrl = (room) => {
    const imageLink = selectedRoomHandle(room)?.profileImage;
    if (room && imageLink) {
      return getPreviewUrl(imageLink);
    } else if (room && room.ticketDetails && room.ticketDetails.length !== 0 && room.ticketDetails.file.length !== 0 && room.ticketDetails.file[0].length !== 0 && Object.keys(room.ticketDetails.file[0]).length !== 0) {
      return getPreviewUrl(room.ticketDetails.file[0]);
    } else {
      return NoProfile
    }
  }

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowEmojis(false);
    }
  };

  const getChatRoomName = (item) => {
    return item?.ticketId ? "Ticket # - " + item?.ticketDetails?.ticketNumber : item?.groupName ?? "";
  }

  let currentDay = moment().format("DD/MM/YY");
  let chatTime = '';
  const chatDateBar = (timeStamp) => {
    const time = moment(timeStamp).format('DD/MM/YY');
    if (chatTime !== time) {
      chatTime = time;
      if (currentDay === chatTime) {
        return <div className="chatDate"><span className="space"><span className="chatTime">Today</span></span></div>;
      } else {
        return <div className="chatDate"><span className="space"><span className="chatTime">{moment(timeStamp).format('DD MMM YYYY')}</span></span></div>;
      }
    }

    return null;
  }

  const textAreaAdjust = (element) => {
    if (element.value !== "") {
      element.style.height = "1px";
      element.style.height = (25 + element.scrollHeight) + "px";
    } else {
      element.style.height = "";
    }
  }

  return (
    <div className="hidden lg:col-span-2 lg:block">
      <div className="w-full">
        {selectedRoom && (
          <div className="relative flex items-center p-3 border-b border-gray-300 justify-between">
            <div className="flex items-center">
              <div className="rounded-full bg-blue-300 w-10 h-10 relative">
                <img className="object-cover w-10 h-10 rounded-full" src={getFileUrl(selectedRoom)} alt="room" onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = NoProfile; }} />
                {selectedRoom?.ticketId &&
                  <div className={`rounded-full h-4 w-4 ml-2 absolute right-0 bottom-0 ${isOnline ? "bg-green-500" : "bg-grey-300"}`} />
                }
              </div>
              <span className="block ml-2 font-bold text-gray-600 text-lg">{getChatRoomName(selectedRoom)}</span>
            </div>
            {selectedRoom?.ticketId ?
              <div className="relative cursor-pointer">
                <div ref={buttonRef} onClick={() => setHidden(!hidden)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16" > {" "} <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />{" "} </svg>
                </div>
                <div ref={dropdownRef} className={`dropdown absolute top-0 left-0 dropdown-box ${hidden ? "" : "open"}`} >
                  <div ref={dropdownRef} className={`dropdown-content bottom-start multiselect-dropdown p-1 ${hidden ? "" : "open"}`} >
                    <button onClick={() => updateChatStatus(selectedRoom.status === 2 ? 'Archived' : 'Inbox', selectedRoom)} style={{ textTransform: "none" }} className="btn w-32 normal-case text-xs py-2 bg-white hover:bg-grey-100 text-grey-900">
                      {selectedRoom.status === 1 ? 'Move to archive' : 'Move to inbox'}
                    </button>
                  </div>
                </div>
              </div>
              :
              null
            }
          </div>
        )}
        {messageList.length !== 0 ? (
          <div className="relative w-full p-6 scrollbar-track-bg-romance h-[60vh] h-cus" style={{ overflow: "auto", display: "flex", flexDirection: "column-reverse" }} >
            <ul className="space-y-2">
              {messageList.map((item, index) => {
                const receiverId = selectedRoom?.participants[0]?._id ?? "";
                return (
                  <>
                    {chatDateBar(item.timeStamp)}
                    <div key={`msg_${index}`} ref={messagesEndRef}>
                      {item.roomId === selectedRoom._id && (
                        <li className={`flex justify-${item.senderId === receiverId ? 'end' : 'start'}`}>
                          <div className={`max-w-md relative px-4 py-2 text-gray-700 ${item.senderId === receiverId ? 'bg-grey-400' : 'bg-grey-100'} rounded shadow chat-list`}>
                            {item?.messageType === "media" ?
                              <>
                                {getFileUrlForMsg(item)}
                              </>
                              :
                              <span className="block text-lg" style={{ whiteSpace: "pre-line" }}> {item.message} </span>
                            }
                            <span style={{ fontSize: "10px", float: "right" }} > {moment(item.timeStamp).format("hh:mm A")} </span>
                          </div>
                        </li>
                      )}
                    </div>
                  </>
                );
              })}
            </ul>
          </div>
        ) : (
          <div className="relative w-full p-6 overflow-y-auto scrollbar-thin scrollbar-thumb-bg-eunry scrollbar-track-bg-romance h-[60vh] h-cus">
            <img src={NoDataImg} className="img-fluid" alt="No Data Found" />
          </div>
        )}

        {selectedRoom && selectedRoom?.ticketId ? (
          <div className="flex items-center justify-between w-full p-3 mt-8 border-t border-gray-300 emoji-parent-box">
            <div ref={wrapperRef}>
              {selectedRoom && showEmojis && (
                <div className="emoji_child_box">
                  <EmojiPicker height={350} width="100%" emojiStyle={EmojiStyle.NATIVE} autoFocusSearch={false} onEmojiClick={(e) => updateMessageEmoji(e)} />
                </div>
              )}
              <button onClick={() => setShowEmojis(!showEmojis)} className="svg-icon-storke" >
                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="blue-50" > <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /> </svg>
              </button>
            </div>
            <label htmlFor="upload-button">
              <Tooltip title={`Allowed types: (${allowedFileTypes.join(', ')})`}>
                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" > <path style={{ stroke: "#6b7280" }} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" /> </svg>
              </Tooltip>
            </label>

            <input type="file" id="upload-button" multiple style={{ display: "none" }} accept={allowedFileTypes.join(', ')} onChange={(e) => handleChange(e.target.files)} />

            <textarea
              placeholder="Message"
              className="block w-full py-2 pl-4 mx-3 bg-gray-100 outline-none focus:text-gray-700 chat-box"
              name="message"
              value={message}
              onKeyUp={(e) => textAreaAdjust(e.target)}
              onKeyDown={(e) => {
                if (e.code === "Enter" && (e.altKey || e.ctrlKey)) {
                  updateMessageInput(message + "\n");
                }
              }}
              onKeyPress={(e) => {
                if (e.code === "Enter" && !e.shiftKey && !e.altKey && !e.ctrlKey) {
                  e.preventDefault();
                  onKeyPress(message.trim());
                  setShowEmojis(false)
                  return;
                }
              }}
              onFocus={() => {
                readMessage();
              }}
              onChange={(e) => updateMessageInput(e.target.value)}
              required
            />
            <button className="svg-icon-fill" type="submit" onClick={() => { onKeyPress(message); setShowEmojis(false) }}>
              <svg className="w-5 h-5 text-gray-500 origin-center transform rotate-90" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" > <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" /> </svg>
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const RenderRight = ({ selectedRoom }) => {
  return (
    <div className="hidden lg:col-span-1 lg:block border-left">
      <div className="w-full">
        <div className="overflow-y-auto scrollbar-thin scrollbar-thumb-bg-eunry scrollbar-track-bg-romance h-[93vh] ticket-box">
          <div className="w-full mt-14">
            {(selectedRoom?.ticketId) &&
              <div className="space-y-2 p-3">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <a href={`/ticketList/${selectedRoom.ticketDetails?.ticketNumber}`} className="text-lg font-worksans-medium">{'Ticket # - ' + selectedRoom.ticketDetails?.ticketNumber}</a>
                  </div>
                </div>
                <div className="flex justify-between">
                  <p>Name</p>
                  <p className="font-worksans-medium">{selectedRoom.ticketDetails?.firstName + ' ' + selectedRoom.ticketDetails?.lastName}</p>
                </div>
                <div className="flex justify-between">
                  <p>Phone Number</p>
                  <p className="font-worksans-medium">{selectedRoom.ticketDetails?.phoneNumber}</p>
                </div>
                <div className="flex justify-between">
                  <p>Email</p>
                  <p className="font-worksans-medium">{selectedRoom.ticketDetails?.email}</p>
                </div>
                <div className="flex justify-between">
                  <p>Topic</p>
                  <p className="font-worksans-medium">{selectedRoom.ticketDetails?.topic}</p>
                </div>
                <div className="flex justify-between">
                  <p>Order No</p>
                  <p className="font-worksans-medium">#{selectedRoom.ticketDetails?.orderNumber}</p>
                </div>
                <hr className="mt-4 mb-2" />
              </div>
            }

            {selectedRoom.participants.map(user => (
              <div className="space-y-2 p-3">
                {user.type !== 'superadmin' &&
                  <>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <p className="text-lg font-worksans-medium">About {user?.userName ?? user?.vendorName}</p>
                      </div>
                    </div>

                    {user.type !== 'user' ?
                      <>
                        {
                          user?.profileSummary &&
                          <p title={user?.profileSummary} style={{ marginTop: 20, marginBottom: 20 }}>
                            {user?.profileSummary.length > 100 ? `${user?.profileSummary.slice(0, 100)}...` : user?.profileSummary}
                          </p>
                        }
                        <div className="flex justify-between">
                          <p>ID</p>
                          <p className="font-worksans-medium">{user?.vendorId}</p>
                        </div>
                        <div className="flex justify-between">
                          <p>Last Login</p>
                          <p className="font-worksans-medium">{user?.lastLogin ? moment(user?.lastLogin.createdAt).format("DD-MM-YY hh:mm A") : "Not Yet"}</p>
                        </div>
                        <div className="flex justify-between">
                          <p>From</p>
                          <p className="font-worksans-medium">{user?.streetAddress1}</p>
                        </div>
                        <div className="flex justify-between">
                          <p>Products</p>
                          <p className="font-worksans-medium">
                            <a target="_blank" rel="noreferrer" href={`https://client-weddingserv.netlify.app/vendor-detail/${user?._id}#products`}>
                              {user?.productCount}
                            </a>
                          </p>
                        </div>
                        {/* <div className="flex justify-between items-center hover:text-bg-eunry cursor-pointer">
                      <p>Ratings</p>
                      <div className="flex items-center">
                        <AiFillStar size={15} />
                        <p className="font-worksans-medium">5</p>
                        <p className="ml-2">(320 Reviews)</p>
                      </div>
                    </div> */}
                        <div className="py-3">
                          <div className="flex justify-between items-center mb-3">
                            <p className="font-worksans-medium text-lg">Services</p>
                            {/* <button className="cursor-pointer hover:underline text-blue-600">View Inbox</button> */}
                          </div>
                          <div className="w-full pb-2 mb-2 border-b border-gray-300">
                            <div className="flex items-center space-x-2">
                              <div className="w-100">
                                <ul className="px-2">
                                  {user?.sellerOffering?.services?.map((service, index) => (
                                    <li key={`serv_${index}`} className="flex items-center gap-2">
                                      <svg stroke="#2196f3" fill="#2196f3" strokeWidth="0" version="1.2" baseProfile="tiny" viewBox="0 0 24 24" height="16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="M12 10.375c0-2.416-1.959-4.375-4.375-4.375s-4.375 1.959-4.375 4.375c0 1.127.159 2.784 1.75 4.375l7 5.25s5.409-3.659 7-5.25 1.75-3.248 1.75-4.375c0-2.416-1.959-4.375-4.375-4.375s-4.375 1.959-4.375 4.375"></path></svg>
                                      {service.name}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                      :
                      <>
                        <div className="flex justify-between" style={{ marginTop: 20 }}>
                          <p>Wedding Date</p>
                          <p className="font-worksans-medium">{moment(user?.weddingDate).format("DD/MM/YYYY")}</p>
                        </div>
                      </>
                    }
                    <hr className="mt-4 mb-2" style={{ marginBottom: 15 }} />
                  </>
                }
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};


const Message = () => {
  const { ticketId } = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  const queryChatStatus = searchParams.get('status') ?? "Inbox";
  const adminData = useMemo(() => JSON.parse(localStorage.getItem('wed_admin_adata')));
  const [message, setMessage] = useState("");
  const [selectedRoom, setSelectedRoom] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [roomList, setRoomList] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState(null);

  const messagesEndRef = useRef(null);
  const roomListRef = useRef(roomList);
  const messageListRef = useRef(messageList);
  const selectedRoomRef = useRef(selectedRoom);

  const [showEmojis, setShowEmojis] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [chatsStatus, setChatsStatus] = useState(queryChatStatus);
  const [chatView, setChatView] = useState('All');
  const dispatch = useDispatch();

  const getRoomsList = (status, view) => {
    let tempStatus = status ?? chatsStatus;
    let tempView = view ?? chatView;
    var adminData = JSON.parse(localStorage.getItem('wed_admin_adata'));
    setIsLoaded(false);
    dispatch(
      getRooms({
        data: {
          status: tempStatus,
          userId: adminData?.id,
          type: "superadmin"
        },
        onSuccess: (data) => {
          let chatRooms = data;
          if (tempView === 'Tickets') {
            chatRooms = chatRooms.filter(x => x.ticketId);
          } else if (tempView === 'Users') {
            chatRooms = chatRooms.filter(x => !x.ticketId);
          }
          setRoomList(chatRooms);
          if (ticketId) {
            for (let i = 0; i < chatRooms.length; i++) {
              if (chatRooms[i].ticketId && chatRooms[i].ticketId === ticketId) {
                getMessages(chatRooms[i]);
                break;
              }
            }
          }
          scrollToBottom();
          setIsLoaded(true);
        },
        onFailed: () => {
          setIsLoaded(true);
          toast.error("Error while loading chats please try again!");
        },
      })
    );
  }

  const getChatsList = (selectedRoomId, lastMessageTimeStamp) => {
    dispatch(
      getChats({
        data: { roomId: selectedRoomId, lastMessageTimeStamp },
        onSuccess: (data) => {
          console.log('onSuccess', data);
          setMessageList(data.docs);
          scrollToBottom();
        },
        onFailed: () => {
          toast.error("Error while loading messages please try again!");
        },
      })
    );
  }

  const scrollToBottom = () => {
    if (messageList && messageList.length !== 0 && selectedRoom && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
    }
  };

  useEffect(() => {
    roomListRef.current = roomList;
    messageListRef.current = messageList;
    selectedRoomRef.current = selectedRoom
  }, [roomList, messageList, selectedRoom]);

  useEffect(() => {
    scrollToBottom();
  }, [message]);

  const updateLeftRender = (data) => {
    let messageList = messageListRef?.current ?? [];
    let roomList = roomListRef?.current ?? [];
    let selectedRoom = selectedRoomRef?.current ?? {};
    if (selectedRoom?._id === data.lastMessage.roomId && data.lastMessage && messageList?.findIndex(x => x._id === data.lastMessage._id) === -1) {
      setMessageList((prev) => [...prev, data.lastMessage]);
      scrollToBottom();
    }

    var filteredRoom = [];
    var matchRoom = {};
    roomList.forEach((rl) => {
      if (rl._id !== data.lastMessage.roomId) {
        filteredRoom.push(rl);
      } else {
        rl.lastMessage = data.lastMessage;
        if (selectedRoom?._id !== data.lastMessage.roomId) {
          rl.unreadMessage = rl.unreadMessage + 1;
        }
        matchRoom = rl;
      }
    });

    if (matchRoom) {
      filteredRoom.unshift(matchRoom);
    }
    setRoomList(filteredRoom);
  }

  const readMessageFromSocket = (data) => {
    let roomList = roomListRef?.current ?? [];
    let selectedRoom = selectedRoomRef?.current ?? {};
    var filteredRoom = [];
    roomList.forEach((rl) => {
      if (rl._id === data.roomId) {
        rl.unreadMessage = 0;
      }
      if (selectedRoom && selectedRoom?._id !== data.roomId) {
        selectedRoom.unreadMessage = 0;
        setSelectedRoom(selectedRoom);
      }
      filteredRoom.push(rl);
    });

    setRoomList(filteredRoom);
  }

  useEffect(() => {
    getRoomsList();

    socket.connect();

    const bindSocketEvents = () => {
      var adminData = JSON.parse(localStorage.getItem('wed_admin_adata'));
      socket.emit("init", {
        userId: adminData?.id,
        userName: adminData?.name,
        userType: adminData?.type
      });

      socket.on("receiveMessage", (receiveMessage) => {
        updateLeftRender(receiveMessage);
      });

      socket.on("messageSave", (data) => {
        updateLeftRender(data);
        setShowEmojis(false);
        updateMessageInput("");
      });

      socket.on("updateRooms", (data) => {
        getRoomsList(data.status);
      });

      socket.on("messageRead", (data) => {
        readMessageFromSocket(data);
      });

      socket.on("isOnlineStatus", (data) => {
        setOnlineUsers(data);
      });
    }

    socket.on("connect", () => {
      console.log("Socket Connected");
      bindSocketEvents();
    });

    if (socket.connected) {
      bindSocketEvents();
    }

    socket.on("disconnect", () => {
      console.log("Socket Disconnected");
    });

    updateMessageInput("");

    return () => {
      socket.off('connect');
      socket.off('messageSave');
      socket.off('receiveMessage');
      socket.off('updateRooms');
      socket.off('isOnlineStatus');
      socket.off('disconnect');
      socket.disconnect();
    };
  }, []);

  const updateMessageInput = (input) => {
    setMessage(input);
  };

  const updateMessageEmoji = (input) => {
    var value = message + input.emoji
    setMessage(value);
  };

  const handleKeyPress = (message) => {
    if (message === "") {
      return;
    }
    socket.emit("sendMessage", {
      roomId: selectedRoom._id,
      senderId: adminData?.id,
      message: message,
      senderType: "admin",
    });
  };


  const handleChange = (files) => {
    setIsLoaded(false);
    if (files.length > 0) {
      for (let index = 0; index < files.length; index++) {
        const f = files[index];
        if (!allowedMimeTypes.includes(f.type)) {
          setIsLoaded(true);
          toast.error('No files are uploaded, one or more file type is invalid. Please only select (' + allowedFileTypes.join(", ") + ')');
          const fileInput = document.getElementById('upload-button');
          if (fileInput) {
            fileInput.value = '';
          }
          return false;
        }
      }

      dispatch(
        uploadMediaAction({
          data: {
            type: "group",
            roomId: selectedRoom._id,
            senderId: adminData?.id,
            files: files
          },
          onProgress: (progress) => {
            console.log("uploadMediaAction onProgress", progress);
          },
          onSuccess: (data) => {
            console.log("uploadMediaAction onSuccess", data);
            setIsLoaded(true);
            const fileInput = document.getElementById('upload-button');
            if (fileInput) {
              fileInput.value = '';
            }
          },
          onFailed: () => {
            setIsLoaded(true);
            const fileInput = document.getElementById('upload-button');
            if (fileInput) {
              fileInput.value = '';
            }
          }
        })
      );
    }
  };

  const readMessage = () => {
    var data = { userId: adminData?.id, type: adminData?.type, roomId: selectedRoom?._id };
    if (selectedRoom.unreadMessage > 0) {
      socket.emit("readMessage", data);
      readMessageFromSocket(data);
    }
  }

  useEffect(() => {
    readMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoom]);

  const getMessages = (selectedRoom) => {
    var lastMessageTimeStamp = "";
    setSelectedRoom(selectedRoom);
    if (message && message.length !== 0) {
      lastMessageTimeStamp = message[0].timeStamp;
    }
    getChatsList(selectedRoom._id, lastMessageTimeStamp);
  };

  const selectedRoomHandle = (selectedRoom) => {
    var userInfo = {}
    if (selectedRoom) {
      for (let i = 0; i < selectedRoom.participants.length; i++) {
        const element = selectedRoom.participants[i];
        if (adminData?.id !== element?._id) {
          userInfo = element;
          userInfo['displayName'] = userInfo.type === 'user' ? (userInfo?.email).split('@')[0] : userInfo.vendorName;
          userInfo['userType'] = userInfo.categories && userInfo.categories.length ? 'vendor' : 'user';
          if (adminData?.type === 'SUPERADMIN' || adminData?.type === 'superadmin') {
            userInfo['userType'] = 'SUPERADMIN';
          }
          if (userInfo?.profileImage === "" && userInfo["userType"] === "user") {
            if (userInfo?.brideImage) {
              userInfo["profileImage"] = userInfo?.brideImage;
            } else if (userInfo?.groomImage) {
              userInfo["profileImage"] = userInfo?.groomImage;
            }
          }
        }
      }
    }
    return userInfo;
  }

  const changeStatus = (status, selectedRoom) => {
    socket.emit("updateRoomStatus", {
      roomId: selectedRoom._id,
      userId: adminData?.id,
      status: status,
      type: adminData?.type
    });
    setSelectedRoom("");
    setMessageList([]);
  }

  const roomListUpdate = (type) => {
    setChatsStatus(type);
    setMessageList([]);
    setSelectedRoom("");
    getRoomsList(type);
  }

  const setChatViewChange = (type) => {
    setMessageList([]);
    setSelectedRoom("");
    setChatView(type);
    getRoomsList(chatsStatus, type);
  }

  return (
    <>
      <SectionTitle title="Chat" subtitle="Chats" />
      <div className="min-w-full border rounded lg:grid lg:grid-cols-4">
        <RenderLeft
          onlineUsers={onlineUsers}
          chatView={chatView}
          setChatViewChange={setChatViewChange}
          roomList={roomList}
          selectedRoom={selectedRoom}
          chatsStatus={chatsStatus}
          onChange={getMessages}
          selectedRoomHandle={selectedRoomHandle}
          roomListUpdate={roomListUpdate}
        />
        <RenderCenter
          messageList={messageList}
          userId={adminData?.id}
          messagesEndRef={messagesEndRef}
          message={message}
          isOnline={onlineUsers?.hasOwnProperty(selectedRoomHandle(selectedRoom)?._id || "")}
          onKeyPress={handleKeyPress}
          updateMessageInput={updateMessageInput}
          updateMessageEmoji={updateMessageEmoji}
          readMessage={readMessage}
          selectedRoom={selectedRoom}
          handleChange={handleChange}
          showEmojis={showEmojis}
          updateChatStatus={changeStatus}
          selectedRoomHandle={selectedRoomHandle}
        />
        {selectedRoom &&
          <RenderRight selectedRoom={selectedRoom} />
        }
      </div>
      <div style={{ paddingBottom: 300 }}></div>
      {!isLoaded &&
        <Backdrop open={true} sx={{ zIndex: 9999 }}>
          <Loader />
        </Backdrop>
      }
    </>
  );
};

export default Message;
