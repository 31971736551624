import { CircularProgress, Switch } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BsChevronDown, BsInfoCircle } from "react-icons/bs";
import { HiOutlineTrash } from "react-icons/hi";
import { useSelector } from "react-redux";
import { fetcher } from "../../utils/common";
import AddPhysicalMeeting from "./addPhysicalMeeting";
import SelectDate from "./SelectDate";

const GeneralSetting = () => {
  // const adminToken = localStorage.getItem("wed_admin_token");
  const adminToken = useSelector((state) => state.adminAuthSlice.token.access.token);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dayOff, setDayOff] = useState([
    {
      date: "",
      openingHour: "",
      closingHour: "",
      isNationalHoliday: false,
    },
  ]);
  const [formGeneralSetting, setFormGeneralSetting] = useState({
    concurrentMeetingEnabled: false,
    concurrentMeetingNumber: 0,
    customOffDays: [],
    generalMeetingBuffer: 0,
    generalMeetingSlots: 0,
    generalOpeningHours: [],
    offlineMeetingLocations: [],
  });

  const openHours = [
    { id: 1, day: "Monday", open: "09:00", close: "17:00" },
    { id: 2, day: "Tuesday", open: "09:00", close: "17:00" },
    { id: 3, day: "Wednesday", open: "09:00", close: "17:00" },
    { id: 4, day: "Thursday", open: "09:00", close: "17:00" },
    { id: 5, day: "Friday", open: "09:00", close: "17:00" },
    { id: 6, day: "Saturday", open: "09:00", close: "17:00" },
    { id: 7, day: "Sunday", open: "09:00", close: "17:00" },
  ];

  const addDayOff = () => {
    setDayOff([
      ...dayOff,
      {
        date: "",
        openingHour: "",
        closingHour: "",
        isNationalHoliday: false,
      },
    ]);
  };

  const removeDayOff = (index) => {
    let _dayOff = [...dayOff];
    _dayOff.splice(index, 1);
    setDayOff(_dayOff);
  };

  const handleDayOff = (key, value, index) => {
    let _dayOff = [...dayOff];
    _dayOff[index][key] = value;
    setDayOff(_dayOff);
  };

  const _getScheduleSetting = async () => {
    try {
      const result = await fetcher(
        "get",
        `/admin/appointment/setting`,
        adminToken
      );

      const data = result?.data;
      setFormGeneralSetting({
        ...data,
        concurrentMeetingEnabled: data.concurrentMeetingEnabled,
        concurrentMeetingNumber: data.concurrentMeetingNumber,
        customOffDays: data.customOffDays,
        generalMeetingBuffer: data.generalMeetingBuffer,
        generalMeetingSlots: data.generalMeetingSlots,
        generalOpeningHours:
          data.generalOpeningHours.length > 0
            ? data.generalOpeningHours
            : openHours,
        offlineMeetingLocations: data.offlineMeetingLocations,
      });
      setDayOff(data.customOffDays.length > 0 && data.customOffDays);
    } catch (error) {
    }
  };

  useEffect(() => {
    _getScheduleSetting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _updateScheduleSetting = async () => {
    let params = { ...formGeneralSetting, customOffDays: [...dayOff] };

    setLoading(true);
    await fetcher(
      "post",
      `/admin/appointment/setting/update`,
      adminToken,
      JSON.stringify(params)
    );
    setLoading(false);
    _getScheduleSetting();
  };

  const handleOpeningHours = (key, value, index) => {
    let _value = value + ":00";
    let _generalOpeningHours = [...formGeneralSetting.generalOpeningHours];
    _generalOpeningHours[index][key] = _value;
    setFormGeneralSetting({
      ...formGeneralSetting,
      generalOpeningHours: _generalOpeningHours,
    });
  };

  return (
    <>
      <div className="mt-10 text-xl">
        <div
          onClick={() => setOpen(!open)}
          className="flex justify-between items-center cursor-pointer"
        >
          <p className="font-worksans-semibold text-2xl">General Setting</p>
          <BsChevronDown
            size={20}
            className={`transition-all ${open ? `rotate-180` : `rotate-0`}`}
          />
        </div>
        <div>
          <div className="mt-10">
            <p className="font-worksans-semibold">Opening Hours:</p>
            {formGeneralSetting.generalOpeningHours.map((item, index) => (
              <div key={index} className="flex items-center mt-1">
                <div className="mr-auto">
                  <p>{item.day}</p>
                </div>
                <div className="flex items-center space-x-2">
                  <div className="w-full">
                    <input
                      onChange={(e) =>
                        handleOpeningHours("openingHour", e.target.value, index)
                      }
                      value={item.openingHour}
                      type="time"
                      className="w-full py-2 px-2 rounded-lg border-2 flex justify-between items-center z-10 cursor-pointer"
                    />
                  </div>
                  <p>-</p>
                  <div className="w-full">
                    <input
                      onChange={(e) =>
                        handleOpeningHours("closingHour", e.target.value, index)
                      }
                      value={item.closingHour}
                      type="time"
                      className="w-full py-2 px-2 rounded-lg border-2 flex justify-between items-center z-10 cursor-pointer"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-10">
            <div className="w-full flex justify-between items-center">
              <p className="font-worksans-semibold">Custom Off Days:</p>
              <button
                onClick={() => addDayOff()}
                className="w-1/4 py-1 rounded-md bg-bg-primary text-lg text-white hover:opacity-70"
              >
                Add Date
              </button>
            </div>
            {dayOff.map((item, index) => {
              return (
                <div key={index} className="mt-3 w-full">
                  <div className="w-full flex items-center space-x-2">
                    <div className="flex items-center gap-x-2 w-full">
                      <div className="w-1/3">
                        <p>Date</p>
                        <SelectDate
                          onChange={(e) =>
                            handleDayOff(
                              "date",
                              moment(e).format("DD-MMM-YYYY"),
                              index
                            )
                          }
                          value={item.date}
                        />
                      </div>
                      <div className="w-1/3">
                        <p>Start Time</p>
                        <input
                          onChange={(e) =>
                            handleDayOff("openingHour", e.target.value, index)
                          }
                          value={item.openingHour}
                          type="time"
                          className="w-full py-2 text-base px-2 rounded-lg border-2 flex justify-between items-center z-10 cursor-pointer"
                        />
                      </div>
                      <div className="w-1/3">
                        <p>End Time</p>
                        <input
                          onChange={(e) =>
                            handleDayOff("closingHour", e.target.value, index)
                          }
                          value={item.closingHour}
                          type="time"
                          className="w-full py-2 text-base px-2 rounded-lg border-2 flex justify-between items-center z-10 cursor-pointer"
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => removeDayOff(index)}
                      className="bg-bg-primary p-2 rounded-md text-white mt-8 cursor-pointer hover:opacity-70"
                    >
                      <HiOutlineTrash size={20} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mt-10">
            <p className="font-worksans-semibold">General Meeting Buffer:</p>
            <div className="flex w-full">
              <input
                onChange={(e) =>
                  setFormGeneralSetting({
                    ...formGeneralSetting,
                    generalMeetingBuffer: e.target.value,
                  })
                }
                value={formGeneralSetting.generalMeetingBuffer}
                type="number"
                className="outline-none border w-1/2 border-bg-primary px-2 py-1"
              />
              <div className="bg-bg-primary text-white flex items-center justify-start px-3 w-1/2">
                <p>min to next appointment</p>
              </div>
            </div>
          </div>
          <div className="mt-10">
            <p className="font-worksans-semibold">General Meeting Slots:</p>
            <div className="flex w-full">
              <input
                onChange={(e) =>
                  setFormGeneralSetting({
                    ...formGeneralSetting,
                    generalMeetingSlots: e.target.value,
                  })
                }
                value={formGeneralSetting.generalMeetingSlots}
                type="number"
                className="outline-none border w-1/2 border-bg-primary px-2 py-1"
              />
              <div className="bg-bg-primary text-white flex items-center justify-start px-3 w-1/2">
                <p>min for each meeting</p>
              </div>
            </div>
          </div>
          <div className="mt-10">
            <div className="flex items-center space-x-3">
              <span
                className="flex items-center space-x-2 cursor-pointer"
                title="Please check if you have the resources to fend multiple concurrent meetings. Otherwise to reschedule."
              >
                <p className="font-worksans-semibold">Concurrent Meetings</p>
                <BsInfoCircle />
              </span>
              <Switch
                onChange={() =>
                  setFormGeneralSetting({
                    ...formGeneralSetting,
                    concurrentMeetingEnabled:
                      !formGeneralSetting.concurrentMeetingEnabled,
                  })
                }
                checked={formGeneralSetting.concurrentMeetingEnabled}
              />
            </div>
            <div className="flex w-full">
              {formGeneralSetting.concurrentMeetingEnabled ? (
                <>
                  <input
                    onChange={(e) =>
                      setFormGeneralSetting({
                        ...formGeneralSetting,
                        concurrentMeetingNumber: e.target.value,
                      })
                    }
                    value={formGeneralSetting.concurrentMeetingNumber}
                    type="number"
                    className="outline-none border w-1/2 border-bg-primary px-2 py-1"
                  />
                  <div className="bg-bg-primary text-white w-full flex items-center justify-start px-3">
                    <p>concurrent meetings allowed</p>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          {/* <div className="mt-10">
            <NationalHolidays />
          </div> */}
          <div className="mt-10">
            <div>
              {/* <PhysicalMeeting /> */}
              <AddPhysicalMeeting />
            </div>
          </div>
          <div className="mt-10 flex justify-end">
            <button
              onClick={() => _updateScheduleSetting()}
              className="w-32 bg-bg-primary flex justify-center items-center rounded-md text-white py-2"
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Save"
              )}
            </button>
          </div>
          {/* <div className="mt-10">
            <p className="font-worksans-semibold">Invite Team Members:</p>
            <div>
              <InviteTeamMember />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default GeneralSetting;
