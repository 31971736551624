import React, { useState, useEffect } from "react";
import { FiChevronRight } from "react-icons/fi";

const Category = ({ name, _id, product_types, discountPlans, setDiscountPlans }) => {
  const [hidden, setHidden] = useState(true);
  const [categorieList, setCategoriesList] = useState([]);

  const handleMaseterCommission = (value) => {
    let discountPlane = [...discountPlans];
    for (let i = 0; i < discountPlane.length; i++) {
      if (_id === discountPlane[i].masterCategory) {
        discountPlane[i].commission = parseInt(value);
      }
    }
    setDiscountPlans(discountPlane);
  };

  const handleMaseterDiscounts = (value) => {
    let discountPlane = [...discountPlans];
    for (let i = 0; i < discountPlane.length; i++) {
      if (_id === discountPlane[i].masterCategory) {
        discountPlane[i].discount = parseInt(value);
      }
    }
    setDiscountPlans(discountPlane);
  };

  const getCategoryListUsingMasterId = () => {
    let full_arr = product_types;
    setCategoriesList(full_arr);
    let obj = {
      masterCategory: _id,
      productTypeWiseDiscountPlan: [],
      discount: 0,
      commission: 0,
    };
    for (let i = 0; i < full_arr.length; i++) {
      let emtObj = {
        productTypeId: full_arr[i]._id,
        discount: 0,
        commission: 0,
      };
      obj.productTypeWiseDiscountPlan.push(emtObj);
    }
    setDiscountPlans((rowData) => [...rowData, obj]);
  };

  useEffect(() => {
    getCategoryListUsingMasterId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCategory = () => {
    if (categorieList && categorieList.length > 0) {
      setHidden(!hidden);
    }
  };

  const handleDiscounts = (value, catId) => {
    let discountPlane = [...discountPlans];
    for (let i = 0; i < discountPlane.length; i++) {
      if (_id === discountPlane[i].masterCategory) {
        let categoryData = discountPlane[i].productTypeWiseDiscountPlan;
        for (let j = 0; j < categoryData.length; j++) {
          if (catId === categoryData[j].productTypeId) {
            categoryData[j].discount = parseInt(value);
          }
        }
      }
    }
    setDiscountPlans(discountPlane);
  };
  const handleCommission = (value, catId) => {
    let discountPlane = [...discountPlans];
    for (let i = 0; i < discountPlane.length; i++) {
      if (_id === discountPlane[i].masterCategory) {
        let categoryData = discountPlane[i].productTypeWiseDiscountPlan;
        for (let j = 0; j < categoryData.length; j++) {
          if (catId === categoryData[j].productTypeId) {
            categoryData[j].commission = parseInt(value);
          }
        }
      }
    }
    setDiscountPlans(discountPlane);
  };

  return (
    <div className="flex items-center justify-start py-2">
      <div className="flex flex-col w-full">
        <div className="flex flex-row items-center justify-between">
          <button
            onClick={() => handleCategory()}
            className="bg-transparent text-left text-grey-900 flex flex-row items-center justify-start w-full"
          >
            <span
              className="font-bold text-sm flex flex-row items-center justify-between py-1 px-2"
              style={{ width: "100%" }}
            >
              <span className="font-bold text-sm">L1 - {name}</span>
              <div
                className="relative flex-auto commission_box"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  borderBottom: "none",
                  paddingBottom: "0px",
                }}
              >
                <div>
                  <label>Comm:</label>
                  <div className="dropDwonVendor2">
                    <select
                      name="commission"
                      id="commission"
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) => handleMaseterCommission(e.target.value)}
                    >
                      <option value="0">0</option>
                      {new Array(100).fill().map((_, j) => (
                        <option key={`masterCommOption_${j}`} value={j + 1}>{j + 1} %</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  <p style={{ paddingLeft: "10px" }}> Seller Disc: </p>
                  <div className="dropDwonVendor" style={{ marginLeft: "5px" }}>
                    <select
                      name="discounts"
                      id="discounts"
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) =>
                        handleMaseterDiscounts(e.target.value)
                      }
                    >
                      <option value="0">0</option>
                      {new Array(100).fill().map((_, j) => (
                        <option key={`masterDisOption_${j}`} value={j + 1}>{j + 1} %</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </span>
            {(categorieList && categorieList.length > 0) ?
              <FiChevronRight
                className={`ml-auto stroke-current transition ease-in-out duration-150 transform ${hidden ? "rotate-0" : "rotate-90" }`}
              />
              :
              <span style={{width: 14}}></span>
            }
          </button>
        </div>
        <div className={`py-2 w-full ${hidden ? "hidden" : "block"}`}>
          {categorieList &&
            categorieList.map((category, i) => (
              <div
                className="flex flex-row items-center justify-between py-1 px-2"
                key={`subDisPlan_${i}`}
              >
                <span className="text-sm">L4 - {category.name}</span>
                <div className="commission_box">
                  <p>Comm: </p>
                  <div className="dropDwonVendor">
                    <select
                      name="commission"
                      id="commission"
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) =>
                        handleCommission(e.target.value, category._id)
                      }
                    >
                      <option value="0">0</option>
                      {new Array(100).fill().map((_, j) => (
                        <option key={`subCommOption_${i}_${j}`} value={j + 1}>{j + 1} %</option>
                      ))}
                    </select>
                  </div>{" "}
                  <p style={{ paddingLeft: "10px" }}> Seller Disc: </p>
                  <div className="dropDwonVendor" style={{ marginLeft: "5px" }}>
                    <select
                      name="discounts"
                      id="discounts"
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) =>
                        handleDiscounts(e.target.value, category._id)
                      }
                    >
                      <option value="0">0</option>

                      {new Array(100).fill().map((_, j) => (
                        <option key={`subDisOption_${i}_${j}`} value={j + 1}>{j + 1} %</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Category;
