export const voucherTypes = [
    {
        value: "Platform Promotion",
        label: "Platform Promotion",
    },
    {
        value: "Platform Refund",
        label: "Platform Refund",
    },
    {
        value: "User Referral",
        label: "User Referral",
    },
];
export const vendorVoucherTypes = [
    {
        value: "Vendor Promotion",
        label: "Vendor Promotion",
    },
    {
        value: "Vendor Refund",
        label: "Vendor Refund",
    },
    {
        value: "Vendor Referral",
        label: "Vendor Referral",
    },
]

export const gstTypes = ["GST Exempt", "-", "w/GST"];

export const csvDataFormatter = (columns, data) => {
    const selectedColumns = columns?.filter(y => (y.isCheck && y.show && !y.skip) || (y.isCheck === undefined && y.show === undefined));
    return [
        selectedColumns.map(x => x.Header),
        ...data.map((x) => {
            return selectedColumns?.map(col => (x[col.accessor] && x[col.accessor].toString().startsWith("+")) ? "'"+x[col.accessor] : x[col.accessor])
        }),
    ]
}

export const getOrderStatus = (status) => {
    return status === 1 ? "Completed" : status === 0 ? "Cancelled" : status === 2 ? "Partially Paid" : status === 3 ? "Under Cancellation" : status === 4 ? "All Paid / Not Delivered" : status === 5 ? 'Delivered' : ""
}

export const getProductStatus = (status) => status === 0 ? "Draft" : status === 1 ? "Active" : status === 2 ? "Rejected" : status === 3 ? "Archived" : status === 4 ? "Probation" : undefined;
export const getProductStatusName = (status) => status === "Draft" ? 0 : status === "Active" ? 1 : status === "Rejected" ? 2 : status === "Archived" ? 3 : status === "Probation" ? 4 : undefined;

export const refundMethods = [{ key: 'voucher', value: 'Voucher' }, { key: 'original', value: 'Original Payment Method' }];