/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useHistory, Link, useParams, Redirect } from "react-router-dom";
import {
  url,
  API_FilterList,
  API_UpdateManageProductById,
  API_MasterCategoryList,
  API_GetAllSubCategory,
  API_GetAllCategory,
  API_GetAllAttribute,
  API_ProductList,
  API_GetAllSubSubCategory
} from "../../apis/constant";
import ApiClient from "../../apis/apiClient";
import InputTag from "../tags/InputTag";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import currency from "./currencyList.json";
import AsyncSelect from "react-select/async";
import { getPreviewUrl } from "../../utils/common";

const EditVendorProduct = () => {
  const adminToken = localStorage.getItem("wed_admin_token");
  const history = useHistory();
  const [isSubmit, setIsSubmit] = useState(false);
  const [subCatList, setSubCategoryList] = useState([]);
  const [categryList, setCategoryList] = useState([]);
  const [tags, setTags] = useState([""]);
  const [productTagsErr, setProductTagsErr] = useState("");
  const [attributes, setAttributes] = useState([]);
  const [attributesErr, setAttributesErr] = useState("");
  const [attributeData, setAttributeData] = useState([]);
  const [masterCatError, setMasterCatError] = useState("");
  const [catError, setCatError] = useState("");
  const [subCatError, setSubCatError] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [handleFilterData, sethandleFilterData] = useState("");
  const [handleFilterError, setHandleFilterError] = useState("");
  const [productTitle, setProductTitle] = useState("");
  const [productTitleErr, setProductTitleErr] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productDescriptionErr, setProductDescriptionErr] = useState("");
  const [skuCode, setSkuCode] = useState("");
  const [skuCodeErr, setSkuCodeErr] = useState("");
  const [productPrice, setproductPrice] = useState("");
  const [productPriceErr, setproductPriceErr] = useState("");
  const [profilePhoto, setProfilePhoto] = useState([]);
  const [profilePhotoErr, setProfilePhotoErr] = useState("");
  const [currencyShow, setShowCurrency] = useState([]);
  const [currencyHandle, setCurrencyHandle] = useState("");
  const [taxClass, setTaxClass] = useState("");
  const [taxClassError, setTaxClassError] = useState("");
  const [taxStatus, setTaxStatus] = useState("");
  const [taxStatusError, setTaxStatusError] = useState("");
  const [productDetails, setProductDetails] = useState("");
  const [productDeatilsErr, setProductDetailsErr] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [vendorNameErr, setVendorNameErr] = useState("");
  const [minQtyOrder, setMinQtyOrder] = useState({});
  const [maxQtyOrder, setMaxQtyOrder] = useState({});
  const [minPricing, setMinPricing] = useState({});
  const [maxPricing, setMaxPricing] = useState({});
  const [showEnable, setShowEnable] = useState(false);
  const [focusValue, setFocusValue] = useState("");
  const [productTypeData, setProductTypeData] = useState([]);
  const [productTypeValue, setProductTypeValue] = useState("");
  const [productTypeValueErr, setProductTypeValueErr] = useState("");
  const [selectedMasterCategoryValue, setSelectedMasterCategoryValue] =
    useState(null);
  const [selectedSubSubCategoryValue, setselectedSubSubCategoryValue] =
    useState(null);
  const [selectedCategoryValue, setSelectedCategoryValue] = useState(null);
  const [selectedSubCategoryValue, setSelectedSubCategoryValue] =
    useState(null);
  const [subSubCatList, setSubSubCategoryList] = useState([]);

  const alphanumericRegex = /^[^'"`]+$/;
  const { id } = useParams();

  const handleCurrency = (e) => {
    setCurrencyHandle(e);
  };

  useEffect(() => {
    MasterCategoryList(10);
    getproductById();
    FiltersList(10);
    setShowCurrency(currency);
    getAllAttribute();
    getAllProducts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllAttribute = async () => {
    const data = "";

    const res = await ApiClient.get(
      API_GetAllAttribute,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      let full_arr = res.data.data.docs;
      let tempArrAttributes = [];
      for (let i = 0; i < full_arr.length; i++) {
        let element = full_arr[i];
        tempArrAttributes.push({
          label: element.attributeDisplayName,
          value: element.id,
        });
      }

      setAttributeData(tempArrAttributes);
    }
  };

  const handleAMasterCategory = async (e) => {
    const id = e.id;
    setSelectedMasterCategoryValue(e);
    if (id === "") {
      setCategoryList([]);
      // setCategoryData("");
      // setSubCatData("");
      // setSubSubCatData("");
      return;
    }
    // setAMasterCategoryData(id);
    localStorage.setItem("masterId", id);
    getCategoryListUsingMasterId(id, "");
  };

  const getCategoryListUsingMasterId = async (id, inputValue) => {
    var masterId = id ? id : localStorage.getItem("masterId");
    const res = await ApiClient.get(
      API_GetAllCategory +
        `?masterCategoryId=${masterId}&page=1&sortBy=asc&limit=${10}&searchBy=${inputValue}`,
      {},
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      let full_arr = res.data.data.docs;
      setCategoryList(full_arr);
      return full_arr;
    }
  };

  const getAllMasterCategory = async (inputValue) => {
    const data = "";
    // setIsApiCall(true);
    const res = await ApiClient.get(
      API_MasterCategoryList +
        `?page=1&sortBy=asc&limit=${30}&searchBy=${inputValue}`,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      // setMasterCategoryData(res.data.data.docs);
      // setIsApiCall(false);
      return res.data.data.docs;
    }
  };

  const handleACategory = async (e) => {
    const id = e.id;
    setSelectedCategoryValue(e);
    // setACategoryData(id);

    if (id === "") {
      setSubCategoryList([]);
      // setSubCatData("");
      // setSubSubCatData("");
      return;
    }

    // setACategoryData(id);
    localStorage.setItem("categoryId", id);
    getSubCategoryListUsingCategoryId(id, "");
    // getSubSubCategoryListUsingSubCategoryId(id, "");
  };

  const getSubCategoryListUsingCategoryId = async (id, inputValue) => {
    var categoryId = id ? id : localStorage.getItem("categoryId");
    const res = await ApiClient.get(
      API_GetAllSubCategory +
        `?categoryId=${categoryId}&page=1&sortBy=asc&limit=${10}&searchBy=${inputValue}`,
      {},
      `Bearer ${adminToken}`
    );

    if (res.data.code === 200) {
      let full_arr = res.data.data.docs;
      setSubCategoryList(full_arr);
      return full_arr;
    }
  };
  const getSubSubCategoryListUsingSubCategoryId = async (id, inputValue) => {
    var subcCategoryId = id ? id : localStorage.getItem("subcategoryId");
    const res = await ApiClient.get(
      API_GetAllSubSubCategory +
        `?subCategoryId=${subcCategoryId}&page=1&sortBy=asc&limit=${10}&searchBy=${inputValue}`,
      {},
      `Bearer ${adminToken}`
    );

    if (res.data.code === 200) {
      let full_arr = res.data.data.docs;
      setSubSubCategoryList(full_arr);
      return full_arr;
    }
  };

  const handleSubCategory = (e) => {
    const id = e.id;
    setSelectedSubCategoryValue(e);

    if (id === "") {
      setSubSubCategoryList([]);
      return;
    }
    localStorage.setItem("subcategoryId", id);
    getSubSubCategoryListUsingSubCategoryId(id, "");
  };

  const handleSubSubCategory = (e) => {
    setselectedSubSubCategoryValue(e);
  };

  const handleAttributes = (e) => {
    if (e === null) {
      setAttributes([]);
    } else {
      setAttributes(e);
    }
  };

  const handleEnable = (e) => {
    if (e === "Yes") {
      setShowEnable(true);
    } else {
      setShowEnable(false);
    }
  };

  const handleFocus = (e) => {
    setFocusValue(e);
  };

  const handleProductTitle = (value) => {
    const limit = 200;
    if (value.length > limit) {
      setProductTitleErr("Please enter less than 200 words.");
    } else if (value && !alphanumericRegex.test(value)) {
      setProductTitleErr("Please enter valid Product name.");
      setProductTitle(value);
    } else {
      setProductTitleErr("");
      setProductTitle(value);
    }
  };

  const handleSku = (value) => {
    if (value && !alphanumericRegex.test(value)) {
      setSkuCodeErr("Please enter valid Sku name.");
      setSkuCode(value);
    } else {
      setSkuCodeErr("");
      setSkuCode(value);
    }
  };

  const handleProductDescription = (value) => {
    setProductDescription(value);
  };
  const handleProductDetails = (value) => {
    if (value && !alphanumericRegex.test(value)) {
      setProductDetailsErr("Please enter valid Product Details.");
      setProductDetails(value);
    } else {
      setProductDetailsErr("");
      setProductDetails(value);
    }
  };
  const handleVendorName = (value) => {
    if (value && !alphanumericRegex.test(value)) {
      setVendorNameErr("Please enter valid Product Details.");
      setVendorName(value);
    } else {
      setVendorNameErr("");
      setVendorName(value);
    }
  };

  const handleProductPrice = (value) => {
    if (value.length > 15) {
      setproductPriceErr(" Please enter max 200 number");
    } else {
      setproductPrice(value);
      setproductPriceErr("");
    }
  };

  const handleChangeFilter = (e) => {
    sethandleFilterData(e);
  };

  const handleMinQtyOrder = (value) => {
    setMinQtyOrder({ minQty: value, minQtyMetric: "String" });
  };
  const handleMaxQtyOrder = (value) => {
    setMaxQtyOrder({ maxQty: value, maxQtyMetric: "String" });
  };
  const handleMinPricing = (value) => {
    setMinPricing({ minPrice: value, minPriceCurrency: "String" });
  };
  const handleMaxPricing = (value) => {
    setMaxPricing({ maxPrice: value, maxPriceCurrency: "String" });
  };

  const handleProductType = (value) => {
    setProductTypeValue(value);
  };

  const FiltersList = async (limit, searchq = "") => {
    const data = "";
    const res = await ApiClient.get(
      API_FilterList + `?page=1&sortBy=asc&limit=${limit}&searchBy=${searchq}`,
      data,
      `Bearer ${adminToken}`
    );

    if (res.data.code === 200) {
      let full_arr = res.data.data.docs;
      setFilterData(full_arr);
    }
  };

  const getproductById = () => {
    axios
      .get(url + API_UpdateManageProductById + "/" + id, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((res) => {
        if (res.data.code === 200) {
          setTags(res.data.data.productTags);
          setProductDescription(res.data.data.productDescription);
          setProductTitle(res.data.data.productTitle);
          setSkuCode(res.data.data.productSkuCode);
          setproductPrice(res.data.data.productPrice.price);
          setCurrencyHandle(res.data.data.productPrice.priceCurrency);
          setProfilePhoto(res.data.data.productGallery);
          setProductDetails(res.data.data.productDetails);
          setVendorName(res.data.data.vendorName);
          setMaxPricing(res.data.data.maxPricing);
          setMinPricing(res.data.data.minPricing);
          setMaxQtyOrder(res.data.data.maxQtyOrder);
          setMinQtyOrder(res.data.data.minQtyOrder);
          setFocusValue(res.data.data.focusKeyword);
          setProductTypeValue(res.data.data.productTypeId);
          setTaxClass(res.data.data.taxClass);
          setTaxStatus(res.data.data.taxStatus);
          sethandleFilterData(res.data.data.filterId[0].id);

          let tempArr = [];
          let resAttributes = res.data.data.attributes;

          for (let i = 0; i < resAttributes.length; i++) {
            let element = resAttributes[i];
            tempArr.push({
              label: element.variation[0],
              value: element.attributeId,
            });
          }
          setAttributes(tempArr);
          setShowEnable(res.data.data.enableMinMaxRule);
          handleAMasterCategory(res.data.data.productCategory.level1);
          handleACategory(res.data.data.productCategory.level2);
          handleSubCategory(res.data.data.productCategory.level3);
          setSelectedSubCategoryValue(res.data.data.productCategory.level3);
          handleSubSubCategory(res.data.data.productCategory.level4);
        }
      })
      .catch((err) => {});
  };

  const handleSubmit = () => {
    let isTrue = false;
    setIsSubmit(true);
    if (productTitle === "") {
      setProductTitleErr("Product title can not be empty.");
      setIsSubmit(false);

      isTrue = true;
    } else {
      setProductTitleErr("");
    }
    if (productDescription === "") {
      setProductDescriptionErr("Product Description can not be empty.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setProductDescriptionErr("");
    }
    if (profilePhoto.length === 0) {
      setProfilePhotoErr(" Product gallery can not be empty.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setProfilePhotoErr("");
    }
    if (productDetails === "") {
      setProductDetailsErr("Product Details can not be empty.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setProductDetailsErr("");
    }

    if (handleFilterData === "") {
      setHandleFilterError("filter data can not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setHandleFilterError("");
    }

    if (skuCode === "") {
      setSkuCodeErr("Sku Code can not be empty.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setSkuCodeErr("");
    }
    if (vendorName === "") {
      setVendorNameErr("Vendor name can not be empty.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setVendorNameErr("");
    }
    if (productTypeValue.length === 0) {
      setProductTypeValueErr("Product Type can not be empty.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setProductTypeValueErr("");
    }

    if (productPrice === "") {
      setproductPriceErr("Product price can not be empty.");
      setIsSubmit(false);
    }else {
      isTrue = false;
      setproductPriceErr("");
    }
    if (taxStatus.length === 0) {
      setTaxStatusError("Tax status can not be empty.");
      setIsSubmit(false); 
      isTrue = true;
    } else {
      setTaxStatusError("");
      isTrue = false;
    }
    if (taxClass.length === 0) {
      setTaxClassError("Tax Class can not be empty.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setTaxClassError("");
      isTrue = false;
    }
    // if (tags.length === 0) {
    //   setProductTagsErr("Product tags can not be empty.");
    //   setIsSubmit(false);
    //   isTrue = true;
    // } else {
    //   setProductTagsErr("");
    //   isTrue = false;
    // }
    if (attributes.length === 0) {
      setAttributesErr("Attributes can not be empty.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setAttributesErr("");
      isTrue = false;
    }

    if (isTrue) {
      return;
    }
    // [{"attributeId":"633678723a72072839eee2bf", "variation":["String"]}]

    let Attributes = [];
    for (let i = 0; i < attributes.length; i++) {
      Attributes.push({
        attributeId: attributes[i].value.id,
        variation: [attributes[i].label],
      });
    }

    let logData = new FormData();
    logData.append("productTitle", productTitle);
    logData.append("productDescription", productDescription);
    logData.append("productDetails", productDetails);
    logData.append("productSkuCode", skuCode);
    logData.append("attributes", JSON.stringify(Attributes));
    logData.append("filterId", JSON.stringify(handleFilterData));
    logData.append("enableMinMaxRule", showEnable);
    if (showEnable === true) {
      logData.append("maxPricing", JSON.stringify(maxPricing));
      logData.append("maxQtyOrder", JSON.stringify(maxQtyOrder));
      logData.append("minPricing", JSON.stringify(minPricing));
      logData.append("minQtyOrder", JSON.stringify(minQtyOrder));
    }
    logData.append("productTags", JSON.stringify(tags));
    logData.append("focusKeyword", focusValue);
    logData.append("productTypeId", productTypeValue);
    logData.append(
      "productPrice",
      JSON.stringify({
        price: productPrice,
        priceCurrency: currencyHandle,
        priceMetric: "string",
      })
    );

    let newProfileGallery = [];
    for (let i = 0; i < profilePhoto.length; i++) {
      if (typeof profilePhoto[i] === "object") {
        logData.append("productGallery", profilePhoto[i]);
      }
      if (typeof profilePhoto[i] === "string") {
        newProfileGallery.push(profilePhoto[i]);
      }
    }
    logData.append("previousProductGallery", JSON.stringify(newProfileGallery));
    logData.append("taxClass", taxClass);
    logData.append("taxStatus", taxStatus);
    logData.append(
      "productCategory",
      JSON.stringify({
        level1: selectedMasterCategoryValue.id,
        level2: selectedCategoryValue.id,
        level3: selectedSubCategoryValue.id,
        // level4: selectedSubSubCategoryValue.id,
      })
    );

    axios
      .patch(url + API_UpdateManageProductById + id, logData, {
        headers: {
          Authorization: `Bearer ${adminToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.code === 200) {
          toast.success(res.data.message);
          history.push("/manage_product");
        } else if (res.data.code === 400) {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
          toast.success("Something went wrong");
          setIsSubmit(false);
      });
  };

  const onAddTag = (tag) => {
    setTags([...tags, ...tag]);
  };

  const onDeleteTag = (tag) => {
    let remainingTags = tags.filter((t) => {
      return t !== tag;
    });
    setTags([...remainingTags]);
  };

  const getAllProducts = async () => {
    const data = "";
    const res = await ApiClient.get(
      API_ProductList,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      setProductTypeData(res.data.data.docs);
    }
  };

  const MasterCategoryList = async (limit, searchq = "") => {
    const data = "";
    const res = await ApiClient.get(
      API_MasterCategoryList +
        `?page=1&sortBy=asc&limit=${limit}&searchBy=${searchq}`,
      data,
      `Bearer ${adminToken}`
    );

    if (res.data.code === 200) {
      // let full_arr = res.data.data.docs;
      // setMasterCat(full_arr);
    }
  };

  const handleTaxStatus = (e) => {
    setTaxStatus(e);
  };

  const handleTaxClass = (e) => {
    setTaxClass(e);
  };

  const handleProfileImg = (e) => {
    let newArry = [];
    for (let i = 0; i < e.length; i++) {
      newArry.push(e[i]);
    }
    let totalLength = newArry.length + profilePhoto.length;

    if (totalLength > 20) {
      setProfilePhotoErr("You Can not add more than 20 photos");
      return;
    }
    setProfilePhoto([...profilePhoto, ...newArry]);
  };

  const handleClickRemoveProfile = (item) => {
    let removePhoto = profilePhoto.filter((i) => i !== item);
    setProfilePhoto(removePhoto);
    if (profilePhoto.length < 3) {
      setProfilePhotoErr("");
    }
  };

  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
    return (
      <>
        <div className="addBack" style={{ marginTop: "15px" }}>
          <Link
            to={"/manage_product"}
            className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
          >
            Back
          </Link>
        </div>

        <div className="">
          <div className="w-100">
            <label>Product Title</label>

            <input
              type="text"
              name="productTitle"
              placeholder="type Name..."
              value={productTitle}
              className="inputFields"
              onChange={(e) => handleProductTitle(e.target.value)}
            />
            {productTitleErr && (
              <p style={{ color: "red" }}>{productTitleErr}</p>
            )}
          </div>

          <div className="w-100">
            <label>Product Description</label>
            <textarea
              type="text"
              name="productDescription"
              placeholder="Product Description..."
              value={productDescription}
              className="inputFields"
              onChange={(e) => handleProductDescription(e.target.value)}
            />
            {productDescriptionErr && (
              <p style={{ color: "red" }}>{productDescriptionErr}</p>
            )}
          </div>
        </div>

        <label>Product Gallery (Max 20 Photos)</label>
        <div className="flex flex-row justify-start w-full py-1 space-x-2 mb-15 product-gallery">
          <div className="image-upload">
            <label htmlFor="profile-img">
              <img
                src="https://icons.iconarchive.com/icons/awicons/vista-artistic/256/add-icon.png"
                title="Choose a photo!"
                alt="choose"
                style={{ width: "30px" }}
              />
            </label>

            <input
              id="profile-img"
              type="file"
              value=""
              style={{ display: "none" }}
              accept=".png, .jpeg, .jpg, .JPG, .PNG, .JPEG, .mp4"
              onChange={(e) => handleProfileImg(e.target.files)}
              multiple
            />
          </div>

          {profilePhoto &&
            profilePhoto?.map((item) => (
              <div className="img-box">
                <img
                  className="img"
                  alt="custom"
                  src={
                    typeof item === "string"
                      ? getPreviewUrl(item)
                      : URL.createObjectURL(item)
                  }
                />
                <img
                  src="https://icons.iconarchive.com/icons/hopstarter/sleek-xp-basic/256/Close-2-icon.png"
                  onClick={() => handleClickRemoveProfile(item)}
                  alt="close"
                  className="removeCss"
                />
              </div>
            ))}
        </div>
        {profilePhotoErr && <p style={{ color: "red" }}>{profilePhotoErr}</p>}

        <div className="flex_box">
          <div className="form-element">
            <label>Product Details</label>

            <input
              type="text"
              name="productDetails"
              placeholder="type Name..."
              value={productDetails}
              className="inputFields"
              onChange={(e) => handleProductDetails(e.target.value)}
            />
            {productDeatilsErr && (
              <p style={{ color: "red" }}>{productDeatilsErr}</p>
            )}
          </div>

          <div className="form-element">
            <label>SKU Code</label>

            <input
              type="text"
              name="name"
              placeholder="Sku Code..."
              value={skuCode}
              className="inputFields"
              onChange={(e) => handleSku(e.target.value)}
            />
            {skuCodeErr && <p style={{ color: "red" }}>{skuCodeErr}</p>}
          </div>
        </div>

        <div className="flex_box">
          <div className="form-element">
            <label>Vendor Name</label>

            <input
              type="text"
              name="vendorName"
              placeholder="Vendor Name..."
              value={vendorName}
              className="inputFields"
              onChange={(e) => handleVendorName(e.target.value)}
            />
            {vendorNameErr && <p style={{ color: "red" }}>{vendorNameErr}</p>}
          </div>

          <div className="form-element">
            <label>Product Type</label>

            <select
              name="select"
              value={productTypeValue}
              onChange={(e) => handleProductType(e.target.value)}
              className="inputFields"
            >
              <option key="" value="">
                Select...
              </option>
              {productTypeData?.map((proName, idy) => (
                <option key={idy} value={proName.id}>
                  {proName?.name}
                </option>
              ))}
            </select>
            {productTypeValueErr && (
              <p style={{ color: "red" }}>{productTypeValueErr}</p>
            )}
          </div>
        </div>
        <div className="flex_box">
          <div className="form-element">
            <div>Level 1:</div>
            <AsyncSelect
              className=""
              cacheOptions
              defaultOptions
              value={selectedMasterCategoryValue}
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.id}
              loadOptions={getAllMasterCategory}
              onChange={handleAMasterCategory}
            />
            {masterCatError && (
              <p
                style={{
                  color: "red",
                  padding: "10px 4px",
                  paddingLeft: "20px",
                }}
              >
                {masterCatError}
              </p>
            )}
          </div>

          <div className="form-element">
            <div>Level 2:</div>
            <AsyncSelect
              className=""
              key={categryList.length}
              cacheOptions
              defaultOptions
              value={selectedCategoryValue}
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.id}
              loadOptions={(e) => getCategoryListUsingMasterId(e.id, "")}
              onChange={handleACategory}
            />
            {catError && (
              <p
                style={{
                  color: "red",
                  padding: "10px 4px",
                  paddingLeft: "20px",
                }}
              >
                {catError}
              </p>
            )}
          </div>
        </div>
        <div className="flex_box">
          <div className="form-element">
            <div>Level 3:</div>
            <AsyncSelect
              className=""
              key={subCatList.length}
              cacheOptions
              defaultOptions
              value={selectedSubCategoryValue}
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.id}
              loadOptions={(e) => getSubCategoryListUsingCategoryId(e.id, "")}
              onChange={handleSubCategory}
            />
            {subCatError && (
              <p
                style={{
                  color: "red",
                  padding: "10px 4px",
                  paddingLeft: "20px",
                }}
              >
                {subCatError}
              </p>
            )}
          </div>
          {/* <div className="form-element">
          <div>Level 4:</div>
          <AsyncSelect
            className=""
            key={subSubCatList.length}
            cacheOptions
            defaultOptions
            value={selectedSubSubCategoryValue}
            getOptionLabel={(e) => e.name}
            getOptionValue={(e) => e.id}
            loadOptions={(e) => getSubSubCategoryListUsingSubCategoryId(e.id,"",)}
            onChange={handleSubSubCategory}
          />
        </div> */}
          <div className="form-element ">
            <div>Select Filters</div>

            <select
              onChange={(e) => handleChangeFilter(e.target.value)}
              name="select"
              value={handleFilterData}
              className="inputFields"
            >
              <option value="">Select...</option>
              {filterData?.map((filter, idf) => (
                <option key={idf} value={filter.id}>
                  {filter?.name}
                </option>
              ))}
            </select>
            {handleFilterError && (
              <p style={{ color: "red" }}>{handleFilterError}</p>
            )}
          </div>
        </div>
        {/* <div className="flex_box">
        <div className="form-element ">
          <label>Select Filters</label>

          <select
            onChange={(e) => handleChangeFilter(e.target.value)}
            name="select"
            value={handleFilterData}
            className="inputFields"
          >
            <option value="">Select...</option>
            {filterData?.map((filter, idf) => (
              <option key={idf} value={filter.id}>
                {filter?.name}
              </option>
            ))}
          </select>
          {handleFilterError && (
            <p style={{ color: "red" }}>{handleFilterError}</p>
          )}
        </div>
      </div> */}

        <div className="flex_box">
          <div className="form-element ">
            <label>Tax Status</label>
            <select
              name="select"
              value={taxStatus}
              onChange={(e) => handleTaxStatus(e.target.value)}
              className="inputFields"
            >
              <option key="" value="">
                Select...
              </option>
              <option value="Taxable including Shipping">
                Taxable including Shipping
              </option>
              <option value="Taxable Excluding Shipping">
                Taxable Excluding Shipping
              </option>
              <option value="None">None</option>
            </select>
            {taxStatusError && <p style={{ color: "red" }}>{taxStatusError}</p>}
          </div>
          <div className="form-element ">
            <label>Tax Class</label>
            <select
              onChange={(e) => handleTaxClass(e.target.value)}
              name="select"
              value={taxClass}
              className="inputFields"
            >
              <option value="">Select...</option>
              <option value="GST 7 (2022)">GST 7 (2022)</option>
              <option value="GST 7 (2023)">GST 7 (2023)</option>
              <option value="GST 9 (2024">GST 9 (2024)</option>
              <option value="VAT 10 (2023)">VAT 10 (2023)</option>
            </select>
            {taxClassError && <p style={{ color: "red" }}>{taxClassError}</p>}
          </div>
        </div>

        <div className="flex_box">
          <div className="form-element">
            <label>Product Price</label>
            <div className="position-relative currency-box">
              <input
                type="number"
                name="productPrice"
                placeholder="type Name..."
                value={productPrice}
                className="inputFields"
                maxLength={5}
                onChange={(e) => handleProductPrice(e.target.value)}
              />
              <select
                name="select"
                value={currencyHandle}
                onChange={(e) => handleCurrency(e.target.value)}
                className="inputFields"
              >
                <option key="" value="">
                  Select...
                </option>
                {Object.values(currencyShow).map((item, index) => (
                  <option value={item.code + "-" + item.symbol_native}>
                    {item.name}
                  </option>
                ))}
              </select>
              {currencyHandle.length > 0 && (
                <p className="currency-name">{currencyHandle.split("-")[1]}</p>
              )}
              {productPriceErr && (
                <p style={{ color: "red" }}>{productPriceErr}</p>
              )}
            </div>
          </div>
          <div className="form-element">
            <label>Focus Keyword</label>
            <input
              type="text"
              name="name"
              className="inputFields"
              value={focusValue}
              onChange={(e) => handleFocus(e.target.value)}
            />
          </div>
        </div>

        <div className="flex_box">
          <div>
            <label>Enable Min Max rule</label>
            <div>
              <label htmlFor="Yes">
                {" "}
                Yes
                <input
                  type="radio"
                  id="Yes"
                  disabled
                  name="Yes"
                  value="Yes"
                  key="1"
                  checked={showEnable === true}
                  style={{ width: "50px ", margin: "20px 0" }}
                  onClick={(e) => handleEnable(e.target.value)}
                />
              </label>

              <label htmlFor="No">
                {" "}
                No
                <input
                  type="radio"
                  id="No"
                  disabled
                  name="Yes"
                  value="No"
                  key="2"
                  checked={showEnable === false}
                  style={{ width: "50px", margin: "20px 0" }}
                  onClick={(e) => handleEnable(e.target.value)}
                />
              </label>
            </div>
          </div>
        </div>
        {showEnable ? (
          <div>
            <div className="flex_box">
              <div className="form-element">
                <label>Minimum Qty to Order</label>
                <input
                  type="text"
                  name="name"
                  className="inputFields"
                  value={minQtyOrder.minQty}
                  onChange={(e) => handleMinQtyOrder(e.target.value)}
                />
              </div>
              <div className="form-element">
                <label>Minimum Pricing</label>
                <input
                  type="text"
                  name="name"
                  className="inputFields"
                  value={minPricing.minPrice}
                  onChange={(e) => handleMinPricing(e.target.value)}
                />
              </div>
            </div>

            <div className="flex_box">
              <div className="form-element">
                <label>Maximum Qty to Order</label>
                <input
                  type="text"
                  name="productDetails"
                  className="inputFields"
                  value={maxQtyOrder.maxQty}
                  onChange={(e) => handleMaxQtyOrder(e.target.value)}
                />
              </div>
              <div className="form-element">
                <label>Maximum Pricing</label>
                <input
                  type="text"
                  name="name"
                  className="inputFields"
                  value={maxPricing.maxPrice}
                  onChange={(e) => handleMaxPricing(e.target.value)}
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="flex_box">
              <div className="form-element">
                <label>Minimum Qty to Order</label>
                <input
                  type="text"
                  name="productDetails"
                  className="inputFields"
                  value="Disabled"
                  disabled
                />
              </div>
              <div className="form-element">
                <label>Minimum Pricing</label>
                <input
                  type="text"
                  name="name"
                  className="inputFields"
                  value="Disabled"
                  disabled
                />
              </div>
            </div>

            <div className="flex_box">
              <div className="form-element">
                <label>Maximum Qty to Order</label>
                <input
                  type="text"
                  name="productDetails"
                  className="inputFields"
                  value="Disabled"
                  disabled
                />
              </div>
              <div className="form-element">
                <label>Maximum Pricing</label>
                <input
                  type="text"
                  name="name"
                  className="inputFields"
                  value="Disabled"
                  disabled
                />
              </div>
            </div>
          </div>
        )}

        <div>
          <div className="form-element">
            <label>Product Tags (Press enter after done)</label>
            <div className="inputTag">
              <InputTag
                className="inputFields"
                onAddTag={onAddTag}
                onDeleteTag={onDeleteTag}
                defaultTags={tags}
                placeholder="enter tags separated by comma"
              />
            </div>
            {productTagsErr && <p style={{ color: "red" }}>{productTagsErr}</p>}
          </div>
        </div>
        <div>
          <div className="form-element">
            <label>Attributes (Press enter after done)</label>
            <div className="inputTag">
              <Select
                options={attributeData}
                name="attributes"
                value={attributes}
                onChange={(e) => handleAttributes(e)}
                isMulti={true}
                placeholder="Select multiple..."
              />
            </div>
            {attributesErr && <p style={{ color: "red" }}>{attributesErr}</p>}
          </div>
        </div>

        <div className="flex flex-col ">
          <div className="inputBoxCategoryCss">
            {isSubmit ? (
              <button className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded">
                Loading...
              </button>
            ) : (
              <button
                className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
                onClick={() => handleSubmit()}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </>
    );
  }
};

export default EditVendorProduct;
