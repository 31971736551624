import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../utils/createApi";

export const getCategoriesLevelOne = createAsyncThunk(
  "categories/getCategoriesLevelOne",
  async ({ onSuccess, onFailed }) => {
    try {
      const result = await API.get(
        "/public/masterCategory?page=1&sortBy=asc&limit=100"
      );
      onSuccess();
      return result.data.data.docs;
    } catch (err) {
      onFailed();
      throw new Error(err);
    }
  }
);

export const getCategoriesAll = createAsyncThunk(
  "categories/getCategoriesAll",
  async ({ vendorId, onSuccess, onFailed }) => {
    try {
      const result = await API.get(`/user/categories-list?vendorId=${vendorId}&useVendorFilter=true`, {
        headers: {
          Accept: "application/json",
          Authorization: "Basic d2VkZGluZ3NlcnY6d2VkZGluZ3NlcnZAMTIzNDU=",
        },
      });
      onSuccess();
      return result.data.data;
    } catch (err) {
      onFailed();
      throw new Error(err);
    }
  }
);

export const getCategoriesFilterType = createAsyncThunk(
  "categories/getCategoriesFilterType",
  async ({ params, onSuccess, onFailed }) => {
    try {
      let url = `/user/filter?masterCategoryId=${params.masterCategoryId}${
        params.subCategoryId && `&subCategoryId=${params.subCategoryId}`
      }${
        params.categoryId && `&categoryId=${params.categoryId}`
      }&page=1&limit=100&sortBy=asc`;
      // let url = `/user/filter?page=1&limit=100&sortBy=asc`;

      const result = await API.get(url);
      onSuccess(result.data.data.docs);
    } catch (err) {
      onFailed();
      throw new Error(err);
    }
  }
);
