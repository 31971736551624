/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import SectionTitle from "../components/section-title";
import OrderPageData from "../components/orders";
import { useOutsideClick } from "../hooks/OutsideClick";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  API_AllOrdersAdmin,
  url,
  API_cancel_order,
  API_OrderViewId,
} from "../apis/constant";
import { toast } from "react-toastify";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { checkAccess } from "../utils/common";
import { getOrderStatus } from "../utils/constants";
import CsvDowloader from "../components/CsvDowloader";
import { RxCross2 } from "react-icons/rx";
import ModalRequestEdit from "../components/modals/modalRequestEdit";
import ModalReviewDescription from "../components/modals/modalReviewDescription";

const getToken = () => localStorage.getItem("wed_admin_token");
const Item = ({ onClick, title, goto }) => {
  return (
    <Link to={goto}>
      <p
        onClick={onClick}
        className="cursor-pointer px-5 py-2  hover:bg-grey-300"
      >
        {title}
      </p>
    </Link>
  );
};

const usdFormatter = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

const CellRender = (props) => {
  const orderId = props.data[props.row.index].id;
  const orderById = props.data[props.row.index].orderNo;
  const vendorId = props.data[props.row.index].vendorId;
  const vendorPolicyId = props.data[props.row.index].vendorPolicyId;
  const orderStatus = props.data[props.row.index].orderStatus;
  
  const [show, setShow] = useState(false);

  const handleCancel = () => {
    axios
      .post(
        url + API_cancel_order + "/cancelMultipleOrders",
        { ids: [orderId] },
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
      .then((res) => {
        if (res.data.code === 200) {
          toast.success("Orders Successfully Cancelled!");
          props.getAllOrder();
        }
      });
  };
  return (
    <div
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      className="relative"
    >
      <p className="cursor-pointer hover:text-grey-500">{props.value}</p>
      {show && (
        <div
          className={`flex ${
            props.flatRows[props.flatRows.length - 1]?.index === props.row.index
              ? ""
              : "absolute"
          } shadow-lg bottom-20 bg-white rounded-md z-10`}
        >
          {checkAccess(20, "view") && (
            <Item title="View" goto={`/edit-order/${orderId}/${orderById}`} />
          )}
          {(checkAccess(20, "edit") && orderStatus !== 3 && orderStatus !== 0) && (
            <p
              onClick={handleCancel}
              className="cursor-pointer px-5 py-2  hover:bg-grey-300"
            >
              Cancel Order
            </p>
          )}
          <Item title="Vendor Profile" goto={`/vendor/${vendorId}`} />
          <Item
            title="Payment List"
            goto={`/payment-list?orderId=${orderById}`}
          />
          <Item
            title="Vendor Payment Policy"
            goto={`/payment-policy-detail/${vendorPolicyId}`}
          />
        </div>
      )}
    </div>
  );
};

const Menu = ({ onClick, title }) => {
  return (
    <p
      onClick={onClick}
      className="cursor-pointer my-1 px-5 py-2  hover:bg-grey-300"
    >
      {title}
    </p>
  );
};

const MenuCheckBox = ({ title, isCheck, setCheck, index }) => {
  return (
    <div className="flex items-center my-1 px-5 py-2  space-x-2">
      <input
        onChange={() => setCheck(index)}
        checked={isCheck}
        type={"checkbox"}
        id={`checkbox${title}`}
      />
      <label htmlFor={`checkbox${title}`} className="cursor-pointer">
        {title}
      </label>
    </div>
  );
};

const SummaryButton = ({ summary, setSummary, title }) => (
  <button
    onClick={() => {
      setSummary(title === "All" ? "All" : title);
    }}
    className={` py-2 px-4 mr-2 rounded shadow-sm ${
      summary === title
        ? "bg-blue-500 text-white hover:bg-blue-300"
        : "bg-white hover:bg-grey-200"
    }`}
  >
    {title === "All" ? title : getOrderStatus(title)}
  </button>
);

const FilterItem = ({
  onRemovePress,
  index,
  setFilter,
  filters,
  setAddFilter,
}) => {
  const [filterOptions, setFilterOptions] = useState([
    { label: "Status", value: "status" },
  ]);
  useEffect(() => {
    if (filters.length !== 0) {
      const fil = [...filterOptions];

      const myArrayFiltered = fil.filter((filt) => {
        return !filters.some((val) => {
          return Object.keys(val).includes(filt.value);
        });
      });
      setFilterOptions(myArrayFiltered);
    } else {
      setFilterOptions(filterOptions);
    }
  }, [filters]);
  return (
    <div className="flex space-x-2 items-center">
      <select
        className="bg-white border-2 w-32 mt-2 py-1 rounded-md"
        value=""
        onChange={(e) => {
          const fil = [...filterOptions];
          const myArrayFiltered = fil.filter((filt) => {
            return !filters.some((val) => {
              return Object.keys(val).includes(filt.value);
            });
          });
          setFilter([...filters, (filters[index] = { [e.target.value]: "" })]);
          setFilterOptions(myArrayFiltered);
          setAddFilter([]);
        }}
      >
        <option value="">Select</option>
        {filterOptions.map((item, i) => (
          <option key={i} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>

      <input
        type="input"
        value={""}
        className="bg-white border-2 w-32 mt-2 py-1 rounded-md"
      />
    </div>
  );
};

const Index = () => {
  const [selectedOrderEdit, setSelectedOrderEdit] = useState("");
  const [openModalOrderEdit, setOpenModalOrderEdit] = useState(false);
  const [selectedComments, setSelectedComments] = useState("");
  const [openModalComments, setOpenModalComments] = useState(false);
  const [columns, setColumns] = useState([
    {
      Header: "No",
      accessor: "no",
      show: true,
      isCheck: true,
    },
    {
      Header: "Order No",
      accessor: "orderNo",
      Cell: (props) => <CellRender {...props} getAllOrder={getAllOrder} />,
      isCheck: true,
      show: true,
    },
    {
      Header: "Order Date",
      accessor: "orderdateee",
      isCheck: true,
      show: true,
    },
    {
      Header: "Vendor ID",
      accessor: "vendorid",
      isCheck: true,
      show: true,
    },
    {
      Header: "Vendor Name",
      accessor: "vendorname",
      isCheck: true,
      show: true,
    },
    {
      Header: "USER ID",
      accessor: "userId",
      isCheck: true,
      show: true,
    },
    {
      Header: "Customer",
      accessor: "customer",
      isCheck: true,
      show: true,
    },
    {
      Header: "Billing First Name",
      accessor: "billingFirstName",
      isCheck: true,
      show: true,
    },
    {
      Header: "Billing Last Name",
      accessor: "billingLastName",
      isCheck: true,
      show: true,
    },
    {
      Header: "Billing Street Address",
      accessor: "billingStreetAddress",
      isCheck: true,
      show: true,
    },
    {
      Header: "Billing address Street (2)",
      accessor: "billingStreetAddress2",
      isCheck: true,
      show: true,
    },
    {
      Header: "Billing address Street (3)",
      accessor: "billingStreetAddress3",
      isCheck: true,
      show: true,
    },
    {
      Header: "Billing address City",
      accessor: "billingStreetCity",
      isCheck: true,
      show: true,
    },
    {
      Header: "Billing Unit Number",
      accessor: "billingUnitNo",
      isCheck: true,
      show: true,
    },
    {
      Header: "Billing Postal Code",
      accessor: "billingPostalCode",
      isCheck: true,
      show: true,
    },
    {
      Header: "Billing Country",
      accessor: "billingCountry",
      isCheck: true,
      show: true,
    },
    {
      Header: "Billing State",
      accessor: "billingState",
      isCheck: true,
      show: true,
    },
    {
      Header: "Shipping First Name",
      accessor: "shippingFirstName",
      isCheck: true,
      show: true,
    },
    {
      Header: "Shipping Last Name",
      accessor: "shippingLastName",
      isCheck: true,
      show: true,
    },
    {
      Header: "Shipping Street Address",
      accessor: "shippingStreetAddress",
      isCheck: true,
      show: true,
    },
    {
      Header: "Shipping address Street (2)",
      accessor: "shippingStreetAddress2",
      isCheck: true,
      show: true,
    },
    {
      Header: "Shipping address Street (3)",
      accessor: "shippingStreetAddress3",
      isCheck: true,
      show: true,
    },
    {
      Header: "Shipping Unit Number",
      accessor: "shippingUnitNumber",
      isCheck: true,
      show: true,
    },
    {
      Header: "Shipping Postal Code",
      accessor: "shippingPostalCode",
      isCheck: true,
      show: true,
    },
    {
      Header: "Shipping City",
      accessor: "shippingCity",
      isCheck: true,
      show: true,
    },
    {
      Header: "Shipping Country",
      accessor: "shippingCountry",
      isCheck: true,
      show: true,
    },
    {
      Header: "Shipping State",
      accessor: "shippingState",
      isCheck: true,
      show: true,
    },
    {
      Header: "Shipping address Phone",
      accessor: "shippingPhone",
      isCheck: true,
      show: true,
    },
    {
      Header: "Vendor Contact",
      accessor: "vendorcontact",
      isCheck: true,
      show: true,
    },
    {
      Header: "Email Address",
      accessor: "emailaddress",
      isCheck: true,
      show: true,
    },
    {
      Header: "Product Name",
      accessor: "product",
      isCheck: true,
      show: true,
    },
    {
      Header: "Expected Delivery Date",
      accessor: "delidate",
      isCheck: true,
      show: true,
    },
    {
      Header: "Price ($)",
      accessor: "prices",
      isCheck: true,
      show: true,
      Cell: (props) => {
        return props.value && usdFormatter(props.value);
      },
    },
    {
      Header: "Item Discount",
      accessor: "itemdisc",
      isCheck: true,
      show: true,
    },
    {
      Header: "Quantity",
      accessor: "qty",
      isCheck: true,
      show: true,
    },
    {
      Header: "Sub Total",
      accessor: "subtotal",
      Cell: (props) => {
        return props.value && usdFormatter(props.value);
      },
      isCheck: true,
      show: true,
    },
    {
      Header: "Discount Code",
      accessor: "couponCode",
      isCheck: true,
      show: true,
    },
    {
      Header: "Voucher Code",
      accessor: "voucherCode",
      isCheck: true,
      show: true,
    },
    {
      Header: "Discount Amount",
      accessor: "discamount",
      isCheck: true,
      show: true,
      Cell: (props) => {
        return props.value && usdFormatter(props.value);
      },
    },
    {
      Header: "Total Order Amount excl Tax",
      accessor: "totalOrderAmtExclTax",
      isCheck: true,
      show: true,
      Cell: (props) => {
        return props.value && usdFormatter(props.value);
      },
    },
    {
      Header: "GST",
      accessor: "gstamount",
      isCheck: true,
      show: true,
      Cell: (props) => {
        return props.value && usdFormatter(props.value);
      },
    },
    {
      Header: "Total Order Amt",
      accessor: "actualamount",
      isCheck: true,
      show: true,
    },
    {
      Header: "Voucher Value",
      accessor: "netvoucher",
      isCheck: true,
      show: true,
      Cell: (props) => {
        return props.value && usdFormatter(props.value);
      },
    },
    {
      Header: "Voucher Offset",
      accessor: "voucherOffset",
      isCheck: true,
      show: true,
    },
    {
      Header: "Total Amount to Pay",
      accessor: "amountToPay",
      isCheck: true,
      show: true,
    },
    {
      Header: "Payment Milestone",
      accessor: "paymilestone",
      isCheck: true,
      show: true,
    },
    {
      Header: "Payment Due Date",
      accessor: "payduedate",
      isCheck: true,
      show: true,
    },
    {
      Header: "Payment Date",
      accessor: "paymentDate",
      isCheck: true,
      show: true,
    },
    {
      Header: "Total Amt Paid",
      accessor: "totalAmtPaid",
      isCheck: true,
      show: true,
    },
    {
      Header: "Net Payment Due",
      accessor: "netPaymentDue",
      isCheck: true,
      show: true,
    },
    {
      Header: "Remaining Voucher Value",
      accessor: "remainingVoucher",
      isCheck: true,
      show: true,
    },
    {
      Header: "Platform Comm Manual",
      accessor: "platformCommManual",
      isCheck: true,
      show: true,
      Cell: (props) => {
        const orderNo = props?.row?.original?.orderNo;
        const value = customFieldsRef.current[orderNo]?.platformCommManual;
        return (
          <>
            <div className="flex items-center" style={{ marginTop: "-2px" }}>
              <div className="flex items-center border border-gray-300 rounded-lg">
                <div className="flex items-center bg-gray-200 rounded-lg pl-2">
                  <p>$</p>
                </div>
                <input
                  type="number"
                  defaultValue={value}
                  onChange={(e) => {
                    handleChange(e.target.value, orderNo, "platformCommManual");
                  }}
                  className="w-20 px-2 rounded-lg"
                />
              </div>
            </div>
          </>
        );
      },
    },
    {
      Header: "Platform Comm Stripe",
      accessor: "platFormCommStripe",
      isCheck: true,
      show: true,
    },
    {
      Header: "Total Platform Comm",
      accessor: "totalPlatFormCom",
      isCheck: true,
      show: true,
    },
    {
      Header: "Commission Collected",
      accessor: "comissionCollected",
      isCheck: true,
      show: true,
    },
    {
      Header: "Commission Receivables",
      accessor: "comissionReceivables",
      isCheck: true,
      show: true,
    },
    {
      Header: "Transaction Fees",
      accessor: "transactionFees",
      isCheck: true,
      show: true,
      Cell: (props) => {
        const orderNo = props?.row?.original?.orderNo;
        return (
          <>
            <div className="flex items-center" style={{ marginTop: "-2px" }}>
              <div className="flex items-center border border-gray-300 rounded-lg">
                <div className="flex items-center bg-gray-200 rounded-lg pl-2">
                  <p>$</p>
                </div>
                <input
                  type="number"
                  defaultValue={
                    customFieldsRef.current[orderNo]?.transactionFees
                  }
                  onChange={(e) => {
                    handleChange(e.target.value, orderNo, "transactionFees");
                  }}
                  className="w-20 px-2 rounded-lg"
                />
              </div>
            </div>
          </>
        );
      },
    },
    {
      Header: "Stripe Fees",
      accessor: "stripeFees",
      isCheck: true,
      show: true,
    },
    {
      Header: "Total Transaction Fees",
      accessor: "totalTransactionFees",
      isCheck: true,
      show: true,
    },
    {
      Header: "Vendor Pmt Held",
      accessor: "vendorPaymentHeld",
      isCheck: true,
      show: true,
    },
    {
      Header: "Manual Pmt Settlement",
      accessor: "manualPmt",
      show: true,
      isCheck: true,
      // Cell: (props) => {
      //   const orderNo = props?.row?.original?.orderNo;
      //   return (
      //     <>
      //       <div className="flex items-center" style={{ marginTop: "-2px" }}>
      //         <div className="flex items-center border border-gray-300 rounded-lg">
      //           <div className="flex items-center bg-gray-200 rounded-lg pl-2">
      //             <p>$</p>
      //           </div>
      //           <input
      //             type="number"
      //             defaultValue={customFieldsRef.current[orderNo]?.manualPmt}
      //             onChange={(e) => {
      //               handleChange(e.target.value, orderNo, "manualPmt");
      //             }}
      //             className="w-20 px-2 rounded-lg"
      //           />
      //         </div>
      //       </div>
      //     </>
      //   );
      // },
    },
    {
      Header: "Manual Pmt Released Ref No",
      accessor: "manualPmtRef",
      isCheck: true,
      show: true,
      Cell: (props) => {
        const orderNo = props?.row?.original?.orderNo;
        return (
          <>
            <div className="flex items-center" style={{ marginTop: "-2px" }}>
              <div className="flex items-center border border-gray-300 rounded-lg w-32">
                <input
                  type="text"
                  defaultValue={customFieldsRef.current[orderNo]?.manualPmtRef}
                  onChange={(e) => {
                    handleChange(e.target.value, orderNo, "manualPmtRef");
                  }}
                  className="px-2 rounded-lg w-full"
                />
              </div>
            </div>
          </>
        );
      },
    },
    {
      Header: "Vendor Pmt Release Date Manual",
      accessor: "vendorPaymentRelaseDateManual",
      isCheck: true,
      show: true,
      Cell: (props) => {
        const orderNo = props?.row?.original?.orderNo;
        return (
          <>
            <div className="flex items-center" style={{ marginTop: "-2px" }}>
              <div className="flex items-center border border-gray-300 rounded-lg w-36 relative">
                <input
                  type="date"
                  id="vendorPaymentRelaseDateManual"
                  defaultValue={
                    customFieldsRef.current[orderNo]
                      ?.vendorPaymentRelaseDateManual
                  }
                  onChange={(e) => {
                    handleChange(
                      e.target.value,
                      orderNo,
                      "vendorPaymentRelaseDateManual"
                    );
                  }}
                  className="px-2 rounded-lg w-full"
                />
                <div
                  className="absolute m-auto cursor-pointer"
                  style={{ right: "27px" }}
                  onClick={() => {
                    document.getElementById(
                      "vendorPaymentRelaseDateManual"
                    ).value = "";
                    handleChange("", orderNo, "vendorPaymentRelaseDateManual");
                  }}
                >
                  <RxCross2 />
                </div>
              </div>
            </div>
          </>
        );
      },
    },
    {
      Header: "Stripe Payout",
      accessor: "stripePayout",
      isCheck: true,
      show: true,
    },
    {
      Header: "Vendor Pmt Release Date Stripe",
      accessor: "vendorPmtStripeDate",
      isCheck: true,
      show: true,
    },
    {
      Header: "Stripe Release Date Txn Id",
      accessor: "stripePayoutId",
      isCheck: true,
      show: true,
    },
    {
      Header: "Modified On",
      accessor: "modifiedon",
      isCheck: true,
      show: true,
    },
    {
      Header: "Comments (from Cart)",
      accessor: "comment",
      isCheck: true,
      show: true,
      Cell: (props) => {
        const comment = props.cell.row.original?.comment || "";
        const truncatedDescription =
          comment.length > 30 ? comment.substring(0, 30) + "....." : comment;
        const handleViewComment = () => {
          setSelectedComments(comment);
          setOpenModalComments(true);
        };
        return (
          <div className="flex items-center space-x-2">
            {comment.length > 30 ? (
              <p
                onClick={handleViewComment}
                className="cursor-pointer hover:text-grey-500"
              >
                {truncatedDescription}
              </p>
            ) : (
              <p>{truncatedDescription}</p>
            )}
          </div>
        );
      },
    },
    {
      Header: "Approved Order Edit fields",
      accessor: "requestEdit",
      isCheck: true,
      show: true,
      Cell: (props) => {
        const orderEdit =
          props.cell.row.original?.requestEdit.length > 0
            ? props.cell.row.original?.requestEdit[0]?.changeComment
            : "";
        const truncatedOrderEdit =
          orderEdit.length > 50
            ? orderEdit.substring(0, 50) + "....."
            : orderEdit;
        const handleViewReviewDesc = () => {
          setSelectedOrderEdit(props.cell.row.original?.requestEdit);
          setOpenModalOrderEdit(true);
        };
        return (
          <div className="flex items-center space-x-2">
            <p
              onClick={handleViewReviewDesc}
              className="cursor-pointer hover:text-grey-500"
            >
              {truncatedOrderEdit}
            </p>
          </div>
        );
      },
    },
    // {
    //   Header: "Approved Order Edit fields",
    //   accessor: "editComment",
    //   isCheck: true,
    //   show: true,
    //   Cell: (props) => {
    //     const orderEdit = props.cell.row.original?.editComment || "";
    //     const truncatedOrderEdit =
    //       orderEdit.length > 50
    //         ? orderEdit.substring(0, 50) + "....."
    //         : orderEdit;
    //     const handleViewReviewDesc = () => {
    //       setSelectedOrderEdit(orderEdit);
    //       setOpenModalOrderEdit(true);
    //     };
    //     return (
    //       <div className="flex items-center space-x-2">
    //         {orderEdit.length > 50 ? (
    //           <p
    //             onClick={handleViewReviewDesc}
    //             className="cursor-pointer hover:text-grey-500"
    //           >
    //             {truncatedOrderEdit}
    //           </p>
    //         ) : (
    //           <p>{truncatedOrderEdit}</p>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      Header: "Order Edit Approved Timestamp",
      accessor: "editApproveDate",
      isCheck: true,
      show: true,
    },
    {
      Header: "Order Status",
      accessor: "ostatus",
      isCheck: true,
      show: true,
    },
    {
      Header: "Order Delivered Date",
      accessor: "orderDeliveredDate",
      isCheck: true,
      show: true,
    },
    {
      Header: "Refund Date Allowable",
      accessor: "refundDate",
      isCheck: true,
      show: true,
    },
    {
      Header: "Action",
      accessor: "id",
      isCheck: true,
      show: true,
      Cell: (props) => {
        const order = props?.row?.original;
        return (
          <div>
            <p
              onClick={() => updateOrder(order)}
              className="underline cursor-pointer"
            >
              Save
            </p>
          </div>
        );
      },
    },
  ]);

  const [sata, setData] = useState([]);
  const [paginationObject, setPaginationObject] = useState({
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  });
  const paginationObjectRef = useRef(paginationObject);
  useEffect(() => {
    paginationObjectRef.current = paginationObject;
  }, [paginationObject]);

  const [search, setSearch] = useState("");

  const history = useHistory();
  const [show, setShow] = useState(false);
  const [Loading, setIsLoading] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [showColumns, setShowColumns] = useState(false);
  const [filters, setFilter] = useState([]);
  const [addFilter, setAddFilter] = useState([0]);
  const [summary, setSummary] = useState("All");
  const [ids, setIds] = useState([]);
  const ref = useOutsideClick(() => setShow(false));
  const refColumns = useOutsideClick(() => setShowColumns(false));
  const refFilter = useOutsideClick(() => setShowFilter(false));
  const query = new URLSearchParams(window.location.search);
  const vendor = query.get("vendorId") ?? null;
  const [customFields, setCustomFields] = useState({});
  const customFieldsRef = useRef();

  const handleChange = (value, orderNo, key) => {
    let newData = {
      ...customFieldsRef.current,
      [orderNo]: {
        ...customFieldsRef.current[orderNo],
        [key]: value,
      },
    };
    setCustomFields(newData);
  };

  const getAllOrder = () => {
    setIsLoading(true);
    axios
      .get(url + API_AllOrdersAdmin, {
        params: {
          page: paginationObjectRef.current.page,
          sortBy: "asc",
          limit: paginationObjectRef.current.limit,
          searchBy: search,
          orderStatus: summary === "All" ? undefined : summary,
        },
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then((res) => {
        if (res.data.code === 200) {
          setCustomFields({});
          const orderData = res.data.data.docs;
          let editableData = {};
          try {
            const resultData = orderData.map((v, i) => {
              const tempItem = {
                platformCommManual: v.platformCommManual ?? 0,
                transactionFees: v.transactionFees ?? 0,
                vendorPaymentRelaseDateManual: v.vendorPaymentRelaseDateManual
                  ? moment(v.vendorPaymentRelaseDateManual).format("YYYY-MM-DD")
                  : null,
                manualPmtRef: v.manualPmtRef ?? "",
                // manualPmt: v.manualPmt ?? 0,
              };
              editableData = { ...editableData, [v.orderId]: tempItem };
              const firstProduct = v.products[0];
              const milestones =
                v?.paymentMilestones[0]?.paymentmilestone[0] ?? {};
              const nextMilestone = v?.paymentMilestones?.find(
                (x) => x?.paymentmilestone[0]?.paymentStatus === 0
              );

              let editComments = "";
              let editApproveDate = "-";
              let requestEdit = [];

              if (v?.approvedChanges?.length > 0) {
                const _approvedChanges = v?.approvedChanges.filter(
                  (x) => x?.approveOrderEditRequest === true
                );
                const lastChange =
                  _approvedChanges[_approvedChanges.length - 1];

                requestEdit = [...v?.approvedChanges];
                editComments = v?.approvedChanges
                  ?.map((x) => x.changeComment)
                  .join(", ");
                editApproveDate =
                  _approvedChanges.length > 0
                    ? moment(lastChange.approveChangeOn).format("DD-MMM-YYYY")
                    : "-";
              }
              return {
                no:
                  res.data.data.total -
                  (paginationObject.page - 1) * paginationObject.limit -
                  i,
                orderNo: v?.orderId,
                orderdateee: moment(v?.createdAt).format("DD-MMM-YYYY"),
                vendorid: v?.vendor?.vendorId,
                vendorname:
                  v?.vendorDetails?.vendorName ?? v?.vendor?.vendorName ?? "",
                userId: v?.userId?.userUniqueId,
                customer: `${v.userId?.brideFirstName} ${v.userId?.groomFirstName}`,
                billingFirstName: v?.billingDetails?.firstName ?? "",
                billingLastName: v?.billingDetails?.lastName ?? "",
                billingStreetAddress: v?.billingDetails?.streetAddress ?? "",
                billingStreetAddress2: v?.billingDetails?.streetAddress2 ?? "",
                billingStreetAddress3: v?.billingDetails?.streetAddress3 ?? "",
                billingStreetCity: v?.billingDetails?.city ?? "",
                billingUnitNo: v?.billingDetails?.unitNumber ?? "",
                billingPostalCode: v?.billingDetails?.postalCode ?? "",
                billingCountry: v?.billingDetails?.country ?? "",
                billingState: v?.billingDetails?.state ?? "",
                shippingFirstName: v?.shippingDetails?.firstName ?? "",
                shippingLastName: v?.shippingDetails?.lastName ?? "",
                shippingStreetAddress: v?.shippingDetails?.streetAddress ?? "",
                shippingStreetAddress2:
                  v?.shippingDetails?.streetAddress2 ?? "",
                shippingStreetAddress3:
                  v?.shippingDetails?.streetAddress3 ?? "",
                shippingUnitNumber: v?.shippingDetails?.unitNumber ?? "",
                shippingPostalCode: v?.shippingDetails?.postalCode ?? "",
                shippingCity: v?.shippingDetails?.city ?? "",
                shippingCountry: v?.shippingDetails?.country ?? "",
                shippingState: v?.shippingDetails?.state ?? "",
                shippingPhone: v?.shippingDetails?.phone ?? "",
                vendorcontact: firstProduct?.vendorId?.phone ?? "",
                emailaddress: v?.userId?.email ?? "",
                product: firstProduct?.productTitle ?? "",
                delidate: firstProduct
                  ? moment(firstProduct?.expectedDelivery).format("DD-MMM-YYYY")
                  : "",
                prices: firstProduct?.price ?? "",
                itemdisc: firstProduct?.discount ?? "",
                qty: firstProduct?.quantity ?? "",
                subtotal: v?.subTotalwithoutDiscount ?? "",
                couponCode: v?.couponCode,
                voucherCode: v?.voucherCode,
                discamount: v?.totalDiscount ?? "",
                totalOrderAmtExclTax: v?.subTotal,
                gstamount: v?.subTotalTax ?? "",
                actualamount: v?.totalOrderAmount ?? "",
                netvoucher: v?.totalVoucher?.toFixed(2) ?? "",
                amountToPay: v?.amountToPay ?? "",
                paymilestone: v?.paymentMilestones.length ?? 0,
                platformCommManual: v.platformCommManual,
                vendorPaymentHeld: v.vendorPaymentHeld,
                transactionFees: v.transactionFees,
                vendorPaymentRelaseDateManual: v.vendorPaymentRelaseDateManual
                  ? moment(v.vendorPaymentRelaseDateManual).format(
                      "DD-MMM-YYYY"
                    )
                  : "",
                manualPmt: v.manualPmt ?? 0,
                manualPmtRef: v.manualPmtRef ?? "",
                vendorPmtStripeDate: v.vendorPmtStripeDate
                  ? moment(v.vendorPmtStripeDate).format("DD-MMM-YYYY")
                  : "-",
                totalPlatFormCom: v?.totalPlatFormCom?.formattedValue,
                totalTransactionFees: v?.totalTransactionFees,
                payduedate: nextMilestone
                  ? moment(
                      nextMilestone?.paymentmilestone[0]?.milestoneDate ?? ""
                    ).format("DD-MMM-YYYY")
                  : "-",
                paymentDate: moment(milestones?.paymentDate ?? "").format(
                  "DD-MMM-YYYY"
                ),
                voucherOffset: v?.voucherOffset,
                netPaymentDue: v?.netPayment,
                remainingVoucher: v?.remainingVoucher,
                comment: v.comment ?? "",
                editComment: editComments,
                editApproveDate,
                requestEdit,
                modifiedon: moment(v?.updatedAt).format("DD-MMM-YYYY"),
                ostatus: getOrderStatus(v?.orderStatus ?? ""),
                orderStatus: v?.orderStatus,
                totalAmtPaid: usdFormatter(v.totalAmtPaid ?? 0),
                refundDate: v?.refundDate,
                orderDeliveredDate: v?.orderDeliveredDate ? moment(v?.orderDeliveredDate).format("DD-MMM-YYYY") : null,
                vendorPolicyId:
                  v?.paymentMilestones[0]?.vendorPaymentPolicyId ?? "",
                id: v.id,
                platFormCommStripe: v?.platFormCommStripe,
                stripeFees: v?.stripeFees,
                comissionCollected: usdFormatter(v.totalPaidPlatformStripeComm),
                comissionReceivables: usdFormatter(
                  v?.totalPlatFormCom?.value -
                    v.totalPaidPlatformStripeComm -
                    v.platformCommManual
                ),
                stripePayoutId: v?.stripePayoutId,
                stripePayout: v?.stripePayout?.formattedValue,
              };
            });
            setCustomFields(JSON.parse(JSON.stringify(editableData)));
            setIsLoading(false);
            setData(resultData);
            setPaginationObject((prev) => ({
              ...prev,
              pages: res.data.data.pages,
              total: res.data.data.total,
            }));
          } catch (ex) {
            console.log(ex);
          }
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const updateOrder = (order) => {
    axios
      .patch(
        url + API_OrderViewId + `${order?.id}`,
        customFieldsRef.current[order?.orderNo],
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
      .then((res) => {
        if (res.data.code === 200) {
          toast.success("Order Updated Successfully!");
          getAllOrder();
        } else {
          toast.error(res.data?.message ?? "Error occured please try again!");
        }
      })
      .catch((err) => {
        toast.error(err?.message ?? "Error occured please try again!");
        console.log(err);
      });
  };

  const OnOrder = (ev) => {
    var ord = ev.target.value;
    setSearch(ord);
    localStorage.setItem("lastSearchTerm", ord);
  };

  const handleCancel = () => {
    if (ids.length === 0) {
      toast.error("Please Select Orders!");
      return;
    }
    axios
      .post(
        url + API_cancel_order + "/cancelMultipleOrders",
        { ids: ids },
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
      .then((res) => {
        if (res.data.code === 200) {
          toast.success("Orders Successfully Cancelled!");
          getAllOrder();
        }
      });
  };

  useEffect(() => {
    if (ids.length !== 0) {
      localStorage.setItem("orderIds", JSON.stringify(ids));
    }
  }, [ids]);

  useEffect(() => {
    const lastSearch = vendor ?? localStorage.getItem("lastSearchTerm") ?? "";
    if (lastSearch) {
      setSearch(lastSearch);
    } else {
      getAllOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (search.length >= 1 || (search.length === 0 && !Loading)) {
      getAllOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, paginationObject.page, paginationObject.limit, summary]);

  useEffect(() => {
    customFieldsRef.current = customFields;
  }, [customFields]);

  const handleStatus = (status) => {
    setSummary(status);
    setPaginationObject((prev) => ({
      ...prev,
      page: 1,
    }));
  };
  return (
    <>
      <SectionTitle title="Orders" subtitle="All Orders View" />
      <div className="p-2 bg-white border rounded-md">
        <div className="flex justify-between">
          <div className="flex p-3">
            {checkAccess(20, "edit") && (
              <button
                style={{ background: "#990000" }}
                className={` py-2 px-4 mr-2 rounded shadow-sm ${"text-white "} w-40`}
                onClick={() => {
                  if (ids.length === 0) toast.error("Please select order");
                  else history.push("/order-payment-summary");
                }}
              >
                Payment
              </button>
            )}
            {checkAccess(20, "edit") && (
              <button
                style={{ background: "#990000" }}
                className={` py-2 px-4 mr-2 rounded shadow-sm ${"text-white "} w-40`}
                onClick={handleCancel}
              >
                Cancel Order
              </button>
            )}
          </div>
        </div>

        <div className="flex justify-between">
          <div className="flex p-3">
            <SummaryButton
              summary={summary}
              setSummary={handleStatus}
              title="All"
            />
            <SummaryButton
              summary={summary}
              setSummary={handleStatus}
              title={1}
            />
            <SummaryButton
              summary={summary}
              setSummary={handleStatus}
              title={2}
            />
            <SummaryButton
              summary={summary}
              setSummary={handleStatus}
              title={4}
            />
            <SummaryButton
              summary={summary}
              setSummary={handleStatus}
              title={5}
            />
            <SummaryButton
              summary={summary}
              setSummary={handleStatus}
              title={0}
            />
            <div className="flex border-2 rounded-md hover:border-blue-500 w-full mr-3">
              <svg
                aria-hidden="true"
                className="flex justify-self-center self-center focus:w-5 h-5 text-gray-500 dark:text-gray-400 ml-2 text-grey-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 35 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
              <input
                value={search}
                type="Search"
                placeholder="Search"
                className="w-96"
                onChange={OnOrder}
              ></input>
            </div>
          </div>

          <div className="flex p-3 w-full">
            <div ref={refFilter} className="ml-auto">
              <CsvDowloader columns={columns} data={sata} filename="orders" />
              <button
                onClick={() => setShowFilter(!showFilter)}
                className="bg-white hover:bg-grey-200 py-2 px-4 mr-2 rounded shadow-sm"
              >
                Filter
              </button>

              {showFilter && (
                <div className="relative">
                  <div
                    style={{ left: "-80px" }}
                    className="absolute shadow-lg bottom-20 bg-white rounded-md z-10 px-2 py-2"
                  >
                    <div>Filter By</div>
                    {filters.map((item, index) => (
                      <>
                        <div className="flex space-x-2 items-center">
                          <input
                            type="input"
                            value={Object.keys(item)[0]}
                            className="bg-white  w-32 mt-2 py-1 rounded-md"
                          />

                          {Object.keys(filters[index])[0] !== "status" ? (
                            <></>
                          ) : (
                            <select
                              className="bg-white border-2 w-32 mt-2 py-1 rounded-md"
                              onChange={(e) => {
                                const updatedfilter = [...filters];
                                updatedfilter[index] = {
                                  [Object.keys(updatedfilter[index])[0]]:
                                    e.target.value,
                                };
                                setFilter(updatedfilter);
                                getAllOrder();
                              }}
                            >
                              <option value={""}>select</option>
                              <option value={2}>Unfullfiled</option>
                              <option value={2}>Open</option>
                              <option value={4}>Close</option>
                              <option value={0}>Cancelled</option>
                              <option value={0}>Drafts</option>
                            </select>
                          )}
                          {
                            <AiOutlineClose
                              onClick={() => {
                                const updatedfilter = [...filters];
                                if (
                                  Object.keys(updatedfilter[index])[0] ===
                                  "status"
                                ) {
                                  getAllOrder();
                                }
                                const update = updatedfilter.filter(
                                  (filte) =>
                                    Object.keys(filte)[0] !==
                                    Object.keys(item)[0]
                                );
                                setFilter(update);
                                setAddFilter([]);
                              }}
                              size={15}
                              className="mt-2 cursor-pointer text-grey-60000"
                            />
                          }
                        </div>
                      </>
                    ))}
                    {addFilter.map((_, index) => (
                      <FilterItem
                        index={index}
                        setFilter={setFilter}
                        filters={filters}
                        setAddFilter={setAddFilter}
                        onRemovePress={() => {
                          const newData = [...addFilter];
                          newData.pop();
                          setAddFilter(newData);
                        }}
                      />
                    ))}
                    <p
                      onClick={() => setAddFilter([...addFilter, 2])}
                      className="mt-2 font-semibold cursor-pointer text-blue-500"
                    >
                      + Add more filter
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className="flex ">
              <div ref={refColumns}>
                <button
                  onClick={() => setShowColumns(!showColumns)}
                  className="bg-white hover:bg-grey-200 py-2 px-4 mr-2 rounded shadow-sm "
                >
                  Columns
                </button>

                {showColumns && (
                  <div className="relative">
                    <div className=" absolute shadow-lg right-0 bottom-20 bg-white rounded-md z-10 h-40 overflow-y-scroll">
                      {columns.map((item, index) => (
                        <div key={index}>
                          <MenuCheckBox
                            title={item.Header}
                            isCheck={item.isCheck}
                            setCheck={(index) => {
                              const newData = [...columns];
                              newData[index].isCheck = !newData[index].isCheck;
                              newData[index].show = !newData[index].show;
                              setColumns(newData);
                            }}
                            index={index}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div ref={ref}>
              {/* <button
                onClick={() => setShow(!show)}
                className="bg-white hover:bg-grey-200 py-2 px-4 rounded shadow-sm"
              >
                Bulk Action
              </button> */}

              {show && (
                <div className="relative">
                  <div className=" absolute shadow-lg right-0 bottom-20 bg-white w-48 rounded-md z-10">
                    <Menu
                      title="Approve Vendors"
                      onClick={() => console.log("Active")}
                    />
                    <hr></hr>
                    <Menu
                      title="Disable Selling"
                      onClick={() => console.log("Deactive")}
                    />
                    <hr></hr>
                    <Menu
                      title="Reject Vendors"
                      onClick={() => console.log("Copy")}
                    />
                    <hr></hr>
                    <Menu
                      title="Warning Vendors"
                      onClick={() => console.log("Delete")}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <OrderPageData
          setIds={setIds}
          columns={columns}
          data={sata}
          loading={Loading}
          handlePagination={(page, limit) =>
            setPaginationObject((prev) => ({
              ...prev,
              page: Number(page),
              limit: Number(limit),
            }))
          }
          showSearch={true}
          pagination={paginationObject}
        />

        <ModalRequestEdit
          title={"Approved Order Edit"}
          body={selectedOrderEdit}
          open={openModalOrderEdit}
          setOpen={setOpenModalOrderEdit}
        />

        <ModalReviewDescription
          title={"Comments"}
          body={selectedComments}
          open={openModalComments}
          setOpen={setOpenModalComments}
        />
      </div>
    </>
  );
};
export default Index;
