import React from 'react'
import { IoMdClose } from 'react-icons/io'

const DateInput = ({ type = 'date', value, onChange = () => { }, className = '', label = '', disabled = false }) => {
    return (
        <div className='w-full'>
            <p className="text-sm">{label}</p>
            <div className="relative w-full">
                <input
                    type={type}
                    value={value}
                    disabled={disabled}
                    onChange={onChange}
                    className={`border border-grey-400 rounded-sm w-full px-3 py-2 ${className}`}
                />
                {value && <IoMdClose onClick={() => onChange({ target: { value: '' } })} className="absolute top-0 bottom-0 m-auto cursor-pointer" style={{ right: '34px' }} />}
            </div>
        </div>
    )
}

export default DateInput