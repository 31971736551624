import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const Attributes = () => {
  const productForm = useSelector((state) => state.productsSlice);

  const [filterAttributes, setFilterAttributes] = useState([]);

  useEffect(() => {
    if (productForm.product) {
      for (
        let index = 0;
        index < productForm.product?.filterId?.length;
        index++
      ) {
        setFilterAttributes([
          ...filterAttributes,
          {
            filterName: productForm.product.filterId[index].name,
            value: productForm.product.filterValues[index],
          },
        ]);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productForm]);

  return (
    <div className="flex flex-col mt-5 space-y-5">
      <div className="pt-4">
        <div className="w-full">
          <table className="w-full text-sm text-left">
            <thead className="">
              <tr>
                <th scope="col" className="px-6 py-3 text-xl">
                  Filters Name
                </th>
                <th scope="col" className="px-6 py-3 text-xl">
                  Filters Value
                </th>
              </tr>
            </thead>

            <tbody className="border-b-0.5">
              {filterAttributes.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className={`border-t-0.5 ${
                      index % 2 !== 0 ? "bg-bg-romance" : ""
                    }`}
                  >
                    <td className="px-6 py-4">
                      <div>
                        <p className="text-xl">{item.filterName}</p>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <input
                        value={item.value}
                        disabled
                        type="text"
                        className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex space-x-3 w-full">
        <p className="mb-2 ml-6">Visible on Product Page:</p>
        <input
          checked
          className="mr-2 mt-1 h-5 w-5 bg-white cursor-pointer"
          type="checkbox"
        />
      </div>
    </div>
  );
};

export default Attributes;
