import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Alert from "../alerts";

const FormValidation = ({
  items,
  onSubmit,
  alerts,
  onChange,
  phoneErr,
  brideErr,
  brideLastErr,
  groomErr,
  groomLastErr,
  HearErr,
}) => {
  const { handleSubmit, errors, register } = useForm();
  const [emailValidError] = useState(false);
  const onSubmitFn = (data) => {
    if (onSubmit) {
      onSubmit(data);
    }
  };
  items = items.map((item) => {
    item["ref"] = register(item["error"]);
    return item;
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmitFn)}
      className="form flex flex-wrap w-full"
    >
      {alerts &&
        items.map((item, i) => {
          if (!errors[item.name]) return null;
          let msg = errors[item.name].message;
          if (msg.length === 0) msg = `${item.label} is required`;
          return (
            <div className="flex flex-col w-full">
              {errors[item.name] && (
                <div className="mb-2" key={i}>
                  <Alert
                    color="bg-transparent border-red-500 text-red-500"
                    borderLeft
                    raised
                  >
                    {msg}
                  </Alert>
                </div>
              )}
            </div>
          );
        })}
      <div className="w-full vendor-flex-box edit_user_box">
        {items.map((item, i) => {
          if (item.type === "date") {
            return (
              <>
                <div className="form-element lg:w-1/2">
                  {item.label && <div className="form-label " style={{paddingLeft: "0px"}}>{item.label}</div>}
                  <input
                    ref={item.ref}
                    id={item.id}
                    value={item.value}
                    name={item.name}
                    type={item.type}
                    min={item.min}
                    onChange={(e) => onChange(e.target.value, item.id)}
                    className={`form-input ${ errors[item.name] ? "border-red-500" : "" } ${item?.readOnly ? "bg-grey-300 cursor-not-allowed" : ""}`}
                    placeholder={item.placeholder}
                    readOnly={item?.readOnly ?? false}
                  />
                  {!alerts && errors[item.name] && (
                    <div className="form-error">
                      {errors[item.name].message}
                    </div>
                  )}
                  {emailValidError && (
                    <div className="form-error">Please enter valid email</div>
                  )}
                </div>
              </>
            );
          }
          if (item.inputType === "user-input") {
            return (
              <>
                <div className="form-element lg:w-1/2">
                  {item.label && <div className="form-label"  style={{paddingLeft: "0px"}}>{item.label}</div>}
                  <input
                    ref={item.ref}
                    id={item.id}
                    value={item.value}
                    name={item.name}
                    type={item.type}
                    onChange={(e) => onChange(e.target.value, item.id)}
                    className={`form-input ${
                      errors[item.name] ? "border-red-500" : ""
                    }`}
                    placeholder={item.placeholder}
                  />
                  {!alerts && errors[item.name] && (
                    <div className="form-error">
                      {errors[item.name].message}
                    </div>
                  )}
                  {emailValidError && (
                    <div className="form-error">Please enter valid email</div>
                  )}
                  {item.id === "phone" && (
                    <p style={{ color: "red" }}>{phoneErr}</p>
                  )}
                  {item.id === "brideFirstName" && (
                    <p style={{ color: "red" }}>{brideErr}</p>
                  )}
                  {item.id === "brideLastName" && (
                    <p style={{ color: "red" }}>{brideLastErr}</p>
                  )}
                  {item.id === "groomFirstName" && (
                    <p style={{ color: "red" }}>{groomErr}</p>
                  )}
                  {item.id === "groomLastName" && (
                    <p style={{ color: "red" }}>{groomLastErr}</p>
                  )}
                  {item.id === "hearAboutUs" && (
                    <p style={{ color: "red" }}>{HearErr}</p>
                  )}
                </div>
              </>
            );
          }
          if (item.type === "select") {
            return (
              <div className="form-element">
                {item.label && <div className="form-label"  style={{paddingLeft: "0px"}}>{item.label}</div>}
                <select
                  ref={item.ref}
                  name={item.name}
                  value={item.value}
                  id={item.id}
                  type={item.type}
                  onChange={(e) => onChange(e.target.value, item.id)}
                  className={`form-select ${
                    errors[item.name] ? "border border-red-500" : ""
                  }`}
                >
                  {item.options.map((option, j) => (
                    <option key={j} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {!alerts && errors[item.name] && (
                  <div className="form-error">{errors[item.name].message}</div>
                )}
              </div>
            );
          }
          return (
            <>
              <div className="form-element">
                {item.label && <div className="form-label"  style={{paddingLeft: "0px"}}>{item.label}</div>}
                <input
                  ref={item.ref}
                  name={item.name}
                  type={item.type}
                  value={item.value}
                  id={item.id}
                  className={`form-input ${
                    errors[item.name] ? "border-red-500" : ""
                  }`}
                  placeholder={item.placeholder}
                />
                {!alerts && errors[item.name] && (
                  <div className="form-error">{errors[item.name].message}</div>
                )}
              </div>
            </>
          );
        })}
      </div>
      <button
        type="submit"
        className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
      >
        Submit
      </button>
    </form>
  );
};
export default FormValidation;
