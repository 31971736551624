import React from "react";

const PhysicalMeeting = () => {
  return (
    <>
      <div className="relative overflow-x-auto">
        <table className="w-full text-left">
          <thead className="bg-bg-primary text-white">
            <tr>
              <th className="px-4 py-3 text-lg"></th>
              <th className="px-4 py-3 text-lg">VENUE</th>
              <th className="px-4 py-3 text-lg">ADDRESS</th>
            </tr>
          </thead>
          <tbody className="border-b-0.5">
            {[1, 2].map((item, index) => (
              <tr key={index} className={`border-t-0.5 ${index % 2 !== 0 ? "bg-bg-romance" : ""}`}>
                <td className="px-4 py-4 text-lg">
                  <input type="checkbox" className="w-5 h-5" />
                </td>
                <td className="px-4 py-4 text-lg">Venue {index + 1}</td>
                <td className="px-4 py-4 text-lg">133 Blk 52, #05-1 Bishan St 21</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PhysicalMeeting;
