import React, { useState, useEffect, useRef } from "react";
import { useSelector, shallowEqual } from "react-redux";
import Portal from "../portal";
import { FiX } from "react-icons/fi";
import {
  API_CreateSubSubCategory,
  API_GetAllSubCategory,
  API_MasterCategoryList,
  API_GetAllCategory,
  url,
} from "../../apis/constant";
import ApiClient from "../../apis/apiClient";
import { toast } from "react-toastify";
import axios from "axios";
import AsyncSelect from "react-select/async";

const AddSubSubCategory = ({ id, title, getApi }) => {
  const alphanumericRegex = /^[^'"`]+$/;
  const adminToken = localStorage.getItem("wed_admin_token");
  const [error, setError] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [nameValue, setNameValue] = useState("");
  const [aMasterCatData, setAMasterCategoryData] = useState("");
  const [categryList, setCategoryList] = useState([]);
  const [subCatList, setSubCategoryList] = useState([]);
  const [subCatData, setSubCatData] = useState("");
  const [subCatError, setSubCatError] = useState("");
  const [masterCatError, setMasterCatError] = useState("");
  const [catError, setCatError] = useState("");
  const [aCatData, setACategoryData] = useState("");
  const [selectedMasterCategoryValue, setSelectedMasterCategoryValue] =
    useState(null);
  const [selectedCategoryValue, setSelectedCategoryValue] = useState(null);
  const [selectedSubCategoryValue, setSelectedSubCategoryValue] =
    useState(null);

  const { palettes } = useSelector(
    (state) => ({
      palettes: state.palettes,
    }),
    shallowEqual
  );
  let { background } = {
    ...palettes,
  };

  const modalRef = useRef(null);
  const [open, setOpen] = useState(false);
  const show = () => {
    getsubcategoryById();
    setOpen(true);
  };
  const hide = () => {
    setOpen(false);
    setNameValue("");
    setAMasterCategoryData("");
    setACategoryData("");
    setSubCatData("");
  };

  useEffect(() => {
    getAllMasterCategory("");
    getAllSubCategory("");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllMasterCategory = async (inputValue) => {
    const data = "";
    const res = await ApiClient.get(
      API_MasterCategoryList +
        `?page=1&sortBy=asc&limit=${30}&searchBy=${inputValue}`,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      return res.data.data.docs;
    }
  };

  const getAllSubCategory = async (limit, searchq = "") => {
    const data = "";
    const res = await ApiClient.get(
      API_GetAllSubCategory,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false;
      if (!open || modalRef.current.contains(event.target)) {
        return false;
      }
      setOpen(!open);
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, modalRef]);

  const getsubcategoryById = () => {
    axios
      .get(url + API_CreateSubSubCategory, +"/" + id, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((res) => {
        if (res.data.code === 200) {
          setNameValue(res.data.data.name);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handleNameValue = (value) => {
    const limit = 200;
    if (value.length > limit) {
      setError("Please enter less than 200 words.");
    } else if (value && !alphanumericRegex.test(value)) {
      setError("Please enter valid name.");
      setNameValue(value);
      return;
    } else {
      setError("");
      setNameValue(value);
    }
  };

  const handleCategory = () => {
    let isTrue = false;
    setIsSubmit(true);
    if (nameValue === "") {
      setError("Name should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setError("");
    }
    if (aMasterCatData === "") {
      setMasterCatError("Level 1 should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setMasterCatError("");
    }
    if (aCatData === "") {
      setCatError("Level 2 should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setCatError("");
    }
    if (subCatData === "") {
      setSubCatError("Level 3 should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setSubCatError("");
    }

    if (isTrue) {
      return;
    }

    let logData = {
      name: nameValue,
      masterCategoryId: aMasterCatData,
      categoryId: aCatData,
      subCategoryId: subCatData,
    };
    axios
      .post(url + API_CreateSubSubCategory, logData, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((res) => {
        if (res.data.code === 200) {
          toast.success(res.data.message);
          hide();
          setIsSubmit(false);
          setNameValue("");
          getApi(10, 1);
          setAMasterCategoryData("");
          setACategoryData("");
          setSubCatData("");
          //
          setSelectedMasterCategoryValue("");
          setSelectedCategoryValue("");
          setSelectedSubCategoryValue("");
        } else if (res.data.code === 400) {
          toast.error(res.data.message);
          setIsSubmit(false);
        }
      })
      .catch((err) => {
        setIsSubmit(false);
        toast.error(err.response.data.message);
      });
  };

  //

  const handleSubCategory = (e) => {
    setSelectedSubCategoryValue(e);
    setSubCatData(e.id);
  };

  const handleACategory = async (e) => {
    const id = e.id;
    setSelectedCategoryValue(e);
    setACategoryData(id);

    if (id === "") {
      setSubCategoryList([]);
      setSubCatData("");
      return;
    }

    setACategoryData(id);
    localStorage.setItem("categoryId", id);
    getSubCategoryListUsingCategoryId(id);
  };

  const getSubCategoryListUsingCategoryId = async (id, inputValue) => {
    var categoryId = id ? id : localStorage.getItem("categoryId");
    const res = await ApiClient.get(
      API_GetAllSubCategory +
        `?categoryId=${categoryId}&page=1&sortBy=asc&limit=${10}&searchBy=${inputValue}`,
      {},
      `Bearer ${adminToken}`
    );

    if (res.data.code === 200) {
      let full_arr = res.data.data.docs;
      setSubCategoryList(full_arr);
      return full_arr;
    }
  };

  const handleAMasterCategory = async (e) => {
    const id = e.id;
    setSelectedMasterCategoryValue(e);
    if (id === "") {
      setCategoryList([]);
      setSubCatData("");
      return;
    }
    setAMasterCategoryData(id);
    localStorage.setItem("masterId", id);
    getCategoryListUsingMasterId(id);
  };

  const getCategoryListUsingMasterId = async (id, inputValue) => {
    var masterId = id ? id : localStorage.getItem("masterId");
    const res = await ApiClient.get(
      API_GetAllCategory +
        `?masterCategoryId=${masterId}&page=1&sortBy=asc&limit=${10}&searchBy=${inputValue}`,
      {},
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      let full_arr = res.data.data.docs;
      setCategoryList(full_arr);
      return full_arr;
    }
  };

  return (
    <>
      <button
        className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white "
        type="button"
        onClick={show}
      >
        Add Level 4
      </button>
      {open && (
        <Portal selector="#portal">
          <div className="modal-backdrop fade-in"></div>
          <div
            className={`modal show ${background === "dark" ? "dark-mode" : ""}`}
            data-background={background}
          >
            <div
              className="relative min-w-sm w-auto mx-auto lg:max-w-5xl"
              ref={modalRef}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="text-xl font-semibold">{title}</h3>
                  <button
                    className="modal-close btn btn-transparent"
                    onClick={hide}
                  >
                    <FiX size={18} className="stroke-current" />
                  </button>
                </div>
                <div className="relative p-4 flex-auto">
                  <label>Title:</label>
                  <div className="inputBoxCategoryCss">
                    <input
                      type="text"
                      placeholder="Please Enter Title"
                      name="name"
                      value={nameValue}
                      onChange={(e) => handleNameValue(e.target.value)}
                      className="inputFields"
                    />
                  </div>

                  {error && (
                    <p style={{ color: "red", padding: "10px 4px" }}>{error}</p>
                  )}
                </div>
                <div className="form-element">
                  <div className="form-label" style={{ marginLeft: "5px" }}>
                    Level 1:
                  </div>
                  <AsyncSelect
                    className="selectWithSearch"
                    cacheOptions
                    defaultOptions
                    value={selectedMasterCategoryValue}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.id}
                    loadOptions={getAllMasterCategory}
                    onChange={handleAMasterCategory}
                  />
                  {masterCatError && (
                    <p
                      style={{
                        color: "red",
                        padding: "10px 4px",
                        paddingLeft: "20px",
                      }}
                    >
                      {masterCatError}
                    </p>
                  )}
                </div>

                <div className="form-element">
                  <div className="form-label" style={{ marginLeft: "5px" }}>
                    Level 2:
                  </div>
                  <AsyncSelect
                    className="selectWithSearch"
                    key={categryList.length}
                    cacheOptions
                    defaultOptions
                    value={selectedCategoryValue}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.id}
                    loadOptions={(e) => getCategoryListUsingMasterId("", e)}
                    onChange={handleACategory}
                  />
                  {catError && (
                    <p
                      style={{
                        color: "red",
                        padding: "10px 4px",
                        paddingLeft: "20px",
                      }}
                    >
                      {catError}
                    </p>
                  )}
                </div>

                <div className="form-element">
                  <div className="form-label" style={{ marginLeft: "5px" }}>
                    Level 3:
                  </div>
                  <AsyncSelect
                    className="selectWithSearch"
                    key={subCatList.length}
                    cacheOptions
                    defaultOptions
                    value={selectedSubCategoryValue}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.id}
                    loadOptions={(e) =>
                      getSubCategoryListUsingCategoryId("", e)
                    }
                    onChange={handleSubCategory}
                  />
                  {subCatError && (
                    <p
                      style={{
                        color: "red",
                        padding: "10px 4px",
                        paddingLeft: "20px",
                      }}
                    >
                      {subCatError}
                    </p>
                  )}
                </div>

                <div className="modal-footer space-x-2">
                  <button
                    className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                    type="button"
                    onClick={hide}
                  >
                    Close
                  </button>
                  {isSubmit ? (
                    <button
                      className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                      type="button"
                    >
                      Loading...
                    </button>
                  ) : (
                    <button
                      className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                      type="button"
                      onClick={() => handleCategory()}
                    >
                      Save Changes
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default AddSubSubCategory;
