import React from "react";
import SubUsersEdit from "./subUsersEditForm";
import Widget from "../widget";

const EditSubUserList = ( ) => {
  return (
    <>
      <Widget description={<span>Edit SubUser</span>}>
        <div className="w-full flex">
          <div className="w-full">
            <SubUsersEdit />
          </div>
        </div>
      </Widget>
    </>
  );
};

export default EditSubUserList;
