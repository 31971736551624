/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import ReactStars from "react-rating-stars-component";
import moment from "moment";
import SectionTitle from "../components/section-title";
import Modal11 from "../components/modals/modal-1-1";
import ModalImport from "../components/modals/modal-import";
import CustomerReviewPageData from "../components/customerReview";
import ApiClient from "../apis/apiClient";
import { getAllReviewList, getDeletedReviewList, url } from "../apis/constant";
import { toast } from "react-toastify";
import ModalActivate from "../components/confirm-activate-modal/modal-activate";
import { FiX } from "react-icons/fi";
import AuditModal from "./customer-review-modal";
import review from "../assets/files/ReviewSample.xlsx";
import Aggregatereview from "../assets/files/aggregateSample.xlsx";
import { checkAccess } from "../utils/common";
import Modal from "../components/modals/modal-1";
import ModalReviewDescription from "../components/modals/modalReviewDescription";

const Simple = ({
  columns,
  data,
  setIds,
  paginationObject,
  hiddenCol,
  Loading,
  getPageData,
  getAllReviewListData,
}) => {
  //  data = React.useMemo(() => data, []);
  return (
    <>
      <CustomerReviewPageData
        columns={columns}
        data={data}
        loading={Loading}
        onSubmit={getPageData}
        showSearch={true}
        pagination={paginationObject}
        getAllVendorProduct={getAllReviewListData}
        setIds={setIds}
        hiddenCol={hiddenCol}
      />
    </>
  );
};

const SummaryButton = ({
  summary,
  setSummary,
  title,
  filter,
  val,
  isAggregated,
  setIsAggregated,
}) => (
  <button
    onClick={() => {
      if (title !== "Aggregated") {
        filter(val);
        if (summary === title) {
          setSummary("");
          return;
        }
        setSummary(title);
      } else {
        setIsAggregated(!isAggregated);
        if (summary === title) {
          setSummary("");
          return;
        }
        setSummary(title);
      }
    }}
    className={` py-2 px-4 mr-2 w-full rounded shadow-sm border customer_review_btn ${
      summary === title
        ? "bg-blue-500 text-white hover:bg-blue-300"
        : "bg-white hover:bg-grey-200"
    }`}
  >
    {title}
  </button>
);

const Index = () => {
  const [open, setOpen] = useState(false);

  const handleDelete = async (review) => {
    const reviewId = review?.cell?.row?.original?.id;
    if (!reviewId) {
      toast.error("Please select review!");
      return;
    }

    const res = await ApiClient.delete(
      getAllReviewList + reviewId,
      null,
      `Bearer ${adminToken}`
    );

    // handle authorization error
    if (res?.data?.code === 401) {
      toast.error(res?.data?.message);
      return;
    }

    if (res.data.code === 200) {
      toast.success(res.data.message);
      await getAllReviewListData(10, "", 1, "");
    }
  };

  const [isAggregated, setIsAggregated] = useState(false);
  const [requestReview, setRequestReview] = useState([]);
  const [AllData, setAllData] = useState();
  const inputFile = useRef("");
  const [selectedReviewDesc, setSelectedReviewDesc] = useState("");
  const [openModalReviewDesc, setOpenModalReviewDesc] = useState(false);
  const [columns, setColumns] = useState([
    {
      Header: "No.",
      accessor: "no",
    },
    {
      Header: "Vendor Id",
      accessor: "alpha2Code",
      Cell: (props) => {
        return (
          <div style={{ minWidth: "80px" }}>
            <p>{props.cell.row.original?.vendorId?.vendorId}</p>
          </div>
        );
      },
      isCheck: true,
    },
    {
      Header: "Vendor Name",
      accessor: "name",
      Cell: (props) => {
        return <p>{props.cell.row.original?.vendorId?.vendorName}</p>;
      },
      isCheck: true,
    },
    {
      Header: "Order Id",
      accessor: "orderId",
      Cell: (props) => {
        return <p>{props.cell.row.original?.orderId.orderId}</p>;
      },
    },
    {
      Header: "Rating",
      accessor: "rating",
      Cell: (props) => {
        return (
          <div className="w-24">
            <ReactStars
              value={props.cell.row.original.rating}
              count={5}
              size={16}
              activeColor="#ffd700"
              isHalf={true}
              edit={false}
            />
          </div>
        );
      },
      isCheck: true,
    },
    {
      Header: "Total Review",
      accessor: "totalReviews",
      Cell: (props) => {
        return <p>{props.value}</p>;
      },
      isCheck: true,
      hidden: !isAggregated,
    },
    {
      Header: "Review Title",
      accessor: "title",
      Cell: (props) => {
        return <p>{props.value}</p>;
      },
      isCheck: true,
    },
    {
      Header: "Review Description",
      accessor: "reviewDescription",
      Cell: (props) => {
        const reviewDescription =
          props.cell.row.original?.reviewDescription || "";
        const truncatedDescription =
          reviewDescription.length > 50
            ? reviewDescription.substring(0, 50) + "....."
            : reviewDescription;
        const handleViewReviewDesc = () => {
          setSelectedReviewDesc(reviewDescription);
          setOpenModalReviewDesc(true);
        };
        return (
          <div className="flex items-center space-x-2">
            {reviewDescription.length > 50 ? (
              <p
                onClick={handleViewReviewDesc}
                className="cursor-pointer hover:text-grey-500"
              >
                {truncatedDescription}
              </p>
            ) : (
              <p>{truncatedDescription}</p>
            )}
          </div>
        );
      },
      isCheck: true,
      hidden: isAggregated,
    },
    {
      Header: "Reviwer Name",
      accessor: "alpha3Code",
      Cell: (props) => {
        if (props.cell.row.original?.latestOne?.Reviewer_name) {
          return <p>{props.cell.row.original?.latestOne?.Reviewer_name}</p>;
        } else {
          return (
            <p>
              {props.cell.row.original?.userId?.brideFirstName +
                " " +
                props.cell.row.original?.userId?.groomFirstName}
            </p>
          );
        }
      },
      isCheck: true,
    },
    {
      Header: "Review Date",
      accessor: "date",
      Cell: (props) => {
        return (
          <p>
            {moment(
              props.cell.row.original?.acceptRejectDate
                ? props.cell.row.original?.acceptRejectDate
                : props.cell.row.original?.updatedAt
            ).format("DD-MMM-YYYY")}
          </p>
        );
      },
      isCheck: true,
      hidden: isAggregated,
    },
    {
      Header: "Customer Review Status",
      accessor: "status",
      Cell: (props) => {
        return (
          <div className="-mt-4">
            <Modal11
              title={"Update Status"}
              body={"Do you really want to update the status?"}
              shortDesc={"hi"}
              props={props}
              handleAction={handleAction}
            />
          </div>
        );
      },
      isCheck: true,
    },
    // {
    //   Header: "Action",
    //   accessor: "action",
    //   Cell: (props) => {
    //     return (
    //       <div>
    //         {props.cell.row.original?.isDelete == 0 ? (
    //           <button className="opacity-50">Delete</button>
    //         ) : (
    //           <button onClick={() => handleDelete(props)}>Delete</button>
    //         )}
    //       </div>
    //     );
    //   },
    //   isCheck: true,
    // },
    {
      Header: "Submitted Date",
      accessor: "enddate",
      Cell: (props) => {
        return (
          <p>
            {moment(
              props.cell.row.original?.resubmitDate
                ? props.cell.row.original?.resubmitDate
                : props.cell.row.original?.createdAt
            ).format("DD-MMM-YYYY")}
            {/* {props.cell.row.original?.createdAt} */}
          </p>
        );
      },
      isCheck: true,
    },
    // {
    //   Header: "Action",
    //   Cell: (props) => {
    //     return <FiSave size={20} className="cursor-pointer mt-1 ml-4" />;
    //   },
    //   isCheck: true,
    // },
  ]);

  const [summary, setSummary] = useState("Pending");
  const [reviewList, setReviewList] = useState([]);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("0");
  const [hidden, setHidden] = useState(true);
  const [isOpened, setIsOpened] = useState(false);
  const [isImportOpened, setIsImportOpened] = useState(false);
  const [paginationObject, setPaginationObject] = useState({});
  const [importFiles, setImportFiles] = useState([]);
  const [hiddenCol, setHiddenCol] = useState([]);
  const [Loading, setIsLoading] = useState(false);
  const [ids, setIds] = useState([]);
  // const [userList, setUserList] = useState([]);
  const [userSelected, setUserSelected] = useState("");
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);
  const adminToken = localStorage.getItem("wed_admin_token");

  const getAllReviewListData = async (
    limit,
    searchq = "",
    pageNumber,
    _status
  ) => {
    setStatus(_status);
    localStorage.setItem("customerReviewStatus", _status);
    const data = "";
    setIsLoading(true);
    let res;
    setHiddenCol(["totalReviews"]);

    if (summary == "Deleted") {
      res = await ApiClient.get(
        getDeletedReviewList +
          `?page=${pageNumber}&sortBy=asc&limit=${limit}&searchBy=${searchq}&status=${_status}&aggregate=`,

        data,
        `Bearer ${adminToken}`
      );
    } else {
      res = await ApiClient.get(
        getAllReviewList +
          `?page=${pageNumber}&sortBy=asc&limit=${limit}&searchBy=${searchq}&status=${_status}&aggregate=`,

        data,
        `Bearer ${adminToken}`
      );
    }

    if (res?.data?.code === 200) {
      setPaginationObject({
        limit: res?.data?.data?.limit,
        page: res?.data?.data?.page,
        pages: res?.data?.data?.pages,
        total: res?.data?.data?.total,
      });

      const _data = res.data.data.docs ?? [];
      const _dataTemp = _data.map((item, index) => {
        return {
          ...item,
          no: res?.data?.data?.total - (pageNumber - 1) * limit - index,
        };
      });
      setReviewList(_dataTemp);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllReviewListData(10, search, 1, status);
    setColumns([...columns]);
  }, [search, isAggregated]);

  const getPageData = (data) => {
    getAllReviewListData(data, "", 1, status);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        hidden ||
        buttonRef.current.contains(event.target) ||
        dropdownRef.current.contains(event.target)
      ) {
        return false;
      }
      setHidden(!hidden);
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hidden, buttonRef]);
  const data = React.useMemo(() => reviewList, [reviewList]);

  const fun = (selectedRows) => {
    setIds(selectedRows);
  };

  const handleAction = async (value, review, hide) => {
    let myObj = {
      rating: review.rating,
      reviewDescription: review.reviewDescription,
      orderId: review.orderId._id,
      title: review.title,
      vendorId: review?.vendorId?.id,
      status: value,
      reviewSource: review.reviewSource,
    };
    const res = await ApiClient.put(
      getAllReviewList + review.id,
      myObj,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      hide();
      toast.success(res.data.message);
      getAllReviewListData(
        10,
        "",
        1,
        localStorage.getItem("customerReviewStatus")
      );
      // getAllReviewListData();
    }
  };
  return (
    <>
      <SectionTitle title="" subtitle="Customer Review" />
      <div className="p-2 bg-white border rounded-md">
        <div className="flex justify-between flex-wrap flex_box_1">
          {/* <div className="flex p-3 ">
            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="Aggregated"
              filter={setStatus}
              val={"1"}
              setIsAggregated={setIsAggregated}
              isAggregated={isAggregated}
            />
          </div> */}
          <div className="flex p-3 customer_review_btn_box">
            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="All"
              filter={() => {
                getAllReviewListData(10, "", 1, "");
              }}
              val={""}
              setIsAggregated={setIsAggregated}
              isAggregated={isAggregated}
            />
            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="Approved"
              filter={() => {
                getAllReviewListData(10, "", 1, 1);
              }}
              val={"1"}
              setIsAggregated={setIsAggregated}
              isAggregated={isAggregated}
            />
            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="Pending"
              filter={() => {
                getAllReviewListData(10, "", 1, 0);
              }}
              val={"0"}
              setIsAggregated={setIsAggregated}
              isAggregated={isAggregated}
            />
            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="Rejected"
              filter={() => {
                getAllReviewListData(10, "", 1, 2);
              }}
              val={"2"}
              setIsAggregated={setIsAggregated}
              isAggregated={isAggregated}
            />
            {/* <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="Deleted"
              filter={setStatus}
              val={"-1"}
              setIsAggregated={setIsAggregated}
              isAggregated={isAggregated}
            /> */}
            <div className="flex border-2 rounded-md hover:border-blue-500 w-full mr-3 review_input_box">
              <svg
                aria-hidden="true"
                className="flex justify-self-center self-center focus:w-5 h-5 text-gray-500 dark:text-gray-400 ml-2 text-grey-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 35 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
              <input
                type="text"
                placeholder="Search By Review Description"
                className="w-auto"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              ></input>
            </div>
          </div>
        </div>

        <Simple
          getAllReviewListData={getAllReviewListData}
          setIds={setIds}
          getPageData={getPageData}
          columns={columns}
          data={data}
          fun={fun}
          paginationObject={paginationObject}
          Loading={Loading}
          hiddenCol={hiddenCol}
        />

        {/* {JSON.stringify(requestReview)} */}
        <AuditModal
          title={"Change Reviews"}
          setOpen={setOpen}
          open={open}
          data={requestReview}
          allData={AllData}
          Loading={Loading}
          getAllReviewListData={getAllReviewListData}
          setIds={setIds}
        />

        <ModalReviewDescription
          title={"Review Description"}
          body={selectedReviewDesc}
          open={openModalReviewDesc}
          setOpen={setOpenModalReviewDesc}
        />
      </div>
    </>
  );
};
export default Index;
