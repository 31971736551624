/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import Portal from "../portal";
import { FiX } from "react-icons/fi";
import { fetcher } from "../../utils/common";
import { toast } from "react-toastify";

const ModalUpdateCourse = ({
  open,
  setOpen,
  adminToken,
  getCourses,
  selectedData,
}) => {
  const { palettes } = useSelector(
    (state) => ({
      palettes: state.palettes,
    }),
    shallowEqual
  );
  let { background } = {
    ...palettes,
  };

  const modalRef = useRef(null);
  const hide = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false;
      if (!open || modalRef.current.contains(event.target)) {
        return false;
      }
      setOpen(!open);
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, modalRef]);

  const initialCourseData = {
    courseName: "",
    tags: [],
    title: "",
    slug: "",
    video_link: "",
    description: "",
    sub_section_header: [],
  };
  const [courseData, setCourseData] = useState(initialCourseData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedData) {
      setCourseData(selectedData);
    }
  }, [selectedData]);

  const handleSave = async () => {
    setLoading(true);
    const result = await fetcher(
      "post",
      `admin/video-course/${selectedData.id}`,
      adminToken,
      JSON.stringify(courseData)
    );
    if (result.code == 200) {
      setOpen(false);
      setLoading(false);
      toast.success("Course updated successfully");
      return getCourses();
    }
    toast.error("Error occured please try again");
  };

  const handleChangeCourse = (e) => {
    const params = { ...courseData };
    params.courseName = e.target.value;
    params.title = e.target.value;
    params.slug = e.target.value.replace(/\s+/g, "-").toLowerCase();
    setCourseData(params);
  };

  return (
    <>
      {open && (
        <Portal selector="#portal">
          <div className="modal-backdrop fade-in"></div>
          <div
            className={`modal show ${background === "dark" ? "dark-mode" : ""}`}
            data-background={background}
          >
            <div className="relative min-w-sm w-auto mx-auto" ref={modalRef}>
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="text-xl font-semibold">Update Course</h3>
                  <button
                    className="modal-close btn btn-transparent"
                    onClick={hide}
                  >
                    <FiX size={18} className="stroke-current" />
                  </button>
                </div>
                <div className="relative py-4 px-6 flex-auto">
                  <div className="flex flex-col w-96">
                    <label className="mb-1">Course Name</label>
                    <input
                      value={courseData?.title}
                      onChange={handleChangeCourse}
                      type="text"
                      className="border border-gray-300 rounded-md p-2"
                      placeholder="Course Name"
                    />
                  </div>
                  <div className="flex flex-col w-96 mt-4">
                    <label className="mb-1">Video Link</label>
                    <input
                      value={courseData?.video_link}
                      onChange={(e) => {
                        setCourseData({
                          ...courseData,
                          video_link: e.target.value,
                        });
                      }}
                      type="text"
                      className="border border-gray-300 rounded-md p-2"
                      placeholder="Video Link"
                    />
                  </div>
                </div>
                <div className="modal-footer space-x-2">
                  {loading ? (
                    <div className="spinner">Loading...</div>
                  ) : (
                    <>
                      <button
                        className="btn btn-default btn-rounded bg-white hover:bg-grey-200 border border-grey-200"
                        type="button"
                        onClick={hide}
                      >
                        Close
                      </button>
                      <button
                        className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                        type="button"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default ModalUpdateCourse;
