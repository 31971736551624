/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { useSelector, shallowEqual } from "react-redux";
import Portal from "../portal";
import { FiX } from "react-icons/fi";
import moment from "moment";

const ModalRequestEdit = ({ title, body = [], open, setOpen }) => {
  const { palettes } = useSelector(
    (state) => ({
      palettes: state.palettes,
    }),
    shallowEqual
  );
  let { background } = {
    ...palettes,
  };

  const modalRef = useRef(null);
  const hide = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false;
      if (!open || modalRef.current.contains(event.target)) {
        return false;
      }
      setOpen(!open);
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, modalRef]);

  return (
    <>
      {open && (
        <Portal selector="#portal">
          <div className="modal-backdrop fade-in"></div>
          <div
            className={`modal show ${background === "dark" ? "dark-mode" : ""}`}
            data-background={background}
          >
            <div
              className="relative min-w-sm w-auto mx-auto lg:max-w-5xl"
              ref={modalRef}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="text-xl font-semibold">{title}</h3>
                  <button
                    className="modal-close btn btn-transparent"
                    onClick={hide}
                  >
                    <FiX size={18} className="stroke-current" />
                  </button>
                </div>
                <div className="relative p-4 flex-auto">
                  {body.length > 0 &&
                    body.map((item, index) => (
                      <div
                        className={`${index === 0 ? "" : "mt-4"}`}
                        key={index}
                      >
                        <p className="font-semibold">
                          {item.requestedBy} Submitted on{" "}
                          {moment(item.commentedOn).format("DD/MM/YYYY")}
                        </p>
                        <p>{item.changeComment}</p>
                        {item.rejectReason !== "" && (
                          <p className="text-red-700">
                            Reject reason: {item.rejectReason}
                          </p>
                        )}
                      </div>
                    ))}
                  {/* <pre className="font-sans" style={{ fontWeight: "normal" }}>
                    {body}
                  </pre> */}
                </div>
                <div className="modal-footer space-x-2">
                  <button
                    className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                    type="button"
                    onClick={hide}
                  >
                    Close
                  </button>
                  {/* <button
                    className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                    type="button"
                    onClick={hide}
                  >
                    Save Changes
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default ModalRequestEdit;
