/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import SectionTitle from "../components/section-title";
import SubCategoriestable from "../components/sub-categories";
import Widget from "../components/widget";
import { toast } from "react-toastify";
import Modal2 from "../components/confirm-delete-modal/modal-2";
import { FiX } from "react-icons/fi";
import ModalActivate from "../components/confirm-activate-modal/modal-activate";
import ApiClient from "../apis/apiClient";
import AsyncSelect from "react-select/async";
import {
  API_GetAllSubCategory,
  API_DeleteSubCategoryById,
  API_UpdateSubCategoryById,
  API_GetAllCategory,
  API_MasterCategoryList
} from "../apis/constant";
import AddSubCategory from "../components/sub-categories/AddSubCategory";
import EditSubCategory from "../components/sub-categories/subCategoryEditModal";
import { Redirect } from "react-router-dom";
import { checkAccess } from "../utils/common";


const Simple = () => {
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [Loading, setIsLoading] = useState(false);
  const [categryList, setCategoryList] = useState([]);
  const [search, setSearch] = useState("");
  const [paginationObject, setPaginationObject] = useState({});
  const [selectedCategoryValue, setSelectedCategoryValue] = useState(null);
  const [selectedMasterCategoryValue, setSelectedMasterCategoryValue] = useState(null);
  const adminToken = localStorage.getItem("wed_admin_token");
  const searchRef = useRef(search);
  const selectedCategoryValueRef = useRef(selectedCategoryValue);
  const selectedMasterCategoryValueRef = useRef(selectedMasterCategoryValue);

  useEffect(() => {
    selectedCategoryValueRef.current = selectedCategoryValue;
    selectedMasterCategoryValueRef.current = selectedMasterCategoryValue
    searchRef.current = search;
    if (search.length > 2 || search.length === 0) {
      getAllsubCategory(10, 1);
    }
  }, [
    search,
    selectedMasterCategoryValue,
    selectedCategoryValue,
  ]);

  useEffect(() => {
    getCategoryListUsingMasterId("");
  }, [
    selectedMasterCategoryValue,
  ]);

  const getAllsubCategory = async (limit, pageNumber) => {
    const data = "";
    setIsLoading(true);
    // setIsApiCall(true);
    let base_uri = API_GetAllSubCategory + `?page=${pageNumber}&sortBy=asc&limit=${limit}&searchBy=${searchRef.current}`;

    if (selectedCategoryValueRef.current !== null) {
      base_uri = base_uri + `&categoryId=${selectedCategoryValueRef.current.id}`;
    }
    if (selectedMasterCategoryValueRef.current !== null) {
      base_uri = base_uri + `&masterCategoryId=${selectedMasterCategoryValueRef.current.id}`;
    }
    const res = await ApiClient.get(
      base_uri,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      if (res.data.data && res.data.data.docs.length !== 0) {
        for (let i = 0; i < res.data.data.docs.length; i++) {
          let element = res.data.data.docs[i];
          res.data.data.docs[i]["displayStatus"] =
            res.data.data.docs[i].status === 0 ? (
              <label>Inactive</label>
            ) : (
              <label>Active</label>
            );
          res.data.data.docs[i]["displayMasterCategory"] =
            element.masterCategoryId !== null
              ? element.masterCategoryId.name
              : "NA";
          res.data.data.docs[i]["displayCategories"] =
            element.categoryId !== null ? element.categoryId.name : "NA";
        }
      }
      setPaginationObject({
        limit: limit,
        page: pageNumber,
        pages: res.data.data.pages,
        total: res.data.data.total,
      });
      // setStatusChange()
      setSubCategoryData(res.data.data.docs);
      setIsLoading(false);
      // setIsApiCall(false);
    }
  };

  const getCategoryListUsingMasterId = async (inputValue) => {
    const res = await ApiClient.get(
      API_GetAllCategory + `?masterCategoryId=${selectedMasterCategoryValueRef?.current?.id ?? ""}&page=1&sortBy=asc&limit=${99999}&searchBy=${inputValue}&isFor=dropdown`, {},
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      let full_arr = res.data.data.docs;
      setCategoryList(full_arr);
      return full_arr;
    }
  };

  const handleAMasterCategory = async (e) => {
    setCategoryList([]);
    setSelectedMasterCategoryValue(e);
  };

  const MasterCategoryList = async (inputValue) => {
    const data = "";
    const res = await ApiClient.get(
      API_MasterCategoryList + `?page=1&sortBy=asc&limit=${99999}&searchBy=${inputValue}&isFor=dropdown`, data,
      `Bearer ${adminToken}`
    );

    if (res.data.code === 200) {
      return res.data.data.docs;
    }
  };

  const handleACategory = async (e) => {
    setSelectedCategoryValue(e);
  };

  const deleteSubCategories = async (subcategoryData) => {
    const id = subcategoryData.original.id;
    const data = "";
    const res = await ApiClient.delete(
      API_DeleteSubCategoryById + id,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllsubCategory(10, 1);
      // window.location.reload();
    } else {
      toast.warning(res.data.message);
    }
  };

  const handleActive = async (status, data) => {
    const masData = data.masterCategoryId.id.toString();
    const casData = data.categoryId.id.toString();
    let myObj = {
      status: status,
      name: data.name,
      masterCategoryId: masData,
      categoryId: casData,
    };

    const res = await ApiClient.patch(
      API_UpdateSubCategoryById + "/" + data.id,
      myObj,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllsubCategory(10, 1);
    } else {
      toast.warning(res.data.message);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Level 1",
        accessor: "displayMasterCategory",
      },
      {
        Header: "Level 2",
        accessor: "displayCategories",
      },
      {
        Header: "Level 3",
        accessor: "name",
      },
      {
        Header: "Status",
        accessor: "displayStatus",
      },
      {
        Header: "Action",
        id: "delete",
        accessor: (str) => { },

        Cell: (Datatable) => (
          <>
            {checkAccess(7, 'edit') && <EditSubCategory
              id={Datatable.row.original.id}
              title={"Edit Sub-Category"}
              body={"body"}
              getApi={(a, c) => getAllsubCategory(a, c)}
            />}{" "}
            |{" "}
            {checkAccess(7, 'delete') && <Modal2
              title="Delete"
              icon={
                <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                  <FiX size={18} className="stroke-current text-red-500" />
                </span>
              }
              body={
                <div className="text-sm text-grey-500">
                  Are You Sure! You want to delete this Level 3?
                </div>
              }
              buttonTitle="Confirm"
              onClick={() => {
                deleteSubCategories(Datatable.row);
              }}
              buttonClassName="underline"
            />}{" "}
            |{" "}
            {Datatable.row.original.status === 0 ? (
              checkAccess(7, 'edit') && <ModalActivate
                title="Activate"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to Deactivate this Level 3?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  handleActive(1, Datatable.cell.row.original);
                }}
                buttonClassName="underline"
              />
            ) : (
              checkAccess(7, 'edit') && <ModalActivate
                title="Deactivate"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to Active this Level 3?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  handleActive(0, Datatable.cell.row.original);
                }}
                buttonClassName="underline"
              />
            )}
          </>
        ),
      },
    ],
    []
  );

  const getPageData = (data, type) => {
    if (type === "limit") {
      getAllsubCategory(data, 1);
    } else {
      getAllsubCategory(data, 1);
    }
  };

  const data = React.useMemo(() => subCategoryData, [subCategoryData]);
  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
    return (
      <>
        <div className="flex justify-between sub_cate_flex">
          <div className="flex flex_box_11">
            <div className="inline-block flex-col manage_product_box">
              <p style={{ margin: '0px 0px 8px 10px', fontWeight: "bold" }}>Search</p>
              <input
                className="searchCss sub_cate_input"
                type="text"
                placeholder="Search"
                value={search}
                // onChange={(e) => handleSearchData(e.target.value)}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="inline-block flex-col manage_product_box">
              <p className="filterCategoryCss">Level 1</p>
              <div className=" masterFilterCss ">
                <AsyncSelect
                  className="w-100"
                  cacheOptions
                  defaultOptions
                  isClearable
                  value={selectedMasterCategoryValue}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.id}
                  loadOptions={MasterCategoryList}
                  onChange={handleAMasterCategory}
                />
              </div>
            </div>
            <div className="inline-block flex-col manage_product_box">
              <p className="filterCategoryCss">Level 2</p>
              <div className="masterFilterCss ">
                <AsyncSelect
                  className="w-100"
                  key={categryList.length}
                  cacheOptions
                  isClearable
                  defaultOptions
                  value={selectedCategoryValue}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.id}
                  loadOptions={getCategoryListUsingMasterId}
                  // onI
                  onChange={handleACategory}
                />
              </div>
            </div>
          </div>
          <div>

            {checkAccess(7, 'add') && <AddSubCategory
              title={"Add Level 3"}
              body={"body"}
              getApi={getAllsubCategory}
            />}
          </div>
        </div>
        <SubCategoriestable
          columns={columns}
          data={data}
          loading={Loading}
          pagination={paginationObject}
          onSubmit={getPageData}
          showSearch={true}
          getAllsubCategory={getAllsubCategory}
        />
      </>
    );
  }
};

const Index = () => (
  <>
    <SectionTitle title="Manage Sub-Categories" subtitle="Sub-Categories" />

    <Widget>
      <Simple />
    </Widget>
  </>
);
export default Index;
