import React, { useEffect, useMemo } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useGlobalFilter,
} from "react-table";
import { PageWithText } from "../pagination";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import Loader from "../../components/custom-loader/loader";
import Pagination from "react-js-pagination";
import PageSizeDropdown from "../PageSizeDropdown";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <input
        type="checkbox"
        ref={resolvedRef}
        {...rest}
        className="form-checkbox h-4 w-4"
      />
    );
  }
);

const VendorPageData = ({
  hiddenCol,
  columns,
  setIds,
  data,
  loading,
  pagination,
  onSubmit,
  getAllVendorProduct,
  // hiddenCol
}) => {
  // const [ids, setIds] = useState([]);
  const tableColumns =
    useMemo(() => {

      return columns.filter((column) => column.show === true);
    }, [columns])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns: tableColumns,
      data,
      loading,
      initialState: { pageIndex: 0, pageSize: 10, hiddenColumns: [...hiddenCol] },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",

          Header: ({ getToggleAllRowsSelectedProps }) => (
            <>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </>
          ),

          Cell: ({ row }) => (
            <>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </>
          ),
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    let allIds = selectedFlatRows.map((item) => item.original.id);
    setIds(allIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowIds]);

  const handlePageData = (e) => {
    onSubmit(e);
  };
  const handlePageChange = (pageNumber) => {
    const page = { ...pagination, page: pageNumber };
    getAllVendorProduct(page, "", "");
  };

  return (
    <>
      <div className="table-responsive">
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="flex flex-row items-center justify-start">
                      <span>{column.render("Header")}</span>
                      {/* Add a sort direction indicator */}
                      <span className="ml-auto">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FiChevronDown className="stroke-current text-2xs" />
                          ) : (
                            <FiChevronUp className="stroke-current text-2xs" />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {loading ? (
              <tr>
                <td colSpan={10}>
                  <Loader />
                </td>
              </tr>
            ) : page.length !== 0 ? (
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10} style={{ textAlign: "center" }}>
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {!loading && page.length > 0 && (
        <div className="flex flex-row items-center justify-between my-4 flex-wrap">
          <Pagination
            color="primary"
            activePage={parseInt(pagination.page)}
            itemsCountPerPage={pagination.limit}
            totalItemsCount={pagination.total}
            pageRangeDisplayed={5}
            onChange={(e) => handlePageChange(e)}
          />
          <div className="flex flex-wrap items-center justify-start space-x-2 pagination">
            {pageIndex !== 0 && (
              <PageWithText onClick={() => gotoPage(0)}>First</PageWithText>
            )}
            {canPreviousPage && (
              <PageWithText onClick={() => previousPage()}>
                Previous
              </PageWithText>
            )}
            {canNextPage && (
              <PageWithText onClick={() => nextPage()} disabled={!canNextPage}>
                Next
              </PageWithText>
            )}
            {pageIndex !== pageCount - 1 && (
              <PageWithText
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                Last
              </PageWithText>
            )}
          </div>

          <select
            className="form-select text-sm bg-white dark:bg-grey-800 dark:border-grey-800 outline-none shadow-none focus:shadow-none"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              handlePageData(e.target.value);
            }}
          >
            <PageSizeDropdown />
          </select>
        </div>
      )}
    </>
  );
};

export default VendorPageData;
