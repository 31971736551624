import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";

import "../discounts/discount.css";

import { toast } from "react-toastify";
import moment from "moment";
import axios from "axios";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import ApiClient from "../../apis/apiClient";
import API from "../../utils/createApi";
import {
  API_GetAllUsers,
  API_GetAllVendors,
  getAllListOfDiscounts,
  url,
} from "../../apis/constant";
import { getCategoriesAll } from "../../actions";
import { insertSelectedCategory } from "../../reducers/categories";
import MegaMenu from "../megaMenu";
import DateInput from "../salesVoucher/DateInput";

function EditDiscounts() {
  const dispatch = useDispatch();
  const { selectedCategory } = useSelector((state) => state.categories);

  const [discountName, setDiscountName] = useState("");
  const [discountCondition, setDiscountCondition] = useState("contains");
  const [discountCode, setDiscountCode] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [discountType, setDiscountType] = useState("FIXED");
  const [discountApplyTo, setDiscountApplyTo] = useState("");
  const [maxCap, setMaxCap] = useState(0);
  const [minimumRequirement, setMinimumRequirement] = useState(null);
  const [minimumPurchaseAmount, setMinimumPurchaseAmount] = useState(0);
  const [customerEligibility, setCustomerEligibility] = useState("");
  const [usageLimitPerCustomer, setUsageLimitPerCustomer] = useState(null);
  const [numberUsageLimitPerCustomer, setNumberUsageLimitPerCustomer] =
    useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [vendor, setVendor] = useState(null);
  const [vendorProduct, setVendorProduct] = useState([]);
  const [selectedVendorProduct, setSelectedVendorProduct] = useState([]);
  const [productFamilies, setProductFamilies] = useState([]);
  const [selectedProductFamilies, setSelectProductFamilies] = useState([]);
  const [openCategory, setOpenCategory] = useState(false);
  const [specificCustomer, setSpecificCustomer] = useState([]);
  const [selectedSpecificCustomer, setSelectedSpecificCustomer] = useState([]);
  const [
    selectedSpecificCustomerSegments,
    setSelectedSpecificCustomerSegments,
  ] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isNoEndDate, setIsNoEndDate] = useState(false);

  let history = useHistory();
  const { id } = useParams();

  const adminToken = localStorage.getItem("wed_admin_token");

  const specificCustomerSegment = [
    {
      label: "Customer not yet purchased",
      value: "Customer not yet purchased",
    },
    // {
    //   label: "Vip Customers - Top 10% Sales",
    //   value: "Vip Customers - Top 10% Sales",
    // },
    {
      label: "Customers with only 1 purchase",
      value: "Customers with only 1 purchase",
    },
  ];

  useEffect(() => {
    getSalesDiscountById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllvendors = async (search = "") => {
    const data = "";

    const res = await ApiClient.get(
      API_GetAllVendors +
      `?page=${1}&sortBy=asc&limit=${20}&searchBy=${search}&status=1`, // page number and status issue
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      return res.data.data.docs;
    }
  };

  const handleVendorList = async (e) => {
    if (e === null) {
      setVendor("");
    } else {
      setVendor(e);
    }
    setSpecificCustomer([]);
    setSelectedSpecificCustomer([]);
  };

  useEffect(() => {
    if (customerEligibility === "specificCustomer") {
      getAllUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerEligibility, vendor]);

  useEffect(() => {
    if (discountApplyTo === "specificProduct") {
      getAllVendorProductList();
    }

    if (discountApplyTo === "productType") {
      getVendorProductFamily();
    }

    if (discountApplyTo === "productCategory") {
      dispatch(
        getCategoriesAll({
          vendorId: vendor?.id || "",
          onSuccess: () => { },
          onFailed: () => { },
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendor, discountApplyTo]);

  const getAllUsers = async () => {
    try {
      const res = await ApiClient.get(
        API_GetAllUsers +
        `?page=${1}&sortBy=asc&limit=${9999}&searchBy=&vendorId=${vendor?.id ?? ""
        }`,
        null,
        `Bearer ${adminToken}`
      );
      if (res.data.code === 200) {
        setSpecificCustomer(
          res.data.data.docs.map((data) => ({
            value: data.id ?? data._id,
            label: data.email,
          }))
        );
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  };

  const getVendorProductFamily = async () => {
    const res = await API.get(`/product-family?vendorId=${vendor?.id || ""}`, {
      headers: {
        Accept: "application/json",
        Authorization: "Basic d2VkZGluZ3NlcnY6d2VkZGluZ3NlcnZAMTIzNDU=",
      },
    });

    if (res.data.code === 200) {
      const productFamilies = (res?.data?.data || []).map((data) => ({
        value: data,
        label: data,
      }));
      setProductFamilies(productFamilies);
    }
  };

  const getAllVendorProductList = async () => {
    const res = await API.get(`/product?vendorId=${vendor?.id || ""}`, {
      headers: {
        Accept: "application/json",
        Authorization: "Basic d2VkZGluZ3NlcnY6d2VkZGluZ3NlcnZAMTIzNDU=",
      },
    });

    if (res.data.code === 200) {
      const products = (res?.data?.data || []).map((data) => ({
        value: data.product[0],
        label: data.name,
      }));
      setVendorProduct(products);
      return products;
    }
  };

  // setup data to form
  const getSalesDiscountById = async () => {
    axios
      .get(url + getAllListOfDiscounts + "/" + id, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then(async (res) => {
        if (res.data.code === 200) {
          setDiscountName(res.data.data.discountName);
          setDiscountCondition(res.data.data.discountCode);
          setDiscountCode(res.data.data.code);
          setDiscountType(res.data.data.type);
          setDiscountValue(res.data.data.discountValue);
          setDiscountApplyTo(res.data.data.discountApplies.to);
          setMaxCap(res.data.data.maxCap);
          setVendor(res.data.data.vendorId);
          switch (res.data.data.discountApplies.to) {
            case "productCategory":
              dispatch(
                insertSelectedCategory(
                  res.data.data.discountApplies.productCategory
                )
              );
              break;
            case "productType":
              setSelectProductFamilies(
                res.data.data.discountApplies.productFamily.map((data) => ({
                  value: data,
                  label: data,
                }))
              );
              break;
            case "specificProduct":
              const products = await getAllVendorProductList();
              const selectedProduct = products.filter((product) =>
                res.data.data.discountApplies.specificProduct.includes(
                  product.value
                )
              );
              setSelectedVendorProduct(selectedProduct);
              break;
            default:
              break;
          }

          if (res.data.data.minimumRequirementPurchaseAmount === 0) {
            setMinimumRequirement("none");
          } else {
            setMinimumRequirement("purchaseAmount");
            setMinimumPurchaseAmount(
              res.data.data.minimumRequirementPurchaseAmount
            );
          }

          let startDate = "";
          let startTime = "";
          let endDate = "";
          let endTime = "";
          if (res.data.data.startDateTime) {
            startTime = parseDateTimeIntoHoursAndMinutes(
              res.data.data.startDateTime
            );
            startDate = moment(res.data.data.startDateTime).format(
              "YYYY-MM-DD"
            );
          }
          if (res.data.data.endDateTime) {
            endTime = parseDateTimeIntoHoursAndMinutes(
              res.data.data.endDateTime
            );
            endDate = moment(res.data.data.endDateTime).format("YYYY-MM-DD");
          } else {
            setIsNoEndDate(true);
          }

          setStartDate(startDate);
          setStartTime(startTime);
          setEndDate(endDate);
          setEndTime(endTime);

          if (res.data.data.usageLimitsPerCustomer < 0) {
            setUsageLimitPerCustomer("Unlimited");
          } else {
            setUsageLimitPerCustomer("SpecifyNumberOfUsage");
          }
          setNumberUsageLimitPerCustomer(res.data.data.usageLimitsPerCustomer);
          setCustomerEligibility(res.data.data.customerEligibility.to);
          if (res.data.data.customerEligibility.to === "specificCustomer") {
            setSelectedSpecificCustomer(
              res.data.data.customerEligibility.specificCustomer
            );
          } else if (
            res.data.data.customerEligibility.to === "specificCustomerSegments"
          ) {
            setSelectedSpecificCustomerSegments(
              res.data.data.customerEligibility.specificCustomerSegments
            );
          }
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmit(false);

    if (!isNoEndDate) {
      if (endDate === "") {
        toast.error("End Date may not be blank");
        return;
      }
      if (endTime === "") {
        toast.error("End Time may not be blank");
        return;
      }
      if (endDate && moment(startDate).isAfter(endDate)) {
        toast.error("End Date may not be earlier than Start Date!");
        return;
      }
    }
    let payload = {
      ...(vendor?.id ? { vendorId: vendor?.id } : { vendorId: null }),
      discountName: discountName,
      discountCode: discountCondition, // conditions
      code: discountCode, // discountCode,
      maxCap: maxCap,
      type: discountType,
      discountValue: discountValue,
      discountOrderLevel: discountApplyTo === "allProducts" ? true : false,
      minimumRequirementPurchaseAmount:
        minimumRequirement === "purchaseAmount" ? minimumPurchaseAmount : 0,
      discountApplies: {
        to: "allProducts",
        productCategory: {},
        productFamily: [],
        specificProduct: [],
      },
      customerEligibility: {
        to: "everyOne",
        specificCustomer: [],
        specificCustomerSegments: [],
      },
      usageLimitsPerCustomer:
        usageLimitPerCustomer === "Unlimited"
          ? -1
          : numberUsageLimitPerCustomer,
      startDateTime: "",
      endDateTime: "",
    };

    if (startDate && startTime) {
      payload.startDateTime = new Date(
        moment(startDate).format("DD-MMM-YYYY") + " " + startTime
      ).toISOString();
    }
    if (!isNoEndDate) {
      const tempDate = moment(endDate).format("DD-MMM-YYYY");
      if (endDate && endTime) {
        payload.endDateTime = new Date(tempDate + " " + endTime).toISOString();
      } else if (endDate) {
        payload.endDateTime = new Date(tempDate).toISOString();
      }
    }

    if (discountApplyTo === "productCategory") {
      payload["discountApplies"] = {
        to: "productCategory",
        productCategory: selectedCategory,
        productFamily: [],
        specificProduct: [],
      };
    } else if (discountApplyTo === "productType") {
      payload["discountApplies"] = {
        to: "productType",
        productCategory: {},
        productFamily: selectedProductFamilies
          ? selectedProductFamilies.map((productFamily) => productFamily.value)
          : [],
        specificProduct: [],
      };
    } else if (discountApplyTo === "specificProduct") {
      payload["discountApplies"] = {
        to: "specificProduct",
        productCategory: {},
        productFamily: [],
        specificProduct: selectedVendorProduct.map((product) => product.value),
      };
    }

    if (customerEligibility === "specificCustomer") {
      payload["customerEligibility"] = {
        to: "specificCustomer",
        specificCustomer: selectedSpecificCustomer,
        specificCustomerSegments: [],
      };
    } else if (customerEligibility === "specificCustomerSegments") {
      payload["customerEligibility"] = {
        to: "specificCustomerSegments",
        specificCustomer: [],
        specificCustomerSegments: selectedSpecificCustomerSegments,
      };
    }

    const error = validatePayload(payload);
    if (error) {
      toast.error(error);
      setIsSubmit(false);
      return;
    }

    const res = await ApiClient.patch(
      getAllListOfDiscounts + "/" + id,
      payload,
      `Bearer ${adminToken}`
    );

    if (res.data.code === 200) {
      toast.success("Discount has been successfully updated");

      history.push("/sales/discount");
    } else if (res.data.code === 400) {
      toast.error("Discount can not be updated");
      setIsSubmit(false);
    }
  };

  return (
    <div>
      <div className="w-full shadow-xl px-5 py-5 mt-10">
        <div className="flex items-center justify-between cursor-pointer">
          <p className="text-xl">Update Discount Code</p>
        </div>
        <div className="" style={{ float: "right" }}>
          <Link
            to={"/sales/discount"}
            className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
          >
            Back
          </Link>
        </div>
        <form>
          <div className="mt-10 animate-fade-in-down">
            <div className="flex space-x-2 mb-5">
              <div className="w-full flex items-center justify-center custom-flex">
                <div className="w-full">
                  <p className="mb-0">Vendor ID</p>
                  <AsyncSelect
                    className="w-100"
                    cacheOptions
                    defaultOptions
                    isClearable
                    value={vendor}
                    getOptionLabel={(e) => e.vendorId}
                    getOptionValue={(e) => e.id}
                    loadOptions={getAllvendors}
                    onChange={handleVendorList}
                  />
                </div>
              </div>
              <div className="w-full flex items-center justify-center custom-flex">
                <div className="w-full">
                  <p className="mb-0">Vendor Name</p>
                  <AsyncSelect
                    className="w-100"
                    cacheOptions
                    defaultOptions
                    isClearable
                    value={vendor}
                    getOptionLabel={(e) => e.vendorName}
                    getOptionValue={(e) => e.id}
                    loadOptions={getAllvendors}
                    onChange={handleVendorList}
                  />
                </div>
              </div>
            </div>
            <div className="flex space-x-2">
              <div className="w-full flex items-center justify-center custom-flex">
                <div className="w-full mr-1">
                  <p className="mb-2">Discount Name</p>
                  <input
                    type="text"
                    required=""
                    className="inputFields"
                    value={discountName}
                    onChange={(e) => setDiscountName(e.target.value)}
                  />
                </div>

                <div className="mr-1 w-full relative">
                  <p className="mb-2">Conditions</p>
                  <select
                    className="inputFields"
                    onChange={(e) => setDiscountCondition(e.target.value)}
                    value={discountCondition}
                  >
                    <option value="contains">...contains</option>
                    <option value="endWith">...ends with</option>
                    <option value="beginsWith">...begins with</option>
                    <option value="=">=</option>
                  </select>
                </div>

                <div className="mr-1 w-full relative">
                  <p className="mb-2">Discount Code</p>
                  <input
                    type="text"
                    className="inputFields"
                    value={discountCode}
                    onChange={(e) => setDiscountCode(e.target.value)}
                  />
                </div>

                <div className="w-full mr-1 space-y-2">
                  <p>Type</p>
                  <select
                    className="inputFields"
                    onChange={(e) => setDiscountType(e.target.value)}
                    value={discountType}
                  >
                    <option value="PERCENT">Percentage %</option>
                    <option value="FIXED">Fixed Amount</option>
                  </select>
                </div>

                {discountType === "PERCENT" ? (
                  <div className="w-full mr-1 space-y-2">
                    <p>Discount Value</p>
                    <select
                      className="inputFields"
                      onChange={(e) => setDiscountValue(e.target.value)}
                      value={discountValue}
                    >
                      <option value="0">0</option>
                      {new Array(100).fill().map((_, i) => (
                        <option value={i + 1}>{i + 1} %</option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <div className="w-full space-y-2">
                    <p>Discount Value</p>
                    <div className="w-full flex">
                      <input
                        type="number"
                        className="inputFields"
                        onChange={(e) => setDiscountValue(e.target.value)}
                        value={discountValue}
                      />
                    </div>
                  </div>
                )}

                <div className="mr-1 w-full relative ml-1">
                  <p className="mb-2">Maxed Cap</p>
                  <input
                    type="number"
                    required=""
                    className="inputFields"
                    value={maxCap}
                    onChange={(e) => setMaxCap(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="flex space-x-2 mt-5">
              <div className="w-full space-y-2 relative">
                <p>Discount Applies to:</p>
                <div className="flex items-start space-x-5 custom-flex">
                  <div className="flex items-center mb-2 radio-box">
                    <input
                      type="radio"
                      name="appliesTo"
                      id="radio1"
                      className="w-5 h-5 mr-2"
                      value="allProducts"
                      onClick={(e) => setDiscountApplyTo(e.target.value)}
                      checked={discountApplyTo === "allProducts"}
                    />
                    <label className="text-sm" htmlFor="radio1">
                      All Products
                    </label>
                  </div>

                  <div className="flex flex-col items-start justify-end">
                    <div className=" mb-2 radio-box">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          name="appliesTo"
                          id="radio2"
                          className="w-5 h-5 mr-2"
                          value="productCategory"
                          checked={discountApplyTo === "productCategory"}
                          onClick={(e) => setDiscountApplyTo(e.target.value)}
                        />
                        <label className="text-sm" htmlFor="radio2">
                          Specific Product Category
                        </label>
                      </div>
                      {discountApplyTo === "productCategory" && (
                        <div className="space-y-2 w-full">
                          <div
                            onClick={() => setOpenCategory(!openCategory)}
                            className="w-full outline-none border  rounded-sm cursor-pointer inputFields"
                          >
                            <p>
                              {selectedCategory
                                ? selectedCategoryText(selectedCategory)
                                : "Select Category"}
                            </p>
                          </div>
                          {openCategory && (
                            <div className="absolute top-[-10px] w-[270px] max-h-50vh left-0 z-20">
                              <MegaMenu
                                addPy=""
                                onClose={() => setOpenCategory(false)}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-col items-start justify-end relative">
                    <div className=" mb-2 radio-box">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          name="appliesTo"
                          id="radio3"
                          className="w-5 h-5 mr-2"
                          value="productType"
                          checked={discountApplyTo === "productType"}
                          onClick={(e) => setDiscountApplyTo(e.target.value)}
                        />
                        <label className="text-sm" htmlFor="radio3">
                          Product Family
                        </label>
                      </div>
                      {discountApplyTo === "productType" && (
                        <div className="mr-1 w-full relative">
                          <Select
                            options={productFamilies}
                            name="productFamily"
                            value={selectedProductFamilies}
                            onChange={(value) =>
                              setSelectProductFamilies(value)
                            }
                            isMulti={true}
                            placeholder="Select multiple..."
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-col items-start justify-end relative">
                    <div className="mb-2 radio-box">
                      <div className="flex items-center">
                        {discountApplyTo === "specificProduct" ? (
                          <input
                            type="radio"
                            name="appliesTo"
                            id="radio4"
                            className="w-5 h-5 mr-2"
                            value="specificProduct"
                            defaultChecked
                            onClick={(e) => setDiscountApplyTo(e.target.value)}
                          />
                        ) : (
                          <input
                            type="radio"
                            name="appliesTo"
                            id="radio4"
                            className="w-5 h-5 mr-2"
                            value="specificProduct"
                            onClick={(e) => setDiscountApplyTo(e.target.value)}
                          />
                        )}
                        <label className="text-sm" htmlFor="radio4">
                          Specific Product
                        </label>
                      </div>

                      {discountApplyTo === "specificProduct" && (
                        <div className="mr-1 w-full relative">
                          <Select
                            options={vendorProduct}
                            name="specificProduct"
                            value={selectedVendorProduct}
                            onChange={(value) =>
                              setSelectedVendorProduct(value)
                            }
                            isMulti={true}
                            placeholder="Select multiple..."
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex space-x-2 mt-5">
              <div className="w-full space-y-2">
                <p>Minimum Requirements:</p>
                <div className="flex items-start space-x-5 custom-flex">
                  <div className="flex items-center mb-2 radio-box">
                    <input
                      type="radio"
                      name="minReq"
                      id="radioMinReq1"
                      className="w-5 h-5 mr-2"
                      value="none"
                      checked={minimumRequirement === "none"}
                      onChange={(e) => setMinimumRequirement(e.target.value)}
                    />
                    <label className="text-sm" htmlFor="radioMinReq1">
                      None
                    </label>
                  </div>

                  <div className="flex flex-col items-start justify-end">
                    <div className="mb-2 radio-box">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          name="minReq"
                          id="radioMinReq2"
                          className="w-5 h-5 mr-2"
                          value="purchaseAmount"
                          checked={minimumRequirement === "purchaseAmount"}
                          onChange={(e) =>
                            setMinimumRequirement(e.target.value)
                          }
                        />
                        <label className="text-sm" htmlFor="radioMinReq2">
                          Minimum Purchase Amount
                        </label>
                      </div>

                      {minimumRequirement === "purchaseAmount" && (
                        <div className="w-full flex">
                          <input
                            type="number"
                            className="inputFields"
                            value={minimumPurchaseAmount}
                            onChange={(e) =>
                              setMinimumPurchaseAmount(e.target.value)
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex space-x-2 mt-5">
              <div className="w-full">
                <p className="mb-2">Customer Eligibility</p>
                <div className="flex items-start space-x-5 custom-flex">
                  <div className="flex items-center radio-box mb-2">
                    <input
                      type="radio"
                      name="elegibility"
                      id="elegibility1"
                      className="w-5 h-5 mr-2"
                      value="everyOne"
                      checked={customerEligibility === "everyOne"}
                      onChange={(e) => setCustomerEligibility(e.target.value)}
                    />
                    <label className="text-sm" htmlFor="elegibility1">
                      Everyone
                    </label>
                  </div>

                  <div className="flex flex-col items-start justify-end relative">
                    <div className="flex items-center mb-2 radio-box">
                      <input
                        type="radio"
                        name="elegibility"
                        id="elegibility2"
                        className="w-5 h-5 mr-2"
                        value="specificCustomer"
                        checked={customerEligibility === "specificCustomer"}
                        onChange={(e) => setCustomerEligibility(e.target.value)}
                      />
                      <label className="text-sm" htmlFor="elegibility2">
                        Specific Customer
                      </label>
                    </div>
                    {customerEligibility === "specificCustomer" && (
                      <Select
                        options={specificCustomer}
                        className="w-full specificCustomer_select"
                        name="specificCustomer"
                        value={selectedSpecificCustomer}
                        onChange={(value) => setSelectedSpecificCustomer(value)}
                        isMulti={true}
                        placeholder="Select multiple..."
                      />
                    )}
                  </div>

                  <div className="flex flex-col items-start justify-end relative">
                    <div className="flex items-center mb-2 radio-box">
                      <input
                        type="radio"
                        name="elegibility"
                        id="elegibility3"
                        className="w-5 h-5 mr-2"
                        value="specificCustomerSegments"
                        checked={
                          customerEligibility === "specificCustomerSegments"
                        }
                        onChange={(e) => setCustomerEligibility(e.target.value)}
                      />
                      <label className="text-sm" htmlFor="elegibility3">
                        Specific Customer Segments
                      </label>
                    </div>

                    {customerEligibility === "specificCustomerSegments" && (
                      <Select
                        options={specificCustomerSegment}
                        className="w-full"
                        name="specificCustomerSegments"
                        value={selectedSpecificCustomerSegments}
                        onChange={(value) =>
                          setSelectedSpecificCustomerSegments(value)
                        }
                        isMulti={true}
                        placeholder="Select multiple..."
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex space-x-2 mt-5">
              <div className="space-y-2">
                <p className="mb-2">Usage Limits Per Customer:</p>
                <div className="flex items-start flex-col">
                  <div>
                    <div className="mr-1 w-full relative">
                      <select
                        className="inputFields"
                        onChange={(e) => {
                          if (e.target.value === "Unlimited") {
                            setNumberUsageLimitPerCustomer(-1);
                          } else {
                            setNumberUsageLimitPerCustomer(1);
                          }
                          setUsageLimitPerCustomer(e.target.value)
                        }}
                        value={usageLimitPerCustomer}
                      >
                        <option value="Unlimited">Unlimited</option>
                        <option value="SpecifyNumberOfUsage">
                          Specify number of usage
                        </option>
                      </select>
                    </div>
                  </div>
                  {usageLimitPerCustomer === "SpecifyNumberOfUsage" && (
                    <div className="w-full space-y-2">
                      <div className="w-full flex">
                        <input
                          type="number"
                          className="inputFields"
                          value={numberUsageLimitPerCustomer}
                          onChange={(e) =>
                            setNumberUsageLimitPerCustomer(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex space-x-2 mt-5">
              <div className="w-full space-y-2">
                <p className="mb-2">Active Dates</p>
                <div className="flex items-start space-x-5">
                  <div className="w-full flex space-x-1 custom-flex">
                    <div className="w-full">
                      <p className="text-sm">Start Date</p>
                      <DateInput
                        className="inputFields"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                    <div className="w-full">
                      <p className="text-sm">Start Time</p>
                      <DateInput
                        type="time"
                        className="inputFields"
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                      />
                    </div>

                    <div className="w-full">
                      <p className="text-sm">End Date</p>
                      <DateInput
                        className="inputFields"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        disabled={isNoEndDate}
                      />
                    </div>
                    <div className="w-full">
                      <p className="text-sm">End Time</p>
                      <DateInput
                        type="time"
                        className="inputFields"
                        value={endTime}
                        disabled={isNoEndDate}
                        onChange={(e) => setEndTime(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-start items-center">
              <label htmlFor="checkboxNoEndDate">No End Date</label>
              <input
                type="checkbox"
                name="appliesTo"
                id="checkboxNoEndDate"
                className="w-4 h-4 ml-2"
                checked={isNoEndDate}
                onChange={() => {
                  setEndDate("");
                  setEndTime("");
                  setIsNoEndDate(!isNoEndDate);
                }}
              />
            </div>
          </div>

          <div className="w-full flex justify-end">
            {isSubmit ? (
              <button className="flex items-center px-5 py-2 bg-blue-500 hover:bg-blue-600 rounded-sm text-white mt-3">
                Loading...
              </button>
            ) : (
              <button
                className="flex items-center px-5 py-2 bg-blue-500 hover:bg-blue-600 rounded-sm text-white mt-3"
                onClick={(e) => handleSubmit(e)}
              >
                Save Discount
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditDiscounts;

const parseDateTimeIntoHoursAndMinutes = (dateTime) => {
  if (!dateTime) return "";
  const expiryDateTime = new Date(dateTime);
  let hours = expiryDateTime.getHours();
  if (hours.toString().length === 1) {
    hours = "0" + hours;
  }
  let minutes = expiryDateTime.getMinutes();
  if (minutes.toString().length === 1) {
    minutes = "0" + minutes;
  }
  return hours + ":" + minutes;
};

const selectedCategoryText = (data) => {
  if (!data?.name) return "Select Category";
  let text = "";
  text = data.name;
  data?.categories?.name && (text += ` > ${data.categories.name}`);
  data?.categories?.["sub-categories"]?.name && (text += ` > ${data.categories["sub-categories"].name}`);
  data?.categories?.["sub-categories"]?.["sub-sub-categories"]?.name &&
    (text += ` > ${data.categories["sub-categories"]["sub-sub-categories"].name}`);
  return text;
};

/**
 * A function to validate the payload for creating a discount.
 * @param {object} payload - The payload for the discount.
 * @property {string} payload.discountName - The name of the discount.
 * @property {string} payload.discountCode - The code of the discount.
 * @property {string} payload.code - Another code property of the discount.
 * @property {string} payload.type - The type of the discount.
 * @property {number} payload.discountValue - The value of the discount.
 * @property {object} payload.discountApplies - The object specifying to whom the discount applies.
 * @property {number} payload.minimumRequirementPurchaseAmount - The minimum purchase amount for the discount to apply.
 * @property {object} payload.customerEligibility - The object specifying the eligibility of customers for the discount.
 * @property {number} payload.usageLimitsPerCustomer - The maximum usage limit per customer.
 * @property {string} payload.startDateTime - The start date and time of the discount.
 * @returns {string|undefined} - Returns a string describing the validation error, or undefined if there are no errors.
 * @throws Will not throw an error, but will return a string with a validation message if an error is found.
 */
const validatePayload = (payload) => {
  if (!payload.discountName) return "Discount name is required";
  if (!payload.discountCode) return "Discount code is required";
  if (!payload.code) return "Discount code is required";
  if (!payload.type) return "Discount type is required";
  if (!payload.discountValue) return "Discount value is required";

  if (
    payload.discountApplies.to === "productCategory" &&
    isObjectEmpty(payload.discountApplies.productCategory)
  )
    return "Product category is required";
  if (
    payload.discountApplies.to === "productType" &&
    payload.discountApplies.productFamily.length === 0
  )
    return "Product family is required";
  if (
    payload.discountApplies.to === "specificProduct" &&
    payload.discountApplies.specificProduct.length === 0
  )
    return "Specific product is required";

  if (
    payload.customerEligibility.to === "specificCustomer" &&
    !payload.customerEligibility.specificCustomer.length
  )
    return "Specific customer is required";
  if (
    payload.customerEligibility.to === "specificCustomerSegments" &&
    !payload.customerEligibility.specificCustomerSegments.length
  )
    return "Specific customer segments is required";

  if (+payload.usageLimitsPerCustomer === 0)
    return "Usage limits per customer is required";
  if (payload.startDateTime === "") return "Start date time is required";
};

/**
 * Function to check if the given object is empty
 *
 * @function isObjectEmpty
 *
 * @param {Object} obj - The object that needs to be checked
 *
 * @returns {Boolean} - Returns true if the object is empty, false otherwise
 *
 * @throws Will throw an error if the parameter passed is not an object
 *
 * @example
 * // returns false
 * isObjectEmpty({name: 'John', age: 30});
 *
 * @example
 * // returns true
 * isObjectEmpty({});
 *
 */
const isObjectEmpty = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    throw new Error("Invalid argument: the parameter must be an object");
  }

  return Object.keys(obj).length === 0;
};
