import React, { useState, useEffect, useRef } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useGlobalFilter,
} from "react-table";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import Loader from "../../components/custom-loader/loader";
import Modal2 from "../../components/confirm-delete-modal/modal-2";
import ModalActivate from "../../components/confirm-activate-modal/modal-activate";
import { FiX } from "react-icons/fi";
import ApiClient from "../../apis/apiClient";
import {
  API_DeleteMultiSubUser,
  API_ActDeactSubuser,
} from "../../apis/constant";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import PageSizeDropdown from "../PageSizeDropdown";

const SubUserstable = ({
  columns,
  data,
  loading,
  onSubmit,
  pagination,
  getAllsubUsers,
}) => {
  const [ids, setIds] = useState([]);
  const [hidden, setHidden] = useState(true);
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setPageSize,
    selectedFlatRows,
    state: { pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      loading,
      initialState: { pageIndex: 0, pageSize: 10, selectedRowIds: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => [
    //     // Let's make a column for selection
    //     {
    //       id: "selection",
    //       // The header can use the table's getToggleAllRowsSelectedProps method
    //       // to render a checkbox
    //       Header: ({ getToggleAllRowsSelectedProps }) => (
    //         <>
    //           <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
    //         </>
    //       ),
    //       // The cell can use the individual row's getToggleRowSelectedProps method
    //       // to the render a checkbox
    //       Cell: ({ row }) => (
    //         <>
    //           <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //         </>
    //       ),
    //     },
    //     ...columns,
    //   ]);
    // }
  );

  useEffect(() => {
    let allIds = selectedFlatRows.map((item) => item.original.id);
    setIds(allIds);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowIds]);

  const adminToken = localStorage.getItem("wed_admin_token");

  const deleteSubUser = async () => {
    const id = ids;
    const data = {
      ids: id,
    };
    const res = await ApiClient.post(
      API_DeleteMultiSubUser,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllsubUsers(10, "", "", 1);
    } else {
      toast.warning(res.data.message);
    }
  };

  const actDeactivateSubuser = async (status) => {
    const id = ids;
    const data = {
      ids: id,
      status: status,
    };
    const res = await ApiClient.post(
      API_ActDeactSubuser,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllsubUsers(10, "", "", 1);
    } else {
      toast.warning(res.data.message);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        hidden ||
        buttonRef.current.contains(event.target) ||
        dropdownRef.current.contains(event.target)
      ) {
        return false;
      }
      setHidden(!hidden);
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hidden, dropdownRef, buttonRef]);

  const handlePageData = (e) => {
    onSubmit(e, "limit");
  };

  const handlePageChange = (pageNumber) => {
    getAllsubUsers(pagination.limit, "", "", pageNumber);
  };

  // Render the UI for your table
  return (
    <>
      <div className="relative">
        {/* <button
          ref={buttonRef}
          onClick={handleDropdownClick}
          className="btn btn-default btn-icon bg-transparent h-16"
        >
          <span className="mr-2">Bulk Action</span>
          <FiChevronDown className="stroke-current" />
        </button> */}
        <div
          ref={dropdownRef}
          className={`dropdown absolute top-0 left-0 dropdown-box ${
            hidden ? "" : "open"
          }`}
        >
          <div
            className="dropdown-content bottom-start multiselect-dropdown"
            style={{ width: "100px" }}
          >
            <Modal2
              title="Delete"
              icon={
                <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                  <FiX size={18} className="stroke-current text-red-500" />
                </span>
              }
              body={
                <div className="text-sm text-grey-500">
                  Are You Sure! You want to delete these subUser?
                </div>
              }
              buttonTitle="Confirm"
              onClick={(e) => {
                deleteSubUser();
              }}
              buttonClassName="btn w-20 mt-2 bg-red-500 hover:bg-red-600 text-white"
            />
            <ModalActivate
              title="Active"
              icon={
                <span className="h-10 w-10 bg-blue-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                  <FiX size={18} className="stroke-current text-red-500" />
                </span>
              }
              body={
                <div className="text-sm text-grey-500">
                  Are You Sure! You want to activate these subUser?
                </div>
              }
              buttonTitle="Confirm"
              onClick={(e) => actDeactivateSubuser("activate")}
              buttonClassName="btn w-20 mt-2 bg-blue-500 hover:bg-red-600 text-white"
            />
            <ModalActivate
              title="Deactive"
              icon={
                <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                  <FiX size={18} className="stroke-current text-red-500" />
                </span>
              }
              body={
                <div className="text-sm text-grey-500">
                  Are You Sure! You want to deactivate these subUser?
                </div>
              }
              buttonTitle="Confirm"
              onClick={(e) => actDeactivateSubuser("deactivate")}
              buttonClassName="btn w-20 mt-2 bg-red-500 hover:bg-red-600 text-white"
            />
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="flex flex-row items-center justify-start">
                      <span>{column.render("Header")}</span>
                      {/* Add a sort direction indicator */}
                      <span className="ml-auto">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FiChevronDown className="stroke-current text-2xs" />
                          ) : (
                            <FiChevronUp className="stroke-current text-2xs" />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {loading ? (
              <tr>
                <td colSpan={10}>
                  <Loader />
                </td>
              </tr>
            ) : page.length !== 0 ? (
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10} style={{ textAlign: "center" }}>
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {!loading && page.length > 0 && (
        <div className="flex flex-row items-center justify-between my-4 flex-wrap">
          <Pagination
            color="primary"
            activePage={pagination.page}
            itemsCountPerPage={pagination.limit}
            totalItemsCount={pagination.total}
            pageRangeDisplayed={5}
            onChange={(e) => handlePageChange(e)}
          />
          <select
            className="form-select text-sm bg-white dark:bg-grey-800 dark:border-grey-800 outline-none shadow-none focus:shadow-none"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              handlePageData(e.target.value);
            }}
          >
            <PageSizeDropdown />
          </select>
        </div>
      )}
    </>
  );
};

export default SubUserstable;
