import React, {useState} from 'react'
import Validation from '../forms/validation'
import Alert from '../alerts'

import ApiClient from './../../apis/apiClient'
import { API_Forgot } from "../../apis/constant"



const ForgotPassword = ({message = null}) => {
  // const [data, onSubmit] = useState(null)
  const [errorColor, setErrorColor] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const onSubmit = (data) => {
    setErrorMsg('');
  let Forgetdata = {
    email: data?.email,
  };
    ApiClient.post(API_Forgot, Forgetdata, "Basic Z29ndWFyZDpnb2d1YXJkQDEyMzQ1").then((res) => {
      if(res.data.code === 200) {
        setErrorColor("bg-transparent border-green-500 text-green-500")
        setErrorMsg(res.data.message)
        document.getElementById('email').value = ''

      } else {
        setErrorColor("bg-transparent border-red-500 text-red-500")   
        setErrorMsg(res.data.message) 
      }
    })
    
  }

  let items = [
    {
      label: 'Email',
      error: {required: 'Please enter a valid email'},
      id: 'email',
      name: 'email',
      type: 'email',
      placeholder: 'Enter you email',
      
    },
  ]

  return (
    <>
      <div className="flex flex-col">
        { errorMsg && (
          <div className="w-full mb-4">
            <Alert
              color= {errorColor}
              borderLeft
              raised>
              {errorMsg}
            </Alert>
          </div>
        )}
        <Validation items={items} onSubmit={onSubmit} />
      </div>
    </>
  )
}

export default ForgotPassword
