/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import SectionTitle from "../components/section-title";
import Widget from "../components/widget";
import { Redirect } from "react-router-dom";
import "nprogress/nprogress.css";
import "../css/custome.css";
import { checkAccess, fetcher } from "../utils/common";
import ModalAddCourse from "../components/modals/modalAddCourse";
import TableCourse from "../components/Courses/table";
import ModalUpdateCourse from "../components/modals/modalUpdateCourse";
import ModalSectionCourse from "../components/Courses/modalSectionCourse";

const Simple = () => {
  const adminToken = localStorage.getItem("wed_admin_token");
  const [modalAddCourse, setModalAddCourse] = useState(false);
  const [modalUpdateCourse, setModalUpdateCourse] = useState(false);
  const [modalSection, setModalSection] = useState(false);
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);

  const getCourses = async () => {
    const result = await fetcher("get", `admin/video-course`, adminToken);
    setData(result.data);
  };

  useEffect(() => {
    getCourses();
  }, []);

  const editAction = async (data) => {
    setSelectedData(data);
    setModalUpdateCourse(true);
  };

  const handleDelete = async (data) => {
    const result = await fetcher(
      "delete",
      `admin/video-course`,
      adminToken,
      JSON.stringify({ id: data.id })
    );
    if (result.code == 200) {
      getCourses();
    }
  };

  const handleOpenModalSection = (e) => {
    setSelectedData(e);
    setModalSection(true);
  };

  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className="flex flex-col">
        <div>
          {checkAccess(10, "add") && (
            <button
              onClick={() => setModalAddCourse(true)}
              style={{ top: "10px" }}
              className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded addFilterCss"
            >
              Add Course
            </button>
          )}
        </div>

        <div className="p-3">
          <TableCourse
            data={data}
            editAction={editAction}
            handleDelete={handleDelete}
            setModalSection={handleOpenModalSection}
          />
        </div>

        {selectedData && (
          <ModalSectionCourse
            open={modalSection}
            setOpen={setModalSection}
            adminToken={adminToken}
            getCourses={getCourses}
            selectedData={selectedData}
          />
        )}

        <ModalAddCourse
          open={modalAddCourse}
          setOpen={setModalAddCourse}
          adminToken={adminToken}
          getCourses={getCourses}
        />
        {selectedData && (
          <ModalUpdateCourse
            open={modalUpdateCourse}
            setOpen={setModalUpdateCourse}
            adminToken={adminToken}
            getCourses={getCourses}
            selectedData={selectedData}
          />
        )}
      </div>
    );
  }
};

const Courses = () => (
  <>
    <SectionTitle title="Manage Courses" subtitle="Courses" />
    <Widget>
      <Simple />
    </Widget>
  </>
);
export default Courses;
