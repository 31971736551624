import React, { useState } from "react";
import Validation from "../forms/changePassword";
import Alert from "../alerts";
import { API_ChangePassword, url } from "../../apis/constant";
import { toast } from "react-toastify";
import axios from "axios";

const SecuritySettings = ({ message = null }) => {
  const adminToken = localStorage.getItem("wed_admin_token");

  const handleChange = (value, id) => {
    var result = [...listItem];
    result = result.map((x) => {
      if (x.id === id) x.value = value;
      return x;
    });
    setListItem(result);
  };

  const handlePassword = (data) => {
    if (data.newPassword !== data.confirmPassword) {
      toast.error("New password and confirm password did not match");
      return;
    }
    let logData = {
      oldPassword: data?.currentPassword,
      password: data?.newPassword,
    };
    axios
      .post(url + API_ChangePassword + "/", logData, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })

      .then((res) => {
        if (res.data.code === 200) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const [data] = useState(null);
  let items = [
    {
      label: "Current password",
      error: {
        required: "Current password is required",
        minLength: {
          value: 4,
          message: "Your password should have at least 4 characters",
        },
        maxLength: {
          value: 20,
          message: "Your password should have no more than 20 characters",
        },
      },
      value: "",
      id: "currentPassword",
      name: "currentPassword",
      type: "password",
      inputType: "login",

      placeholder: "Enter your current password",
    },
    {
      label: "New password",
      error: {
        required: "New password is required",
        minLength: {
          value: 4,
          message: "Your password should have at least 4 characters",
        },
        maxLength: {
          value: 20,
          message: "Your password should have no more than 20 characters",
        },
      },
      value: "",
      id: "newPassword",
      name: "newPassword",
      inputType: "login",

      type: "password",
      placeholder: "Enter your new password",
    },
    {
      label: "Confirm new password",
      error: {
        required: "Password confirmation is required",
        minLength: {
          value: 4,
          message: "Your password should have at least 4 characters",
        },
        maxLength: {
          value: 20,
          message: "Your password should have no more than 20 characters",
        },
      },
      value: "",
      id: "confirmPassword",
      name: "confirmPassword",
      type: "password",
      inputType: "login",

      placeholder: "Enter your new password confirmation",
    },
  ];
  const [listItem, setListItem] = useState(items);
  return (
    <>
      <div className="flex flex-col">
        {data && message && (
          <div className="w-full mb-4">
            <Alert
              color="bg-transparent border-green-500 text-green-500"
              borderLeft
              raised
            >
              {message}
            </Alert>
          </div>
        )}
        <Validation
          items={listItem}
          onSubmit={handlePassword}
          onChange={handleChange}
        />
      </div>
    </>
  );
};

export default SecuritySettings;
