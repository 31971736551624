
import { CSVLink } from "react-csv";
import { csvDataFormatter } from '../utils/constants'

const CsvDowloader = ({ filename = 'list', columns = [], data = [] }) => {
    return (
        <CSVLink
            className="bg-white hover:bg-grey-200 py-2 px-4 mr-2 rounded shadow-sm"
            filename={filename + '.csv'}
            data={csvDataFormatter(columns, data)}
        >
            Export to CSV
        </CSVLink>
    )
}

export default CsvDowloader