/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SectionTitle from "../components/section-title";
import TicketListTable from "../components/ticketList";
import Widget from "../components/widget";
import { toast } from "react-toastify";
// import Modal2 from "../components/confirm-delete-modal/modal-2";
import { FiX } from "react-icons/fi";
import ModalActivate from "../components/confirm-activate-modal/modal-activate";
import ApiClient from "../apis/apiClient";
import { getAllCustomerTicketList, APIUpdateStatus } from "../apis/constant";
import moment from "moment";

import { Link, Redirect } from "react-router-dom";
import EditTicketNotes from "../components/ticketList/editNotes";
import { useParams, useHistory } from "react-router-dom";

const FILE_URL = process.env.REACT_APP_FILE_URL;

const Simple = () => {
  const { ticketNumber } = useParams();
  const history = useHistory();
  const [ticketList, setTicketList] = useState([]);
  const [all, setAll] = useState(true);
  const [Loading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [paginationObject, setPaginationObject] = useState({});
  const adminToken = localStorage.getItem("wed_admin_token");
  const [insertNotes, setInsertNotes] = useState({
    id: "",
    notes: "",
  });
  const [openModalNotes, setOpenModalNotes] = useState(false);

  const getAllTicketListData = async (
    limit = 10,
    searchq = "",
    pageNumber = 1
  ) => {
    const data = "";
    setIsLoading(true);
    const res = await ApiClient.get(
      getAllCustomerTicketList +
      `?page=${pageNumber}&sortBy=asc&limit=${limit}&searchBy=${searchq}${all ? "&status=1" : "&status=0"
      }`,

      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      let no =
        res.data.data.total - (res.data.data.page - 1) * res.data.data.limit;
      for (let i = 0; i < res.data.data.docs.length; i++) {
        res.data.data.docs[i].no = no--;
      }
      if (res.data.data && res.data.data.docs.length !== 0) {
        for (let i = 0; i < res.data.data.docs.length; i++) {
          let element = res.data.data.docs[i];
          element["status"] =
            element.status === 0 ? (
              <label className="">Close</label>
            ) : (
              <label className="">Open</label>
            );
        }
      }
      setPaginationObject({
        limit: res.data.data.limit,
        page: res.data.data.page,
        pages: res.data.data.pages,
        total: res.data.data.total,
      });
      setTicketList(res.data.data.docs);
      setIsLoading(false);
      // setIsApiCall(false);
    }
  };

  useEffect(() => {
    if (ticketNumber) {
      getAllTicketListData(10, ticketNumber, 1);
    } else if (all) {
      setSearch("");
      getAllTicketListData(10, "");
    } else if (search.length > 2 || search.length === 0) {
      getAllTicketListData(10, search, 1);
    }
  }, [all, search, ticketNumber]);

  const handleActive = async (status, data) => {
    let myObj = {
      status: status,
    };
    const res = await ApiClient.patch(
      APIUpdateStatus + data.id,
      myObj,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      if (status === 0) {
        toast.success("Ticket closed successfully!");
      } else {
        toast.success("Ticket open successfully!");
      }
      getAllTicketListData(10, "", 1);
    } else {
      toast.error("Ticket update failed");
    }
  };

  const handleNotes = async () => {
    let myObj = {
      notes: insertNotes.notes,
    };
    const res = await ApiClient.patch(
      APIUpdateStatus + insertNotes.id,
      myObj,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success("Notes update successfully");
      getAllTicketListData(10, "", 1);
    } else {
      toast.warning("Can not update Notes");
    }
    setOpenModalNotes(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "No",
        accessor: "no",
      },
      {
        Header: "Ticket Number",
        accessor: "ticketNumber",
      },
      {
        Header: "Topic",
        accessor: "topic",
      },
      {
        Header: "Order ID",
        accessor: "orderNumber",
      },
      {
        Header: " First Name",
        accessor: "firstName",
      },

      {
        Header: " Last Name",
        accessor: "lastName",
      },
      {
        Header: "Email Id",
        accessor: "email",
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
      },
      {
        Header: "Date Time Submitted",
        accessor: (str) => { },
        Cell: (Datatable) => (
          <>
            {moment(Datatable.row.original.createdAt).format(
              "DD-MMM-YYYY HH:mm:ss"
            )}
          </>
        ),
      },
      {
        Header: "Message",
        accessor: "message",
      },
      {
        Header: "File",
        accessor: (str) => { },
        Cell: (Datatable) => (
          <>
            {Datatable.row.original.file?.[0] &&
              Object.keys(Datatable.row.original.file?.[0]).length !== 0 ? (
              <a
                href={`${FILE_URL}${Datatable.row.original.file?.[0]}`}
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                Open File
              </a>
            ) : (
              <p>No File</p>
            )}
          </>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Action",
        id: "delete",
        accessor: (str) => { },

        Cell: (Datatable) => (
          <>
            <div className="flex items-center space-x-2">
              {Datatable.row.original?.userId ?
                <Link
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  to={`/ticketchat/${Datatable.row.original.id}${Datatable.row.original.status.props.children === "Close" ? "?status=Archived" : ""}`}
                >
                  Open Chat
                </Link>
                : null}

              {/* <Modal2
              title="Delete"
              icon={
                <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                  <FiX size={18} className="stroke-current text-red-500" />
                </span>
              }
              body={
                <div className="text-sm text-grey-500">
                  Are You Sure! You want to delete this Ticket?
                </div>
              }
              buttonTitle="Confirm"
              onClick={() => {
                deleteTicket(Datatable.row);
              }}
              buttonClassName="underline"
            />{" "} */}
              {Datatable.row.original.status.props.children === "Close" ? (
                <ModalActivate
                  title="Open"
                  icon={
                    <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                      <FiX size={18} className="stroke-current text-red-500" />
                    </span>
                  }
                  body={
                    <div className="text-sm text-black">
                      Are You Sure! You want to open this ticket?
                    </div>
                  }
                  buttonTitle="Confirm"
                  onClick={() => {
                    handleActive(1, Datatable.cell.row.original);
                  }}
                  buttonClassName="underline"
                  confirmBtnClass="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                />
              ) : (
                <ModalActivate
                  title="Close"
                  icon={
                    <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                      <FiX size={18} className="stroke-current text-red-500" />
                    </span>
                  }
                  body={
                    <div className="text-sm text-black">
                      Are You Sure! You want to close the ticket?
                    </div>
                  }
                  buttonTitle="Confirm"
                  onClick={() => {
                    handleActive(0, Datatable.cell.row.original);
                  }}
                  buttonClassName="underline"
                  confirmBtnClass="btn btn-default btn-rounded bg-red-500 hover:bg-red-600 text-white"
                />
              )}
              <p
                onClick={() => {
                  // handleNotes(Datatable.cell.row.original);
                  setInsertNotes({
                    id: Datatable.cell.row.original.id,
                    notes: Datatable.cell.row.original?.notes || "",
                  });
                  setOpenModalNotes(true);
                }}
                className="underline cursor-pointer"
              >
                Notes
              </p>
            </div>
          </>
        ),
      },
    ],
    []
  );

  const getPageData = (data, type) => {
    if (type === "limit") {
      getAllTicketListData(data, "", 1);
    } else {
      getAllTicketListData(data, "", 1);
    }
  };

  const data = React.useMemo(() => ticketList, [ticketList]);

  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
    return (
      <>
        <EditTicketNotes
          open={openModalNotes}
          setOpen={setOpenModalNotes}
          insertNotes={insertNotes}
          setInsertNotes={setInsertNotes}
          handleNotes={handleNotes}
        />
        <div className="flex justify-between">
          <div className="flex gap-2 items-center">
            <input
              className="searchCss add_fliter_input"
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {ticketNumber &&
              <>
                <b>Showing only Ticket #{ticketNumber} </b>
                <button
                  onClick={() => history.push("/ticketList")}
                  className={`border-2 py-2 w-24 rounded-md bg-grey-300 hover:bg-grey-100`}
                >
                  Clear
                </button>
              </>
            }
          </div>
          <div className="flex space-x-2">
            <button
              onClick={() => setAll(true)}
              className={`border-2 py-2 w-24 rounded-md hover:bg-grey-100 ${all && "bg-grey-300"
                }`}
            >
              Active
            </button>
            <button
              onClick={() => setAll(false)}
              className={`border-2 py-2 w-24 rounded-md hover:bg-grey-100 ${!all && "bg-grey-300"
                }`}
            >
              Archive
            </button>
          </div>
          {/* <AddAttribute
          title={"Add Attribute "}
          body={"body"}
          getApi={getAllTicketListData}
        /> */}
        </div>
        <TicketListTable
          columns={columns}
          data={data}
          loading={Loading}
          pagination={paginationObject}
          onSubmit={getPageData}
          showSearch={true}
          getAllTicketListData={getAllTicketListData}
        />
      </>
    );
  }
};

const TicketList = () => (
  <>
    <SectionTitle title="Tables" subtitle="Ticket List" />

    <Widget>
      <Simple />
    </Widget>
  </>
);
export default TicketList;
