import React from "react";
import UserEdit from './userEditForm'
import Widget from '../widget'

const EditUserList = ({ vendors, selectedVendr, setVendors, setIsEditing }) => {

  return (
    <>
      <Widget
        description={<span>Edit User</span>}>
        <div className="w-full flex">
          <div className="w-full">
            <UserEdit />
          </div>
        </div>
      </Widget>
    </>
  );
};

export default EditUserList;
