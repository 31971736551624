/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import Portal from "../portal";
import { FiX } from "react-icons/fi";
import { fetcher } from "../../utils/common";
import { toast } from "react-toastify";
import TableSectionCourse from "./tableSection";
import ModalAddSectionCourse from "./modalAddSection";

const ModalSectionCourse = ({
  open,
  setOpen,
  adminToken,
  getCourses,
  selectedData,
}) => {
  const { palettes } = useSelector(
    (state) => ({
      palettes: state.palettes,
    }),
    shallowEqual
  );
  let { background } = {
    ...palettes,
  };
  const modalRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false;
      if (!open || modalRef.current.contains(event.target)) {
        return false;
      }
      setOpen(!open);
      setSectionLevel(2);
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, modalRef]);

  const [sectionLevel, setSectionLevel] = useState(2);
  const [modalUpdateSection, setModalUpdateSection] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemToEdit, setSelectedItemToEdit] = useState(null);
  const [selectedItemLvl4, setSelectedItemLvl4] = useState(null);
  const [selectedItemToEditLvl4, setSelectedItemToEditLvl4] = useState(null);
  const [isAdd, setIsAdd] = useState(false);
  const [selectedDataLocal, setSelectedDataLocal] = useState(null);

  useEffect(() => {
    setSelectedDataLocal(selectedData);
  }, [selectedData]);

  if (!selectedDataLocal) {
    return null;
  }

  const hide = () => {
    if (sectionLevel > 2) {
      setSectionLevel(sectionLevel - 1);
      if (sectionLevel == 4) {
        setSelectedItemLvl4(null);
      }
      if (sectionLevel == 3) {
        setSelectedItem(null);
      }
    } else {
      setOpen(false);
      getCourses();
      // setSelectedDataLocal([]);
    }
  };

  const handleAddSection = async (val) => {
    const _selectedData = { ...selectedDataLocal };
    if (sectionLevel == 2) {
      _selectedData.sub_section_header.push(val);
    } else if (sectionLevel == 3 && selectedItem) {
      _selectedData.sub_section_header
        .filter((item) => item.slug == selectedItem.slug)[0]
        .sub_section_header.push(val);
    } else if (sectionLevel == 4 && selectedItem && selectedItemLvl4) {
      _selectedData.sub_section_header
        .filter((item) => item.slug == selectedItem.slug)[0]
        .sub_section_header.filter(
          (item) => item.slug == selectedItemLvl4.slug
        )[0]
        .sub_section_header.push(val);
    }

    const result = await fetcher(
      "post",
      `admin/video-course/${selectedDataLocal.id}`,
      adminToken,
      JSON.stringify(_selectedData)
    );
    if (result.code == 200) {
      setSelectedDataLocal(_selectedData);
      // setSectionLevel(2);
      // setOpen(false);
      toast.success("Course updated successfully");
      setIsAdd(false);
      setModalUpdateSection(false);
      // hide();
      // return getCourses();
      return;
    }
    toast.error("Error occured please try again");
  };

  const handleUpdateSection = async (val) => {
    const _selectedData = { ...selectedDataLocal };
    if (sectionLevel == 2) {
      _selectedData.sub_section_header = _selectedData.sub_section_header.map(
        (item) => {
          if (item.slug == selectedItemToEdit.slug) {
            return val;
          }
          return item;
        }
      );
    } else if (sectionLevel == 3 && selectedItem) {
      _selectedData.sub_section_header.filter(
        (item) => item.slug == selectedItem.slug
      )[0].sub_section_header = _selectedData.sub_section_header
        .filter((item) => item.slug == selectedItem.slug)[0]
        .sub_section_header.map((item) => {
          if (item.slug == selectedItemToEdit.slug) {
            return val;
          }
          return item;
        });
    } else if (sectionLevel == 4 && selectedItem && selectedItemLvl4) {
      _selectedData.sub_section_header
        .filter((item) => item.slug == selectedItem.slug)[0]
        .sub_section_header.filter(
          (item) => item.slug == selectedItemLvl4.slug
        )[0].sub_section_header = _selectedData.sub_section_header
        .filter((item) => item.slug == selectedItem.slug)[0]
        .sub_section_header.filter(
          (item) => item.slug == selectedItemLvl4.slug
        )[0]
        .sub_section_header.map((item) => {
          if (item.slug == selectedItemToEditLvl4.slug) {
            return val;
          }
          return item;
        });
    }

    const result = await fetcher(
      "post",
      `admin/video-course/${selectedDataLocal.id}`,
      adminToken,
      JSON.stringify(_selectedData)
    );
    if (result.code == 200) {
      setSelectedDataLocal(_selectedData);
      // setSectionLevel(2);
      // setOpen(false);
      toast.success("Course updated successfully");
      setModalUpdateSection(false);
      // hide();
      // return getCourses();
      return;
    }
    toast.error("Error occured please try again");
  };

  const handleDeleteSection = async (val) => {
    const _selectedData = { ...selectedDataLocal };
    if (sectionLevel == 2) {
      _selectedData.sub_section_header =
        _selectedData.sub_section_header.filter(
          (item) => item.slug != val.slug
        );
    } else if (sectionLevel == 3 && selectedItem) {
      _selectedData.sub_section_header.filter(
        (item) => item.slug == selectedItem.slug
      )[0].sub_section_header = _selectedData.sub_section_header
        .filter((item) => item.slug == selectedItem.slug)[0]
        .sub_section_header.filter((item) => item.slug != val.slug);
    } else if (sectionLevel == 4 && selectedItem && selectedItemLvl4) {
      _selectedData.sub_section_header
        .filter((item) => item.slug == selectedItem.slug)[0]
        .sub_section_header.filter(
          (item) => item.slug == selectedItemLvl4.slug
        )[0].sub_section_header = _selectedData.sub_section_header
        .filter((item) => item.slug == selectedItem.slug)[0]
        .sub_section_header.filter(
          (item) => item.slug == selectedItemLvl4.slug
        )[0]
        .sub_section_header.filter((item) => item.slug != val.slug);
    }

    const result = await fetcher(
      "post",
      `admin/video-course/${selectedDataLocal.id}`,
      adminToken,
      JSON.stringify(_selectedData)
    );
    if (result.code == 200) {
      setSelectedDataLocal(_selectedData);
      // setSectionLevel(2);
      // setOpen(false);
      toast.success("Course updated successfully");
      setModalUpdateSection(false);
      // hide();
      // return getCourses();
      return;
    }
    toast.error("Error occured please try again");
  };

  const handleRenderData = () => {
    let data = [];
    if (sectionLevel == 2) {
      data = selectedDataLocal.sub_section_header;
    } else if (sectionLevel == 3 && selectedItem) {
      data = selectedDataLocal.sub_section_header.filter(
        (item) => item.slug == selectedItem.slug
      )[0].sub_section_header;
    } else if (sectionLevel == 4 && selectedItem && selectedItemLvl4) {
      data = selectedDataLocal.sub_section_header
        .filter((item) => item.slug == selectedItem.slug)[0]
        .sub_section_header.filter(
          (item) => item.slug == selectedItemLvl4.slug
        )[0].sub_section_header;
    }
    return data;
  };
  const data = handleRenderData();

  return (
    <>
      {open && (
        <Portal selector="#portal">
          <div className="modal-backdrop fade-in"></div>
          <div
            className={`modal show ${background === "dark" ? "dark-mode" : ""}`}
            data-background={background}
          >
            <div className="relative min-w-sm w-auto mx-auto" ref={modalRef}>
              {!modalUpdateSection ? (
                <div className="modal-content">
                  <div className="modal-header">
                    <h3 className="text-xl font-semibold">
                      {`Course Name: ${selectedDataLocal.title} (Section ${sectionLevel})`}
                    </h3>
                    <button
                      className="modal-close btn btn-transparent"
                      onClick={hide}
                    >
                      <FiX size={18} className="stroke-current" />
                    </button>
                  </div>
                  <div className="relative py-4 px-6 flex-auto flex flex-col">
                    <div className="mb-3">
                      <button
                        onClick={() => {
                          setModalUpdateSection(true);
                          setIsAdd(true);
                        }}
                        className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded float-right"
                      >
                        Add Section
                      </button>
                    </div>
                    <TableSectionCourse
                      data={data}
                      editAction={(val) => {
                        sectionLevel == 4
                          ? setSelectedItemToEditLvl4(val)
                          : setSelectedItemToEdit(val);
                        setModalUpdateSection(true);
                      }}
                      handleDelete={handleDeleteSection}
                      setModalSection={(val) => {
                        sectionLevel < 3
                          ? setSelectedItem(val)
                          : setSelectedItemLvl4(val);
                        setSectionLevel(sectionLevel + 1);
                      }}
                      sectionLevel={sectionLevel}
                    />
                  </div>
                  <div className="modal-footer space-x-2">
                    <button
                      className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                      type="button"
                      onClick={hide}
                    >
                      {sectionLevel > 2 ? "BACK" : "CLOSE"}
                    </button>
                  </div>
                </div>
              ) : (
                <ModalAddSectionCourse
                  setOpen={setModalUpdateSection}
                  handleAddSection={handleAddSection}
                  handleUpdateSection={handleUpdateSection}
                  level={sectionLevel}
                  selectedItem={
                    sectionLevel == 4
                      ? selectedItemToEditLvl4
                      : selectedItemToEdit
                  }
                  isAdd={isAdd}
                />
              )}
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default ModalSectionCourse;
