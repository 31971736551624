import React, { useEffect, useState, useRef } from "react";
import { BsChevronDown } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { updateVendorCompensationPlan } from '../../actions/vendor.action'
import { vendorFormAction } from "../../reducers/vendor.reducer";
import ApiClient from "../../apis/apiClient";
import {
  API_GetAllSubscriptionList,
} from "../../apis/constant";
import { toast } from "react-toastify";
const FormCompensationPlan = ({ isNew }) => {
  const adminToken = localStorage.getItem("wed_admin_token");
  const vendor = useSelector((state) => state.vendorSlice.vendor);
  const [subsrciption, setSubscrption] = useState([])
  const vendorForm = useSelector((state) => state.vendorSlice.vendorForm);
  const dispatch = useDispatch();
  const compensationDetails = useRef({
    commissionFixed: 0,
    commissionId: "",
    subscriptionPeriod: "",
    subscription: 0,
    subscriptionName: "",
    subscriptionId: "",
  })


  const getAllSubscriptionList = async (limit, search = "", pageNumber) => {
    const data = "";

    const res = await ApiClient.get(
      API_GetAllSubscriptionList +
      `?page=${pageNumber}&sortBy=asc&limit=${limit}&searchBy=${search}`,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {

      if (res.data.data.docs)

        if (res.data.data && res.data.data.docs.length !== 0) {
          for (let i = 0; i < res.data.data.docs.length; i++) {
            let element = res.data.data.docs[i];
            if (element.name === compensationDetails?.current?.commissionPlan) {
              compensationDetails.current = { ...compensationDetails.current, subscriptionPeriod: element.type, subscription: element.fee, subscriptionName: element.name, subscriptionId: element.id, commissionId: element.commissionId }
              element.discountPlan.forEach((item) => {
                if (item?.masterCategory === vendorForm?.sellerOffering?.services[0]?.id) {
                  compensationDetails.current = { ...compensationDetails.current, commissionPercentage: item.commission }
                }
              })
            }

          }
        }

      setSubscrption(res.data.data.docs);
    }
  };


  useEffect(() => {
    if (isNew) return;
    getAllSubscriptionList(20, '', 1);
    compensationDetails.current = {
      commissionPlan: vendorForm?.compensationPlan?.commissionPlan
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeForm = (title, value) => {
    let compensation = {
      [title]: value,
    }
    compensationDetails.current = { ...compensationDetails.current, ...compensation }
    dispatch(
      vendorFormAction({
        ...vendorForm,
        compensationPlan: {
          ...vendorForm.compensationPlan,
          ...compensation,
        },
      })
    );
    getAllSubscriptionList(20, '', 1);
  };

  const handleUpdate = () => {
    if( compensationDetails.current?.subscriptionId && compensationDetails.current?.commissionId && compensationDetails.current?.subscriptionId !== "" && compensationDetails.current?.commissionId !== "") {
      dispatch(
        updateVendorCompensationPlan({
          params: {
            id: vendor.id,
            compensationPlan: compensationDetails.current
          },
          onSuccess: () => {
            toast.success("Vendor Updated Successfully!")
            window.location.reload();
  
          },
          onFailed: () => { },
        })
      )
    } else {
      toast.error("Please Select Compensation Plan")
    }
  }


  return (
    <div className="md:mt-10 mt-5 animate-fade-in-down">
      <div className="md:flex md:space-x-2 md:space-y-0 space-y-3">
        <div className="w-full space-y-2">
          <p>Commission Plan</p>
          <select
            value={compensationDetails.current?.commissionPlan}
            onChange={(e) => {
              const sub = subsrciption.find(x => x.name === e.target.value);
              if (sub) {
                handleChangeForm("subscriptionId", sub.id);
                handleChangeForm("commissionId", sub.commissionId);
              }
              handleChangeForm("commissionPlan", e.target.value)
            }}
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          >
            <option value={""}>Select Plan</option>
            {subsrciption && subsrciption.map(item =>
              (<option value={item.name}>{item.name}</option>))}
          </select>
        </div>
        <div className="w-full space-y-2">
          <p>Commission %</p>
          <input
            disabled
            type="number"
            // onChange={(e) =>
            //   handleChangeForm("commissionPercentage", e.target.value)
            // }
            value={compensationDetails.current?.commissionPercentage}
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          />
        </div>
        <div className="w-full space-y-2">
          <p>Commission $</p>
          <input
            disabled
            // onChange={(e) =>
            //   handleChangeForm("commissionFixed", e.target.value)
            // }
            value={compensationDetails.current?.commissionFixed}
            type="number"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          />
        </div>
      </div>
      <div className="md:flex md:space-x-2 md:space-y-0 space-y-3 mt-5">
        <div className="w-full space-y-2">
          <p>Subscription S$</p>
          <input
            disabled
            type="number"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
            // onChange={(e) => handleChangeForm("subscription", e.target.value)}
            value={compensationDetails.current?.subscription}
          />
        </div>
        <div className="w-full space-y-2">
          <p>Subscription Name</p>
          <input
            disabled
            onChange={(e) => handleChangeForm("subscriptionName", e.target.value)}
            value={compensationDetails.current?.subscriptionName}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          />
        </div>
        <div className="w-full space-y-2">
          <p>Subscription Period</p>
          <select
            disabled
            // onChange={(e) => handleChangeForm("subscriptionPeriod", e.target.value)}
            value={compensationDetails.current?.subscriptionPeriod}
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          >
            <option value="Monthly">Monthly</option>
            <option value="Quarterly">Quarterly</option>
          </select>
        </div>
      </div>
      {!isNew && <button
        style={{ background: "#990000", marginLeft: '1000px' }}
        className={` py-2 px-4  mt-5 mr-2 rounded shadow-sm ${"text-white "} w-40`}
        onClick={() => handleUpdate()}
      >
        Update
      </button>}
      {/* <div className="md:flex md:space-x-2 md:space-y-0 space-y-3 mt-5">
        <div className="w-full space-y-2">
          <p>Advertising $$</p>
          <input
            type="number"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          />
        </div>
        <div className="w-full space-y-2">
          <p>Advertising %</p>
          <input
            type="number"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          />
        </div>
      </div> */}
    </div>
  );
};

const CompensationPlan = ({ isNew }) => {
  const [openForm, setOpenForm] = useState(false);

  return (
    <div className="w-full shadow-xl px-5 py-5 bg-white">
      <div
        onClick={() => setOpenForm(!openForm)}
        className="flex justify-between cursor-pointer"
      >
        <p className="text-xl">Compensation Plan</p>
        <BsChevronDown
          size={25}
          className={
            "text-bg-primary " +
            (openForm ? "rotate-180 transition-all" : "rotate-0 transition-all")
          }
        />
      </div>
      {openForm && <FormCompensationPlan isNew={isNew} />}
    </div>
  );
};

export default CompensationPlan;
