import React, { useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";

import PhonePicker from "../phone-picker/PhonePicker";
import { vendorFormAction } from "../../reducers/vendor.reducer";

const FormBusinessOwner = ({ isNew }) => {
  const vendorForm = useSelector((state) => state.vendorSlice.vendorForm);
  const dispatch = useDispatch();

  const handleChangeForm = (title, value) => {
    dispatch(
      vendorFormAction({
        ...vendorForm,
        businessOwner: {
          ...vendorForm.businessOwner,
          [title]: value,
        },
      })
    );
  };

  return (
    <div className="md:mt-10 mt-5 animate-fade-in-down">
      <div className="md:flex md:space-x-2 md:space-y-0 space-y-3">
        <div className="w-full space-y-2">
          <p>First Name</p>
          <input
            onChange={(e) => handleChangeForm("firstName", e.target.value)}
            value={vendorForm.businessOwner?.firstName}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          />
        </div>
        <div className="w-full space-y-2">
          <p>Last Name</p>
          <input
            onChange={(e) => handleChangeForm("lastName", e.target.value)}
            value={vendorForm.businessOwner?.lastName}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          />
        </div>
      </div>
      <div className="md:flex mt-5 md:space-x-2 md:space-y-0 space-y-5">
        <div className="w-full space-y-2">
          <p>Phone Number *</p>
          <PhonePicker
            phoneCode={vendorForm.businessOwner?.countryCode}
            phone={vendorForm.businessOwner?.phone}
            onSelect={(code) => handleChangeForm("countryCode", code)}
            onChangePhone={(value) => handleChangeForm("phone", value)}
          />
        </div>
        <div className="w-full space-y-2">
          <p>Email Address</p>
          <input
            onChange={(e) => handleChangeForm("email", e.target.value)}
            value={vendorForm.businessOwner?.email}
            type="email"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
          />
        </div>
      </div>
      <div className="flex space-x-2 mt-5">
        <div className="w-full flex items-center">
          <input
            checked={vendorForm.businessOwner.termsConditions}
            onChange={() =>
              handleChangeForm(
                "isPrivacyAndTerms",
                !vendorForm.businessOwner.termsConditions
              )
            }
            className="h-5 w-5 mt-3 mr-3"
            type="checkbox"
            id="flexCheckDefault"
          />
          <label
            htmlFor="flexCheckDefault"
            className="md:w-3/4 mt-3 flex cursor-pointer"
          >
            <p>
              {`I agree to WeddingServ's `}
              <span className="font-semibold hover:underline cursor-pointer">
                Privacy
              </span>
              <span> and </span>
              <span className="font-semibold  hover:underline cursor-pointer">
                Terms & Conditions
              </span>
            </p>
          </label>
        </div>
      </div>
    </div>
  );
};

const BusinessOwner = ({ isNew }) => {
  const [openForm, setOpenForm] = useState(false);

  return (
    <div className="w-full shadow-xl px-5 py-5 bg-white">
      <div
        onClick={() => setOpenForm(!openForm)}
        className="flex justify-between cursor-pointer"
      >
        <p className="text-xl">Business Owner</p>
        <BsChevronDown
          size={25}
          className={
            "text-bg-primary " +
            (openForm ? "rotate-180 transition-all" : "rotate-0 transition-all")
          }
        />
      </div>
      {openForm && <FormBusinessOwner isNew={isNew} />}
    </div>
  );
};

export default BusinessOwner;
