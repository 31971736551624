import React from "react";
import {
  // FiToggleLeft,
  FiList,
  FiActivity,
  // FiCalendar,
  // FiDroplet,
  FiGrid,
  // FiClock,
  // FiCopy,
  // FiUser,
  // FiPieChart,
  // FiMap,
  FiTarget,
  FiCompass,
  FiHelpCircle,
  // FiHome,
  FiFilter,
  FiUsers,
} from "react-icons/fi";


import { GiShop, GiSharkJaws } from "react-icons/gi";
import { BsCart3 } from "react-icons/bs";
const checkAccess = (moduleId, type) => {
  var data = localStorage.getItem("wed_admin_adata") && JSON.parse(localStorage.getItem("wed_admin_adata"))?.role;
  var loginDetails = localStorage.getItem("wed_admin_adata") && JSON.parse(localStorage.getItem("wed_admin_adata"));
  if (data && loginDetails.type !== 'superadmin') {
    for (let i = 0; i < data.resource.length; i++) {
      if (data.resource[i].moduleId === moduleId) {
        return data.resource[i].permissions.includes(type) ? true : false
      }
    }
  } else {
    return true;
  }
}
const initialState = [
  {
    title: "Applications",
    items: [
      {
        url: "/dashboard",
        icon: <FiCompass size={20} />,
        title: "Dashboard",
        items: [],
      },
      {
        url: "/",
        icon: <GiSharkJaws size={20} />,
        title: "Role & Permission",
        badge: {
          color: "bg-indigo-500 text-white",
          text: 6,
        },
        items: [
          {
            url: "/role/permission",
            title: "Role & Permission",
            items: [],
            hidden: checkAccess(1, 'list'),
          },
          {
            url: "/admin",
            title: "Manage Admin",
            hidden: checkAccess(2, 'list'),
            items: [],
          },
        ],
      },
      {
        url: "/",
        icon: <FiUsers size={20} />,
        title: "Customer",
        badge: {
          color: "bg-indigo-500 text-white",
          text: 6,
        },
        items: [
          {
            url: "/users",
            title: "Users",
            items: [],
            hidden: checkAccess(3, 'list'),
          },
          {
            url: "/users-address",
            title: "Users Address",
            items: [],
            hidden: checkAccess(14, 'list'),
          },
          {
            url: "/customer-review",
            title: "Customer Review",
            items: [],
            hidden: checkAccess(15, 'list'),
          },
        ],
      },
      {
        url: "/",
        icon: <GiShop size={20} />,
        title: "Manage Vendors",
        badge: {
          color: "bg-indigo-500 text-white",
          text: 6,
        },
        items: [
          {
            url: "/vendors",
            title: "Vendors",
            items: [],
            hidden: checkAccess(4, 'list'),
          },
          {
            url: "/payment-policy-list",
            title: "Vendor Payment Policy",
            items: [],
            hidden: checkAccess(16, 'list'),
          },
          {
            url: "/product",
            title: "Product",
            items: [],
            hidden: checkAccess(17, 'list'),
          },
          {
            url: "/commission",
            title: "Subscription",
            items: [],
            hidden: checkAccess(18, 'list'),
          },
          {
            url: "/vendor_Suscription",
            title: "Vendor Compensation",
            items: [],
            hidden: checkAccess(19, 'list'),
          },
          // {
          //   url: "/manage_product",
          //   title: "Vendor Product",
          //   items: [],
          // },
          {
            url: "/scheduling",
            title: "Scheduling",
            items: [],
            hidden: checkAccess(29, 'list'),
          },
        ],
      },
      {
        url: "/",
        icon: <FiList size={20} />,
        title: "Categories",
        badge: {
          color: "bg-indigo-500 text-white",
          text: 6,
        },
        items: [
          {
            url: "/mastercategory",
            title: "Level 1",
            items: [],
            hidden: checkAccess(5, 'list'),
          },
          {
            url: "/category",
            title: "Level 2",
            items: [],
            hidden: checkAccess(6, 'list'),
          },
          {
            url: "/sub-category",
            title: "Level 3",
            items: [],
            hidden: checkAccess(7, 'list'),
          },
          {
            url: "/product_type",
            title: "Level 4 (Product Type)",
            items: [],
            hidden: checkAccess(11, 'list'),
          },
        ],
      },
      {
        url: "/",
        icon: <BsCart3 size={20} />,
        title: "Order",
        items: [
          {
            url: "/orders",
            title: "Orders",
            items: [],
            hidden: checkAccess(20, 'list'),
          },
          {
            url: "/cancel-order",
            title: "Cancel Order",
            items: [],
            hidden: checkAccess(21, 'list'),
          },
          {
            url: "/anomaly-order",
            title: "Anomaly Order",
            items: [],
            hidden: checkAccess(20, 'list'),
          },
          // {
          //   url: "/invoice",
          //   title: "invoice",
          //   items: [],
          // },
        ],
      },
      {
        url: "/",
        icon: <GiShop size={20} />,
        title: "Manage Attributes",
        badge: {
          color: "bg-indigo-500 text-white",
          text: 6,
        },
        items: [
          {
            url: "/attributes",
            title: "Attributes",
            items: [],
            hidden: checkAccess(9, 'list'),
          },
        ],
      },
      {
        url: "/filters",
        icon: <FiFilter size={20} />,
        title: "Filters",
        badge: {
          color: "bg-indigo-500 text-white",
          text: 6,
        },
        hidden: checkAccess(10, 'list'),
        items: [],
      },
      // {
      //   url: "/product_type",
      //   icon: <FiShoppingCart size={20} />,
      //   title: "Product Type",
      //   badge: {
      //     color: "bg-indigo-500 text-white",
      //     text: 6,
      //   },
      //   items: [],
      // },

      {
        url: "/",
        icon: <FiGrid size={20} />,
        title: "Manage Faq",
        badge: {
          color: "bg-indigo-500 text-white",
          text: 6,
        },
        items: [
          {
            url: "/faq-details",
            title: "Faq",
            items: [],
            hidden: checkAccess(12, 'list'),
          },
        ],
      },

      // {
      //   url: "/manage_product",
      //   icon: <FiShoppingCart size={20} />,
      //   title: "Vendor Product",
      //   badge: {
      //     color: "bg-indigo-500 text-white",
      //     text: 6,
      //   },
      //   items: [],
      // },

      {
        url: "/",
        icon: <FiActivity size={20} />,
        title: "Static Content",
        badge: {
          color: "bg-indigo-500 text-white",
          text: 6,
        },
        hidden: checkAccess(22, 'list'),
        items: [
          {
            url: "/user-term-conditions",
            title: "User Terms & Conditions",
            hidden: checkAccess(22, 'list'),
            items: [],
          },
          {
            url: "/user-privacy-policy",
            title: "User Privacy & Policy",
            items: [],
            hidden: checkAccess(22, 'list'),
          },
          {
            url: "/term-condition",
            title: "Vendor Terms & Conditions",
            items: [],
            hidden: checkAccess(22, 'list'),
          },
          {
            url: "/vendor-privacy-policy",
            title: "Vendor Privacy & Policy",
            items: [],
            hidden: checkAccess(22, 'list'),
          },
          {
            url: "/refund-policy",
            title: "Refund Policy",
            items: [],
            hidden: checkAccess(22, 'list'),
          },
        ],
      },

      // {
      //   url: "/vendor_Suscription",
      //   icon: <FiStar size={20} />,
      //   title: "Vendor Subscription",
      //   badge: {
      //     color: "bg-indigo-500 text-white",
      //     text: 6,
      //   },
      //   items: [],
      // },
      // {
      //   url: "/",
      //   icon: <FiHelpCircle size={20} />,
      //   title: "Manage Commision",
      //   badge: {
      //     color: "bg-indigo-500 text-white",
      //     text: 6,
      //   },
      //   items: [
      //     {
      //       url: "/commission",
      //       title: "Commission",
      //       items: [],
      //     },
      //   ],
      // },
      {
        url: "/",
        icon: <FiHelpCircle size={20} />,
        title: "Chat and Ticket",
        badge: {
          color: "bg-indigo-500 text-white",
          text: 6,
        },
        items: [
          {
            url: "/chat",
            title: "Chat",
            items: [],
            hidden: checkAccess(23, 'list'),
          },
          {
            url: "/ticketList",
            title: "Ticket List",
            items: [],
            hidden: checkAccess(24, 'list'),
          },
        ],
      },

      {
        url: "/",
        icon: <FiHelpCircle size={20} />,
        title: "E-Voucher",
        badge: {
          color: "bg-indigo-500 text-white",
          text: 6,
        },
        items: [
          {
            url: "/evoucher",
            title: "E-Voucher",
            items: [],
            hidden: checkAccess(25, 'list'),
          },
        ],
      },

      {
        url: "/",
        icon: <FiTarget size={20} />,
        title: "Sales Campaign",
        hidden: checkAccess(26, 'list'),
        badge: {
          color: "bg-indigo-500 text-white",
          text: 6,
        },
        items: [
          {
            url: "/sales/discount",
            title: "Discount",
            items: [],
            hidden: checkAccess(27, 'list'),
          },
          {
            url: "/sales/voucher",
            title: "Voucher",
            items: [],
            hidden: checkAccess(28, 'list'),
          },
        ],
      },
    ],
  },
];

export default function navigation(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
