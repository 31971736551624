import axios from "axios";

class APIClient {

    /*--------- Methode for API calling use this methods as per your requirement just need to call this methods---------*/
    /*---Don't change this methods--*/

    async httpRequest(method, url, data, auth) {
        var headers = {
            "Content-Type": "application/json",
            // "Token": authToken
            // "Authorization":"Basic Z29ndWFyZDpnb2d1YXJkQDEyMzQ1"
            Authorization: auth
        }

        try {
            if (method === "get") {
                return await axios.get(url, { headers: headers });
            }
            else if (method === "patch") {
                return await axios.patch(
                    url,
                    data,
                    { headers: headers },
                    {
                        validateStatus: function (status) {
                            return status < 500; /* this is resolve if status code is less than 500 */
                        },
                    }
                );
            }
            else if (method === "put") {
                return await axios.put(
                    url,
                    data,
                    { headers: headers },
                    {
                        validateStatus: function (status) {
                            return status < 500; /* this is resolve if status code is less than 500 */
                        },
                    }
                );
            } else if (method === "post") {
                return await axios.post(
                    url,
                    data,
                    { headers: headers },
                    {
                        validateStatus: function (status) {
                            return status < 500; /* this is resolve if status code is less than 500 */
                        },
                    }
                );
            } else if (method === "delete") {
                return await axios.delete(
                    url,
                    { headers: headers },
                    data,
                    {
                        validateStatus: function (status) {
                            return status < 500; /* this is resolve if status code is less than 500 */
                        },
                    }
                );
            }

        } catch (err) {
            if (err.response &&
                err.response.status >= 400 &&
                err.response.status < 500 &&
                err.response.data
            ) {
                return err.response
            } else {
                if (err === "Error: Network Error") {
                    return { data: { response_code: 400, message: "Please check your internet connection." } }
                }
                return err.response
            }
        }
    }

    async multipartRequest(method, url, data, auth) {
        var headers = {
            // Accept: "application/json",
            "Content-Type": "multipart/form-data",
            // "Token": authToken
            Authorization: auth
        }

        try {
            if (method === "patch") {
                return await axios.patch(
                    url,
                    data,
                    { headers: headers },
                    {
                        validateStatus: function (status) {
                            return status < 500; /* this is resolve if status code is less than 500 */
                        },
                    }
                );
            } else if (method === "post") {
                return await axios.post(
                    url,
                    data,
                    { headers: headers },
                    {
                        validateStatus: function (status) {
                            return status < 500; /* this is resolve if status code is less than 500 */
                        },
                    }
                );
            }

        } catch (err) {
            if (err.response &&
                err.response.status >= 400 &&
                err.response.status < 500 &&
                err.response.data
            ) {
                return err.response
            } else {
                return err.response
                //throw err;
            }
        }
    }

    async get(url, data, auth) {
        return this.httpRequest("get", url, data, auth)
    }

    async post(url, data, auth) {
        return this.httpRequest("post", url, data, auth)
    }

    async patch(url, data, auth) {
        return this.httpRequest("patch", url, data, auth)
    }

    async put(url, data, auth) {
        return this.httpRequest("put", url, data, auth)
    }

    async delete(url, data, auth) {
        return this.httpRequest("delete", url, data, auth)
    }

    async multipart(method, url, data, auth) {
        return this.multipartRequest(method, url, data, auth)
    }
}
const ApiClient = new APIClient();
export default ApiClient
