import React, { useState, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Widget from "../widget";
import ApiClient from "../../apis/apiClient";
import { Api_EditRole } from "../../apis/constant";
import SectionTitle from "../section-title";

const EditUserList = () => {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [isDeleted, setIsDeleted] = useState(null);
  const [status, setStaus] = useState(null);
  const [modules, setModules] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);

  const adminToken = localStorage.getItem("wed_admin_token");
  const [error, setError] = useState("");

  let history = useHistory();
  const alphanumericRegex = /^[a-zA-Z0-9-. ]+$/;

  useEffect(() => {
    getDetailsById();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDetailsById = async () => {
    let data = "";
    let res = await ApiClient.get(
      Api_EditRole + "/" + id,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      let data = res.data.data;
      setName(data.name);
      setIsDeleted(data.isDeleted);
      setStaus(data.status);
      let perms_arr = [];
      for (let d in data.resource) {
        let obj = {
          _id: data.resource[d]._id,
          moduleName: data.resource[d].moduleName,
          moduleId: data.resource[d].moduleId,
        };

        if (data.resource[d].permissions.length === 0) {
          obj["selectedAll"] = false;
          obj["list"] = false;
          obj["add"] = false;
          obj["edit"] = false;
          obj["delete"] = false;
          obj["view"] = false;
          perms_arr.push(obj);
          continue;
        }

        if (data.resource[d].permissions.includes("selectedAll")) {
          obj["selectedAll"] = true;
        } else {
          obj["selectedAll"] = false;
        }
        if (data.resource[d].permissions.includes("list")) {
          obj["list"] = true;
        } else {
          obj["list"] = false;
        }
        if (data.resource[d].permissions.includes("add")) {
          obj["add"] = true;
        } else {
          obj["add"] = false;
        }
        if (data.resource[d].permissions.includes("view")) {
          obj["view"] = true;
        } else {
          obj["view"] = false;
        }
        if (data.resource[d].permissions.includes("delete")) {
          obj["delete"] = true;
        } else {
          obj["delete"] = false;
        }
        if (data.resource[d].permissions.includes("edit")) {
          obj["edit"] = true;
        } else {
          obj["edit"] = false;
        }
        perms_arr.push(obj);
      }
      setModules(perms_arr);
    }
  };

  const handleClick = (e, index, type) => {
    const values = [...modules];
    if (type === "all") {
      values[index].selectedAll = e.target.checked;
      values[index].list = e.target.checked;
      values[index].add = e.target.checked;
      values[index].edit = e.target.checked;
      values[index].view = e.target.checked;
      values[index].delete = e.target.checked;
    } else if (type === "list") {
      values[index].list = e.target.checked;
    } else if (type === "add") {
      values[index].add = e.target.checked;
    } else if (type === "edit") {
      values[index].edit = e.target.checked;
    } else if (type === "view") {
      values[index].view = e.target.checked;
    } else {
      values[index].delete = e.target.checked;
    }
    if (
      values[index].add &&
      values[index].edit &&
      values[index].view &&
      values[index].delete &&
      values[index].list
    ) {
      values[index].selectedAll = true;
    } else {
      values[index].selectedAll = false;
    }
    setModules(values);
  };

  const handleSubmitAdd = async () => {
    setIsSubmit(true);

    if (name === "") {
      // let errors = "Role Name is required";
      setError("Role Name is required");
      setIsSubmit(false);

      return;
    }
    if (name && !alphanumericRegex.test(name)) {
      setError("Please enter valid name.");
      setIsSubmit(false);

      return;
    }

    let myObj = {
      name: name,
      isDeleted: isDeleted,
      status: status,
      resource: [],
    };

    for (let data in modules) {
      let boxes = modules[data];
      let permission_arr = [];

      if (boxes["selectedAll"] === true) {
        permission_arr.push("selectedAll");
      }
      if (boxes["list"] === true) {
        permission_arr.push("list");
      }
      if (boxes["add"] === true) {
        permission_arr.push("add");
      }
      if (boxes["edit"] === true) {
        permission_arr.push("edit");
      }
      if (boxes["view"] === true) {
        permission_arr.push("view");
      }
      if (boxes["delete"] === true) {
        permission_arr.push("delete");
      }
      myObj["resource"].push({
        permissions: permission_arr,
        moduleName: modules[data]["moduleName"],
        moduleId: modules[data]["moduleId"],
      });
    }
    let error = false;
    for (let arr in myObj.resource) {
      if (myObj.resource[arr].permissions.length === 0) {
        error = true;
      } else {
        error = false;
        break;
      }
    }

    if (error) {
      toast.error("Please select atleast one permission.");
      setIsSubmit(false);

      return;
    }

    let res = await ApiClient.patch(
      Api_EditRole + "/" + id,
      myObj,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      history.push("/role/permission");
    } else if (res.data.code === 400) {
      toast.error(res.data.message);
      setIsSubmit(false);
    }
  };

  const handleChange = (value) => {
    if (!alphanumericRegex.test(value)) {
      setError("Please enter valid role name.");
      setName(value);
      setIsSubmit(false);

      return;
    } else {
      setName(value);
      setError("");
    }
  };

  return (
    <>
      <SectionTitle title="Tables" subtitle="Edit Roll & Permission" />
      <div className="addBack">
        <Link
          to={"/role/permission/"}
          className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
        >
          Back
        </Link>
      </div>

      <Widget>
        <div className="w-full flex">
          <div className="w-full">
            <div className="card-body">
              <div className="form-group row">
                <label>Name: </label>
                <div className="form-element lg:w-1/2 inline-flex inputCss">
                  <input
                    type="text"
                    className="form-input inline-flex"
                    id="exampleInputName1"
                    placeholder="Enter Role Name"
                    pattern="[a-zA-Z0-9 ]*"
                    name="name"
                    maxLength={50}
                    minLength={2}
                    value={name}
                    required
                    onChange={(e) => handleChange(e.target.value)}
                  />
                   <p style={{ color: "red" ,padding:"10px 4px"}}> {error}</p>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-12">
                  <div className="table-responsive border rounded mt-1">
                    <h6 className="py-1 mx-1 mb-0 font-medium-2">
                      <i className="fa fa-lock mr-25"></i>
                      <span className="align-middle">Permission</span>
                    </h6>
                    <table className="table table-striped table-borderless">
                      <thead className="thead-light">
                        <tr>
                          <th>Module Name</th>
                          <th>Select All</th>
                          <th>List</th>
                          <th>Add</th>
                          <th>Edit</th>
                          <th>View</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {modules.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.moduleName}</td>
                              <td>
                                <input
                                  name="checkall"
                                  type="checkbox"
                                  className="form-checkbox h-4 w-4 border-black"
                                  checked={item.selectedAll}
                                  onChange={(e) => handleClick(e, index, "all")}
                                />
                              </td>
                              <td>
                                <input
                                  name="checkList"
                                  type="checkbox"
                                  className="form-checkbox h-4 w-4 border-black"
                                  checked={item.list}
                                  onChange={(e) =>
                                    handleClick(e, index, "list")
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  name="checkAdd"
                                  type="checkbox"
                                  className="form-checkbox h-4 w-4 border-black"
                                  checked={item.add}
                                  onChange={(e) => handleClick(e, index, "add")}
                                />
                              </td>
                              <td>
                                <input
                                  name="checkEdit"
                                  type="checkbox"
                                  className="form-checkbox h-4 w-4 border-black"
                                  checked={item.edit}
                                  onChange={(e) =>
                                    handleClick(e, index, "edit")
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  name="checkView"
                                  type="checkbox"
                                  className="form-checkbox h-4 w-4 border-black"
                                  checked={item.view}
                                  onChange={(e) =>
                                    handleClick(e, index, "view")
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  name="checkView"
                                  type="checkbox"
                                  className="form-checkbox h-4 w-4 border-black"
                                  checked={item.delete}
                                  onChange={(e) =>
                                    handleClick(e, index, "delete")
                                  }
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {isSubmit ? (
                <button className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded addSubmit">
                  Loading...
                </button>
              ) : (
                <button
                  className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded addSubmit"
                  onClick={() => handleSubmitAdd()}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </Widget>
    </>
  );
};

export default EditUserList;
