
import React, { useEffect, useState } from "react";
import SectionTitle from "../components/section-title";
import SubCategoriestable from "../components/sub-sub-categories";
import Widget from "../components/widget";
import { toast } from "react-toastify";
import Modal2 from "../components/confirm-delete-modal/modal-2";
import { FiX } from "react-icons/fi";
import ModalActivate from "../components/confirm-activate-modal/modal-activate";
import ApiClient from "../apis/apiClient";
import {
  API_GetAllSubSubCategory,
  API_DeleteSubSubCategoryById,
  API_UpdateSubSubCategoryById,
} from "../apis/constant";
import AddSubSubCategory from "../components/sub-sub-categories/AddSubSubCategory";
import EditSubSubCategory from "../components/sub-sub-categories/subSubCategoryEditModal";
import { Redirect } from "react-router-dom";

const Simple = () => {
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [Loading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [paginationObject, setPaginationObject] = useState({});

  const adminToken = localStorage.getItem("wed_admin_token");

  const getAllsubsubCategory = async (limit, pageNumber) => {
    const data = "";
    setIsLoading(true);
    // setIsApiCall(true);
    const res = await ApiClient.get(
      API_GetAllSubSubCategory +
        `?page=${pageNumber}&sortBy=asc&limit=${limit}&searchBy=${search}`,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      if (res.data.data && res.data.data.docs.length !== 0) {
        for (let i = 0; i < res.data.data.docs.length; i++) {
          let element = res.data.data.docs[i];
          res.data.data.docs[i]["displayStatus"] =
            res.data.data.docs[i].status === 0 ? (
              <label className="">Inactive</label>
            ) : (
              <label className="">Active</label>
            );
          res.data.data.docs[i]["displayMasterCategory"] =
            element.masterCategoryId !== null
              ? element.masterCategoryId.name
              : "NA";
          res.data.data.docs[i]["displayCategories"] =
            element.categoryId !== null ? element.categoryId.name : "NA";
          res.data.data.docs[i]["displaySubCategories"] =
            element.subCategoryId !== null ? element.subCategoryId.name : "NA";
        }
      }
      setPaginationObject({
        limit: res.data.data.limit,
        page: res.data.data.page,
        pages: res.data.data.pages,
        total: res.data.data.total,
      });
      // setStatusChange()
      setSubCategoryData(res.data.data.docs);
      setIsLoading(false);
      // setIsApiCall(false);
    }
  };
  useEffect(() => {
    if (search.length > 2 || search.length === 0) {
      getAllsubsubCategory(10, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const deleteSubSubCategories = async (subsubcategoryData) => {
    const id = subsubcategoryData.original.id;
    const data = "";
    const res = await ApiClient.delete(
      API_DeleteSubSubCategoryById + id,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllsubsubCategory(10, 1);
    } else {
      toast.warning(res.data.message);
    }
  };

  const handleActive = async (status, data) => {
    const masData = data.masterCategoryId.id.toString();
    const casData = data.categoryId.id.toString();
    const subcasData = data.subCategoryId.id.toString();

    let myObj = {
      status: status,
      name: data.name,
      masterCategoryId: masData,
      categoryId: casData,
      subCategoryId: subcasData,
    };

    const res = await ApiClient.patch(
      API_UpdateSubSubCategoryById + "/" + data.id,
      myObj,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllsubsubCategory(10, 1);
    } else {
      toast.warning(res.data.message);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Level 1",
        accessor: "displayMasterCategory",
      },
      {
        Header: "Level 2",
        accessor: "displayCategories",
      },
      {
        Header: "Level 3",
        accessor: "displaySubCategories",
      },
      {
        Header: "Level 4",
        accessor: "name",
      },
      {
        Header: "Status",
        accessor: "displayStatus",
      },
      {
        Header: "Action",
        id: "delete",
        accessor: (str) => {},

        Cell: (Datatable) => (
          <>
            <EditSubSubCategory
              id={Datatable.row.original.id}
              title={"Edit Level 4"}
              body={"body"}
              getApi={getAllsubsubCategory}
            />{" "}
            |{" "}
            <Modal2
              title="Delete"
              icon={
                <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                  <FiX size={18} className="stroke-current text-red-500" />
                </span>
              }
              body={
                <div className="text-sm text-grey-500">
                  Are You Sure! You want to delete this Level 4?
                </div>
              }
              buttonTitle="Confirm"
              onClick={() => {
                deleteSubSubCategories(Datatable.row);
              }}
              buttonClassName="underline"
            />{" "}
            |{" "}
            {Datatable.row.original.status === 0 ? (
              <ModalActivate
                title="Activate"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to Deactivate this Level 4?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  handleActive(1, Datatable.cell.row.original);
                }}
                buttonClassName="underline"
              />
            ) : (
              <ModalActivate
                title="Deactivate"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to Active this Level 4?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  handleActive(0, Datatable.cell.row.original);
                }}
                buttonClassName="underline"
              />
            )}
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getPageData = (data, type) => {
    if (type === "limit") {
      getAllsubsubCategory(data, 1);
    } else {
      getAllsubsubCategory(data, 1);
    }
  };

  const data = React.useMemo(() => subCategoryData, [subCategoryData]);
  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
  return (
    <>
      <div className="flex justify-between">
        <div>
          <input
            className="searchCss"
            type="text"
            placeholder="Search"
            value={search}
            // onChange={(e) => handleSearchData(e.target.value)}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <AddSubSubCategory
          title={"Add Level 4"}
          body={"body"}
          getApi={getAllsubsubCategory}
        />
      </div>
      <SubCategoriestable
        columns={columns}
        data={data}
        loading={Loading}
        pagination={paginationObject}
        onSubmit={getPageData}
        showSearch={true}
        getAllsubsubCategory={getAllsubsubCategory}
      />
    </>
  );
  }
};

const Index = () => (
  <>
    <SectionTitle title="Tables" subtitle="Sub Sub-Categories" />

    <Widget>
      <Simple />
    </Widget>
  </>
);
export default Index;
