import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import Datatables from "./pages/datatables";
import DefaultForms from "./pages/default-forms";
import DefaultTables from "./pages/default-tables";
import Demo1 from "./pages/demo-1";
import Demo2 from "./pages/demo-2";
import Demo3 from "./pages/demo-3";
import Demo4 from "./pages/demo-4";
import Demo5 from "./pages/demo-5";
import Demo6 from "./pages/demo-6";
import Documentation from "./pages/documentation";
import EmailConfirmation from "./pages/email-confirmation";
import ErrorPage from "./pages/error-page";
import ForgotPassword from "./pages/forgot-password";
import Images from "./pages/images";
import LineCharts from "./pages/line-charts";
import Lists from "./pages/lists";
import LockScreen from "./pages/lock-screen";
import Login1 from "./pages/login-1";
import Login2 from "./pages/login-2";
import Login3 from "./pages/login-3";
import Modals from "./pages/modals";
import Pagination from "./pages/pagination";
import Popovers from "./pages/popovers";
import Tooltips from "./pages/tooltips";
import PrivacyPolicy from "./pages/privacy-policy";
import ProgressBars from "./pages/progress-bars";
import Notifications from "./pages/notifications";
import ResetPassword from "./pages/reset-password";
import Sliders from "./pages/sliders";
import Subscribe from "./pages/subscribe";
import Switches from "./pages/switches";
import Tabs from "./pages/tabs";
import TermsOfService from "./pages/terms-of-service";
import Typography from "./pages/typography";
import UserProfile from "./pages/user-profile";
import Usertable from "./pages/usertables";
import Permission from "./pages/permission";
import CustomerReview from "./pages/customer-review";
import EditUser from "./components/usertable/EditUser";
import Vendortables from "./pages/vendor";
import VendorDetail from "./pages/vendor-detail";
import Loader from "./components/loader";
import AddPermission from "./components/rolePermission/AddPermission";
import EditRolePermission from "./components/rolePermission/EditRolePermission";
import SubUsersTables from "./pages/subuserstables";
import EditSubUser from "./components/sub-users/EditSubUsers";
import ManageAdmin from "./pages/manageAdmin";
import AddsubAdmin from "./components/manageAdmin/addAdmin";
import EditSubAdmin from "./components/manageAdmin/editAdmin";
import MasterCategory from "./pages/masterCategory";
import EditMasterCategory from "./components/masterCategory/editMasterCategory";
import Category from "./pages/category";
import SubCategoriestables from "./pages/sub-categories";
import EditSubCategory from "./components/sub-categories/EditSubCategory";
import SubSubCategoriestables from "./pages/sub-sub-categories";
import Attributetables from "./pages/attribute";
import Filters from "./pages/filters";
import AddFilterData from "./components/Filters/addFilters";
import EditFilterData from "./components/Filters/editFilters";
import EditVendorData from "./components/vendortable/EditVendors";
import Faqtables from "./pages/faq";
import ProductType from "./pages/product_type";
import AddProductTypeData from "./components/products/addProduct";
import EditProductType from "./components/products/editProduct";
import ManageProduct from "./pages/manage_product";
import EditVendorProduct from "./components/manageProduct/editManageProduct";
import TermCondition from "./pages/term-condition";
import UsersTermCondition from "./pages/users-term-conditions";
import UsersPrivacyPolicy from "./pages/user-privacy-policy";
import VendorsPrivacyPolicy from "./pages/vendor-privacy-policy";
import RefundPolicy from "./pages/Refund-policy";
import VendorSubscription from "./components/vendorSubscription/addSubScription";
import Suscription from "./pages/subscription";
import Commisiontables from "./pages/commission";
import EditVendorSubscription from "./components/vendorSubscription/editSubscription";
import Chat from "./pages/chat";
import TicketList from "./pages/ticketList";
import EVoucher from "./pages/evoucher";
import DiscountPage from "./pages/discounts";
import AddDiscount from "./components/discounts/addDiscount";
import EditDiscounts from "./components/discounts/editDiscounts";
import SalesVoucher from "./pages/salesVoucher";
import AddVoucher from "./components/salesVoucher/addVoucher";
import EditVoucher from "./components/salesVoucher/editVouucher";
import PaymentPolicy from "./pages/payment-policy";
import PaymentPolicyDetail from "./pages/payment-policy-detail";
import Product from "./pages/product";
import ProductDetail from "./pages/product-detail";
import PaymentSummary from "./pages/payment-summary";
import Orders from "./pages/orders";
import AnomalyOrder from "./pages/anomaly_orders";
import TableOrder from "./pages/table-order";
import EditOrder from "./pages/editOrder";
import PrivateRoute from "./components/privateroute";
import AuditPage from "./pages/audit";
import OrderPayment from "./pages/order-payments";
import PaymentList from "./pages/payment-list";
import Scheduling from "./pages/scheduling";
import AddFaqCategory from "./pages/addFaqCategory";
import PaymentDetails from "./pages/payment-details";
import Courses from "./pages/courses";

const Routes = () => {
  return (
    <Switch>
      <PrivateRoute path="/add-faq-category">
        <AddFaqCategory />
      </PrivateRoute>
      <PrivateRoute path="/dashboard">
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute path="/order-payment-summary">
        <OrderPayment />
      </PrivateRoute>
      <PrivateRoute path="/payment-list">
        <PaymentList />
      </PrivateRoute>
      <PrivateRoute path="/add_vendor_subscription">
        <VendorSubscription />
      </PrivateRoute>
      <PrivateRoute path="/vendor_Suscription">
        <Suscription />
      </PrivateRoute>
      <PrivateRoute path="/editvendorsubscription/:id">
        <EditVendorSubscription />
      </PrivateRoute>
      <Route path="/login">
        <Login1 />
      </Route>
      <PrivateRoute path="/datatables">
        <Datatables />
      </PrivateRoute>
      <PrivateRoute path="/default-forms">
        <DefaultForms />
      </PrivateRoute>
      <PrivateRoute path="/default-tables">
        <DefaultTables />
      </PrivateRoute>
      <PrivateRoute path="/demo-1">
        <Demo1 />
      </PrivateRoute>
      <PrivateRoute path="/demo-2">
        <Demo2 />
      </PrivateRoute>
      <PrivateRoute path="/demo-3">
        <Demo3 />
      </PrivateRoute>
      <PrivateRoute path="/demo-4">
        <Demo4 />
      </PrivateRoute>
      <PrivateRoute path="/demo-5">
        <Demo5 />
      </PrivateRoute>
      <PrivateRoute path="/demo-6">
        <Demo6 />
      </PrivateRoute>
      <PrivateRoute path="/documentation">
        <Documentation />
      </PrivateRoute>
      <Route path="/email-confirmation">
        <EmailConfirmation />
      </Route>
      <PrivateRoute path="/error-page">
        <ErrorPage />
      </PrivateRoute>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <PrivateRoute path="/images">
        <Images />
      </PrivateRoute>
      <PrivateRoute path="/line-charts">
        <LineCharts />
      </PrivateRoute>
      <PrivateRoute path="/lists">
        <Lists />
      </PrivateRoute>
      <Route path="/lock-screen">
        <LockScreen />
      </Route>

      <Route path="/login-1">
        <Login1 />
      </Route>
      <Route path="/login-2">
        <Login2 />
      </Route>
      <Route path="/login-3">
        <Login3 />
      </Route>
      <PrivateRoute path="/modals">
        <Modals />
      </PrivateRoute>
      <PrivateRoute path="/pagination">
        <Pagination />
      </PrivateRoute>
      <PrivateRoute path="/tooltips">
        <Tooltips />
      </PrivateRoute>
      <PrivateRoute path="/popovers">
        <Popovers />
      </PrivateRoute>
      <PrivateRoute path="/privacy-policy">
        <PrivacyPolicy />
      </PrivateRoute>
      <PrivateRoute path="/progress-bars">
        <ProgressBars />
      </PrivateRoute>
      <PrivateRoute path="/notifications">
        <Notifications />
      </PrivateRoute>
      <Route path="/reset-password">
        <ResetPassword />
      </Route>
      <PrivateRoute path="/sliders">
        <Sliders />
      </PrivateRoute>
      <PrivateRoute path="/subscribe">
        <Subscribe />
      </PrivateRoute>
      <PrivateRoute path="/switches">
        <Switches />
      </PrivateRoute>
      <PrivateRoute path="/tabs">
        <Tabs />
      </PrivateRoute>
      <PrivateRoute path="/terms-of-service">
        <TermsOfService />
      </PrivateRoute>
      <PrivateRoute path="/typography">
        <Typography />
      </PrivateRoute>
      <PrivateRoute path="/term-condition">
        <TermCondition />
      </PrivateRoute>
      <PrivateRoute path="/chat">
        <Chat />
      </PrivateRoute>
      <PrivateRoute path="/ticketchat/:ticketId">
        <Chat />
      </PrivateRoute>
      <PrivateRoute path="/ticketList/:ticketNumber">
        <TicketList />
      </PrivateRoute>
      <PrivateRoute path="/ticketList">
        <TicketList />
      </PrivateRoute>
      <PrivateRoute path="/evoucher">
        <EVoucher />
      </PrivateRoute>
      <PrivateRoute path="/sales/discount">
        <DiscountPage />
      </PrivateRoute>

      <PrivateRoute path="/sales/addDiscount">
        <AddDiscount />
      </PrivateRoute>
      <PrivateRoute path="/sales/editDiscount/:id">
        <EditDiscounts />
      </PrivateRoute>

      <PrivateRoute path="/sales/voucher/">
        <SalesVoucher />
      </PrivateRoute>

      <PrivateRoute path="/sales/addVoucher/">
        <AddVoucher />
      </PrivateRoute>

      <PrivateRoute path="/sales/editVoucher/:id">
        <EditVoucher />
      </PrivateRoute>
      <PrivateRoute path="/courses">
        <Courses />
      </PrivateRoute>
      <PrivateRoute path="/customer-review">
        <CustomerReview />
      </PrivateRoute>
      <PrivateRoute path="/user-term-conditions">
        <UsersTermCondition />
      </PrivateRoute>
      <PrivateRoute path="/user-privacy-policy">
        <UsersPrivacyPolicy />
      </PrivateRoute>
      <PrivateRoute path="/community-guidelines">
        <VendorsPrivacyPolicy />
      </PrivateRoute>
      <PrivateRoute path="/payment-policy">
        <RefundPolicy />
      </PrivateRoute>
      <PrivateRoute path="/user-profile">
        <UserProfile />
      </PrivateRoute>
      <PrivateRoute path="/users">
        <Usertable />
      </PrivateRoute>
      <PrivateRoute path="/role/permission/add">
        <AddPermission />
      </PrivateRoute>
      <PrivateRoute path="/editrolepermission/:id">
        <EditRolePermission />
      </PrivateRoute>
      <PrivateRoute path="/role/permission">
        <Permission />
      </PrivateRoute>
      <PrivateRoute path="/admin/addSubAdmin">
        <AddsubAdmin />
      </PrivateRoute>
      <PrivateRoute path="/admin">
        <ManageAdmin />
      </PrivateRoute>
      <PrivateRoute path="/editAdminUser/:id">
        <EditSubAdmin />
      </PrivateRoute>
      <PrivateRoute path="/mastercategory">
        <MasterCategory />
      </PrivateRoute>
      <PrivateRoute path="/editmastercategory/:id">
        <EditMasterCategory />
      </PrivateRoute>

      <PrivateRoute path="/category">
        <Category />
      </PrivateRoute>
      <PrivateRoute path="/editfilters/:id">
        <EditFilterData />
      </PrivateRoute>
      <PrivateRoute path="/filters/addfilters">
        <AddFilterData />
      </PrivateRoute>
      <PrivateRoute path="/filters">
        <Filters />
      </PrivateRoute>
      <PrivateRoute path="/editProduct/:id">
        <EditProductType />
      </PrivateRoute>

      <PrivateRoute path="/manage_product">
        <ManageProduct />
      </PrivateRoute>

      <PrivateRoute path="/editvendor/:id">
        <EditVendorProduct />
      </PrivateRoute>

      <PrivateRoute path="/product_type/addproducts">
        <AddProductTypeData />
      </PrivateRoute>

      <PrivateRoute path="/product_type">
        <ProductType />
      </PrivateRoute>
      <PrivateRoute path="/edituser/:id">
        <EditUser />
      </PrivateRoute>

      <PrivateRoute path="/editvendors/:id">
        <EditVendorData />
      </PrivateRoute>
      <PrivateRoute path="/vendors">
        <Vendortables />
      </PrivateRoute>

      <PrivateRoute path="/vendor/:id">
        <VendorDetail />
      </PrivateRoute>

      <Route path="/scheduling">
        <Scheduling />
      </Route>

      <PrivateRoute path="/users-address">
        <SubUsersTables />
      </PrivateRoute>
      <PrivateRoute path="/editsub-user/:id">
        <EditSubUser />
      </PrivateRoute>
      <PrivateRoute path="/sub-category">
        <SubCategoriestables />
      </PrivateRoute>
      <PrivateRoute path="/editsub-cat/:id">
        <EditSubCategory />
      </PrivateRoute>
      <PrivateRoute path="/sub-sub-category">
        <SubSubCategoriestables />
      </PrivateRoute>
      <PrivateRoute path="/attributes">
        <Attributetables />
      </PrivateRoute>
      <PrivateRoute path="/faq-details">
        <Faqtables />
      </PrivateRoute>
      <PrivateRoute path="/commission">
        <Commisiontables />
      </PrivateRoute>
      <PrivateRoute path="/loader">
        <Loader />
      </PrivateRoute>
      <Route exact path="/">
        <Login1 />
      </Route>

      <PrivateRoute path="/vendor-add">
        <VendorDetail />
      </PrivateRoute>

      <PrivateRoute path="/payment-policy-list">
        <PaymentPolicy />
      </PrivateRoute>

      <PrivateRoute path="/payment-policy-detail/:id">
        <PaymentPolicyDetail />
      </PrivateRoute>

      <PrivateRoute path="/product">
        <Product />
      </PrivateRoute>

      <PrivateRoute path="/product-detail/:id">
        <ProductDetail />
      </PrivateRoute>

      <PrivateRoute path="/payment-summary/:id">
        <PaymentSummary />
      </PrivateRoute>

      <PrivateRoute path="/orders">
        <Orders />
      </PrivateRoute>

      <PrivateRoute path="/anomaly-orders">
        <AnomalyOrder />
      </PrivateRoute>

      <PrivateRoute path="/cancel-order">
        <TableOrder />
      </PrivateRoute>

      <PrivateRoute path="/edit-order/:id/:orderId">
        <EditOrder />
      </PrivateRoute>

      <PrivateRoute path="/payment-details/:id/:orderId">
        <PaymentDetails />
      </PrivateRoute>
      <PrivateRoute path="/audit-report/:type/:id">
        <AuditPage />
      </PrivateRoute>
      <Redirect to="/dashboard" />
    </Switch>
  );
};
export default Routes;
