import React from "react";
import { useSelector } from "react-redux";

const AttributesNew = () => {
  const productForm = useSelector((state) => state.productsSlice);

  return (
    <div className="flex flex-col mt-5 space-y-5">
      <div className="pt-4">
        <div className="w-full">
          <table className="w-full text-sm text-left">
            <thead className="">
              <tr>
                <th scope="col" className="px-6 py-3 text-xl">
                  Attribute Name
                </th>
                <th scope="col" className="px-6 py-3 text-xl">
                  Attribute Value
                </th>
                <th scope="col" className="px-6 py-3 text-xl"></th>
              </tr>
            </thead>
            <tbody className="border-b-0.5">
              {productForm.product?.attributes.map((item, index) => (
                <tr className="border-t-0.5 border-gray-300">
                  <td className="px-6 py-4">
                    <div>
                      <p className="text-xl font-worksans-semibold">
                        {item.attributeId.attributeName}
                      </p>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div>
                      <input
                        value={item.variation.map((item, index) => {
                          if (index !== item.variation - 1) return item;
                          return `${item}, `
                        })}
                        disabled
                        type="text"
                        className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AttributesNew;
