import React, { useState, useEffect, useRef } from "react";
import { useSelector, shallowEqual } from "react-redux";
import Portal from "../portal";
import { FiX } from "react-icons/fi";
import {
  API_UpdateFaqDetailsById,
  API_CreateFaqDetails,
  API_GetAllFaqTypes,
  url,
} from "../../apis/constant";
import ApiClient from "../../apis/apiClient";
import { toast } from "react-toastify";
import axios from "axios";

const EditFaq = ({ id, title, getApi }) => {
  const alphanumericRegex = /^[a-zA-Z0-9-. ]+$/;
  const adminToken = localStorage.getItem("wed_admin_token");
  const [error, setError] = useState("");
  const [faqNameerror, setFaqNameError] = useState("");
  const [varError, setVarError] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [faqNameValue, setFaqNameValue] = useState("");
  const [varNameValue, setVarNameValue] = useState([]);
  const [allFaqTypes, setAllFaqTypes] = useState([]);
  const [subCatData, setSubCatData] = useState("");

  const { palettes } = useSelector(
    (state) => ({
      palettes: state.palettes,
    }),
    shallowEqual
  );
  let { background } = {
    ...palettes,
  };

  const modalRef = useRef(null);
  const [open, setOpen] = useState(false);
  const show = () => {
    getFaqById();
    setOpen(true);
  };
  const hide = () => {
    setOpen(false);
    setFaqNameValue("");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false;
      if (!open || modalRef.current.contains(event.target)) {
        return false;
      }
      setOpen(!open);
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, modalRef]);

  const getFaqById = () => {
    axios
      .get(url + API_CreateFaqDetails + "/" + id, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((res) => {
        if (res.data.code === 200) {
          getAllFaqTypes(10, "", 1);
          setSubCatData(res.data.data.faqTypeId.id);
          setFaqNameValue(res.data.data.question);
          setVarNameValue(res.data.data.answer);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handleChange = (value, type) => {
    if (type === "name") {
      if (value && !alphanumericRegex.test(value)) {
        setError("Faq Type should not be blank.");
      } else {
        setError("");
      }
      setSubCatData(value);
    } else if (type === "attname") {
      if (value.length === 0) {
        setFaqNameError("Question should not be blank.");
      } else if (value.length > 500) {
        setFaqNameError("Question should not be longer than 500.");
      } else {
        setFaqNameError("");
      }
      setFaqNameValue(value);
    } else if (type === "varname") {
      if (value.length === 0) {
        setVarError("Answer should not be blank.");
      } else if (value.length > 1000) {
        setVarError("Answer should not be longer than 1000.");
      } else {
        setVarError("");
      }
      setVarNameValue(value);
    }
  };

  const handleFaq = () => {
    let isTrue = false;
    setIsSubmit(true);
    if (subCatData === "") {
      setError("Faq Type should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setError("");
    }
    if (faqNameValue === "") {
      setFaqNameError("Faq Question should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else if (faqNameValue.length > 500) {
      setVarError("Faq Question should not be longer than 500.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      isTrue = false;
      setFaqNameError("");
    }
    if (varNameValue === "") {
      setVarError("Faq Answer should not be blank.");
      setIsSubmit(false);
      isTrue = true;
    } else if (varNameValue.length > 1000) {
      setVarError("Faq Answer should not be longer than 1000.");
      setIsSubmit(false);
      isTrue = true;
    } else {
      setVarError("");
      isTrue = false;
    }

    if (isTrue) {
      return;
    }

    let logData = {
      faqTypeId: subCatData,
      question: faqNameValue,
      answer: varNameValue,
    };
    axios
      .patch(url + API_UpdateFaqDetailsById + "/" + id, logData, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((res) => {
        if (res.data.code === 200) {
          toast.success(res.data.message);
          hide();
          getApi(10, "", 1);
        } else if (res.data.code === 400) {
          toast.error(res.data.message);
          setIsSubmit(false);
        }
      })
      .catch((err) => {
        setIsSubmit(false);
        toast.error(err.response.data.message);
      });
  };

  const getAllFaqTypes = async (limit, searchq = "") => {
    const data = "";
    const res = await ApiClient.get(
      API_GetAllFaqTypes,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      if (res.data.data && res.data.data.docs.length !== 0) {
        for (let i = 0; i < res.data.data.docs.length; i++) {
        }
      }
      setAllFaqTypes(res.data.data.docs);
    }
  };



  return (
    <>
      <button
        style={{ cursor: "pointer", textDecoration: "underline" }}
        type="button"
        onClick={show}
      >
        Edit
      </button>
      {open && (
        <Portal selector="#portal">
          <div className="modal-backdrop fade-in"></div>
          <div
            className={`modal show ${background === "dark" ? "dark-mode" : ""}`}
            data-background={background}
          >
            <div
              className="relative min-w-sm w-auto mx-auto lg:max-w-5xl"
              ref={modalRef}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="text-xl font-semibold">{title}</h3>
                  <button
                    className="modal-close btn btn-transparent"
                    onClick={hide}
                  >
                    <FiX size={18} className="stroke-current" />
                  </button>
                </div>

                <div className="form-element">
                  <div className="form-label">Types</div>
                  <select
                    name="select"
                    value={subCatData}
                    id=""
                    onChange={(e) => handleChange(e.target.value, "name")}
                    style={{}}
                    className="SelctOptionscategory"
                  >
                    <option value="">Select...</option>
                    {allFaqTypes?.map((mcat, idx) => (
                      <option key={idx} value={mcat.id}>
                        {mcat?.type}
                      </option>
                    ))}
                  </select>
                  {error && (
                    <p style={{ color: "red", padding: "5px 20px" }}>{error}</p>
                  )}
                </div>

                <div className="form-element">
                  <p>
                    <label className="form-label">Question</label>
                  </p>
                  <textarea
                    value={faqNameValue}
                    placeholder="Please Enter Answer"
                    id="name"
                    name="attname"
                    rows="4"
                    cols="50"
                    onChange={(e) => handleChange(e.target.value, "attname")}
                    className="SelctOptionscategory"
                  ></textarea>
                  {faqNameerror && (
                    <p style={{ color: "red", padding: "5px 20px" }}>
                      {faqNameerror}
                    </p>
                  )}{" "}
                </div>

                <div className="form-element">
                  <p>
                    <label className="form-label">Answer</label>
                  </p>
                  <textarea
                    value={varNameValue}
                    placeholder="Please Enter Answer"
                    id="name"
                    name="varname"
                    rows="4"
                    cols="50"
                    onChange={(e) => handleChange(e.target.value, "varname")}
                    className="SelctOptionscategory"
                  ></textarea>
                  {varError && (
                    <p style={{ color: "red", padding: "5px 20px" }}>
                      {varError}
                    </p>
                  )}{" "}
                </div>

                <div className="modal-footer space-x-2">
                  <button
                    className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                    type="button"
                    onClick={hide}
                  >
                    Close
                  </button>
                  {isSubmit ? (
                    <button
                      className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                      type="button"
                    >
                      Loading...
                    </button>
                  ) : (
                    <button
                      className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                      type="button"
                      onClick={() => handleFaq()}
                    >
                      Save Changes
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default EditFaq;
