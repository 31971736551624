import { createSlice } from "@reduxjs/toolkit";
import {
  vendorAction,
  vendorDeactivateAction,
  vendorGetByIdAction,
  vendorForm,
  addVendorAction,
  getCategoriesLevelOneAction,
} from "../actions";

export const initialVendor = {
  sellerInfo: {
    countryCode: "+65",
    countryCodeForMobile: "+65",
    profileImage: "",
    profilePreview: "",
    bannerImage: "",
    bannerPreview: "",
    review: "",
    website: "",
    username: "",
    password: "",
    vendorName: "",
    vatTaxNumber: "",
    companyName: "",
    companyRegistrationNumber: "",
    phone: "",
    mobileNumber: "",
    email: "",
    unitNo: "",
    buildingName: "",
    streetAddress1: "",
    streetAddress2: "",
    streetAddress3: "",
    postCode: "",
    country: "",
    city: "",
    state: "",
    blockNumber: "",
  },
  businessOwner: {
    countryCode: "+65",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    isPrivacyAndTerms: false,
  },
  sellerOffering: {
    services: [],
    areYouASocialChampion: false,
  },
  socialMedia: {
    facebook: "",
    flickr: "",
    instagram: "",
    linkedIn: "",
    pinterest: "",
    twitter: "",
    youtube: "",
  },
  bankInfo: {
    accountName: "",
    accountNumber: "",
    bankAddress: "",
    bankName: "",
    iban: "",
    routingNumber: "",
    swiftCode: "",
  },
  compensationPlan: {
    commissionPlan: "",
    commissionPercentage: "",
    commissionFixed: "",
    subscription: "",
    subscriptionName: "",
    subscriptionPeriod: "MONTHLY",
  },
  faq: [{ id: 1, question: "", answer: "", isEdit: false }],
  validationChecks: {
    fiveYearHistory: true,
    isDirectorOfbankrupt: true,
    isCaseReview: true,
    isBadPress: true,
    isBadReviews: true,
    comment: "",
  },
  settings: {
    enableSelling: true,
    publishProductDirectly: true,
    makeVendorFeatured: true,
  },
};

const initialState = {
  vendors: [],
  vendor: null,
  categoriesLevelOne: [],
  isFetching: false,
  vendorForm: initialVendor,
};

export const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    vendorFormAction: vendorForm,
  },
  extraReducers: {
    //Get all Vendor
    [vendorAction.pending]: (state, action) => {
      state.isFetching = true;
      state.error = null;
    },
    [vendorAction.fulfilled]: (state, action) => {
      state.vendors = action.payload.vendors;
      state.isFetching = false;
    },
    [vendorAction.rejected]: (state, action) => {
      state.isFetching = false;
    },

    //Get Vendor by id
    [vendorGetByIdAction.pending]: (state, action) => {
      state.isFetching = true;
      state.error = null;
    },
    [vendorGetByIdAction.fulfilled]: (state, action) => {
      state.vendor = action.payload.vendor;
      state.isFetching = false;
    },
    [vendorGetByIdAction.rejected]: (state, action) => {
      state.isFetching = false;
    },

    // deactive vendor
    [vendorDeactivateAction.pending]: (state, action) => {
      state.isFetching = true;
      state.error = null;
    },
    [vendorDeactivateAction.fulfilled]: (state, action) => {
      state.isFetching = false;
    },
    [vendorDeactivateAction.rejected]: (state, action) => {
      state.isFetching = false;
    },

    // add vendor
    [addVendorAction.pending]: (state, action) => {
      state.isFetching = true;
      state.error = null;
    },
    [addVendorAction.fulfilled]: (state, action) => {
      state.isFetching = false;
    },
    [addVendorAction.rejected]: (state, action) => {
      state.isFetching = false;
    },

    //Get categories level one
    [getCategoriesLevelOneAction.pending]: (state, action) => {
      state.isFetching = true;
      state.error = null;
    },
    [getCategoriesLevelOneAction.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.categoriesLevelOne = action.payload;
    },
    [getCategoriesLevelOneAction.rejected]: (state, action) => {
      state.isFetching = false;
    },
  },
});

export const { vendorFormAction } = vendorSlice.actions;

export default vendorSlice.reducer;
