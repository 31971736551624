/* eslint-disable eqeqeq */
/* eslint-disable default-case */
import { url as APIURL, url } from "../apis/constant";

export const fetcher = async (action, url, token = null, params = null) => {
  const credential = token
    ? `Bearer ${token}`
    : `Basic d2VkZGluZ3NlcnY6d2VkZGluZ3NlcnZAMTIzNDU=`;
  const res = await fetch(`${APIURL}${url}`, {
    method: action,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: credential,
    },
    body: params ? params : null,
  });
  const data = await res.json();
  return data;
};

export const checkAccess = (moduleId, type) => {
  var data = localStorage.getItem("wed_admin_adata") && JSON.parse(localStorage.getItem("wed_admin_adata")).role;
  var loginDetails = localStorage.getItem("wed_admin_adata") && JSON.parse(localStorage.getItem("wed_admin_adata"));
  if (data && loginDetails.type !== "superadmin") {
    for (let i = 0; i < data.resource.length; i++) {
      if (data.resource[i].moduleId === moduleId) {
        return data.resource[i].permissions.includes(type) ? true : false;
      }
    }
  } else {
    return true;
  }
};

export const getPreviewUrl = (filename) => {
  return filename !== "undefined" && filename
    ? `${url}/media/preview?filename=${filename}`
    : `${url}/media/preview?filename=`;
};

export const thousandFormat = (value) => {
  const _value = value.toString();
  const rawValue = _value.replace(/\D/g, "");
  if (!isNaN(Number(rawValue))) {
    const formattedValue = rawValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedValue;
  }
};

export const dateSwitch = (dateVal) => {
  let date = new Date(dateVal);
  var tahun = date.getFullYear();
  var bulan = date.getMonth();
  var tanggal = date.getDate();
  var hari = date.getDay();

  switch (hari) {
    case 0:
      hari = "Sunday";
      break;
    case 1:
      hari = "Monday";
      break;
    case 2:
      hari = "Tuesday";
      break;
    case 3:
      hari = "Wednesday";
      break;
    case 4:
      hari = "Thursday";
      break;
    case 5:
      hari = "Friday";
      break;
    case 6:
      hari = "Saturday";
      break;
  }

  switch (bulan) {
    case 0:
      bulan = "Jan";
      break;
    case 1:
      bulan = "Feb";
      break;
    case 2:
      bulan = "Mar";
      break;
    case 3:
      bulan = "Apr";
      break;
    case 4:
      bulan = "May";
      break;
    case 5:
      bulan = "Jun";
      break;
    case 6:
      bulan = "Jul";
      break;
    case 7:
      bulan = "Aug";
      break;
    case 8:
      bulan = "Sep";
      break;
    case 9:
      bulan = "Oct";
      break;
    case 10:
      bulan = "Nov";
      break;
    case 11:
      bulan = "Dec";
      break;
  }

  return { hari, bulan, tanggal, tahun };
};

export const dateFormat = (dateVal) => {
  let date = dateSwitch(dateVal);
  return `${date.tanggal}-${date.bulan}-${date.tahun}`;
};

export const formatCityText = (details) => {
  const { city, state, country, postalCode, postCode } = details || {};
  const cityText = city && (state || country) ? `${city},` : city;
  const stateText = state && country ? `${state},` : state;
  const postalCodeText =
    postalCode || postCode ? `${postalCode ?? postCode},` : "";
  const countryText = country || "";

  if (countryText == "Singapore" || countryText == "SG") {
    return (
      <>
        {postalCodeText} {countryText}
      </>
    );
  }
  return (
    <>
      {cityText} {stateText} {postalCodeText} {countryText}
    </>
  );
};

export const FormatPrice = (price) => {
  if (!price) {
    return 0;
  }

  return Number.parseFloat(price)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
