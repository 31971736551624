import Axios from "axios";
import { url } from "../apis/constant";

const BASE_URL = url;

const API = Axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
  },
});

export default API;