import React from 'react'
import axios from "axios";
import { url, API_Dashboard } from "../../apis/constant";
import Widget2 from "../../components/dashboard/widget-2";

const SalesInsight = () => {
  const [salesData, setSalesData] = React.useState([]);

  React.useEffect(() => {
    const adminToken = localStorage.getItem("wed_admin_token");
    try {
      axios.get(url + API_Dashboard.salesInsight, { 
        headers: {
          Authorization: `Bearer ${adminToken}`
        }
      }).then(res => {
        const data = res.data.data;
        setSalesData(data);
      }).catch(err => {
        console.log(err);
      })
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div className="animate-fade-in-down flex_open_sale">
      <div className="flex w-2/3 space-x-40">
        <div className="w-1/2">
          <p className="mb-2 text-2xl">Active Costumer</p>
          <div className="border border-grey-100 flex rounded-lg p-3 my-2">
            <div className="text-xl font-bold self-center">{salesData.customer?.activeCustomers || 0}</div>
          </div>
        </div>
        <div className="w-1/2">
          <p className="mb-2 text-2xl">Total Customers</p>
          <div className="border border-grey-100 flex rounded-lg p-3 my-2">
            <div className="text-xl font-bold self-center">{salesData.customer?.totalCustomers || 0}</div>
          </div>
        </div>
      </div>

      <div className="flex w-2/3 space-x-40 mt-8">
        <div className="w-1/2">
          <p className="mb-2 text-2xl">Top Services/Products Sold</p>
          {salesData.productCategory?.categoryReports.map((category) => (
            <Widget2
              key={category.categoryId}
              title={category.categoryName}
              description={String(category.count)}
              right={""}
            />
          ))}
        </div>
        <div className="w-1/2">
          <p className="mb-2 text-2xl">Sales by Traffic Source</p>
          <Widget2 title="Direct Online" description={"10"} right={""} />
          <Widget2 title="Social" description={"10"} right={""} />
          <Widget2 title="Refferals" description={"10"} right={""} />
          <Widget2 title="Affiliates" description={"10"} right={""} />
        </div>
      </div>
    </div>
  )
}

export default SalesInsight
