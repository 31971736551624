/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SectionTitle from "../components/section-title";
import Permissiontable from "../components/rolePermission";
import Widget from "../components/widget";
import { toast } from "react-toastify";
import Modal2 from "./../components/confirm-delete-modal/modal-2";
import { FiX } from "react-icons/fi";
import ApiClient from "../apis/apiClient";
import {
  API_ListOfRoles,
  API_DeleteRole,
  API_UpdateStatus,
} from "../apis/constant";
import "nprogress/nprogress.css";
import "../css/custome.css";
import { Link,Redirect } from "react-router-dom";
import { checkAccess } from "../utils/common";
const Simple = () => {
  const [permissionsData, setPermissionsData] = useState([]);
  const [paginationObject, setPaginationObject] = useState({});
  const [Loading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const adminToken = localStorage.getItem("wed_admin_token");
  const getAllUsers = async (limit, pageNumber, searchq = "") => {
    const data = "";
    setIsLoading(true);
    const res = await ApiClient.get(
      API_ListOfRoles +
        `?page=${pageNumber}&sortBy=asc&limit=${limit}&searchBy=${searchq}`,
      data,
      `Bearer ${adminToken}`
    );

    if (res.data.code === 200) {
      setPaginationObject({
        limit: res.data.data.limit,
        page: res.data.data.page,
        pages: res.data.data.pages,
        total: res.data.data.total,
      });
      setPermissionsData(res.data.data.docs);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    
    if (search.length > 2 || search.length === 0) {
      getAllUsers(10, 1, search);
    }
  }, [search]);

  const handleActive = async (status, data) => {
    let myObj = {
      status: status,
      name: data.name,
    };

    const res = await ApiClient.patch(
      API_UpdateStatus + "/" + data.id,
      myObj,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllUsers(10, 1, "");
    } else {
      toast.warning(res.data.message);
    }

  };

  const deleteUser = async (userData) => {
    const id = userData.original.id;
    const data = "";
    const res = await ApiClient.delete(
      API_DeleteRole + "/" + id,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      toast.success(res.data.message);
      getAllUsers(10, 1, "");
    } else {
      toast.warning(res.data.message);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: " Name",
        accessor: "name",
      },
      {
        Header: "Status",
        id: "status",
        accessor: (str) => {
          return str["status"] === 1
            ?  <label >Active</label>
             
            :  <label>Inactive</label>
             
        },
      },

      {
        Header: "Action",
        id: "delete",
        accessor: (str) => {
        },

        Cell: (Datatable) => (
          <>
           {checkAccess(1, "edit") && <Link
              className="underLineText"
              style={{ cursor: "pointer" }}
              to={`/EditRolePermission/${Datatable.row.original.id}`}
            >
              Edit
            </Link>}{" "}
            |{" "}
           {checkAccess(1, "delete") && <Modal2
              title="Delete"
              icon={
                <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                  <FiX size={18} className="stroke-current text-red-500" />
                </span>
              }
              body={
                <div className="text-sm text-grey-500">
                  Are You Sure! You want to delete this User?
                </div>
              }
              buttonTitle="Confirm"
              onClick={() => {
                deleteUser(Datatable.row);
              }}
              buttonClassName="underLineText"

            />}{" "}
            | {" "}
            { Datatable.cell.row.original.status === 1 ? (
             checkAccess(1, "edit") && <Modal2
                title="Deactivate"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to Active this permission?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  handleActive(0, Datatable.cell.row.original);
                }}
                buttonClassName="underLineText"
              />
            ) : (
              checkAccess(1, "edit") &&<Modal2
                title="Activate"
                icon={
                  <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500" />
                  </span>
                }
               
                body={
                  <div className="text-sm text-grey-500">
                    Are You Sure! You want to Deactivate this permission?
                  </div>
                }
                buttonTitle="Confirm"
                onClick={() => {
                  handleActive(1, Datatable.cell.row.original);
                }}
                 buttonClassName="underLineText"
              />
            )}
          </>
        ),
      },
    ],
    []
  );

  const getPageData = (data, type) => {
    if (type === "limit") {
      getAllUsers(data, 1, "");
    } else {
      getAllUsers(10, data, "");
    }
  };

  const data = React.useMemo(() => permissionsData, [permissionsData]);
  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
  return (
    <>
      <div>
        <input
          className="searchCss"
          type="text"
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <Permissiontable
        columns={columns}
        data={data}
        loading={Loading}
        pagination={paginationObject}
        onSubmit={getPageData}
        showSearch={true}
        getAllUsers={getAllUsers}
      />
    </>
  );
  }
};

const Permission = () => (
  <>
    <div>
      {checkAccess(1, "add") && <Link
        to={"/role/permission/add"}
        className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded addRoleCss"
      >
        Add New Role
      </Link>}
    </div>
    <SectionTitle title="Manage Roles & Permissions" subtitle="Role & Permissions" />
    <Widget>
      <Simple />
    </Widget>
  </>
);
export default Permission;
