import React from "react";
import { useSelector } from "react-redux";

const PRODUCT_STATUS = {
  0: "Draft",
  1: "Published",
};

const Publish = () => {
  const productForm = useSelector((state) => state.productsSlice);

  return (
    <div className="flex flex-col mt-5 space-y-5">
      <div className="flex items-center w-full">
        <p className="mr-3">Custom:</p>
        <input
          checked={productForm.product?.custom}
          className="h-5 w-5 bg-white cursor-pointer"
          type="checkbox"
        />
      </div>

      <div className="flex space-x-3 w-full">
        <p className="mb-2">Enable Selling:</p>
        <input
          checked={productForm.product?.enableSelling}
          className="mr-2 mt-1 h-5 w-5 bg-white cursor-pointer"
          type="checkbox"
        />
      </div>

      <div className="flex space-x-3 w-full">
        <p className="mb-2">Feature Product:</p>
        <input
          checked={productForm.product?.featuredProduct}
          className="mr-2 mt-1 h-5 w-5 bg-white cursor-pointer"
          type="checkbox"
        />
      </div>

      <div className="flex space-x-2">
        <div className="w-full">
          <p className="mb-2">Publish Status</p>
          <div
            className="w-56 cursor-pointer justify-center outline-none border border-bg-primary rounded-sm px-1 flex items-center"
            style={{ height: "34px" }}
          >
            <p>{PRODUCT_STATUS[productForm.product?.publishStatus]}</p>
          </div>
        </div>
      </div>

      <div>
        <p className="mb-2">Publish Immediately *</p>
        <div className="relative">
          <div className="w-64 outline-none py-2 px-2 rounded-lg border-2 flex justify-between items-center z-10 cursor-pointer">
            <p>{productForm.product?.publishImmediatelyDate}</p>
          </div>
        </div>
      </div>

      <div className="flex space-x-4 pb-5">
        <button className="py-2 w-36 border-2 border-bg-primary text-bg-primary rounded-md hover:opacity-50 px-2">
          Preview Product
        </button>
      </div>
    </div>
  );
};

export default Publish;
