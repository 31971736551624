/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { useSelector, shallowEqual } from "react-redux";
import Portal from "../portal";
import { FiX } from "react-icons/fi";

const EditTicketNotes = ({
  open,
  setOpen,
  insertNotes,
  setInsertNotes,
  handleNotes,
}) => {
  const { palettes } = useSelector(
    (state) => ({
      palettes: state.palettes,
    }),
    shallowEqual
  );
  let { background } = {
    ...palettes,
  };

  const modalRef = useRef(null);
  // const [open, setOpen] = useState(false);
  // const show = () => {
  //   setOpen(true);
  // };
  const hide = () => {
    setOpen(false);
  };

  useEffect(() => {
    // getAllFaqTypes("");
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false;
      if (!open || modalRef.current.contains(event.target)) {
        return false;
      }
      setOpen(!open);
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, modalRef]);

  return (
    <>
      {open && (
        <Portal selector="#portal">
          <div className="modal-backdrop fade-in"></div>
          <div
            className={`modal show add_faq_cat_modal ${
              background === "dark" ? "dark-mode" : ""
            }`}
            data-background={background}
          >
            <div
              className="relative min-w-sm w-auto mx-auto lg:max-w-5xl"
              ref={modalRef}
            >
              <div className="modal-content" style={{ height: "70vh" }}>
                <div className="modal-header">
                  <h3 className="text-xl font-semibold">Ticket Notes</h3>
                  <button
                    className="modal-close btn btn-transparent"
                    onClick={hide}
                  >
                    <FiX size={18} className="stroke-current" />
                  </button>
                </div>
                <div className="relative p-4 flex-auto faq_box_inner">
                  <label>Notes</label>
                  <div className="inputBoxCategoryCss">
                    <textarea
                      type="text"
                      placeholder="Please Enter Notes"
                      name="name"
                      value={insertNotes.notes}
                      onChange={(e) => {
                        setInsertNotes({
                          ...insertNotes,
                          notes: e.target.value,
                        });
                      }}
                      className="inputFields"
                      cols={30}
                      rows={10}
                    />
                  </div>
                </div>
                <div className="modal-footer space-x-2">
                  <button
                    className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                    type="button"
                    onClick={hide}
                  >
                    Close
                  </button>
                  <button
                    className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                    type="button"
                    onClick={() => handleNotes()}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default EditTicketNotes;
