import React, { useEffect, useRef, useState } from "react";
import { AiOutlineDown } from "react-icons/ai";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";

const SelectDate = ({ onChange, value }) => {
  const dateRef = useRef(null);
  const [showDate, setShowDate] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dateRef.current && !dateRef.current.contains(event.target)) {
        setShowDate(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dateRef]);

  return (
    <div ref={dateRef} className="w-full relative">
      <div
        onClick={() => {
          setShowDate(!showDate);
        }}
        className="w-full outline-none py-2 px-2 rounded-lg border-2 flex justify-between items-center z-10 cursor-pointer text-lg"
      >
        <p className={!value && "text-slate-400"}>
          {value ? moment(value).format("DD-MMM-YYYY") : "Select Date"}
        </p>
        <AiOutlineDown className="text-bg-primary font-bold" />
      </div>
      {showDate && (
        <div className="bg-white absolute top-12 border-2 z-10">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticDatePicker
              value={value}
              onChange={(e) => {
                onChange(e);
                setShowDate(false);
              }}
              renderInput={() => <div />}
            />
          </LocalizationProvider>
        </div>
      )}
    </div>
  );
};

export default SelectDate;
