import React from "react";
import SubCategoryEdit from "./subCategoryEditModal";
import Widget from "../widget";

const EditSubCategoryList = ({
  vendors,
  selectedVendr,
  setVendors,
  setIsEditing,
}) => {
  return (
    <>
      <Widget description={<span>Edit SubCategory</span>}>
        <div className="w-full flex">
          <div className="w-full">
            <SubCategoryEdit />
          </div>
        </div>
      </Widget>
    </>
  );
};

export default EditSubCategoryList;
