import React, { useEffect, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { vendorFormAction } from "../../reducers/vendor.reducer";

const FormBankInfo = ({ isNew }) => {
  const vendorForm = useSelector((state) => state.vendorSlice.vendorForm);
  const dispatch = useDispatch();

  const handleChangeForm = (title, value) => {
    dispatch(
      vendorFormAction({
        ...vendorForm,
        bankInfo: {
          ...vendorForm.bankInfo,
          [title]: value,
        },
      })
    );
  };

  return (
    <div className="md:mt-10 mt-5 animate-fade-in-down">
      <div className="md:flex md:space-x-2 md:space-y-0 space-y-4">
        <div className="w-full space-y-2">
          <p>Account Name*</p>
          <input
            onChange={(e) => handleChangeForm("accountName", e.target.value)}
            value={vendorForm.bankInfo?.accountName}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
            required
          />
        </div>
        <div className="w-full space-y-2">
          <p>Account Number*</p>
          <input
            onChange={(e) => handleChangeForm("accountNumber", e.target.value)}
            value={vendorForm.bankInfo?.accountNumber}
            type="number"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
            required
          />
        </div>
      </div>
      <div className="md:flex md:space-x-2 md:space-y-0 space-y-4 mt-5">
        <div className="w-full space-y-2">
          <p>Bank Name*</p>
          <input
            onChange={(e) => handleChangeForm("bankName", e.target.value)}
            value={vendorForm.bankInfo?.bankName}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
            required
          />
        </div>
        <div className="w-full space-y-2">
          <p>Bank Address*</p>
          <input
            onChange={(e) => handleChangeForm("bankAddress", e.target.value)}
            value={vendorForm.bankInfo?.bankAddress}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
            required
          />
        </div>
      </div>
      <div className="md:flex md:space-x-2 md:space-y-0 space-y-4 mt-5">
        <div className="w-full space-y-2">
          <p>Routing Number*</p>
          <input
            onChange={(e) => handleChangeForm("routingNumber", e.target.value)}
            value={vendorForm.bankInfo?.routingNumber}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
            required
          />
        </div>
        <div className="w-full space-y-2">
          <p>IBAN*</p>
          <input
            onChange={(e) => handleChangeForm("iban", e.target.value)}
            value={vendorForm.bankInfo?.iban}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
            required
          />
        </div>
      </div>
      <div className="md:flex md:space-x-2 md:space-y-0 space-y-3 mt-5">
        <div className="w-full space-y-2">
          <p>SWIFT Code*</p>
          <input
            onChange={(e) => handleChangeForm("swiftCode", e.target.value)}
            value={vendorForm.bankInfo?.swiftCode}
            type="text"
            className="w-full outline-none border border-bg-primary rounded-sm py-1 px-2"
            required
          />
        </div>
      </div>
    </div>
  );
};

const BankInfo = ({ isNew }) => {
  const [openForm, setOpenForm] = useState(false);
  const vendor = useSelector((state) => state.vendorSlice.vendor);
  const vendorForm = useSelector((state) => state.vendorSlice.vendorForm);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isNew) {
      dispatch(
        vendorFormAction({
          ...vendorForm,
          bankInfo: {
            accountName: "",
            accountNumber: "",
            bankAddress: "",
            bankName: "",
            iban: "",
            routingNumber: "",
            swiftCode: "",
          },
        })
      );
      return;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendor]);

  return (
    <div className="w-full shadow-xl px-5 py-5 bg-white">
      <div
        onClick={() => setOpenForm(!openForm)}
        className="flex justify-between cursor-pointer"
      >
        <p className="text-xl">Bank Info</p>
        <BsChevronDown
          size={25}
          className={
            "text-bg-primary " +
            (openForm ? "rotate-180 transition-all" : "rotate-0 transition-all")
          }
        />
      </div>
      {openForm && <FormBankInfo isNew={isNew} />}
    </div>
  );
};

export default BankInfo;
