import React, { useState, useEffect } from "react";
import Validation from "../forms/validation-user";
import Alert from "../alerts";
import { useParams, useHistory, Link, Redirect } from "react-router-dom";
import ApiClient from "../../apis/apiClient";
import {
  API_GetSubUserById,
  API_UpdateSubUserById,
  url,
} from "../../apis/constant";
import { toast } from "react-toastify";
import axios from "axios";

const SubUsersEdit = ({ message = null }) => {
  const adminToken = localStorage.getItem("wed_admin_token");
  const [data, onSubmit] = useState(null);
  const history = useHistory();
  const { id } = useParams();

  let itemsList = [
    {
      label: "Email address",
      error: {
        required: "Please enter a valid email",
        pattern: {
          value: /^[A-Za-z0-9._]{3,}@[A-Za-z]{3,}[.]{1}[A-Za-z.]{2,6}$/,
          message: "Entered value needs to be an e-mail address.",
        },
      },
      name: "email",
      type: "email",
      placeholder: "Enter your email",
      value: "",
      id: "email",
      inputType: "user-input",
    },
    {
      label: "Type",
      error: {
        required: "Type is required",
        validate: (value) =>
          ["bridesMade", "groomsMen", "fiancee"].includes(value) ||
          "Type is required",
      },
      name: "type",
      type: "select",
      id: "type",
      // inputType: "user-input",
      options: [
        { value: null, label: "Select language" },
        { value: "bridesMade", label: "Brides Made" },
        { value: "groomsMen", label: "Grooms Men" },
        { value: "fiancee", label: "Fiancee" },
      ],
    },
  ];

  const [items, setItems] = useState(itemsList);
  const GetSubuser = async () => {
    const data = "";
    const res = await ApiClient.get(
      API_GetSubUserById + id,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      var tempArr = [];
      for (let i = 0; i < items.length; i++) {
        const element = items[i];
        element.value = res.data.data[element.id];
        tempArr.push(element);
      }
      setItems(tempArr);
    } else {
      toast.warning(res.data.message);
    }
  };

  useEffect(() => {
    GetSubuser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value, id) => {
    var result = [...items];
    result = result.map((x) => {
      if (x.id === id) x.value = value;
      return x;
    });
    setItems(result);
  };

  //update Subuser
  if (data !== null) {
    let logData = {
      email: data?.email,
      type: data?.type,
    };
    axios
      .patch(url + API_UpdateSubUserById + id, logData, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((res) => {
        if (res.data.code === 200) {
          toast.success(res.data.message);
          history.push("/sub-users");
        } else {
          toast.error(res.data.message);
        }
      });
  }

  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
  return (
    <>
      <div className="addBack" style={{ marginTop: "15px" }}>
        <Link
          to={"/sub-users"}
          className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
        >
          Back
        </Link>
      </div>
      <div className="flex flex-col">
        {data && message && (
          <div className="w-full mb-4">
            <Alert
              color="bg-transparent border-green-500 text-green-500"
              borderLeft
              raised
            >
              {message}
            </Alert>
          </div>
        )}
        <Validation items={items} onSubmit={onSubmit} onChange={handleChange} />
      </div>
    </>
  );
        }
};

export default SubUsersEdit;
