import React from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import Layouts from './layouts'
import Routes from './Routes'
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './css/tailwind.css'
import './css/main.css'
import './css/animate.css'
import './css/_components.css'

import { url } from "./apis/constant"

import axios from "axios";



const Wrapper = ({children}) => {
  return (<Layouts>
  < ToastContainer />
    {children}
  </Layouts>)
}

const App = () => {
  axios.defaults.baseURL = url;
  return (
    <Router>
      <Wrapper>
        <Routes />
      </Wrapper>
    </Router>
  )
}
export default App
