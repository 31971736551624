import React, { useState, useEffect } from "react";
import Validation from "../forms/add-subadmin";
import { useParams, useHistory, Link, Redirect } from "react-router-dom";
import ApiClient from "../../apis/apiClient";
import {
  API_EditUserSubAdmin,
  url,
  API_ListOfRoles,
} from "../../apis/constant";
import { toast } from "react-toastify";
import axios from "axios";

const EditSubAdmin = ({ message }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const adminToken = localStorage.getItem("wed_admin_token");
  const history = useHistory();
  const { id } = useParams();
  const [nameVErr, setNameVErr] = useState("");

  let itemsList = [
    {
      label: "Name",
      error: { required: "Please enter a valid name" },
      name: "vendorName",
      type: "text",
      placeholder: "Enter your name",
      value: "",
      id: "name",
      inputType: "vendor-input",
    },
    {
      label: "Email address",
      error: { required: "Please enter a valid xxx email" },
      name: "email",
      type: "email",
      placeholder: "Enter your email",
      value: "",
      id: "email",
      inputType: "vendor-input",
    },
    {
      label: "Phone",
      error: {
        required: "Please enter a valid phone number",
        pattern: {
          value: /^[0-9]{8}$/,
          message: "Phone number must be 8 digit .",
        },
      },
      name: "phone",
      type: "number",
      placeholder: "Enter your phone number",
      value: "",
      id: "phone",
      inputType: "vendor-input",
    },

    {
      label: "Country",
      error: {
        required: "Please enter country",
        pattern: {
          value: /^[a-zA-Z]{0,50}(?: [a-zA-Z]+){0,2}$/,
          message: "Country needs to be less than 50 words",
        },
      },
      name: "country",
      type: "text",
      placeholder: "Enter country",
      value: "",
      id: "country",
      inputType: "vendor-input",
    },
    {
      label: "City",
      error: { required: "Please enter city" },
      name: "city",
      type: "text",
      placeholder: "Enter city",
      value: "",
      id: "city",
      inputType: "vendor-input",
    },
    {
      label: "State",
      error: {
        required: "Please enter state",
        pattern: {
          value: /^[a-zA-Z]{0,50}(?: [a-zA-Z]+){0,2}$/,
          message: "State needs to be less than 50 words",
        },
      },
      name: "state",
      type: "text",
      placeholder: "Enter state",
      value: "",
      id: "state",
      inputType: "vendor-input",
      maxLength: "50",
      minLength: "2",
    },
    {
      label: "Post Code",
      error: {
        required: "Please enter a valid post code",
        pattern: {
          value: /^[0-9]{6}$/,
          message: "Postal Code must be 6 digit.",
        },
      },
      name: "postCode",
      type: "number",
      placeholder: "Enter post code",
      value: "",
      id: "zipcode",
      inputType: "vendor-input",
    },
    {
      label: "Roles",
      error: {
        required: "Role is required",
        validate: (value) => console.log(value, "vlue"),
      },
      name: "role",
      type: "select",
      id: "role",
      value: "",
      options: [],
    },
    {
      label: "Address",
      error: {
        required: "Please enter Address",
        pattern: {
          value: /^[a-zA-Z]{0,50}(?: [a-zA-Z]+){0,2}$/,
          message: "Address needs to be less than 50 words",
        },
      },
      name: "address",
      type: "text",
      placeholder: "Enter Address",
      value: "",
      id: "address",
      inputType: "vendor-input",
    },
  ];
  const [items, setItems] = useState(itemsList);

  const getAdminAllData = async () => {
    const data = "";
    const res = await ApiClient.get(
      API_EditUserSubAdmin + "/" + id,
      data,
      `Bearer ${adminToken}`
    );
    if (res.data.code === 200) {
      var tempArr = [];
      for (let i = 0; i < items.length; i++) {
        const element = items[i];
        if (element.type === "select") {
          element.value = res.data.data.role.id;
        } else {
          element.value = res.data.data[element.id];
        }
        tempArr.push(element);
      }
      setItems(tempArr);
    } else {
      toast.warning(res.data.message);
    }
  };

  const getAllRoles = async (limit, searchq = "") => {
    const data = "";
    const res = await ApiClient.get(
      API_ListOfRoles + `?status=active&page=1&sortBy=asc&limit=${limit}&searchBy=${searchq}`,
      data,
      `Bearer ${adminToken}`
    );

    if (res.data.code === 200) {
      let full_arr = res.data.data.docs;
      let data_arr = [];
      let temp_arr = [];
      for (let d in full_arr) {
        data_arr.push({
          value: full_arr[d].id,
          label: full_arr[d].name,
        });
      }
      data_arr.splice(0, 0, { value: "", label: "Select Role" });
      for (let data in items) {
        if (full_arr && items[data].type === "select") {
          items[data].options = data_arr;
          items[data].value = data_arr;
        }
        temp_arr.push(items[data]);
      }
      setItems(temp_arr);
    }
  };

  useEffect(() => {
    getAdminAllData();
    getAllRoles(10);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value, id) => {
    var result = [...items];

    result = result.map((x) => {
      if (x.id === id) {
        if (x.id === "name") {
          if (value.length <= 200) {
            x.value = value;
            setNameVErr("");
          } else {
            setNameVErr("Name can not be greter than 200 words.");
          }
        } else {
          x.value = value;
        }
      }
      return x;
    });
    setItems(result);
  };

  const onSubmit = (data) => {
    setIsSubmit(true);

    let logData = {
      name: data?.vendorName,
      email: data?.email,
      phone: data?.phone,
      country: data?.country,
      city: data?.city,
      state: data?.state,
      address: data?.address,
      role: data?.role,
      zipcode: data?.postCode,
      type: "subadmin",
    };
    axios
      .patch(url + API_EditUserSubAdmin + "/" + id, logData, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((res) => {
        if (res.data.code === 200) {
          toast.success(res.data.message);
          history.push("/admin");
        } else if (res.data.code === 400) {
          toast.error(res.data.message);
          setIsSubmit(false);
        }
      })
      .catch((err) => {
        setIsSubmit(false);
        toast.error(err.response.data.message);
      });
  };

  if (adminToken === null) {
    return <Redirect to="/" />;
  } else {
  return (
    <>
      <div className="addBack" style={{ marginTop: "15px" }}>
        <Link
          to={"/admin"}
          className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
        >
          Back
        </Link>
      </div>

      <div className="flex flex-col">
        <Validation
          items={items}
          onSubmit={onSubmit}
          onChange={handleChange}
          isSubmit={isSubmit}
          nameVErr={nameVErr}
        />
      </div>
    </>
  );
  }
};

export default EditSubAdmin;
