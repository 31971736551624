import React from "react";

const TableSectionCourse = ({
  data,
  editAction,
  handleDelete,
  setModalSection,
  sectionLevel,
}) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr>
            <th className="py-2 px-4 bg-gray-200 text-gray-700 text-left text-sm font-bold border-b border-gray-200 whitespace-no-wrap">
              Section {sectionLevel}
            </th>
            <th className="py-2 px-4 bg-gray-200 text-gray-700 text-left text-sm font-bold border-b border-gray-200">
              Video Link
            </th>
            {sectionLevel + 1 <= 4 && (
              <th className="py-2 px-4 bg-gray-200 text-gray-700 text-left text-sm font-bold border-b border-gray-200 whitespace-no-wrap">
                Section {sectionLevel + 1}
              </th>
            )}
            <th className="py-2 px-4 bg-gray-200 text-gray-700 text-right text-sm font-bold border-b border-gray-200">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className="border-b">
              <td className="py-2 px-4 text-sm text-gray-700 border-b border-gray-200">
                {item.title}
              </td>
              <td className="py-2 px-4 text-sm text-gray-700 border-b border-gray-200">
                {item.video_link}
              </td>
              {sectionLevel + 1 <= 4 && (
                <td className="py-2 px-4 text-sm text-gray-700 border-b border-gray-200 underline cursor-pointer">
                  <p onClick={() => setModalSection(item)}>
                    {item.sub_section_header.length} items
                  </p>
                </td>
              )}
              <td className="py-2 px-4 text-sm text-gray-700 border-b border-gray-200 text-right whitespace-no-wrap">
                <button
                  className="underline mr-2"
                  onClick={() => handleDelete(item)}
                >
                  Delete
                </button>
                <button className="underline" onClick={() => editAction(item)}>
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableSectionCourse;
