import React from "react";
import { DateEnd } from "./dateEnd";
import { DateStart } from "./dateStart";

export const DatePolicyContent = ({
  FORMAT,
  val,
  policies,
  setPolicies,
  dateStart,
  dateEnd,
  showDateStart,
  showDateEnd,
  setShowDateEnd,
  setShowDateStart,
  setUpdatedPolicyStartDate,
  setUpdatedPolicyEndDate,
  isdisabled,
  isNoEndDate
}) => {
  return (
    <div>
      <div className="flex my-4 w-full md:items-center md:flex-row flex-col ">
        <DateStart
          isdisabled={isdisabled}
          FORMAT={FORMAT}
          dateStart={dateStart}
          showDateStart={showDateStart}
          policies={policies}
          val={{ start_date: dateStart }}
          setPolicies={setPolicies}
          setShowDateStart={setShowDateStart}
          setUpdatedPolicyStartDate={setUpdatedPolicyStartDate}
        />

        <DateEnd
          FORMAT={FORMAT}
          dateEnd={dateEnd}
          showDateEnd={showDateEnd}
          policies={policies}
          val={{ end_date: dateEnd }}
          setPolicies={setPolicies}
          setShowDateEnd={setShowDateEnd}
          setUpdatedPolicyEndDate={setUpdatedPolicyEndDate}
          isdisabled={isdisabled}
          isNoEndDate={isNoEndDate}
        />
      </div>
    </div>
  );
};
