import React, { createRef, useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import Calendar from "react-calendar";
import GeneralSetting from "../components/scheduling/generalSetting";
import { AiOutlineDoubleRight } from "react-icons/ai";
import CreateCalendarEvent from "../components/scheduling/event";
import ReminderVendor from "../components/scheduling/reminder";
import moment from "moment";
import SectionTitle from "../components/section-title";
import { BsFillCalendarEventFill } from "react-icons/bs";
import { fetcher } from "../utils/common";
import { toast } from "react-toastify";

const Scheduling = () => {
  const [openSide, setOpenSide] = useState(false);
  const calendarRef = createRef();
  const [dateSelect, setDateSelect] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenReminder, setIsOpenReminder] = useState(false);
  const [eventForm, setEventForm] = useState({ members: [""] });
  const [data, setData] = useState([]);
  const userId = localStorage.getItem("wed_admin_userId");
  const token = localStorage.getItem("wed_admin_token");

  const _gotoDate = (e) => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.gotoDate(e);
    setDateSelect(e);
  };

  const getAppointment = async (id) => {
    const result = await fetcher("get", `/admin/appointment/id/${id}`);
    const _data = result?.data;
    if (_data) {
      setEventForm({
        ..._data,
        startTime: moment(_data.startTime).format("HH:mm"),
        endTime: moment(_data.endTime).format("HH:mm"),
      });
      setIsOpen(true);
    }
  };

  const updateAppointment = async () => {
    const params = {
      id: eventForm.id,
      userId: eventForm.id,
      vendorId: userId,
      topic: eventForm.topic,
      date: moment(eventForm.date).format("DD-MMM-YYYY"),
      startTime: `${moment(eventForm.date).format("DD-MMM-YYYY")} ${
        eventForm.startTime
      }`,
      endTime: `${moment(eventForm.date).format("DD-MMM-YYYY")} ${
        eventForm.endTime
      }`,
      meetingType: eventForm.meetingType,
      invitedUser: eventForm.invitedUser,
      maxUser: eventForm.maxUser,
      location: eventForm?.location || "133 Blk 52, #05-1 Bishan St 21",
      meetingLink:
        eventForm.meetingLink || "https://zoom.us/j/1234567890?pwd=1234567890",
    };

    const result = await fetcher(
      "post",
      `/vendor/appointment/edit`,
      token,
      JSON.stringify(params)
    );
    const _data = result?.data;
    if (_data) {
      getAppointments();
      setIsOpen(false);
      toast.success("Update Success");
      return;
    }
    toast.error("Update Failed");
  };

  const getAppointments = async () => {
    const result = await fetcher("get", `/admin/appointment/show/${userId}`);

    const _data = result?.data;

    if (_data) {
      let dataTemp = [];
      _data.forEach((item) => {
        const data = {
          id: item._id,
          title: item.topic,
          start: moment(item.startTime).format("DD-MMM-YYYY HH:mm:ss"),
          end: moment(item.endTime).format("DD-MMM-YYYY HH:mm:ss"),
          color: "#DBA8A1",
        };
        dataTemp.push(data);
      });
      setData(dataTemp);
    }
  };

  useEffect(() => {
    getAppointments();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <SectionTitle title="Manage Vendors" subtitle="Schedulings" />
      <div className="flex w-full justify-end flex_calender">
        <div
          onClick={() => setIsOpenReminder(!isOpenReminder)}
          className="cursor-pointer"
        >
          <BsFillCalendarEventFill size={40} className="text-bg-primary" />
        </div>
      </div>
      <div className="mt-10 flex gap-x-2">
        <div
          className={`${
            openSide ? `w-2/3  flex items-start justify-between` : ``
          }`}
        >
          {openSide ? (
            <div className="w-full">
              <div className="w-full flex justify-center">
                <Calendar
                  onChange={(e) => _gotoDate(e)}
                  value={dateSelect}
                  className="text-xl rounded-md"
                />
              </div>
              <div className="mt-5 text-xl">
                <GeneralSetting />
              </div>
            </div>
          ) : null}
          <div className="mr-5">
            <div
              onClick={() => setOpenSide(!openSide)}
              className="cursor-pointer text-bg-primary"
              title="Toggle Menu"
            >
              <AiOutlineDoubleRight
                size={25}
                className={`${
                  openSide ? `rotate-180` : `rotate-0`
                } transition-all`}
              />
            </div>
          </div>
        </div>
        <div className="w-full">
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
            initialView="dayGridMonth"
            showNonCurrentDates={false}
            weekends={true}
            events={data}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            dateClick={(info) => {
              // setEventForm({ ...eventForm, start_date: info.dateStr, start_time: moment(info.dateStr).format("HH:mm:ss") });
              // setIsOpen(true);
            }}
            eventClick={(info) => {
              // alert("event clicked " + info.event.title + " " + info.event.id);
              // setEventForm({
              //   ...eventForm,
              //   title: info.event.title,
              //   date: info.event.startStr,
              //   start_time: moment(info.event.startStr).format("HH:mm:ss"),
              //   end_time: moment(info.event.endStr).format("HH:mm:ss"),
              // });
              // setIsOpen(true);
              getAppointment(info.event.id);
            }}
          />
        </div>
      </div>
      <CreateCalendarEvent
        updateAppointment={updateAppointment}
        eventForm={eventForm}
        setEventForm={setEventForm}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />

      <ReminderVendor
        data={data}
        isOpen={isOpenReminder}
        setIsOpen={setIsOpenReminder}
      />
    </div>
  );
};

export default Scheduling;
