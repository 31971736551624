import React, { useState } from "react";
import { useForm } from 'react-hook-form'
import Alert from '../alerts'



const FormValidation = ({ items, onSubmit, alerts, onChange ,isSubmit,phoneErr}) => {
  const { handleSubmit, errors, register } = useForm();
  const [emailValidError, setEmailValidError] = useState(false);


  // useEffect(() => {
  //   onChange()
    
  // }, [])
  
  const onSubmitFn = data => {
    if (onSubmit) {
      onSubmit(data)
    }
  }
  items = items.map(item => {
    item['ref'] = register(item['error'])
    return item
  })


  const handleEmailChange = (value, id) => {
    onChange(value, id)

    const regex =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (value === "") {
      setEmailValidError(false);
    } else if (regex.test(value) === false) {
      setEmailValidError(true);
    } else {
      setEmailValidError(false);
    }
  };

  

  return (
    <form
      onSubmit={handleSubmit(onSubmitFn)}
      className="form flex flex-wrap w-full">
      {alerts &&
        items.map((item, i) => {
          if (!errors[item.name]) return null
          let msg = errors[item.name].message
          if (msg.length === 0) msg = `${item.label} is required`
          return (
            <div className="flex flex-col w-full">
              {errors[item.name] && (
                <div className="mb-2" key={i}>
                  <Alert
                    color="bg-transparent border-red-500 text-red-500"
                    borderLeft
                    raised>
                    {msg}
                  </Alert>
                </div>
              )}
            </div>
          )
        })}
      <div className="w-full vendor-flex-box">
        {items.map((item, i) => {
          if (item.type === 'email')
           { 
            return (
              <>
                <div className="form-element lg:w-1/2">
                  {item.label && <div className="form-label">{item.label}</div>}
                  <input
                    ref={item.ref}
                    id={item.id}
                    value={item.value}
                    name={item.name}
                    type={item.type}
                    onChange={(e) => handleEmailChange(e.target.value, item.id)}
                    className={`form-input ${errors[item.name] ? 'border-red-500' : ''
                      }`}
                    placeholder={item.placeholder}
                    readOnly= {item.readOnly}
                  />
                  {!alerts && errors[item.name] && (
                    <div className="form-error">{errors[item.name].message}</div>
                  )}
                  {emailValidError && (
                    <div className="form-error">Please enter valid email</div>
                  )}
                </div>
              </>
            )
          }
          if (item.inputType === 'vendor-input') {
            return (
              <>
                <div className="form-element lg:w-1/2">
                  {item.label && <div className="form-label">{item.label}</div>}
                  <input
                    ref={item.ref}
                    id={item.id}
                    value={item.value}
                    name={item.name}
                    type={item.type}
                    onChange={(e) => onChange(e.target.value, item.id)}
                    className={`form-input ${errors[item.name] ? 'border-red-500' : ''
                      }`}
                    placeholder={item.placeholder}
                  />
                  {!alerts && errors[item.name] && (
                    <div className="form-error">{errors[item.name].message}</div>
                  )}
                  {item.id==="phone" && <p style={{color:"red"}}>{phoneErr}</p>}
                </div>
              </>
            )
          }
          return null;
        })}
      </div>
      {isSubmit ? 
      <button
      type="submit"
      className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
    >Loading...</button>
       :
   
      <button
        type="submit"
        className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
      >Submit</button>
      }
    </form>
  )
}
export default FormValidation
