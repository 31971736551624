import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import MuiDateTimePicker from '../MuiDateTimePicker';
import axios from 'axios';
import { API_cancel_order, url } from '../../apis/constant';
import { toast } from 'react-toastify';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const RefundModal = ({ refundItem = null, handleClose = () => { }, handleRefund = () => { } }) => {
    const [formData, setFormData] = useState({ stripeId: '', paymentRefundedOn: null, evoucherCoupon: '', voucherRefunedOn: null });
    const [loading, setLoading] = useState(false);
    const adminToken = localStorage.getItem("wed_admin_token");
    const [value, setValue] = useState(refundItem.refundMethod?.toLowerCase() === "voucher" ? 1 : 0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const saveRefundDetails = () => {
        if (value === 1) {
            // if (!formData.evoucherCoupon) {
            //     toast.error('Voucher Code is required!');
            //     return;
            // }
            // if (!formData.voucherRefunedOn) {
            //     toast.error('Refund Timestamp is required!');
            //     return;
            // }
            formData.stripeId = "";
            formData.paymentRefundedOn = "";
        } else {
            // if (!formData.stripeId) {
            //     toast.error('Stripe Id is required!');
            //     return;
            // }
            // if (!formData.paymentRefundedOn) {
            //     toast.error('Stripe Timestamp is required!');
            //     return;
            // }
            formData.evoucherCoupon = "";
            formData.voucherRefunedOn = "";
        }

        setLoading(true);
        axios
            .put(url + API_cancel_order + `/refundOrder/${refundItem.id}`, formData, { headers: { Authorization: `Bearer ${adminToken}` }, } )
            .then((res) => {
                if (res.data.code === 200) {
                    handleRefund();
                    if (res?.data?.message) {
                        toast.warn(res?.data?.message);
                    } else {
                        toast.success("Refund Details saved successfuly!");
                    }
                } else {
                    toast.error(res?.data?.message ?? "Error occured please try again!");
                }
                setLoading(false);
            }).catch(err => {
                toast.error(err?.response?.data?.message ?? "Error occured please try again!");
                setLoading(false);
            });
    }

    useEffect(() => {
        if (refundItem) {
            console.log('refundItem', refundItem);
            setFormData({
                stripeId: refundItem?.stripeId ?? "",
                paymentRefundedOn: refundItem?.srtimestamp && refundItem?.srtimestamp !== '-' ? refundItem?.srtimestamp : null,
                evoucherCoupon: refundItem?.voucherCoupon ?? "",
                voucherRefunedOn: refundItem?.prtimestamp && refundItem?.prtimestamp !== '-' ? refundItem?.prtimestamp : null,
            })
        }
    }, [refundItem])


    return (
        <Dialog open={true} onClose={handleClose} fullWidth>
            <DialogTitle>Please enter refund details</DialogTitle>
            <DialogContent>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Stripe Refund" />
                        <Tab label="Voucher Refund" />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <TextField
                        autoFocus
                        label="Stripe Id"
                        type="text"
                        fullWidth
                        variant="standard"
                        sx={{
                            my: '15px'
                        }}
                        value={formData.stripeId}
                        onChange={(e) => {
                            setFormData((prev) => ({
                                ...prev,
                                stripeId: e.target.value
                            }))
                        }}
                    />
                    <MuiDateTimePicker
                        label='Refund Timestamp'
                        value={formData.paymentRefundedOn}
                        onChange={(val) => {
                            setFormData((prev) => ({
                                ...prev,
                                paymentRefundedOn: val
                            }))
                        }}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <TextField
                        autoFocus
                        label="Voucher Code"
                        type="text"
                        fullWidth
                        variant="standard"
                        sx={{
                            my: '15px'
                        }}
                        value={formData.evoucherCoupon}
                        onChange={(e) => {
                            setFormData((prev) => ({
                                ...prev,
                                evoucherCoupon: e.target.value
                            }))
                        }}
                    />
                    <MuiDateTimePicker
                        label='Refund Timestamp'
                        value={formData.voucherRefunedOn}
                        onChange={(val) => {
                            setFormData((prev) => ({
                                ...prev,
                                voucherRefunedOn: val
                            }))
                        }}
                    />
                </CustomTabPanel>
            </DialogContent>
            <DialogActions sx={{ paddingX: "30px", paddingTop: "10px", paddingBottom: "20px" }}>
                <Button
                    onClick={handleClose}
                    sx={{
                        boxShadow: 'none',
                        textTransform: 'capitalize'
                    }}
                    disabled={loading}
                >
                    Cancel
                </Button>
                <LoadingButton
                    loading={loading}
                    disabled={loading}
                    variant='contained'
                    sx={{
                        boxShadow: 'none',
                        textTransform: 'capitalize'
                    }}
                    onClick={() => {
                        saveRefundDetails();
                    }}
                >
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}
export default RefundModal