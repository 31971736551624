import React from "react";
import Layout from "../layouts/centered";
import CenteredForm from "../layouts/centered-form";
import Login from "../components/sample-forms/login";
import Logo from "../components/left-sidebar-1/logo.js"
const Index = () => {
  return (
    <div className="newLogo">
      <Logo />
    <Layout>
      <CenteredForm
        title="Login"
        subtitle="Please enter your Email and Password to login"
      >
        <Login />

        
      </CenteredForm>
    </Layout>
    </div>
  );
};

export default Index;
