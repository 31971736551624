import React, { useState } from "react";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import moment from "moment";

export const DateEnd = ({
  policies,
  idx,
  val,
  setUpdatedPolicyEndDate,
  dateEnd,
  FORMAT,
  showDateEnd,
  setShowDateEnd,
  isdisabled,
  isNoEndDate
}) => {
  // const [showDateEnd, setShowDateEnd] = useState(false);
  const [selectedDays, setSelectedDayEnd] = useState(val?.end_date);
  return (
    <div>
      <div className="md:ml-5 md:mt-0 mt-3">
        <p className="font-worksans-semibold w-32">End Date</p>
        <div className="relative">
          <div
            onClick={() => {
              setShowDateEnd(!showDateEnd);
            }}
            className="md:w-64 w-full h-10 outline-none py-2 px-2 rounded-lg border-2 flex justify-between items-center z-10 cursor-pointer"
          >
            <p className={!dateEnd && "text-slate-400"}>
              {
                val.end_date ? moment(new Date(val.end_date)).format(FORMAT) : ""}
            </p>
            {/* <AiOutlineDown className="text-bg-primary font-bold" /> */}
          </div>
          <div className="bg-white absolute top-12 right-0 border-2 z-10">
            {showDateEnd && !isdisabled && !isNoEndDate && (
              <DayPicker
                onDayClick={(e) => {
                  setUpdatedPolicyEndDate(moment(e).format(FORMAT));
                  setShowDateEnd(false);
                  setSelectedDayEnd(e);
                  // const copyPolicy = [...policies];
                  // copyPolicy.splice(idx, 1, { ...val, end_date: e.toISOString() });
                  // setPolicies(copyPolicy);
                }}
                selectedDays={selectedDays}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
