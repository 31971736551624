import React, { useState } from "react";
import { useOutsideClick } from "../../hooks/OutsideClick";
import PhoneJson from "../../../src/json/phoneCode.json";

const PhonePicker = ({ onSelect, phoneCode, phone, onChangePhone }) => {
  const [show, setShow] = useState(false);
  const ref = useOutsideClick(() => setShow(false));

  return (
    <div className="flex items-center border py-1 px-1">
      <div ref={ref} className="border-r-2 pr-2 relative">
        <div className="cursor-pointer" onClick={() => setShow(!show)}>
          <p>{phoneCode}</p>
        </div>
        {show && (
          <div className="bg-white w-12  absolute mt-3 -ml-1 shadow-md text-center">
            {PhoneJson.countries.map((item, index) => (
              <div
                onClick={() => {
                  onSelect(item.code);
                  setShow(false);
                }}
                className="py-2 cursor-pointer hover:bg-grey-300"
              >
                <p key={index.toString()}>{item.code}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="pl-2">
        <input
          placeholder="823xxxxxxx"
          value={phone}
          onChange={(e) => onChangePhone(e.target.value)}
        />
      </div>
    </div>
  );
};

export default PhonePicker;
