/* eslint-disable no-unreachable */
import { createAsyncThunk } from "@reduxjs/toolkit";
// import { logoutAction } from "../reducers/auth.reducer";
import API from "../utils/createApi";

// VendorForm
export const vendorForm = (state, action) => {
  const { payload } = action;
  const {
    sellerInfo,
    businessOwner,
    sellerOffering,
    socialMedia,
    compensationPlan,
    referral,
    bankInfo,
    faq,
    validationChecks,
    settings,
  } = payload;
  state.vendorForm = {
    sellerInfo,
    businessOwner,
    sellerOffering,
    socialMedia,
    compensationPlan,
    referral,
    bankInfo,
    faq,
    validationChecks,
    settings,
  };
};

//// Fetching API
// Get All Vendor
export const vendorAction = createAsyncThunk(
  "vendor/vendorAction",
  async ({ params, onSuccess, onFailed }, { getState, dispatch }) => {
    const token = localStorage.getItem("wed_admin_token");
    try {
      const result = await API.get(
        `vendor/vendorList?page=${params.page}&sortBy=asc&limit=${params.limit}&searchBy=${params.search}&status=${params.status}&name=${params.name}&email=${params.email}&phone=${params.phone}&categories=${params.categories}`,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );

      if (result.data.code === 401) {
        onFailed();
        // dispatch(logoutAction());
        return { vendors: [] };
      }

      onSuccess(result.data.data)
      return { vendors: result.data.data.docs };
    } catch (err) {
      onFailed();
      throw new Error(err);
    }
  }
);

// Get All Vendor
export const updateVendorAction = createAsyncThunk(
  "vendor/updateVendorAction",
  async ({ params, onSuccess, onFailed }, { getState, dispatch }) => {
    const token = localStorage.getItem("wed_admin_token");
    const form = new FormData();
    form.append("status", params.status);
    try {
      const result = await API.put(
        `vendor/${params.id}`, form,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );

      if (result.data.code === 401) {
        onFailed();
        // dispatch(logoutAction());
        return { vendors: [] };
      }

      onSuccess(result.data.data)
      return {  };
    } catch (err) {
      onFailed();
      throw new Error(err);
    }
  }
);

export const updateVendorSellerOffering = createAsyncThunk(
  "vendor/updateVendorSellerOffering",
  async ({ params, onSuccess, onFailed }, { getState, dispatch }) => {
    const formVendor = getState().vendorSlice.vendorForm;
    const token = localStorage.getItem("wed_admin_token");
    const form = new FormData();
    form.append(
      "areYouASocialChampion",
      formVendor.sellerOffering.areYouASocialChampion
    );
    form.append(
      "categories",
      JSON.stringify(formVendor.sellerOffering.services)
    );
    form.append(
      "sellerOffering",
      JSON.stringify(formVendor.sellerOffering)
    );
    try {
      const result = await API.put(
        `vendor/${params.id}`, form,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );

      if (result.data.code === 401) {
        onFailed();
        // dispatch(logoutAction());
        return { vendors: [] };
      }

      onSuccess(result.data.data)
      return {  };
    } catch (err) {
      onFailed();
      throw new Error(err);
    }
  }
);

export const updateVendorCompensationPlan = createAsyncThunk(
  "vendor/updateVendorCompensationPlan",
  async ({ params, onSuccess, onFailed }, { getState, dispatch }) => {
    const token = localStorage.getItem("wed_admin_token");
    const form = new FormData();
    form.append(
      "compensationPlan",
      JSON.stringify(params.compensationPlan)
    );
    
    try {
      const result = await API.put(
        `vendor/${params.id}`, form,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );

      if (result.data.code === 401) {
        onFailed();
        // dispatch(logoutAction());
        return { vendors: [] };
      }

      onSuccess(result.data.data)
      return {  };
    } catch (err) {
      onFailed();
      throw new Error(err);
    }
  }
);

export const updateVendorActionChecks = createAsyncThunk(
  "vendor/updateVendorAction",
  async ({ params, onSuccess, onFailed }, { getState, dispatch }) => {
    const token = localStorage.getItem("wed_admin_token");
    const formVendor = getState().vendorSlice.vendorForm;
    const form = new FormData();
    form.append("status", params.status);
    // if()
    form.append("fiveYearHistory", formVendor.validationChecks.fiveYearHistory !== undefined ? formVendor.validationChecks.fiveYearHistory: true);
    form.append(
      "isDirectorOfbankrupt",
      formVendor.validationChecks.isDirectorOfbankrupt!== undefined ? formVendor.validationChecks.isDirectorOfbankrupt : true
    );
    form.append("isCaseReview", formVendor.validationChecks.isCaseReview !== undefined ? formVendor.validationChecks.isCaseReview : true);
    form.append("isBadPress", formVendor.validationChecks.isBadPress !== undefined ? formVendor.validationChecks.isBadPress : true);
    form.append("isBadReviews", formVendor.validationChecks.isBadReviews !== undefined ? formVendor.validationChecks.isBadReviews : true);
    form.append("comment", formVendor.validationChecks.comment ? formVendor.validationChecks.comment : "");
    try {
      const result = await API.put(
        `vendor/${params.id}`, form,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );

      if (result.data.code === 401) {
        onFailed();
        // dispatch(logoutAction());
        return { vendors: [] };
      }

      onSuccess(result.data.data)
      return {  };
    } catch (err) {
      onFailed();
      throw new Error(err);
    }
  }
);
export const vendorGetByIdAction = createAsyncThunk(
  "vendor/vendorGetByIdAction",
  async ({ onSuccess, onFailed, id }, { getState, dispatch }) => {
    const token =  localStorage.getItem("wed_admin_token");
    
    try {
      const result = await API.get(`vendor/${id}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      });

      if (result.data.code === 401) {
        onFailed();
        // dispatch(logoutAction())
        return { vendor: null };
      }

      onSuccess(result.data.data);
      return { vendor: result.data.data };
    } catch (err) {
      onFailed();
      throw new Error(err);
    }
  }
);

export const vendorDeactivateAction = createAsyncThunk(
  "vendor/vendorDeactivateAction",
  async ({ ids, onSuccess, onFailed, status }, { getState, dispatch }) => {
const token = localStorage.getItem("wed_admin_token");
// const token = adminToken;
    try {
      const result = await API.post(
        "vendor/admin/activateDeactivateVendors",
        {
          ids,
          status,
        },
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );
      if (result.data.code === 401) {
        onFailed();
        // dispatch(logoutAction())
        return {};
      }


      onSuccess();
      return {};
    } catch (err) {
      onFailed();
      throw new Error(err);
    }
  }
);

export const getCategoriesLevelOneAction = createAsyncThunk(
  "categories/getCategoriesLevelOne",
  async ({ onSuccess, onFailed }, { getState }) => {
    try {
      const result = await API.get(
        "public/masterCategory?page=1&sortBy=asc&limit=100",
        {
          headers: {
            Authorization: `Basic d2VkZGluZ3NlcnY6d2VkZGluZ3NlcnZAMTIzNDU=`,
          },
        }
      );
      onSuccess(result.data.data.docs);
      return result.data.data.docs;
    } catch (err) {
      onFailed();
      throw new Error(err);
    }
  }
);

export const addVendorAction = createAsyncThunk(
  "vendor/addVendorAction",
  async ({ onSuccess, onFailed }, { getState, dispatch }) => {
    const token = localStorage.getItem("wed_admin_token");;
    const formVendor = getState().vendorSlice.vendorForm;

    // return;

    const form = new FormData();
    form.append("vendorName", formVendor.sellerInfo.vendorName);
    form.append(
      "companyRegistrationNumber",
      formVendor.sellerInfo.companyRegistrationNumber
    );
    form.append("email", formVendor.sellerInfo.email);
    form.append("countryCode", formVendor.sellerInfo.countryCode);
    form.append("phone", formVendor.sellerInfo.phone);
    form.append(
      "countryCodeForMobile",
      formVendor.sellerInfo.countryCodeForMobile
    );
    form.append("mobileNumber", formVendor.sellerInfo.mobileNumber);
    form.append("username", formVendor.sellerInfo.username);
    form.append("companyName", formVendor.sellerInfo.companyName);
    form.append("blockNumber", formVendor.sellerInfo.blockNumber);
    form.append("unitNo", formVendor.sellerInfo.unitNo);
    form.append("buildingName", formVendor.sellerInfo.buildingName);
    form.append("streetAddress1", formVendor.sellerInfo.streetAddress1);
    form.append("streetAddress2", formVendor.sellerInfo.streetAddress2);
    form.append("streetAddress3", formVendor.sellerInfo.streetAddress3);
    form.append("country", formVendor.sellerInfo.country);
    form.append("city", formVendor.sellerInfo.city);
    form.append("state", formVendor.sellerInfo.state);
    form.append("postCode", formVendor.sellerInfo.postCode);
    form.append("website", formVendor.sellerInfo.website);
    form.append("vatTaxNumber", formVendor.sellerInfo.vatTaxNumber);
    form.append(
      "categories",
      JSON.stringify(formVendor.sellerOffering.services.map((item) => item.service))
    );
    form.append(
      "areYouASocialChampion",
      formVendor.sellerOffering.areYouASocialChampion
    );
    form.append(
      "businessOwnerInformation",
      JSON.stringify({
        firstName: formVendor.businessOwner.firstName,
        lastName: formVendor.businessOwner.lastName,
        email: formVendor.businessOwner.email,
        countryCode: formVendor.businessOwner.countryCode,
        phone: formVendor.businessOwner.phone,
      })
    );
    form.append(
      "isPrivacyAndTerms",
      formVendor.businessOwner.isPrivacyAndTerms
    );
    form.append("fiveYearHistory", formVendor.validationChecks.fiveYearHistory);
    form.append(
      "isDirectorOfbankrupt",
      formVendor.validationChecks.isDirectorOfbankrupt
    );
    form.append("isCaseReview", formVendor.validationChecks.isCaseReview);
    form.append("isBadPress", formVendor.validationChecks.isBadPress);
    form.append("isBadReviews", formVendor.validationChecks.isBadReviews);
    form.append("comment", formVendor.validationChecks.comment);
    form.append(
      "socialOptions",
      JSON.stringify({
        facebook: formVendor.socialMedia.facebook,
        flickr: formVendor.socialMedia.flickr,
        twitter: formVendor.socialMedia.twitter,
        youtube: formVendor.socialMedia.youtube,
        linkedIn: formVendor.socialMedia.linkedIn,
        pinterest: formVendor.socialMedia.pinterest,
        instagram: formVendor.socialMedia.instagram,
      })
    );
    // form.append("compensationPlan", {
    //   commissionPlan: formVendor.compensationPlan,
    //   commissionPercentage: formVendor.compensationPlan,
    //   commissionFixed: formVendor.compensationPlan,
    //   subscription: formVendor.compensationPlan,
    //   subscriptionName: formVendor.compensationPlan,
    //   subscriptionPeriod: formVendor.compensationPlan,
    // });
    form.append(
      "bankInfo",
      JSON.stringify({
        accountName: formVendor.bankInfo.accountName,
        accountNumber: formVendor.bankInfo.accountNumber,
        bankName: formVendor.bankInfo.bankName,
        bankAddress: formVendor.bankInfo.bankAddress,
        routingNumber: formVendor.bankInfo.routingNumber,
        iban: formVendor.bankInfo.iban,
        swiftCode: formVendor.bankInfo.swiftCode,
      })
    );
    form.append(
      "faq",
      JSON.stringify(
        formVendor.faq.map((item) => ({
          question: item.question,
          answer: item.answer,
        }))
      )
    );
    // form.append("startDate", formVendor); //ini tidak ditemukan gung
    // form.append("endDate", formVendor); //ini tidak ditemukan gung
    form.append(
      "setting",
      JSON.stringify({
        enableSelling: formVendor.settings.enableSelling,
        publishProductDirectly: formVendor.settings.publishProductDirectly,
        makeVendorFeatured: formVendor.settings.makeVendorFeatured,
      })
    );
    form.append("profileImage", formVendor.sellerInfo.profileImage);
    form.append("storeBanner", formVendor.sellerInfo.bannerImage);
    // form.append("storePhoto", formVendor); //ini tidak ditemukan gung
    form.append("profileSummary", formVendor.sellerInfo.review);

    try {
      const result = await API.post("vendor/admin/createVendor", form, {
        headers: {
          Authorization: `bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (result.data.code === 401) {
        onFailed();
        // dispatch(logoutAction());
        return {};
      }

      onSuccess();
      return {};
    } catch (err) {
      onFailed();
      throw new Error(err);
    }
  }
);