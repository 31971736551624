import React from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useGlobalFilter,
} from "react-table";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

import Loader from "../../components/custom-loader/loader";
import Pagination from "react-js-pagination";
import PageSizeDropdown from "../PageSizeDropdown";



const SubCategoriestable = ({
  columns,
  data,
  loading,
  pagination,
  onSubmit,
  getAllsubsubCategory,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setPageSize,
    state: {  pageSize },
  } = useTable(
    {
      columns,
      data,
      loading,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns]);
    }
  );

  const handlePageData = (e) => {
    onSubmit(e);
  };

 

  const handlePageChange = (pageNumber) => {
    getAllsubsubCategory(10, pageNumber);
  };


  return (
    <>
      <div className="flex p-5"></div>
      <table {...getTableProps()} className="table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div className="flex flex-row items-center justify-start">
                    <span>{column.render("Header")}</span>
                    <span className="ml-auto">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FiChevronDown className="stroke-current text-2xs" />
                        ) : (
                          <FiChevronUp className="stroke-current text-2xs" />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {loading ? (
            <tr>
              <td colSpan={10}>
                <Loader />
              </td>
            </tr>
          ) : page.length !== 0 ? (
            page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={10} style={{ textAlign: "center" }}>
                No Data Found
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {!loading && page.length && (
        <div className="flex flex-row items-center justify-between my-4 flex-wrap">
          <Pagination
            color="primary"
            activePage={parseInt(pagination.page)}
            itemsCountPerPage={pagination.limit}
            totalItemsCount={pagination.total}
            pageRangeDisplayed={5}
            onChange={(e) => handlePageChange(e)}
          />
          <select
            className="form-select text-sm bg-white dark:bg-grey-800 dark:border-grey-800 outline-none shadow-none focus:shadow-none"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              handlePageData(e.target.value);
            }}
          >
            <PageSizeDropdown />
          </select>
        </div>
      )}
    </>
  );
};

export default SubCategoriestable;
