import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const PrivateRoute = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) => (
        localStorage.getItem("wed_admin_token") ?
          children :
          <Redirect 
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
      )}
    />
  );
};

PrivateRoute.defaultProps = {
  exact: false
};

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool
};

export default PrivateRoute;