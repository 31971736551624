/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_OrderViewId, url } from "../apis/constant";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { formatCurrency } from "../functions/numbers";
import { Link } from "react-router-dom";
import Loader from "../components/custom-loader/loader";
import OrderDetailsItem from "../components/orderItem";
import { dateFormat } from "../utils/common";

const adminToken = localStorage.getItem("wed_admin_token");
const EditOrder = () => {
  const urlParams = useParams();
  const [data, setvData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(url + API_OrderViewId + urlParams.id, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((res) => {
        console.log("__res", res.data);
        if (res.data.code === 200) {
          const orderData = res.data.data;
          setvData(orderData);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err.message);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center h-full">
          <Loader />
        </div>
      ) : (
        <div className="md:p-20 p-5">
          <div className="w-full items-end mt-5 justify-between">
            {/* <div className="md:flex items-end mb-5">
              <p className="text-bg-primary font-cagily text-3xl w-full">
                Order No: {data.orderId}
              </p>
            </div>
            <div className="border-b border-[#AAAAAA] mb-5">
              <p className="text-black text-xl pb-5">
                Order Date : {moment(data.createdAt).format("DD-MMM-YYYY")}
              </p>
            </div> */}
            <div className="flex justify-between mb-5">
              <div className="flex flex-col justify-between mt-5">
                <p className="text-bg-primary font-cagily text-3xl w-full">
                  Order Details
                </p>
                <p className="text-black text-lg mt-3">
                  Order No: {`${data?.orderId}`}
                </p>
                <p className="text-black text-lg pb-5">
                  Order Date: {dateFormat(data?.createdAt)}
                </p>
              </div>
            </div>
          </div>
          <OrderDetailsItem data={data} urlParams={urlParams} />
        </div>
      )}
    </>
  );
};

export default EditOrder;
