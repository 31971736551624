import React from "react";
import { HiOutlineTrash, HiOutlineUserAdd } from "react-icons/hi";

const InvitePerson = ({ eventForm, setEventForm }) => {
  const addMember = () => {
    setEventForm({
      ...eventForm,
      invitedUser: [...eventForm.invitedUser, { email: "" }],
    });
  };

  const removeMember = (index) => {
    let _members = [...eventForm.invitedUser];
    if (eventForm.invitedUser.length > 1) {
      _members.splice(index, 1);
      setEventForm({ ...eventForm, invitedUser: _members });
    }
  };

  return (
    <>
      {eventForm.invitedUser.map((item, index) => {
        return (
          <div key={index} className="w-full">
            <div className="w-full flex items-center space-x-2">
              <div className="flex items-center gap-x-2 w-full">
                <input
                  onChange={(e) => {
                    let _members = [...eventForm.invitedUser];
                    _members[index] = {
                      ..._members[index],
                      email: e.target.value,
                    };
                    setEventForm({ ...eventForm, invitedUser: _members });
                  }}
                  value={item.email}
                  type="text"
                  className="w-full py-2 px-2 rounded-lg border-2 outline-none"
                  placeholder="Enter Email Address"
                />
              </div>
              <div
                onClick={() => removeMember(index)}
                className="bg-bg-primary p-2 rounded-md text-white cursor-pointer hover:opacity-70"
              >
                <HiOutlineTrash size={20} />
              </div>
              {index === eventForm.invitedUser.length - 1 ? (
                <div
                  onClick={() => addMember()}
                  className="bg-bg-primary p-2 rounded-md text-white cursor-pointer hover:opacity-70"
                  title="Add Team Member"
                >
                  <HiOutlineUserAdd size={20} />
                </div>
              ) : (
                <div className="p-2 invisible">
                  <HiOutlineUserAdd size={20} />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default InvitePerson;
