import React from 'react'
import { useLocation } from 'react-router-dom'
import Centered from './centered'
import Empty from './empty'
import Layout1 from './layout-1'

const Layouts = ({ children }) => {
  let location = useLocation()
  let { pathname } = { ...location }

  return (

    [
      // '/users',
      // '/vendors',
      '/',
      '/login',
      '/login-1',
      '/contact-us-1',
      '/create-account',
      '/email-confirmation',
      '/logout',
      '/reset-password',
      '/forgot-password',
      '/lock-screen',
      '/subscribe',
      '/error-page',
      '/coming-soon'
    ].includes(pathname)
      ?
      <Centered>{children}</Centered> :
      ['/landing', '/login-2', '/login-3'].includes(pathname) ?
        <Empty>{children}</Empty>
        :
        // return (

        <Layout1>{children}</Layout1>
    // }, 0)
    // )
    // }
  )
}

export default Layouts
