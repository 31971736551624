import React from "react";

const Widget2 = ({ title, description, right = null }) => {
  return (
    <div className="border border-grey-100 flex justify-between rounded-lg p-3 my-2">
      <div className="text-xl uppercase font-light self-center">
        {title}
      </div>
      <div className="text-xl font-bold self-center">{description}</div>
      {right}
    </div>
  );
};

export default Widget2;
